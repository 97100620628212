import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import UploadAudioFile from './UploadAudioFile';
import PropTypes from 'prop-types';
import AudioRecorder from './AudioRecorder';

function AddNewAudioFile(props) {
    const { newFileDetails, setNewFileDetails } = props;
    const [isRecordingMode, setIsRecordingMode] = useState(false);
    const [newAudioFile, setNewAudioFile] = useState(null);

    useEffect(() => {
        //debugger;
        //setNewFileName(null);
        setNewAudioFile(null);
        setNewFileDetails({ shared: false });
    }, []);

    const handleModeChange = (value) => {
        if (value === 'upload') {
            setIsRecordingMode(false);
        } else {
            setIsRecordingMode(true);
        }
        setNewAudioFile(null);
        setNewFileDetails({ ...newFileDetails, audioFile: null });
    };

    useEffect(() => {
        //debugger;
        setNewFileDetails({ ...newFileDetails, audioFile: newAudioFile });
    }, [newAudioFile]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <TextField
                    required
                    id="file-name"
                    label="FileName"
                    variant="outlined"
                    size="small"
                    value={newFileDetails.fileName}
                    onChange={(e) =>
                        setNewFileDetails({
                            ...newFileDetails,
                            fileName: e.target.value,
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={newFileDetails.shared}
                                onChange={(e) =>
                                    setNewFileDetails({
                                        ...newFileDetails,
                                        shared: e.target.checked,
                                    })
                                }
                            />
                        }
                        label="Share across Locations"
                    />
                </FormGroup>
            </Grid>
            <Grid item xs={12}>
                <FormControl>
                    <FormLabel id="newFile-mode-group-label">Mode</FormLabel>
                    <RadioGroup
                        row={{ xs: false, sm: true }}
                        aria-labelledby="newFile-mode-group-label"
                        defaultValue="upload"
                        name="newFile-mode-group"
                    >
                        <FormControlLabel
                            value="upload"
                            control={<Radio />}
                            label="Upload a File"
                            onChange={() => handleModeChange('upload')}
                        />
                        <FormControlLabel
                            value="record"
                            control={<Radio />}
                            label="Record a Message"
                            onChange={() => handleModeChange('record')}
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                {/* <RecordAMessage /> */}
                {isRecordingMode ? (
                    <AudioRecorder
                        newAudioFile={newAudioFile}
                        setNewAudioFile={setNewAudioFile}
                    />
                ) : (
                    <UploadAudioFile
                        newAudioFile={newAudioFile}
                        setNewAudioFile={setNewAudioFile}
                    />
                )}
            </Grid>
        </Grid>
    );
}

AddNewAudioFile.propTypes = {
    newFileName: PropTypes.string,
    setNewFileName: PropTypes.func,
    newFileDetails: PropTypes.shape({
        fileName: PropTypes.string,
        audioFile: PropTypes.object,
        shared: PropTypes.bool,
    }),
    setNewFileDetails: PropTypes.func,
};

export default AddNewAudioFile;
