import React from 'react';
import PropTypes from 'prop-types';
import SubmitButton from '../formControls/SubmitButton';

function SignupSuccessForm(props) {
    const { successMessage, navigate } = props;
    return (
        <SubmitButton
            message={successMessage}
            severity={successMessage ? 'success' : ''}
            label="Log in"
            onClick={() => navigate('/home')}
        />
    );
}

SignupSuccessForm.propTypes = {
    successMessage: PropTypes.string,
    navigate: PropTypes.func.isRequired,
};

SignupSuccessForm.defaultProps = {
    SignupSuccessForm: '',
};

export default SignupSuccessForm;
