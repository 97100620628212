import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

function SQRadioGroup(props) {
    const { label, value, handleChange, options, showFormControl } = props;
    const formattedLabel = label.toLowerCase().replace(' ', '-');
    const labelId = `${formattedLabel}-group-label`;
    return (
        <>
            {!showFormControl && (
                <Typography variant="h6" component="h6">
                    {label}
                </Typography>
            )}

            <FormControl>
                {showFormControl && <FormLabel id={labelId}>{label}</FormLabel>}
                <RadioGroup
                    aria-labelledby={labelId}
                    name={`${formattedLabel}-radio-buttons-group`}
                    value={value}
                    onChange={handleChange}
                >
                    {options.map(({ label, value }) => (
                        <FormControlLabel
                            key={value}
                            value={value}
                            control={<Radio />}
                            label={label}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </>
    );
}

SQRadioGroup.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        })
    ).isRequired,
    showFormControl: PropTypes.bool,
};
SQRadioGroup.defaultProps = {
    showFormControl: false,
};

export default SQRadioGroup;
