import React from 'react';
import { Outlet } from 'react-router';
import PhoneMenuContent from '../Components/PhoneMenuContent';
import PhoneMenuContext from '../PhoneMenuContext';
import usePhoneMenu from '../usePhoneMenu';

function PhoneMenuRoot() {
    const phoneMenuHook = usePhoneMenu();

    return (
        <PhoneMenuContext.Provider value={phoneMenuHook}>
            <PhoneMenuContent>
                <Outlet />
            </PhoneMenuContent>
        </PhoneMenuContext.Provider>
    );
}

PhoneMenuRoot.propTypes = {};

export default PhoneMenuRoot;
