import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { RestauInfoContext } from '../home/RestauInfoContext.js';
import { useStyles } from '../home/useStyles';
import { useLoader } from '../Hooks/useLoader';
import { useModifiers } from '../Hooks/useModifiers';
import { useOpenFood } from '../Hooks/useOpenFood';
import { FoodDialog } from './FoodDialog';
import Section from './Section.js';
import { style } from './Styles/style';
import EditorDrawer from './EditorDrawer';
import FoodEditor from './FoodEditor';
import { MenuInfoContext } from '../home/MenuInfoContext';
import PopularItems from './PopularItems/PopularItems.js';
import SortIcon from '@mui/icons-material/Sort';
import SortItemsInDialog from './PopularItems/SortItemsInDialog.js';
import Config from '../config/Config.js';
import axios from 'axios';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import AddNewSectionDialog from './AddNewSectionDialog.js';

const MenuStyled = styled.div`
    // margin: 0px 100px 100px 100px;
    // Add this part
    @media (max-width: 1000px) {
        margin: 0px 0px 100px 0px;
    }
    @media (max-width: 720px) {
        width: 100%;
        margin: auto;
        margin-bottom: 100px;
        margin-top: 0px;
    }
`;

const daysOfTheWeek = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
};

const DialogContent = styled.div`
    overflow: auto;
    min-height: 20px;
    padding: 0px 40px;
    padding-bottom: 20px;
`;

const MenuContent = styled(DialogContent)`
    padding: 10px 0;
    height: 100%;
`;

//Collapsible trigger={sectionName}

export default function MenuComp(props) {
    const menuHook = useContext(MenuInfoContext);
    // const menuHook = props.menuHook;
    //console.log('menuHook =>', menuHook);
    const { restauHook } = useContext(RestauInfoContext);

    const openFood = useOpenFood();
    const classes = useStyles();
    const loader = useLoader();
    const [template, setTemplate] = React.useState('');
    const [category, setCategory] = React.useState();
    const [newCategory, setNewCategory] = React.useState();
    const modifiersHook = useModifiers();
    const [isClose, setClose] = React.useState(false);
    //const [isModEdit, setOpenModEdit] = React.useState(false);
    const [openDialogToSort, setOpenDialogToSort] = React.useState(false);
    const [openDialogToAddSection, setOpenDialogToAddSection] =
        React.useState(false);
    const [tempItemData, setTempItemData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    async function refreshMenu() {
        //debugger;
        console.log('Refreshin Menu..');
        modifiersHook.setCurrentModifiers([]);
        setLoading(true);
        await menuHook.refreshData();
        console.log('MenuHook: ', menuHook);
        console.log('Template: ', menuHook.value.template);
        setTemplate(menuHook.value.template);
        modifiersHook.setModifiers(menuHook.value.modifiers);
        convertedPrice(menuHook.value.modifiers);
        setLoading(false);
    }

    function hoursString(hoursArray) {
        var hours = '';
        hoursArray.forEach(
            (slot) => (hours += slot.start + ' - ' + slot.end + '; ')
        );
        return hours.slice(0, -2);
    }

    function convertedPrice(modifiers) {
        if (!modifiers) {
            modifiersHook.setConvertedPrice({});
            return;
        }
        let optionPrice = {};
        for (let mod in modifiers) {
            let modifier = modifiers[mod];
            if (!modifier.options) {
                continue;
            }
            for (let option of modifier.options) {
                if (!option.price) {
                    optionPrice[modifier.id + option.id] = option.price;
                } else {
                    let p = parseFloat(option.price) / 100;
                    optionPrice[modifier.id + option.id] = p.toFixed(2);
                }
            }
        }
        modifiersHook.setConvertedPrice(optionPrice);
    }
    function setModifiers(food) {
        let modifiers = food.modifiers;
        modifiersHook.setModifiers(menuHook.value.modifiers);

        if (!modifiers || modifiers.length <= 0) {
            return;
        }

        modifiersHook.setCurrentModifiers(food.modifiers);
    }

    if (!restauHook) {
        return <></>;
    }
    useEffect(() => {
        refreshMenu();
    }, [restauHook.restauId]);

    let menu = null;
    let sectionData = null;
    if (menuHook && menuHook.value && menuHook.value.menu) {
        menu = menuHook.value.menu;
        sectionData = menu.reduce((res, sec) => {
            if (sec) {
                res[sec.name] = sec.id;
            }
            return res;
        }, {});
    }

    const styl = style();

    const addNewFoodItem = (sectionId) => {
        setClose(false);
        openFood.setValue({
            food: {},
            sectionId: sectionId,
            display: true,
        });
    };

    const handleFoodClick = (sectionId, food) => {
        setModifiers(food);
        convertedPrice(modifiersHook.modifiers);
        let openFoodValue = {
            food: food,
            sectionId: sectionId,
            display: true,
        };
        setClose(false);
        openFood.setValue(openFoodValue);
    };

    function showSortCategoriesDialog() {
        setTempItemData([...menu]);
        setOpenDialogToSort(true);
    }
    async function handleSortCategorySave() {
        //debugger;
        setLoading(true);
        console.log(`Saving the sorted categories order to database`);

        const token = await window.customerApp.auth().currentUser.getIdToken();
        var config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };

        let reqBody = {
            restauId: restauHook.restauId,
            menu: [...tempItemData],
        };

        //function to load the twilio data for this restaurant id
        let response = await axios
            .post(Config.updateCompleteFoodMenu, reqBody, config)
            .catch(function (error) {
                console.log(error);
            });
        if (response && response.data) {
            //console.log('Response>>: ' + JSON.stringify(response.data));
            if (response.data && response.data.status === 'success') {
                //setPopularItemData([...response.data]);
                refreshMenu();
            }
            //setLoading(false);
        }
        setLoading(false);
    }

    const handleAddNewSection = (catName, afterSectionId) => {
        menuHook
            .addSectionAfter(afterSectionId, catName)
            .then(() => {
                refreshMenu();
            })
            .catch((ex) => {
                alert('Error while saving please try again');
                //Need to pop error someways
                console.log(ex);
            });
    };

    const handleDeleteFoodItem = (foodId, sectionId) => {
        menuHook
            .deleteFoodItem(foodId, sectionId)
            .then(() => {
                refreshMenu();
            })
            .catch((ex) => {
                alert('Error while deleteting foodItem, please try again');
                //Need to pop error someways
                console.log(ex);
            });
    };

    const showEditor = false;
    return (
        <div>
            {!menu ? (
                <div>
                    <img
                        className={classes.loadingImage}
                        src="../../img/spinner.gif"
                    />
                </div>
            ) : (
                <>
                    {showEditor && (
                        <EditorDrawer open={true}>
                            <FoodEditor
                                // sectionId={currentSection}
                                // food={currentFood}
                                template={template}
                            />
                        </EditorDrawer>
                    )}
                    <SortItemsInDialog
                        open={openDialogToSort}
                        setOpen={setOpenDialogToSort}
                        itemDataToSort={tempItemData}
                        setItemDataToSort={setTempItemData}
                        restauId={restauHook.restauId}
                        handleSave={handleSortCategorySave}
                    />
                    <FoodDialog
                        modifiersHook={modifiersHook}
                        menu={menuHook}
                        food={openFood}
                        restauHook={restauHook}
                        loader={loader}
                        classes={classes}
                        styl={styl}
                        isClose={isClose}
                        setClose={setClose}
                        refreshMenu={refreshMenu}
                        deleteFoodItem={handleDeleteFoodItem}
                        //forAddNewItem={true}
                    />
                    <AddNewSectionDialog
                        open={openDialogToAddSection}
                        setOpen={setOpenDialogToAddSection}
                        handleSave={handleAddNewSection}
                        sections={sectionData}
                    />
                    <MenuStyled>
                        <MenuContent>
                            <Stack direction="row" spacing={3}>
                                <TextField
                                    size="small"
                                    label="Search Menu"
                                    sx={{ width: '30ch' }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment>
                                                <IconButton>
                                                    <SearchIcon
                                                        sx={{ fontSize: 22 }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Button
                                    aria-label="sort items"
                                    onClick={showSortCategoriesDialog}
                                    sx={{
                                        border: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: 0,
                                    }}
                                >
                                    <SortIcon sx={{ fontSize: '22px' }} /> Sort
                                    Categories
                                </Button>
                                <Button
                                    size="small"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: 1,
                                        pl: 2,
                                        pr: 3,
                                    }}
                                    onClick={() => {
                                        setOpenDialogToAddSection(true);
                                    }}
                                >
                                    <AddCircleSharpIcon
                                        color="primary"
                                        sx={{ fontSize: 26 }}
                                    />
                                    <Typography
                                        sx={{ ml: 0.5 }}
                                        //variant="body1"
                                    >
                                        Add
                                    </Typography>
                                </Button>
                            </Stack>
                            <br />
                            <PopularItems refreshMenu={refreshMenu} />
                            {loading ? (
                                <div>
                                    <img
                                        className={classes.loadingImage}
                                        src="../../img/spinner.gif"
                                    />
                                </div>
                            ) : (
                                menu.map((section, sidx) =>
                                    section ? (
                                        <Section
                                            key={section.id}
                                            sidx={sidx}
                                            template={template}
                                            addNewFoodItem={addNewFoodItem}
                                            onFoodClick={handleFoodClick}
                                            onRefreshData={refreshMenu}
                                        />
                                    ) : (
                                        <div key={sidx} />
                                    )
                                )
                            )}
                        </MenuContent>
                    </MenuStyled>
                </>
            )}
        </div>
    );
}
