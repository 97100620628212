import { useState } from 'react';
import Config from '../config/Config';
const { default: axios } = require('axios');

export function useDashBoard(defaultChoice) {
    const [value, setValue] = useState({});

    async function loadData(restauId) {
        if (!window.customerApp.auth().currentUser) {
            // navigate('/login');
            return;
        }
        if (!restauId) {
            return;
        }
        const token = await window.customerApp.auth().currentUser.getIdToken();

        let config = {
            headers: {
                token: token,
            },
        };
        axios
            .post(Config.getDailyReport, { restauId: restauId }, config)
            .then(function (response) {
                setValue(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

        return;
    }

    return {
        value,
        setValue,
        loadData,
    };
}
