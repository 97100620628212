import { createGlobalStyle } from 'styled-components';

// https://github.com/glennflanagan/react-collapsible/blob/develop/example/_src/sass/components/_Collapsible.scss
export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0; 
    font-family: 'Roboto';
  }
  
  h1, h2, h3 {
    font-family: 'Roboto';
  }

$yellow:  rgb(248, 159, 0);
$cyan:    rgb(0, 172, 157);
$grey:    rgb(51, 51, 51);
$black:   rgb(38, 38, 38);
$base:    rgb(255, 255, 255);
$lightGrey:    rgb(235, 235, 235);

$BASE_FONT_SIZE: 16px;

$breakpointMega: 1600px;
$breakpointLarge: 990px;
$breakpointMed: 767px;

.Collapsible {
  background-color: $base;
}


//The content within the collaspable area
.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid $lightGrey;
  border-top: 0;

  p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

//The link which when clicked opens the collapsable area
.Collapsible__trigger {
  border-radius: 7px; 
  display: block;
  text-decoration: none;
  color: #f2f2f2;
  position: relative;
  border: 1px solid white;
  padding: 15px;
  background: #f2f2f2;
  color: black;
  font-size: 1em;
  margin-top: 20px;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;  
  font-family: 'Roboto';

  &:after {
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
  }

  &.is-open {
    &:after {
      transform: rotateZ(180deg);
    }
  }

  &.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
  
  
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black;
}

`;
