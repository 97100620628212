import {
    Button,
    Chip,
    Grid,
    InputLabel,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useState } from 'react';

export default function DeleteItem(props) {
    const { itemType, setClose, handleDelete } = props;
    const [enableDelete, setEnableDelete] = useState(false);
    function handleTextChange(e) {
        setEnableDelete(e.target.value === 'Delete ' + itemType);
    }
    //const itemType = itemType;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Chip
                    label="Danger Zone"
                    size="small"
                    sx={{
                        m: 0,
                        pl: 0,
                        borderRadius: 2,
                        color: '#FF0000',
                        background: '#FFA07A',
                    }}
                />
                <Typography variant="h6" component="h6" sx={{ mt: 1 }}>
                    Delete {itemType}
                </Typography>
                <Typography variant="body1" component="p">
                    By deleting this {itemType}, the {itemType} will be removed
                    permentantly, and you will not be able to revert it.
                </Typography>
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'top',
                    }}
                >
                    <Grid item xs={7.5}>
                        <Stack>
                            <TextField
                                size="small"
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                onChange={handleTextChange}
                            />
                            <InputLabel>
                                Enter &ldquo;Delete {itemType}&rdquo; to
                                continue
                            </InputLabel>
                        </Stack>
                    </Grid>
                    <Grid item xs={4.5}>
                        <Button
                            size="medium"
                            variant="outlined"
                            sx={{ ml: 1 }}
                            disabled={!enableDelete}
                            onClick={() => {
                                setClose(true);
                                handleDelete();
                            }}
                        >
                            Delete {itemType}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

DeleteItem.propTypes = {
    itemType: PropTypes.string,
    setClose: PropTypes.func,
    handleDelete: PropTypes.func,
};
