import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));

export default function UploadButton(props) {
    const { label, color, onChange } = props;
    const classes = useStyles();

    return (
        <div style={{ flex: 'none' }}>
            {/* className={classes.root}> */}
            <input
                accept="image/*"
                className={classes.input}
                id="menu-image-upload"
                multiple
                type="file"
                onChange={onChange}
            />
            <label htmlFor="menu-image-upload">
                <Button variant="contained" color={color} component="span">
                    {label}
                </Button>
            </label>
        </div>
    );
}

UploadButton.defaultProps = {
    label: 'Upload',
    color: 'primary',
};
