import React, { useContext, useState, useEffect } from 'react';
import CateringSettingContext from '../CateringSettingContext';
import { Grid, TextField } from '@mui/material';
import DialogWithContent from '../../../../components/common/DialogWithContent/DialogWithContent';

const FaqDialog = ({
    open,
    setOpenFaqDialog,
    dialogTitle,
    setDialogTitle,
    defaultFaq,
    faqData,
    isAddingNewFaq,
    setIsAddingNewFaq,
    faq,
    setFaq,
    faqDialogIndex,
    setFaqDialogIndex,
}) => {
    const { updateFormConfig } = useContext(CateringSettingContext);
    const [editFaq, setEditFaq] = useState(defaultFaq);
    const [errors, setErrors] = useState({});
    useEffect(() => {
        setEditFaq(faqData);
    }, []);

    const updateFaq = (value) => {
        return setEditFaq((prev) => {
            return { ...prev, ...value };
        });
    };

    const cancel = () => {
        setDialogTitle('Edit FAQ');
        if (isAddingNewFaq) {
            setFaq((prevFaqs) => {
                const newFaqs = [...prevFaqs];
                newFaqs.pop();
                return newFaqs;
            });
            setIsAddingNewFaq(false);
        }
        setEditFaq(defaultFaq);
        setOpenFaqDialog(false);
    };

    const saveFaq = async () => {
        const newFaq = { ...editFaq };
        // conduct validation and data processing
        setErrors({});
        const tempErrors = {};
        if (newFaq.question.length === 0) {
            tempErrors.question = 'Please provide the question';
        }
        if (newFaq.answer.content[0].text.length === 0) {
            tempErrors.answer_text = 'Please provide the answer';
        }

        setErrors((prev) => {
            return { ...prev, ...tempErrors };
        });

        if (Object.keys(tempErrors).length === 0) {
            const newFaqs = [...faq];
            newFaqs[faqDialogIndex] = newFaq;
            setFaq(newFaqs);
            let response = await updateFormConfig({ faq: newFaqs });
        } else {
            throw new Error('Validation failed');
        }
    };

    return (
        <>
            <DialogWithContent
                open={open}
                dialogTitle={dialogTitle}
                setOpen={setOpenFaqDialog}
                handleSave={saveFaq}
                handleClose={cancel}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Question"
                            size="small"
                            variant="outlined"
                            margin="normal"
                            sx={{ width: '90%' }}
                            value={editFaq.question}
                            helperText={
                                errors.question ? errors.question : null
                            }
                            error={errors.question}
                            onChange={(e) =>
                                updateFaq({
                                    ...editFaq,
                                    question: e.target.value,
                                })
                            }
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            multiline
                            minRows={3}
                            label="Answer"
                            size="small"
                            variant="outlined"
                            margin="normal"
                            sx={{ width: '90%' }}
                            value={editFaq.answer.content[0].text}
                            helperText={
                                errors.answer_text ? errors.answer_text : null
                            }
                            error={errors.answer_text}
                            onChange={(e) =>
                                updateFaq({
                                    ...editFaq,
                                    answer: {
                                        ...editFaq.answer,
                                        content: [{ text: e.target.value }],
                                    },
                                })
                            }
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogWithContent>
            {/* <Dialog open={open} fullWidth>
                <DialogTitle>
                    <Typography variant="h4"> Edit FAQ </Typography>
                </DialogTitle>

                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="Question"
                                size="small"
                                variant="outlined"
                                margin="normal"
                                sx={{ width: '90%' }}
                                value={editFaq.question}
                                helperText={
                                    errors.question ? errors.question : null
                                }
                                error={errors.question}
                                onChange={(e) =>
                                    updateFaq({
                                        ...editFaq,
                                        question: e.target.value,
                                    })
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                multiline
                                minRows={3}
                                label="Answer"
                                size="small"
                                variant="outlined"
                                margin="normal"
                                sx={{ width: '90%' }}
                                value={editFaq.answer.content[0].text}
                                helperText={
                                    errors.answer_text
                                        ? errors.answer_text
                                        : null
                                }
                                error={errors.answer_text}
                                onChange={(e) =>
                                    updateFaq({
                                        ...editFaq,
                                        answer: {
                                            ...editFaq.answer,
                                            content: [{ text: e.target.value }],
                                        },
                                    })
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => {
                            cancel();
                        }}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button autoFocus onClick={saveFaq} variant="contained">
                        Save
                    </Button>
                </DialogActions>
            </Dialog> */}
        </>
    );
};

export default FaqDialog;
