// import { Typography } from '@mui/material';
import { Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import FormRoot from '../formControls/FormRoot';
import HelpText from '../formControls/HelpText';
import InputField from '../formControls/InputField';
import SubmitButton from '../formControls/SubmitButton';
import TitleBlock from '../formControls/TitleBlock';

function ResetPasswordForm(props) {
    const {
        email,
        setEmail,
        loaded,
        message,
        severity,
        onKeyPress,
        onPasswordReset,
    } = props;

    return (
        <FormRoot>
            <TitleBlock
                title="Reset Password"
                subtitle="Enter your email address and we will send you a link to reset your password."
            />
            <InputField
                id="outlined-email-input"
                label="Email"
                type="email"
                autoComplete="current-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyPress={onKeyPress}
            />

            <SubmitButton
                message={message}
                severity={severity}
                showProgress={loaded}
                label="Send Password Reset Link"
                onClick={onPasswordReset}
            />
            <HelpText />

            <div>
                <Typography
                    align="center"
                    component="p"
                    variant="subtitle1"
                    display="block"
                    noWrap
                >
                    <Link to="/login">Back To Login</Link>
                </Typography>
            </div>
        </FormRoot>
    );
}

ResetPasswordForm.propTypes = {};

export default ResetPasswordForm;
