import { Button, Divider, Grid, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Utils from '../../../../utils/Utils';
import PhoneSettingsContext from '../PhoneSettingsContext';
import CircularProgress from '@mui/material/CircularProgress';
const { getPhoneNumberInE164Format } = { ...Utils };

function PhoneNumberDetails() {
    const {
        strideQPhNumber,
        frwdingPhNumber,
        setFrwdingPhNumber,
        updatePhoneSettings,
    } = useContext(PhoneSettingsContext);

    const [isProcessing, setIsProcessing] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [tempFrwdingPhNumber, setTempFrwdingPhNumber] = useState('');

    function validatePhoneNumber(phNumber) {
        let formattedPhNumber = getPhoneNumberInE164Format(phNumber);
        //console.log('Formatted to:', formattedPhNumber);
        if (formattedPhNumber.length !== 12) {
            return false;
        }
        setFrwdingPhNumber(formattedPhNumber);
        return true;
    }

    const updatePhoneNumberDetails = () => {
        //debugger;

        let isPhNoValid = validatePhoneNumber(frwdingPhNumber);
        if (isPhNoValid) {
            setErrMsg('');
            let body = { forwadingPhNumber: frwdingPhNumber };
            setIsProcessing(true);
            updatePhoneSettings(
                body,
                setErrMsg,
                setSuccessMsg,
                setIsProcessing
            );
        } else {
            setErrMsg('Phone Number is Invalid.');
            setSuccessMsg('');
        }
    };

    const resetData = () => {
        setErrMsg('');
        setSuccessMsg('');
        setFrwdingPhNumber(tempFrwdingPhNumber);
    };

    useEffect(() => {
        setErrMsg('');
        setSuccessMsg('');
        setTempFrwdingPhNumber(frwdingPhNumber);
    }, []);

    return (
        <Grid
            container
            spacing={2}
            rowSpacing={3}
            sx={{ justifyContent: 'space-between' }}
            xs={12}
        >
            <Grid item xs={12}>
                <Typography variant="h6">StrideQ Phone Number</Typography>
                <Typography varint="body1">
                    This is the phone number for your StrideQ system. You can
                    always hear what a customer will hear by calling this number
                    directly.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="strideqPhNumber"
                    label="StrideQ Phone Number"
                    value={strideQPhNumber}
                    size="md"
                    disabled
                />
            </Grid>
            <Divider style={{ width: '100%' }} />

            <Grid item xs={12}>
                <Typography variant="h6">Forwarding Phone Number</Typography>
                <Typography varint="body1">
                    This is the phone number that StrideQ will forward to when a
                    customer wants to speak with your restaurant. This should be
                    the phone number of a line that will ring in the store.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="forwadingPhNumber"
                    label="Forwading Phone Number"
                    value={frwdingPhNumber}
                    size="md"
                    onChange={(e) => setFrwdingPhNumber(e.target.value)}
                />
            </Grid>
            <Divider style={{ width: '100%' }} />

            <Grid item xs={12} md={9}>
                <Typography variant="h6">Update!</Typography>
                <Typography variant="body1">For Full Service :</Typography>
                <Typography variant="body1">
                    Make sure to update StrideQ number on Yelp/Google. If you
                    have more one phone line? Press *72 [StrideQ Number] on the
                    main line to go live!
                </Typography>
                <br />
                <Typography variant="body1">
                    For Missed Call Service Only :
                </Typography>
                <Typography variant="body1">
                    Press *90 [StrideQ Number] on the main line. Also press *92
                    [StrideQ Number] and *58 [StrideQ Number] on the main line.
                    For xfinity customers need to press *71 [StrideQ Number] on
                    the main line.
                </Typography>
            </Grid>
            {errMsg !== '' ? (
                <Grid item xs={12} md={9}>
                    <Typography variant="body1" sx={{ color: 'red' }}>
                        {errMsg}
                    </Typography>
                </Grid>
            ) : (
                successMsg !== '' && (
                    <Grid item xs={12} md={9}>
                        <Typography variant="body1" sx={{ color: 'green' }}>
                            {successMsg}
                        </Typography>
                    </Grid>
                )
            )}

            <Grid
                item
                xs={12}
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                }}
            >
                <Button variant="outlined" onClick={resetData}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    sx={{ ml: 2 }}
                    onClick={updatePhoneNumberDetails}
                    disabled={isProcessing}
                >
                    {isProcessing && <CircularProgress size={24} />} Save
                </Button>
            </Grid>
        </Grid>
    );
}

PhoneNumberDetails.propTypes = {
    // strideQPhNumber: PropTypes.string,
    // frwdingPhNumber: PropTypes.string,
};

export default PhoneNumberDetails;
