import React, { useContext, useEffect, useState } from 'react';
import PhoneAudioLibContext from '../PhoneAudioLibContext';
import AudioFileCard from './AudioFileCard';
import {
    Alert,
    Button,
    Grid,
    Snackbar,
    Stack,
    Typography,
} from '@mui/material';
import { RestauInfoContext } from '../../../../home/RestauInfoContext';
import { useStyles } from '../../../../home/useStyles';
import DialogWithContent from '../../../../components/common/DialogWithContent/DialogWithContent';
import AddNewAudioFile from './AddNewAudioFile';
import EditAudioFile from './EditAudioFile';
import PhoneMenuContext from '../../../PhoneMenuContext';

function PhoneAudioLibContent(props) {
    const {
        showStatus,
        setShowStatus,
        statusMsg,
        setStatusMsg,
        isError,
        setIsError,
        updateAudioFile,
        //getAudioLibraryData,
        addNewAudioFile,
    } = useContext(PhoneAudioLibContext);
    //const { getAudioLibraryData } = useContext(PhoneAudioLibContext);
    const { loadAudioLibraryData } = useContext(PhoneMenuContext);
    const { restauHook } = useContext(RestauInfoContext);
    const [loading, setLoading] = useState(false);
    const [showAddNewFileDialog, setShowAddNewFileDialog] = useState(false);
    const [showEditFileDialog, setShowEditFileDialog] = useState(false);
    const [newFileDetails, setNewFileDetails] = useState({});
    const [editAudioFileDetails, setEditAudioFileDetails] = useState({});
    const [audioLibraryData, setAudioLibraryData] = useState(null);
    const [sharedAudioLibraryData, setSharedAudioLibraryData] = useState(null);
    const classes = useStyles();

    const loadData = async () => {
        //debugger;
        setLoading(true);
        try {
            let data = await loadAudioLibraryData(restauHook.restauId);
            // setAudioLibraryData(
            //     data.filter((x) => !x.shared || x.shared === false)
            // );
            setAudioLibraryData(
                data.filter((x) => x.restauId === restauHook.restauId)
            );
            setSharedAudioLibraryData(data.filter((x) => x.shared === true));
            setIsError(false);
            setShowStatus(false);
            setStatusMsg('');
        } catch (ex) {
            setIsError(true);
            setShowStatus(true);
            setStatusMsg('Failed to fetch files from Audio Library.');
        } finally {
            setLoading(false);
        }
    };

    const handleAddNewFileClick = () => {
        setShowAddNewFileDialog(true);
        setNewFileDetails({});
    };

    const saveEditedAudioFileDetails = async () => {
        //debugger;
        //check if the file with same name already exist
        let fileId = editAudioFileDetails.id;
        let updatedFileName = editAudioFileDetails.displayName;
        let updatedSharedVal = editAudioFileDetails.shared;

        if (
            !fileId ||
            fileId === '' ||
            !updatedFileName ||
            updatedFileName === ''
        ) {
            throw new Error(`Audio File Name cannot be blank.`);
        } else if (
            audioLibraryData.filter(
                (x) => x.id !== fileId && x.displayName === updatedFileName
            ).length >= 1
        ) {
            throw new Error(`An Audio File with the same name already exists.`);
        } else {
            //debugger;
            let result = await updateAudioFile(
                restauHook.restauId,
                fileId,
                updatedFileName,
                updatedSharedVal
            );

            if (!result) {
                throw new Error('Failed while saving the audio file.');
            }

            setShowEditFileDialog(false);
            setIsError(false);
            setStatusMsg('File details updated succesfully.');

            setShowStatus(true);
            loadData();
        }
    };

    const handleSaveNewFile = async () => {
        if (newFileDetails.fileName === '' || newFileDetails.fileName == null) {
            throw new Error('FileName cannot be empty.');
        }

        if (newFileDetails.audioFile == null) {
            throw new Error('Please select an audio file.');
        }

        if (
            audioLibraryData &&
            audioLibraryData.filter(
                (x) => x.displayName === newFileDetails.fileName
            ).length >= 1
        ) {
            throw new Error('An Audio file with the same name already exists.');
        }
        debugger;

        let result = await addNewAudioFile(
            restauHook.restauId,
            newFileDetails.audioFile,
            newFileDetails.fileName,
            newFileDetails.shared
        );
        //debugger;

        if (!result) {
            throw new Error('Failed while saving the audio file.');
        }

        //close the popup and reload the data
        setShowAddNewFileDialog(false);

        setIsError(false);
        setStatusMsg('New Audio File saved succesfully.');
        setShowStatus(true);
        loadData();
    };

    const handleClose = () => {
        setNewFileDetails({});

        setEditAudioFileDetails(null);
    };

    const handleStartEditingAudioFile = (fileId) => {
        setShowEditFileDialog(true);
        setEditAudioFileDetails(
            audioLibraryData.filter((x) => x.id === fileId)[0]
        );
    };

    useEffect(() => {
        //debugger;
        if (restauHook.restauId) {
            //debugger;
            loadData();
        }
    }, [restauHook.restauId, restauHook.chainId]);

    return (
        <>
            <Snackbar
                open={showStatus}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3000}
                onClose={() => setShowStatus(false)}
            >
                <Alert
                    onClose={() => setShowStatus(false)}
                    severity={isError ? 'error' : 'success'}
                    variant="outlined" //filled
                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                >
                    {statusMsg}
                </Alert>
            </Snackbar>

            <DialogWithContent
                open={showAddNewFileDialog}
                dialogMaxWidth="sm"
                dialogTitle="Add New Audio File"
                setOpen={setShowAddNewFileDialog}
                handleSave={handleSaveNewFile}
                handleClose={handleClose}
            >
                <AddNewAudioFile
                    newFileDetails={newFileDetails}
                    setNewFileDetails={setNewFileDetails}
                />
            </DialogWithContent>

            <DialogWithContent
                open={showEditFileDialog}
                dialogMaxWidth="xs"
                dialogTitle="Edit Audio File"
                setOpen={setShowEditFileDialog}
                handleSave={saveEditedAudioFileDetails}
                handleClose={handleClose}
            >
                <EditAudioFile
                    editAudioFileDetails={editAudioFileDetails}
                    setEditAudioFileDetails={setEditAudioFileDetails}
                />
            </DialogWithContent>

            <Grid container rowSpacing={2}>
                <Grid container item spacing={2}>
                    <Grid item xs={12}>
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            alignItems="baseline"
                            justifyContent="space-between"
                        >
                            <Typography variant="h5" component="span">
                                Existing Audio files:
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={handleAddNewFileClick}
                            >
                                Add New File
                            </Button>
                        </Stack>
                    </Grid>

                    {loading ? (
                        <img
                            className={classes.loadingImage}
                            src="../../../img/spinner.gif"
                            alt="loading"
                        />
                    ) : audioLibraryData && audioLibraryData.length > 0 ? (
                        audioLibraryData.map((x, i) => (
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={4}
                                key={x.displayName}
                                //color={i % 2 ? '#EAE9F8' : '#ddf1e9'}
                            >
                                <AudioFileCard
                                    index={i}
                                    fileDetails={x}
                                    restauId={restauHook.restauId}
                                    loadData={loadData}
                                    handleFileNameChange={
                                        saveEditedAudioFileDetails
                                    }
                                    startEditAudioFile={
                                        handleStartEditingAudioFile
                                    }
                                />
                            </Grid>
                        ))
                    ) : (
                        <Typography
                            component="div"
                            variant="h6"
                            style={{ margin: '30px 20px' }}
                        >
                            No files to display.
                        </Typography>
                    )}
                </Grid>

                <Grid container item spacing={2}>
                    <Grid item xs={12}>
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            alignItems="baseline"
                            justifyContent="space-between"
                        >
                            <Typography variant="h5" component="span">
                                Shared Audio files:
                            </Typography>
                        </Stack>
                    </Grid>

                    {loading ? (
                        <img
                            className={classes.loadingImage}
                            src="../../../img/spinner.gif"
                            alt="loading"
                        />
                    ) : sharedAudioLibraryData &&
                      sharedAudioLibraryData.length > 0 ? (
                        sharedAudioLibraryData.map((x, i) => (
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={4}
                                key={x.displayName}
                            >
                                <AudioFileCard
                                    index={i}
                                    fileDetails={x}
                                    restauId={restauHook.restauId}
                                    loadData={loadData}
                                    readOnly={true}
                                />
                            </Grid>
                        ))
                    ) : (
                        <Typography
                            component="div"
                            variant="h6"
                            style={{ margin: '30px 20px' }}
                        >
                            No files to display.
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </>
    );
}

PhoneAudioLibContent.propTypes = {};

export default PhoneAudioLibContent;
