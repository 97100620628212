import React from 'react';
import FoodCard from './FoodCard';
import FoodForm from './FoodForm';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { useFoodItem } from './hooks/useFoodItem';

function FoodEditor(props) {
    const { sectionId, food, template } = props;
    const foodItem = useFoodItem(food);
    // const { name, desc, price, file, original_price, updateItem } = foodItem;
    // const foodUpdated = {
    //     ...foodItem,
    //     price: food.price * 100,
    //     original_price: food.original_price * 100,
    // };
    const handleSave = () => {
        console.log('save food item');
    };

    return (
        <div style={{ width: 300, padding: 10 }}>
            <FoodCard
                template={template}
                sectionId={sectionId}
                food={foodItem}
            />
            <Divider />
            <FoodForm {...foodItem} />
            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSave}
            >
                Save
            </Button>
        </div>
    );
}

FoodEditor.propTypes = {};
FoodEditor.defaultProps = {
    food: {
        defaultImgSrc: 'https://sliceq.com/menu-images/stock_img.jpg',
        desc: '',
        foodId: 'KKZQ9PXJ9283R',
        hide: false,
        id: '',
        imgSrc: '',
        name: 'New Food Item',
        oos: false,
        price: 300,
        onFoodClick: () => {},
    },

    sectionId: '',
};

export default FoodEditor;
