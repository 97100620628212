import React, { useState, useContext } from 'react';
import PaymentCustomDialog from './PaymentCustomDialog';
import {
    Grid,
    FormControlLabel,
    Checkbox,
    Typography,
    IconButton,
    TextField,
    InputAdornment,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Utils from '../../../../../utils/Utils';
import { RestauInfoContext } from '../../../../../home/RestauInfoContext';

const PaymentRecord = ({
    orders,
    dispatchOrder,
    depositAmount,
    depositError,
    updateMenuSelection,
}) => {
    const { restauHook } = useContext(RestauInfoContext);
    const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
    const [paymentDialogType, setPaymentDialogType] = useState('');
    const depositPaymentTimeToDisplay = orders.deposit_payment_success_timestamp
        ? Utils.convertTimestamp(
              orders.deposit_payment_success_timestamp,
              2,
              false,
              restauHook.restauTimeZone
          )
        : null;
    const [depositPaymentTime, setDepositPaymentTime] = useState(
        orders.deposit_payment_success_timestamp
            ? new Date(orders.deposit_payment_success_timestamp)
            : null
    );
    const invoicePaymentTimeToDisplay = orders.invoice_payment_success_timestamp
        ? Utils.convertTimestamp(
              orders.invoice_payment_success_timestamp,
              2,
              false,
              restauHook.restauTimeZone
          )
        : null;

    const update_payment = (value) => {
        dispatchOrder({ type: 'UPDATE_PAYMENT', value });
    };

    const openDialog = (dialogType) => {
        setPaymentDialogType(dialogType);
        setOpenPaymentDialog(true);
    };

    return (
        <Grid container sx={{ mt: 3, mb: 2 }}>
            <Grid item xs={12}>
                <Typography color="primary" variant="h6">
                    Payment Management
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="small"
                            checked={orders.deposit_payment}
                            onChange={(e) => {
                                let depositToggle = orders.deposit_payment;
                                update_payment({
                                    deposit_payment: !orders.deposit_payment,
                                    invoice_payment: depositToggle
                                        ? orders.invoice_payment
                                        : true,
                                });
                            }}
                        />
                    }
                    label="Enable online deposit payment"
                />
            </Grid>

            <Grid item xs={12} lg={6}>
                <TextField
                    required
                    label="Deposit Amount"
                    size="small"
                    variant="outlined"
                    margin="normal"
                    sx={{ width: '90%' }}
                    value={depositAmount}
                    onChange={(e) => {
                        if (e.target.value === '')
                            dispatchOrder({
                                type: 'UPDATE_PAYMENT',
                                value: { deposit_amount: 0 },
                            });
                        else if (isNaN(Number(e.target.value))) return;
                        else
                            dispatchOrder({
                                type: 'UPDATE_PAYMENT',
                                value: {
                                    deposit_amount:
                                        Number(e.target.value) * 100,
                                },
                            });
                    }}
                    helperText={depositError ? depositError : null}
                    error={!!depositError}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                        ),
                    }}
                />
            </Grid>

            <Grid
                item
                container
                direction="row"
                sx={{ display: 'flex' }}
                xs={12}
            >
                <Typography variant="body2">
                    {`Deposit paid: ${
                        orders.deposit_payment_success ? 'Yes' : 'No'
                    }`}
                </Typography>
                {!orders.deposit_paid_via_stripe && (
                    <IconButton
                        onClick={() => {
                            openDialog('Deposit');
                        }}
                        color="primary"
                        sx={{ padding: 0.2, mr: 5 }}
                    >
                        <EditIcon sx={{ fontSize: 12 }} />
                        <Typography sx={{ fontSize: '.85rem' }}>
                            Edit
                        </Typography>
                    </IconButton>
                )}
                {orders.deposit_payment_success ? (
                    <Grid item container xs={12} direction="column">
                        <Typography variant="body2">{`- Deposit amount: $${
                            orders.deposit_amount / 100
                        }`}</Typography>
                        <Typography variant="body2">{`- Deposit paid: $${
                            orders.deposit_amount_paid / 100
                        }`}</Typography>
                        <Typography variant="body2">{`- Payment method: ${orders.deposit_payment_method}`}</Typography>
                        <Typography variant="body2">{`- Payment time: ${depositPaymentTimeToDisplay}`}</Typography>
                        <Typography variant="body2">{`- Payment collected by: ${orders.deposit_payment_collected_by}`}</Typography>
                    </Grid>
                ) : (
                    <></>
                )}
            </Grid>

            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="small"
                            checked={orders.invoice_payment}
                            onChange={(e) => {
                                update_payment({
                                    invoice_payment: !orders.invoice_payment,
                                });
                            }}
                        />
                    }
                    label="Enable online invoice payment"
                />
            </Grid>
            <Grid
                item
                container
                direction="row"
                sx={{ display: 'flex' }}
                xs={12}
            >
                <Typography variant="body2">
                    {`Invoice paid: ${
                        orders.invoice_payment_success ? 'Yes' : 'No'
                    }`}
                </Typography>
                {!orders.invoice_paid_via_stripe && (
                    <IconButton
                        onClick={() => {
                            openDialog('Invoice');
                        }}
                        color="primary"
                        sx={{ padding: 0.2, mr: 5 }}
                    >
                        <EditIcon sx={{ fontSize: 12 }} />
                        <Typography sx={{ fontSize: '.85rem' }}>
                            Edit
                        </Typography>
                    </IconButton>
                )}
                {orders.invoice_payment_success ? (
                    <Grid item container xs={12} direction="column">
                        <Typography variant="body2">{`- Amount paid: $${
                            orders.invoice_amount_paid / 100
                        }`}</Typography>
                        <Typography variant="body2">{`- Payment method: ${orders.invoice_payment_method}`}</Typography>
                        <Typography variant="body2">{`- Payment time: ${invoicePaymentTimeToDisplay}`}</Typography>
                        <Typography variant="body2">{`- Payment collected by: ${orders.invoice_payment_collected_by}`}</Typography>
                    </Grid>
                ) : (
                    <></>
                )}
            </Grid>

            {openPaymentDialog && (
                <PaymentCustomDialog
                    open={openPaymentDialog}
                    setOpenPaymentDialog={setOpenPaymentDialog}
                    orders={orders}
                    paymentType={paymentDialogType}
                    update_payment={update_payment}
                    updateMenuSelection={updateMenuSelection}
                />
            )}
        </Grid>
    );
};

export default PaymentRecord;
