import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';

const Utils = {
    isEmailValid: (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },

    convertToCamelCase: (str) => {
        return str
            .trim()
            .toLowerCase()
            .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
    },

    getAmount: (amount) => {
        if (!amount) {
            return '$0';
        }
        return '$' + amount;
    },
    getAmountFromKey: (amountObj, key) => {
        if (!amountObj || !amountObj[key]) {
            return '$0';
        }
        let amount = parseFloat(amountObj[key]).toFixed(2);
        return '$' + amount;
    },

    getDateStringFromTimestamp: (timestampInSeconds, timeZone = null) => {
        let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(timestampInSeconds);
        //console.log(d.toLocaleString())
        //return d.toLocaleString();
        return timeZone
            ? d.toLocaleString('en-US', { timeZone: timeZone })
            : d.toLocaleString();
    },
    getDateWithOffset: (days) => {
        var date = new Date();
        date.setDate(date.getDate() + days);
        return date;
    },
    getPhoneNumberInE164Format: (phNumber) => {
        if (!phNumber.trim()) return phNumber.trim();
        let number = phNumber
            .replaceAll('(', '')
            .replaceAll(')', '')
            .replaceAll('-', '')
            .replaceAll('.', '')
            .replaceAll(' ', '');
        if (number.startsWith('+1')) {
            return number;
        } else if (number.startsWith('1')) {
            return '+' + number;
        } else {
            return '+1' + number;
        }
    },
    getUTCDate: (momentObject, isEndDate = false) => {
        const formatter = isEndDate
            ? {
                  hour: 23,
                  minute: 59,
                  second: 59,
              }
            : {
                  hour: 0,
                  minute: 0,
                  second: 0,
              };

        return momentObject.set(formatter).toISOString();
    },

    getRestaurantTimeZone: async (restauId) => {
        if (!restauId) {
            return '';
        }
        const url = `menus/${restauId}/storeTimezone`;
        const admin = window.customerApp.database();
        let timeZoneRef = admin.ref(url);
        let tz = '';

        try {
            const timeZone = await timeZoneRef.once('value');
            tz = timeZone.val();
        } catch (error) {}
        return tz;
    },

    convertDateFormat: (dateStr) => {
        const date = new Date(dateStr);
        if (isNaN(date.getTime())) return '-';
        const formattedDate = date.toLocaleDateString('en-US', {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        });
        return formattedDate;
    },

    // convert Firestore timestamp to:
    // 1. moment object for MUI Datepicker
    // 2. date string "Mon, Nov 6, 2023"
    // 3. date and time string "Mon, Nov 6, 2023, 12:19PM"
    // the utcFlag is to handle whether to convert to utc or not
    convertTimestamp: (timestamp, formatType, utcFlag, timezone) => {
        let date;
        if (timestamp && timestamp._seconds) {
            date = new Date(timestamp._seconds * 1000);
            switch (formatType) {
                case 1: // for MUI Datepicker
                    if (utcFlag) return moment.utc(date).format('YYYY-MM-DD');
                    else return moment(date).tz(timezone).format('YYYY-MM-DD');

                case 2: // Display date string "Mon, Nov 6, 2023"
                    if (utcFlag)
                        return moment.utc(date).format('ddd, MMM D, YYYY');
                    else
                        return moment(date)
                            .tz(timezone)
                            .format('ddd, MMM D, YYYY');

                case 3: // Display date and time "Mon, Nov 6, 2023, 12:19PM"
                    if (utcFlag)
                        return moment
                            .utc(date)
                            .format('ddd, MMM D, YYYY, h:mmA');
                    else
                        return moment(date)
                            .tz(timezone)
                            .format('ddd, MMM D, YYYY, h:mmA');

                case 4: // Display date "2023-10-05"
                    let newDate;
                    if (utcFlag) newDate = moment.utc(date);
                    else newDate = moment(date);
                    return newDate.format('YYYY-MM-DD');

                default:
                    return null;
            }
        } else {
            switch (formatType) {
                case 1: // not a timestamp foramt but a date string, return string to MUI Datepicker
                    return timestamp;

                case 2: // convert date string to "Mon, Nov 6, 2023"
                    date = new Date(timestamp);
                    if (isNaN(date.getTime())) return '-';
                    const formattedDate = date.toLocaleDateString('en-US', {
                        weekday: 'short',
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                    });
                    return formattedDate;

                case 4: // Display date "2023-10-05"
                    if (timestamp) return timestamp.replace('/', '-');
                    else return timestamp;

                default:
                    return null;
            }
        }
    },
    getGUID: () => {
        return uuidv4();
    },

    // // convert timestamp to display date time format "2023/8/10 12:19PM"
    // convertTimestampDateFormat: (timestamp) => {
    //     let date;

    //     // Check if timestamp has a toDate method (Firestore Timestamp)
    //     if (timestamp && typeof timestamp.toDate === 'function') {
    //         date = timestamp.toDate();
    //     }
    //     // Check if timestamp has _seconds
    //     else if (timestamp && timestamp._seconds) {
    //         date = new Date(timestamp._seconds * 1000); // Convert to milliseconds
    //     }
    //     // Handle other cases
    //     else {
    //         return '-';
    //     }

    //     const year = date.getFullYear();
    //     const month = date.getMonth() + 1;
    //     const day = date.getDate();
    //     const hours = date.getHours();
    //     const minutes = date.getMinutes();
    //     const ampm = hours >= 12 ? 'PM' : 'AM';
    //     const formattedHours = hours % 12 || 12;
    //     const paddedMinutes = minutes.toString().padStart(2, '0');

    //     return `${year}/${month}/${day} ${formattedHours}:${paddedMinutes}${ampm}`;
    // },

    // convert timestamp to display date format "Mon, Nov 6, 2023"
    // convertTimeStampToDisplayFormat: (timestamp) => {
    //     if (!timestamp) return '';
    //     const date = new Date(timestamp);
    //     const options = {
    //         weekday: 'short',
    //         year: 'numeric',
    //         month: 'short',
    //         day: 'numeric',
    //     };
    //     const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
    //         date
    //     );
    //     return formattedDate;
    // },
};

export default Utils;
//module.exports = Utils;
