import { useState } from 'react';

const defaultHoliday = false;

export function useHoliday(current = defaultHoliday) {
    const [holiday, setHoliday] = useState(current);

    const toggleHoliday = (e) => {
        setHoliday(e.target.checked);
    };

    const resetHoliday = () => {
        setHoliday(current);
    };

    return {
        holiday,
        toggleHoliday,
        resetHoliday,
    };
}
