import { Divider } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const SortDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: '5px', //theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '5px', //theme.spacing(1),
    },
}));

const SortDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        //color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

SortDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

//styles to apply while dragging
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '5px',
    display: 'inline-flex',
    alignItems: 'center',

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'none',

    // styles we need to apply on draggables
    ...draggableStyle,
});

//styles to apply after dropping
// const getListStyle = (isDraggingOver) => ({
//     //background: isDraggingOver ? 'lightblue' : 'lightgrey',
//     //padding: grid,
//     //width: 250,
//     listStyle: 'none',
// });

export default function SortItemsInDialog(props) {
    const handleClose = () => {
        props.setOpen(false);
    };

    function handleOnDragEnd(result) {
        //console.log('Dragged n Dropped: ', result);
        let tempData = [...props.itemDataToSort];
        const [movedItem] = tempData.splice(result.source.index, 1);
        tempData.splice(result.destination.index, 0, movedItem);
        props.setItemDataToSort([...tempData]);
    }

    async function handleSave() {
        /*  console.log(
            'Item order after finalizing Sort:',
            props.itemDataToSort.map((x) => x.id)
        ); */
        handleClose();
        props.handleSave();
    }

    return (
        <div>
            <SortDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <SortDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    Sort Items
                </SortDialogTitle>

                <DialogContent dividers={true}>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="itemsToSort">
                            {(provided, snapshot) => (
                                <ul
                                    className="itemsToSort"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{ listStyle: 'none' }}
                                >
                                    {props.itemDataToSort.map((item, index) => {
                                        return (
                                            <Draggable
                                                key={item.id}
                                                draggableId={item.id}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <>
                                                        <li
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided
                                                                    .draggableProps
                                                                    .style
                                                            )}
                                                        >
                                                            <DragIndicatorIcon />
                                                            {item.name}
                                                        </li>
                                                        <Divider
                                                            sx={{ margin: 0 }}
                                                        />
                                                    </>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    </DragDropContext>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button autoFocus onClick={handleSave} variant="contained">
                        Save
                    </Button>
                </DialogActions>
            </SortDialog>
        </div>
    );
}
