import React from 'react';
import { Grid } from '@mui/material';
import BrandingPane from './BrandingPane';

function BrandingDesktop(props) {
    const { children } = props;
    return (
        <>
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
            >
                <Grid item md={6} display="flex" justifyContent="center">
                    <div style={{ width: '100%', maxWidth: 350 }}>
                        {children}
                    </div>
                </Grid>
                <Grid item md={6}>
                    <BrandingPane />
                </Grid>
            </Grid>
        </>
    );
}

BrandingDesktop.propTypes = {};

export default BrandingDesktop;
