import { useContext } from 'react';
import { RestauInfoContext } from '../home/RestauInfoContext';
import Config from '../config/Config';
import axios from 'axios';
import Utils from '../utils/Utils';

const getAudioLibraryDataFromDb = async (payload) => {
    const token = await window.customerApp.auth().currentUser.getIdToken();
    var config = {
        headers: {
            token: token,
            'Content-Type': 'application/json',
        },
    };

    //function to fetch the Phone Settings from databasse
    return await axios.post(Config.getAudioLibraryData, payload, config);
};

const addNewAudioFileToDb = async (
    restauId,
    audioFile,
    audioFileName,
    shared
) => {
    const token = await window.customerApp.auth().currentUser.getIdToken();
    var config = {
        headers: {
            token: token,
            'Content-Type': 'multipart/form-data',
        },
    };
    const formData = new FormData();
    formData.append('restauId', restauId);
    formData.append('fileId', Utils.getGUID());
    formData.append('displayName', audioFileName);
    formData.append('audioFile', audioFile, audioFile.name);
    formData.append('shared', shared);
    return await axios.post(Config.addNewAudioFileinLibrary, formData, config);
};

const usePhoneMenu = () => {
    const { restauHook } = useContext(RestauInfoContext);
    //const [audioLibraryData, setAudioLibraryData] = useState([]);

    const loadAudioLibraryData = async (restauId) => {
        //debugger;
        let reqBody = {
            restauId: restauId,
            includeShared: true,
        };

        let response = await getAudioLibraryDataFromDb(reqBody).catch((err) => {
            //debugger;
            console.log(err);
            throw new Error(err.toString());
        });

        //debugger;
        console.log('Response: ', response);
        if (response.data.status) {
            return response.data.data;
        } else {
            throw new Error('Failed to fetch data.');
        }
    };

    const addNewAudioFile = async (
        restauId,
        newAudioFile,
        newAudioFileName,
        shared
    ) => {
        //debugger;

        let response = await addNewAudioFileToDb(
            restauId,
            newAudioFile,
            newAudioFileName,
            shared
        ).catch((err) => {
            //debugger;
            console.log(err);
            throw new Error(err.toString());
        });

        //debugger;
        console.log('Response: ', response);
        if (response && response.data.status) {
            return response.data;
        } else {
            throw new Error('Failed to save new file.');
        }
    };

    // useEffect(() => {
    //     if (restauHook.restauId) loadAudioLibraryData(restauHook.restauId);
    // }, [restauHook.restauId]);

    return {
        loadAudioLibraryData,
        addNewAudioFile,
    };
};

export default usePhoneMenu;
