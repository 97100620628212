import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import AssignedVisOverrides from '../components/AssignOverridesToMenu/AssignedVisOverrides';
import { RestauInfoContext } from '../../../home/RestauInfoContext';
import Config from '../../../config/Config';

//To display the list of assigned Overrides in Food Dialog
function AssignedOverridesAtFoodItem(props) {
    const { openFood } = props;

    const { restauHook } = useContext(RestauInfoContext);
    const [assignedVisOverrideForFoodItem, setAssignedVisOverrideForFoodItem] =
        useState({});
    const [openAssignOverridesDialog, setOpenAssignOverridesDialog] =
        useState(false);
    const [allOverrideMapping, setAllOverrideMapping] = useState({});
    const [loading, setLoading] = useState(false);

    function loadData() {
        //console.log(props.openFood);
        setLoading(true);
        loadVisOverridesForFood();
        //setLoading(false);
    }

    const loadVisOverridesForFood = async () => {
        //debugger;
        let payload = {
            restauId: restauHook.restauId,
            categoryId: openFood.value.sectionId,
            foodItemId: openFood.value.food.id,
        };

        const token = await window.customerApp.auth().currentUser.getIdToken();
        var config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };
        console.log('Payload: ', payload);
        //function to fetch the Visibility Overrides from databasse
        axios
            .post(Config.getVisibilityOverrideForFoodItem, payload, config)
            .then((response) => {
                console.log('Response: ', response);
                if (response && response.status === 200) {
                    setAllOverrideMapping(response.data);
                    let mappedOverride = Object.values(response.data).filter(
                        (x) => x.isMapped
                    )[0];
                    setAssignedVisOverrideForFoodItem({ ...mappedOverride });
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    async function handleAssignedOverrideUpdate(assignedOverride) {
        //debugger;
        //console.log('Assigned Override: ', assignedOverride);
        setAssignedVisOverrideForFoodItem({ ...assignedOverride });
        let tempObj = { ...allOverrideMapping };
        Object.keys(tempObj).forEach((key) => {
            tempObj[key] = { ...tempObj[key], isMapped: false };
        });
        //console.log('Reset all Overrides: ', tempObj);
        setAllOverrideMapping({
            ...tempObj,
            [assignedOverride.id]: {
                ...assignedOverride,
                isMapped: true,
            },
        });

        //console.log('Stations to update in db: ', stationNamesToMap);
        //props.openFood.value.food.station = [...stationNamesToMap];
        let val = openFood.value;
        if (assignedOverride.id) {
            val.food.visiblityOverrideId = assignedOverride.id;
        } else {
            delete val.food.visiblityOverrideId;
        }

        openFood.setValue(val);
    }

    useEffect(() => {
        console.log(openFood);
        setAssignedVisOverrideForFoodItem({});
        loadData();
    }, []);

    return (
        <AssignedVisOverrides
            setOpenAssignOverridesDialog={setOpenAssignOverridesDialog}
            openAssignOverridesDialog={openAssignOverridesDialog}
            loading={loading}
            assignedVisOverride={assignedVisOverrideForFoodItem}
            setAssignedVisOverride={setAssignedVisOverrideForFoodItem}
            allOverrideMapping={allOverrideMapping}
            handleAssignedOverrideUpdate={handleAssignedOverrideUpdate}
        />
    );
}

AssignedOverridesAtFoodItem.propTypes = {
    openFood: PropTypes.shape({
        value: PropTypes.shape({
            food: PropTypes.shape({
                id: PropTypes.string,
                visiblityOverrideId: PropTypes.string,
            }),
            sectionId: PropTypes.string,
        }),
        setValue: PropTypes.func,
    }),
};

export default AssignedOverridesAtFoodItem;
