import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

const useStyles = makeStyles(() => ({
    paper: { minWidth: '50%' },
}));
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function WelcomeDialog({ isOpen, setOpen, restauId }) {
    let classes = useStyles();
    let customerURL = 'http://strideq.com/' + restauId;
    return (
        <div>
            <Dialog
                onClose={console.log('tesdst')}
                aria-labelledby="customized-dialog-title"
                open={isOpen}
                classes={{ paper: classes.paper }}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={(e) => {
                        //handleClose();
                        setOpen(false);
                    }}
                >
                    Welcome to StrideQ
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Your store is ready
                        <ul>
                            <li>
                                Check the restaurant profile,menu, sales tax etc
                                at left menu tab
                            </li>
                            <li>Your daily reports</li>
                            <li>
                                {' '}
                                Access{' '}
                                <a href="http://merchant.strideq.com">
                                    merchant app{' '}
                                </a>{' '}
                                here using same credentials{' '}
                            </li>
                            <li>
                                Here is your{' '}
                                <a href={customerURL}>customer App</a>
                            </li>
                        </ul>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={(e) => {
                            setOpen(false);
                            // handleClose();
                        }}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
