import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import AuditLog from './AuditLog/auditLog';
import CRM from './crm/CRM';
import Delivery from './delivery/Delivery';
import QRPoster from './design/containers/QRPoster';
import QRRoot from './design/containers/QRRoot';
import QRTable from './design/containers/QRTable';
import DineIn from './Dinein/DineIn';
import Holiday from './holiday/Holiday';
import HomeSummary from './home/HomeSummary';
import Items from './items/Items';
import MainMenu from './Menu/MainMenu';
import MenuComp from './Menu/MenuComp';
import MenuSettings from './Menu/MenuSettings/MenuSettings';
import ModifierGroups from './Menu/ModifierGroup/ModifierGroups';
import StationMappings from './Menu/StationMapping/StationMappings';
import VisibilityOverrideRoot from './Menu/VisiblityOverride/containers/VisibilityOverrideRoot';
import Login from './Login';
import OrderSearch from './orderSearch/OrderSearch';
import POS from './pos/POS';
import Profile from './profile/Profile';
import Promotions from './promotions/Promotions';
import Signup from './Signup';
import SignupStripe from './singup/SignupStripe';
import SignupSuccess from './singup/SignupSuccess';
import Subscription from './subscription/Subscription';
import Summary from './summary/Summary';
import Tax from './tax/Tax';
import PhoneMenuRoot from './PhoneMenu/Containers/PhoneMenuRoot';
import PhoneSettingsRoot from './PhoneMenu/Components/PhoneSettings/Containers/PhoneSettingsRoot';
import PhoneReportsRoot from './PhoneMenu/Components/PhoneReports/Containers/PhoneReportsRoot';
import PhoneLogsRoot from './PhoneMenu/Components/PhoneLogs/Containers/PhoneLogsRoot';
import ResetPassword from './ResetPassword';
import CateringRoot from './Catering/Containers/CateringRoot';
import CateringDashboardRoot from './Catering/Components/CateringDashboard/Containers/CateringDashboardRoot';
import CateringSettingRoot from './Catering/Components/CateringSetting/Containers/CateringSettingRoot';
import CateringEventRoot from './Catering/Components/CateringEvent/Containers/CateringEventRoot';
import CateringSearchRoot from './Catering/Components/CateringSearch/Containers/CateringSearchRoot';
import MultilocationRoot from './Multilocation/MultilocationRoot.js';
import PhoneCallersRoot from './PhoneMenu/Components/PhoneCallers/Containers/PhoneCallersRoot';
import PhoneAudioLibRoot from './PhoneMenu/Components/PhoneAudioLibrary/Containers/PhoneAudioLibRoot';

const AppRoutes = () => (
    <BrowserRouter history={history}>
        <Routes>
            <Route element={<App />}>
                <Route
                    exact
                    path="/"
                    element={<HomeSummary title="Dashboard" />}
                />
                <Route
                    path="/home"
                    element={<HomeSummary title="Dashboard" />}
                />
                <Route path="/reports" element={<Summary title="Summary" />} />
                <Route path="/items" element={<Items title="Items" />} />
                {/* <Route path="/menu" element={<MenuComp />} /> */}
                <Route path="/menu" element={<MainMenu title="Menu" />}>
                    <Route exact path="/menu" element={<MenuComp />} />
                    <Route path="/menu/fullmenu" element={<MenuComp />} />
                    <Route
                        path="/menu/modifiers"
                        element={<ModifierGroups />}
                    />
                    <Route
                        path="/menu/stations"
                        element={<StationMappings />}
                    />
                    <Route
                        path="/menu/visibility"
                        element={<VisibilityOverrideRoot />}
                    />
                    <Route path="/menu/settings" element={<MenuSettings />} />
                </Route>
                <Route path="/tax" element={<Tax title="Sales Tax" />} />
                <Route path="/profile" element={<Profile title="Profile" />} />
                <Route path="/dinein" element={<DineIn title="Dine In" />} />
                <Route
                    path="/messaging"
                    element={<Promotions title="Custom Messaging" />}
                />
                <Route path="/hours" element={<Holiday title="Hours" />} />
                <Route
                    path="/86report"
                    element={<AuditLog title="86 Report" />}
                />
                <Route
                    path="/subscription"
                    element={<Subscription title="Subscription" />}
                />
                <Route
                    path="/orders"
                    element={<OrderSearch title="Order Search" />}
                />
                <Route path="/pos" element={<POS title="PoS Settings" />} />
                <Route
                    path="/delivery"
                    element={<Delivery title="Delivery Settings" />}
                />
                {/* <Route
                    path="/phone"
                    element={<PhoneSettingsRoot title="Phone Settings" />}
                /> */}
                <Route
                    path="/phone"
                    element={<PhoneMenuRoot title="Phone Menu" />}
                >
                    <Route exact path="/phone" element={<PhoneReportsRoot />} />
                    <Route path="/phone/logs" element={<PhoneLogsRoot />} />
                    <Route
                        path="/phone/reports"
                        element={<PhoneReportsRoot />}
                    />
                    <Route
                        path="/phone/callers"
                        element={<PhoneCallersRoot />}
                    />
                    <Route
                        path="/phone/settings"
                        element={<PhoneSettingsRoot />}
                    />
                    <Route
                        path="/phone/audioLibrary"
                        element={<PhoneAudioLibRoot />}
                    />
                </Route>
                <Route
                    path="/catering"
                    element={<CateringRoot title="Catering" />}
                >
                    <Route
                        exact
                        path="/catering"
                        element={<CateringDashboardRoot />}
                    />
                    <Route
                        exact
                        path="/catering/dashboard"
                        element={<CateringDashboardRoot />}
                    />
                    <Route
                        exact
                        path="/catering/search"
                        element={<CateringSearchRoot />}
                    />
                    <Route
                        path="/catering/setting"
                        element={<CateringSettingRoot />}
                    />
                    <Route
                        path="/catering/newevent"
                        element={<CateringEventRoot />}
                    />
                    <Route
                        path="/catering/:RestauId/:CateringInquiryId"
                        element={<CateringEventRoot />}
                    />
                </Route>
                <Route
                    path="/crm"
                    element={<CRM title="Customer's Messages" />}
                />
                <Route path="/design" element={<QRRoot title="Design" />}>
                    <Route exact path="/design" element={<QRTable />} />
                    <Route path="/design/table" element={<QRTable />} />
                    <Route path="/design/poster" element={<QRPoster />} />
                </Route>
                <Route
                    path="/multilocation"
                    element={
                        <MultilocationRoot title="Multilocation Management" />
                    }
                />
            </Route>
            <Route path="/login" element={<Login />} />

            <Route path="/resetpassword" element={<ResetPassword />} />

            <Route path="/signup" element={<Signup />} />
            <Route path="/signupstorei/:rId" element={<SignupStripe />} />
            <Route
                path="/signupstoresuccess/:rId"
                element={<SignupSuccess />}
            />
        </Routes>
    </BrowserRouter>
);

export default AppRoutes;
