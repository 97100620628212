import { Button, CircularProgress } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import Config from '../config/Config';
import { RestauInfoContext } from '../home/RestauInfoContext.js';
import AuditLogButton from './AutitLogButton';

export default function AuditInput(props) {
    const resauInfoContext = useContext(RestauInfoContext);
    const restauHook = resauInfoContext.restauHook;
    const [loading, setLoading] = useState(false);
    const [summaryLoader, setSummaryLoader] = React.useState(false);
    const { summaryHook, auditHook } = props;

    function setEndDate(date) {
        auditHook.setStartDate(date);
        if (auditHook.endDate < date) {
            auditHook.setEndDate(date);
        }
    }
    function getDays(days) {
        var date = new Date();
        date.setDate(date.getDate() + days);
        return date;
    }
    const getUTCDate = (momentObject, isEndDate = false) => {
        const formatter = isEndDate
            ? {
                  hour: 23,
                  minute: 59,
                  second: 59,
              }
            : {
                  hour: 0,
                  minute: 0,
                  second: 0,
              };

        return momentObject.set(formatter).toISOString();
    };
    async function loadData(restauHook) {
        auditHook.setOOSLog('');
        auditHook.setHideLog('');
        auditHook.setPauseLog('');
        setLoading((prevstatus) => !prevstatus);
        const token = await window.customerApp.auth().currentUser.getIdToken();
        let config = {
            headers: {
                token: token,
            },
        };
        setSummaryLoader(true);
        summaryHook.setRestauId(restauHook.restauId);
        const startDate = getUTCDate(auditHook.startDate);
        const endDate = getUTCDate(auditHook.endDate, true);

        if (auditHook.audit == 'Out of Stocks') {
            axios
                .post(
                    Config.getAuditLogOOS,
                    { restauId: restauHook.restauId, startDate, endDate },
                    config
                )
                .then(function (response) {
                    auditHook.setOOSLog(response.data.auditLog);
                    setLoading((prevstatus) => !prevstatus);
                })
                .catch(function (error) {
                    auditHook.setOOSLog('');
                    setLoading((prevstatus) => !prevstatus);
                });
        } else if (auditHook.audit == 'Hidden Menu') {
            axios
                .post(
                    Config.getAuditLogHide,
                    { restauId: restauHook.restauId, startDate, endDate },
                    config
                )
                .then(function (response) {
                    console.log(response.data.auditLog);
                    auditHook.setHideLog(response.data.auditLog);
                    setLoading((prevstatus) => !prevstatus);
                })
                .catch(function (error) {
                    auditHook.setHideLog('');
                    setLoading((prevstatus) => !prevstatus);
                });
        } else if (auditHook.audit == 'Paused Order') {
            axios
                .post(
                    Config.getAuditLogPause,
                    { restauId: restauHook.restauId, startDate, endDate },
                    config
                )
                .then(function (response) {
                    console.log(response.data.auditLog);
                    auditHook.setPauseLog(response.data.auditLog);
                    console.log(auditHook.pauseLog);
                    setLoading((prevstatus) => !prevstatus);
                })
                .catch(function (error) {
                    auditHook.setPauseLog('');
                    setLoading((prevstatus) => !prevstatus);
                });
        }
    }

    function GenerateReport(restauHook) {
        loadData(restauHook);
    }

    useEffect(() => {
        console.log('Loading Summary Report');
        console.log(summaryHook);
        console.log(restauHook);
        if (
            summaryHook.restauId != null &&
            summaryHook.restauId != restauHook.restauId
        ) {
            loadData(restauHook);
        }
    }, [restauHook.restauId]);

    return (
        <div>
            <div style={{ flexDirection: 'row', width: 'auto' }}>
                <div>
                    <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-label">
                            Report
                        </InputLabel>

                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={auditHook.audit}
                            label="Audit"
                            onChange={(e) => auditHook.setAudit(e.target.value)}
                            margin="dense"
                            size="small"
                        >
                            <MenuItem value={'Out of Stocks'}>
                                Out of Stocks
                            </MenuItem>
                            <MenuItem value={'Hidden Menu'}>
                                Hidden Menu
                            </MenuItem>
                            <MenuItem value={'Paused Order'}>
                                Paused Order
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'baseline',
                        gap: 12,
                        marginTop: '15px',
                    }}
                >
                    <DatePicker
                        label="From Date"
                        value={auditHook.startDate}
                        autoOk
                        inputVariant="outlined"
                        size="small"
                        margin="dense"
                        onChange={(date) => setEndDate(date)}
                        minDate={moment(getDays(-365))}
                        maxDate={moment(new Date())}
                        selected={moment(auditHook.startDate)}
                        renderInput={(props) => <TextField {...props} />}
                    />

                    <DatePicker
                        label="To Date"
                        value={auditHook.endDate}
                        autoOk
                        inputVariant="outlined"
                        size="small"
                        margin="dense"
                        onChange={(date) => auditHook.setEndDate(date)}
                        minDate={moment(
                            auditHook.startDate
                                ? auditHook.startDate
                                : getDays(-180)
                        )}
                        maxDate={moment(new Date())}
                        selected={moment(auditHook.endDate)}
                        renderInput={(props) => <TextField {...props} />}
                    />
                    <Button
                        color="primary"
                        style={{
                            height: 'max-content',
                        }}
                        variant="contained"
                        onClick={() => GenerateReport(restauHook)}
                    >
                        Audit Report
                    </Button>
                </div>
            </div>
            {loading ? (
                <CircularProgress color="secondary" />
            ) : (
                <AuditLogButton auditHook={auditHook} />
            )}
        </div>
    );
}
