import React from 'react';
import { useNavigate } from 'react-router';
import BrandingTemplate from './components/branding/BrandingTemplate';
import ResetPasswordForm from './components/forms/ResetPasswordForm';

function ResetPassword(props) {
    const queryParam = new URLSearchParams(location.search);
    //debugger;

    let navigate = useNavigate();
    //let { emailId } = useParams();

    const [loaded, setLoaded] = React.useState(false);
    const [email, setEmail] = React.useState(queryParam.get('email'));
    const [message, setMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('');

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            handle(event);
        }
    }
    async function handlePasswordReset(e) {
        e.preventDefault();
        setLoaded(true);
        setMessage('');
        try {
            await window.customerApp.auth().sendPasswordResetEmail(email);
            //TODO : check response from above line. And see what it received.
            console.log('Password reset email sent');
            setMessage(
                'We have emailed your password reset link. Visit https://admin.strideq.com after resetting the password.'
            );
            setSeverity('success');
            setLoaded(false);
        } catch {
            console.log('Password reset email unsuccessful');
            setMessage(
                'Sorry email delivery was not successful. Ensure email id is correct. Reach support@strideq.com if you need assistance.'
            );
            setSeverity('error');
            setLoaded(false);
        }
    }

    return (
        <BrandingTemplate>
            <ResetPasswordForm
                email={email}
                setEmail={setEmail}
                loaded={loaded}
                message={message}
                severity={severity}
                onKeyPress={handleKeyPress}
                onPasswordReset={handlePasswordReset}
            />
        </BrandingTemplate>
    );
}

export default ResetPassword;
