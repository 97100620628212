import React, { useState, useEffect, useContext } from 'react';
import { useStyles } from '../../../../../home/useStyles';
import styled from 'styled-components';
import { useModifiers } from './useModifiers';
import { Choices } from './Choices';
import { Toppings } from './Toppings';
import { ModifierGroup } from './ModifierGroup/ModifierGroup';
import { QuantityInput } from './QuantityInput';
import ItemDiscount from './ItemDiscount';
import {
    validateOrderSelection,
    getModifierPrice,
    getModifierStr,
} from './ModifierGroup/ModifierUtil';
import formatPrice from '../../../../../utils/formatPrice';
import CateringContext from '../../../../CateringContext';
import { TextField, Dialog, DialogContent, DialogActions } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';

export const FoodLabel = styled.div`
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.75);
`;

export const ConfirmButton = styled.div`
    margin: 10px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    border-radius: 5px;
    padding: 7px;
    text-align: center;
    width: 200px;
    cursor: pointer;
    background-color: #7777f7;
    ${({ disabled }) =>
        disabled &&
        `
    opactity: .5; 
    background-color: grey; 
    pointer-events: none; 
  `}
`;

const DialogBanner = styled.div`
    min-height: 200px;
    ${({ img }) =>
        img ? `background-image: url(${img});` : `min-height: 50px;`}
    background-position: center;
    background-size: cover;
    @media (max-width: 720px) {
        min-height: 170px;
    }
`;

const DialogBannerName = styled(FoodLabel)`
    padding: 10px 20px 10px;
    box-shadow: 0px 2px 0px 0px #e7e7e7;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* or 150% */
    position: relative;
    z-index: 99 !important;
    letter-spacing: -0.01em;

    color: #222c31;
`;

const FoodDescription = styled.div`
    display: flex;
    padding: 20px 0px 0px;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: -0.01em;
    color: #7c7d7d;
`;

export const LabelContainer = styled.div`
    display: flex;
    height: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #222c31;
`;

export function getPriceWithToppings(order) {
    var toppingsPrice = 0;
    if (order.toppings) {
        for (var t of order.toppings) {
            if (t.checked) {
                if ('price' in t) {
                    toppingsPrice += t.price;
                }
            }
        }
    }
    return order.base_price + toppingsPrice;
}

const FoodDialog = ({
    open,
    setOpenFoodDialog,
    orders,
    dispatchOrder,
    openFood,
    setOpenFood,
    menuLoading,
    updateMenuSelection,
    updatePricing,
    defaultTax,
    disableOrdering,
    areOrdersPaused,
    keyToEdit,
    setKeyToEdit,
}) => {
    const { setSnackbarOpen, setSnackbarMessage } = useContext(CateringContext);
    const [quantity, setQuantity] = useState(
        openFood.quantity ? openFood.quantity : 1
    );
    const [toppings, setToppings] = useState(
        openFood.toppings ? openFood.toppings : undefined
    );
    const [choiceRadio, setChoice] = useState(openFood.choice);
    const [notes, setNotes] = useState(openFood.notes ? openFood.notes : '');
    const [discount, setDiscount] = useState(
        openFood.discount
            ? openFood.discount
            : {
                  amount: 0,
                  discount_type: 'Dollar',
                  amount_off: 0,
              }
    );
    const [finalPrice, setFinalPrice] = useState(openFood.price);
    const modifiersHook = useModifiers(openFood.modifierGroup);
    const isEditing = keyToEdit !== null;
    const classes = useStyles();

    const close = () => {
        setKeyToEdit(null);
        setOpenFood();
        setOpenFoodDialog(false);
    };

    const order = {
        ...openFood,
        quantity: quantity,
        toppings: toppings,
        choice: choiceRadio,
        notes: notes,
        modifierGroup: modifiersHook.value,
        base_price: openFood.base_price ? openFood.base_price : openFood.price,
    };

    if ('choices' in order) {
        for (var c of order.choices) {
            if (c.name === order.choice) {
                order.choiceId = c.id;
                if ('price' in c) {
                    order.price = c.price;
                    order.base_price = c.price;
                }
            }
        }
    }

    const calcFinal = () => {
        return (
            getPriceWithToppings(order) +
            getModifierPrice(order) -
            discount.amount
        );
    };
    useEffect(() => {
        setFinalPrice(calcFinal());
    }, [order, discount]);

    const checkTopping = (index) => {
        const newToppings = [...toppings];
        newToppings[index].checked = !newToppings[index].checked;
        setToppings(newToppings);
    };

    /*
     *  base_price : price of item in DB without any modifier/topping
     *  original_price: price of item based on base_price with modifier/topping
     *  price : original_price - discounted amount. For % discount we take original_price in consideration.
     */
    async function editOrder() {
        if (!validateOrderSelection(order)) {
            return;
        }
        var newOrder = {
            id: order.id,
            quantity: order.quantity,
            name: order.name,
            base_price: order.base_price,
            original_price:
                getPriceWithToppings(order) + getModifierPrice(order),
            price: finalPrice,
            discount: discount,
            key: order.key,
        };

        if (order.station) {
            newOrder['station'] = order.station;
        }
        if (order.img) {
            newOrder['img'] = order.img;
        }
        if (order.desc) {
            newOrder['desc'] = order.desc;
        }

        if (order.toppings) {
            newOrder['toppings'] = order.toppings;
        }

        if (order.choice) {
            newOrder['choiceId'] = order.choiceId;
            newOrder['choice'] = order.choice;
            newOrder['choices'] = order.choices;
        }

        if (order.notes && order.notes !== '') {
            newOrder['notes'] = order.notes;
        }
        if (order.modifierGroup) {
            newOrder['modifierGroup'] = order.modifierGroup;
            newOrder['modifiersStr'] = getModifierStr(order);
        }

        let newOrders = cloneDeep(orders);
        if (keyToEdit !== null) {
            newOrders.items[keyToEdit] = {
                ...newOrder,
            };
            newOrders = updatePricing(newOrders, newOrders.items);
            try {
                // send to DB first before updating state
                await updateMenuSelection(newOrders);
                dispatchOrder({ type: 'UPDATE_ALL', newOrders });
                close();
            } catch (e) {
                console.log('Error editing order: ', e);
                setSnackbarMessage(
                    'Issue editing item. Please contact support if the issue persists.'
                );
                setSnackbarOpen(true);
            }
        }
    }

    async function addToOrder() {
        if (!validateOrderSelection(order)) {
            return;
        }

        var newOrder = {
            id: order.id,
            quantity: order.quantity,
            name: order.name,
            base_price: order.base_price,
            original_price:
                getPriceWithToppings(order) + getModifierPrice(order),
            price: finalPrice,
            discount: discount,
        };

        if (order.station) {
            newOrder['station'] = order.station;
        }

        if (order.img) {
            newOrder['img'] = order.img;
        }
        if (order.desc) {
            newOrder['desc'] = order.desc;
        }

        if (order.toppings) {
            newOrder['toppings'] = order.toppings;
        }

        if (order.choice) {
            newOrder['choiceId'] = order.choiceId;
            newOrder['choice'] = order.choice;
            newOrder['choices'] = order.choices;
        }

        if (order.notes && order.notes !== '') {
            newOrder['notes'] = order.notes;
        }
        if (order.modifierGroup) {
            newOrder['modifierGroup'] = order.modifierGroup;
            newOrder['modifiersStr'] = getModifierStr(order);
        }

        const itemID = `${Date.now().toString(36)}-${Math.random()
            .toString(36)
            .substring(2, 5)}`;
        // check if orders is undefined. If it is, create the default structure;
        let newOrders;
        if (!orders) {
            newOrders = {
                items: {
                    [itemID]: {
                        ...newOrder,
                        key: itemID,
                    },
                },
                discount: {
                    discount_type: 'Dollar',
                    amount: 0,
                    amount_off: 0,
                },
                payment_subtotal_original: 0,
                payment_subtotal_before_discount: 0,
                payment_subtotal: 0,
                payment_salestax: 0,
                payment_salestax_percent: defaultTax,
                payment_total: 0,
                tip: 0,
                orderInstruction: '',
                orderInstructionInternal: '',
                deposit_payment: false,
                deposit_amount_paid: 0,
                deposit_amount: 0,
                deposit_payment_success: false,
                deposit_payment_method: '',
                deposit_payment_success_timestamp: '',
                deposit_payment_collected_by: '',
                deposit_paid_via_stripe: false,
                invoice_payment: false,
                invoice_amount_paid: 0,
                invoice_payment_success: false,
                invoice_payment_method: '',
                invoice_payment_success_timestamp: '',
                invoice_payment_collected_by: '',
                invoice_paid_via_stripe: false,
                orderType: 'catering',
            };
        } else {
            newOrders = cloneDeep(orders);
            newOrders.items[itemID] = {
                ...newOrder,
                key: itemID,
            };
        }
        newOrders = updatePricing(newOrders, newOrders.items);
        try {
            // send to DB first before updating state
            await updateMenuSelection(newOrders);
            dispatchOrder({ type: 'UPDATE_ALL', newOrders });
            close();
        } catch (e) {
            console.log('Error adding new order: ', e);
            setSnackbarMessage(
                'Issue adding item. Please contact support if the issue persists.'
            );
            setSnackbarOpen(true);
        }
    }

    return (
        <>
            {menuLoading ? (
                <img
                    className={classes.loadingImage}
                    src="/img/spinner.gif"
                    alt="loading"
                />
            ) : (
                <Dialog open={open} fullWidth sx={{ zIndex: '9999' }}>
                    {openFood.img && (
                        <DialogBanner img={openFood.img}></DialogBanner>
                    )}

                    <DialogBannerName>
                        <div style={{ marginRight: '30px' }}>
                            <b> {openFood.name} </b>{' '}
                        </div>
                    </DialogBannerName>

                    <DialogContent>
                        {openFood.desc && (
                            <FoodDescription>{openFood.desc}</FoodDescription>
                        )}

                        <QuantityInput
                            quantity={quantity}
                            setQuantity={setQuantity}
                        />

                        {openFood.choices && (
                            <Choices
                                key="choiceKey"
                                openFood={openFood}
                                choiceRadio={choiceRadio}
                                setChoice={setChoice}
                            />
                        )}

                        {openFood.toppings && (
                            <Toppings
                                key="toppingsKey"
                                topping={toppings}
                                checkTopping={checkTopping}
                                openFood={openFood}
                            />
                        )}

                        {openFood.modifierGroup && (
                            <ModifierGroup modifierHook={modifiersHook} />
                        )}

                        <ItemDiscount
                            discount={discount}
                            setDiscount={setDiscount}
                            order={order}
                            finalPrice={finalPrice}
                            setFinalPrice={setFinalPrice}
                        />

                        <TextField
                            label="Special Instructions"
                            size="small"
                            variant="outlined"
                            margin="normal"
                            multiline
                            minRows={2}
                            sx={{ width: '100%', mt: 5 }}
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <ConfirmButton
                            onClick={isEditing ? editOrder : addToOrder}
                            disabled={
                                disableOrdering ||
                                areOrdersPaused ||
                                (openFood.choices && !choiceRadio)
                            }
                        >
                            {isEditing ? `Update order ` : `Add to order `}
                            {order.price
                                ? formatPrice(
                                      (order.quantity * finalPrice) / 100
                                  )
                                : ''}
                        </ConfirmButton>
                        <ConfirmButton
                            onClick={close}
                            style={{ width: '80px' }}
                        >
                            Close
                        </ConfirmButton>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default FoodDialog;
