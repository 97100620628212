import React from 'react';
/* import PropTypes from 'prop-types'; */
import ButtonWithIcon from '../../../components/common/ButtonWithIcon';
import ExistingOverrides from '../containers/ExistingOverrides';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import { useContext } from 'react';
import VisOverrideContext from '../VisOverrideContext';
import { useStyles } from '../../../home/useStyles';
import OverrideDataEditor from './OverrideEditor/OverrideDataEditor';
import DialogWithContent from '../../../components/common/DialogWithContent/DialogWithContent';

//The main content which is displayed in the "Visibility Overrides" tab
function VisibilityOverrideContent() {
    const classes = useStyles();

    //const [openOverrideEditorDialog, setOpenOverrideEditorDialog] = useState(false);
    const {
        openEditOverrideDialog,
        setOpenEditOverrideDialog,
        handleAddNewVisOverride,
        openNewOverrideDialog,
        setOpenNewOverrideDialog,
        setOpenVisOverrideData,
        loading,
        handleEditVisOverride,
        openVisOverrideData,
    } = useContext(VisOverrideContext);

    const validateOverrideData = (data) => {
        console.log('Override data to validate: ', data);
        //debugger;
        //Validate if the override has a name
        if (data.name === '' || data.name === undefined) {
            throw new Error('Override Name cannot be empty.');
        }

        //validate if the override has atleast one rule
        if (!data.rules || data.rules.length === 0) {
            throw new Error('Override should have atleast one rule.');
        }

        //validate if the override rules have necessary data
        data.rules.forEach((rule) => {
            if (!rule.type || !rule.status || !rule.dateTimeType) {
                throw new Error(
                    'Override Rules should have all the fields data.'
                );
            }
        });

        //validate if both DineIn & ToGo rules are added, when added seperately. 'Only Dine-In' 'Only ToGo' 'DineIn & ToGo'
        if (data.rules.filter((x) => x.type === 'DineIn & ToGo').length === 0) {
            if (
                data.rules.filter((x) => x.type === 'Only DineIn').length ===
                    0 ||
                data.rules.filter((x) => x.type === 'Only ToGo').length === 0
            ) {
                throw new Error(
                    'Override should have both DineIn & ToGo rules, when added seperately.'
                );
            }
        }
    };

    const handleAddNewOverride = (data) => {
        validateOverrideData(data);
        handleAddNewVisOverride(data);
    };

    const showNewOverrideDialog = () => {
        setOpenVisOverrideData({});
        setOpenNewOverrideDialog(true);
    };

    const handleEditOverride = (data) => {
        validateOverrideData(data);
        handleEditVisOverride(data);
    };

    return (
        <>
            <ButtonWithIcon
                flexDirection="row"
                onClickHandler={showNewOverrideDialog}
                buttonText="Add Override"
            >
                <AddCircleSharpIcon sx={{ fontSize: 30 }} />
            </ButtonWithIcon>
            <br />
            {loading ? (
                <div>
                    <img
                        alt="loading"
                        className={classes.loadingImage}
                        src="../../img/spinner.gif"
                    />
                </div>
            ) : (
                <ExistingOverrides />
            )}

            {/* For Adding New Override */}
            <DialogWithContent
                open={openNewOverrideDialog}
                setOpen={setOpenNewOverrideDialog}
                dialogTitle={'Add Visbility Override'}
                dialogMaxWidth={'md'}
                handleSave={() => handleAddNewOverride(openVisOverrideData)}
                dialogCloseBtnText={'Close'}
                dialogSaveBtnText={'Save'}
            >
                <OverrideDataEditor />
            </DialogWithContent>

            {/* For Editing an existing Override */}
            <DialogWithContent
                open={openEditOverrideDialog}
                setOpen={setOpenEditOverrideDialog}
                dialogTitle={'Edit Visbility Override'}
                dialogMaxWidth={'md'}
                handleSave={() => handleEditOverride(openVisOverrideData)}
                dialogCloseBtnText={'Close'}
                dialogSaveBtnText={'Save'}
            >
                <OverrideDataEditor />
            </DialogWithContent>
        </>
    );
}

VisibilityOverrideContent.propTypes = {};

export default VisibilityOverrideContent;
