import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Box, Snackbar } from '@mui/material';
import CateringEventResults from './CateringEventResults';
import CateringDashboardContext from '../CateringDashboardContext';
import CateringContext from '../../../CateringContext';
import { RestauInfoContext } from '../../../../home/RestauInfoContext';
import { useStyles } from '../../../../home/useStyles';
// import moment from 'moment';

const CateringDashboardSub = (props) => {
    const { restauHook } = useContext(RestauInfoContext);
    useEffect(() => {}, [restauHook.restauId]);

    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState([]);
    const {
        prioritySubmission,
        priorityFollowup,
        priorityConfirmed,
        priorityRecent,
        refreshToggle,
    } = useContext(CateringDashboardContext);
    const {
        retrieveFilteredEvents,
        snackbarOpen,
        setSnackbarOpen,
        snackbarMessage,
        snackbarAction,
    } = useContext(CateringContext);
    const classes = useStyles();

    useEffect(() => {
        const updateDashboardSub = async (filters, sort, title) => {
            setLoading(true);
            try {
                const response = await retrieveFilteredEvents(filters);
                const sortedResults = sort(response.data.filteredEvents);
                setResults(sortedResults);
                setTitle(`${title} (${sortedResults.length})`);
            } catch (error) {
                console.log('Error fetching events: ', error);
            } finally {
                setLoading(false);
            }
        };
        let currentDate = new Date();
        switch (props.title) {
            case 'InqToReachOut':
                updateDashboardSub(
                    [
                        {
                            field: 'eventStatus',
                            operator: 'in',
                            value: ['New Event'],
                            type: 'dropdown',
                        },
                    ],
                    prioritySubmission,
                    'Inquiries to reach out'
                );
                break;
            case 'InqToFollowUp':
                updateDashboardSub(
                    [
                        {
                            field: 'eventStatus',
                            operator: 'in',
                            value: ['FollowUp'],
                            type: 'dropdown',
                        },
                    ],
                    priorityFollowup,
                    'Inquiries to follow up'
                );
                break;
            case 'UpcomingEvent':
                updateDashboardSub(
                    [
                        {
                            field: 'eventStatus',
                            operator: 'in',
                            value: ['Confirmed'],
                            type: 'dropdown',
                        },
                        {
                            field: 'eventDate',
                            operator: '>=',
                            value: currentDate.toISOString(),
                            type: 'date',
                        },
                    ],
                    priorityConfirmed,
                    'Upcoming catering events'
                );
                break;
            case 'RecentPastEvent':
                updateDashboardSub(
                    [
                        {
                            field: 'eventStatus',
                            operator: 'in',
                            value: ['Confirmed'],
                            type: 'dropdown',
                        },
                        {
                            field: 'eventDate',
                            operator: '<',
                            value: currentDate.toISOString(),
                            type: 'date',
                        },
                    ],
                    priorityRecent,
                    'Recent past catering events'
                );
                break;
            case 'VoidEvent':
                updateDashboardSub(
                    [
                        {
                            field: 'eventStatus',
                            operator: 'in',
                            value: ['Void'],
                            type: 'dropdown',
                        },
                    ],
                    priorityConfirmed,
                    'Void catering events'
                );
                break;
        }
    }, [props.title, restauHook.restauId, refreshToggle]);

    return (
        <div>
            {loading ? (
                <img
                    className={classes.loadingImage}
                    src="/img/spinner.gif"
                    alt="loading"
                />
            ) : (
                <Grid container>
                    <Grid item xs={12}>
                        <Typography color="primary" variant="h6">
                            {title}
                        </Typography>
                    </Grid>
                    {results.length === 0 ? (
                        <Typography variant="body2">
                            No results available
                        </Typography>
                    ) : (
                        <Box
                            style={{
                                overflowY: 'auto',
                                width: '100%',
                                maxHeight: '45vh',
                            }}
                        >
                            <Grid item xs={12}>
                                {results.map((result, i) => (
                                    <CateringEventResults
                                        key={result.inquiryId}
                                        color={i % 2 ? '#EAE9F8' : '#ddf1e9'}
                                        style={{
                                            height: '20vh',
                                            overflow: 'auto',
                                        }}
                                        data={result}
                                        field={props.field}
                                        fieldName={props.fieldName}
                                    />
                                ))}
                            </Grid>
                        </Box>
                    )}
                </Grid>
            )}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                message={snackbarMessage}
                onClose={(event, reason) => {
                    if (reason === 'clickaway') return;
                    setSnackbarOpen(false);
                }}
                action={snackbarAction}
            />
        </div>
    );
};

export default CateringDashboardSub;
