import React from 'react';
import { Typography } from '@mui/material';

function TitleBlock(props) {
    const { title, subtitle, subtitle2 } = props;
    return (
        <>
            {title && (
                <Typography
                    component="h2"
                    variant="h3"
                    color="inherit"
                    fontWeight="500"
                >
                    {title}
                </Typography>
            )}
            {subtitle && (
                <Typography component="h6" variant="subtitle1">
                    {subtitle}
                </Typography>
            )}
            {subtitle2 && (
                <Typography component="h6" variant="subtitle2">
                    {subtitle2}
                </Typography>
            )}
        </>
    );
}

TitleBlock.propTypes = {};

export default TitleBlock;
