import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
export default function OOSLogRow(props) {
    const { row } = props;
    return (
        <TableRow key={row.index}>
            <TableCell
                component="th"
                scope="row"
                style={{ width: 200, minwidth: 150 }}
            >
                {row.itemName}
            </TableCell>
            <TableCell style={{ minwidth: 150 }} align="left">
                {row.oosDate}
            </TableCell>
            <TableCell style={{ minwidth: 150 }} align="left">
                {row.oosEmail}
            </TableCell>
            <TableCell style={{ minwidth: 150 }} align="left">
                {row.instockDate}
            </TableCell>
            <TableCell style={{ minwidth: 150 }} align="left">
                {row.instockEmail}
            </TableCell>
        </TableRow>
    );
}
