import React from 'react';
import CateringEventContent from '../Components/CateringEventContent';
import CateringEventContext from '../CateringEventContext';
import useCateringEvent from '../useCateringEvent';

const CateringEventRoot = () => {
    const cateringEventHook = useCateringEvent();
    return (
        <CateringEventContext.Provider value={cateringEventHook}>
            <CateringEventContent />
        </CateringEventContext.Provider>
    );
};

export default CateringEventRoot;
