import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const templates = {
    tuba: 'Tuba',
    imageright: 'Image Right',
    imageleft: 'Image Left',
};

function SelectFoodTemplate(props) {
    const { template, onTemplateChange } = props;
    return (
        <FormControl>
            <InputLabel id="select-food-template-label">Template</InputLabel>

            <Select
                labelId="select-food-template-label"
                id="select-food-template"
                value={template}
                label="Templates"
                onChange={onTemplateChange}
                size="small"
            >
                {Object.keys(templates).map((template) => (
                    <MenuItem value={template} key={template}>
                        {templates[template]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

SelectFoodTemplate.propTypes = {};

export default SelectFoodTemplate;
