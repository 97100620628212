import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import HolidayToggle from './HolidayToggle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import moment from 'moment';

import SlotList from './SlotList';
import { ConfigContainer, ConfigTitle } from './HolidayStyles';

function SlotsConfig(props) {
    const {
        list,
        holiday,
        disabled,
        onRemoveSlot,
        onAddSlot,
        onChangeSlot,
        onToggleHoliday,
        onUpdate,
        onReset,
        configDate,
        onChangeDate,
    } = props;

    return (
        <div style={{ backgroundColor: '#cfe3d5', padding: 15, paddingTop: 0 }}>
            <ConfigContainer verticalLayout>
                <ConfigTitle verticalLayout>
                    <DatePicker
                        value={configDate}
                        onChange={onChangeDate}
                        inputVariant="outlined"
                        size="small"
                        margin="dense"
                        label="Date"
                        autoOk
                        minDate={moment(new Date())} //don't allow adding configuration for past dates
                        selected={moment(configDate)}
                        renderInput={(props) => <TextField {...props} />}
                    />

                    <HolidayToggle
                        label="Holiday"
                        labelPlacement="start"
                        checked={holiday}
                        onChange={onToggleHoliday}
                    />
                </ConfigTitle>
                <div>
                    <SlotList
                        disabled={disabled}
                        list={list}
                        onChange={onChangeSlot}
                        onRemoveSlot={onRemoveSlot}
                        onAddSlot={onAddSlot}
                    />
                </div>
            </ConfigContainer>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: 12,
                    justifyContent: 'center',
                }}
            >
                <Button
                    onClick={onUpdate}
                    size="small"
                    variant="contained"
                    color="primary"
                >
                    Update
                </Button>
                <Button onClick={onReset} size="small" variant="outlined">
                    Reset
                </Button>
            </div>
        </div>
    );
}

SlotsConfig.propTypes = {};
SlotsConfig.defaultProps = {
    selectedDate: '',
};

export default SlotsConfig;
