import { useEffect, useState, useContext } from 'react';
import { RestauInfoContext } from '../home/RestauInfoContext';
import axios from 'axios';
import Config from '../config/Config';

const useMultilocation = () => {
    const { restauHook } = useContext(RestauInfoContext);
    const [loading, setLoading] = useState(true);
    const [location, setLocation] = useState([]);

    const retrieveRestauLocation = async () => {
        setLoading(true);
        const token = await window.customerApp.auth().currentUser.getIdToken();
        let config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };
        try {
            //debugger;
            let response = await axios.post(Config.getRestaurantId, {}, config);
            const data = await response.data;
            if (data) {
                let locationIdName = data.allChain;
                const admin = window.customerApp.database();
                const promises = locationIdName.map((item) => {
                    let restauId = item.id;
                    let ref = admin.ref('menus/' + restauId);
                    return ref.once('value').then((snapshot) => {
                        let allData = snapshot.val();
                        let restauAddress = allData.restaurantAddress;
                        let restauName = allData.restaurantName;
                        return {
                            id: restauId,
                            restauAddress: restauAddress,
                            restauName: restauName,
                            editName: item.name,
                        };
                    });
                });

                const tempLocation = await Promise.all(promises);
                setLocation(tempLocation);
            }
        } catch (error) {
            console.log('Caught error in retrieveRestauLocation: ', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        retrieveRestauLocation();
    }, []);

    const updateLocationName = async () => {
        setLoading(true);
        if (!location) return;
        const token = await window.customerApp.auth().currentUser.getIdToken();
        let config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };
        try {
            let reqBody = { location: location, restauId: restauHook.restauId };
            const response = await axios.post(
                Config.updateLocationName,
                reqBody,
                config
            );
            if (!response.data || response.data.status === false) {
                throw new Error(
                    'Error from the server: ' + response.data
                        ? response.data.msg
                        : null
                );
            }

            retrieveRestauLocation();
        } catch (error) {
            console.log('Caught error in updateLocationName: ', error);
        } finally {
            setLoading(false);
        }
    };

    const updateForm = (i, value) => {
        let newLocation = [...location];
        newLocation[i] = { ...newLocation[i], ...value };
        setLocation(newLocation);
    };

    return {
        loading,
        location,
        updateLocationName,
        updateForm,
    };
};

export default useMultilocation;
