import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Day = styled.span`
    margin-right: 16px;
    display: inline-block;
`;

const Holiday = styled.span`
    color: red;
    display: inline-block;
    font-style: italic;
    font-weight: 600;
`;

const Slot = styled.span`
    display: inline-block;
    font-style: italic;
`;

function SpecialHourItem(props) {
    const { day, holiday, hours, onDelete } = props;
    const matchPattern = /^(\d{2})(\d{2})(\d{4})$/g;
    const replacePattern = '$1/$2/$3';
    const formattedDay = day.replace(matchPattern, replacePattern);
    return holiday ? (
        <ListItem key={`${day}_holiday`} dense>
            <ListItemText>
                <Day>{formattedDay}</Day>
                <Holiday>Holiday</Holiday>
            </ListItemText>

            <ListItemSecondaryAction>
                <IconButton
                    onClick={() => onDelete(day)}
                    edge="end"
                    aria-label="comments"
                    size="large"
                >
                    <DeleteOutline />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    ) : hours ? (
        hours.map(({ start, end }, index) => (
            <ListItem key={`${day}_${start}_${end}`} dense>
                <ListItemText>
                    <Day>{formattedDay}</Day>
                    <Slot>
                        {start} To {end}
                    </Slot>
                </ListItemText>

                <ListItemSecondaryAction>
                    <IconButton
                        onClick={() => onDelete(day, index)}
                        edge="end"
                        aria-label="comments"
                        size="large"
                    >
                        <DeleteOutline />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        ))
    ) : null;
}

SpecialHourItem.propTypes = {
    day: PropTypes.string.isRequired,
    holiday: PropTypes.bool,
    hours: PropTypes.string,
    onDelete: PropTypes.func.isRequired,
};

export default SpecialHourItem;
