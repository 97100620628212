import {
    Button,
    Chip,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React, { useContext, useEffect, useState } from 'react';
import { RestauInfoContext } from '../../home/RestauInfoContext';
import Config from '../../config/Config';
import axios from 'axios';
import AddNewStationDialog from './AddNewStationDialog';
import { getUUID } from '../Utils';
import { useStyles } from '../../home/useStyles';
import { CustomWidthTooltip } from '../StationMapping/CustomWidthTooltip';

export default function StationMappings() {
    const classes = useStyles();
    const { restauHook } = useContext(RestauInfoContext);
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [stationMappingData, setStationMappingData] = useState({});
    const [openNewStationDialog, setOpenNewStationDialog] = useState(false);
    //const [openEditStationDialog, setOpenEditStationDialog] = useState(false);

    /* const [stationMenuAnchorEl, setStationMenuAnchorEl] = React.useState(null);
    const openStationMenu = Boolean(stationMenuAnchorEl);
    const handleStationMenuClick = (event) => {
        setStationMenuAnchorEl(event.currentTarget);
    };
    const handleStationMenuClose = () => {
        setStationMenuAnchorEl(null);
    };
    const ITEM_HEIGHT = 48; */

    async function loadData(restauId) {
        setLoading(true);
        const token = await window.customerApp.auth().currentUser.getIdToken();
        var config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };

        let reqBody = {
            restauId: restauId,
        };

        //function to fetch the popularitems from databasse
        let response = await axios
            .post(Config.getAllStationMapping, reqBody, config)
            .catch(function (error) {
                console.log(error);
            });
        if (response && response.data) {
            if (response.data.status === undefined) {
                //console.log('Response>>: ' + JSON.stringify(response.data));
                setStationMappingData({ ...response.data });
            }
        }
        setLoading(false);
    }

    async function refreshStationMapping() {
        //debugger;
        loadData(restauHook.restauId);
    }

    async function handleCreateNewStation(stationName, isStationDefault) {
        //console.log(stationName, isStationDefault);
        let uuid = getUUID();
        const newStationData = {
            [uuid]: {
                id: uuid,
                name: stationName,
                isDefault: isStationDefault,
            },
        };
        const token = await window.customerApp.auth().currentUser.getIdToken();
        var config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };

        let reqBody = {
            restauId: restauHook.restauId,
            stationsToAdd: { ...newStationData },
        };

        //function to fetch the popularitems from databasse
        setLoading(true);
        let response = await axios
            .post(Config.updateStationsForRestaurant, reqBody, config)
            .catch(function (error) {
                //show the error message
                setErrorMessage(error.response.data.msg);
                setIsError(true);
            });

        if (response && response.data && response.data.status === 'Success') {
            setLoading(true);
            setIsError(false);
            //console.log('Response>>: ' + JSON.stringify(response.data));
            await refreshStationMapping();
        }
        setLoading(false);
    }
    //<<Currently Not LIVE. Will be implemented later>>
    async function handleEditStation() {}
    //<<Currently Not LIVE. Will be implemented later>>
    async function handleDeleteStation(stationId) {
        console.log('Station to Delete: ', stationId);
        const token = await window.customerApp.auth().currentUser.getIdToken();
        var config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };

        let reqBody = {
            restauId: restauHook.restauId,
            stationsToDelete: [stationId],
        };

        //function to fetch the popularitems from databasse
        let response = await axios
            .post(Config.updateStationsForRestaurant, reqBody, config)
            .catch(function (error) {
                console.log(error);
            });

        if (response && response.data) {
            console.log('Response>>: ' + JSON.stringify(response.data));
            refreshStationMapping();
        }
        setLoading(false);
    }

    useEffect(() => {
        loadData(restauHook.restauId);
        setIsError(false);
    }, [restauHook.restauId]);

    return (
        <>
            <AddNewStationDialog
                open={openNewStationDialog}
                setOpen={setOpenNewStationDialog}
                handleSave={handleCreateNewStation}
                mode="create"
            />
            {/* <StationDialog
                open={openEditStationDialog}
                setOpen={setOpenEditStationDialog}
                handleSave={handleEditStation}
                mode="edit"
            /> */}
            <Stack direction="row" spacing={2}>
                <Button
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: 1,
                        padding: '10px 15px',
                    }}
                    onClick={() => {
                        setOpenNewStationDialog(true);
                    }}
                >
                    <AddCircleSharpIcon color="primary" sx={{ fontSize: 30 }} />
                    <Typography sx={{ ml: '20px' }}>Create Station</Typography>
                </Button>
                {isError && (
                    <Typography
                        component="span"
                        variant="h5"
                        style={{
                            color: 'red',
                            fontWeight: 'normal',
                            fontSize: '0.85rem',
                        }}
                    >
                        {errorMessage}
                    </Typography>
                )}
            </Stack>
            <br />
            {loading ? (
                <div>
                    <img
                        className={classes.loadingImage}
                        src="../../img/spinner.gif"
                    />
                </div>
            ) : (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="center">
                                    Item/Categories Assigned
                                </TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(stationMappingData).map(
                                (stName, index) => (
                                    <TableRow
                                        key={stName + '_row'}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell
                                            component="th"
                                            scope="station"
                                            /* style={{ width: 120 }} */
                                        >
                                            {stName}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            /*  sx={{
                                                width: 850,
                                            }} */
                                        >
                                            <CustomWidthTooltip
                                                placement="bottom"
                                                title={
                                                    stationMappingData[stName]
                                                        .mappedTo &&
                                                    stationMappingData[
                                                        stName
                                                    ].mappedTo.join(' | ')
                                                }
                                            >
                                                <Typography
                                                    sx={{
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: '2',
                                                        WebkitBoxOrient:
                                                            'vertical',
                                                    }}
                                                >
                                                    {stationMappingData[stName]
                                                        .mappedTo &&
                                                        stationMappingData[
                                                            stName
                                                        ].mappedTo.join(' | ')}
                                                </Typography>
                                            </CustomWidthTooltip>
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            /* style={{ width: 100 }} */
                                        >
                                            {stationMappingData[stName]
                                                .isDefault && (
                                                <Chip
                                                    label="default"
                                                    color="success"
                                                    variant="outlined"
                                                />
                                            )}
                                        </TableCell>

                                        <TableCell align="right">
                                            <MoreHorizIcon
                                                sx={{ fontSize: 24 }}
                                                disabled
                                                //onClick={handleStationMenuClick}
                                            />
                                            {/* <Menu
                                            id={
                                                stationMappingData[stName].id +
                                                'menu'
                                            }
                                            MenuListProps={{
                                                'aria-labelledby':
                                                    'long-button',
                                            }}
                                            anchorEl={stationMenuAnchorEl}
                                            open={openStationMenu}
                                            onClose={handleStationMenuClose}
                                            PaperProps={{
                                                style: {
                                                    maxHeight:
                                                        ITEM_HEIGHT * 4.5,
                                                    width: '20ch',
                                                },
                                            }}
                                        >
                                            <MenuItem>Assign to Items</MenuItem>
                                            <MenuItem>
                                                <EditIcon /> Edit Station
                                            </MenuItem>
                                            <MenuItem
                                                id={
                                                    stationMappingData[stName]
                                                        .id
                                                }
                                                onClick={(e) => {
                                                    console.log(stName, index);
                                                    handleDeleteStation(
                                                        stationMappingData[
                                                            stName
                                                        ].id
                                                    );
                                                }}
                                            >
                                                <DeleteIcon /> Delete Station
                                            </MenuItem>
                                        </Menu> */}
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
}
