import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@mui/material';
import SayMessageDetails from './SayMessageDetails';
import PlayMessageDetails from './PlayMessageDetails';
import { useStyles } from '../../../../../home/useStyles';

function SayPlayMessageDetails(props) {
    const {
        msgDetails,
        setMsgDetails,
        //uploadedPlayFile,
        //setUploadedPlayFile,
        originalMsgDetails,
        readOnly,
        showNewAudioFilePopup,
    } = props;
    const classes = useStyles();
    //const tempData = JSON.parse(JSON.stringify(msgDetails));

    const handleMsgTypeChange = (updatedType) => {
        //debugger;
        var temp = [];
        if (updatedType === 'say') {
            originalMsgDetails.forEach((x, index) => {
                temp[index] = { sayMessage: '', ...x, type: 'say' };
                delete temp[index].playFilePath;
            });
            setMsgDetails([...temp]);
        } else {
            //debugger;
            if (originalMsgDetails.length === 1) {
                temp = [
                    {
                        ...originalMsgDetails[originalMsgDetails.length - 1],
                        type: 'play',
                    },
                ];
                delete temp[0].sayMessage;
                delete temp[0].sayMessageLanguage;
            } else {
                temp = [];
                //last one should capture the play file details
                //all the other widgets should be say with empty string
                originalMsgDetails.forEach((msg, index) => {
                    if (index === originalMsgDetails.length - 1) {
                        temp.push({
                            name: msg.name,
                            playFilePath: '',
                            type: 'play',
                        });
                    } else {
                        temp.push({
                            ...msg,
                            sayMessage: '',
                        });
                    }
                });
            }

            setMsgDetails([...temp]);
        }
    };

    return (
        <Grid container rowSpacing={2}>
            <Grid item xs={12}>
                <Grid container rowSpacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Type</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        className={readOnly && classes.disableElements}
                    >
                        <Grid container columnGap={2}>
                            <Grid
                                item
                                md={1.75}
                                xs={5.5}
                                className={
                                    msgDetails &&
                                    msgDetails.length > 0 &&
                                    (msgDetails[msgDetails.length - 1].type ===
                                    'say'
                                        ? `${classes.selectedTile}`
                                        : `${classes.unselectedTile}`)
                                }
                            >
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    sx={{ p: 4, textAlign: 'center' }}
                                    onClick={() => handleMsgTypeChange('say')}
                                >
                                    Say
                                </Button>
                            </Grid>
                            <Grid
                                item
                                md={1.75}
                                xs={5.5}
                                className={
                                    msgDetails &&
                                    msgDetails.length > 0 &&
                                    (msgDetails[msgDetails.length - 1].type ===
                                    'say'
                                        ? `${classes.unselectedTile}`
                                        : `${classes.selectedTile}`)
                                }
                            >
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    sx={{ p: 4, textAlign: 'center' }}
                                    onClick={() => handleMsgTypeChange('play')}
                                >
                                    Play
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {msgDetails &&
                    msgDetails.length > 0 &&
                    (msgDetails[msgDetails.length - 1].type === 'say' ? (
                        <SayMessageDetails
                            msgDetails={msgDetails}
                            setMsgDetails={setMsgDetails}
                            readOnly={readOnly}
                        />
                    ) : (
                        <PlayMessageDetails
                            msgDetails={msgDetails}
                            setMsgDetails={setMsgDetails}
                            //uploadedPlayFile={uploadedPlayFile}
                            //setUploadedPlayFile={setUploadedPlayFile}
                            originalMsgDetails={originalMsgDetails}
                            readOnly={readOnly}
                            showNewAudioFilePopup={showNewAudioFilePopup}
                        />
                    ))}
            </Grid>
        </Grid>
    );
}

SayPlayMessageDetails.propTypes = {
    msgDetails: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            sayMessage: PropTypes.string,
            type: PropTypes.string,
        })
    ),
    setMsgDetails: PropTypes.func,
    uploadedPlayFile: PropTypes.shape({}),
    setUploadedPlayFile: PropTypes.func,
    originalMsgDetails: PropTypes.array,
    readOnly: PropTypes.bool,
    showNewAudioFilePopup: PropTypes.bool,
};

SayPlayMessageDetails.defaultProps = {
    readOnly: false,
    showNewAudioFilePopup: true,
};

export default SayPlayMessageDetails;
