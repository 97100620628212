import React from 'react';
import NavItem from './NavItem';
import LockIcon from '@mui/icons-material/Lock';
import PropTypes from 'prop-types';

function NavItems(props) {
    const { open, onLogout, navItems, level, strideQOnlineOrdering } = props;

    return (
        <>
            {navItems.map(
                ({ path, icon, text, childs, forStrideQOnlineCustOnly }) => {
                    if (
                        strideQOnlineOrdering ||
                        (!strideQOnlineOrdering && !forStrideQOnlineCustOnly)
                    ) {
                        return (
                            <NavItem
                                key={path}
                                path={path}
                                icon={icon}
                                text={text}
                                open={open}
                                level={level}
                            >
                                {childs && (
                                    <NavItems
                                        open={open}
                                        onLogout={onLogout}
                                        navItems={childs}
                                        level={level + 1}
                                        strideQOnlineOrdering={
                                            strideQOnlineOrdering
                                        }
                                    />
                                )}
                            </NavItem>
                        );
                    } else {
                        return <></>;
                    }
                }
            )}
            {level === 1 && (
                //Only for Level 1 Nav Items
                <NavItem
                    onClick={onLogout}
                    icon={LockIcon}
                    text="Log off"
                    open={open}
                />
            )}
        </>
    );
}

NavItems.propTypes = {
    onLogout: PropTypes.func.isRequired,
    open: PropTypes.bool,
    level: PropTypes.number,
    strideQOnlineOrdering: PropTypes.bool,
    navItems: PropTypes.arrayOf(
        PropTypes.shape({
            path: PropTypes.string,
            icon: PropTypes.object.isRequired,
            text: PropTypes.string.isRequired,
            forStrideQOnlineCustOnly: PropTypes.bool,
        })
    ),
};

export default NavItems;
