import * as React from 'react';
import Popover from '@mui/material/Popover';
import InfoIcon from '@mui/icons-material/Info';
import FoodCardNew from './PopularItems/FoodCardNew';

export default function FoodCardPreview(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <InfoIcon
                aria-owns={open ? 'foodCard-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            />
            <Popover
                id="foodCard-popover"
                style={{ pointerEvents: 'none' }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                PaperProps={{
                    style: { width: '350px' },
                }}
            >
                <FoodCardNew
                    data={props.foodItem}
                    showWasPrice={true}
                    editMode={false}
                />
            </Popover>
        </div>
    );
}
