import {
    Button,
    CircularProgress,
    Grid,
    Snackbar,
    StyledEngineProvider,
    Typography,
} from '@mui/material';
import 'react-h5-audio-player/lib/styles.css';
import React, { useContext, useEffect, useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import PropTypes from 'prop-types';
import PhoneSettingsContext from '../../PhoneSettingsContext';
import SelectWithLabel from '../../../../../components/common/SelectWithLabel';
import { useNavigate } from 'react-router';
import { useStyles } from '../../../../../home/useStyles';
import DialogWithContent from '../../../../../components/common/DialogWithContent/DialogWithContent';
import AddNewAudioFile from '../../../PhoneAudioLibrary/Components/AddNewAudioFile';
import { RestauInfoContext } from '../../../../../home/RestauInfoContext';
import PhoneMenuContext from '../../../../PhoneMenuContext';
function PlayMessageDetails(props) {
    let navigate = useNavigate();
    const classes = useStyles();

    const {
        //audioFilesInLibrary,
        readOnly,
        msgDetails,
        setMsgDetails,
        //setUploadedPlayFile,
        originalMsgDetails,
        showNewAudioFilePopup,
    } = props;
    const { audioLibraryData, refreshAudioLibraryData } =
        useContext(PhoneSettingsContext);
    const { addNewAudioFile } = useContext(PhoneMenuContext);
    const { restauHook } = useContext(RestauInfoContext);
    const [errMsg, setErrMsg] = useState('');
    const [playFileId, setPlayFileId] = useState('');
    const [showNewAudioFileDialog, setShowNewAudioFileDialog] = useState(false);
    const [newFileDetails, setNewFileDetails] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    //const [openRecordMsgDialog, setOpenRecordMsgDialog] = useState(false);
    //const [uploadedPlayFile, setUploadedPlayFile] = useState({});

    const handleSaveNewPlayFile = async () => {
        if (newFileDetails.fileName === '' || newFileDetails.fileName == null) {
            throw new Error('FileName cannot be empty.');
        }

        if (newFileDetails.audioFile == null) {
            throw new Error('Please select an audio file.');
        }

        if (
            audioLibraryData.filter(
                (x) => x.displayName === newFileDetails.fileName
            ).length >= 1
        ) {
            throw new Error('An Audio file with the same name already exists.');
        }

        try {
            //debugger;
            setIsProcessing(true);
            let result = await addNewAudioFile(
                restauHook.restauId,
                newFileDetails.audioFile,
                newFileDetails.fileName,
                newFileDetails.shared
            );
            if (!result) {
                throw new Error('Failed while saving the audio file.');
            }
            //debugger;
            await refreshAudioLibraryData();

            //close the popup and reload the data
            //setShowNewAudioFileDialog(false);

            setPlayFileId(result.fileId);
            if (msgDetails.length === 1) {
                setMsgDetails([
                    {
                        ...msgDetails[0],
                        playFilePath: result.assetUrl,
                    },
                ]);
            } else {
                let temp = [];
                msgDetails.forEach((msg, index) => {
                    if (index === msgDetails.length - 1) {
                        temp.push({
                            ...msg,
                            playFilePath: result.assetUrl,
                        });
                    } else {
                        // temp.push({
                        //     name: msg.name,
                        //     type: 'say',
                        //     sayMessage: '',
                        //     voice: 'woman',
                        //     sayMessageLanguage: 'en-US',
                        // });
                        let updatedmsg = { ...msg, type: 'say' };
                        delete updatedmsg.playFilePath;
                        temp.push({ ...updatedmsg });
                    }
                });
                setMsgDetails(temp);
            }

            setShowSuccessMsg(true);
            setSuccessMsg('Play file saved succesfully!');
        } catch (ex) {
            setErrMsg('Failed while saving the new Audio file');
        } finally {
            setShowNewAudioFileDialog(false);
            setIsProcessing(false);
        }

        // setIsError(false);
        // setStatusMsg('New Audio File saved succesfully.');
        // setShowStatus(true);
        // loadData();
    };

    let audioFilestoDisplay =
        audioLibraryData &&
        audioLibraryData.map((x) => {
            return {
                id: x.id,
                value: x.id,
                label: x.shared ? x.displayName + ' <shared>' : x.displayName,
            };
        });

    const handleAudioFileChange = (selectedFileId) => {
        //debugger;
        let selectedFile = audioLibraryData.filter(
            (x) => x.id === selectedFileId
        )[0];
        setPlayFileId(selectedFile.id);
        if (msgDetails.length === 1) {
            setMsgDetails([
                {
                    ...msgDetails[0],
                    playFilePath: selectedFile.url,
                },
            ]);
        } else {
            debugger;
            let temp = [];
            msgDetails.forEach((msg, index) => {
                if (index === msgDetails.length - 1) {
                    temp.push({
                        ...msg,
                        playFilePath: selectedFile.url,
                    });
                } else {
                    // temp.push({
                    //     name: msg.name,
                    //     type: 'say',
                    //     sayMessage: '',
                    //     voice: 'woman',
                    //     sayMessageLanguage: 'en-US',
                    // });
                    let updatedmsg = { ...msg, type: 'say' };
                    delete updatedmsg.playFilePath;
                    temp.push({ ...updatedmsg });
                }
            });
            setMsgDetails(temp);
        }
    };

    const navigateToLibrary = () => {
        if (
            confirm(
                'You changes will be lost and you will be navigated to Audio Library page. \n Are you sure you want to proceed?'
            )
        ) {
            navigate('/phone/audioLibrary');
        } else {
            return;
        }
    };

    useEffect(() => {
        //debugger;
        if (
            msgDetails &&
            msgDetails[msgDetails.length - 1].playFilePath !== ''
        ) {
            setErrMsg('');
            let data =
                audioLibraryData &&
                audioLibraryData.filter(
                    (x) =>
                        x.url === msgDetails[msgDetails.length - 1].playFilePath
                )[0];
            setPlayFileId(data && data.id);
        }
    }, [msgDetails]);

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={showSuccessMsg}
                onClose={() => setShowSuccessMsg(false)}
                message={successMsg}
                key={'bottomcenter'}
            />
            <DialogWithContent
                dialogTitle={`Add New Audio File`}
                dialogMaxWidth={'sm'}
                open={showNewAudioFileDialog}
                setOpen={setShowNewAudioFileDialog}
                handleSave={handleSaveNewPlayFile}
            >
                <AddNewAudioFile
                    newFileDetails={newFileDetails}
                    setNewFileDetails={setNewFileDetails}
                />
            </DialogWithContent>
            <Grid container columnGap={2} rowSpacing={1}>
                <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    columnSpacing={1}
                    className={readOnly && classes.disableElements}
                >
                    <Grid item>
                        <SelectWithLabel
                            //items={menuOptionDestinations}
                            label="Select Audio File"
                            items={audioFilestoDisplay}
                            id="selectAudioFile"
                            size="small"
                            minWidth={300}
                            value={playFileId}
                            autoComplete={true}
                            onChangeHandler={(selectedVal) =>
                                handleAudioFileChange(selectedVal)
                            }
                        ></SelectWithLabel>
                    </Grid>
                    <Grid item>
                        <Typography> or </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            disabled={isProcessing}
                            //fullWidth
                            size="small"
                            //onClick={navigateToLibrary}
                            onClick={
                                showNewAudioFilePopup
                                    ? () => setShowNewAudioFileDialog(true)
                                    : navigateToLibrary
                            }
                        >
                            {isProcessing && <CircularProgress size={24} />}
                            Add New File
                        </Button>
                    </Grid>
                </Grid>

                {msgDetails && msgDetails[msgDetails.length - 1].playFilePath && (
                    <Grid
                        item
                        xs={12}
                        md={9}
                        style={{
                            //marginTop: '10px',
                            marginLeft: '10px',
                            width: '400px',
                            marginBottom: '10px',
                        }}
                    >
                        {/* <br /> */}
                        <Typography>Audio Message:</Typography>
                        <StyledEngineProvider injectFirst>
                            <AudioPlayer
                                //autoPlay
                                //src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
                                src={
                                    msgDetails[msgDetails.length - 1]
                                        .playFilePath
                                }
                                //onPlay={(e) => console.log('onPlay')}
                            />
                        </StyledEngineProvider>
                    </Grid>
                )}
                {errMsg !== '' && (
                    <Typography variant="body1" sx={{ color: 'red' }}>
                        {errMsg}
                    </Typography>
                )}
            </Grid>
        </>
    );
}

PlayMessageDetails.propTypes = {
    msgDetails: PropTypes.arrayOf(
        PropTypes.shape({
            playFilePath: PropTypes.string,
        })
    ),
    setMsgDetails: PropTypes.func,
    uploadedPlayFile: PropTypes.shape({}),
    setUploadedPlayFile: PropTypes.func,
    originalMsgDetails: PropTypes.arrayOf(PropTypes.shape({})),
    readOnly: PropTypes.bool,
    showNewAudioFilePopup: PropTypes.bool,
};

PlayMessageDetails.defaultProps = {
    readOnly: false,
    showNewAudioFilePopup: true,
};

export default PlayMessageDetails;
