import { Grid, TextField } from '@mui/material';
import React from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { useContext } from 'react';
import moment from 'moment';
import { useEffect } from 'react';
import { RestauInfoContext } from '../../../../home/RestauInfoContext';
import Utils from '../../../../utils/Utils';
import PhoneCallerContext from '../PhoneCallerContext';

const { getDateWithOffset, getPhoneNumberInE164Format } = { ...Utils };

function SearchCriteria() {
    const {
        searchStartDate,
        setSearchStartDate,
        searchEndDate,
        setSearchEndDate,
        getFilteredCallDetails,
    } = useContext(PhoneCallerContext);
    const { restauHook } = useContext(RestauInfoContext);

    function setDateRange(date) {
        setSearchStartDate(date);
        if (searchEndDate < date) {
            setSearchEndDate(date);
        }
    }

    function loadData() {
        setSearchStartDate(moment().add(-30, 'days'));
        setSearchEndDate(moment());
        // setSearchStartDate(null);
        // setSearchEndDate(null);
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        //debugger;
        if (searchStartDate && searchEndDate) getFilteredCallDetails();
    }, [searchStartDate, searchEndDate, restauHook.restauId]);

    return (
        <Grid
            container
            item
            xs={12}
            sx={{
                //justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'center',
            }}
            columnSpacing={2}
            rowSpacing={2}
        >
            <Grid item xs={12} md={4}>
                <DatePicker
                    fullWidth
                    size="small"
                    label="Date From"
                    value={searchStartDate}
                    autoOk
                    inputVariant="outlined"
                    margin="dense"
                    onChange={(date) => setDateRange(date)}
                    minDate={moment(getDateWithOffset(-365))}
                    maxDate={moment()}
                    selected={moment(searchStartDate)}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <DatePicker
                    fullWidth
                    size="small"
                    label="Date To"
                    autoOk
                    value={searchEndDate}
                    inputVariant="outlined"
                    margin="dense"
                    onChange={(date) => setSearchEndDate(date)}
                    minDate={moment(
                        searchStartDate
                            ? searchStartDate
                            : getDateWithOffset(-180)
                    )}
                    maxDate={moment()}
                    //selected={moment(searchEndDate)}
                    renderInput={(props) => <TextField {...props} />}
                />
            </Grid>
            {/* <Grid item xs={12} md={4}>
                <Button variant="contained" onClick={getPhoneLogs}>
                    Apply
                </Button>
            </Grid> */}
        </Grid>
    );
}

SearchCriteria.propTypes = {};

export default SearchCriteria;
