import React from 'react';
import Collapsible from '../../../../../Menu/Collapsible';
import styled from 'styled-components';
import FoodItem from './FoodItem';

const FoodGrid = styled.div`
    @media (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
    @media (max-width: 500px) {
        grid-template-columns: 1fr;
        gap: 10px;
    }
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding-bottom: 20px;
`;
FoodGrid.displayName = 'FoodGrid';

const FoodSection = ({
    id,
    name,
    foods,
    showCount,
    sidx,
    setOpenFood,
    setOpenFoodDialog,
}) => {
    return (
        <Collapsible
            key={id}
            trigger={name + (showCount ? ` (${foods.length})` : '')}
            open={sidx === 0}
        >
            <FoodGrid>
                {foods &&
                    foods.map((food, fidx) =>
                        food ? (
                            <FoodItem
                                key={id + food.id}
                                food={food}
                                setOpenFood={setOpenFood}
                                setOpenFoodDialog={setOpenFoodDialog}
                            />
                        ) : (
                            <div key={sidx + ' ' + fidx} />
                        )
                    )}
            </FoodGrid>
        </Collapsible>
    );
};

export default FoodSection;
