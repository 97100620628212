import React from 'react';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import SortIcon from '@mui/icons-material/Sort';
import ModifierEditDialog from './ModifierEditDialog';
import ModifierCheckBox from './ModifierCheckBox';
import ModifierRadio from './ModifierRadio';
import { Button, Grid, Stack, Typography } from '@mui/material';
import SortItemsInDialog from '../PopularItems/SortItemsInDialog';

export function ModifierGroupsForFoodItem({
    modifierHook,
    openFood,
    restauHook,
}) {
    const [open, setOpen] = React.useState(false);
    const [openDialogToSort, setOpenDialogToSort] = React.useState(false);
    let allCurrentModifiers = null;
    let allModifierIdsAndNames = null;
    console.log('All selected modifiers........');
    //console.log(modifierHook.currentModifiers);
    if (modifierHook && modifierHook.currentModifiers) {
        allCurrentModifiers = [];
        for (let modId of modifierHook.currentModifiers) {
            //console.log(modId);
            allCurrentModifiers.push(modifierHook.modifiers[modId]);
        }
    }
    //console.log(allCurrentModifiers);
    if (allCurrentModifiers) {
        allModifierIdsAndNames = allCurrentModifiers.reduce((res, mod) => {
            res.push({ id: mod.id, name: mod.label });
            return res;
        }, []);
    }
    //console.log('AllModifierNamesAndIds: ', allModifierIdsAndNames);

    const removeModifier = (modifier) => {
        modifierHook.deleteCurrentModifier(modifier);
        let ofv = { ...openFood.value };
        ofv.food.modifiers = modifierHook.currentModifiers;
        openFood.setValue(ofv);
    };
    const moveUpModifier = (modifier) => {
        modifierHook.moveUpModifier(modifier);
        let ofv = { ...openFood.value };
        ofv.food.modifiers = modifierHook.currentModifiers;
        openFood.setValue(ofv);
    };

    const moveDownModifier = (modifier) => {
        modifierHook.moveDownModifier(modifier);
        let ofv = { ...openFood.value };
        ofv.food.modifiers = modifierHook.currentModifiers;
        openFood.setValue(ofv);
    };

    const reorderModifers = (modifiers) => {
        let sortedModfiers = modifiers.map((mod) => {
            return allCurrentModifiers.find((x) => x.id === mod.id);
        });
        console.log('Sorted Modifiers: ', sortedModfiers);
        modifierHook.setCurrentModifiers(sortedModfiers.map((x) => x.id));
    };

    const handleSortItemSave = () => {
        let ofv = { ...openFood.value };
        ofv.food.modifiers = modifierHook.currentModifiers;
        openFood.setValue(ofv);
    };

    return (
        <Grid container>
            <Grid item xs={3}>
                <Typography variant="h6" component="h6">
                    Modifiers
                </Typography>
            </Grid>
            <Grid item xs={9}>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                >
                    <Button
                        sx={{
                            border: 0,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        onClick={() => setOpen(true)}
                    >
                        <AddCircleSharpIcon sx={{ fontSize: 23 }} />
                        <Typography sx={{ ml: 0.5 }}>Add Modifier</Typography>
                    </Button>
                    <Button
                        onClick={() => setOpenDialogToSort(true)}
                        sx={{
                            border: 0,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <SortIcon sx={{ fontSize: 23 }} />
                        <Typography sx={{ ml: 0.5 }}>Sort</Typography>
                    </Button>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                {allCurrentModifiers &&
                    allCurrentModifiers.map((modifier, i) => (
                        <Grid item xs={12} key={modifier.id} sx={{ mt: 1 }}>
                            {modifier.max == 1 && !modifier.isOptional ? (
                                <ModifierRadio
                                    modifier={modifier}
                                    onChange={modifierHook.changeRadio}
                                    removeModifier={removeModifier}
                                />
                            ) : (
                                <ModifierCheckBox
                                    modifier={modifier}
                                    onChange={modifierHook.changeCheckBox}
                                    removeModifier={removeModifier}
                                />
                            )}
                        </Grid>
                    ))}
            </Grid>
            <ModifierEditDialog
                modifier={null}
                modifierHook={modifierHook}
                readOnly={false}
                open={open}
                setOpen={setOpen}
                removeModifier={removeModifier}
            />
            <SortItemsInDialog
                open={openDialogToSort}
                setOpen={setOpenDialogToSort}
                itemDataToSort={allModifierIdsAndNames}
                setItemDataToSort={reorderModifers}
                restauId={restauHook.restauId}
                handleSave={handleSortItemSave}
            />
        </Grid>
    );
}
