import React, { useContext } from 'react';
import CateringDashboardContext from '../CateringDashboardContext';
import CateringDashboardSub from './CateringDashboardSub';
import { Grid } from '@mui/material';

const CateringDashboardContent = () => {
    const { dashboardSections } = useContext(CateringDashboardContext);

    return (
        <Grid container spacing={5}>
            {dashboardSections.map((item, i) => (
                <Grid item key={i} xs={12} md={6}>
                    <CateringDashboardSub
                        title={item.content}
                        timeline={item.timeline}
                        field={item.additionalField}
                        fieldName={item.additionalKey}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default CateringDashboardContent;
