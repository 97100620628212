import React from 'react';
import FoodItemRow from './FoodItemRow';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';

const FoodItemTable = ({
    items,
    setOpenFood,
    setOpenFoodDialog,
    setOpenCustomFoodDialog,
    setKeyToEdit,
    orders,
    dispatchOrder,
    updateMenuSelection,
    updatePricing,
}) => {
    return (
        <Table size="small" sx={{ mb: 2 }}>
            <TableHead>
                <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="right"></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.values(items).map((item, index) => (
                    <FoodItemRow
                        key={index}
                        index={index}
                        item={item}
                        setOpenFood={setOpenFood}
                        setOpenFoodDialog={setOpenFoodDialog}
                        setOpenCustomFoodDialog={setOpenCustomFoodDialog}
                        setKeyToEdit={setKeyToEdit}
                        orders={orders}
                        dispatchOrder={dispatchOrder}
                        updateMenuSelection={updateMenuSelection}
                        updatePricing={updatePricing}
                    />
                ))}
            </TableBody>
        </Table>
    );
};

export default FoodItemTable;
