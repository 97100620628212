import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import Config from '../config/Config';
import { RestauInfoContext } from '../home/RestauInfoContext.js';

import StarIcon from '@mui/icons-material/StarBorder';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';

const { default: axios } = require('axios');

export default function Subscription(props) {
    // const restauHook = props.restauHook;

    const useStyles = makeStyles((theme) => ({
        '@global': {
            ul: {
                margin: 0,
                padding: 0,
                listStyle: 'none',
            },
        },
        table: {
            minWidth: 650,
        },
        appBar: {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        toolbar: {
            flexWrap: 'wrap',
        },
        toolbarTitle: {
            flexGrow: 1,
        },
        link: {
            margin: theme.spacing(1, 1.5),
        },
        heroContent: {
            padding: theme.spacing(8, 0, 6),
        },
        cardHeader: {
            backgroundColor:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[200]
                    : theme.palette.grey[700],
        },
        cardPricing: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'baseline',
            marginBottom: theme.spacing(2),
        },
        footer: {
            borderTop: `1px solid ${theme.palette.divider}`,
            marginTop: theme.spacing(8),
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            [theme.breakpoints.up('sm')]: {
                paddingTop: theme.spacing(6),
                paddingBottom: theme.spacing(6),
            },
        },
    }));

    const classes = useStyles();

    //alert(location.protocol + '//' + location.host + location.pathname);
    //|| !restauHook || !restauHook.value || ! restauHook.value.restauName
    const resauInfoContext = useContext(RestauInfoContext);
    const restauHook = resauInfoContext.restauHook;

    const [subscriptionData, setSubscriptionData] = useState(null);
    const [productDescription, setProductDescription] = useState(null);
    const [loaded, setLoaded] = React.useState(false);
    const [sessionId, setSessionId] = useState(null);
    const [success, setSuccess] = useState(false);

    async function loadData(restauHook) {
        if (!restauHook.user) {
            let navigate = useNavigate();

            navigate('/login');
            return;
        }
        const token = restauHook.tokenId;
        console.log('token: ', token);

        let config = {
            headers: {
                token: token,
            },
        };
        setLoaded(true);
        const search = window.location.search;
        const params = new URLSearchParams(search);
        let session_id = params.get('session_id');
        let success = params.get('success');
        if (session_id && success === 'true') {
            setSessionId(session_id);
            setSuccess(true);
        } else if (session_id && success === 'true') {
            setSessionId(session_id);
            setSuccess(false);
        }

        let subData = await axios
            .post(
                Config.getSubscription,
                {
                    restauId: restauHook.restauId,
                    session_id: session_id,
                    success: success,
                },
                config
            )
            .then((response) => {
                console.log(response.data);
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
        if (subData && subData.status === 'SUCCESS') {
            setSubscriptionData(subData);
        } else {
            setSubscriptionData(null);
        }

        if (subData && subData.metadata) {
            let metadata = [];
            for (let key of Object.keys(subData.metadata)) {
                metadata.push(subData.metadata[key]);
            }
            setProductDescription(metadata);
        }
        setLoaded(false);

        return;
    }

    function getStatus(status, isPaid) {
        if (isPaid) {
            return 'Reciept';
        }
        if (status === 'processing') {
            return 'Invoice';
        }
        return 'Pay';
    }
    function open(url) {
        window.location.href = url;
    }
    function home() {
        let url = location.protocol + '//' + location.host;
        window.location.href = url;
    }
    useEffect(() => {
        setLoaded(true);
        loadData(restauHook);
    }, [restauHook.restauId]);

    return (
        <div>
            {loaded ? (
                <div>
                    <img
                        className={classes.loadingImage}
                        src="../../img/spinner.gif"
                    />
                </div>
            ) : (
                <>
                    {/* E      </Container>
nd hero unit */}
                    {subscriptionData ? (
                        <>
                            {sessionId && success && (
                                <Alert severity="info">
                                    <b>successfully subscribed</b>
                                </Alert>
                            )}
                            {sessionId && !success && (
                                <Alert severity="error">
                                    <b> Subscription was not successfull</b>
                                </Alert>
                            )}
                            <Grid
                                container
                                spacing={5}
                                alignItems="flex-end"
                                style={{ marginTop: '20px' }}
                            >
                                <Grid item xs={12} sm={12}>
                                    <Card>
                                        <CardHeader
                                            title={subscriptionData.name}
                                            subheader={
                                                subscriptionData.description
                                            }
                                            titleTypographyProps={{
                                                align: 'center',
                                            }}
                                            subheaderTypographyProps={{
                                                align: 'center',
                                            }}
                                            action={<StarIcon />}
                                            className={classes.cardHeader}
                                        />
                                        <CardContent>
                                            <div
                                                className={classes.cardPricing}
                                            >
                                                <Typography
                                                    component="h2"
                                                    variant="h3"
                                                    color="textPrimary"
                                                >
                                                    $
                                                    {
                                                        subscriptionData.afterDiscountPrice
                                                    }
                                                </Typography>
                                                <Typography
                                                    variant="h6"
                                                    color="textSecondary"
                                                >
                                                    /mo
                                                </Typography>
                                                {subscriptionData.discountPrice &&
                                                    subscriptionData.discountPrice >
                                                        0 && (
                                                        <Typography
                                                            variant="h5"
                                                            color="textSecondary"
                                                        >
                                                            <span
                                                                style={{
                                                                    marginLeft:
                                                                        '3px',
                                                                    color: 'red',
                                                                    textDecoration:
                                                                        'line-through',
                                                                }}
                                                            >
                                                                $
                                                                {
                                                                    subscriptionData.actualPrice
                                                                }
                                                            </span>
                                                        </Typography>
                                                    )}
                                            </div>
                                            <ul>
                                                {productDescription.map(
                                                    (line) => (
                                                        <Typography
                                                            component="li"
                                                            variant="subtitle1"
                                                            align="center"
                                                            key={line}
                                                        >
                                                            {line}
                                                        </Typography>
                                                    )
                                                )}
                                            </ul>
                                        </CardContent>
                                        <CardActions>
                                            {subscriptionData.subscriptionStatus !==
                                            'ACTIVE' ? (
                                                <Button
                                                    fullWidth
                                                    variant={'contained'}
                                                    color="primary"
                                                    onClick={(e) =>
                                                        open(
                                                            subscriptionData.url
                                                        )
                                                    }
                                                >
                                                    {'Subscribe'}
                                                </Button>
                                            ) : (
                                                <Button
                                                    fullWidth
                                                    variant={'contained'}
                                                    color="primary"
                                                    disabled
                                                >
                                                    {'Subscribed'}
                                                </Button>
                                            )}
                                        </CardActions>
                                        {sessionId && (
                                            <div>
                                                <Button
                                                    fullWidth
                                                    variant={'contained'}
                                                    color="primary"
                                                    onClick={(e) =>
                                                        home(
                                                            subscriptionData.url
                                                        )
                                                    }
                                                >
                                                    {'Home'}
                                                </Button>
                                            </div>
                                        )}
                                    </Card>
                                </Grid>
                            </Grid>
                            {/*
                            <br />

                            <Typography
                                component="h3"
                                variant="h5"
                                color="inherit"
                                noWrap
                            >
                                Payment History
                            </Typography>
                            <br />
                            <TableContainer component={Paper}>
                                <Table
                                    className={classes.table}
                                    aria-label="simple table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">
                                                Invoice/Receipt
                                            </TableCell>
                                            <TableCell>Start Date</TableCell>
                                            <TableCell align="left">
                                                Due Date
                                            </TableCell>
                                            <TableCell align="left">
                                                Payment Status
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {subscriptionData &&
                                            subscriptionData.invoices &&
                                            subscriptionData.invoices.map(
                                                (invoice) => (
                                                    <TableRow key={invoice.id}>
                                                        <TableCell>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => {
                                                                    window.location.href =
                                                                        invoice.hosted_invoice_url;
                                                                }}
                                                            >
                                                                {getStatus(
                                                                    invoice.status,
                                                                    invoice.paid
                                                                )}
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {moment
                                                                .unix(
                                                                    invoice.period_start
                                                                )
                                                                .format(
                                                                    'dddd, MM DD'
                                                                )}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {moment
                                                                .unix(
                                                                    invoice.period_end
                                                                )
                                                                .format(
                                                                    'dddd, MM DD'
                                                                )}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {invoice.status}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                    </TableBody>
                                </Table>
                                                                </TableContainer>*/}
                        </>
                    ) : (
                        <>
                            <div style={{ marginTop: '50px' }}>
                                {!sessionId && (
                                    <b> Subscription is not enabled</b>
                                )}
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
}
