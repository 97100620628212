import React, { useContext } from 'react';
import PhoneLogContext from '../PhoneLogContext';
import SearchCriteria from './SearchCriteria';
import SearchResults from './SearchResults';

function PhoneLogContent() {
    const { filteredCallLogs } = useContext(PhoneLogContext);
    return (
        <div>
            <SearchCriteria />
            <SearchResults />
        </div>
    );
}

PhoneLogContent.propTypes = {};

export default PhoneLogContent;
