import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import Config from '../config/Config';
import { RestauInfoContext } from '../home/RestauInfoContext.js';
import { useStyles } from '../home/styles/useStyles';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import { blue } from '@mui/material/colors';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import MessageDialog from './MessageDialog';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';

const useStylesIn = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStylesTab = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function CRM(props) {
    // const restauHook = props.restauHook;
    const resauInfoContext = useContext(RestauInfoContext);
    const restauHook = resauInfoContext.restauHook;
    const [crmData, setCrmData] = React.useState(undefined);
    const [isOpen, setOpen] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [msgData, setMsgData] = React.useState(null);

    const classes = useStyles();
    const classesIn = useStylesIn();

    async function loadData() {
        if (!window.customerApp.auth().currentUser) {
            // navigate('/login');
            return;
        }

        const token = await window.customerApp.auth().currentUser.getIdToken();

        let config = {
            headers: {
                token: token,
            },
        };

        axios
            .post(
                Config.getCustomerData,
                { restauId: restauHook.restauId },
                config
            )
            .then(function (response) {
                console.log('response', response.data);
                setCrmData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

        return;
    }
    useEffect(() => {
        setLoaded(true);
        loadData(restauHook);
    }, [restauHook.restauId]);

    function openDialog(key) {
        let msg = { ...crmData.data[key] };
        msg['phoneNumber'] = key;
        setMsgData(msg);

        setOpen(true);
    }

    const classesTab = useStylesTab();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div>
            {!crmData ? (
                <div>
                    <img
                        className={classes.loadingImage}
                        src="../../img/spinner.gif"
                    />
                </div>
            ) : (
                <>
                    <div style={{ maxWidth: 350 }}>
                        <MessageDialog
                            isOpen={isOpen}
                            setOpen={setOpen}
                            msgData={msgData}
                        />
                        <div className={classesTab.root}>
                            <AppBar position="static">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="simple tabs example"
                                >
                                    <Tab label="Messages" {...a11yProps(0)} />
                                    <Tab
                                        label="All Customers"
                                        {...a11yProps(1)}
                                    />
                                    <Tab
                                        label="All Customers"
                                        {...a11yProps(1)}
                                    />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={value} index={0}>
                                <FormControl component="fieldset">
                                    <List className={classesIn.root}>
                                        {Object.keys(crmData.data).map(
                                            (tkey, index) => (
                                                <ListItem
                                                    button
                                                    onClick={(e) => {
                                                        openDialog(tkey);
                                                    }}
                                                >
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            className={
                                                                classesIn.avatar
                                                            }
                                                        >
                                                            <AccountCircleIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={
                                                            crmData.data[tkey][
                                                                'name'
                                                            ]
                                                        }
                                                        secondary={tkey}
                                                    />
                                                </ListItem>
                                            )
                                        )}
                                    </List>
                                </FormControl>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                Work in progress
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                Item Three
                            </TabPanel>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
