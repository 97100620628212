import React from 'react';
import PropTypes from 'prop-types';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useContext } from 'react';
import VisOverrideContext from '../../VisOverrideContext';

function OverrideRuleDisplayTable(props) {
    const { ruleData } = props;
    const { computeTimeString, deleteDayDataFromRule } =
        useContext(VisOverrideContext);

    return (
        <TableContainer component={Paper}>
            <Table aria-label="override time table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">
                            <Typography variant="body1">Day/s</Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography>Time</Typography>
                        </TableCell>
                        <TableCell width="20" align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ruleData &&
                        ruleData.days &&
                        Object.keys(ruleData.days).map((dayKey, index) => {
                            let dayData = ruleData.days[dayKey];
                            return (
                                <TableRow
                                    key={`${ruleData.id}_${index}`}
                                    sx={{ verticalAlign: 'middle' }}
                                >
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        align="left"
                                    >
                                        <Typography>{dayData.day}</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography>
                                            {computeTimeString(
                                                dayData.time,
                                                ruleData.dateTimeType
                                            )}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center" width="20">
                                        <DeleteIcon
                                            style={{ fontSize: '18' }}
                                            onClick={() =>
                                                deleteDayDataFromRule(
                                                    ruleData.id,
                                                    dayData.day
                                                )
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

OverrideRuleDisplayTable.propTypes = {
    ruleData: PropTypes.shape({
        id: PropTypes.string.isRequired,
        days: PropTypes.shape({
            day: PropTypes.string,
            time: PropTypes.shape({}),
        }).isRequired,
        dateTimeType: PropTypes.string,
    }).isRequired,
};

export default OverrideRuleDisplayTable;
