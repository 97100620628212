import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Typography,
} from '@mui/material';
import React from 'react';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import { useStyles } from '../../../home/useStyles';
import AssignStationsDialog from './AssignStationsDialog';

function PrintStations(props) {
    const {
        openDialogToAssignStations,
        setopenDialogToAssignStations,
        handleStationMappingUpdate,
        stationMappingData,
        loading,
    } = props;
    const classes = useStyles();

    return (
        <Grid container spacing={0}>
            <Grid item xs={7.5}>
                <Box>
                    <Typography variant="h6" component="h6">
                        PrintStations
                    </Typography>
                    <Typography variant="subtitle2" component="sub">
                        if not specified, Stations will be defaulted from the
                        category
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={4.5}>
                <Button
                    sx={{
                        //alignSelf: 'flex-end',
                        border: 0,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={() => {
                        setopenDialogToAssignStations(true);
                    }}
                >
                    <AddCircleSharpIcon color="primary" sx={{ fontSize: 23 }} />
                    <Typography sx={{ ml: 0.5 }}>Assign Station</Typography>
                </Button>
                <AssignStationsDialog
                    open={openDialogToAssignStations}
                    setOpen={setopenDialogToAssignStations}
                    itemStationMapping={stationMappingData}
                    handleAssignStations={handleStationMappingUpdate}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl
                    sx={{ m: 2 }}
                    component="fieldset"
                    variant="standard"
                >
                    {loading ? (
                        <div>
                            <img
                                alt="loading"
                                style={{ width: '50%' }}
                                className={classes.loadingImage}
                                src="../../img/spinner.gif"
                            />
                        </div>
                    ) : (
                        <FormGroup>
                            {stationMappingData &&
                                Object.keys(stationMappingData).map(
                                    (stationId) => {
                                        return (
                                            //display only if the station is mapped to this item
                                            stationMappingData[stationId]
                                                .isMapped && (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    key={stationId}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                sx={{
                                                                    '& .MuiSvgIcon-root':
                                                                        {
                                                                            fontSize: 20,
                                                                        },
                                                                }}
                                                                checked={
                                                                    stationMappingData[
                                                                        stationId
                                                                    ].isMapped
                                                                }
                                                                name={stationId}
                                                            />
                                                        }
                                                        label={
                                                            stationMappingData[
                                                                stationId
                                                            ].name
                                                        }
                                                    ></FormControlLabel>
                                                    {(stationMappingData[
                                                        stationId
                                                    ].mappingFromCategory ||
                                                        stationMappingData[
                                                            stationId
                                                        ]
                                                            .mappingFromDefault) && (
                                                        <Chip
                                                            color="primary"
                                                            label={
                                                                stationMappingData[
                                                                    stationId
                                                                ]
                                                                    .mappingFromCategory
                                                                    ? 'Category'
                                                                    : 'Default'
                                                            }
                                                            variant="outlined"
                                                            sx={{
                                                                fontSize:
                                                                    '15px',
                                                                height: '20px',
                                                                '& .MuiChip-label':
                                                                    {
                                                                        padding:
                                                                            '5px',
                                                                    },
                                                            }}
                                                        />
                                                    )}
                                                </Grid>
                                            )
                                        );
                                    }
                                )}
                        </FormGroup>
                    )}
                </FormControl>
            </Grid>
        </Grid>
    );
}

PrintStations.propTypes = {
    openDialogToAssignStations: PropTypes.bool,
    setopenDialogToAssignStations: PropTypes.func,
    handleStationMappingUpdate: PropTypes.func.isRequired,
    stationMappingData: PropTypes.shape({}).isRequired,
    loading: PropTypes.bool,
};

PrintStations.defaultProps = {
    loading: false,
};

export default PrintStations;
