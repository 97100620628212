import { CardActionArea, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import PropTypes from 'prop-types';
import React from 'react';

export default function QRTemplateCard(props) {
    const { disabled, src, selected, onSelected } = props;
    const handleSelected = () => onSelected(src);
    return (
        <Card
            sx={{
                minHeight: 120,
                height: '100%',
                padding: '2px',
                borderWidth: selected ? 2 : 0,
                borderColor: selected ? '#333' : 'transparent',
                borderStyle: 'solid',
            }}
        >
            <CardActionArea disabled={disabled} onClick={handleSelected}>
                {src ? (
                    <CardMedia
                        component="img"
                        sx={{ height: 116 }}
                        image={src}
                        alt="QR Template"
                    />
                ) : (
                    <Typography
                        variant="body2"
                        textAlign="center"
                        style={{
                            marginTop: 40,
                            marginLeft: 10,
                            marginRight: 10,
                        }}
                    >
                        Template not available
                    </Typography>
                )}
            </CardActionArea>
        </Card>
    );
}

QRTemplateCard.propTypes = {
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    src: PropTypes.string,
    onSelected: PropTypes.func,
};

QRTemplateCard.defaultProps = {
    disabled: false,
    selected: false,
    src: '',
    onSelected: () => {},
};
