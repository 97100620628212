import axios from 'axios';
import { useState } from 'react';
import { useContext } from 'react';
import Config from '../../../config/Config';
import { RestauInfoContext } from '../../../home/RestauInfoContext';

const getFilteredPhoneLogsFromDb = async (payload) => {
    const token = await window.customerApp.auth().currentUser.getIdToken();
    var config = {
        headers: {
            token: token,
            'Content-Type': 'application/json',
        },
    };

    //function to fetch the Phone Settings from databasse
    return await axios.post(Config.getFilteredCallRecordsData, payload, config);
};

function usePhoneLogs() {
    const { restauHook } = useContext(RestauInfoContext);
    const [searchPhNumber, setSearchPhNumber] = useState('');
    const [searchStartDate, setSearchStartDate] = useState(null);
    const [searchEndDate, setSearchEndDate] = useState(null);
    const [searchCallStatus, setSearchCallStatus] = useState(null);
    const [filters, setFilters] = useState([]);
    const [filteredCallLogs, setFilteredCallLogs] = useState({});
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    //const [successMsg, setSuccessMsg] = useState('');

    const resetFilters = () => {
        setSearchPhNumber('');
        setSearchStartDate(null);
        setSearchEndDate(null);
        setSearchCallStatus(null);
        setErrMsg('');
    };

    const callStatusItems = {
        Pressed_2_Connected: 'Answered Call',
        Pressed_2_But_Busy: 'Missed Call answered by StrideQ',
        Pressed_1: 'Online Order',
        Pressed_Banquet: 'Banquet Inquiry',
        Pressed_Catering: 'Catering Inquiry',
        Pressed_Store_hours: 'Store Hours Inquiry',
        Pressed_Reservation: 'Reservation Inquiry',
        // Timeout_Or_no_match: 'Timeout or No Match',
        // Unknown: 'Unknown',
    };

    const fetchSubscriptionPlanData = async () => {
        //debugger;
        if (!restauHook.restauId) {
            console.log('ReastauId not available.');
            return '';
        }
        const restauId = restauHook.restauId;
        const token = await window.customerApp.auth().currentUser.getIdToken();

        let subscriptionPlanData = {};
        let config = {
            headers: {
                token: token,
            },
        };
        //debugger;
        let response = await axios
            .post(
                Config.getSubscriptionPlanForRestau,
                { restauId: restauId },
                config
            )
            .catch(function (error) {
                //let url = location.protocol + '//' + location.host;
                //window.location.href = url + '/login';
                console.log(error);
            });

        if (response && response.status === 200) {
            restauHook.setSubscriptionPlan(response.data);
            subscriptionPlanData = response.data;
        }

        if (
            subscriptionPlanData &&
            subscriptionPlanData.productPlanPackages &&
            subscriptionPlanData.productPlanPackages.custom
        ) {
            Object.keys(
                subscriptionPlanData.productPlanPackages.custom
            ).forEach((x) => {
                let pkgName = 'Pressed_custom_' + x;
                callStatusItems[pkgName] =
                    subscriptionPlanData.productPlanPackages.custom[x].label;
            });
        }
    };

    const getFilteredPhoneLogs = () => {
        setLoading(true);
        //debugger;
        let reqBody = {
            restauId: restauHook.restauId,
        };

        filters.forEach((fil) => {
            reqBody[fil.key] = fil.val;
        });

        console.log('Request Body: ', reqBody);

        getFilteredPhoneLogsFromDb(reqBody)
            .then((response) => {
                //debugger;
                console.log('Response: ', response);
                if (Object.keys(response.data).length > 0) {
                    setErrMsg('');
                } else {
                    setErrMsg('No results found.');
                }

                setFilteredCallLogs(response.data);
                //refreshData();
                //setSuccessMsg('Data saved succesfully !');
            })
            .catch((err) => {
                //debugger;
                console.log(err);
                setErrMsg('Failed to fetch Phone Logs.');
                // setSuccessMsg('');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        searchPhNumber,
        setSearchPhNumber,
        searchStartDate,
        setSearchStartDate,
        searchEndDate,
        setSearchEndDate,
        searchCallStatus,
        setSearchCallStatus,
        getFilteredPhoneLogs,
        filters,
        setFilters,
        filteredCallLogs,
        setFilteredCallLogs,
        resetFilters,
        callStatusItems,
        loading,
        setLoading,
        errMsg,
        fetchSubscriptionPlanData,
    };
}

export default usePhoneLogs;
