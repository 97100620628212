import FileDownload from '@mui/icons-material/FileDownload';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Card, CardMedia, Grid, Typography } from '@mui/material';
import React from 'react';

import { useContext } from 'react';
import QRContext from '../QRContext';

function Preview() {
    const { selectedTemplate, downloadQRCodes, activeTemplate, isProcessing } =
        useContext(QRContext);

    return (
        <div style={{ width: 250, marginRight: 25 }}>
            <Typography variant="h6" component="h6">
                Preview
            </Typography>
            <div style={{ minHeight: 300, marginBottom: 5 }}>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            backgroundColor: '#efefef',
                            display: 'flex',
                            flexGrow: 1,
                            borderRadius: 1.5,
                            padding: 1.5,
                            height: 'fit-content',
                            minHeight: 150,
                        }}
                    >
                        <Card
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                width: '100%',
                            }}
                        >
                            {selectedTemplate ? (
                                <CardMedia
                                    component="img"
                                    image={selectedTemplate}
                                    alt="QR Template"
                                />
                            ) : (
                                <Typography variant="body2" textAlign="center">
                                    Template not selected
                                </Typography>
                            )}
                        </Card>
                    </Grid>
                </Grid>
            </div>

            <div>
                <Typography variant="body2">
                    {activeTemplate?.print_instructions && (
                        <b>Print Instructions: </b>
                    )}
                    {activeTemplate?.print_instructions}
                </Typography>
            </div>

            <Button
                size="small"
                variant="contained"
                startIcon={
                    isProcessing ? (
                        <CircularProgress size={24} />
                    ) : (
                        <FileDownload />
                    )
                }
                onClick={downloadQRCodes}
                disabled={isProcessing}
            >
                Download
            </Button>
        </div>
    );
}

Preview.propTypes = {};

export default Preview;
