import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Typography } from '@mui/material';
import PauseLogRow from './PauseLogRow';
import download from '../utils/downloadExcel';
import Grid from '@mui/material/Grid';
import AuditTableHead from './AuditTableHead';
import SetTablePagination from '../utils/setTablePagination';

export default function PauseLogTable(props) {
    const tableHead = [
        'Total Paused Time',
        'Paused User',
        'Paused Time',
        'Resume User',
        'Resume Time',
    ];
    function createData(
        index,
        totalPausedHr,
        pauseUser,
        pauseTime,
        resumeUser,
        resumeTime
    ) {
        return {
            index,
            totalPausedHr,
            pauseUser,
            pauseTime,
            resumeUser,
            resumeTime,
        };
    }
    var items = [];
    var length = 0;
    if (props.pauseData != '' && props.pauseData != undefined) {
        props.pauseData.forEach((item) => {
            items.push(
                createData(
                    item.index,
                    item.totalPausedHr,
                    item.pauseUser,
                    item.pauseTime,
                    item.resumeUser,
                    item.resumeTime
                )
            );
        });
    }
    const rows = items;
    length = rows.length;

    const useStyles2 = makeStyles({
        table: {
            minWidth: 650,
        },
    });
    const classes = useStyles2();
    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const rowsPerPageOptions = [5, 10, 15, { label: 'All', value: -1 }];

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    function ExportToExcel() {
        const format = '.xlsx';
        const fileName = 'Paused Order' + format;
        download.excelDownload(rows, fileName);
    }
    return (
        <div>
            {length == 0 ? (
                <></>
            ) : (
                <>
                    <Typography
                        component="h2"
                        variant="h5"
                        color="inherit"
                        noWrap
                    >
                        Paused Report
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table
                            className={classes.table}
                            aria-label="custom pagination table"
                        >
                            <AuditTableHead
                                headData={tableHead}
                            ></AuditTableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? rows.slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                      )
                                    : rows
                                ).map((row, index) => (
                                    <PauseLogRow row={row}></PauseLogRow>
                                ))}

                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{ height: 53 * emptyRows }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            <SetTablePagination
                                rows={rows}
                                rowsPerPageOptions={rowsPerPageOptions}
                                page={page}
                                setPage={setPage}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                            ></SetTablePagination>
                        </Table>
                    </TableContainer>
                    <Grid container justifyContent="flex-end">
                        <Button
                            color="primary"
                            style={{
                                height: 'max-content',
                                marginTop: '20px',
                            }}
                            variant="contained"
                            onClick={() => ExportToExcel()}
                        >
                            Export to Excel
                        </Button>
                    </Grid>
                </>
            )}
        </div>
    );
}
