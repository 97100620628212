import React from 'react';
import QRPaperSizes from './QRPaperSizes';
import Preview from './QRPreview';

import Grid from '@mui/material/Grid';
import QRTemplates from './QRTemplates';

import { posterPaperSizes, posterTypes } from '../constants';

import QRServiceTypes from './QRServiceTypes';
import QRPosterSettings from './QRPosterSettings';

function PosterPanel() {
    return (
        <Grid
            container
            columnSpacing={4}
            sx={{ justifyContent: 'space-between' }}
        >
            <Grid item sx={{ flexGrow: 1 }} xs={12} md={7}>
                <Grid container rowSpacing={3}>
                    <Grid item xs={12}>
                        <Grid container columnSpacing={1}>
                            <Grid item xs={6}>
                                <QRServiceTypes
                                    label={'Poster Type'}
                                    serviceTypes={posterTypes}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <QRPaperSizes paperSizes={posterPaperSizes} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <QRTemplates />
                    </Grid>
                    <Grid item xs={12}>
                        <QRPosterSettings />
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                sx={{
                    flexGrow: 0,
                }}
                xs={12}
                md={5}
            >
                <Preview />
            </Grid>
        </Grid>
    );
}

PosterPanel.propTypes = {};

export default PosterPanel;
