import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Rating } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment-timezone';
import React, { useContext, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import Config from '../config/Config';
import { RestauInfoContext } from '../home/RestauInfoContext.js';
import { useStyles } from '../home/styles/useStyles';
import Utils from '../utils/Utils';
import PriceGrid from './PriceGrid';
import DineInDataExport from './DineInDataExport';
import ItemDetailedDataExport from './ItemDetailedDataExport';
import TextField from '@mui/material/TextField';

const { getAmountFromKey } = { ...Utils };
const getUTCDate = (momentObject, isEndDate = false) => {
    const formatter = isEndDate
        ? {
              hour: 23,
              minute: 59,
              second: 59,
          }
        : {
              hour: 0,
              minute: 0,
              second: 0,
          };

    return momentObject.set(formatter).toISOString();
};
const { default: axios } = require('axios');

const Title = styled.div`
    font-family: 'Roboto';
`;

const corianderGreen = '#27ae60'; // Original #f44336

export const ConfirmButton = styled(Title)`
    margin-top: 10px;
    margin-bottom: 40px;
    color: white;
    font-weight: bold;
    height: 40px;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    width: 200px;
    cursor: pointer;
    background-color: ${corianderGreen};
    ${({ disabled }) =>
        disabled &&
        `
    opactity: .5; 
    background-color: grey; 
    pointer-events: none; 
  `}
`;

export default function Summary() {
    const { restauHook, summaryHook } = useContext(RestauInfoContext);
    const restauTimeZone = restauHook.restauTimeZone;

    const [summaryLoader, setSummaryLoader] = React.useState(false);
    const [reviewLoader, setReviewLoader] = React.useState(false);
    const [dineInLoader, setDineInLoader] = React.useState(false);
    const [showProcessingFees, setShowProcessingFees] = React.useState(false);
    const classes = useStyles();

    function setDateRange(date) {
        summaryHook.setStartDate(date);
        if (summaryHook.endDate < date) {
            summaryHook.setEndDate(date);
        }
    }
    async function loadData(restauHook) {
        if (!window.customerApp.auth().currentUser) {
            navigate('/login');
            return;
        }
        const token = await window.customerApp.auth().currentUser.getIdToken();
        const admin = window.customerApp.database();
        let showProcessFee = (
            await admin
                .ref('menus/' + restauHook.restauId + '/details/showProcessFee')
                .once('value')
        ).val();
        setShowProcessingFees(showProcessFee);
        let config = {
            headers: {
                token: token,
            },
        };
        if (!summaryHook.startDate || !summaryHook.endDate) {
            //alert('Please select valid date range');
            return;
        }
        setSummaryLoader(true);
        setReviewLoader(true);
        setDineInLoader(true);
        summaryHook.setValue(null);
        summaryHook.setReview(null);
        summaryHook.setRestauId(restauHook.restauId);
        summaryHook.setDineInReport(null);

        const startDate = getUTCDate(summaryHook.startDate);
        const endDate = getUTCDate(summaryHook.endDate, true);

        axios
            .post(
                Config.getSummaryReport,
                {
                    restauId: restauHook.restauId,
                    startDate,
                    endDate,
                },
                config
            )
            .then(function (response) {
                console.log(response.data);
                summaryHook.setValue(response.data);
                setSummaryLoader(false);
            })
            .catch(function (error) {
                console.log(error);
                setSummaryLoader(false);
            });

        axios
            .post(
                Config.getDineInSummaryReport,
                {
                    restauId: restauHook.restauId,
                    startDate: startDate,
                    endDate: endDate,
                },
                config
            )
            .then(function (response) {
                console.log(response.data);
                summaryHook.setDineInReport(response.data);
                setDineInLoader(false);
            })
            .catch(function (error) {
                console.log(error);
                setDineInLoader(false);
            });
        axios
            .post(
                Config.getCustomerReviews,
                {
                    restauId: restauHook.restauId,
                    startDate: startDate,
                    endDate: endDate,
                },
                config
            )
            .then(function (response) {
                summaryHook.setReview(response.data);
                setReviewLoader(false);
            })
            .catch(function (error) {
                setReviewLoader(false);
            });
        return;
    }

    function reviewOfItems() {
        let reviews = {};

        if (!summaryHook.review || !summaryHook.review.reviews) {
            return reviews;
        }
        //alert(summaryHook.review.reviews.length);
        for (let i = 0; i < summaryHook.review.reviews.length; i++) {
            if (!summaryHook.review.reviews[i].ratings) {
                continue;
            }
            let subData = summaryHook.review.reviews[i].ratings;

            Object.keys(subData).forEach(function (nKey) {
                if (!reviews[subData[nKey].name]) {
                    reviews[subData[nKey].name] = {
                        name: subData[nKey].name,
                        rating: subData[nKey].rating,
                        count: 1,
                    };
                } else {
                    let count = reviews[subData[nKey].name].count + 1;
                    let totalRating =
                        reviews[subData[nKey].name].rating +
                        subData[nKey].rating;
                    totalRating = totalRating / 2;
                    reviews[subData[nKey].name] = {
                        name: subData[nKey].name,
                        rating: totalRating,
                        count: count,
                    };
                }
            });
        }

        for (let i = 0; i < summaryHook.review.dineInReview.length; i++) {
            if (!summaryHook.review.dineInReview[i].ratings) {
                continue;
            }
            let subData = summaryHook.review.dineInReview[i].ratings;

            Object.keys(subData).forEach(function (nKey) {
                if (!reviews[subData[nKey].name]) {
                    reviews[subData[nKey].name] = {
                        name: subData[nKey].name,
                        rating: subData[nKey].rating,
                        count: 1,
                    };
                } else {
                    let count = reviews[subData[nKey].name].count + 1;
                    let totalRating =
                        reviews[subData[nKey].name].rating +
                        subData[nKey].rating;
                    totalRating = totalRating / 2;
                    reviews[subData[nKey].name] = {
                        name: subData[nKey].name,
                        rating: totalRating,
                        count: count,
                    };
                }
            });
        }

        return reviews;
    }

    function getTopItems() {
        let sortItems = [];
        if (!summaryHook.value || !summaryHook.value.summary) {
            return sortItems;
        }
        let allItems = summaryHook.value.summary['customerReport'];
        if (allItems) {
            let items = allItems.items;

            Object.keys(items).forEach(function (k) {
                sortItems.push({
                    item: k,
                    total: items[k].quantity,
                    price: items[k].price,
                });
            });
        }

        let allDineInItems =
            summaryHook.dineInReport && summaryHook.dineInReport.summary
                ? summaryHook.dineInReport.summary['customerReport']
                : null;
        if (allDineInItems) {
            let dineItems = allDineInItems.items;

            Object.keys(dineItems).forEach(function (dineItem) {
                let itemFound = false;
                for (let i = 0; i < sortItems.length; i++) {
                    //sortItems[]
                    if (sortItems[i].item === dineItem) {
                        sortItems[i].total += dineItems[dineItem].quantity;
                        sortItems[i].price += dineItems[dineItem].price;
                        itemFound = true;
                    }
                }
                if (!itemFound) {
                    sortItems.push({
                        item: dineItem,
                        total: dineItems[dineItem].quantity,
                        price: dineItems[dineItem].price,
                    });
                }
            });
        }
        if (sortItems.length > 0) {
            sortItems.sort(function (a, b) {
                return b.total - a.total;
            });
        }

        return sortItems;
    }

    function getTopSoldItems(items) {
        let reviews = reviewOfItems();

        return (
            <TableContainer style={{ maxHeight: '400px' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow
                            style={{
                                backgroundColor: '#f5f5f5',
                                height: '35px',
                            }}
                        >
                            <TableCell>Item Name</TableCell>
                            <TableCell>quantity</TableCell>
                            <TableCell>Total Amount</TableCell>
                            <TableCell>Reviews</TableCell>
                            <TableCell>Reviewed By</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(items).map((key, index) => (
                            <TableRow key={'key'}>
                                <TableCell>{items[key]['item']}</TableCell>
                                <TableCell>{items[key]['total']}</TableCell>
                                <TableCell>
                                    {'$' + items[key]['price'] / 100}
                                </TableCell>
                                <TableCell>
                                    <Rating
                                        name="read-only"
                                        value={
                                            reviews[items[key]['item']]
                                                ? reviews[items[key]['item']]
                                                      .rating
                                                : ''
                                        }
                                        readOnly
                                    />
                                </TableCell>
                                <TableCell>
                                    {reviews[items[key]['item']]
                                        ? reviews[items[key]['item']].count
                                        : ''}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
        //return itemText;
    }

    function getCommentsOnly(item) {
        console.log(item.comments);
        if (!item.comments || !item.comments.trim()) {
            return '';
        } else {
            return (
                <TableRow key={item.time}>
                    <TableCell>{item.time}</TableCell>
                    <TableCell>{item.comments}</TableCell>
                    <TableCell>
                        <Rating
                            name="read-only"
                            value={
                                item.ratings &&
                                item.ratings.Overall_Food &&
                                item.ratings.Overall_Food.rating
                                    ? item.ratings.Overall_Food.rating
                                    : ''
                            }
                            readOnly
                        />
                    </TableCell>

                    <TableCell>
                        <Rating
                            name="read-only"
                            value={
                                item.ratings &&
                                item.ratings.Overall_Service &&
                                item.ratings.Overall_Service.rating
                                    ? item.ratings.Overall_Service.rating
                                    : ''
                            }
                            readOnly
                        />
                    </TableCell>

                    <TableCell>
                        <Rating
                            name="read-only"
                            value={
                                item.ratings &&
                                item.ratings.Online_Ordering &&
                                item.ratings.Online_Ordering.rating
                                    ? item.ratings.Online_Ordering.rating
                                    : ''
                            }
                            readOnly
                        />
                    </TableCell>
                </TableRow>
            );
        }
    }

    function getCustomerReviews(items) {
        return (
            <TableContainer style={{ maxHeight: '400px' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow
                            style={{
                                backgroundColor: '#f5f5f5',
                                height: '35px',
                            }}
                        >
                            <TableCell>Date</TableCell>
                            <TableCell>Customer Comment</TableCell>
                            <TableCell>Overall Food Ratings</TableCell>
                            <TableCell>Overall Service Ratings</TableCell>
                            <TableCell>Online Ordering Ratings</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => getCommentsOnly(item))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
        //return itemText;
    }
    function getDays(days) {
        var date = new Date();
        date.setDate(date.getDate() + days);
        return date;
        // return moment() + days;
    }
    function getLoader(loader) {
        if (loader) {
            return (
                <div>
                    <img
                        className={classes.loadingImage}
                        src="../../img/spinner.gif"
                    />
                </div>
            );
        } else {
            return <></>;
        }
    }
    function getPaidOnline(payment) {
        if (!payment || !payment['paymentTotal']) {
            return '$0';
        }
        let paymentTotal = parseFloat(payment['paymentTotal']).toFixed(2);
        let paidInStore = parseFloat(
            payment['paidInStore'] ? payment['paidInStore'] : 0
        ).toFixed(2);
        let paidInTerminal = parseFloat(
            payment['paidInTerminal'] ? payment['paidInTerminal'] : 0
        ).toFixed(2);
        let otherTotal = parseFloat(paidInStore + paidInTerminal).toFixed(2);
        return parseFloat(paymentTotal - otherTotal).toFixed(2);
    }
    function getMergedReviews() {
        let mergedReviews = [];
        if (
            summaryHook.review &&
            summaryHook.review.reviews &&
            summaryHook.review.reviews.length > 0
        ) {
            mergedReviews = summaryHook.review.reviews;
        }

        if (
            summaryHook.review &&
            summaryHook.review.dineInReview &&
            summaryHook.review.dineInReview.length > 0
        ) {
            mergedReviews = mergedReviews.concat(
                summaryHook.review.dineInReview
            );
        }
        return mergedReviews;
    }
    function getOnlineTipPercentage() {
        if (
            summaryHook.value &&
            summaryHook.value.summary &&
            summaryHook.value.summary.customerReport &&
            summaryHook.value.summary.customerReport.tip
        ) {
            return (
                parseFloat(
                    (100 * summaryHook.value.summary.customerReport.tip) /
                        summaryHook.value.summary.customerReport.subTotal
                ).toFixed(2) + '%'
            );
            //return "0%";
        } else {
            return '0%';
        }
    }

    function getDineInTipPercentage() {
        if (
            summaryHook.dineInReport &&
            summaryHook.dineInReport.summary &&
            summaryHook.dineInReport.summary.customerReport &&
            summaryHook.dineInReport.summary.customerReport.tip
        ) {
            return (
                parseFloat(
                    (100 *
                        summaryHook.dineInReport.summary.customerReport.tip) /
                        summaryHook.dineInReport.summary.customerReport.subTotal
                ).toFixed(2) + '%'
            );
        } else {
            return '0%';
        }
    }

    useEffect(() => {
        console.log('Loading Summary Report');
        console.log(summaryHook);
        console.log(restauHook);
        if (
            summaryHook.restauId != null &&
            summaryHook.restauId != restauHook.restauId
        ) {
            //set the start and end dates, based on the restaurant timezone
            summaryHook.setStartDate(moment().tz(restauTimeZone));
            summaryHook.setEndDate(moment().tz(restauTimeZone));
            loadData(restauHook);
        }
    }, [restauHook.restauId]);

    let topItems = getTopItems();
    let mergedReviews = getMergedReviews();
    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'baseline',
                    gap: 12,
                }}
            >
                <DatePicker
                    label="From Date"
                    value={summaryHook.startDate}
                    autoOk
                    inputVariant="outlined"
                    size="small"
                    margin="dense"
                    onChange={(date) => setDateRange(date)}
                    minDate={moment(getDays(-365))}
                    maxDate={moment().add(1, 'days')}
                    selected={moment(summaryHook.startDate)}
                    renderInput={(props) => <TextField {...props} />}
                />
                <DatePicker
                    label="To Date"
                    value={summaryHook.endDate}
                    autoOk
                    inputVariant="outlined"
                    size="small"
                    margin="dense"
                    onChange={(date) => summaryHook.setEndDate(date)}
                    minDate={moment(
                        summaryHook.startDate
                            ? summaryHook.startDate
                            : getDays(-180)
                    )}
                    maxDate={moment().add(1, 'days')}
                    selected={moment(summaryHook.endDate)}
                    renderInput={(props) => <TextField {...props} />}
                />
                <Button
                    color="primary"
                    variant="contained"
                    style={{
                        height: 'max-content',
                    }}
                    onClick={() => {
                        console.log(summaryHook.startDate);
                        console.log(summaryHook.endDate);
                        if (!summaryHook.startDate || !summaryHook.endDate) {
                            alert('Please select valid date range');
                            return;
                        }
                        loadData(restauHook);
                    }}
                >
                    Load Report
                </Button>
            </div>
            <Grid item xs={12}>
                {getLoader(summaryLoader)}
                {summaryHook.value && summaryHook.value.summary && (
                    <div style={{ marginTop: '10px' }}>
                        <Typography
                            style={{
                                topMargin: '20px',
                                bottomMargin: '10px',
                                color: 'green',
                            }}
                            component="b"
                            variant="h6"
                            color="primary"
                            gutterBottom
                        >
                            <b>Online Orders Report</b>
                        </Typography>

                        <Typography paragraph>
                            <Grid container spacing={4}>
                                <br />
                                <PriceGrid
                                    title="Total Payments"
                                    total={getAmountFromKey(
                                        summaryHook.value.summary[
                                            'customerReport'
                                        ],
                                        'paymentTotal'
                                    )}
                                    onlinePayment={
                                        '$' +
                                        getPaidOnline(
                                            summaryHook.value.summary[
                                                'customerReport'
                                            ]
                                        )
                                    }
                                    paidInStore={getAmountFromKey(
                                        summaryHook.value.summary[
                                            'customerReport'
                                        ],
                                        'paidInStore'
                                    )}
                                    paidInTerminal={getAmountFromKey(
                                        summaryHook.value.summary[
                                            'customerReport'
                                        ],
                                        'paidInTerminal'
                                    )}
                                />
                                <PriceGrid
                                    title="Sub Total"
                                    total={getAmountFromKey(
                                        summaryHook.value.summary[
                                            'customerReport'
                                        ],
                                        'subTotal'
                                    )}
                                    date={''}
                                />
                                <PriceGrid
                                    title="Sales Tax"
                                    total={getAmountFromKey(
                                        summaryHook.value.summary[
                                            'customerReport'
                                        ],
                                        'salesTax'
                                    )}
                                    date={''}
                                />
                                <PriceGrid
                                    title="Total Tips"
                                    total={
                                        getAmountFromKey(
                                            summaryHook.value.summary[
                                                'customerReport'
                                            ],
                                            'tip'
                                        ) +
                                        '  (' +
                                        getOnlineTipPercentage() +
                                        ')'
                                    }
                                    date={''}
                                />
                                <PriceGrid
                                    title="Average Order Price"
                                    total={getAmountFromKey(
                                        summaryHook.value.summary[
                                            'customerReport'
                                        ],
                                        'averageOrder'
                                    )}
                                    date={''}
                                />

                                <PriceGrid
                                    title="Total Orders"
                                    total={
                                        summaryHook.value.summary[
                                            'customerReport'
                                        ] &&
                                        summaryHook.value.summary[
                                            'customerReport'
                                        ]['numberOfOrders']
                                    }
                                    date={''}
                                />
                                {showProcessingFees && (
                                    <>
                                        <PriceGrid
                                            title="Processing Fee"
                                            total={getAmountFromKey(
                                                summaryHook.value.summary[
                                                    'customerReport'
                                                ],
                                                'processFee'
                                            )}
                                            date={''}
                                        />
                                        <PriceGrid
                                            title="Net Transfer"
                                            total={getAmountFromKey(
                                                summaryHook.value.summary[
                                                    'customerReport'
                                                ],
                                                'netTransfer'
                                            )}
                                            date={''}
                                        />
                                    </>
                                )}
                            </Grid>
                        </Typography>
                    </div>
                )}
                {getLoader(dineInLoader)}
                {summaryHook.dineInReport &&
                    summaryHook.dineInReport.summary &&
                    Object.keys(summaryHook.dineInReport.summary).length >
                        0 && (
                        <div>
                            <br />
                            <Typography
                                style={{
                                    topMargin: '20px',
                                    color: 'green',

                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    gap: 20,
                                }}
                                component="b"
                                variant="h6"
                                color="primary"
                                gutterBottom
                            >
                                <b>Dine-In Orders Report</b>
                                <DineInDataExport
                                    startDate={summaryHook.startDate}
                                    endDate={summaryHook.endDate}
                                    restauId={restauHook.restauId}
                                    restauTimeZone={restauTimeZone}
                                />
                            </Typography>
                            <br />
                            <Typography paragraph>
                                <Grid container spacing={4}>
                                    <br />
                                    <PriceGrid
                                        title="Total Payments"
                                        total={getAmountFromKey(
                                            summaryHook.dineInReport.summary[
                                                'customerReport'
                                            ],
                                            'paymentTotal'
                                        )}
                                        paidInStore={getAmountFromKey(
                                            summaryHook.dineInReport.summary[
                                                'customerReport'
                                            ],
                                            'paidInStore'
                                        )}
                                        paidInTerminal={getAmountFromKey(
                                            summaryHook.dineInReport.summary[
                                                'customerReport'
                                            ],
                                            'paidInTerminal'
                                        )}
                                    />
                                    <PriceGrid
                                        title="Sub Total"
                                        total={getAmountFromKey(
                                            summaryHook.dineInReport.summary[
                                                'customerReport'
                                            ],
                                            'subTotal'
                                        )}
                                        subTotalTerminal={getAmountFromKey(
                                            summaryHook.dineInReport.summary[
                                                'customerReport'
                                            ],
                                            'subTotalTerminal'
                                        )}
                                        date={''}
                                    />
                                    <PriceGrid
                                        title="Sales Tax"
                                        total={getAmountFromKey(
                                            summaryHook.dineInReport.summary[
                                                'customerReport'
                                            ],
                                            'salesTax'
                                        )}
                                        salesTaxTerminal={getAmountFromKey(
                                            summaryHook.dineInReport.summary[
                                                'customerReport'
                                            ],
                                            'salesTaxTerminal'
                                        )}
                                        date={''}
                                    />
                                    <PriceGrid
                                        title="Total Tips"
                                        total={
                                            getAmountFromKey(
                                                summaryHook.dineInReport
                                                    .summary['customerReport'],
                                                'tip'
                                            ) +
                                            '  (' +
                                            getDineInTipPercentage() +
                                            ')'
                                        }
                                        tipTerminal={getAmountFromKey(
                                            summaryHook.dineInReport.summary[
                                                'customerReport'
                                            ],
                                            'tipTerminal'
                                        )}
                                        date={''}
                                    />
                                    <PriceGrid
                                        title="Average Order Price"
                                        total={getAmountFromKey(
                                            summaryHook.dineInReport.summary[
                                                'customerReport'
                                            ],
                                            'averageOrder'
                                        )}
                                        date={''}
                                    />
                                    <PriceGrid
                                        title="Total Orders"
                                        total={
                                            summaryHook.dineInReport.summary[
                                                'customerReport'
                                            ] &&
                                            summaryHook.dineInReport.summary[
                                                'customerReport'
                                            ]['numberOfOrders']
                                        }
                                        date={''}
                                    />
                                </Grid>
                            </Typography>
                        </div>
                    )}

                {summaryHook.value && summaryHook.value.summary && (
                    <div>
                        <br />
                        <Typography
                            style={{
                                topMargin: '20px',
                                color: 'green',

                                display: 'flex',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                                gap: 20,
                            }}
                            component="b"
                            variant="h6"
                            color="primary"
                            gutterBottom
                        >
                            <b>All Items Report</b>
                            <ItemDetailedDataExport
                                startDate={summaryHook.startDate}
                                endDate={summaryHook.endDate}
                                restauId={restauHook.restauId}
                                restauTimeZone={restauTimeZone}
                            />
                        </Typography>
                        <br />
                        {getTopSoldItems(topItems)}
                    </div>
                )}

                {getLoader(reviewLoader)}
                {summaryHook.review && summaryHook.review.reviews && (
                    <>
                        <br />
                        <div>
                            <Typography
                                style={{
                                    topMargin: '20px',
                                    paddingBottom: '40px',
                                    color: 'green',
                                }}
                                component="b"
                                variant="h6"
                                color="primary"
                                gutterBottom
                            >
                                <b> Customers Comments Report</b>
                            </Typography>
                            <br />
                            &nbsp;
                            {mergedReviews && getCustomerReviews(mergedReviews)}
                        </div>
                    </>
                )}
            </Grid>
        </div>
    );
}
