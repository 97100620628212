import { useContext, useEffect, useState } from 'react';
import { RestauInfoContext } from '../../../home/RestauInfoContext';
import CateringContext from '../../CateringContext';
import Config from '../../../config/Config';
import axios from 'axios';

const useCateringSetting = () => {
    const { restauHook } = useContext(RestauInfoContext);
    const {
        formConfig,
        retrieveFormConfig,
        configLoading,
        setConfigLoading,
        setSnackbarOpen,
        setSnackbarMessage,
    } = useContext(CateringContext);

    const [formTitle, setFormTitle] = useState(
        formConfig && formConfig.form_title
            ? formConfig.form_title
            : 'Catering Inquiry Form'
    );
    const [customMessages, setCustomMessages] = useState(
        formConfig &&
            formConfig.custom_message &&
            formConfig.custom_message.length !== 0
            ? formConfig.custom_message
            : [{ text: '' }]
    );
    const [fields, setFields] = useState(formConfig.fields);
    const [faq, setFaq] = useState(formConfig.faq);
    const [adminContact, setAdminContact] = useState(
        formConfig.admin_contact
            ? formConfig.admin_contact
            : { phone: '', email: '' }
    );
    const [submissionFields, setSubmissionFields] = useState({
        submission_header: formConfig.submission_header,
        submission_body: formConfig.submission_body,
        catering_menu_body: formConfig.catering_menu_body,
        catering_menu_link: formConfig.catering_menu_link,
        home_page_link: formConfig.home_page_link,
    });

    useEffect(() => {
        setFormTitle(
            formConfig && formConfig.form_title
                ? formConfig.form_title
                : 'Catering Inquiry Form'
        );
        setCustomMessages(
            formConfig &&
                formConfig.custom_message &&
                formConfig.custom_message.length !== 0
                ? formConfig.custom_message
                : [{ text: '' }]
        );
        setFields(formConfig.fields);
        setFaq(formConfig.faq);
        setAdminContact(
            formConfig.admin_contact
                ? formConfig.admin_contact
                : { phone: '', email: '' }
        );
        setSubmissionFields({
            submission_header: formConfig.submission_header,
            submission_body: formConfig.submission_body,
            catering_menu_body: formConfig.catering_menu_body,
            catering_menu_link: formConfig.catering_menu_link,
            home_page_link: formConfig.home_page_link,
        });
    }, [formConfig, restauHook.restauId]);

    // API call to update catering form configuration
    const updateFormConfig = async (updatedFormConfigData) => {
        if (!restauHook.restauId) return;
        setConfigLoading(true);
        let reqBody = { restauId: restauHook.restauId, updatedFormConfigData };
        try {
            const token = await window.customerApp
                .auth()
                .currentUser.getIdToken();
            const response = await axios.post(
                Config.updateFormConfig,
                reqBody,
                {
                    headers: {
                        token: token,
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (!response.data || response.data.status === false) {
                throw new Error(
                    'Error from the server: ' + response.data
                        ? response.data.msg
                        : null
                );
            } else {
                retrieveFormConfig();
            }
        } catch (error) {
            console.log('Caught error in updateFormConfig: ', error);
            setSnackbarMessage(
                'Issue retrieving form configurations. Please contact support if the issue persists.'
            );
            setSnackbarOpen(true);
        } finally {
            setConfigLoading(false);
        }
    };

    return {
        formTitle,
        setFormTitle,
        customMessages,
        setCustomMessages,
        fields,
        setFields,
        updateFormConfig,
        faq,
        setFaq,
        adminContact,
        setAdminContact,
        submissionFields,
        setSubmissionFields,
    };
};

export default useCateringSetting;
