import { useState } from 'react';

const defaultHoliday = false;
export function useHoliday(currentVal = false) {
    const [holiday, setHoliday] = useState(currentVal);

    const toggleHoliday = (e) => {
        setHoliday(e.target.checked);
    };

    const resetHoliday = () => {
        setHoliday(currentVal);
    };

    return {
        holiday,
        toggleHoliday,
        resetHoliday,
    };
}
