import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

function EditAudioFile(props) {
    const { editAudioFileDetails, setEditAudioFileDetails } = props;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    required
                    id="file-name"
                    label="FileName"
                    variant="outlined"
                    size="small"
                    value={
                        editAudioFileDetails && editAudioFileDetails.displayName
                    }
                    onChange={(e) =>
                        setEditAudioFileDetails({
                            ...editAudioFileDetails,
                            displayName: e.target.value,
                        })
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    editAudioFileDetails &&
                                    editAudioFileDetails.shared
                                }
                                onChange={(e) =>
                                    setEditAudioFileDetails({
                                        ...editAudioFileDetails,
                                        shared: e.target.checked,
                                    })
                                }
                            />
                        }
                        label="Share across Locations"
                    />
                </FormGroup>
            </Grid>
        </Grid>
    );
}

EditAudioFile.propTypes = {
    editAudioFileDetails: PropTypes.shape({
        displayName: PropTypes.string,
        shared: PropTypes.bool,
    }),
    setEditAudioFileDetails: PropTypes.func,
};

export default EditAudioFile;
