import React from 'react';
import PropTypes from 'prop-types';
import DrawerHeader from './DrawerHeader';
import { IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/material/styles';

function AppLogo(props) {
    const theme = useTheme();
    const { onDrawerClose } = props;
    return (
        <DrawerHeader>
            <div
                style={{
                    justifyContent: 'space-between',
                    display: 'flex',
                    flexGrow: 1,
                }}
            >
                <div style={{ paddingLeft: 10 }}>
                    <img
                        alt="logo"
                        src="../../img/strideQ-logo.png"
                        style={{ height: 40 }}
                    />
                </div>
                <IconButton onClick={onDrawerClose}>
                    {theme.direction === 'ltr' ? (
                        <ChevronLeftIcon />
                    ) : (
                        <ChevronRightIcon />
                    )}
                </IconButton>
            </div>
        </DrawerHeader>
    );
}

AppLogo.propTypes = {
    onDrawerClose: PropTypes.func.isRequired,
};

export default AppLogo;
