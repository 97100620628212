import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import InfoIcon from '@mui/icons-material/Info';

import PropTypes from 'prop-types';

function InputHint(props) {
    const { children } = props;
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <InfoIcon
                aria-owns={open ? 'foodCard-popover' : undefined}
                aria-haspopup="true"
                fontSize="small"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            />
            <Popover
                id="foodCard-popover"
                style={{ pointerEvents: 'none' }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                PaperProps={{
                    style: { maxWidth: 200 },
                }}
            >
                {children}
            </Popover>
        </>
    );
}

InputHint.propTypes = {
    children: PropTypes.node.isRequired,
};

export default InputHint;
