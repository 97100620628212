import React, { memo } from 'react';
import { StyledEngineProvider } from '@mui/material';
import AudioPlayer from 'react-h5-audio-player';
import PropTypes from 'prop-types';

function AudioPreview(props) {
    const { audioFile } = props;
    return (
        <StyledEngineProvider injectFirst>
            <AudioPlayer
                volume={0.75}
                autoPlay={false}
                src={URL.createObjectURL(audioFile)}
            />
        </StyledEngineProvider>
    );
}

AudioPreview.propTypes = {
    audioFile: PropTypes.shape({}),
};

export default memo(AudioPreview);
