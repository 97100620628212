import { useState } from 'react';

export function useOpenFood(defaultChoice) {
    const [value, setValue] = useState({});

    return {
        value,
        setValue,
    };
}
