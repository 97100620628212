import React, { useState } from 'react';
import download from '../utils/downloadExcel';
import Config from '../config/Config';
import { Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import Utils from '../utils/Utils';
import moment from 'moment';

export default function DineInDataExport(props) {
    // const [dineInOrders, setDineInOrders] = useState([]);
    // const [itemLevelOrderDetails, setItemLevelOrderDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const reportNamePrefix = 'DineInOrdersReport_';

    //to export the data related to all the items from all the orders
    async function exportDineInOrderData() {
        setLoading((prevstatus) => !prevstatus);
        setIsError(false);
        const token = await window.customerApp.auth().currentUser.getIdToken();
        let config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };

        let reqBody = {
            restauId: props.restauId,
            createdTime: {
                start: props.startDate,
                end: props.endDate ? props.endDate : new Date(),
            },
        };

        let dataToExport = [];

        //Fetch the data
        axios
            .post(Config.searchDineInOrders, reqBody, config)
            .then(function (response) {
                //console.log("Response: " + JSON.stringify(response.data));
                if (response.data.orderResults) {
                    response.data.orderResults.forEach((element) => {
                        let createdDateTime = Utils.getDateStringFromTimestamp(
                            element.time._seconds,
                            props.restauTimeZone
                        );
                        //console.log(props.restauTimeZone);
                        dataToExport.push({
                            OrderId: element.id,
                            TableNumber: element.tableId,
                            Server: element.server,
                            Date: createdDateTime.substring(
                                0,
                                createdDateTime.indexOf(',')
                            ),
                            Time: createdDateTime.substring(
                                createdDateTime.indexOf(',') + 2
                            ),
                            SubTotal: element.subTotal / 100,
                            Tax: element.salesTax / 100,
                            ServiceFees: element.serviceFees / 100,
                            Tips: element.tip / 100,
                            Total: element.total / 100,
                        });
                    });
                    //console.table(dataToExport);

                    //console.log("orderResults: " + JSON.stringify(orderResults));
                    setLoading((prevstatus) => !prevstatus);
                    //console.log(moment(new Date()).format('DD-MM-YYYY_hhmmss'));
                    download.excelDownload(
                        dataToExport,
                        reportNamePrefix +
                            moment(new Date()).format('DD-MM-YYYY_hhmmss') +
                            '.xlsx',
                        'DineInOrders'
                    );
                } else {
                    setLoading((prevstatus) => !prevstatus);
                    setIsError(true);
                }
            })
            .catch(function (error) {
                setLoading((prevstatus) => !prevstatus);
                setIsError(true);
                console.log(error);
            });
    }

    return (
        <>
            <Button
                color="primary"
                variant="contained"
                onClick={() => exportDineInOrderData()}
            >
                Export to Excel
            </Button>
            {loading && <CircularProgress color="secondary" size={30} />}
            {isError && (
                <p
                    style={{
                        color: 'red',
                        fontWeight: 'normal',
                        fontSize: '0.85rem',
                    }}
                >
                    Failed to export the data.
                </p>
            )}
        </>
    );
}
