import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    Grid,
    Typography,
} from '@mui/material';
import ButtonWithIcon from '../../../../components/common/ButtonWithIcon';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import { useStyles } from '../../../../home/useStyles';
import DialogWithContent from '../../../../components/common/DialogWithContent/DialogWithContent';
import AddOrUpdateAssignedOverride from './AddOrUpdateAssignedOverride';

//The common component to show the list of assigned overrides either at Section/FoodItem level
function AssignedVisOverrides(props) {
    const classes = useStyles();
    const {
        setOpenAssignOverridesDialog,
        openAssignOverridesDialog,
        loading,
        assignedVisOverride,
        setAssignedVisOverride,
        allOverrideMapping,
        handleAssignedOverrideUpdate,
    } = props;

    //const [assignedOverride, setAssignedOverride] = useState({});

    console.log('Assigned Override: ', assignedVisOverride);

    return (
        <Grid container spacing={0}>
            <Grid item xs={7.5}>
                <Box>
                    <Typography variant="h6" component="h6">
                        VisibilityOverrides
                    </Typography>
                    <Typography variant="subtitle2" component="sub">
                        if not specified, overrides will be defaulted from the
                        category
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={4.5}>
                <ButtonWithIcon
                    flexDirection="row"
                    buttonText="Assign Override"
                    sx={{ border: 0 }}
                    onClickHandler={() => setOpenAssignOverridesDialog(true)}
                >
                    <AddCircleSharpIcon color="primary" sx={{ fontSize: 23 }} />
                </ButtonWithIcon>
            </Grid>
            <Grid item xs={12}>
                <FormControl
                    sx={{ m: 2 }}
                    component="fieldset"
                    variant="standard"
                >
                    {loading ? (
                        <div>
                            <img
                                alt="loading"
                                style={{ width: '50%' }}
                                className={classes.loadingImage}
                                src="../../img/spinner.gif"
                            />
                        </div>
                    ) : (
                        assignedVisOverride &&
                        Object.keys(assignedVisOverride).length > 0 && (
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 20,
                                                },
                                            }}
                                            checked={true}
                                        />
                                    }
                                    label={assignedVisOverride.name}
                                ></FormControlLabel>
                                {assignedVisOverride.mappingFromCategory && (
                                    <Chip
                                        color="primary"
                                        label={'Category'}
                                        variant="outlined"
                                        sx={{
                                            //fontSize: '15px',
                                            height: '20px',
                                            '& .MuiChip-label': {
                                                padding: '5px',
                                            },
                                        }}
                                    />
                                )}
                            </Grid>
                        )
                    )}
                </FormControl>
            </Grid>
            <DialogWithContent
                open={openAssignOverridesDialog}
                setOpen={setOpenAssignOverridesDialog}
                dialogTitle={'Assign Visbility Override'}
                dialogMaxWidth={'xs'}
                handleSave={() =>
                    handleAssignedOverrideUpdate(assignedVisOverride)
                }
                dialogCloseBtnText={'Close'}
                dialogSaveBtnText={'Assign'}
            >
                <AddOrUpdateAssignedOverride
                    allOverrideMapping={allOverrideMapping}
                    assignedOverride={assignedVisOverride}
                    setAssignedOverride={setAssignedVisOverride}
                />
            </DialogWithContent>
        </Grid>
    );
}

AssignedVisOverrides.propTypes = {
    setOpenAssignOverridesDialog: PropTypes.func,
    openAssignOverridesDialog: PropTypes.bool,
    loading: PropTypes.bool,
    assignedVisOverride: PropTypes.shape({ name: PropTypes.string }),
    allOverrideMapping: PropTypes.shape({}),
    handleAssignedOverrideUpdate: PropTypes.func,
};

AssignedVisOverrides.defaultProps = {
    loading: false,
    openAssignOverridesDialog: false,
};
export default AssignedVisOverrides;
