import React, { useContext } from 'react';
import { useAudit } from '../Hooks/useAudit';
import AuditInput from './auditInput';

import { RestauInfoContext } from '../home/RestauInfoContext';

export default function AuditLog(props) {
    const { summaryHook } = useContext(RestauInfoContext);
    const auditHook = useAudit();

    return <AuditInput summaryHook={summaryHook} auditHook={auditHook} />;
}
