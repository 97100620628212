//let function_url = 'http://localhost:5001/sliceline-admin/us-central1/';
let function_url = 'https://us-central1-sliceline-admin.cloudfunctions.net/';
let firebase_db = 'https://sliceline-waiter.firebaseio.com';
let function_url_onboarding =
    'https://us-central1-sliceline-onboarding.cloudfunctions.net/';
let function_url_takeout =
    'https://us-central1-sliceline-takeout.cloudfunctions.net/';

const prodConfig = {
    environment: '',
    env: 'prod',
    stripe_publish_key:
        'pk_live_51HrjUNHaof0IjCDrl0AZr0k9mHbmfXagLnUZQeUllX5ML4b2uPlnqQqkm5QIRqs5VmbLCHbn1xZrjiPEzXQNAWEp00Z60DqOva',
    secondaryAppConfig: {
        apiKey: 'AIzaSyD5quCrcrCloyNBSpeBy4_2aUmDNgdrfxM',
        authDomain: 'sliceline-takeout.firebaseapp.com',
        databaseURL: 'https://sliceline-takeout.firebaseio.com',
        projectId: 'sliceline-takeout',
        appId: '1:223301731732:web:565079a27cfa18581d7b81',
    },

    firebaseConfig: {
        apiKey: 'AIzaSyCNhYHcgdIHm3Jpptbs7YuzkASuW2aDY3M',
        authDomain: 'sliceline-admin.firebaseapp.com',
        projectId: 'sliceline-admin',
        storageBucket: 'sliceline-admin.appspot.com',
        messagingSenderId: '232556823667',
        appId: '1:232556823667:web:6d08960403baa8fcf95606',
        measurementId: 'G-Z7P35HKQ34',
    },

    function_url,
    function_url_onboarding,
    function_url_takeout,
};

export default prodConfig;
