import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';

function ButtonWithIcon(props) {
    const {
        flexDirection,
        buttonText,
        buttonTextSize,
        buttonTextVariant,
        onClickHandler,
        children,
    } = props;
    return (
        <Button
            sx={{
                display: 'flex',
                flexDirection: { flexDirection }, //'row',
                justifyContent: 'center',
                alignItems: 'center',
                border: 1,
                //padding: '10px 15px',
            }}
            {...props}
            onClick={() => {
                onClickHandler(true);
            }}
        >
            {children}
            <Typography
                variant={buttonTextVariant}
                fontSize={buttonTextSize}
                sx={{ ml: 1 }}
            >
                {buttonText}
            </Typography>
        </Button>
    );
}

ButtonWithIcon.propTypes = {
    children: PropTypes.node.isRequired,
    flexDirection: PropTypes.string,
    buttonText: PropTypes.string,
    onClickHandler: PropTypes.func.isRequired,
    buttonTextVariant: PropTypes.string,
    buttonTextSize: PropTypes.string,
};

ButtonWithIcon.defaultProps = {
    flexDirection: 'row',
    buttonText: 'Add',
    buttonTextVariant: 'body1',
    buttonTextSize: '1rem',
};

export default ButtonWithIcon;
