import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import React from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { designOptions } from '../constants';
import QRContext from '../QRContext';

function QRDesign({ children }) {
    const { activeTab } = useContext(QRContext);
    const navigate = useNavigate();

    const handleChange = (e, tab) => {
        navigate(tab);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={activeTab} onChange={handleChange}>
                    {designOptions.map(({ label, value }) => (
                        <Tab
                            key={value}
                            label={label}
                            value={value}
                            id={`QR-code-tab-${value}`}
                            aria-controls={`QR-code-tabpanel-${value}`}
                        />
                    ))}
                </Tabs>
            </Box>
            <Box
                role="tabpanel"
                id={`QR-code-tabpanel-${activeTab}`}
                aria-labelledby={`QR-code-tab-${activeTab}`}
                sx={{ paddingTop: 2, paddingLeft: 2 }}
            >
                {children}
            </Box>
        </Box>
    );
}

QRDesign.propTypes = {
    children: PropTypes.node.isRequired,
};

export default QRDesign;
