import { TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function TextFieldWithMaxCharacters(props) {
    const {
        txtMessage,
        setTxtMessage,
        maxCharAllowableLimit,
        maxCharWarningLimit,
    } = props;

    const [txtMessageLength, setTxtMessageLength] = useState(0);
    const [displayWarning, setDisplayWarning] = useState(false);

    const validateTextMessageLength = (txtMsg) => {
        //console.log('Text Message for Online Ordering', txtMsg);
        let txtMsgCharCount = txtMsg ? txtMsg.length : 0;
        setTxtMessageLength(txtMsgCharCount);
        //if it is beyond the allowable limit, then do not allow user to enter characters further
        if (txtMsgCharCount >= maxCharAllowableLimit) {
            return false;
        }
        //if it is beyond the warning limit, then show the warning message in RED
        if (txtMsgCharCount > maxCharWarningLimit) {
            setDisplayWarning(true);
        } else {
            setDisplayWarning(false);
        }
        return true;
    };

    const handleTxtMsgChange = (txtMsg) => {
        var res = validateTextMessageLength(txtMsg);
        if (res) {
            setTxtMessage(txtMsg, null);
        }
    };

    useEffect(() => {
        validateTextMessageLength(txtMessage);
    }, []);

    useEffect(() => {
        validateTextMessageLength(txtMessage);
    }, [txtMessage]);

    return (
        <>
            <TextField
                {...props}
                value={txtMessage}
                onChange={(e) => handleTxtMsgChange(e.target.value)}
            />
            <Typography variant="subtitle2">
                Length: {txtMessageLength}
            </Typography>
            {displayWarning && (
                <Typography variant="subtitle2" sx={{ color: 'red' }}>
                    SMS is too long, extra charges may apply.
                </Typography>
            )}
        </>
    );
}

TextFieldWithMaxCharacters.propTypes = {
    txtMessage: PropTypes.string,
    setTxtMessage: PropTypes.func,
    fullWidth: PropTypes.bool,
    id: PropTypes.string,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    size: PropTypes.string,
    maxCharAllowableLimit: PropTypes.number,
    maxCharWarningLimit: PropTypes.number,
    disabled: PropTypes.bool,
    variant: PropTypes.string,
};

TextFieldWithMaxCharacters.defaultProps = {
    size: 'medium',
    multiline: true,
    fullWidth: true,
    rows: 2,
    maxCharAllowableLimit: 320,
    maxCharWarningLimit: 160,
    disabled: false,
    variant: 'outlined',
};

export default TextFieldWithMaxCharacters;
