import React, { useContext, useState } from 'react';
import FaqDialog from './FaqDialog';
import CateringSettingContext from '../CateringSettingContext';
import {
    Typography,
    TextField,
    Grid,
    Button,
    Box,
    Card,
    CardMedia,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import { useTheme } from '@mui/material/styles';

const FaqAndSubmission = () => {
    const {
        faq,
        setFaq,
        submissionFields,
        setSubmissionFields,
        updateFormConfig,
    } = useContext(CateringSettingContext);
    const defaultFaq = {
        question: '',
        answer: { content: [{ text: '' }] },
    };
    const [openFaqDialog, setOpenFaqDialog] = useState(false);
    const [faqData, setFaqData] = useState(defaultFaq);
    const [faqDialogIndex, setFaqDialogIndex] = useState(null);
    const [dialogTitle, setDialogTitle] = useState('Edit FAQ');
    const [isAddingNewFaq, setIsAddingNewFaq] = useState(false);
    const theme = useTheme();

    const handleEdit = (i, faqsToUse) => {
        setOpenFaqDialog(true);
        setFaqData(faqsToUse[i]);
        setFaqDialogIndex(i);
    };

    const addFaq = () => {
        setDialogTitle('Create FAQ');
        setIsAddingNewFaq(true);
        setFaq((prevFaqs) => {
            const newFaqs = [...prevFaqs, defaultFaq];
            handleEdit(newFaqs.length - 1, newFaqs);
            return newFaqs;
        });
    };

    const clearAll = () => {
        setFaq([]);
        setSubmissionFields({
            submission_header: '',
            submission_body: '',
            catering_menu_body: '',
            catering_menu_link: '',
            home_page_link: '',
        });
    };

    const save = async () => {
        if (submissionFields.submission_header.length === 0) {
            submissionFields.submission_header =
                'Thank you! Catering inquiry submission is successful.';
        }
        if (submissionFields.submission_body.length === 0) {
            submissionFields.submission_body =
                'We will reach back to you as soon as possible.';
        }
        let response = await updateFormConfig({
            submission_header: submissionFields.submission_header,
            submission_body: submissionFields.submission_body,
            catering_menu_body: submissionFields.catering_menu_body,
            catering_menu_link: submissionFields.catering_menu_link,
            home_page_link: submissionFields.home_page_link,
        });
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography color="primary" variant="h6" sx={{ mb: 2 }}>
                    FAQ And Submission Message
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    Frequently Asked Questions
                </Typography>
                {faq &&
                    faq.map((fq, i) => (
                        <Accordion sx={{ mb: 2 }} key={i}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id={i}
                                sx={{ backgroundColor: '#eceff1' }}
                            >
                                <Typography>Q: {fq.question}</Typography>

                                <IconButton
                                    onClick={() => handleEdit(i, faq)}
                                    color="primary"
                                    sx={{ padding: 0.2, mr: 5 }}
                                >
                                    <EditIcon sx={{ fontSize: 18 }} />
                                    <Typography> Edit </Typography>
                                </IconButton>
                            </AccordionSummary>

                            <AccordionDetails>
                                {fq.answer.content &&
                                    fq.answer.content.map((item, index) => {
                                        if (item.text) {
                                            return (
                                                <Typography key={index}>
                                                    {item.text
                                                        .split('\n')
                                                        .map(
                                                            (
                                                                line,
                                                                lineIndex
                                                            ) => (
                                                                <React.Fragment
                                                                    key={
                                                                        lineIndex
                                                                    }
                                                                >
                                                                    {line}
                                                                    <br />
                                                                </React.Fragment>
                                                            )
                                                        )}
                                                </Typography>
                                            );
                                        } else if (item.img) {
                                            return (
                                                <Box
                                                    key={index}
                                                    sx={{
                                                        display: 'flex',
                                                        overflowX: 'auto',
                                                        flexWrap: 'nowrap',
                                                    }}
                                                >
                                                    {item.img &&
                                                    item.img.length !== 0 ? (
                                                        item.img.map(
                                                            (image, i) => (
                                                                <Box
                                                                    key={i}
                                                                    sx={{
                                                                        margin: 1,
                                                                    }}
                                                                >
                                                                    <Card
                                                                        align="center"
                                                                        sx={{
                                                                            margin: 1,
                                                                            minWidth:
                                                                                '20vh',
                                                                        }}
                                                                    >
                                                                        <CardMedia
                                                                            component="img"
                                                                            height="200"
                                                                            image={
                                                                                image
                                                                            }
                                                                            alt="FAQ image"
                                                                        />
                                                                    </Card>
                                                                </Box>
                                                            )
                                                        )
                                                    ) : (
                                                        <></>
                                                    )}
                                                </Box>
                                            );
                                        }
                                    })}
                            </AccordionDetails>
                        </Accordion>
                    ))}
            </Grid>

            {openFaqDialog ? (
                <FaqDialog
                    open={openFaqDialog}
                    setOpenFaqDialog={setOpenFaqDialog}
                    dialogTitle={dialogTitle}
                    setDialogTitle={setDialogTitle}
                    defaultFaq={defaultFaq}
                    faqData={faqData}
                    isAddingNewFaq={isAddingNewFaq}
                    setIsAddingNewFaq={setIsAddingNewFaq}
                    faq={faq}
                    setFaq={setFaq}
                    faqDialogIndex={faqDialogIndex}
                    setFaqDialogIndex={setFaqDialogIndex}
                />
            ) : (
                <></>
            )}

            <Grid item xs={12} sx={{ mb: 2 }}>
                <Button
                    component="label"
                    xs={3}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: 1,
                        height: '100%',
                        [theme.breakpoints.down('xs')]: {
                            maxWidth: '20%',
                        },
                        [theme.breakpoints.up('md')]: {
                            maxWidth: '9%',
                        },
                    }}
                    onClick={addFaq}
                >
                    <AddCircleSharpIcon color="primary" sx={{ fontSize: 20 }} />
                    <Typography
                        Typography
                        align="center"
                        variant="body2"
                        sx={{ m: 'auto' }}
                    >
                        Add FAQ
                    </Typography>
                </Button>
            </Grid>

            <Grid container item>
                <Grid item xs={12}>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        Submission Page Messages
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        required
                        multiline
                        label="Submission Page Header"
                        size="small"
                        variant="outlined"
                        margin="normal"
                        sx={{ width: '70%' }}
                        value={submissionFields.submission_header}
                        onChange={(e) =>
                            setSubmissionFields({
                                ...submissionFields,
                                submission_header: e.target.value,
                            })
                        }
                        // error={!!errors[field.field_name]}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        required
                        multiline
                        label="Submission Page Body"
                        size="small"
                        variant="outlined"
                        margin="normal"
                        sx={{ width: '70%' }}
                        value={submissionFields.submission_body}
                        onChange={(e) =>
                            setSubmissionFields({
                                ...submissionFields,
                                submission_body: e.target.value,
                            })
                        }
                        // error={!!errors[field.field_name]}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        multiline
                        label="Catering Menu Message"
                        size="small"
                        variant="outlined"
                        margin="normal"
                        sx={{ width: '70%' }}
                        value={submissionFields.catering_menu_body}
                        onChange={(e) =>
                            setSubmissionFields({
                                ...submissionFields,
                                catering_menu_body: e.target.value,
                            })
                        }
                        // error={!!errors[field.field_name]}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        multiline
                        label="Catering Menu Link"
                        size="small"
                        variant="outlined"
                        margin="normal"
                        sx={{ width: '70%' }}
                        value={submissionFields.catering_menu_link}
                        onChange={(e) =>
                            setSubmissionFields({
                                ...submissionFields,
                                catering_menu_link: e.target.value,
                            })
                        }
                        // error={!!errors[field.field_name]}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>

            <Button
                size="small"
                variant="outlined"
                onClick={clearAll}
                sx={{ m: 2 }}
            >
                Clear All
            </Button>
            <Button
                size="small"
                variant="contained"
                onClick={save}
                sx={{ m: 2 }}
            >
                Save
            </Button>
        </Grid>
    );
};

export default FaqAndSubmission;
