import React from 'react';
import styled from 'styled-components';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

// TODO : Move this common / components. Ask Bhanu
export const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 900,
    },
});
