/**
 * Custom theme object is defined here which will be used for
 * controlling theming of MUI components
 *
 * Default theme details are available here
 * https://v4.mui.com/customization/default-theme/
 *
 * The customization details can be found here
 * https://v4.mui.com/customization/theming/
 */
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    props: {
        MuiButton: {
            // size: 'small',
        },
    },
    overrides: {},
    palette: {
        primary: {
            main: '#7777F7',
            light: '#ADADF8',
            dark: '#506DF4',
            contrastText: '#fff',
        },
        secondary: {
            main: '#36B37E',
            light: '#68B39F',
            dark: '#288F8E',
            contrastText: '#fff',
        },
        tertiary: {
            main: '#222C31',
            // light: '#7986cb',
            // dark: '#303f9f',
            contrastText: '#fff',
        },
        neutral1: {
            main: '#F2F3F3',
            // light: '#7986cb',
            // dark: '#303f9f',
            // contrastText: '#fff',
        },
        neutral2: {
            // main: '#222C31',
            // light: '#7986cb',
            // dark: '#303f9f',
            // contrastText: '#fff',
        },
    },

    typography: {
        htmlFontSize: 16,
        fontSize: 16,
        fontFamily: 'TT Norms Pro, Roboto, sans-serif;',
        fontWeight: 400,
        letterSpacing: 0,
        color: '#222C31',
        h1: {
            // font-size: 60px;
            // line-height: 70px;
            // letter-spacing: -1.5px;

            fontSize: '3.75rem',
            lineHeight: 1.6667,
            letterSpacing: -1.5,
        },
        h2: {
            // font-size: 45px;
            // line-height: 54px;

            fontSize: '2.813rem',
            lineHeight: 1.2,
        },
        h3: {
            // font-size: 35px;
            // line-height: 41px;

            fontSize: '2.188rem',
            lineHeight: 1.1714,
        },
        h4: {
            // font-size: 25px;
            // line-height: 31px;

            fontSize: '1.563rem',
            fontWeight: 500,
            lineHeight: 1.24,
        },
        h5: {
            // font-size: 20px;
            // line-height: 27px;

            marginBottom: '1.25rem',
            fontWeight: 500,
            lineHeight: 1.35,
        },
        h6: {
            // font-size: 16px;
            // line-height: 21px;

            fontSize: '1rem',
            fontWeight: 500,
            lineHeight: 1.3125,
        },
        subtitle1: {
            // font-size: 18px;
            // line-height: 32px;
            // letter-spacing: 0.15000000596046448px;

            fontSize: '1.125rem',
            fontWeight: 500,
            letterSpacing: 0.15,
            lineHeight: 1.7778,
            color: 'rgba(34, 44, 49, 0.6)',
        },
        subtitle2: {
            // font-size: 14px;
            // line-height: 22px;
            // letter-spacing: 0.10000000149011612px;

            fontSize: '.875rem',
            fontWeight: 500,
            letterSpacing: 0.1,
            lineHeight: 1.5714,
            color: 'rgba(34, 44, 49, 0.6)',
        },
        body1: {
            // font-size: 16px;
            // line-height: 24px;
            // letter-spacing: 0.15000000596046448px;

            fontSize: '1rem',
            letterSpacing: 0.15,
            lineHeight: 1.5,
        },
        body2: {
            // font-size: 14px;
            // line-height: 20px;
            // letter-spacing: 0.17000000178813934px;

            fontSize: '.875rem',
            letterSpacing: 0.17,
            lineHeight: 1.4286,
        },
        button: {
            fontSize: '1.1rem',
        },
        caption: {
            // font-size: 10px;
            // line-height: 17px;

            fontSize: '0.625rem',
            lineHeight: 1.7,
            color: 'gray',
        },
        overline: {
            fontSize: '0.65rem',
            color: 'gray',
        },
    },
    components: {
        MuiIconButton: {
            styleOverrides: {
                sizeSmall: {
                    // Adjust spacing to reach minimal touch target hitbox
                    marginLeft: 4,
                    marginRight: 4,
                    padding: 12,
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    marginTop: 8,
                    marginBottom: 8,
                    height: 1,
                    // backgroundColor: '#52be7f',
                },
            },
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                labelContainer: {
                    overflow: 'visible',
                },
            },
        },
    },
});

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
    typography: {
        htmlFontSize: 14,
        fontSize: 14,
        body1: {
            fontSize: '.875rem',
            letterSpacing: 0.17,
            lineHeight: 1,
        },
        body2: {
            fontSize: '.875rem',
            letterSpacing: 0.17,
            lineHeight: 1,
        },
    },
});

export default theme;
