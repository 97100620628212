import { useState } from 'react';
const { default: axios } = require('axios');

export function useSelectedIndex(defaultChoice) {
    const [value, setValue] = useState(0);

    return {
        value,
        setValue,
    };
}
