import React from 'react';
import DisplayChart from '../utils/DisplayChart';
import PropTypes from 'prop-types';

export default function CallSummaryByHour(props) {
    // const data = [
    //     [
    //         'Hour',
    //         'Pressed_1',
    //         'Pressed_2_But_Busy',
    //         'Pressed_Catering',
    //         'Pressed_Store_hours',
    //         'Pressed_Banquet',
    //     ],
    //     ['00', 10, 25, 3, 5, 19],
    //     ['02', 13, 29, 3, 15, 9],
    //     ['05', 18, 25, 13, 5, 9],
    //     ['11', 12, 24, 3, 25, 9],
    //     ['15', 8, 25, 3, 5, 39],
    //     ['18', 7, 25, 13, 15, 9],
    //     ['19', 9, 35, 23, 25, 9],
    //     ['22', 9, 35, 23, 25, 9],
    // ];
    const { data, chartColorPriority } = props;

    const options = {
        //title: 'Call Recrods Summary - By Hour',
        chartArea: {
            top: 70,
            bottom: 40,
            width: '90%',
            height: '80%',
        },
        isStacked: true,
        hAxis: {
            title: 'Hour',
            minValue: 0,
        },
        vAxis: {
            title: 'Call Count',
        },
        bar: { groupWidth: '60%' },
        theme: 'material',
        legend: {
            position: 'top',
            alignment: 'middle',
            maxLines: 4,
        },
        colors: [...chartColorPriority],
    };

    return (
        <DisplayChart
            chartType="ColumnChart"
            width="100%"
            height="460px"
            data={data}
            options={options}
        />
    );
}

CallSummaryByHour.propTypes = {
    data: PropTypes.array,
    chartColorPriority: PropTypes.array,
};
