import React from 'react';
import usePhoneSettings from '../usePhoneSettings';
import PhoneSettingsContext from '../PhoneSettingsContext';
import PhoneSettingsContent from '../Components/PhoneSettingsContent';

function PhoneSettingsRoot(props) {
    const phoneSettingsHook = usePhoneSettings();

    return (
        <PhoneSettingsContext.Provider value={phoneSettingsHook}>
            <PhoneSettingsContent />
        </PhoneSettingsContext.Provider>
    );
}

PhoneSettingsRoot.propTypes = {};

export default PhoneSettingsRoot;
