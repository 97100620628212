import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import React from 'react';
import Title from './Title';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    flexContext: {
        flex: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

export default function PriceGrid(prop) {
    function getTopSoldItems(items) {
        let itemText = '';
        let len = items.length;
        if (items.length > 5) {
            len = 5;
        }
        for (let i = 0; i < len; i++) {
            itemText += '\n' + items[i].item + '(Qty-' + items[i].total + '),';
        }
        return itemText;
    }
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    return (
        // <Grid item xs={12} md={4} lg={3}>
        <Grid item>
            <Paper className={fixedHeightPaper}>
                <React.Fragment>
                    <Title>{prop.title}</Title>
                    <Typography component="p" variant="h4">
                        {prop.total ? prop.total : ''}
                    </Typography>
                    {prop.details && (
                        <Typography color="textSecondary">
                            {prop.details}
                        </Typography>
                    )}
                    <Typography color="textSecondary">
                        on {prop.date ? prop.date : ''}
                        {prop.topItems && prop.topItems.length > 0 && (
                            <>
                                <br />
                                Top 5 Items Sold:
                                <br />
                                {getTopSoldItems(prop.topItems)}
                            </>
                        )}
                    </Typography>
                </React.Fragment>
            </Paper>
        </Grid>
    );
}
