import { useState } from 'react';

const defaultList = [{}];

export function useSlotList(current = defaultList) {
    const [list, setList] = useState(current);

    const handleRemove = (index) => {
        const updatedList = [...list.slice(0, index), ...list.slice(index + 1)];
        setList(updatedList);
    };

    const handleRemove1 = (...args) => {
        console.log('handleRemove1', ...args);
        // if only one slot is present, it should be reset
        if (list.length === 1) {
            console.log('handleRemove1', 'handleReset');
            handleReset();
        } else {
            handleRemove(...args);
        }
    };

    const handleAdd = () => {
        const updatedList = [...list, {}];
        setList(updatedList);
    };

    const handleChange = (date, index, id) => {
        const updated = { ...list[index] };
        if (id === 0) {
            updated.start = date;
        } else if (id === 1) {
            updated.end = date;
        }

        const updatedList = [
            ...list.slice(0, index),
            updated,
            ...list.slice(index + 1),
        ];

        setList(updatedList);
    };

    const handleReset = () => {
        setList(defaultList);
    };

    return {
        list,
        handleRemoveSlot: handleRemove1,
        handleAddSlot: handleAdd,
        handleChangeSlot: handleChange,
        handleResetSlot: handleReset,
    };
}
