import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

function ToggleButtonGroupWithLabel(props) {
    const { label, items, exclusive, includeLabel } = props;
    return (
        <>
            {includeLabel && (
                <Typography variant="body1" sx={{ mb: 1 }}>
                    {label}
                </Typography>
            )}
            <ToggleButtonGroup
                color="primary"
                exclusive={exclusive}
                aria-label="Platform"
                {...props}
            >
                {items &&
                    items.map((btn) => (
                        <ToggleButton key={btn.name} value={btn.value}>
                            {btn.label}
                        </ToggleButton>
                    ))}
            </ToggleButtonGroup>
        </>
    );
}

ToggleButtonGroupWithLabel.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
            value: PropTypes.string,
        }).isRequired
    ),
    label: PropTypes.string,
    exclusive: PropTypes.bool,
    includeLabel: PropTypes.bool,
};

ToggleButtonGroupWithLabel.defaultProps = {
    label: 'Select',
    exclusive: true,
    includeLabel: true,
};

export default ToggleButtonGroupWithLabel;
