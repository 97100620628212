import React from 'react';

function FormRoot({ children }) {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 20,
            }}
        >
            {children}
        </div>
    );
}

FormRoot.propTypes = {};

export default FormRoot;
