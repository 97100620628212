import React from 'react';
import { Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export default function ToggleField(props) {
    const { label, labelPlacement, checked, name, onChange, id, size } = props;

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={checked}
                    onChange={onChange}
                    name={name}
                    label={label}
                    // labelPlacement={labelPlacement}
                    id={id}
                    size={size}
                />
            }
            label={
                <Typography
                    style={{
                        marginBottom: '-5px',
                        fontSize: '0.85em',
                    }}
                >
                    {label}
                </Typography>
            }
            labelPlacement={labelPlacement}
            style={{ margin: '0 5px 0' }}
        />
    );
}
