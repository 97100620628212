export const overrideTypes = [
    {
        name: 'dineInType',
        label: 'Dine-In',
        value: 'Only DineIn',
    },
    {
        name: 'togoType',
        label: 'To Go',
        value: 'Only ToGo',
    },
];

export const overrideStatuses = [
    {
        name: 'hideStatus',
        label: 'Hide',
        value: 'hide',
    },
    {
        name: 'showStatus',
        label: 'Show',
        value: 'show',
    },
];

export const overrideDateTimeTypes = [
    {
        name: '24/7',
        label: '24/7',
        value: '24/7',
    },
    {
        name: 'time',
        label: 'Time',
        value: 'time',
    },
    {
        name: 'dayTime',
        label: 'Day+Time',
        value: 'dayTime',
    },
];

export const overrideAllDays = [
    {
        name: 'Sun',
        label: 'Sun',
        value: 'Sun',
    },
    {
        name: 'Mon',
        label: 'Mon',
        value: 'Mon',
    },
    {
        name: 'Tue',
        label: 'Tue',
        value: 'Tue',
    },
    {
        name: 'Wed',
        label: 'Wed',
        value: 'Wed',
    },
    {
        name: 'Thu',
        label: 'Thu',
        value: 'Thu',
    },
    {
        name: 'Fri',
        label: 'Fri',
        value: 'Fri',
    },
    {
        name: 'Sat',
        label: 'Sat',
        value: 'Sat',
    },
];
