import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SQRadioGroup from '../../components/formControls/SQRadioGroup';
import QRContext from '../QRContext';

function QRPaperSizes({ paperSizes }) {
    const { paperSize, setPaperSize } = useContext(QRContext);

    const handleChange = (e, val) => {
        setPaperSize(val);
    };

    return (
        <SQRadioGroup
            label="Sizes"
            options={paperSizes}
            value={paperSize}
            handleChange={handleChange}
        />
    );
}

QRPaperSizes.propTypes = {
    paperSizes: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
};

export default QRPaperSizes;
