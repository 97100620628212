import React from 'react';
import { TextField } from '@mui/material';

function InputField(props) {
    return (
        <div>
            <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                {...props}
            />
        </div>
    );
}

InputField.propTypes = {};

export default InputField;
