import React from 'react';
import { useStyles } from '../../../../home/useStyles';
import PhoneCallerContext from '../PhoneCallerContext';
import { useContext } from 'react';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Grid, Typography } from '@mui/material';
import download from '../../../../utils/downloadExcel';
import moment from 'moment';

function SearchResults() {
    const { filteredCallerDetails, loading, errMsg } =
        useContext(PhoneCallerContext);
    const [pageSize, setPageSize] = React.useState(20);
    const classes = useStyles();

    const reportNamePrefix = 'CallerDetails_';

    const columns = [
        { field: 'id', headerName: 'Id', width: 75, align: 'left' },
        {
            field: 'phNumber',
            headerName: 'Phone Number',
            width: 150,
            align: 'left',
        },
        {
            field: 'totalCallCount',
            headerName: 'Total Call Count',
            width: 150,
            align: 'center',
        },
        {
            field: 'lastCallTimeStamp',
            headerName: 'Last Call Date Time',
            width: 200,
            align: 'left',
        },
    ];

    const rows = Object.values(filteredCallerDetails).map((x, index) => ({
        ...x,
        id: index + 1,
    }));

    async function exportCallerDetailsToExcel() {
        // setLoading((prevstatus) => !prevstatus);
        // setIsError(false);
        let dataToExport;
        dataToExport = filteredCallerDetails;
        if (dataToExport === undefined) {
            //display error
            // setLoading(false);
            // setIsError(true);
        }
        download.excelDownload(
            filteredCallerDetails,
            reportNamePrefix +
                moment(new Date()).format('DD-MM-YYYY_hhmmss') +
                '.xlsx',
            'Caller Details'
        );
        // setLoading(false);
        // setIsError(false);
    }

    console.log('Row data: ', rows);

    return (
        <Box sx={{ width: '100%', mt: 2 }}>
            <div>
                {loading ? (
                    <img
                        className={classes.loadingImage}
                        src="../../../img/spinner.gif"
                        alt="loading"
                    />
                ) : (
                    <div>
                        {errMsg !== '' ? (
                            <Typography color={'red'}>{errMsg}</Typography>
                        ) : (
                            Object.keys(filteredCallerDetails).length > 0 && (
                                <Grid container>
                                    <Grid
                                        container
                                        item
                                        spacing={0}
                                        xs={12}
                                        sx={{
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Grid item xs={12} md={2}>
                                            <Typography>
                                                Count:{' '}
                                                {
                                                    Object.keys(
                                                        filteredCallerDetails
                                                    ).length
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={3.5}
                                            style={{ alignSelf: 'flex-end' }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                <Button
                                                    //onClick={async () => await exportMenuItemsWithPriceToExcel()}
                                                    onClick={
                                                        exportCallerDetailsToExcel
                                                    }
                                                    size="small"
                                                    color="primary"
                                                    variant="contained"
                                                >
                                                    {'Download to Excel'}
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div
                                            style={{
                                                height: 500,
                                                //width: 1000,
                                            }}
                                        >
                                            <DataGrid
                                                rows={rows}
                                                columns={columns}
                                                pageSize={pageSize}
                                                onPageSizeChange={(
                                                    newPageSize
                                                ) => setPageSize(newPageSize)}
                                                rowsPerPageOptions={[
                                                    20, 50, 100,
                                                ]}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            )
                        )}
                    </div>
                )}
            </div>
        </Box>
    );
}

SearchResults.propTypes = {};

export default SearchResults;
