import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import {
    Box,
    Grid,
    Typography,
    Chip,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    MenuItem,
    DialogActions,
    Button,
    Tooltip,
} from '@mui/material';
import CateringContext from '../../../CateringContext';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import CateringDashboardContext from '../CateringDashboardContext';
import PeopleIcon from '@mui/icons-material/People';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Utils from '../../../../utils/Utils';
import { RestauInfoContext } from '../../../../home/RestauInfoContext';

const CateringEventResults = (props) => {
    const { restauHook } = useContext(RestauInfoContext);
    const [dashboardEventStatus, setDashboardEventStatus] = useState(
        props.data.eventStatus
    );
    const [
        dashboardFollowUpOrSubmissionDate,
        setDashboardFollowUpOrSubmissionDate,
    ] = useState(
        props.fieldName === 'followupDueDate'
            ? Utils.convertTimestamp(props.data[props.fieldName], 2, true)
            : Utils.convertTimestamp(
                  props.data[props.fieldName],
                  2,
                  false,
                  restauHook.restauTimeZone
              )
    );
    const [eventSelectValue, setEventSelectValue] =
        useState(dashboardEventStatus);
    const [followUpDateValue, setFollowUpDateValue] = useState(
        props.data[props.fieldName]
            ? Utils.convertTimestamp(props.data[props.fieldName], 1, true)
            : moment()
    );
    const [openStatusSelect, setOpenStatusSelect] = useState(false);
    const [gapDays, setGapDays] = useState(2);
    const {
        statusColor,
        eventStatus,
        showPriority,
        alertTooltip,
        updateEvent,
    } = useContext(CateringContext);
    const { refreshToggle, setRefreshToggle } = useContext(
        CateringDashboardContext
    );

    const navigate = useNavigate();

    // To click into one individual event
    const clickToEvent = () => {
        navigate(`/catering/${props.data.restauId}/${props.data.inquiryId}`);
    };

    // functions related with changing event status on dashboard page directly
    const updateDashboardEventStatus = async (e) => {
        e.preventDefault();
        await updateEvent(
            props.data.restauId,
            props.data.inquiryId,
            props.data.eventId,
            {
                eventStatus: eventSelectValue,
                followupDueDate: followUpDateValue,
            }
        );
        handleStatusClose();
        setDashboardEventStatus(eventSelectValue);
        setDashboardFollowUpOrSubmissionDate(followUpDateValue);
        setRefreshToggle(!refreshToggle);
    };

    const renderAdditional = (field, fieldName) => {
        if (field === 'Submission' || field === 'Follow up by') {
            return (
                <Box
                    sx={{
                        height: 20,
                        display: 'flex',
                        alignItems: 'center',
                        mr: 2,
                    }}
                >
                    <Typography fontSize="0.7rem" sx={{ color: '#64696b' }}>
                        {field +
                            ': ' +
                            (fieldName === 'followupDueDate'
                                ? Utils.convertTimestamp(
                                      dashboardFollowUpOrSubmissionDate,
                                      2,
                                      true
                                  )
                                : Utils.convertTimestamp(
                                      dashboardFollowUpOrSubmissionDate,
                                      2,
                                      true
                                  ))}
                    </Typography>
                </Box>
            );
        } else if (field === 'Other') {
            return (
                props.data[fieldName] && (
                    <Box>
                        <Chip
                            label={
                                <Typography fontSize="0.8rem">
                                    {props.data[fieldName]}
                                </Typography>
                            }
                            fontSize="0.8rem"
                            size="small"
                        />
                    </Box>
                )
            );
        }
    };

    const handleStatusClickOpen = () => {
        setOpenStatusSelect(true);
    };

    const handleStatusClose = (event, reason) => {
        setEventSelectValue(dashboardEventStatus);
        setFollowUpDateValue(
            props.data[props.fieldName] === ''
                ? moment()
                : Utils.convertTimestamp(props.data[props.fieldName], 1, true)
        );
        if (reason !== 'backdropClick') setOpenStatusSelect(false);
    };

    return (
        <Box
            sx={{
                backgroundColor: props.color,
                marginTop: '1%',
                borderRadius: '5px',
            }}
        >
            <Grid
                container
                direction="row"
                sx={{
                    wrap: { xs: 'wrap', md: 'nowrap' },
                    backgroundColor: props.color,
                    marginTop: '1%',
                    borderRadius: '5px',
                }}
            >
                <Grid container item direction="column" xs={12} md={6}>
                    <Grid
                        container
                        item
                        direction="row"
                        sx={{ ml: 2, mr: 2, mt: 1 }}
                    >
                        <Grid item>
                            <Typography
                                fontSize="0.9rem"
                                sx={{ fontWeight: 500 }}
                            >
                                {props.data.customerName}
                            </Typography>
                        </Grid>
                        <Typography
                            fontSize="0.9rem"
                            sx={{ fontWeight: 500, whiteSpace: 'pre' }}
                        >
                            {' '}
                            ·{' '}
                        </Typography>
                        <Grid item>
                            <Typography
                                fontSize="0.9rem"
                                sx={{ fontWeight: 500 }}
                            >
                                {Utils.convertTimestamp(
                                    props.data.eventDate,
                                    2,
                                    true
                                )}
                            </Typography>
                        </Grid>
                        <Typography
                            fontSize="0.9rem"
                            sx={{ fontWeight: 500, whiteSpace: 'pre' }}
                        >
                            {' '}
                            ·{' '}
                        </Typography>
                        <Grid item>
                            <Typography
                                fontSize="0.9rem"
                                sx={{ fontWeight: 500 }}
                            >
                                {props.data.endTime
                                    ? `${props.data.startTime} - ${props.data.endTime}`
                                    : `${props.data.startTime}`}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        spacing={0.3}
                        direction="row"
                        sx={{ ml: 2, mr: 2, mt: 1, mb: 1 }}
                    >
                        {props.data.phone && (
                            <Box
                                sx={{
                                    height: 30,
                                    display: 'flex',
                                    alignItems: 'center',
                                    mr: 2,
                                }}
                            >
                                <IconButton
                                    color="primary"
                                    href={`tel:${props.data.phone}`}
                                    sx={{ padding: 0.2 }}
                                >
                                    <PhoneIcon sx={{ fontSize: 18 }} />
                                </IconButton>

                                <Typography
                                    fontSize="0.7rem"
                                    sx={{ color: '#64696b' }}
                                >
                                    {props.data.phone}
                                </Typography>
                            </Box>
                        )}

                        {props.data.email && (
                            <Box
                                sx={{
                                    height: 30,
                                    flexWrap: 'nowrap',
                                    display: 'flex',
                                    alignItems: 'center',
                                    mr: 2,
                                }}
                            >
                                <IconButton
                                    color="primary"
                                    href={`mailto:${props.data.email}`}
                                    sx={{ padding: 0.2 }}
                                >
                                    <EmailIcon sx={{ fontSize: 18 }} />
                                </IconButton>

                                <Typography
                                    fontSize="0.7rem"
                                    sx={{
                                        whiteSpace: 'normal',
                                        overflowWrap: 'anywhere',
                                        color: '#64696b',
                                    }}
                                >
                                    {props.data.email}
                                </Typography>
                            </Box>
                        )}

                        {props.data.numOfGuests && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    spacing: 1,
                                    mr: 2,
                                }}
                            >
                                <PeopleIcon
                                    color="primary"
                                    sx={{ fontSize: 18, padding: 0.2 }}
                                />
                                <Typography
                                    fontSize="0.7rem"
                                    sx={{ color: '#64696b' }}
                                >
                                    {`${props.data.numOfGuests} Guests`}
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>

                <Grid container item direction="column" xs={12} md={4}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            spacing: 1,
                            ml: 2,
                            mr: 2,
                            mt: 1,
                        }}
                    >
                        <Grid item>
                            <Chip
                                label={
                                    <Typography fontSize="0.8rem">
                                        {dashboardEventStatus}
                                    </Typography>
                                }
                                fontSize="0.8rem"
                                size="small"
                                style={{
                                    backgroundColor:
                                        statusColor(dashboardEventStatus),
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <IconButton
                                onClick={handleStatusClickOpen}
                                color="primary"
                                sx={{ padding: 0.2 }}
                            >
                                <FormatListBulletedIcon
                                    color="primary"
                                    sx={{ fontSize: 18 }}
                                />
                            </IconButton>
                        </Grid>
                    </Box>

                    <Grid
                        container
                        item
                        direction="row"
                        sx={{ ml: 2, mr: 2, mt: 1, mb: 1 }}
                    >
                        {renderAdditional(props.field, props.fieldName)}
                    </Grid>
                </Grid>

                <Grid container item direction="column" xs={12} md={1}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            spacing: 1,
                            ml: 2,
                            mr: 2,
                            mt: 1,
                        }}
                    >
                        <IconButton
                            onClick={clickToEvent}
                            color="primary"
                            sx={{ padding: 0.2 }}
                        >
                            <EditIcon sx={{ fontSize: 18 }} />
                            <Typography fontSize="0.8rem"> Edit </Typography>
                        </IconButton>
                    </Box>

                    <Grid
                        container
                        spacing={0.3}
                        direction="row"
                        sx={{ ml: 2, mr: 2, mt: 1 }}
                    >
                        {props.field && (
                            <Grid item>
                                {showPriority(
                                    dashboardEventStatus,
                                    props.data[props.fieldName],
                                    gapDays
                                ) ? (
                                    <Tooltip
                                        title={alertTooltip(
                                            dashboardEventStatus,
                                            gapDays
                                        )}
                                    >
                                        <AccessAlarmIcon
                                            sx={{ fontSize: 18, color: 'red' }}
                                        />
                                    </Tooltip>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            <Dialog
                disableEscapeKeyDown
                fullWidth
                open={openStatusSelect}
                onClose={handleStatusClose}
            >
                <DialogTitle>Update Event Status</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        select
                        id="eventStatus"
                        label="Event Status"
                        size="small"
                        variant="outlined"
                        margin="normal"
                        value={eventSelectValue}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => {
                            setEventSelectValue(e.target.value);
                        }}
                    >
                        {eventStatus.map((n) => (
                            <MenuItem key={n.value} value={n.value}>
                                {n.value}
                            </MenuItem>
                        ))}
                    </TextField>
                    {eventSelectValue === 'FollowUp' ? (
                        <div>
                            <DatePicker
                                size="small"
                                label="Follow-up Due Date"
                                variant="outlined"
                                autoOk
                                value={followUpDateValue}
                                inputVariant="outlined"
                                margin="normal"
                                onChange={(newDate) => {
                                    newDate.set({ h: 0, m: 0 });
                                    setFollowUpDateValue(newDate);
                                }}
                                minDate={moment().add(0, 'days')}
                                maxDate={moment().add(90, 'days')}
                                renderInput={(params) => (
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        sx={{ width: '100%' }}
                                        {...params}
                                    />
                                )}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleStatusClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={updateDashboardEventStatus}
                    >
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CateringEventResults;
