import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import ModifierCheckBox from './ModifierCheckBox';
import ModifierRadio from './ModifierRadio';

const useStyles = makeStyles(() => ({
    paper: {
        minWidth: '40%',
        padding: '10px',
    },
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function ModifierEditDialog({
    modifierHook,
    modifier,
    openFood,
    readOnly,
    open,
    setOpen,
    removeModifier,
}) {
    //const [open, setOpen] = React.useState(false);
    let classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    const [currentModifier, setCurrentModifier] = React.useState(
        modifier ? modifier : {}
    );
    const [selectedModifier, setSelectedModifier] = React.useState(
        modifier ? { ...modifier } : null
    );
    const [isEdit, setEdit] = React.useState(modifier ? true : false);
    const [modifierIndx, setModifierIndx] = React.useState(getCurrentIndex());
    function getCurrentIndex() {
        if (selectedModifier && modifierHook && modifierHook.currentModifiers) {
            let inx = 0;
            for (let mod of modifierHook.currentModifiers) {
                if (mod.id === selectedModifier.id) {
                    return inx;
                }
                inx++;
            }
        } else {
            return null;
        }
    }
    const handleChange = (event) => {
        setCurrentModifier(modifierHook.modifiers[event.target.value]);
    };
    const moveUpModifier = (event) => {
        modifierHook.moveUpModifier(modifier);
        let ofv = { ...openFood.value };
        ofv.food.modifiers = modifierHook.currentModifiers;
        openFood.setValue(ofv);
    };

    const moveDownModifier = (event) => {
        modifierHook.moveDownModifier(modifier);
        let ofv = { ...openFood.value };
        ofv.food.modifiers = modifierHook.currentModifiers;
        openFood.setValue(ofv);
    };
    //<Modifier modifier={currentModifier} modifierHook={modifierHook} index={index}  readOnly={readOnly}/>

    const saveChanges = () => {
        if (!currentModifier) {
            alert('Please select the modifier');
            return;
        }
        modifierHook.updateCurrentModifiers(
            isEdit,
            modifierIndx,
            currentModifier
        );
        handleClose();
    };
    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            classes={{ paper: classes.paper }}
        >
            <DialogTitle
                id="customized-dialog-title"
                onClose={(e) => {
                    handleClose();
                }}
            >
                Add/Edit Modifier
            </DialogTitle>
            {modifierHook && modifierHook.modifiers ? (
                <>
                    <FormControl
                        variant="outlined"
                        size="small"
                        margin="dense"
                        fullWidth
                    >
                        <InputLabel id="demo-simple-select-outlined-label">
                            All Modifiers
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={currentModifier ? currentModifier.id : null}
                            onChange={handleChange}
                            label="All Modifiers"
                        >
                            {Object.keys(modifierHook.modifiers).map(
                                (key, indx) => (
                                    <MenuItem
                                        key={modifierHook.modifiers[key].id}
                                        value={modifierHook.modifiers[key].id}
                                    >
                                        {modifierHook.modifiers[key].name
                                            ? modifierHook.modifiers[key].name
                                            : modifierHook.modifiers[key].label}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                    <br />
                    {currentModifier &&
                    currentModifier.max == 1 &&
                    !currentModifier.isOptional ? (
                        <ModifierRadio
                            modifier={currentModifier}
                            onChange={modifierHook.changeRadio}
                            removeModifier={removeModifier}
                        />
                    ) : (
                        <ModifierCheckBox
                            modifier={currentModifier}
                            onChange={modifierHook.changeCheckBox}
                            removeModifier={removeModifier}
                        />
                    )}
                </>
            ) : (
                <>
                    <b>Please add modifiers from Menu edit page </b>
                </>
            )}
            <DialogActions>
                <Button
                    autoFocus
                    onClick={(e) => {
                        handleClose();
                    }}
                    variant="outlined"
                >
                    Cancel
                </Button>
                <Button
                    autoFocus
                    onClick={(e) => {
                        saveChanges();
                    }}
                    variant="contained"
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}
