import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: '5px', //theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '5px', //theme.spacing(1),
    },
}));

const CustomDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        //color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

CustomDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function AddNewSectionDialog(props) {
    const [categoryName, setCategoryName] = useState('');
    const [afterCategoryName, setAfterCategoryName] = useState('');
    const [isError, setIsError] = useState(false);
    //console.log('sections', props.sections);

    const handleClose = () => {
        props.setOpen(false);
    };

    async function handleSave() {
        if (props.sections[categoryName]) {
            setIsError(true);
            return;
        }
        setIsError(false);
        handleClose();
        console.log(categoryName, props.sections[afterCategoryName]);
        props.handleSave(categoryName, props.sections[afterCategoryName]);
    }

    useEffect(() => {
        if (props.open) {
            setCategoryName('');
            setAfterCategoryName('');
            setIsError(false);
        }
    }, [props.open]);

    return (
        <div>
            <CustomDialog
                onClose={handleClose}
                aria-labelledby="add-new-category-dialog-title"
                open={props.open}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <CustomDialogTitle
                    id="add-new-category-dialog-title"
                    onClose={handleClose}
                >
                    Add New Category
                </CustomDialogTitle>

                <DialogContent dividers={true}>
                    <Box
                        noValidate
                        component="div"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: '5% 10%',
                        }}
                    >
                        <TextField
                            fullWidth
                            id="newCategoryname"
                            label="Category Name"
                            variant="outlined"
                            value={categoryName}
                            onChange={(e) => {
                                setIsError(false);
                                setCategoryName(e.target.value);
                            }}
                        />
                        {isError && (
                            <Typography
                                variant="body1"
                                sx={{
                                    color: 'red',
                                    fontWeight: 'normal',
                                    fontSize: '0.85rem',
                                }}
                            >
                                A category with same name already exists.
                            </Typography>
                        )}
                        <br />
                        <FormControl>
                            <InputLabel id="select-after-category">
                                After Category
                            </InputLabel>

                            <Select
                                labelId="select-after-category"
                                id="select-after-cat"
                                value={afterCategoryName}
                                label="Categories"
                                onChange={(e) =>
                                    setAfterCategoryName(e.target.value)
                                }
                                size="small"
                            >
                                {Object.keys(props.sections).map((section) => (
                                    <MenuItem
                                        value={section}
                                        key={props.sections[section]}
                                    >
                                        {section}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={handleClose}
                        variant="outlined"
                        size="medium"
                    >
                        Cancel
                    </Button>
                    <Button
                        autoFocus
                        onClick={handleSave}
                        variant="contained"
                        size="medium"
                    >
                        Save
                    </Button>
                </DialogActions>
            </CustomDialog>
        </div>
    );
}
