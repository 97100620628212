import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Chip,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

export default function FoodCardNew(props) {
    let cardMedia = true; //props.cardMedia;
    //console.log('OriginalPrice: ', props.data.original_price);
    return (
        <>
            <Card
                sx={{
                    display: 'flex',
                    borderBottom: '2px solid #36B37E',
                    boxShadow:
                        '0px 4px 20px rgba(0, 0, 0, 0.03), 4px 4px 20px rgba(34, 44, 49, 0.05)',
                    borderRadius: '4px',
                    height: '95%',
                    '& .MuiCardContent-root': { padding: '10px' },
                }}
            >
                <Box sx={{ width: props.editMode ? '70%' : '76%' }}>
                    {/*  <CardActionArea> */}
                    <CardContent
                        sx={{
                            flexDirection: 'column',
                        }}
                    >
                        <Typography
                            component="div"
                            variant="h6"
                            style={{
                                fontWeight: 'bold',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '1',
                                WebkitBoxOrient: 'vertical',
                            }}
                        >
                            {props.data.name}{' '}
                            {props.data.oos && (
                                <Chip
                                    color="error"
                                    label="Sold out"
                                    sx={{
                                        fontSize: '15px',
                                        height: '20px',
                                        '& .MuiChip-label': {
                                            padding: '5px',
                                        },
                                    }}
                                />
                            )}
                            {props.data.hide && (
                                <Chip
                                    color="error"
                                    label="Hidden"
                                    sx={{
                                        color: 'Pink',
                                        fontSize: '15px',
                                        height: '20px',
                                        '& .MuiChip-label': {
                                            padding: '5px',
                                        },
                                    }}
                                />
                            )}
                        </Typography>
                        <Typography
                            component="div"
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '2',
                                WebkitBoxOrient: 'vertical',
                            }}
                        >
                            {props.data.desc}
                        </Typography>
                        <Stack direction="row" spacing={2}>
                            <Typography
                                component="div"
                                style={{
                                    marginTop: '5px',
                                    fontWeight: 'bold',
                                }}
                            >
                                ${props.data.price / 100}
                            </Typography>
                            {props.showWasPrice &&
                                props.data.original_price &&
                                props.data.original_price != 0 && (
                                    <Typography
                                        component="div"
                                        style={{
                                            marginTop: '5px',
                                            fontWeight: 'bold',
                                            textDecoration: 'line-through',
                                            color: 'red',
                                        }}
                                    >
                                        ${props.data.original_price / 100}
                                    </Typography>
                                )}
                        </Stack>
                    </CardContent>
                    {/*  </CardActionArea> */}
                </Box>
                {cardMedia && (
                    <Box sx={{ width: '24%' }}>
                        <CardMedia
                            component="img"
                            alt="image"
                            style={{
                                marginTop: '10px',
                                width: '100%', //'80px',
                                height: '70%', //'80px'
                                objectFit: 'cover',
                            }}
                            image={
                                props.data.img
                                    ? props.data.img
                                    : 'https://sliceq.com/menu-images/stock_img.jpg'
                            }
                        />
                    </Box>
                )}
                {props.editMode && (
                    <Box
                        sx={{
                            width: '6%',
                            '& .MuiButtonBase-root': { p: 0.5 },
                        }}
                    >
                        <IconButton
                            color="primary"
                            onClick={(e) => {
                                props.handleDeletePopularItem(props.data.id);
                            }}
                        >
                            <CloseIcon sx={{ fontSize: 'medium' }} />
                        </IconButton>
                    </Box>
                )}
            </Card>
        </>
    );
}
