import React from 'react';
import CateringDashboardContent from '../Components/CateringDashboardContent.js';
import CateringDashboardContext from '../CateringDashboardContext.js';
import useCateringDashboard from '../useCateringDashboard.js';

const CateringDashboardRoot = () => {
    const cateringDashboardHook = useCateringDashboard();

    return (
        <CateringDashboardContext.Provider value={cateringDashboardHook}>
            <CateringDashboardContent />
        </CateringDashboardContext.Provider>
    );
};

export default CateringDashboardRoot;
