import React, { useEffect, useContext } from 'react';
import { DashboardContext } from './DashboardContext.js';
import { RestauInfoContext } from './RestauInfoContext.js';
import { useStyles } from './styles/useStyles';
import Grid from '@mui/material/Grid';
import PriceGrid from './PriceGrid';
import Typography from '@mui/material/Typography';
import Utils from '../utils/Utils';
const { getAmountFromKey } = { ...Utils };

export default function PastTwoDays() {
    const { pastTwoDaysHook } = useContext(DashboardContext);
    const { restauHook } = useContext(RestauInfoContext);
    const classes = useStyles();
    let toDay = new Date();
    const month = toDay.getMonth() + 1;
    const day = toDay.getDate();
    const year = toDay.getFullYear();
    let todayKey = month + '/' + day + '/' + year;
    let yesterDay = new Date(toDay);
    yesterDay.setDate(yesterDay.getDate() - 1);

    let yesterDayKey =
        yesterDay.getMonth() +
        1 +
        '/' +
        yesterDay.getDate() +
        '/' +
        yesterDay.getFullYear();

    useEffect(() => {
        console.log('Loading data from..... past two days');
        pastTwoDaysHook.loadData(restauHook);
    }, [restauHook.restauId]);

    function getTopItems(pastTwoDays) {
        let sortItems = [];
        if (pastTwoDays) {
            let items = pastTwoDays.items;

            Object.keys(items).forEach(function (k) {
                sortItems.push({ item: k, total: items[k] });
            });
            if (sortItems.length > 0) {
                sortItems.sort(function (a, b) {
                    return b.total - a.total;
                });
            }
        }
        return sortItems;
    }

    return (
        <div>
            {!pastTwoDaysHook.value.pastTwoDays ? (
                <div>
                    <img
                        className={classes.loadingImage}
                        src="../../img/spinner.gif"
                    />
                </div>
            ) : (
                <>
                    <Grid item xs={12}>
                        <Typography paragraph>
                            <Typography
                                component="b"
                                variant="h6"
                                color="primary"
                                gutterBottom
                            >
                                Today's Orders
                            </Typography>
                            <Grid container spacing={4}>
                                <PriceGrid
                                    title="Total Orders"
                                    total={
                                        pastTwoDaysHook.value.pastTwoDays[
                                            todayKey
                                        ]
                                            ? pastTwoDaysHook.value.pastTwoDays[
                                                  todayKey
                                              ].numberOfOrders
                                            : '0'
                                    }
                                    date={todayKey}
                                    topItems={getTopItems(
                                        pastTwoDaysHook.value.pastTwoDays[
                                            todayKey
                                        ]
                                    )}
                                />
                                <PriceGrid
                                    title="Total Payments"
                                    total={getAmountFromKey(
                                        pastTwoDaysHook.value.pastTwoDays[
                                            todayKey
                                        ],
                                        'paymentTotal'
                                    )}
                                    date={todayKey}
                                />
                                <PriceGrid
                                    title="Total Tips"
                                    total={getAmountFromKey(
                                        pastTwoDaysHook.value.pastTwoDays[
                                            todayKey
                                        ],
                                        'tip'
                                    )}
                                    date={todayKey}
                                />
                                <PriceGrid
                                    title="Average Order Price"
                                    total={getAmountFromKey(
                                        pastTwoDaysHook.value.pastTwoDays[
                                            todayKey
                                        ],
                                        'averageOrder'
                                    )}
                                    date={todayKey}
                                />
                            </Grid>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography paragraph>
                            <Typography
                                component="b"
                                variant="h6"
                                color="primary"
                                gutterBottom
                            >
                                Yesterday's Orders
                            </Typography>
                            <Grid container spacing={4}>
                                <PriceGrid
                                    title="Total Orders"
                                    total={
                                        pastTwoDaysHook.value.pastTwoDays[
                                            yesterDayKey
                                        ]
                                            ? pastTwoDaysHook.value.pastTwoDays[
                                                  yesterDayKey
                                              ].numberOfOrders
                                            : '0'
                                    }
                                    date={yesterDayKey}
                                    topItems={getTopItems(
                                        pastTwoDaysHook.value.pastTwoDays[
                                            yesterDayKey
                                        ]
                                    )}
                                />
                                <PriceGrid
                                    title="Total Payments"
                                    total={getAmountFromKey(
                                        pastTwoDaysHook.value.pastTwoDays[
                                            yesterDayKey
                                        ],
                                        'paymentTotal'
                                    )}
                                    date={yesterDayKey}
                                />
                                <PriceGrid
                                    title="Total Tips"
                                    total={getAmountFromKey(
                                        pastTwoDaysHook.value.pastTwoDays[
                                            yesterDayKey
                                        ],
                                        'tip'
                                    )}
                                    date={yesterDayKey}
                                />
                                <PriceGrid
                                    title="Average Order Price"
                                    total={getAmountFromKey(
                                        pastTwoDaysHook.value.pastTwoDays[
                                            yesterDayKey
                                        ],
                                        'averageOrder'
                                    )}
                                    date={yesterDayKey}
                                />
                            </Grid>
                        </Typography>
                    </Grid>
                </>
            )}
        </div>
    );
}
