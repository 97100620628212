import React from 'react';
import DisplayChart from '../utils/DisplayChart';
import PropTypes from 'prop-types';

export default function CallSummaryByDay(props) {
    // const data = [
    //     [
    //         'Day',
    //         'Pressed_1',
    //         { role: 'annotation' },
    //         'Pressed_2',
    //         { role: 'annotation' },
    //         'Pressed_2_But_Busy',
    //         { role: 'annotation' },
    //         'Pressed_Catering',
    //         { role: 'annotation' },
    //         'Pressed_Store_hours',
    //         { role: 'annotation' },
    //         'Pressed_Banquet',
    //         { role: 'annotation' },
    //     ],
    //     ['Sunday', 10, '10', 20, '20', 25, '25', 3, '3', 5, '5', 19, '19'],
    //     ['Monday', 13, '13', 20, '20', 25, '25', 3, '3', 15, '15', 9, '9'],
    //     ['Tuesday', 18, '18', 20, '20', 25, '25', 13, '13', 5, '5', 9, '9'],
    //     ['Wednesday', 12, '12', 20, '20', 25, '25', 3, '3', 25, '25', 9, '9'],
    //     ['Thursday', 8, '8', 20, '20', 25, '25', 3, '3', 5, '5', 39, '39'],
    //     ['Friday', 7, '7', 20, '20', 25, '25', 13, '13', 15, '15', 9, '9'],
    //     ['Staurday', 9, '9', 10, '10', 35, '35', 23, '23', 25, '25', 9, '9'],
    // ];

    // const data = [
    //     [
    //         'Day',
    //         'Pressed_1',
    //         'Pressed_2_But_Busy',
    //         'Pressed_Catering',
    //         'Pressed_Store_hours',
    //         'Pressed_Banquet',
    //     ],
    //     ['Sunday', 10, 25, 3, 5, 19],
    //     ['Monday', 13, 25, 3, 15, 9],
    //     ['Tuesday', 18, 25, 13, 5, 9],
    //     ['Wednesday', 12, 25, 3, 25, 9],
    //     ['Thursday', 8, 25, 3, 5, 39],
    //     ['Friday', 7, 25, 13, 15, 9],
    //     ['Staurday', 9, 35, 23, 25, 9],
    // ];

    const { data, chartColorPriority } = props;
    //console.log('Data received :', data);

    const options = {
        //title: 'Call Recrods Summary - By Day',
        chartArea: {
            top: 60,
            bottom: 40,
            width: '80%',
            height: '80%',
        },
        isStacked: true,
        hAxis: {
            title: 'Day',
            minValue: 0,
        },
        vAxis: {
            title: 'Call Count',
        },
        bar: { groupWidth: '35%' },
        theme: 'material',
        legend: {
            position: 'top',
            alignment: 'middle',
            maxLines: 4,
        },
        colors: [...chartColorPriority],
    };

    return (
        <DisplayChart
            chartType="ColumnChart"
            width="100%"
            height="350px"
            data={data}
            options={options}
        />
    );
}

CallSummaryByDay.propTypes = {
    data: PropTypes.array,
    chartColorPriority: PropTypes.array,
};

CallSummaryByDay.defaultProps = {};
