import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import BrandingTemplate from './components/branding/BrandingTemplate';
import LoginForm from './components/forms/LoginForm';
import { useAuthentication } from './Hooks/useAuthentication';

function Login(props) {
    const auth = useAuthentication();

    let navigate = useNavigate();
    const [loaded, setLoaded] = React.useState(false);
    const [loginMessage, setLoginMessage] = React.useState('');
    const [values, setValues] = React.useState({
        password: '',
        email: '',
        error: '',
        showPassword: false,
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    useEffect(() => {
        // Update the document title using the browser API
        console.log(values.email);
    }, [values.email]);
    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            handleLogin(event);
        }
    }
    function handleLogin(e) {
        e.preventDefault();
        setLoaded(true);
        setLoginMessage('');
        window.customerApp
            .auth()
            .signInWithEmailAndPassword(values.email, values.password)
            .then((res) => {
                setLoaded(false);
                const search = window.location.search;
                const params = new URLSearchParams(search);
                let path = params.get('path');
                if (path && path === 'subscription') {
                    auth.loggedInUser(res.user);
                    navigate('/subscription');
                } else if (res.user) {
                    auth.loggedInUser(res.user);
                    navigate('/home');
                }
            })
            .catch((e) => {
                setLoaded(false);
                setLoginMessage('Invalid login');
                setValues({ ...values, error: e.message });
            });
    }

    return (
        <BrandingTemplate>
            <LoginForm
                email={values.email}
                loaded={loaded}
                loginMessage={loginMessage}
                password={values.password}
                onEmailChange={handleChange('email')}
                onKeyPress={handleKeyPress}
                onLogin={handleLogin}
                onPasswordChange={handleChange('password')}
            />
        </BrandingTemplate>
    );
}

export default Login;
