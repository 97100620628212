import React from 'react';
import { Grid, AppBar } from '@mui/material';

function BrandingMobile(props) {
    const { children } = props;
    return (
        <>
            <Grid container alignItems="center" justify="center">
                <Grid item xs={12}>
                    <AppBar
                        position="sticky"
                        style={{
                            background: '#222C31',
                        }}
                    >
                        <img
                            src="../../img/LogoImg.png"
                            style={{ height: 200, width: 180, margin: 'auto' }}
                        />
                    </AppBar>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ padding: 20 }}>{children}</div>
                </Grid>
            </Grid>
        </>
    );
}

BrandingMobile.propTypes = {};

export default BrandingMobile;
