import React from 'react';
import RegularHoursConfig from './RegularHoursConfig';

function RegularHoursContainer(props) {
    const { day, config } = props;

    const {
        list,
        handleRemoveSlot,
        handleAddSlot,
        handleChangeSlot,
        holiday,
        toggleHoliday,
    } = config;

    return (
        <div>
            <RegularHoursConfig
                day={day}
                disabled={holiday}
                list={list}
                holiday={holiday}
                onAddSlot={handleAddSlot}
                onRemoveSlot={handleRemoveSlot}
                onChangeSlot={handleChangeSlot}
                onToggleHoliday={toggleHoliday}
            />
        </div>
    );
}

RegularHoursContainer.propTypes = {};

export default RegularHoursContainer;
