import React from 'react';
import { Chart } from 'react-google-charts';
import PropTypes from 'prop-types';

export default function DisplayChart(props) {
    const { data, options, chartType, width, height } = props;

    return (
        <Chart
            chartType={chartType}
            width={width}
            height={height}
            data={data}
            options={options}
        />
    );
}

DisplayChart.propTypes = {
    data: PropTypes.array,
    options: PropTypes.shape({}),
    chartType: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};
