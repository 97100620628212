import React, { useContext, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Chip,
    IconButton,
    Typography,
} from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import AudioPlayer from 'react-h5-audio-player';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import PropTypes from 'prop-types';
import PhoneAudioLibContext from '../PhoneAudioLibContext';
import ConfirmationDialog from './ConfirmationDialog';

function AudioFileCard(props) {
    const {
        index,
        fileDetails,
        restauId,
        loadData,
        startEditAudioFile,
        readOnly,
    } = props;
    const { deleteAudioFile, setShowStatus, setStatusMsg, setIsError } =
        useContext(PhoneAudioLibContext);
    //const [tempFileName, setTempFileName] = useState(fileDetails.displayName);
    //const [startEditingFileName, setStartEditingFileName] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    //const [isFileUpdateOperation, setIsFileUpdateOperation] = useState(null);

    const handleFileDelete = async () => {
        try {
            await deleteAudioFile(restauId, fileDetails.id);
            loadData();
            setIsError(false);
            setStatusMsg('File deleted from Library succesfully.');
        } catch (ex) {
            setIsError(true);
            setStatusMsg(
                `Failed to delete file from Library. Error: ${ex.toString()}`
            );
        } finally {
            setShowStatus(true);
        }
    };

    const downloadAudioFile = async () => {
        // const a = document.createElement('a');
        // a.style.display = 'none';
        // a.href = fileDetails.url;
        // a.download = 'recording.mp3';
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);

        try {
            window.open(fileDetails.url, '_blank').focus();
            // const link = document.createElement('a');
            // link.href = fileDetails.url;
            // // link.href =
            // //     'https://strideq-assets-dharma-2102-prod.twil.io/OssTQlt548AfPksi3wPlt.mp3';
            // link.download = 'audio-file.mp3';
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);
        } catch (error) {
            console.error('There was an error downloading the file:', error);
        }
    };

    // const revertFileName = () => {
    //     setTempFileName(fileDetails.displayName);
    //     setStartEditingFileName(false);
    // };

    return (
        <>
            {/* <ConfirmationDialog
                showConfirmationDialog={showConfirmDialog}
                setShowConfirmationDialog={setShowConfirmDialog}
                handleConfirm={
                    isFileUpdateOperation
                        ? async () => {
                              await handleFileNameChange(
                                  fileDetails.id,
                                  tempFileName
                              );
                              setStartEditingFileName(false);
                          }
                        : handleFileDelete
                }
                handleClose={isFileUpdateOperation ? revertFileName : null}
                dialogMsg={
                    isFileUpdateOperation
                        ? 'Are you sure, you want to update the filename in Library?'
                        : 'Are you sure, you want to delete the file from Library?'
                }
            /> */}
            <ConfirmationDialog
                showConfirmationDialog={showConfirmDialog}
                setShowConfirmationDialog={setShowConfirmDialog}
                handleConfirm={handleFileDelete}
                //handleClose={isFileUpdateOperation ? revertFileName : null}
                dialogMsg="Are you sure, you want to delete the file from Library?"
            />
            <Card
                raised
                sx={{
                    display: 'flex',
                    width: '100%',
                    //backgroundColor: index % 2 ? '#EAE9F8' : '#ddf1e9',
                    ':hover': {
                        boxShadow: 20, // theme.shadows[20]
                    },
                }}
            >
                <Box sx={{ flexDirection: 'column', flex: 1, display: 'flex' }}>
                    <CardContent
                        sx={{
                            flex: '1 0 auto',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '80%',
                                    wordBreak: 'break-all', // Breaks words at any character
                                    overflowWrap: 'break-word', // Breaks long words
                                    whiteSpace: 'normal', // Ensures text wraps normally
                                }}
                            >
                                <Typography
                                    component="div"
                                    variant="h6"
                                    sx={{ mb: 0 }}
                                >
                                    {fileDetails.displayName}

                                    {readOnly ? (
                                        <Chip
                                            color="secondary"
                                            label={
                                                <Typography fontSize="0.8rem">
                                                    from: {fileDetails.restauId}
                                                </Typography>
                                            }
                                            fontSize="0.8rem"
                                            size="small"
                                            sx={{ ml: 1 }}
                                        />
                                    ) : (
                                        fileDetails.shared && (
                                            <Chip
                                                color="secondary"
                                                label={
                                                    <Typography fontSize="0.8rem">
                                                        shared
                                                    </Typography>
                                                }
                                                fontSize="0.8rem"
                                                size="small"
                                                sx={{ ml: 1 }}
                                            />
                                        )
                                    )}
                                </Typography>
                            </Box>
                            <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
                                <IconButton aria-label="edit">
                                    <DownloadIcon
                                        color="primary"
                                        onClick={() =>
                                            downloadAudioFile(fileDetails.id)
                                        }
                                    />
                                </IconButton>
                                <IconButton
                                    aria-label="edit"
                                    disabled={readOnly}
                                >
                                    <EditIcon
                                        color={!readOnly ? 'primary' : ''}
                                        onClick={() =>
                                            startEditAudioFile(fileDetails.id)
                                        }
                                    />
                                </IconButton>
                                <IconButton
                                    aria-label="delete"
                                    disabled={readOnly}
                                >
                                    <DeleteIcon
                                        color={!readOnly ? 'secondary' : ''}
                                        onClick={() => {
                                            //setIsFileUpdateOperation(false);
                                            setShowConfirmDialog(true);
                                        }}
                                    />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                // pl: 1,
                                // pb: 1,
                                // ml: 1,
                                // mb: 1,
                                width: '80%',
                                margin: '0 auto', // Center the box horizontally
                                '& .rhap_main-controls-button': {
                                    color: '#7777F7',
                                },
                            }}
                        >
                            <StyledEngineProvider injectFirst>
                                <AudioPlayer
                                    // style={{
                                    //     backgroundColor:
                                    //         index % 2 ? '#EAE9F8' : '#ddf1e9',
                                    // }}
                                    //loop={false}
                                    volume={0.75}
                                    //autoPlay
                                    //src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
                                    src={fileDetails.url}
                                    //onPlay={(e) => console.log('onPlay')}
                                />
                            </StyledEngineProvider>
                        </Box>
                    </CardContent>
                </Box>
            </Card>
        </>
    );
}

AudioFileCard.propTypes = {
    index: PropTypes.number,
    readOnly: PropTypes.bool,
    fileDetails: PropTypes.shape({
        displayName: PropTypes.string,
        url: PropTypes.string,
        id: PropTypes.string,
        shared: PropTypes.bool,
        restauId: PropTypes.string,
    }),
    restauId: PropTypes.string,
    loadData: PropTypes.func,
    //handleFileNameChange: PropTypes.func,
    startEditAudioFile: PropTypes.func,
};

export default AudioFileCard;
