import React from 'react';
import {
    Dialog,
    DialogContent,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@mui/material';
import OrderItemAndModifiers from './OrderItemAndModifiers';

export function OrderItemsDialog(props) {
    //const [isClose, setisClose] = useState(false)
    const itemsData = props.itemsData;
    //console.log("Items Data",JSON.stringify(itemsData));
    const itemsToDisplay = itemsData.items && itemsData.items;
    //console.log("Items to Display",JSON.stringify(itemsToDisplay));
    const orderInst = itemsData.orderInst;

    //console.log('Order Instructions', orderInst);

    //console.log("Display?",itemsData.display);
    //console.log("IsClose",props.isClose);

    function handleClose() {
        //console.log("Closing popup..")
        props.setisClose(true);
    }

    function calcItemPriceWithModifiers(item) {
        let modifiersPrice =
            item.modifierGroup && item.modifierGroup.modifiers.length > 0
                ? item.modifierGroup.modifiers.reduce((total, modifier) => {
                      //console.log('total', total, 'modifier', modifier);
                      let checkedOptions =
                          modifier.options &&
                          modifier.options.filter((option) => option.checked);
                      //console.table(checkedOptions);
                      return (
                          total +
                          (checkedOptions
                              ? checkedOptions.reduce((tot, option) => {
                                    return tot + option.price * option.quantity;
                                }, 0)
                              : 0)
                      );
                  }, 0)
                : 0;

        //console.log('ModifiersPrice', modifiersPrice);
        let itemTotalPrice = (modifiersPrice + item.price) / 100;
        //console.log('ItemPrice', itemTotalPrice);
        return itemTotalPrice;
    }

    return itemsData && itemsData.display && !props.isClose ? (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={itemsData && itemsData.display}
            onClose={handleClose}
        >
            {orderInst && (
                <Typography variant="h6" style={{ margin: '10px 10px 0' }}>
                    Order Instructions:
                    <span
                        style={{
                            display: 'block',
                            fontSize: '0.75rem',
                            fontWeight: 'normal',
                        }}
                    >
                        {orderInst}
                    </span>
                </Typography>
            )}
            <Typography variant="h6" style={{ margin: '0 10px' }}>
                Items:
            </Typography>

            <DialogContent>
                <TableContainer style={{ maxHeight: '400px' }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow
                                style={{
                                    backgroundColor: '#f5f5f5',
                                    height: '35px',
                                }}
                            >
                                <TableCell>Name</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {itemsToDisplay &&
                                Object.keys(itemsToDisplay).map(
                                    (key, index) => {
                                        let itemSubTotal =
                                            calcItemPriceWithModifiers(
                                                itemsToDisplay[key]
                                            );
                                        return (
                                            <TableRow
                                                key={itemsToDisplay[key].key}
                                            >
                                                <TableCell>
                                                    <OrderItemAndModifiers
                                                        orderItem={
                                                            itemsToDisplay[key]
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {
                                                        itemsToDisplay[key]
                                                            .quantity
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {'$' + itemSubTotal}
                                                </TableCell>
                                                <TableCell>
                                                    {'$' +
                                                        itemsToDisplay[key]
                                                            .quantity *
                                                            itemSubTotal}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                )}
                            <TableRow key="subTotal">
                                <TableCell rowSpan={3} colSpan={2} />
                                <TableCell style={{ textAlign: 'right' }}>
                                    {' '}
                                    Sub Total :{' '}
                                </TableCell>
                                <TableCell>
                                    {itemsData &&
                                        '$' + itemsData.subTotal / 100}
                                </TableCell>
                            </TableRow>
                            <TableRow key="salesTax">
                                <TableCell style={{ textAlign: 'right' }}>
                                    {' '}
                                    Tax :{' '}
                                </TableCell>
                                <TableCell>
                                    {itemsData && '$' + itemsData.tax / 100}
                                </TableCell>
                            </TableRow>
                            <TableRow key="tip">
                                <TableCell style={{ textAlign: 'right' }}>
                                    {' '}
                                    Tip :{' '}
                                </TableCell>
                                <TableCell>
                                    {itemsData && '$' + itemsData.tip / 100}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
        </Dialog>
    ) : null;
}
