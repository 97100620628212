import React, { useState, useContext, useEffect } from 'react';
import Field from './Field';
import FieldDialog from './FieldDialog';
import { Grid, Typography, Button } from '@mui/material';
import CateringSettingContext from '../CateringSettingContext';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import { useTheme } from '@mui/material/styles';
import SortFieldDialog from './SortFieldDialog';
import SortIcon from '@mui/icons-material/Sort';

const EditFormFields = () => {
    const { fields, updateFormConfig } = useContext(CateringSettingContext);
    const defaultField = {
        enable_field: true,
        show_in_customer_form: true,
        show_in_ai_bot: false,
        is_system: false,
        field_name: '',
        field_label: 'Custom Field',
        field_description: '',
        field_type: '',
        validation: {
            required: false,
        },
    };
    const [allFields, setAllFields] = useState(fields);
    const [openFieldDialog, setOpenFieldDialog] = useState(false);
    const [fieldData, setFieldData] = useState({});
    const [isAddingNewField, setIsAddingNewField] = useState(false);
    const [fieldDialogIndex, setFieldDialogIndex] = useState(null);
    const [dialogTitle, setDialogTitle] = useState('Edit Field');
    const [openSortFieldDialog, setOpenSortFieldDialog] = useState(false);
    const theme = useTheme();
    useEffect(() => {
        setAllFields(fields);
    }, [fields]);

    const handleEdit = (i, fieldsToUse) => {
        setOpenFieldDialog(true);
        setFieldData(fieldsToUse[i]);
        setFieldDialogIndex(i);
    };

    const addField = () => {
        setIsAddingNewField(true);
        setDialogTitle('Create Field');
        setAllFields((prevFields) => {
            const newFields = [...prevFields, defaultField];
            handleEdit(newFields.length - 1, newFields);
            return newFields;
        });
    };

    const handleDelete = async (i) => {
        const newFields = allFields.filter((field, index) => index !== i);
        setAllFields(newFields);
        let response = await updateFormConfig({ fields: newFields });
    };

    return (
        allFields && (
            <Grid container>
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography color="primary" variant="h6" sx={{ mr: 2 }}>
                        Form Field Customization
                    </Typography>
                    <Button
                        aria-label="sort fields"
                        onClick={setOpenSortFieldDialog}
                        sx={{
                            alignSelf: 'flex-end',
                            border: 0,
                            display: 'flex',
                            alignItems: 'center',
                            padding: 0,
                        }}
                    >
                        <SortIcon sx={{ fontSize: '22px' }} />
                        <Typography color="primary" variant="h6">
                            Sort Fields
                        </Typography>
                    </Button>
                </Grid>
                {allFields.map((field, i) => (
                    <Field
                        key={i}
                        field={field}
                        handleEdit={() => handleEdit(i, allFields)}
                        handleDelete={() => handleDelete(i)}
                    />
                ))}
                {openFieldDialog ? (
                    <FieldDialog
                        open={openFieldDialog}
                        setOpenFieldDialog={setOpenFieldDialog}
                        dialogTitle={dialogTitle}
                        setDialogTitle={setDialogTitle}
                        defaultField={defaultField}
                        fieldData={fieldData}
                        isAddingNewField={isAddingNewField}
                        setIsAddingNewField={setIsAddingNewField}
                        allFields={allFields}
                        setAllFields={setAllFields}
                        fieldDialogIndex={fieldDialogIndex}
                        setFieldDialogIndex={setFieldDialogIndex}
                    />
                ) : (
                    <></>
                )}
                {openSortFieldDialog ? (
                    <SortFieldDialog
                        open={openSortFieldDialog}
                        setOpenSortFieldDialog={setOpenSortFieldDialog}
                        dialogTitle="Sort Fields"
                        allFields={allFields}
                        setAllFields={setAllFields}
                    />
                ) : (
                    <></>
                )}
                <Grid item xs={12} sx={{ mt: 1 }}>
                    <Button
                        component="label"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: 1,
                            height: '100%',
                            [theme.breakpoints.down('xs')]: {
                                maxWidth: '20%',
                            },
                            [theme.breakpoints.up('md')]: {
                                maxWidth: '10%',
                            },
                        }}
                        onClick={addField}
                    >
                        <AddCircleSharpIcon
                            color="primary"
                            sx={{ fontSize: 20 }}
                        />
                        <Typography
                            align="center"
                            variant="body2"
                            sx={{ m: 'auto' }}
                        >
                            Add Field
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        )
    );
};

export default EditFormFields;
