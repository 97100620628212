import React from 'react';
import useVisOverride from '../useVisOverride';
import VisOverrideContext from '../VisOverrideContext';
import VisibilityOverrideContent from '../components/VisibilityOverrideContent';

// The main component which is dsiplayed in the "Visibility Overerides" tab.
function VisibilityOverrideRoot() {
    const visOverrideHook = useVisOverride();

    return (
        <VisOverrideContext.Provider value={visOverrideHook}>
            <VisibilityOverrideContent />
        </VisOverrideContext.Provider>
    );
}

VisibilityOverrideRoot.propTypes = {};

export default VisibilityOverrideRoot;
