import React from 'react';
import { DashboardProvider } from './home/DashboardContext';
import Home from './home/Home';
import { MenuProvider } from './home/MenuInfoContext';
import { RestaurantProvider } from './home/RestauInfoContext';
import useScript from './Menu/hooks/useScript';

export default function App({ children }) {
    useScript('//js.hs-scripts.com/21878431.js');
    console.log(children);
    return (
        <RestaurantProvider>
            <MenuProvider>
                <DashboardProvider>
                    <Home />
                </DashboardProvider>
            </MenuProvider>
        </RestaurantProvider>
    );
}
