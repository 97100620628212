import FormControlLabel from '@mui/material/FormControlLabel';
import makeStyles from '@mui/styles/makeStyles';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ToggleField from '../../ToggleField';
import {
    LabelContainer,
    ModifierCheckbox,
    ModifierDiv,
    ModifierGrid,
} from './ModifierEditStyle';
import React from 'react';

const style = makeStyles((theme) => ({
    input: {
        marginTop: '20px',
        marginBottom: '10px',
        backgroundColor: 'white',
        marginLeft: '2px',
        marginRight: '3px',
    },
    number: {
        marginTop: '20px',
        marginBottom: '10px',
        backgroundColor: 'white',
        marginLeft: '2px',
        marginRight: '15px',
    },
    icon: {
        position: 'absolute',
        right: 0,
        top: 0,
    },
}));
export default function ModifierEdit({ modifierHook }) {
    const modifier = modifierHook.getCurrentAddEditModifier();

    const styl = style();
    function isDisabled(modifier, isChecked) {
        let max = modifier.max;
        let count = 0;
        for (let option of modifier.options) {
            if (option.checked) {
                count++;
            }
        }
        console.log('Max checked is:::');
        console.log(max + ':' + count + ':' + isChecked);
        if (max && max != 0 && count >= max && !isChecked) {
            return true;
        } else {
            return false;
        }
    }

    function getPrice(price) {
        if (!price) {
            return price;
        }

        let p = parseFloat(price) / 100;
        return p; //.toFixed(2);
        //disabled={isEnabled(modifier, option.checked)}
    }

    function getPriceOnOptions(modifierId, optionId) {
        console.log('New modifier.');
        console.log(modifierId + optionId);
        console.log(modifierHook.convertedPrice);
        if (!modifierHook.convertedPrice) {
            return 0;
        }
        return modifierHook.convertedPrice[modifierId + optionId];
    }
    let bgcolor = 'white';

    return (
        <ModifierDiv
            style={{
                backgroundColor: bgcolor,
                border: '1px solid #ccc',
                padding: 4,
                borderRadius: 4,
            }}
        >
            <div style={{ position: 'relative' }}>
                <div className={styl.icon}>
                    {/* <CancelIcon onClick ={(e)=>{modifierHook.deleteAllModifier(modifier.id);}}/> */}
                </div>
            </div>

            <LabelContainer>
                <b>{modifier.name ? modifier.name : modifier.label}</b>
            </LabelContainer>
            <ModifierGrid>
                <TextField
                    label="Name (internal)"
                    value={modifier.name}
                    onChange={(e) => {
                        modifierHook.changeModifier(modifier.id, 'NAME', e);
                        //setModifier({...modifier, label: e.})
                    }}
                    variant="outlined"
                    size="small"
                    margin="dense"
                />
            </ModifierGrid>
            <ModifierGrid>
                <TextField
                    label="Label (shown to customer)"
                    value={modifier.label}
                    onChange={(e) => {
                        modifierHook.changeModifier(modifier.id, 'LABEL', e);
                        //setModifier({...modifier, label: e.})
                    }}
                    variant="outlined"
                    size="small"
                    margin="dense"
                />
            </ModifierGrid>
            <ModifierGrid>
                <TextField
                    label="Minimum Selected"
                    value={modifier.min}
                    onChange={(e) => {
                        modifierHook.changeModifier(modifier.id, 'MIN', e);
                    }}
                    type="number"
                    variant="outlined"
                    size="small"
                    margin="dense"
                />
                <TextField
                    label="Maximum Selected"
                    value={modifier.max}
                    onChange={(e) => {
                        modifierHook.changeModifier(modifier.id, 'MAX', e);
                    }}
                    type="number"
                    variant="outlined"
                    size="small"
                    margin="dense"
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={
                                modifier.isOptional
                                    ? modifier.isOptional
                                    : false
                            }
                            onChange={(e) => {
                                modifierHook.changeOptional(modifier.id);
                            }}
                            name="optional"
                            label="Optional"
                            labelPlacement="end"
                            color="primary"
                            id="optional"
                        />
                    }
                    htmlFor="holiday-switch"
                    label="Optional"
                />
            </ModifierGrid>
            <ModifierGrid>
                <LabelContainer>
                    <b>Options</b>
                </LabelContainer>
                <div>
                    {modifier.options &&
                        Object.values(modifier.options).map((option, i) => (
                            <>
                                <label
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: '5px',
                                    }}
                                >
                                    <TextField
                                        id={option.id}
                                        label="Option Label"
                                        value={option.label}
                                        onChange={(e) => {
                                            modifierHook.changeValue(
                                                modifier.id,
                                                option.id,
                                                'LABEL',
                                                e
                                            );
                                        }}
                                        variant="outlined"
                                        size="small"
                                        margin="dense"
                                    />
                                    <TextField
                                        id={option.id}
                                        label="Option Price"
                                        value={getPriceOnOptions(
                                            modifier.id,
                                            option.id
                                        )}
                                        onChange={(e) => {
                                            modifierHook.changeValue(
                                                modifier.id,
                                                option.id,
                                                'PRICE',
                                                e
                                            );
                                        }}
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        margin="dense"
                                    />

                                    <ModifierCheckbox
                                        type="checkbox"
                                        checked={
                                            option.checked
                                                ? option.checked
                                                : false
                                        }
                                        id={option.id}
                                        name={modifier.id}
                                        disabled={isDisabled(
                                            modifier,
                                            option.checked
                                        )}
                                        onClick={(e) => {
                                            modifierHook.changeCheckBox(
                                                modifier.id,
                                                option.id
                                            );
                                        }}
                                    />

                                    <DeleteOutlineIcon
                                        onClick={(e) => {
                                            modifierHook.deleteOption(
                                                modifier.id,
                                                option.id
                                            );
                                        }}
                                    />
                                    <ToggleField
                                        name="hidden"
                                        label="Hidden"
                                        labelPlacement="Top"
                                        id="hidden"
                                        size="small"
                                        checked={
                                            option.hide ? option.hide : false
                                        }
                                        onChange={(e) => {
                                            modifierHook.setOptionHiddenValue(
                                                modifier.id,
                                                option.id
                                            );
                                        }}
                                    />
                                    <ToggleField
                                        name="OutofStock"
                                        label="OutofStock"
                                        labelPlacement="Top"
                                        id="OutofStock"
                                        size="small"
                                        checked={
                                            option.oos ? option.oos : false
                                        }
                                        onChange={(e) => {
                                            modifierHook.setOptionOOSValue(
                                                modifier.id,
                                                option.id
                                            );
                                        }}
                                    />
                                </label>
                            </>
                        ))}
                    <div
                        onClick={(e) => {
                            modifierHook.addOption(modifier.id);
                        }}
                    >
                        <AddCircleOutlineIcon />
                        Add Option
                    </div>
                </div>
            </ModifierGrid>
        </ModifierDiv>
    );
}
