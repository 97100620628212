import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

function PriceField(props) {
    const { id, label, value, fullWidth, onChange } = props;
    console.log(parseFloat(value));
    // console.log(value.toFixed && value.toFixed(2));
    return (
        <TextField
            id={id}
            type="number"
            label={label}
            value={parseFloat(value / 100)}
            onChange={onChange}
            type="number"
            variant="outlined"
            size="small"
            margin="dense"
            fullWidth={fullWidth}
            InputLabelProps={{
                shrink: true,
            }}
            InputProps={{
                // readOnly: true,
                startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                ),
            }}
        />
    );
}

PriceField.propTypes = {};

export default PriceField;
