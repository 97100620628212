import React from 'react';
import { formatPrice } from '../Data/FoodData';
import { getQuatity } from './ModifierUtil';
import FoodHidden from '../FoodTemplates/FoodHidden';
import FoodOOS from '../FoodTemplates/FoodOOS';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    Typography,
} from '@mui/material';

export default function ModifierCheckBox({
    modifier,
    onChange,
    removeModifier,
}) {
    function isDisabled(modifier, isChecked) {
        let max = modifier.max;
        let count = 0;
        for (let option of modifier.options) {
            if (option.checked) {
                count++;
            }
        }
        if (max && max != 0 && count >= max && !isChecked) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            {modifier !== null && (
                <Grid container justifyContent="space-between" direction="row">
                    <Grid item xs={7}>
                        {modifier.label ? (
                            <Typography
                                variant="h6"
                                //style={{ paddingBottom: '3px' }}
                            >
                                {modifier.label}
                                <Typography
                                    component="subtitle1"
                                    sx={{ ml: 1 }}
                                >
                                    (Select 1 or more)
                                </Typography>
                            </Typography>
                        ) : (
                            <Typography
                                variant="h6"
                                //style={{ paddingBottom: '3px' }}
                            >
                                Options:
                                <Typography
                                    component="subtitle1"
                                    sx={{ ml: 1 }}
                                >
                                    Select 1 or more
                                </Typography>
                            </Typography>
                        )}
                    </Grid>
                    {removeModifier && (
                        <Grid item xs={5}>
                            <Button
                                sx={{
                                    border: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                onClick={(e) => {
                                    removeModifier(modifier);
                                }}
                            >
                                <DeleteOutlineIcon sx={{ fontSize: 23 }} />
                                <Typography sx={{ ml: 0.5 }}>
                                    Remove Modifier
                                </Typography>
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        {modifier.options &&
                            modifier.options.map((option, i) => (
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    option.checked
                                                        ? option.checked
                                                        : false
                                                }
                                                id={option.id}
                                                disabled={isDisabled(
                                                    modifier,
                                                    option.checked
                                                )}
                                                name={modifier.id}
                                            />
                                        }
                                        label={
                                            <Typography
                                                variant="subtitle1"
                                                component="span"
                                            >
                                                {option.label}
                                                {option.price
                                                    ? ' (+' +
                                                      formatPrice(
                                                          (option.price *
                                                              getQuatity(
                                                                  option.quantity
                                                              )) /
                                                              100
                                                      ) +
                                                      ') '
                                                    : ''}
                                                {option.hide ? (
                                                    <FoodHidden />
                                                ) : (
                                                    ''
                                                )}
                                                {option.oos ? <FoodOOS /> : ''}
                                            </Typography>
                                        }
                                    />
                                </FormGroup>
                            ))}
                    </Grid>
                </Grid>
            )}
        </>
    );
}
