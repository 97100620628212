import styled from 'styled-components';

const ConfigContainer = styled.div`
    display: flex;
    flex-direction: ${({ verticalLayout }) =>
        verticalLayout ? 'column' : 'row'};
    padding: 0 8px;
    gap: 8px;
    justify-content: space-between;
`;

ConfigContainer.displayName = 'ConfigContainer';

const ConfigTitle = styled.div`
    display: flex;
    padding: 4px 0;
    gap: 6px;
    flex-direction: ${({ verticalLayout }) =>
        verticalLayout ? 'row' : 'column'};
    justify-content: ${({ verticalLayout }) =>
        verticalLayout ? 'space-between' : 'flex-start'};
    align-items: ${({ verticalLayout }) =>
        verticalLayout ? 'center' : 'flex-start'}; ;
`;
ConfigTitle.displayName = 'ConfigTitle';

const Hours = styled.div`
    display: flex;
    flex-direction: ${({ horizontalLayout }) =>
        horizontalLayout ? 'row' : 'column'};
    // justify-content: space-between;
    gap: 10px;
    //${({ horizontalLayout }) => (horizontalLayout ? '5px' : '10px')};
`;
Hours.displayName = 'Hours';

const SpecialHoursWrapper = styled.div`
    order: ${({ horizontalLayout }) => (horizontalLayout ? 1 : 0)};
    max-width: 400px;
`;
SpecialHoursWrapper.displayName = 'SpecialHoursWrapper';

const RegularHoursWrapper = styled.div`
    order: ${({ horizontalLayout }) => (horizontalLayout ? 0 : 1)};
    max-width: 500px;
`;
RegularHoursWrapper.displayName = 'RegularHoursWrapper';

export {
    ConfigContainer,
    ConfigTitle,
    Hours,
    SpecialHoursWrapper,
    RegularHoursWrapper,
};
