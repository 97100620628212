import { Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteItem from './DeleteItem';
import { useContext } from 'react';
import { MenuInfoContext } from '../home/MenuInfoContext';
import PrintStationsAtSection from './PrintStations/containers/PrintStationsAtSection';
import AssignedOverridesAtSection from './VisiblityOverride/containers/AssignedOverridesAtSection';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: '5px', // theme.spacing(2), //
    },
    '& .MuiDialogActions-root': {
        padding: '5px', // theme.spacing(1), //
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function EditSectionDialog(props) {
    const { section, open, setOpen, handleEdit, handleDeleteSection } = props;
    //const menuHook = useContext(MenuInfoContext);
    const [isError, setIsError] = useState(false);
    //const [sectionName, setSectionName] = useState('');
    const [tempSection, setTempSection] = useState({});

    const menuHook = useContext(MenuInfoContext);
    const menu = menuHook.value.menu;
    //console.log('Menu: ', menu);
    const handleClose = (e, val = false) => {
        setOpen(val);
    };

    function handleSave() {
        //debugger;
        if (
            menu &&
            menu.filter((x) => x && x.name === tempSection.name).length > 1
        ) {
            setIsError(true);
            return;
        }
        handleEdit(tempSection);
        setIsError(false);
        handleClose();
        //handleEdit(sectionName);
    }

    async function handleDeleteSectionClick() {
        setIsError(false);
        handleClose();
        handleDeleteSection();
    }

    const handleStationsUpdate = (stations) => {
        //debugger;
        setTempSection({ ...tempSection, station: stations });
    };

    const handleVisOverridesUpdate = (visOverrideId) => {
        //debugger;
        setTempSection({ ...tempSection, visiblityOverrideId: visOverrideId });
    };

    useEffect(() => {
        if (open === true) {
            console.log(section);
            setIsError(false);
            //setSectionName(section.name);
            setTempSection({ ...section });
        }
    }, [open]);

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                sx={{
                    '& .MuiDialog-container': {
                        '& .MuiPaper-root': {
                            width: '100%',
                        },
                    },
                }}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    Edit Section
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ m: 1 }}>
                            <TextField
                                fullWidth
                                id="editSectionName"
                                label="Category Name"
                                value={tempSection.name}
                                onChange={(e) => {
                                    setIsError(false);
                                    setTempSection({
                                        ...tempSection,
                                        name: e.target.value,
                                    });
                                }}
                            />
                            {isError && (
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: 'red',
                                        fontWeight: 'normal',
                                        fontSize: '0.85rem',
                                    }}
                                >
                                    A category with same name already exists.
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sx={{ m: 1 }}>
                            <PrintStationsAtSection
                                openSection={tempSection}
                                handleStationsUpdate={handleStationsUpdate}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ m: 1 }}>
                            <AssignedOverridesAtSection
                                openSection={tempSection}
                                handleOverrideUpdate={handleVisOverridesUpdate}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ m: 1 }}>
                            <DeleteItem
                                handleDelete={handleDeleteSectionClick}
                                setClose={handleClose}
                                itemType="Section"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={() => handleClose()}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        autoFocus
                        onClick={() => handleSave()}
                        variant="contained"
                    >
                        Save
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

EditSectionDialog.propTypes = {
    section: PropTypes.shape({}).isRequired,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    handleEdit: PropTypes.func,
    handleDeleteSection: PropTypes.func,
};

EditSectionDialog.defaultProps = {
    open: false,
};
