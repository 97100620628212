import React, { useState, useContext, useEffect } from 'react';
import { useStyles } from '../../../../../home/useStyles';
import cloneDeep from 'lodash/cloneDeep';
import { RestauInfoContext } from '../../../../../home/RestauInfoContext';
import { MenuInfoContext } from '../../../../../home/MenuInfoContext';
import CateringContext from '../../../../CateringContext';
import useOrder from './useOrder';
import { Typography, Grid, Button, TextField, Snackbar } from '@mui/material';
import FoodItemTable from './FoodItemTable';
import PriceTable from './PriceTable';
import PaymentRecord from './PaymentRecord';
import MenuDialog from './MenuDialog';
import FoodDilog from './FoodDialog';
import CustomFoodDialog from './CustomFoodDialog';
import { useTheme } from '@mui/material/styles';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';

const MenuSelection = () => {
    const { restauHook } = useContext(RestauInfoContext);
    const menuHook = useContext(MenuInfoContext);
    const {
        orders,
        dispatchOrder,
        openFood,
        setOpenFood,
        menuLoading,
        setMenuLoading,
        updateMenuSelection,
    } = useOrder();
    const {
        snackbarOpen,
        setSnackbarOpen,
        snackbarMessage,
        setSnackbarMessage,
        snackbarAction,
    } = useContext(CateringContext);
    const [menu, setMenu] = useState(null);
    const [defaultTax, setDefaultTax] = useState('');
    const [openMenuDialog, setOpenMenuDialog] = useState(false);
    const [openFoodDialog, setOpenFoodDialog] = useState(false);
    const [openCustomFoodDialog, setOpenCustomFoodDialog] = useState(false);
    const [keyToEdit, setKeyToEdit] = useState(null);
    const [depositAmount, setDepositAmount] = useState(
        orders ? orders.deposit_amount / 100 : ''
    );
    const [depositError, setDepositError] = useState('');
    const theme = useTheme();
    const classes = useStyles();

    useEffect(() => {
        refreshMenu();
    }, [restauHook.restauId]);

    useEffect(() => {
        if (menuHook.value) {
            if (menuHook.value.menu) setMenu(menuHook.value.menu);
            if (menuHook.value.payments)
                setDefaultTax(menuHook.value.payments.salesTax);
            if (menuHook.value.modifiers) processModifiers();
        }
    }, [menuHook]);

    useEffect(() => {
        setDepositAmount(orders ? orders.deposit_amount / 100 : '');
    }, [orders]);

    const refreshMenu = async () => {
        console.log('Refreshin Menu..');
        setMenuLoading(true);
        try {
            await menuHook.refreshData();
            console.log('MenuHook: ', menuHook);
            if (menuHook.value) {
                if (menuHook.value.menu) setMenu(menuHook.value.menu);
                if (menuHook.value.payments)
                    setDefaultTax(menuHook.value.payments.salesTax);
                if (menuHook.value.modifiers) processModifiers();
            }
        } catch (e) {
            console.log('Error in refreshing menu: ', e);
        } finally {
            setMenuLoading(false);
        }
    };

    const processModifiers = () => {
        let newMenu = cloneDeep(menuHook.value.menu);
        for (const [section_idx, section] of Object.entries(newMenu)) {
            if (!section.foods) continue;
            for (const [food_idx, food] of Object.entries(section.foods)) {
                if (food['modifiers']) {
                    let modifierGroup = {};
                    modifierGroup['modifiers'] = [];
                    for (let mod of food['modifiers']) {
                        let modf = { ...menuHook.value.modifiers[mod] };
                        modifierGroup['modifiers'].push(modf);
                    }
                    food['modifierGroup'] = modifierGroup;
                }
            }
        }
        setMenu(newMenu);
    };

    const addItem = () => {
        setOpenMenuDialog(true);
    };

    const updatePricing = (orderToUpdate, currItems) => {
        let newOrderToUpdate;
        if (Object.keys(currItems).length === 0) {
            newOrderToUpdate = {
                ...orderToUpdate,
                items: currItems,
                payment_subtotal_original: 0,
                payment_subtotal_before_discount: 0,
                payment_subtotal: 0,
                payment_salestax: 0,
                payment_salestax_percent: defaultTax,
                payment_total: 0,
                tip: 0,
            };
        } else {
            let original_subtotal = 0;
            let subtotal = 0;
            let salestax_percent = orderToUpdate
                ? orderToUpdate.payment_salestax_percent
                : defaultTax;
            for (let key in currItems) {
                let item = currItems[key];
                original_subtotal += item.quantity * item.original_price;
                subtotal += item.quantity * item.price;
            }
            let discount_amount = orderToUpdate.discount.amount;
            if (orderToUpdate.discount.discount_type === 'Percent') {
                discount_amount = Math.round(
                    (subtotal * orderToUpdate.discount.percent_off) / 100
                );
            }
            newOrderToUpdate = {
                ...orderToUpdate,
                discount: {
                    ...orderToUpdate.discount,
                    amount: discount_amount,
                },
                items: currItems,
                payment_subtotal_original: original_subtotal,
                payment_subtotal_before_discount: subtotal,
                payment_subtotal: subtotal - discount_amount,
                payment_salestax: Math.round(
                    (salestax_percent / 100) * (subtotal - discount_amount)
                ),
                payment_salestax_percent: salestax_percent,
                payment_total: Math.round(
                    (subtotal - discount_amount) * (1 + salestax_percent / 100)
                ),
                tip: 0,
            };
        }
        return newOrderToUpdate;
    };

    const updateInstructions = (value) => {
        dispatchOrder({ type: 'UPDATE_INSTRUCTIONS', value });
    };

    const savePricingAndInstructions = async () => {
        await updateMenuSelection(orders);
    };

    return (
        <>
            {menuLoading ||
            !menuHook ||
            !menuHook.value ||
            !menuHook.value.payments ? (
                <img
                    className={classes.loadingImage}
                    src="/img/spinner.gif"
                    alt="loading"
                />
            ) : (
                <>
                    <Grid item xs={12}>
                        <Typography color="primary" variant="h6">
                            Menu Selection
                        </Typography>
                    </Grid>

                    {orders &&
                        orders.items &&
                        Object.keys(orders.items).length !== 0 && (
                            <FoodItemTable
                                items={orders.items}
                                setOpenFood={setOpenFood}
                                setOpenFoodDialog={setOpenFoodDialog}
                                setOpenCustomFoodDialog={
                                    setOpenCustomFoodDialog
                                }
                                setKeyToEdit={setKeyToEdit}
                                orders={orders}
                                dispatchOrder={dispatchOrder}
                                updateMenuSelection={updateMenuSelection}
                                updatePricing={updatePricing}
                            />
                        )}

                    <Grid item xs={12}>
                        <Button
                            component="label"
                            xs={3}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: 1,
                                height: '100%',
                                [theme.breakpoints.down('xs')]: {
                                    maxWidth: '20%',
                                },
                                [theme.breakpoints.up('md')]: {
                                    maxWidth: '10%',
                                },
                            }}
                            onClick={addItem}
                        >
                            <AddCircleSharpIcon
                                color="primary"
                                sx={{ fontSize: 20 }}
                            />
                            <Typography
                                align="center"
                                variant="body2"
                                sx={{ m: 'auto' }}
                            >
                                Add Item
                            </Typography>
                        </Button>
                    </Grid>

                    {orders &&
                        orders.items &&
                        Object.keys(orders.items).length !== 0 && (
                            <PriceTable
                                orders={orders}
                                dispatchOrder={dispatchOrder}
                                defaultTax={defaultTax}
                            />
                        )}

                    {orders && orders.items && (
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                <TextField
                                    label="Order Instructions"
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    sx={{ width: '90%' }}
                                    multiline
                                    minRows={3}
                                    value={orders.orderInstruction}
                                    onChange={(e) =>
                                        updateInstructions({
                                            orderInstruction: e.target.value,
                                        })
                                    }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                <TextField
                                    label="Internal Order Instructions"
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    sx={{ width: '90%' }}
                                    multiline
                                    minRows={3}
                                    value={orders.orderInstructionInternal}
                                    onChange={(e) =>
                                        updateInstructions({
                                            orderInstructionInternal:
                                                e.target.value,
                                        })
                                    }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}

                    {orders &&
                        orders.items &&
                        Object.keys(orders.items).length !== 0 && (
                            <PaymentRecord
                                orders={orders}
                                dispatchOrder={dispatchOrder}
                                updateMenuSelection={updateMenuSelection}
                                depositAmount={depositAmount}
                                setDepositAmount={setDepositAmount}
                                depositError={depositError}
                            />
                        )}

                    {orders &&
                        orders.items &&
                        Object.keys(orders.items).length !== 0 && (
                            <Button
                                size="small"
                                variant="contained"
                                onClick={savePricingAndInstructions}
                                sx={{ m: 1 }}
                            >
                                Save
                            </Button>
                        )}

                    {openMenuDialog && menuHook && (
                        <MenuDialog
                            open={openMenuDialog}
                            setOpenMenuDialog={setOpenMenuDialog}
                            setOpenFood={setOpenFood}
                            menu={menu}
                            setOpenFoodDialog={setOpenFoodDialog}
                            setOpenCustomFoodDialog={setOpenCustomFoodDialog}
                        />
                    )}

                    {openFood && (
                        <FoodDilog
                            open={openFoodDialog}
                            setOpenFoodDialog={setOpenFoodDialog}
                            orders={orders}
                            dispatchOrder={dispatchOrder}
                            openFood={openFood}
                            setOpenFood={setOpenFood}
                            menuLoading={menuLoading}
                            updateMenuSelection={updateMenuSelection}
                            defaultTax={defaultTax}
                            updatePricing={updatePricing}
                            keyToEdit={keyToEdit}
                            setKeyToEdit={setKeyToEdit}
                        />
                    )}

                    {openCustomFoodDialog && (
                        <CustomFoodDialog
                            open={openCustomFoodDialog}
                            setOpenCustomFoodDialog={setOpenCustomFoodDialog}
                            orders={orders}
                            dispatchOrder={dispatchOrder}
                            openFood={openFood}
                            setOpenFood={setOpenFood}
                            updateMenuSelection={updateMenuSelection}
                            defaultTax={defaultTax}
                            updatePricing={updatePricing}
                            keyToEdit={keyToEdit}
                            setKeyToEdit={setKeyToEdit}
                        />
                    )}

                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        message={snackbarMessage}
                        onClose={(event, reason) => {
                            if (reason === 'clickaway') return;
                            setSnackbarOpen(false);
                        }}
                        action={snackbarAction}
                    />
                </>
            )}
        </>
    );
};

export default MenuSelection;
