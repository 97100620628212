import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import SayPlayMessageDetails from '../../SayPlayMessage/SayPlayMessageDetails';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import { RestauInfoContext } from '../../../../../../home/RestauInfoContext';
const moment = require('moment-timezone');

function GreetingMessageDetails(props) {
    const {
        selectedGreetMsgDetails,
        //setSelectedGreetMsgDetails,
        handleSaveGreetMessage,
        handleDeleteGreetMessage,
    } = props;
    const { restauHook } = useContext(RestauInfoContext);
    const [selectedMsgDetails, setSelectedMsgDetails] = useState(null);
    const [tempGreetMsgDetails, setTempGreetMsgDetails] = useState(null);
    const [tempMsgDetails, setTempMsgDetails] = useState(null);
    const [errMsg, setErrMsg] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isDeleteProcessing, setIsDeleteProcessing] = useState(false);
    // const [origGreetMsgDetails, setOrigGreetMsgDetails] = useState(null);
    // const [origMsgDetails, setOrigMsgDetails] = useState(null);

    const resetSelectedGreetMessage = () => {
        //debugger;
        setTempGreetMsgDetails({ ...selectedGreetMsgDetails });
        setTempMsgDetails([...selectedGreetMsgDetails.msgDetails]);
        setErrMsg('');
    };

    const handleGreetMsgNameChange = (newVal) => {
        //debugger;
        setTempGreetMsgDetails({
            ...tempGreetMsgDetails,
            displayName: newVal,
        });
        setErrMsg('');
    };

    const handleGreetMsgDateChange = (date) => {
        //debugger;
        // const localDate = new moment(date)
        setTempGreetMsgDetails({
            ...tempGreetMsgDetails,
            // from_date:
            //     new moment(date)
            //         .tz(restauHook.restauTimeZone)
            //         .format('YYYY-MM-DD') + 'T00:00:01',
            // to_date:
            //     new moment(date)
            //         .add(1, 'days')
            //         .tz(restauHook.restauTimeZone)
            //         .format('YYYY-MM-DD') + 'T00:00:01',
            from_date: moment(date)
                .tz(restauHook.restauTimeZone)
                .startOf('day')
                .add(1, 'second')
                .format('YYYY-MM-DDTHH:mm:ssZ'),
            to_date: moment(date)
                .add(1, 'days')
                .tz(restauHook.restauTimeZone)
                .startOf('day')
                .add(1, 'second')
                .format('YYYY-MM-DDTHH:mm:ssZ'),
        });
        setErrMsg('');
    };

    const saveGreetMsgDetails = async () => {
        //debugger;
        setErrMsg('');
        if (
            !tempGreetMsgDetails.displayName ||
            tempGreetMsgDetails.displayName === ''
        ) {
            setErrMsg('Name cannot be empty.');
            return;
        }

        setIsProcessing(true);
        let dataToSave = {
            ...tempGreetMsgDetails,
            from_date: moment(tempGreetMsgDetails.from_date)
                .tz(restauHook.restauTimeZone)
                .startOf('day')
                .add(1, 'second')
                .format('YYYY-MM-DDTHH:mm:ssZ'),
            to_date: moment(tempGreetMsgDetails.to_date)
                .tz(restauHook.restauTimeZone)
                .startOf('day')
                .add(1, 'second')
                .format('YYYY-MM-DDTHH:mm:ssZ'),
            msgDetails: [...tempMsgDetails],
        };

        setTempGreetMsgDetails({
            ...dataToSave,
        });

        try {
            await handleSaveGreetMessage(dataToSave);
            setErrMsg('');
        } catch (ex) {
            setErrMsg(ex.toString());
        } finally {
            setIsProcessing(false);
        }
    };

    const deleteGreetMsgDetails = async () => {
        setErrMsg('');
        //debugger;
        let dataToDelete = {
            ...tempGreetMsgDetails,
            from_date: moment(tempGreetMsgDetails.from_date)
                .tz(restauHook.restauTimeZone)
                .startOf('day')
                .add(1, 'second')
                .format('YYYY-MM-DDTHH:mm:ssZ'),
            to_date: moment(tempGreetMsgDetails.to_date)
                .tz(restauHook.restauTimeZone)
                .startOf('day')
                .add(1, 'second')
                .format('YYYY-MM-DDTHH:mm:ssZ'),
            msgDetails: [...tempMsgDetails],
        };
        setIsDeleteProcessing(true);
        try {
            await handleDeleteGreetMessage(dataToDelete);
            setErrMsg('');
        } catch (ex) {
            setErrMsg(ex.toString());
        } finally {
            setIsDeleteProcessing(false);
        }
    };

    useEffect(() => {
        //debugger;
        //setSelectedMsgDetails([...selectedGreetMsgDetails.msgDetails]);
        //setOrigGreetMsgDetails({ ...selectedGreetMsgDetails });
        //setOrigMsgDetails([...selectedGreetMsgDetails.msgDetails]);
        setSelectedMsgDetails(
            JSON.parse(JSON.stringify(selectedGreetMsgDetails.msgDetails))
        );
        setTempGreetMsgDetails(
            JSON.parse(JSON.stringify(selectedGreetMsgDetails))
        );
        setTempMsgDetails(
            JSON.parse(JSON.stringify(selectedGreetMsgDetails.msgDetails))
        );
    }, [selectedGreetMsgDetails]);

    return (
        <Grid
            container
            item
            xs={9}
            rowSpacing={1}
            sx={{ mt: 0.5 }}
            //alignItems={'center'}
        >
            <Grid item xs={12} md={3}>
                <TextField
                    id="messageName"
                    label="Name"
                    value={
                        tempGreetMsgDetails && tempGreetMsgDetails.displayName
                    }
                    size="small"
                    onChange={(e) => handleGreetMsgNameChange(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <DatePicker
                    size="small"
                    label="For Date" // (per Restaurant TimeZone)"
                    value={tempGreetMsgDetails && tempGreetMsgDetails.from_date}
                    autoOk
                    format="MM/dd/yyyy"
                    inputVariant="outlined"
                    margin="dense"
                    onChange={(date) => handleGreetMsgDateChange(date)}
                    // minDate={moment(getDateWithOffset(-365))}
                    //maxDate={moment().add(1, 'days')}
                    // selected={moment(searchStartDate)}
                    renderInput={(params) => (
                        <TextField {...params} size="small" />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography color={'red'}>
                    **date as per Restaurant Timezone
                </Typography>
            </Grid>

            <Grid
                container
                item
                xs={12}
                rowSpacing={2}
                style={{ marginTop: '10px' }}
            >
                {tempMsgDetails && (
                    <SayPlayMessageDetails
                        msgDetails={tempMsgDetails}
                        setMsgDetails={setTempMsgDetails}
                        originalMsgDetails={selectedMsgDetails}
                        showNewAudioFilePopup={false}
                    />
                )}
                <Grid
                    container
                    item
                    xs={12}
                    textAlign="center"
                    columnSpacing={1}
                    sx={{ justifyContent: 'flex-end' }}
                >
                    {errMsg && errMsg !== '' && (
                        <Grid item>
                            <Typography color="red">{errMsg} </Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <Button
                            variant="outlined"
                            //fullWidth
                            size="small"
                            onClick={resetSelectedGreetMessage}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            //fullWidth
                            size="small"
                            onClick={deleteGreetMsgDetails}
                            disabled={isDeleteProcessing}
                        >
                            {isDeleteProcessing && (
                                <CircularProgress size={24} />
                            )}
                            Delete
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            //fullWidth
                            size="small"
                            onClick={saveGreetMsgDetails}
                            disabled={isProcessing}
                        >
                            {isProcessing && <CircularProgress size={24} />}
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

GreetingMessageDetails.propTypes = {
    selectedGreetMsgDetails: PropTypes.shape({
        displayName: PropTypes.string,
        date: PropTypes.string,
        msgDetails: PropTypes.arrayOf({}),
    }),
    setSelectedGreetMsgDetails: PropTypes.func,
    handleSaveGreetMessage: PropTypes.func,
    handleDeleteGreetMessage: PropTypes.func,
};

export default GreetingMessageDetails;
