import React from 'react';
import SQRadioGroup from '../../components/formControls/SQRadioGroup';

import { useContext } from 'react';
import QRContext from '../QRContext';

function QRServiceTypes({ label, serviceTypes }) {
    const { serviceType, setServiceType } = useContext(QRContext);

    const handleChange = (e, val) => {
        setServiceType(val);
    };

    return (
        <SQRadioGroup
            label={label}
            options={Object.values(serviceTypes)}
            value={serviceType}
            handleChange={handleChange}
        />
    );
}

QRServiceTypes.propTypes = {};

export default QRServiceTypes;
