import { Typography } from '@mui/material';
import List from '@mui/material/List';
import React from 'react';
import SpecialHourItem from './SpecialHourItem';

function SpecialHoursList(props) {
    const { specialHours, onDelete } = props;

    return (
        <div style={{ marginTop: 10 }}>
            <Typography variant="h6" margin="dense">
                Configured Slots
            </Typography>
            <List dense>
                {specialHours &&
                    Object.keys(specialHours).map((key) => {
                        const day = specialHours[key];
                        const { holiday, hours } = day;
                        return (
                            <SpecialHourItem
                                key={key}
                                day={key}
                                holiday={holiday}
                                hours={hours}
                                onDelete={onDelete}
                            />
                        );
                    })}
            </List>
        </div>
    );
}

SpecialHoursList.propTypes = {};

export default SpecialHoursList;
