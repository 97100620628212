import { Typography } from '@mui/material';
import React from 'react';
import { useContext } from 'react';
import PhoneLogContext from '../PhoneLogContext';
import SearchResultFilters from './SearchResultFilters';
import { useStyles } from '../../../../home/useStyles';
import { DataGrid } from '@mui/x-data-grid';

function SearchResults() {
    const { filteredCallLogs, callStatusItems, loading, errMsg } =
        useContext(PhoneLogContext);
    const [pageSize, setPageSize] = React.useState(5);
    const classes = useStyles();

    const columns = [
        { field: 'id', headerName: 'Id', width: 100, align: 'left' },
        {
            field: 'phNumber',
            headerName: 'Phone Number',
            width: 200,
            align: 'left',
        },
        {
            field: 'callStatus',
            headerName: 'Call Status',
            width: 400,
            align: 'left',
        },
        {
            field: 'dateTime',
            headerName: 'Date Time',
            width: 400,
            align: 'left',
        },
    ];

    const rows = Object.values(filteredCallLogs).map((x, index) => ({
        ...x,
        callStatus: callStatusItems[x.callStatus],
        id: index + 1,
    }));

    console.log('Row data: ', rows);

    return (
        <div>
            {loading ? (
                <img
                    className={classes.loadingImage}
                    src="../../../img/spinner.gif"
                    alt="loading"
                />
            ) : (
                <div>
                    <SearchResultFilters />
                    {errMsg !== '' ? (
                        <Typography color={'red'}>{errMsg}</Typography>
                    ) : (
                        Object.keys(filteredCallLogs).length > 0 && (
                            <>
                                <Typography>
                                    Count:{' '}
                                    {Object.keys(filteredCallLogs).length}
                                </Typography>

                                <div style={{ height: 430, width: '100%' }}>
                                    <DataGrid
                                        rows={rows}
                                        columns={columns}
                                        pageSize={pageSize}
                                        onPageSizeChange={(newPageSize) =>
                                            setPageSize(newPageSize)
                                        }
                                        rowsPerPageOptions={[5, 10, 20]}
                                    />
                                </div>

                                {/*  <TableContainer sx={{ maxHeight: 430 }}>
                                    <Table stickyHeader size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Phone Number
                                                </TableCell>
                                                <TableCell align="center">
                                                    Status
                                                </TableCell>
                                                <TableCell align="center">
                                                    Date & Time
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(filteredCallLogs).map(
                                                (key) => (
                                                    <TableRow key={key}>
                                                        <TableCell>
                                                            {
                                                                filteredCallLogs[
                                                                    key
                                                                ].phNumber
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Chip
                                                                label={
                                                                    callStatusItems[
                                                                        filteredCallLogs[
                                                                            key
                                                                        ].status
                                                                    ]
                                                                }
                                                                color="success"
                                                                variant="outlined"
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {
                                                                filteredCallLogs[
                                                                    key
                                                                ].dateTime
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer> */}
                            </>
                        )
                    )}
                </div>
            )}
        </div>
    );
}

SearchResults.propTypes = {};

export default SearchResults;
