import React from 'react';
import {
    Card,
    CardActionArea,
    CardContent,
    Grid,
    Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

function GreetingMessageHeader(props) {
    const { grtMsgDetails, showSpecialMsgDetails, pastData } = props;
    return (
        grtMsgDetails && (
            <Grid item xs={12} textAlign="center">
                <Card
                    onClick={() => showSpecialMsgDetails(grtMsgDetails.id)}
                    raised
                    sx={{
                        display: 'flex',
                        width: '100%',
                        backgroundColor: pastData ? 'lightgreen' : 'lightblue',
                        ':hover': {
                            boxShadow: 20, // theme.shadows[20]
                        },
                    }}
                >
                    <CardActionArea>
                        <CardContent
                            sx={{
                                p: 0,
                                '&:last-child': { pb: 0 },
                            }}
                        >
                            <Typography sx={{ fontSize: 14 }} variant="h6">
                                {grtMsgDetails.displayName +
                                    ' : ' +
                                    grtMsgDetails.msgDetails[0].type}
                            </Typography>

                            <Typography color="text.secondary">
                                {grtMsgDetails.from_date &&
                                    grtMsgDetails.from_date.split('T')[0]}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        )
    );
}

GreetingMessageHeader.propTypes = {
    grtMsgDetails: PropTypes.shape({
        id: PropTypes.string,
        displayName: PropTypes.string,
        from_date: PropTypes.string,
        msgDetails: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string,
            })
        ),
    }),
    showSpecialMsgDetails: PropTypes.func,
    pastData: PropTypes.bool,
};

export default GreetingMessageHeader;
