import { Autocomplete, TextField } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { MenuInfoContext } from '../../home/MenuInfoContext';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: '5px', // theme.spacing(2), //
    },
    '& .MuiDialogActions-root': {
        padding: '5px', // theme.spacing(1), //
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function AddPopularItem(props) {
    let foodItemsListForAdd = [];
    let tempAddedFoodItems = [];
    const menuHook = useContext(MenuInfoContext);

    const handleClose = () => {
        props.setOpen(false);
    };

    function loadData() {
        //debugger;
        let menu = menuHook.value.menu;
        menu.forEach((section) => {
            section.foods &&
                section.foods.forEach((food) => {
                    //if it is not a popular item already
                    if (props.popularItemData.some((x) => x.id === food.id)) {
                        //to do
                    } else {
                        foodItemsListForAdd.push(food);
                    }
                });
        });
        /*  console.log(
            'FoodItems List for add functionality: ',
            foodItemsListForAdd
        ); */
    }

    async function handleSave() {
        handleClose();
        props.handleSave([...tempAddedFoodItems]);
    }

    function handleItemAddition(event, value) {
        //console.log('Added Item: ', value);
        tempAddedFoodItems = [...value];
        //console.log('Added Items List: ', tempAddedFoodItems);
    }

    useEffect(() => {
        //console.log('Loading data..');
        if (props.open === true) {
            loadData();
        }
    }, [props.open]);

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}
            fullWidth={true}
            maxWidth={'xs'}
        >
            <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
            >
                Add to "Popular Items"
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <br />
                <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={foodItemsListForAdd}
                    getOptionLabel={(food) => food.name}
                    //defaultValue={[top100Films[13]]}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            //label="filterSelectedOptions"
                            placeholder="FoodItems"
                        />
                    )}
                    onChange={handleItemAddition}
                />
                <br />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined">
                    Cancel
                </Button>
                <Button autoFocus onClick={handleSave} variant="contained">
                    Add Items
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}
