import React from 'react';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';

export default function AuditTableHead(props) {
    const { headData } = props;
    return (
        <TableHead component="th" scope="row">
            <TableRow>
                {headData.map((data, key) => (
                    <TableCell key={key} style={{ width: 200, minwidth: 150 }}>
                        <Typography
                            variant="h6"
                            align="left"
                            color="inherit"
                            noWrap
                        >
                            {data}
                        </Typography>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
