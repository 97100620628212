import React from 'react';
import PosterPanel from '../components/PosterPanel';

function QRPoster(props) {
    return <PosterPanel />;
}

QRPoster.propTypes = {};

export default QRPoster;
