import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import Utils from '../../../../../utils/Utils';
import { customPhDestWidgets } from '../../PhoneSettingsConstants';

function CallDefMenuOptionNewDest(props) {
    const { newDestData, setNewDestData } = props;

    const handleWidgetListChange = (e) => {
        //debugger;
        let temp = [];
        if (e.target.checked) {
            temp = [
                ...newDestData.widgets.slice(0, e.target.id),
                e.target.name,
                ...newDestData.widgets.slice(e.target.id),
            ];
        } else {
            temp = newDestData.widgets.filter((x) => x !== e.target.name);
        }

        setNewDestData({
            ...newDestData,
            widgets: [...temp],
        });
    };

    const handleDestNameChange = (val) => {
        //console.log(Utils.convertToCamelCase(val));
        setNewDestData({
            ...newDestData,
            label: val,
            name: Utils.convertToCamelCase(val),
        });
    };

    useEffect(() => {
        // let widgetData = {};
        // Object.keys(customPhDestWidgets).forEach((wdName) => {
        //     widgetData[customPhDestWidgets[wdName].name] = true;
        // });
        // setNewDestData({
        //     name: '',
        //     value: '',
        //     widgets: { ...widgetData },
        // });

        setNewDestData({
            name: '',
            label: '',
            widgets: [...Object.keys(customPhDestWidgets)],
        });
    }, []);

    return (
        <Grid container rowSpacing={2}>
            <Grid row xs={6} mt={2}>
                <TextField
                    id="destinationName"
                    label="Destination Name"
                    variant="outlined"
                    size="small"
                    //value={key}
                    sx={{
                        width: 'auto',
                        textAlign: 'center',
                    }}
                    onChange={(e) => {
                        handleDestNameChange(e.target.value);
                    }}
                />
            </Grid>
            <Grid row xs={12}>
                <FormGroup>
                    {Object.keys(customPhDestWidgets).map((wdName, index) => (
                        <FormControlLabel
                            //id={wd.name}
                            key={customPhDestWidgets[wdName].name}
                            control={
                                <Checkbox
                                    id={index}
                                    inputProps={{ index: { index } }}
                                    name={customPhDestWidgets[wdName].name}
                                    defaultChecked
                                    onChange={(e) => handleWidgetListChange(e)}
                                />
                            }
                            label={customPhDestWidgets[wdName].label}
                        />
                    ))}
                </FormGroup>
            </Grid>
        </Grid>
    );
}

CallDefMenuOptionNewDest.propTypes = {
    newDestData: PropTypes.shape({
        name: PropTypes.string,
        widgets: PropTypes.arrayOf(PropTypes.string),
    }),
    setNewDestData: PropTypes.func,
};

export default CallDefMenuOptionNewDest;
