var XLSX = require('xlsx');

const download = {
    excelDownload: (rows, fileName, sheetName = 'rows') => {
        const workSheet = XLSX.utils.json_to_sheet(rows);
        const workBook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
        XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
        XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

        XLSX.writeFile(workBook, fileName);
    },

    readExcelData: async (file, callBackFunction) => {
        //read the file as buffer
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
            const bufferArray = e?.target.result;
            const wb = XLSX.read(bufferArray, { type: 'buffer' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            const data = XLSX.utils.sheet_to_json(ws);
            //call the callback function with the data for further processing.
            callBackFunction(data);
        };
    },
};

export default download;
