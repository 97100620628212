import React, { useContext, useEffect } from 'react';
import { RestauInfoContext } from '../../../../home/RestauInfoContext';
import CateringContext from '../../../CateringContext';
import FormInfo from './FormInfo';
import EditFormFields from './EditFormFields';
import FaqAndSubmission from './FaqAndSubmission';
import { useStyles } from '../../../../home/useStyles';

const CateringSettingContent = () => {
    const { restauHook } = useContext(RestauInfoContext);
    useEffect(() => {}, [restauHook.restauId]);
    const { configLoading, setConfigLoading } = useContext(CateringContext);
    const classes = useStyles();

    return (
        <div>
            {configLoading ? (
                <img
                    className={classes.loadingImage}
                    src="/img/spinner.gif"
                    alt="loading"
                />
            ) : (
                <>
                    <FormInfo />
                    <br />
                    <br />
                    <EditFormFields />
                    <br />
                    <br />
                    <FaqAndSubmission />
                </>
            )}
        </div>
    );
};

export default CateringSettingContent;
