import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@mui/material';
import { useEffect } from 'react';

//The component to show the list of all the overrides, so that user can choose one
function AddOrUpdateAssignedOverride(props) {
    const { allOverrideMapping, assignedOverride, setAssignedOverride } = props;

    //console.log('All Overrides: ', allOverrideMapping);

    const handleVisOverrideMappingChange = (e) => {
        //debugger;
        setAssignedOverride(
            Object.values(allOverrideMapping).find(
                (x) => x.name === e.target.value
            )
        );
    };

    useEffect(() => {
        console.log('All Override Mapping: ', allOverrideMapping);
        allOverrideMapping &&
            setAssignedOverride(
                Object.values(allOverrideMapping).filter((x) => x.isMapped)[0]
            );
        console.log('Assigned Override: ', assignedOverride.name);
    }, []);

    return (
        <FormControl
            sx={{ m: 2, ml: 4 }}
            component="fieldset"
            variant="standard"
        >
            <RadioGroup
                aria-labelledby="overrides-radio-buttons-group"
                name="overrides-radio-buttons-group"
                value={assignedOverride && assignedOverride.name}
                onChange={handleVisOverrideMappingChange}
            >
                {allOverrideMapping &&
                    Object.keys(allOverrideMapping).map((overrideId) => {
                        return (
                            <FormControlLabel
                                key={overrideId}
                                value={allOverrideMapping[overrideId].name}
                                control={<Radio />}
                                label={allOverrideMapping[overrideId].name}
                            ></FormControlLabel>
                        );
                    })}
            </RadioGroup>
            <Button
                variant="outlined"
                size="small"
                //sx={{ width: '40%' }}
                onClick={() => setAssignedOverride({})}
            >
                Clear Selected
            </Button>
        </FormControl>
    );
}

AddOrUpdateAssignedOverride.propTypes = {
    allOverrideMapping: PropTypes.shape({}),
    assignedOverride: PropTypes.shape({ name: PropTypes.string }),
    setAssignedOverride: PropTypes.func,
};

AddOrUpdateAssignedOverride.defaultProps = {
    openAssignOverridesDialog: false,
};
export default AddOrUpdateAssignedOverride;
