import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import MicRecorder from 'mic-recorder-to-mp3';
import { getUUID } from '../../../../Menu/Utils';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

function AudioRecorder(props) {
    const { newAudioFile, setNewAudioFile } = props;
    const [isRecording, setIsRecording] = useState(false);
    const [audioURL, setAudioURL] = useState(null);

    const startRecording = () => {
        Mp3Recorder.start()
            .then(() => {
                setIsRecording(true);
            })
            .catch((e) => console.error(e));
    };

    const stopRecording = () => {
        Mp3Recorder.stop()
            .getMp3()
            .then(([buffer, blob]) => {
                let guid = getUUID();
                const fl = new File(buffer, `${guid}.mp3`, {
                    type: blob.type,
                    lastModified: Date.now(),
                });
                setNewAudioFile(fl);

                const blobURL = URL.createObjectURL(blob);
                setAudioURL(blobURL);
                setIsRecording(false);

                // // Save the MP3 file to test if its a valid file
                // debugger;
                // const mp3BlobURL = URL.createObjectURL(blob);
                // const a = document.createElement('a');
                // a.style.display = 'none';
                // a.href = mp3BlobURL;
                // a.download = 'recording.mp3';
                // document.body.appendChild(a);
                // a.click();
                // document.body.removeChild(a);
            })
            .catch((e) => console.log(e));
    };

    return (
        <>
            <Stack
                direction="column"
                spacing={2}
                alignItems="center"
                //sx={{ border: '1px solid' }}
            >
                <Typography sx={{ margin: 'auto' }}>
                    Click below to start recording a message.
                </Typography>
                <IconButton
                    //onClick={checkDelete}
                    onClick={startRecording}
                    disabled={isRecording}
                    color="primary"
                    sx={{ p: 0, m: 0 }}
                >
                    <MicIcon sx={{ fontSize: 65 }} />
                </IconButton>
                <Button
                    variant="contained"
                    size="small"
                    onClick={stopRecording}
                    disabled={!isRecording}
                    //color="success"
                    sx={{
                        borderRadius: '50px', // You can adjust the value for more or less rounded corners
                        padding: '5px 20px', // Optional: Adjust padding to your liking
                        textTransform: 'none', // Optional: Prevent uppercase text transformation
                    }}
                >
                    Stop Recording
                </Button>
                {audioURL && (
                    <div>
                        {audioURL && (
                            <audio controls>
                                <source src={audioURL} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                        )}
                    </div>
                )}
            </Stack>
        </>
    );
}

AudioRecorder.propTypes = {
    newAudioFile: PropTypes.object,
    setNewAudioFile: PropTypes.func,
};

export default AudioRecorder;
