import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function ResLocations(props) {
    const { currentLocation, locations, onResLocationChange } = props;
    return locations ? (
        <Select
            id="restauId-select"
            value={currentLocation}
            size="small"
            margin="dense"
            variant="standard"
            onChange={onResLocationChange}
            inputProps={{
                'aria-label': 'Restaurant Location',
            }}
            sx={{ fontSize: '.875rem' }}
        >
            {locations?.map((location) => (
                <MenuItem dense key={location.id} value={location.id}>
                    {location.name}
                </MenuItem>
            ))}
        </Select>
    ) : null;
}

ResLocations.propTypes = {
    currentLocation: PropTypes.string.isRequired,
    locations: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    onResLocationChange: PropTypes.func.isRequired,
};

export default ResLocations;
