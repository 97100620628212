import React, { useEffect, useContext, useState } from 'react';
import { RestauInfoContext } from '../home/RestauInfoContext';
import Config from '../config/Config';
import axios from 'axios';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const moment = require('moment');

const useCatering = () => {
    const { restauHook } = useContext(RestauInfoContext);
    const [displayTitle, setDisplayTitle] = useState('');
    const [loadingSummaryData, setLoadingSummaryData] = useState(false);

    const eventType = [
        {
            value: 'Pick-up Catering',
            color: '#FCAEAE',
        },
        {
            value: 'Drop-off Catering',
            color: '#D8C4B6',
        },
        {
            value: 'Full-service Catering',
            color: '#E1ECC8',
        },
        {
            value: 'On-premise Catering',
            color: '#F5EFE7',
        },
    ];

    const eventStatus = [
        {
            key: 'NEW_EVENT',
            value: 'New Event',
            color: '#CCEEBC',
        },
        {
            key: 'CONFIRMED',
            value: 'Confirmed',
            color: '#D7BBF5',
        },
        {
            key: 'VOID',
            value: 'Void',
            color: '#FAE392',
        },
        {
            key: 'FOLLOW_UP',
            value: 'FollowUp',
            color: '#FFD0D0',
        },
        {
            key: 'LOST',
            value: 'Lost',
            color: '#EEEEEE',
        },
        {
            key: 'REJECTED',
            value: 'Rejected',
            color: '#D7C0AE',
        },
    ];

    // not being used now
    const typeColor = (typeInput) => {
        for (let type of eventType) {
            if (typeInput === type.value) return type.color;
        }
    };

    const statusColor = (statusInput) => {
        for (let status of eventStatus) {
            if (statusInput === status.value) return status.color;
        }
    };

    // Retrieve form configuration
    const [formConfig, setFormConfig] = useState({});
    const [configLoading, setConfigLoading] = useState(true);
    const [formFields, setFormFields] = useState({});
    const [form, setForm] = useState({});

    const extractFieldNames = (formConfig) => {
        let initialForm = {};
        formConfig.fields.forEach((field) => {
            initialForm[field.field_name] = '';
        });
        return initialForm;
    };

    const retrieveFormConfig = async () => {
        if (!restauHook.restauId) return;
        setConfigLoading(true);
        let reqBody = { restauId: restauHook.restauId };
        try {
            const token = await window.customerApp
                .auth()
                .currentUser.getIdToken();
            const response = await axios.post(
                Config.retrieveFormConfig,
                reqBody,
                {
                    headers: {
                        token: token,
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (!response.data || response.data.status === false) {
                throw new Error(
                    'Error from the server: ' + response.data
                        ? response.data.msg
                        : null
                );
            }

            setFormConfig(response.data.formConfig);
            setForm(extractFieldNames(response.data.formConfig));
            setFormFields(extractFieldNames(response.data.formConfig));
        } catch (error) {
            console.log('Caught error in retrieveFormConfig: ', error);
            setSnackbarMessage(
                'Issue retrieving form configurations. Please contact support if the issue persists.'
            );
            setSnackbarOpen(true);
        } finally {
            setConfigLoading(false);
        }
    };
    useEffect(() => {
        //debugger;
        retrieveFormConfig();
    }, [restauHook.restauId]);

    // to make below configurable later
    const lostReasons = ['Price', 'Menu', 'Non-ideal customer', 'Other'];

    // to make below configurable later
    const rejectReasons = [
        'Duplicate',
        'To-go order',
        'Issue with existing order',
        'Other',
    ];

    // -------------- related with dashboard --------------
    const retrieveFilteredEvents = async (filters) => {
        if (!restauHook.restauId) return;
        try {
            const token = await window.customerApp
                .auth()
                .currentUser.getIdToken();
            let config = {
                headers: {
                    token: token,
                    'Content-Type': 'application/json',
                },
            };
            let reqBody = {
                restauId: restauHook.restauId,
                filters: filters,
            };
            const response = await axios.post(
                Config.getFilteredEvents,
                reqBody,
                config
            );
            if (!response.data || response.data.status === false) {
                throw new Error(
                    'Error from the server: ' + response.data
                        ? response.data.msg
                        : null
                );
            }
            return response;
        } catch (error) {
            console.log('Caught error in retrieveFilteredEvents', error);
            setSnackbarMessage(
                'Issue retrieving events. Please contact support if the issue persists.'
            );
            setSnackbarOpen(true);
        }
    };

    // -------------- Snackbar related --------------
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const snackbarAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={(event, reason) => {
                    if (reason === 'clickaway') return;
                    setSnackbarOpen(false);
                }}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    // -------------- functions related with marking events as priorities on dashboard --------------
    // (new events to reach out to and follow-up events with due date coming)
    const alertTooltip = (status, gap) => {
        if (status === 'New Event') {
            return `New events submitted more than ${gap} days ago.`;
        } else if (status === 'FollowUp') {
            return `Follow-ups due in ${gap} days.`;
        }
    };
    const showPriority = (status, date, gap) => {
        let convertedDate;
        // Check if timestamp has _seconds
        if (date && date._seconds) {
            convertedDate = new Date(date._seconds * 1000); // Convert to milliseconds
        }
        // Handle other cases
        else if (date) {
            convertedDate = date;
        } else return false;
        if (status === 'New Event') {
            let benchmark = moment().subtract(gap, 'days');
            if (moment(convertedDate).isBefore(benchmark)) return true;
            else return false;
        } else if (status === 'FollowUp') {
            let benchmark = moment().add(gap, 'days');
            if (moment(convertedDate).isBefore(benchmark)) return true;
            else return false;
        }
    };

    // -------------- related with individual event page --------------
    const [loading, setLoading] = useState(false);
    const [newEventPage, setNewEventPage] = useState(false);
    const [eventOrder, setEventOrder] = useState(null);
    const [conversation, setConversation] = useState(null);

    const processConversationData = async (restauId, inquiryId) => {
        try {
            if (!restauId || !inquiryId) return;
            const token = await window.customerApp
                .auth()
                .currentUser.getIdToken();
            let config = {
                headers: {
                    token: token,
                    'Content-Type': 'application/json',
                },
            };
            let reqBody = {
                restauId: restauId,
                inquiryId: inquiryId,
            };
            const response = await axios.post(
                Config.processCateringConversation,
                reqBody,
                config
            );
            if (!response.data || response.data.status === false) {
                throw new Error(
                    'Error from the server: ' + response.data
                        ? response.data.msg
                        : null
                );
            }
            return response;
        } catch (error) {
            console.log('Caught error in retrieveEventsByInquiryId');
            setSnackbarMessage(
                'Issue retrieving event. Please contact support if the issue persists.'
            );
            setSnackbarOpen(true);
        }
    };

    const retrieveEventsByInquiryId = async (restauId, inquiryId) => {
        try {
            if (!restauId || !inquiryId) return;
            const token = await window.customerApp
                .auth()
                .currentUser.getIdToken();
            let config = {
                headers: {
                    token: token,
                    'Content-Type': 'application/json',
                },
            };
            let reqBody = {
                restauId: restauId,
                inquiryId: inquiryId,
            };
            const response = await axios.post(
                Config.getEventsByCateringId,
                reqBody,
                config
            );
            if (!response.data || response.data.status === false) {
                throw new Error(
                    'Error from the server: ' + response.data
                        ? response.data.msg
                        : null
                );
            }
            return response;
        } catch (error) {
            console.log('Caught error in retrieveEventsByInquiryId');
            setSnackbarMessage(
                'Issue retrieving event. Please contact support if the issue persists.'
            );
            setSnackbarOpen(true);
        }
    };

    const updateForm = (value) => {
        return setForm((prev) => {
            return { ...prev, ...value };
        });
    };

    const clearAll = () => {
        setForm({
            ...formFields,
            eventStatus: '',
            OtherStatusReasons: '',
            eventStatusReason: '',
            followupDueDate: '',
            internalNotes: '',
        });
    };

    // -------------- Update and Create event data in DB --------------
    const updateEvent = async (restauId, inquiryId, eventId, updatedData) => {
        if (!restauId) return;
        try {
            const token = await window.customerApp
                .auth()
                .currentUser.getIdToken();
            let config = {
                headers: {
                    token: token,
                    'Content-Type': 'application/json',
                },
            };
            let reqBody = {
                restauId: restauId,
                inquiryId: inquiryId,
                eventId: eventId,
                updatedData: updatedData,
            };
            const response = await axios.post(
                Config.updateEvent,
                reqBody,
                config
            );
            if (!response.data || response.data.status === false) {
                throw new Error(
                    'Error from the server: ' + response.data
                        ? response.data.msg
                        : null
                );
            }
            return response;
        } catch (error) {
            console.log('Caught error in updateEvent');
            setSnackbarMessage(
                'Issue updating current events. Please contact support if the issue persists.'
            );
            setSnackbarOpen(true);
            throw error;
        }
    };

    const createEvent = async (eventData) => {
        // createEvent creates under the location which is selected at the top.
        if (!restauHook.restauId) return;
        try {
            const token = await window.customerApp
                .auth()
                .currentUser.getIdToken();
            let config = {
                headers: {
                    token: token,
                    'Content-Type': 'application/json',
                },
            };
            let reqBody = {
                restauId: restauHook.restauId,
                eventData: eventData,
            };
            const response = await axios.post(
                Config.createEvent,
                reqBody,
                config
            );
            if (!response.data || response.data.status === false) {
                throw new Error(
                    'Error from the server: ' + response.data
                        ? response.data.msg
                        : null
                );
            }
            return response;
        } catch (error) {
            console.log('Caught error in createEvent');
            setSnackbarMessage(
                'Issue creating event. Please contact support if the issue persists.'
            );
            setSnackbarOpen(true);
            throw error;
        }
    };

    return {
        displayTitle,
        setDisplayTitle,
        eventType,
        eventStatus,
        typeColor,
        statusColor,
        formConfig,
        formFields,
        retrieveFormConfig,
        configLoading,
        setConfigLoading,
        retrieveFilteredEvents,
        lostReasons,
        rejectReasons,
        newEventPage,
        setNewEventPage,
        form,
        setForm,
        updateForm,
        clearAll,
        eventOrder,
        setEventOrder,
        conversation,
        setConversation,
        retrieveEventsByInquiryId,
        updateEvent,
        createEvent,
        loading,
        setLoading,
        showPriority,
        alertTooltip,
        snackbarOpen,
        setSnackbarOpen,
        snackbarMessage,
        setSnackbarMessage,
        snackbarAction,
        processConversationData,
        loadingSummaryData,
        setLoadingSummaryData,
    };
};

export default useCatering;
