import React from 'react';
import { Grid, TextField, Typography, IconButton } from '@mui/material';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const FieldValidation = ({
    editFieldForm,
    updateFieldForm,
    validationLoading,
    errors,
}) => {
    const addDropdownOption = () => {
        const newOptions = [
            ...editFieldForm.field_type_dropdown.options,
            { label: '', desc: '' },
        ];
        updateFieldForm({
            field_type_dropdown: {
                options: newOptions,
            },
        });
    };

    const deleteDropdownOption = (iToDelete) => {
        const newOptions = editFieldForm.field_type_dropdown.options.filter(
            (item, i) => i !== iToDelete
        );
        newOptions.filter((item, i) => i !== iToDelete);
        updateFieldForm({
            field_type_dropdown: {
                options: newOptions,
            },
        });
    };

    const renderValidation = (field_type) => {
        switch (field_type) {
            case 'Text':
                return (
                    <Grid container>
                        <TextField
                            label="Max Character Length"
                            size="small"
                            variant="outlined"
                            margin="normal"
                            helperText={
                                errors.text_max_char
                                    ? errors.text_max_char
                                    : 'Optional'
                            }
                            error={errors.text_max_char}
                            value={
                                editFieldForm.validation.max_char
                                    ? editFieldForm.validation.max_char
                                    : ''
                            }
                            onChange={(e) =>
                                updateFieldForm({
                                    validation: {
                                        ...editFieldForm.validation,
                                        max_char: e.target.value,
                                    },
                                })
                            }
                            sx={{ width: '90%' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                );
            case 'Number':
                return (
                    <Grid container>
                        <TextField
                            label="Min Number Required"
                            size="small"
                            variant="outlined"
                            margin="normal"
                            helperText={
                                errors.number_min_number
                                    ? errors.number_min_number
                                    : 'Optional'
                            }
                            error={errors.number_min_number}
                            value={editFieldForm.validation.min_number}
                            onChange={(e) =>
                                updateFieldForm({
                                    validation: {
                                        ...editFieldForm.validation,
                                        min_number: e.target.value,
                                    },
                                })
                            }
                            sx={{ width: '90%' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            label="Max Number Allowed"
                            size="small"
                            variant="outlined"
                            margin="normal"
                            helperText={
                                errors.number_max_number
                                    ? errors.number_max_number
                                    : 'Optional'
                            }
                            error={errors.number_max_number}
                            value={
                                editFieldForm.validation.max_number
                                    ? editFieldForm.validation.max_number
                                    : ''
                            }
                            onChange={(e) =>
                                updateFieldForm({
                                    validation: {
                                        ...editFieldForm.validation,
                                        max_number: e.target.value,
                                    },
                                })
                            }
                            sx={{ width: '90%' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                );
            case 'Text Area':
                return (
                    <Grid container>
                        <TextField
                            label="Max Character Length"
                            size="small"
                            variant="outlined"
                            margin="normal"
                            helperText={
                                errors.text_area_max_char
                                    ? errors.text_area_max_char
                                    : 'Optional'
                            }
                            error={errors.text_area_max_char}
                            value={
                                editFieldForm.validation.max_char
                                    ? editFieldForm.validation.max_char
                                    : ''
                            }
                            onChange={(e) =>
                                updateFieldForm({
                                    validation: {
                                        ...editFieldForm.validation,
                                        max_char: e.target.value,
                                    },
                                })
                            }
                            sx={{ width: '90%' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            label="Min Row for the Field"
                            size="small"
                            variant="outlined"
                            margin="normal"
                            helperText={
                                errors.text_area_minRow
                                    ? errors.text_area_minRow
                                    : 'Optional'
                            }
                            error={errors.text_area_minRow}
                            value={
                                editFieldForm.field_type_text_area &&
                                !isNaN(
                                    editFieldForm.field_type_text_area.minRow
                                )
                                    ? editFieldForm.field_type_text_area.minRow
                                    : 3
                            }
                            onChange={(e) =>
                                updateFieldForm({
                                    field_type_text_area: {
                                        minRow: e.target.value,
                                    },
                                })
                            }
                            sx={{ width: '90%' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                );
            case 'Date':
                return (
                    <Grid container>
                        <TextField
                            label="Min Notice Days"
                            size="small"
                            variant="outlined"
                            margin="normal"
                            helperText={
                                errors.date_min_notice_period
                                    ? errors.date_min_notice_period
                                    : 'Optional. Customers can only select a date after this number of days from today'
                            }
                            error={errors.date_min_notice_period}
                            value={
                                editFieldForm.field_type_date &&
                                !isNaN(
                                    editFieldForm.field_type_date
                                        .min_notice_period
                                )
                                    ? editFieldForm.field_type_date
                                          .min_notice_period
                                    : 0
                            }
                            onChange={(e) =>
                                updateFieldForm({
                                    field_type_date: {
                                        ...editFieldForm.field_type_date,
                                        min_notice_period: e.target.value,
                                    },
                                })
                            }
                            sx={{ width: '90%' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            label="Max Allowed Days"
                            size="small"
                            variant="outlined"
                            margin="normal"
                            helperText={
                                errors.date_max_notice_period
                                    ? errors.date_max_notice_period
                                    : 'Optional. Customers can only select a date before this number of days from today'
                            }
                            error={errors.date_max_notice_period}
                            value={
                                editFieldForm.field_type_date
                                    ? editFieldForm.field_type_date
                                          .max_notice_period
                                    : ''
                            }
                            onChange={(e) =>
                                updateFieldForm({
                                    field_type_date: {
                                        ...editFieldForm.field_type_date,
                                        max_notice_period: e.target.value,
                                    },
                                })
                            }
                            sx={{ width: '90%' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                );
            case 'Time':
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="Start Hour"
                                size="small"
                                variant="outlined"
                                margin="normal"
                                helperText={
                                    errors.time_start_hour
                                        ? errors.time_start_hour
                                        : 'Please enter a number between 0-23'
                                }
                                error={errors.time_start_hour}
                                value={
                                    editFieldForm.field_type_time &&
                                    !isNaN(
                                        editFieldForm.field_type_time.start_hour
                                    )
                                        ? editFieldForm.field_type_time
                                              .start_hour
                                        : 0
                                }
                                onChange={(e) =>
                                    updateFieldForm({
                                        field_type_time: {
                                            ...editFieldForm.field_type_time,
                                            start_hour: e.target.value,
                                        },
                                    })
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                required
                                label="Start Minute"
                                size="small"
                                variant="outlined"
                                margin="normal"
                                helperText={
                                    errors.time_start_min
                                        ? errors.time_start_min
                                        : 'Please enter a number between 0-59'
                                }
                                error={errors.time_start_min}
                                value={
                                    editFieldForm.field_type_time &&
                                    !isNaN(
                                        editFieldForm.field_type_time.start_min
                                    )
                                        ? editFieldForm.field_type_time
                                              .start_min
                                        : 0
                                }
                                onChange={(e) =>
                                    updateFieldForm({
                                        field_type_time: {
                                            ...editFieldForm.field_type_time,
                                            start_min: e.target.value,
                                        },
                                    })
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="End Hour"
                                size="small"
                                variant="outlined"
                                margin="normal"
                                helperText={
                                    errors.time_end_hour
                                        ? errors.time_end_hour
                                        : 'Please enter a number between 0-23'
                                }
                                error={errors.time_end_hour}
                                value={
                                    editFieldForm.field_type_time &&
                                    !isNaN(
                                        editFieldForm.field_type_time.end_hour
                                    )
                                        ? editFieldForm.field_type_time.end_hour
                                        : 23
                                }
                                onChange={(e) =>
                                    updateFieldForm({
                                        field_type_time: {
                                            ...editFieldForm.field_type_time,
                                            end_hour: e.target.value,
                                        },
                                    })
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                required
                                label="End Minute"
                                size="small"
                                variant="outlined"
                                margin="normal"
                                helperText={
                                    errors.time_end_min
                                        ? errors.time_end_min
                                        : 'Please enter a number between 0-59'
                                }
                                error={errors.time_end_min}
                                value={
                                    editFieldForm.field_type_time &&
                                    !isNaN(
                                        editFieldForm.field_type_time.end_min
                                    )
                                        ? editFieldForm.field_type_time.end_min
                                        : 0
                                }
                                onChange={(e) =>
                                    updateFieldForm({
                                        field_type_time: {
                                            ...editFieldForm.field_type_time,
                                            end_min: e.target.value,
                                        },
                                    })
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                );
            case 'Dropdown':
                return (
                    <Grid container>
                        <Grid
                            item
                            container
                            xs={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography sx={{ fontWeight: 'bold' }}>
                                Dropwdown Options
                            </Typography>
                            <IconButton onClick={addDropdownOption}>
                                <AddCircleSharpIcon color="primary" />
                                <Typography color="primary" sx={{ ml: 0.5 }}>
                                    Add Option
                                </Typography>
                            </IconButton>
                        </Grid>

                        {editFieldForm.field_type_dropdown &&
                            editFieldForm.field_type_dropdown.options.map(
                                (option, i) => (
                                    <Grid container item key={i} sx={{ mb: 1 }}>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                label={`Option ${i + 1}`}
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                value={option.label}
                                                helperText={
                                                    errors[`dropdown_${i}`]
                                                        ? errors[
                                                              `dropdown_${i}`
                                                          ]
                                                        : null
                                                }
                                                error={errors[`dropdown_${i}`]}
                                                onChange={(e) => {
                                                    let tempOptions = [
                                                        ...editFieldForm
                                                            .field_type_dropdown
                                                            .options,
                                                    ];
                                                    tempOptions[i].label =
                                                        e.target.value;
                                                    updateFieldForm({
                                                        field_type_dropdown: {
                                                            options:
                                                                tempOptions,
                                                        },
                                                    });
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            <IconButton
                                                onClick={() =>
                                                    deleteDropdownOption(i)
                                                }
                                            >
                                                <RemoveCircleIcon color="primary" />
                                                <Typography
                                                    color="primary"
                                                    sx={{ ml: 0.5 }}
                                                >
                                                    Delete Option
                                                </Typography>
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label={`Option ${
                                                    i + 1
                                                } description`}
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                helperText="Optional. If this option is selected, description will show under the field"
                                                value={option.desc}
                                                onChange={(e) => {
                                                    let tempOptions = [
                                                        ...editFieldForm
                                                            .field_type_dropdown
                                                            .options,
                                                    ];
                                                    tempOptions[i].desc =
                                                        e.target.value;
                                                    updateFieldForm({
                                                        field_type_dropdown: {
                                                            options:
                                                                tempOptions,
                                                        },
                                                    });
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                )
                            )}
                    </Grid>
                );
        }
    };

    return (
        <>
            {validationLoading ? (
                <img src="/img/spinner.gif" alt="loading" />
            ) : (
                renderValidation(editFieldForm.field_type)
            )}
        </>
    );
};

export default FieldValidation;
