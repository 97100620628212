import { useEffect, useState } from 'react';
import axios from 'axios';
import Config from '../config/Config';

export function useRestauInfo() {
    const [value, setValue] = useState({});
    const [allRestaurants, setAllRestaurants] = useState({});
    const [restauId, setRestauId] = useState(null);
    const [chainId, setChainId] = useState(null);
    const [restauTimeZone, setRestauTimeZone] = useState(null);
    const [strideQOnlineOrdering, setStrideQOnlineOrdering] = useState(false);
    const [user, setUser] = useState({});
    const [tokenId, setToken] = useState({});
    const [subscriptionPlan, setSubscriptionPlan] = useState({});

    const auth = window.customerApp.auth();
    auth.onAuthStateChanged(
        async function (currUser) {
            // !user means user is not signed in.
            if (
                !(await window.customerApp.auth().currentUser) &&
                !currUser &&
                !(
                    location.pathname == '/login' ||
                    location.pathname.includes('/signup') ||
                    location.pathname.startsWith('/resetpassword')
                )
            ) {
                let path = '';
                if (location.pathname === '/subscription') {
                    path = '?path=subscription';
                }
                let url = location.protocol + '//' + location.host;

                window.location.href = url + '/login' + path;

                return;
            }
            if (currUser && currUser.email !== user.email) {
                setUser(currUser);
                setToken(
                    await window.customerApp.auth().currentUser.getIdToken()
                );
            }
        },
        function (error) {
            //debugger;
            let url = location.protocol + '//' + location.host;

            window.location.href = url + '/login';
            console.log(error);
        }
    );

    function onChange(event, all) {
        //debugger;

        fetchRestaurantChainId(event.target.value);
        setRestauId(event.target.value);
        fetchRestaurantTimeZone(event.target.value);
        fetchStrideQOnlineOrdering(event.target.value);
        //loadSubscriptionPlan(event.target.value);
    }

    useEffect(() => {
        loadRestauInfo();
    }, [user.email]);

    async function loadRestauInfo() {
        if (!user.email) {
            return;
        }
        if (
            location.pathname === '/login' ||
            location.pathname.startsWith('/signup')
        ) {
            return;
        }
        console.log(user);
        const token = await window.customerApp.auth().currentUser.getIdToken();

        let config = {
            headers: {
                token: token,
            },
        };
        axios
            .post(Config.getRestaurantId, {}, config)
            .then(async function (response) {
                //debugger;
                setValue(response.data);
                setChainId(response.data.chainId);
                setRestauId(response.data.restauId);
                fetchRestaurantTimeZone(response.data.restauId);
                //await loadSubscriptionPlan(response.data.restauId);
                //console.log('subscriptionPlan: ', subscriptionPlan);
                if (response.data.strideQOnlineOrdering !== null) {
                    setStrideQOnlineOrdering(
                        response.data.strideQOnlineOrdering
                    );
                } else {
                    setStrideQOnlineOrdering(true);
                }
            })
            .catch(function (error) {
                //debugger;
                let url = location.protocol + '//' + location.host;

                window.location.href = url + '/login';
                console.log(error);
            });
    }

    async function fetchRestaurantTimeZone(restauId) {
        if (!restauId) {
            console.log('Reastaurant');
            return '';
        }
        const url = `menus/${restauId}/storeTimezone`;
        const admin = window.customerApp.database();
        let timeZoneRef = admin.ref(url);
        let tz = '';

        try {
            const timeZone = await timeZoneRef.once('value');
            setRestauTimeZone(timeZone.val());
        } catch (error) {}
        return tz;
    }

    async function fetchRestaurantChainId(restauId) {
        if (!restauId) {
            console.log('Reastaurant');
            return '';
        }
        const url = `menus/${restauId}/chainId`;
        const admin = window.customerApp.database();
        let valueRef = admin.ref(url);
        let tz = '';

        try {
            const value = await valueRef.once('value');
            setChainId(value.val());
        } catch (error) {}
        return tz;
    }

    async function fetchStrideQOnlineOrdering(restauId) {
        //debugger;
        if (!restauId) {
            console.log('Reastaurant');
            return '';
        }
        const url = `menus/${restauId}/strideQOnlineOrdering`;
        const admin = window.customerApp.database();
        let valRef = admin.ref(url);

        try {
            const value = await valRef.once('value');
            if (value.val() !== null) {
                setStrideQOnlineOrdering(value.val());
            } else {
                setStrideQOnlineOrdering(true);
            }
        } catch (error) {}
    }

    async function loadSubscriptionPlan(restauId) {
        //debugger;
        if (!restauId) {
            console.log('Reastaurant');
            return '';
        }
        const token = await window.customerApp.auth().currentUser.getIdToken();

        let config = {
            headers: {
                token: token,
            },
        };
        //debugger;
        let response = await axios
            .post(
                Config.getSubscriptionPlanForRestau,
                { restauId: restauId },
                config
            )
            .catch(function (error) {
                //debugger;
                let url = location.protocol + '//' + location.host;

                window.location.href = url + '/login';
                console.log(error);
            });

        if (response && response.status === 200) {
            setSubscriptionPlan(response.data);
        }
    }

    async function fetchRestaurantTimeZone(restauId) {
        if (!restauId) {
            console.log('Reastaurant');
            return '';
        }
        const url = `menus/${restauId}/storeTimezone`;
        const admin = window.customerApp.database();
        let timeZoneRef = admin.ref(url);
        let tz = '';

        try {
            const timeZone = await timeZoneRef.once('value');
            setRestauTimeZone(timeZone.val());
        } catch (error) {}
        return tz;
    }

    return {
        value,
        allRestaurants,
        restauId,
        chainId,
        strideQOnlineOrdering,
        subscriptionPlan,
        setSubscriptionPlan,
        onChange,
        setRestauId,
        setAllRestaurants,
        setValue,
        restauTimeZone,
        loadRestauInfo,
        user,
        tokenId,
    };
}
