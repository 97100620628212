import React from 'react';
import PropTypes from 'prop-types';
import TextMessageDetails from '../TextMessageDetails';
import PhoneSettingsContext from '../../PhoneSettingsContext';
import { useContext } from 'react';
import { DefaultWidgetValues } from '../../PhoneSettingsConstants';

function CallDefMsgOnlineOrderDetails(props) {
    const { txtMsgForOnlineOrdering, setTxtMsgForOnlineOrdering } = props;
    const { allowUpdateTxtMessage, restauName, restauId } =
        useContext(PhoneSettingsContext);
    return (
        // allowUpdateTxtMessage && (
        <TextMessageDetails
            txtMessage={txtMsgForOnlineOrdering}
            setTxtMessage={setTxtMsgForOnlineOrdering}
            id="send-message1-text"
            messageHeading="Text Message for Online Ordering"
            toolTipText={DefaultWidgetValues(
                'send_message_1',
                restauName,
                restauId
            )}
            size="large"
            disabled={!allowUpdateTxtMessage}
        />
        // )
    );
}

CallDefMsgOnlineOrderDetails.propTypes = {
    txtMsgForOnlineOrdering: PropTypes.string,
    setTxtMsgForOnlineOrdering: PropTypes.func,
    allowUpdateTxtMessage: PropTypes.bool,
};

export default CallDefMsgOnlineOrderDetails;
