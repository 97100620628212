import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import SayPlayMessageDetails from '../SayPlayMessage/SayPlayMessageDetails';

function CallDefThankYouMsgDetails(props) {
    const {
        thankUMsgDetails,
        setThankUMsgDetails,
        thankUMsgPlayFile,
        setThankUMsgPlayFile,
        originalThankUMsgDetails,
    } = props;
    return (
        <Grid container rowSpacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6">
                    Thank You Message for Online Ordering
                </Typography>
                <Typography variant="body1">
                    Thank You message to the customer for ordering online
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'flexEnd' }}
            >
                <SayPlayMessageDetails
                    msgDetails={thankUMsgDetails}
                    setMsgDetails={setThankUMsgDetails}
                    uploadedPlayFile={thankUMsgPlayFile}
                    setUploadedPlayFile={setThankUMsgPlayFile}
                    originalMsgDetails={originalThankUMsgDetails}
                />
            </Grid>
        </Grid>
    );
}

CallDefThankYouMsgDetails.propTypes = {
    thankUMsgDetails: PropTypes.shape({}),
    setThankUMsgDetails: PropTypes.func,
    thankUMsgPlayFile: PropTypes.shape({}),
    setThankUMsgPlayFile: PropTypes.func,
    originalThankUMsgDetails: PropTypes.array,
};

export default CallDefThankYouMsgDetails;
