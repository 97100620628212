import React, { useState } from 'react';
import axios from 'axios';
import Config from '../config/Config';

export function usePastTwoDays() {
    const [value, setValue] = useState({});
    const [loading, setLoading] = React.useState(false);
    const [pastRestauId, setPastRestauId] = useState({});

    async function loadData(restauInfoHook) {
        if (
            !restauInfoHook ||
            !restauInfoHook.restauId ||
            !window.customerApp.auth().currentUser
        ) {
            return;
        }
        if (restauInfoHook.restauId === pastRestauId) {
            return;
        }
        setPastRestauId(restauInfoHook.restauId);
        setLoading(true);
        setValue({});
        const token = await window.customerApp.auth().currentUser.getIdToken();

        let config = {
            headers: {
                token: token,
            },
        };
        console.log('loading data');
        //setLoading(false);
        axios
            .post(
                Config.getPast2DaysReport,
                { restauId: restauInfoHook.restauId },
                config
            )
            .then(function (response) {
                console.log('Past two days of data loading...........');
                console.log(response);
                setValue(response.data);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }
    return {
        value,
        loading,
        setLoading,
        setValue,
        loadData,
    };
}
