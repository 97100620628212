import { Alert } from '@mui/material';
import React from 'react';
import FormRoot from '../formControls/FormRoot';
import TitleBlock from '../formControls/TitleBlock';
import SignupPendingForm from './SignupPendingForm';
import SignupSuccessForm from './SignupSuccessForm';

function SignupForm(props) {
    const {
        successMessage,
        navigate,
        restaurantName,
        handleChange,
        merchantData,
        loginMessage,
        loaded,
        signup,
        globalError,
        signupSuccess,
    } = props;

    let restaurantNameUpper = restaurantName
        ? restaurantName.toUpperCase()
        : restaurantName;

    return (
        <FormRoot>
            {globalError && <Alert severity="error">{globalError}</Alert>}
            <TitleBlock
                title="Welcome To StrideQ"
                // subtitle={restaurantName ? `Create Account for ${restaurantName}` : ''}
            />
            {restaurantName && (
                <>
                    <br />
                    <i>
                        <TitleBlock
                            title={restaurantNameUpper}
                            subtitle={'Create Your Account'}
                        />
                    </i>
                </>
            )}

            {signupSuccess ? (
                <SignupSuccessForm
                    successMessage={successMessage}
                    navigate={navigate}
                />
            ) : (
                <SignupPendingForm
                    handleChange={handleChange}
                    merchantData={merchantData}
                    loginMessage={loginMessage}
                    loaded={loaded}
                    signup={signup}
                />
            )}
        </FormRoot>
    );
}

SignupForm.propTypes = {};

export default SignupForm;
