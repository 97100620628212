import { Button, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import PhoneSettingsContext from '../PhoneSettingsContext';
import SayPlayMessageDetails from './SayPlayMessage/SayPlayMessageDetails';
import CircularProgress from '@mui/material/CircularProgress';
import TextMessageDetails from './TextMessageDetails';
import { DefaultWidgetValues } from '../PhoneSettingsConstants';
import ConfirmationDialog from './ConfirmationDialog';
import { isEqual } from 'lodash';

function MissedCallProtectionDetails() {
    const {
        busyMessageDetails,
        setBusyMessageDetails,
        txtMsgForBusy,
        setTxtMsgForBusy,
        updatePhoneSettings,
        uploadAndUpdatePlayMessage,
        allowUpdateTxtMessage,
        restauName,
        restauId,
        setAccordionExpanded,
    } = useContext(PhoneSettingsContext);

    const [isProcessing, setIsProcessing] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [originalBusyMessageDetails, setOriginalBusyMessageDetails] =
        useState([]);
    const [originalTxtMsgForBusy, setOriginalTxtMsgForBusy] = useState('');
    const [uploadedPlayFile, setUploadedPlayFile] = useState({});
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const updateMisCallProtectionDetails = async () => {
        //debugger;

        let isMsgDetailsValid = true;
        if (
            busyMessageDetails.length === 0 ||
            busyMessageDetails[0].type === undefined
        ) {
            isMsgDetailsValid = false;
            setErrMsg('Message Details are not Valid.');
            return;
        }
        let isBusyTxtValid = true;
        if (txtMsgForBusy === '') {
            isBusyTxtValid = false;
            setErrMsg('Text Message to customer cannot be blank.');
            return;
        }
        if (isMsgDetailsValid && isBusyTxtValid) {
            setErrMsg('');
            setSuccessMsg('');
            setIsProcessing(true);
            // debugger;
            let temp;
            if (busyMessageDetails[0].type === 'play') {
                //Fix for uploading play file.
                //check if the play file changed.
                // if (
                //     originalBusyMessageDetails[0].playFilePath !==
                //     busyMessageDetails[0].playFilePath
                // ) {
                //     temp = await uploadAndUpdatePlayMessage(
                //         busyMessageDetails,
                //         uploadedPlayFile,
                //         setErrMsg,
                //         setSuccessMsg,
                //         originalBusyMessageDetails
                //     );
                //     if (!temp) {
                //         //debugger;
                //         setIsProcessing(false);
                //         return;
                //     }
                // } else {
                temp = JSON.parse(JSON.stringify(busyMessageDetails));
                //}
                // delete temp[0].sayMessage;
                // delete temp[0].sayMessageLanguage;
            } else {
                temp = JSON.parse(JSON.stringify(busyMessageDetails));
                delete temp[0].playFilePath;
            }

            let body = {
                missedCallProtection: {
                    busyMessage: temp,
                    sendTextWhenBusy: txtMsgForBusy,
                },
            };

            updatePhoneSettings(
                body,
                setErrMsg,
                setSuccessMsg,
                setIsProcessing
            );
        }
    };

    const resetData = () => {
        if (
            txtMsgForBusy === originalTxtMsgForBusy &&
            isEqual(busyMessageDetails, originalBusyMessageDetails)
        ) {
            //close the accordion
            setAccordionExpanded(false);
        } else {
            setShowConfirmationDialog(true);
        }
    };

    const handleDiscardChanges = () => {
        setSuccessMsg('');
        setErrMsg('');
        setBusyMessageDetails(
            JSON.parse(JSON.stringify(originalBusyMessageDetails))
        );
        setTxtMsgForBusy(originalTxtMsgForBusy);
        setAccordionExpanded(false);
    };

    useEffect(() => {
        setErrMsg('');
        setSuccessMsg('');
        setOriginalBusyMessageDetails(
            JSON.parse(JSON.stringify(busyMessageDetails))
        );
        setOriginalTxtMsgForBusy(txtMsgForBusy);
    }, []);

    return (
        <>
            <ConfirmationDialog
                showConfirmationDialog={showConfirmationDialog}
                setShowConfirmationDialog={setShowConfirmationDialog}
                handleConfirm={handleDiscardChanges}
            />
            <Grid container rowSpacing={3} xs={12} md={9}>
                <Grid item xs={12}>
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Message to Customer
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography varint="body1">
                                Turn missed calls into orders! This protection
                                feature allows you to send a text if you are not
                                available to pick up a call
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Divider style={{ width: '100%' }} /> */}
                <Grid item xs={12}>
                    <SayPlayMessageDetails
                        msgDetails={busyMessageDetails}
                        setMsgDetails={setBusyMessageDetails}
                        uploadedPlayFile={uploadedPlayFile}
                        setUploadedPlayFile={setUploadedPlayFile}
                        originalMsgDetails={originalBusyMessageDetails}
                    />
                </Grid>
                <Grid item xs={12}>
                    {/* {allowUpdateTxtMessage && ( */}
                    <TextMessageDetails
                        txtMessage={txtMsgForBusy}
                        setTxtMessage={setTxtMsgForBusy}
                        id="busy-msg-text"
                        messageHeading="Text Message to Customer"
                        toolTipText={DefaultWidgetValues(
                            'send_message_as_busy',
                            restauName,
                            restauId
                        )}
                        size="large"
                        disabled={!allowUpdateTxtMessage}
                    />
                    {/* )} */}
                </Grid>
                {errMsg !== '' ? (
                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{ color: 'red' }}>
                            {errMsg}
                        </Typography>
                    </Grid>
                ) : (
                    successMsg !== '' && (
                        <Grid item xs={12}>
                            <Typography variant="body1" sx={{ color: 'green' }}>
                                {successMsg}
                            </Typography>
                        </Grid>
                    )
                )}
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                    }}
                >
                    <Button variant="outlined" onClick={resetData}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ ml: 2 }}
                        onClick={updateMisCallProtectionDetails}
                        disabled={isProcessing}
                    >
                        {isProcessing && <CircularProgress size={24} />}
                        Save
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

MissedCallProtectionDetails.propTypes = {};

export default MissedCallProtectionDetails;
