import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { DashboardContext } from '../home/DashboardContext';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
    return { id, date, name, shipTo, paymentMethod, amount };
}

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
}));

function createMap(allOrders) {
    let map = new Map();
    for (var ak in allOrders) {
        let items = allOrders[ak]['items'];
        if (!map[ak]) {
            map[ak] = {};
        }
        for (var ik in items) {
            map[ak][ik] = items[ik];
        }
    }
    return map;
}
function createKeys(allOrders) {
    let map = new Map();
    for (var ak in allOrders) {
        let items = allOrders[ak]['items'];

        for (var ik in items) {
            if (!map[ik]) {
                map[ik] = true;
            }
        }
    }
    return map;
}
function getNumber(number) {
    if (!number) {
        return 0;
    }
    return number;
}
function createRow(mapKey, allOrders, map) {
    return (
        <>
            <TableCell>{mapKey}</TableCell>
            {Object.keys(allOrders).map((key, index) => (
                <TableCell align="right">
                    {getNumber(map[key][mapKey])}
                </TableCell>
            ))}
        </>
    );
}

export default function Items(prop) {
    const { dashboardHook } = useContext(DashboardContext);
    const classes = useStyles();
    const map = createMap(dashboardHook.value.allOrders);
    const keysMap = createKeys(dashboardHook.value.allOrders);
    return (
        <>
            {!prop.allOrders ? (
                <div>
                    <img
                        className={classes.loadingImage}
                        src="../../img/spinner.gif"
                    />
                </div>
            ) : (
                <>
                    <Typography
                        component="b"
                        variant="h6"
                        color="primary"
                        gutterBottom
                    >
                        Past 10 Days Items Data
                    </Typography>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                {Object.keys(prop.allOrders).map(
                                    (key, index) => (
                                        <TableCell>{key}</TableCell>
                                    )
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(keysMap).map((key, index) => (
                                <TableRow key={'key'}>
                                    {createRow(key, prop.allOrders, map)}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}
        </>
    );
}
