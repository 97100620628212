import React, { useState, useContext } from 'react';
import {
    Grid,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { ConfirmButton } from './FoodDialog';
import { QuantityInput } from './QuantityInput';
import CateringContext from '../../../../CateringContext';
import formatPrice from '../../../../../utils/formatPrice';
import cloneDeep from 'lodash/cloneDeep';

const CustomFoodDialog = ({
    open,
    setOpenCustomFoodDialog,
    openFood,
    orders,
    dispatchOrder,
    setOpenFood,
    updateMenuSelection,
    defaultTax,
    updatePricing,
    keyToEdit,
    setKeyToEdit,
}) => {
    const { setSnackbarOpen, setSnackbarMessage } = useContext(CateringContext);
    const [name, setName] = useState(openFood ? openFood.name : '');
    const [quantity, setQuantity] = useState(openFood ? openFood.quantity : 1);
    const [inputPrice, setInputPrice] = useState(
        openFood ? openFood.price / 100 : ''
    );
    const [notes, setNotes] = useState(
        openFood && openFood.notes ? openFood.notes : ''
    );
    const [errors, setErrors] = useState({});
    const isEditing = keyToEdit !== null;
    const order = {
        ...openFood,
        name: name,
        price: inputPrice * 100,
        quantity: quantity,
        notes: notes,
    };

    const close = () => {
        setKeyToEdit(null);
        setOpenFood();
        setOpenCustomFoodDialog(false);
    };

    const customItemValidation = (tempErrors) => {
        if (name.length === 0)
            tempErrors['name'] = 'Please provide custom item name';
        if (inputPrice.length === 0)
            tempErrors['price'] = 'Please provide custom item price';
        else if (isNaN(Number(inputPrice)))
            tempErrors['price'] = 'Please input a valid number';
        return tempErrors;
    };

    const editOrder = async () => {
        // conduct validation
        setErrors({});
        let tempErrors = {};
        tempErrors = customItemValidation(tempErrors);
        setErrors(tempErrors);
        if (Object.keys(tempErrors).length !== 0) return;

        var newOrder = {
            id: order.id,
            key: order.key,
            quantity: order.quantity,
            name: order.name,
            original_price: order.price,
            price: order.price,
            isCustom: true,
        };
        if (order.notes && order.notes !== '') {
            newOrder['notes'] = order.notes;
        }

        let newOrders = cloneDeep(orders);
        if (keyToEdit !== null) {
            newOrders.items[keyToEdit] = {
                ...newOrder,
            };
            newOrders = updatePricing(newOrders, newOrders.items);
            try {
                // send to DB first before updating state
                await updateMenuSelection(newOrders);
                dispatchOrder({ type: 'UPDATE_ALL', newOrders });
                close();
            } catch (e) {
                console.log('Error editing order: ', e);
                setSnackbarMessage(
                    'Issue editing item. Please contact support if the issue persists.'
                );
                setSnackbarOpen(true);
            }
        }
    };

    const addToOrder = async () => {
        // conduct validation
        setErrors({});
        let tempErrors = {};
        tempErrors = customItemValidation(tempErrors);
        setErrors(tempErrors);
        if (Object.keys(tempErrors).length !== 0) return;

        var newOrder = {
            quantity: order.quantity,
            name: order.name,
            original_price: order.price,
            price: order.price,
            isCustom: true,
        };

        if (order.notes && order.notes !== '') {
            newOrder['notes'] = order.notes;
        }

        const itemID = `${Date.now().toString(36)}-${Math.random()
            .toString(36)
            .substring(2, 5)}`;
        // check if orders is undefined. If it is, create the default structure;
        let newOrders;
        if (!orders) {
            newOrders = {
                items: {
                    [itemID]: {
                        ...newOrder,
                        key: itemID,
                        id: itemID,
                    },
                },
                discount: {
                    discount_type: 'Dollar',
                    amount: 0,
                    amount_off: 0,
                },
                payment_subtotal_original: 0,
                payment_subtotal_before_discount: 0,
                payment_subtotal: 0,
                payment_salestax: 0,
                payment_salestax_percent: defaultTax,
                payment_total: 0,
                tip: 0,
            };
        } else {
            newOrders = cloneDeep(orders);
            newOrders.items[itemID] = {
                ...newOrder,
                key: itemID,
                id: itemID,
            };
        }
        newOrders = updatePricing(newOrders, newOrders.items);
        try {
            // send to DB first before updating state
            await updateMenuSelection(newOrders);
            dispatchOrder({ type: 'UPDATE_ALL', newOrders });
            close();
        } catch (e) {
            console.log('Error adding new order: ', e);
            setSnackbarMessage(
                'Issue adding item. Please contact support if the issue persists.'
            );
            setSnackbarOpen(true);
        }
    };

    return (
        <>
            <Dialog open={open} fullWidth sx={{ zIndex: '9999' }}>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="Item Name"
                                size="small"
                                variant="outlined"
                                margin="normal"
                                value={name}
                                sx={{ width: '100%' }}
                                helperText={!errors.name ? null : errors.name}
                                onChange={(e) => setName(e.target.value)}
                                error={!!errors.name}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                required
                                label="Item Price"
                                size="small"
                                variant="outlined"
                                margin="normal"
                                value={inputPrice}
                                sx={{ width: '100%' }}
                                helperText={!errors.price ? null : errors.price}
                                onChange={(e) => setInputPrice(e.target.value)}
                                error={!!errors.price}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <QuantityInput
                                quantity={quantity}
                                setQuantity={setQuantity}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Special Instructions"
                                size="small"
                                variant="outlined"
                                margin="normal"
                                multiline
                                minRows={2}
                                sx={{ width: '100%', mt: 5 }}
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <ConfirmButton onClick={isEditing ? editOrder : addToOrder}>
                        {isEditing ? `Update order ` : `Add to order `}
                        {order.price
                            ? formatPrice((order.quantity * order.price) / 100)
                            : ''}
                    </ConfirmButton>
                    <ConfirmButton onClick={close} style={{ width: '80px' }}>
                        Close
                    </ConfirmButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CustomFoodDialog;
