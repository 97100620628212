import React from 'react';
import Tuba from './FoodTemplates/Tuba';
import ImageRight from './FoodTemplates/ImageRight';
import ImageLeft from './FoodTemplates/ImageLeft';
import formatPrice from '../utils/formatPrice';
import getImage from '../utils/getImage';

const templates = {
    imageleft: ImageLeft,
    imageright: ImageRight,
    tuba: Tuba,
};

function FoodCard(props) {
    const { sectionId, food, template, onFoodClick } = props;

    const {
        id,
        desc,
        oos,
        pricelabel,
        original_price,
        price,
        name,
        img,
        hide,
    } = food;

    const Template = templates[template] || ImageRight;

    const handleFoodClick = () => {
        onFoodClick(food);
    };

    return (
        <Template
            sectionId={sectionId}
            foodId={id}
            price={pricelabel || formatPrice(price / 100)}
            originalPrice={
                original_price ? formatPrice(original_price / 100) : ''
            }
            desc={desc}
            oos={oos}
            name={name}
            hide={hide}
            imgSrc={img ? getImage(img) : ''}
            defaultImgSrc={getImage('/menu-images/stock_img.jpg')}
            onFoodClick={handleFoodClick}
        />
    );
}

FoodCard.propTypes = {};
FoodCard.defaultProps = {
    onFoodClick: () => {},
    template: 'imageright',
};

export default FoodCard;
