import React from 'react';
import { Grid } from '@mui/material';
import SubmitButton from '../formControls/SubmitButton';
import PropTypes from 'prop-types';

function ACHPendingForm(props) {
    const { loaded, signup, successMessage, setPaymentMode } = props;
    return (
        <Grid item>
            <form id="payment-method-form"></form>
            <form id="confirmation-form"></form>

            <SubmitButton
                message={successMessage}
                severity={successMessage ? 'success' : ''}
                showProgress={!loaded}
                disabled={!loaded}
                label="ACH"
                onClick={() => {
                    setPaymentMode('ACH');
                    signup();
                }}
            />
        </Grid>
    );
}

ACHPendingForm.propTypes = {
    loaded: PropTypes.bool,
    signup: PropTypes.func,
    successMessage: PropTypes.string,
    setPaymentMode: PropTypes.func,
};

export default ACHPendingForm;
