/**
 * defines slot for defining a start-time and end-time
 * between which the restaurant operates
 */

import React from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import isNull from 'lodash/isNull';
import TextField from '@mui/material/TextField';

const SlotContainer = styled.div`
    padding: 0;
    flex-grow: 1;
`;

const SlotDefinitionContainer = styled.div`
    display: flex;
    flex-grow: 1;
    gap: 12px;
    margin-top: 10px;
`;
function DaySlot(props) {
    const { startTime, endTime, label, disabled, onChange } = props;
    let isError = false;
    let messageStr = '';
    if (endTime && endTime.isBefore(startTime)) {
        isError = true;
        messageStr = 'End < Start';
    }
    if (!isNull(startTime) && isNull(endTime)) {
        isError = true;
        messageStr = 'End not defined';
    } // flag if end is not defined

    const message = <div style={{ paddingTop: 3 }}>{messageStr}</div>;

    return (
        <SlotContainer>
            {label && (
                <div>
                    <Typography>{label}</Typography>
                </div>
            )}
            <SlotDefinitionContainer>
                <TimePicker
                    label="Start"
                    value={startTime}
                    onChange={(time) => onChange(time, 0)}
                    minutesStep={10}
                    size="small"
                    margin="dense"
                    autoOk
                    disabled={disabled}
                    selected={startTime}
                    renderInput={(props) => <TextField {...props} />}
                />
                <TimePicker
                    label="End"
                    value={endTime}
                    onChange={(time) => onChange(time, 1)}
                    minutesStep={15}
                    size="small"
                    margin="dense"
                    autoOk
                    disabled={disabled}
                    error={isError}
                    helperText={isError && message}
                    selected={endTime}
                    renderInput={(props) => <TextField {...props} />}
                />
            </SlotDefinitionContainer>
        </SlotContainer>
    );
}

DaySlot.propTypes = {};
DaySlot.defaultProps = {};

export default DaySlot;
