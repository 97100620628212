import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import Config from '../config/Config';
import { useAuthentication } from '../Hooks/useAuthentication';

import { useMediaQuery, useTheme } from '@mui/material';
import BrandingTemplate from '../components/branding/BrandingTemplate';
import LinkAccountForm from '../components/forms/LinkAccountForm';
import { useStyles } from './newSignupTheme';
const { default: axios } = require('axios');

export default function SignupSuccess(props) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        noSsr: true,
    });
    const mobileMargin = { marginTop: '210px' };
    const mobileStyle = isMobile ? mobileMargin : {};
    const mobileLayout = isMobile ? 12 : 6;
    const auth = useAuthentication();
    let navigate = useNavigate();
    const [loaded, setLoaded] = useState(true);
    const [loginMessage, setLoginMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState(null);
    const [restauId, setRestauId] = useState(null);
    const [stripeAccount, setStripeAccount] = useState(null);
    const [paymentInt, setPaymentIntent] = useState(null);
    const [accept, setAccept] = useState(false);
    const [achSuccess, setACHSuccess] = useState(false);
    const [cardSuccess, setCardSuccess] = useState(false);
    const [restaurantName, setRestuarantName] = useState(null);
    const [ownerFullName, setOwnerFullName] = useState(null);
    const [achLinkingInProgress, setAChLinkingInProgress] = useState(false);

    let stripe = Stripe(Config.stripe_publish_key);

    let { rId } = useParams();

    async function sendStatus(status) {
        const achStatus = Config.achStatus;
        let env = Config.env;
        let req = {};
        req['restauId'] = restauId;
        req['env'] = env;
        req['status'] = status;
        await axios.post(achStatus, req, { crossDomain: true });
    }

    async function confirmPayment(clientsecret) {
        //debugger;
        stripe
            .confirmUsBankAccountPayment(clientsecret)
            .then(async ({ paymentIntent, error }) => {
                console.log('setup Intent ', paymentIntent);
                console.log('error', error);
                if (error) {
                    setLoginMessage(error.message);
                    //setLoaded(false);

                    // The payment failed for some reason.
                } else if (paymentIntent.status === 'requires_payment_method') {
                    // Confirmation failed. Attempt again with a different payment method.
                } else if (paymentIntent.status === 'processing') {
                    // alert('processing ');

                    await sendStatus('ACH_SUCCESS');
                    setACHSuccess(true);
                    //setLoaded(false);

                    // Confirmation succeeded! The account will be debited.
                    // Display a message to customer.
                } else if (
                    paymentIntent.next_action?.type ===
                    'verify_with_microdeposits'
                ) {
                    console.log(paymentIntent);
                    await sendStatus('ACH_MANUAL_VERIFICATION');
                    // The account needs to be verified via microdeposits.
                    // Display a message to consumer with next steps (consumer waits for
                    // microdeposits, then enters a statement descriptor code on a page sent to them via email).
                }
            });
    }

    async function getMerchantInfo(restauId, stripeAct) {
        const getMerchantInfoURL = Config.signupSuccess;
        setLoaded(false);
        let merchantInfo = await axios.post(
            getMerchantInfoURL,
            {
                env: Config.env,
                restauId: restauId,
                stripeAccount: stripeAct,
            },
            { crossDomain: true }
        );
        if (merchantInfo.data.status === 'START') {
            console.log(
                'restaurant name: ',
                merchantInfo.data.data.restaurantName
            );
            console.log(merchantInfo.data.data.restuarantName);
            setPaymentIntent(merchantInfo.data.data.paymentIntent);
            setRestuarantName(merchantInfo.data.data.restaurantName);
            setOwnerFullName(merchantInfo.data.data.ownerFullName);
        } else if (merchantInfo.data.status.endsWith('SUCCESS')) {
            setRestuarantName(merchantInfo.data.data.restaurantName);
            setOwnerFullName(merchantInfo.data.data.ownerFullName);
            if (merchantInfo.data.status === 'ACH_SUCCESS') setACHSuccess(true);
            else setCardSuccess(true);
            return;
        } else if (merchantInfo.data.status === 'ERROR') {
            setLoginMessage(merchantInfo.data.msg);
            setLoaded(true);
            return;
        }

        console.log(merchantInfo);
        setLoaded(true);
    }
    useEffect(() => {
        setLoaded(true);

        // Update the document title using the browser API
        const search = window.location.search;
        const params = new URLSearchParams(search);

        if (rId) {
            setRestauId(rId);
            setStripeAccount(params.get('act'));
            getMerchantInfo(rId, params.get('act'));
        }
    }, []);
    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            signup(event);
        }
    }

    async function signup(e) {
        if (!accept) {
            setLoginMessage('Please accept to proceed');
            return;
        }
        //setLoaded(true);
        const paymentMethodForm = document.getElementById(
            'payment-method-form'
        );
        const confirmationForm = document.getElementById('confirmation-form');

        // Calling this method will open the instant verification dialog.
        console.log(paymentInt);
        setLoaded(false);
        stripe
            .collectBankAccountForPayment({
                clientSecret: paymentInt.client_secret,
                params: {
                    payment_method_type: 'us_bank_account',
                    payment_method_data: {
                        billing_details: {
                            name: 'test_email',
                            email: 'test_email@gmail.com',
                        },
                    },
                },
                expand: ['payment_method'],
            })
            .then(async ({ paymentIntent, error }) => {
                console.log(paymentIntent);
                if (error) {
                    setLoginMessage(error.message);
                    await sendStatus('ACH_LINK_FAILED');
                    setLoaded(true);
                    // PaymentMethod collection failed for some reason.
                } else if (paymentIntent.status === 'requires_payment_method') {
                    setLoaded(true);

                    // Customer canceled the hosted verification modal. Present them with other
                    // payment method type options.
                } else if (paymentIntent.status === 'requires_confirmation') {
                    // We collected an account - possibly instantly verified, but possibly
                    // manually-entered. Display payment method details and mandate text
                    // to the customer and confirm the intent once they accept
                    // the mandate.
                    await sendStatus('ACH_LINKED');
                    setAChLinkingInProgress(true);
                    setSuccessMessage('Bankaccount Linking in process...');
                    await confirmPayment(paymentInt.client_secret);
                    setLoaded(true);
                }
            });
    }

    return (
        <BrandingTemplate>
            <LinkAccountForm
                loaded={loaded}
                loginMessage={loginMessage}
                navigate={navigate}
                accept={accept}
                setAccept={setAccept}
                signup={signup}
                successMessage={successMessage}
                achSuccess={achSuccess}
                restaurantName={restaurantName}
                setLoginMessage={setLoginMessage}
                clientSecret={paymentInt && paymentInt.client_secret}
                cardSuccess={cardSuccess}
                setCardSuccess={setCardSuccess}
                sendStatus={sendStatus}
                setLoaded={setLoaded}
                ownerFullName={ownerFullName}
            />
        </BrandingTemplate>
    );
}
