import { Typography } from '@mui/material';
import React from 'react';

function HelpText() {
    return (
        <div>
            <Typography
                align="center"
                component="p"
                variant="subtitle2"
                display="block"
                noWrap
            >
                Need Help? Contact: (650) 282-1239
            </Typography>
        </div>
    );
}

HelpText.propTypes = {};

export default HelpText;
