import { useMediaQuery, useTheme } from '@mui/material';
import React, { useContext, useEffect } from 'react';

import { RestauInfoContext } from '../home/RestauInfoContext.js';
import { useStyles } from '../home/styles/useStyles';
import {
    Hours,
    RegularHoursWrapper,
    SpecialHoursWrapper,
} from './HolidayStyles';
import RegularHours from './RegularHours';
import SpecialHours from './SpecialHours';

export default function Holiday(props) {
    const resauInfoContext = useContext(RestauInfoContext);
    const restauHook = resauInfoContext.restauHook;
    const [loaded, setLoaded] = React.useState(false);
    const [hours, setHours] = React.useState(undefined);
    const [specialHours, setSpecialHours] = React.useState(null);
    const classes = useStyles();

    const theme = useTheme();

    const isLarge = useMediaQuery(theme.breakpoints.up('md'), {
        noSsr: true,
    });

    async function updateRegularHoursConfig(config) {
        setLoaded(true);
        await saveRegularHours(restauHook.restauId, config);
        setHours(config);
        setLoaded(false);
    }

    async function saveHoursConfig(url, config) {
        const database = window.customerApp.database();
        const updates = { [url]: config };

        await database
            .ref()
            .update(updates)
            .then(function () {
                console.log('Hours updated successfully');
            })
            .catch(function (error) {
                console.log('Error while saving please try again');
                //Need to pop error someways
                console.log(error);
            });
    }

    async function saveRegularHours(restauId, config) {
        const url = 'menus/' + restauId + '/details/hours';
        saveHoursConfig(url, config);
    }

    async function saveSpecialHours(restauId, config) {
        const url = 'menus/' + restauId + '/details/special_hours';
        saveHoursConfig(url, config);
    }

    function mergeConfigs(hoursConfig = {}, record = {}) {
        console.log(hoursConfig, record);
        let splHours = { ...hoursConfig }; //= hoursConfig ? hoursConfig : {};
        Object.keys(record).forEach((key, index) => {
            if (splHours[key]) {
                const holiday = record[key].holiday;
                splHours[key].holiday = holiday;
                if (holiday) {
                    delete splHours[key].hours;
                } else {
                    splHours[key].hours = [
                        ...(splHours[key].hours || []),
                        ...record[key].hours,
                    ];
                }
            } else {
                splHours[key] = record[key];
            }
        });
        return splHours;
    }

    async function updateSpecialHourConfig(record) {
        setLoaded(true);
        // merge special hour config with new record
        const config = mergeConfigs(specialHours, record);
        await saveSpecialHours(restauHook.restauId, config);
        setSpecialHours(config);
        setLoaded(false);
    }
    // handleDelete
    async function handleDeleteSplHours(day, slotID = -1) {
        setLoaded(true);
        // merge special hour config with new record
        const config = { ...specialHours };
        if (slotID === -1) {
            delete config[day];
        } else {
            const { hours } = config[day];
            const updated = [
                ...hours.slice(0, slotID),
                ...hours.slice(slotID + 1),
            ];
            if (updated.length === 0) {
                delete config[day];
            } else {
                config[day].hours = updated;
            }
        }

        await saveSpecialHours(restauHook.restauId, config);
        setSpecialHours(config);
        setLoaded(false);
    }

    async function loadData(restauHook) {
        if (!window.customerApp.auth().currentUser) {
            navigate('/login');
            return;
        }
        const token = await window.customerApp.auth().currentUser.getIdToken();

        let config = {
            headers: {
                token: token,
            },
        };
        console.log('Restau Hook from restaurant: ' + restauHook.restauId);
        const admin = window.customerApp.database();
        let menuRef = admin.ref(
            'menus/' + restauHook.restauId + '/details/hours'
        );

        const menus = await menuRef
            .once('value')
            .then(function (snapshot) {
                let result = snapshot.val();
                //menuHook.setValue({menu:result});
                console.log('Hours::' + result);
                console.log(result);
                if (result) {
                    console.log('setting value');
                    setHours(result);
                }
                setLoaded(false);

                return result;
            })
            .catch(function (error) {
                setLoaded(false);
                console.log(error);
            });
        let specialRef = admin.ref(
            'menus/' + restauHook.restauId + '/details/special_hours'
        );

        const specialHour = await specialRef
            .once('value')
            .then(function (snapshot) {
                let result = snapshot.val();
                //menuHook.setValue({menu:result});
                console.log('Hours::' + result);
                console.log(result);

                setLoaded(false);

                return result;
            })
            .catch(function (error) {
                setLoaded(false);
                console.log(error);
            });
        setSpecialHours(specialHour);

        return;
    }
    useEffect(() => {
        setLoaded(true);
        loadData(restauHook);
    }, [restauHook.restauId]);

    return (
        <div>
            {loaded ? (
                <div>
                    <img
                        className={classes.loadingImage}
                        src="../../img/spinner.gif"
                    />
                </div>
            ) : (
                <Hours horizontalLayout={isLarge}>
                    <SpecialHoursWrapper horizontalLayout={isLarge}>
                        <SpecialHours
                            specialHours={specialHours}
                            onDelete={handleDeleteSplHours}
                            onUpdateConfig={updateSpecialHourConfig}
                        />
                    </SpecialHoursWrapper>
                    <RegularHoursWrapper horizontalLayout={isLarge}>
                        <RegularHours
                            regularHours={hours || {}}
                            onUpdateConfig={updateRegularHoursConfig}
                        />
                    </RegularHoursWrapper>
                </Hours>
            )}
        </div>
    );
}
