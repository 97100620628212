import React, { PureComponent } from 'react';
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import classes from './graph.module.css';

export default class Chart extends PureComponent {
    static demoUrl =
        'https://codesandbox.io/s/composed-chart-of-same-data-i67zd';

    render() {
        const orderedDates = [];
        let allOrders = this.props.allOrders;

        let data = [];
        console.log(allOrders);
        Object.keys(allOrders)
            .reverse()
            .forEach(function (key) {
                console.log(key);
                let json = {};
                json['date'] = key;
                json['paymentTotal'] = parseFloat(allOrders[key].paymentTotal);
                json['averageOrder'] = allOrders[key].averageOrder;
                data.push(json);
            });

        return (
            <div className={classes.wrapper}>
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                        width={700}
                        height={600}
                        data={data}
                        margin={{
                            top: 3,
                            right: 3,
                            bottom: 3,
                            left: 3,
                        }}
                    >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="date" scale="band" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar
                            dataKey="paymentTotal"
                            barSize={20}
                            fill="#413ea0"
                        />
                        <Line
                            type="monotone"
                            dataKey="averageOrder"
                            stroke="#ff7300"
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        );
    }
}
