import { Button, Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React, { useState, useEffect } from 'react';
import { useContext } from 'react';
import { RestauInfoContext } from '../../../../home/RestauInfoContext';
import Utils from '../../../../utils/Utils';
import PhoneReportsContext from '../PhoneReportsContext';
const moment = require('moment-timezone');

const { getDateWithOffset } = { ...Utils };
function SearchReportsCriteria() {
    const [tempStartDate, setTempStartDate] = useState(null);
    const [tempEndDate, setTempEndDate] = useState(null);
    const { restauHook } = useContext(RestauInfoContext);
    const { startDate, setStartDate, endDate, setEndDate, loadData } =
        useContext(PhoneReportsContext);

    //console.log('restauTimeZone', restauHook.restauTimeZone);
    //console.log('restauId', restauHook.restauId);
    //console.log('EndTimeStamp for Report', tempEndDate);
    //console.log('StartTimeStamp for Report', tempStartDate);

    function resetSearchCriteria() {
        let yesterdayDate = moment()
            .tz(restauHook.restauTimeZone)
            .subtract(1, 'days');
        //.format('YYYY-MM-DD');
        setTempEndDate(
            moment.tz(
                yesterdayDate.format('YYYY-MM-DD') + 'T23:59:59',
                restauHook.restauTimeZone
            )
        );

        setTempStartDate(
            moment
                .tz(
                    yesterdayDate.format('YYYY-MM-DD') + 'T00:00:01',
                    restauHook.restauTimeZone
                )
                .subtract(30, 'days')
        );
    }

    function setSearchDates() {
        //debugger;
        setEndDate((prevVal) =>
            moment
                .tz(
                    tempEndDate.format('YYYY-MM-DD') + 'T23:59:59',
                    restauHook.restauTimeZone
                )
                //.add(1, 'days')
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss[Z]')
        );
        setStartDate((prevVal) =>
            moment
                .tz(
                    tempStartDate.format('YYYY-MM-DD') + 'T00:00:01',
                    restauHook.restauTimeZone
                )
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss[Z]')
        );
    }

    useEffect(() => {
        //debugger;
        resetSearchCriteria();
    }, []);

    function setDateRange(date) {
        setTempStartDate(date);
        if (tempEndDate < date) {
            setTempEndDate(date);
        }
    }

    useEffect(() => {
        //debugger;
        loadData();
    }, [startDate, endDate]);

    useEffect(() => {
        //debugger;
        resetSearchCriteria();
    }, [restauHook.restauId]);

    return (
        <Grid
            container
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Grid item xs={12} md={2.5}>
                <DatePicker
                    fullWidth
                    size="small"
                    label="Date From"
                    value={tempStartDate}
                    autoOk
                    inputVariant="outlined"
                    margin="dense"
                    onChange={(date) => setDateRange(date)}
                    minDate={moment(getDateWithOffset(-365))}
                    maxDate={moment().add(1, 'days')}
                    selected={moment(tempStartDate)}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Grid>
            <Grid item xs={12} md={2.5}>
                <DatePicker
                    fullWidth
                    size="small"
                    label="Date To"
                    autoOk
                    value={tempEndDate}
                    inputVariant="outlined"
                    margin="dense"
                    onChange={(date) => setTempEndDate(date)}
                    minDate={moment(
                        tempStartDate ? tempStartDate : getDateWithOffset(-180)
                    )}
                    maxDate={
                        tempStartDate
                            ? moment(tempStartDate).add(30, 'days')
                            : moment().add(1, 'days')
                    }
                    //selected={moment(searchEndDate)}
                    renderInput={(props) => <TextField {...props} />}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <Button variant="contained" onClick={setSearchDates}>
                    Fetch
                </Button>
            </Grid>
        </Grid>
    );
}

SearchReportsCriteria.propTypes = {};

export default SearchReportsCriteria;
