import { Grid } from '@mui/material';
import React from 'react';
import DisplayChart from '../utils/DisplayChart';
import PropTypes from 'prop-types';

export default function CallSummaryForLastNDays(props) {
    // * uncomment below lines for some chart sample data

    // const lastNDaysData = [
    //     ['Metric', 'Number of Calls'],
    //     ['Answered Calls', 10],
    //     ['Missed Calls answered by StrideQ', 18],
    //     ['Online Order', 12],
    //     ['Banquet Inquiry', 8],
    //     ['Catering Inquiry', 7],
    //     ['Reservation Inquiry', 7],
    //     ['Store Hours Inquiry', 7],
    // ];

    const { data, chartColorPriority } = props;

    const lastNDaysOptions = {
        //title: 'Yesterday',
        sliceVisibilityThreshold: 0,
        pieHole: 0.4,
        is3D: false,
        chartArea: { width: '100%', height: '80%' },
        pieSliceText: 'value',
        colors: [...chartColorPriority],
    };

    return (
        <Grid container rowSpacing={2}>
            <Grid item xs={12}>
                {/* <Paper height="225" sx={{ p: 2 }}> */}
                {/* <Paper sx={{ p: 2 }}> */}
                {/* <Title>Summary</Title> */}
                <DisplayChart
                    chartType="PieChart"
                    width="100%"
                    height="310px"
                    data={data}
                    options={lastNDaysOptions}
                />
                {/* </Paper> */}
            </Grid>
        </Grid>
    );
}

CallSummaryForLastNDays.propTypes = {
    data: PropTypes.shape({
        today: PropTypes.array,
        yesterday: PropTypes.array,
    }),
    chartColorPriority: PropTypes.array,
};
