import React, { useState, useContext } from 'react';
import CateringSettingContext from '../CateringSettingContext';
import DialogWithContent from '../../../../components/common/DialogWithContent/DialogWithContent';
import { Divider } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const SortFieldDialog = ({
    open,
    setOpenSortFieldDialog,
    dialogTitle,
    allFields,
    setAllFields,
}) => {
    const { updateFormConfig } = useContext(CateringSettingContext);
    const [fieldsToSort, setFieldsToSort] = useState(allFields);

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        padding: '5px',
        display: 'inline-flex',
        alignItems: 'center',

        // change background colour if dragging
        background: isDragging ? 'lightgreen' : 'none',

        // styles we need to apply on draggables
        ...draggableStyle,
    });

    function handleOnDragEnd(result) {
        let tempData = [...fieldsToSort];
        const [movedItem] = tempData.splice(result.source.index, 1);
        tempData.splice(result.destination.index, 0, movedItem);
        setFieldsToSort([...tempData]);
    }

    const cancel = () => {
        setFieldsToSort(allFields);
        setOpenSortFieldDialog(false);
    };

    const saveSortedField = async () => {
        setAllFields(fieldsToSort);
        let response = await updateFormConfig({ fields: fieldsToSort });
    };

    return (
        <>
            <DialogWithContent
                open={open}
                dialogTitle={dialogTitle}
                setOpen={setOpenSortFieldDialog}
                handleSave={saveSortedField}
                handleClose={cancel}
            >
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="itemsToSort">
                        {(provided, snapshot) => (
                            <ul
                                className="itemsToSort"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{ listStyle: 'none' }}
                            >
                                {fieldsToSort.map((field, index) => {
                                    return (
                                        <Draggable
                                            key={index}
                                            draggableId={index.toString()}
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <>
                                                    <li
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided
                                                                .draggableProps
                                                                .style
                                                        )}
                                                    >
                                                        <DragIndicatorIcon />
                                                        {field.field_label}
                                                    </li>
                                                    <Divider
                                                        sx={{ margin: 0 }}
                                                    />
                                                </>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </DialogWithContent>
        </>
    );
};

export default SortFieldDialog;
