import { useMediaQuery, useTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import moment from 'moment-timezone';
import React, { useContext, useEffect } from 'react';
import { useOutlet } from 'react-router';
import { Outlet } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import { MenuInfoContext } from '../home/MenuInfoContext';
import { RestauInfoContext } from '../home/RestauInfoContext';
import { useSelectedIndex } from '../Hooks/useSelectedIndex';
import { GlobalStyle } from '../styles/GlobalStyle';
import Twilio from '../Twilio';
import WelcomeDialog from '../WelcomeDialog';
import { DashboardContext } from './DashboardContext.js';
import { useStyles } from './styles/useStyles';
//import {getRestaurantTimeZone} from '../utils/Utils';
import Utils from '../utils/Utils';

const logout = (history) => {
    var logoutConfirmation = window.confirm('Do you want to logout?');
    if (logoutConfirmation) {
        window.customerApp
            .auth()
            .signOut()
            .then(function () {
                navigate('/login');
            })
            .catch(function (error) {
                console.log('logout error');
            });
    }
};

export default function Home() {
    //debugger;
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        noSsr: true,
    });
    // const [open, setOpen] = React.useState(!isMobile);
    const [welcomeOpen, setWelcomeOpen] = React.useState(false);
    const [twilioOpen, setTwilioOpen] = React.useState(false);
    const { dashboardHook } = useContext(DashboardContext);
    const { restauHook } = useContext(RestauInfoContext);
    const selectedIndex = useSelectedIndex();
    const menuHook = useContext(MenuInfoContext);
    const [restaurantName, setRestaurantName] = React.useState('');

    useEffect(() => {
        //debugger;
        if (restauHook) {
            async function fetchTime() {
                const search = window.location.search;
                const params = new URLSearchParams(search);
                let isClover = params.get('isCloverFirst');
                console.log('isClover', isClover);
                if (isClover) {
                    const admin = window.customerApp.database();

                    let configRef = admin.ref(
                        'integrations/config/' +
                            restauHook.restauId +
                            '/billingInfo'
                    );

                    let confRef = await configRef
                        .once('value')
                        .then(function (snapshot) {
                            let result = snapshot.val();
                            if (result.appSubscription.id === '8XDEMWGCXR0QR') {
                                setTwilioOpen(true);
                                setWelcomeOpen(false);
                            } else {
                                setWelcomeOpen(true);
                            }
                            return result;
                        })
                        .catch(function (error) {
                            // setWelcomeOpen(true);
                            console.log(error);
                        });
                }
                const tz = await Utils.getRestaurantTimeZone(
                    restauHook.restauId
                );
                moment.tz.setDefault(tz);
            }
            console.log(
                'Restuarant Name::::::::::::::::::::::::::::::' +
                    restauHook.value.restauName
            );
            //setRestaurantName( restauHook.value.restauName);
            if (restauHook.value.allChain) {
                for (let c of restauHook.value.allChain) {
                    if (c.id === restauHook.restauId) {
                        setRestaurantName(
                            c.restaurantName ? c.restaurantName : c.id
                        );
                    }
                }
            }

            fetchTime();
        }
    }, [restauHook && restauHook.restauId]);

    if (!restauHook || !restauHook.value || !restauHook.value.restauName) {
        return (
            <div className={classes.root}>
                <GlobalStyle />
                <CssBaseline />
                <div>
                    <img
                        height="75px"
                        className={classes.loadingImage}
                        src="../../img/spinner.gif"
                    />
                </div>
            </div>
        );
    }

    const all = restauHook.value.allChain;
    const restauChange = (e) => {
        restauHook.onChange(e, all);
        dashboardHook.setValue({});
        for (let c of all) {
            if (c.id === e.target.value) {
                setRestaurantName(c.restaurantName ? c.restaurantName : c.id);
            }
        }

        menuHook.setValue({});
    };

    const outlet = useOutlet();
    const title = outlet?.props?.children?.props.children?.props?.title;
    const restaurant = {
        name: restauHook.value.restauName,
        currentLocation: restauHook.restauId,
        //If 'strideQOnlineOrdering' value is not present, default it to 'true'
        strideQOnlineOrdering: restauHook.strideQOnlineOrdering,
        locations: restauHook.value.allChain || [],
        onResLocationChange: restauChange,
    };

    return (
        <div className={classes.root}>
            <GlobalStyle />
            <CssBaseline />
            <Twilio
                restauId={restauHook.restauId}
                isOpen={twilioOpen}
                setOpen={setTwilioOpen}
            />
            <WelcomeDialog
                isOpen={welcomeOpen}
                setOpen={setWelcomeOpen}
                restauId={restauHook.restauId}
            />
            <Layout
                title={title}
                restaurant={restaurant}
                onLogout={() => logout(history)}
            >
                <Outlet />
            </Layout>
        </div>
    );
}
