import React from 'react';
import TablePanel from '../components/TablePanel';

function QRTable() {
    return <TablePanel />;
}

QRTable.propTypes = {};

export default QRTable;
