import axios from 'axios';
import Config from '../../../config/Config';
import { useState } from 'react';
import Utils from '../../../utils/Utils';

// const getAudioLibraryDataFromDb = async (payload) => {
//     const token = await window.customerApp.auth().currentUser.getIdToken();
//     var config = {
//         headers: {
//             token: token,
//             'Content-Type': 'application/json',
//         },
//     };

//     //function to fetch the Phone Settings from databasse
//     return await axios.post(Config.getAudioLibraryData, payload, config);
// };

const deleteAudioFileInDb = async (payload) => {
    const token = await window.customerApp.auth().currentUser.getIdToken();
    var config = {
        headers: {
            token: token,
            'Content-Type': 'application/json',
        },
    };

    //function to fetch the Phone Settings from databasse
    return await axios.post(Config.deleteAudioFileFromLibrary, payload, config);
};

const updateAudioFileInDb = async (payload) => {
    const token = await window.customerApp.auth().currentUser.getIdToken();
    var config = {
        headers: {
            token: token,
            'Content-Type': 'application/json',
        },
    };

    //function to fetch the Phone Settings from databasse
    return await axios.post(Config.updateAudioFileinLibrary, payload, config);
};

const addNewAudioFileToDb = async (
    restauId,
    audioFile,
    audioFileName,
    shared
) => {
    const token = await window.customerApp.auth().currentUser.getIdToken();
    var config = {
        headers: {
            token: token,
            'Content-Type': 'multipart/form-data',
        },
    };
    const formData = new FormData();
    formData.append('restauId', restauId);
    formData.append('fileId', Utils.getGUID());
    formData.append('displayName', audioFileName);
    formData.append('audioFile', audioFile, audioFile.name);
    formData.append('shared', shared);
    return await axios.post(Config.addNewAudioFileinLibrary, formData, config);
};

function usePhoneAudioLib() {
    const [showStatus, setShowStatus] = useState(false);
    const [statusMsg, setStatusMsg] = useState('');
    const [isError, setIsError] = useState(false);

    // const getAudioLibraryData = async (restauId) => {
    //     //debugger;
    //     let reqBody = {
    //         restauId: restauId,
    //     };

    //     let response = await getAudioLibraryDataFromDb(reqBody).catch((err) => {
    //         //debugger;
    //         console.log(err);
    //         throw new Error(err.toString());
    //     });

    //     //debugger;
    //     console.log('Response: ', response);
    //     if (response.data.status) {
    //         return response.data.data;
    //     } else {
    //         throw new Error('Failed to fetch data.');
    //     }
    // };

    const updateAudioFile = async (restauId, fileId, displayName, shared) => {
        //debugger;
        let reqBody = {
            restauId: restauId,
            fileId: fileId,
            displayName: displayName,
        };

        //send the shared value, only if the value is set
        if (shared !== undefined) {
            reqBody.shared = shared;
        }

        let response = await updateAudioFileInDb(reqBody).catch((err) => {
            //debugger;
            console.log(err);
            throw new Error(err.toString());
        });

        //debugger;
        console.log('Response: ', response);
        if (response.data.status) {
            return response.data.data;
        } else {
            throw new Error('Failed to update data.');
        }
    };

    const deleteAudioFile = async (restauId, fileId) => {
        //debugger;
        let reqBody = {
            restauId: restauId,
            fileId: fileId,
        };

        let response = await deleteAudioFileInDb(reqBody).catch((err) => {
            //debugger;
            console.log(err);
            throw new Error(err.toString());
        });

        //debugger;
        console.log('Response: ', response);
        if (response.data.status) {
            return response.data.data;
        } else {
            throw new Error('Failed to delete data.');
        }
    };

    const addNewAudioFile = async (
        restauId,
        newAudioFile,
        newAudioFileName,
        shared
    ) => {
        //debugger;

        let response = await addNewAudioFileToDb(
            restauId,
            newAudioFile,
            newAudioFileName,
            shared
        ).catch((err) => {
            //debugger;
            console.log(err);
            throw new Error(err.toString());
        });

        //debugger;
        console.log('Response: ', response);
        if (response && response.data.status) {
            return response.data.assetUrl;
        } else {
            throw new Error('Failed to save new file.');
        }
    };

    return {
        //getAudioLibraryData,
        deleteAudioFile,
        updateAudioFile,
        addNewAudioFile,
        showStatus,
        setShowStatus,
        statusMsg,
        setStatusMsg,
        isError,
        setIsError,
    };
}

export default usePhoneAudioLib;
