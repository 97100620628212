import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import navOptions from './navOptions';
import NavItems from './NavItems';

function NavBar(props) {
    const { onLogout, open, strideQOnlineOrdering } = props;
    return (
        <nav>
            <List>
                <NavItems
                    open={open}
                    onLogout={onLogout}
                    navItems={navOptions}
                    strideQOnlineOrdering={strideQOnlineOrdering}
                    level={1}
                />
            </List>
        </nav>
    );
}

NavBar.propTypes = {
    onLogout: PropTypes.func.isRequired,
    open: PropTypes.bool,
    strideQOnlineOrdering: PropTypes.bool,
};

export default NavBar;
