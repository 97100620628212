import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React, { useContext, useEffect } from 'react';
import { RestauInfoContext } from '../home/RestauInfoContext.js';
import { useStyles } from '../home/styles/useStyles';

export default function POS(props) {
    // const restauHook = props.restauHook;
    const resauInfoContext = useContext(RestauInfoContext);
    const restauHook = resauInfoContext.restauHook;
    const [posSetting, setPosSetting] = React.useState(undefined);
    const [payAtRestaurant, setPayAtRestaurant] = React.useState(undefined);
    const [isPrint, setPrint] = React.useState(false);
    const [isUnPaid, setUnPaid] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [saveMessage, setSaveMessage] = React.useState('');
    const classes = useStyles();

    async function handleChange(e) {
        console.log('target value');
        console.log(e.target.value);
        if (e.target.value === 'clover') {
            setPrint('clover');
        } else {
            setPrint('strideq');
        }
    }
    async function handleUnPaidChange(e) {
        if (e.target.value === 'unpaid') {
            setUnPaid('unpaid');
        } else {
            setUnPaid('paid');
        }
    }
    async function updateSettings(restauHook) {
        let updates = {};
        const database = window.customerApp.database();
        setSaveMessage('');
        setLoaded(true);
        let printSettings = false;
        let unPaidSetting = false;
        if (isUnPaid === 'unpaid') {
            unPaidSetting = true;
        }
        if (isPrint === 'clover') {
            printSettings = true;
        }
        updates['integrations/config/' + restauHook.restauId + '/isPrint'] =
            printSettings;
        updates['integrations/config/' + posSetting.merchantId + '/isPrint'] =
            printSettings;
        console.log(
            'update config',
            'integrations/config/' + posSetting.merchantId + '/isPrint'
        );
        console.log(updates);
        await database
            .ref()
            .update(updates)
            .then(function () {
                setSaveMessage('Succesfully updated');
            })
            .catch(function (error) {
                alert('Error while saving please try again');
                //Need to pop error someways
                console.log(error);
                setLoaded(false);

                return;
            });

        let unPaidSettingUpdate = {};
        unPaidSettingUpdate[
            'menus/' +
                restauHook.restauId +
                '/payments/thirdparty/pay_at_restaurant'
        ] = unPaidSetting;
        await database
            .ref()
            .update(unPaidSettingUpdate)
            .then(function () {
                setSaveMessage('Succesfully updated');
            })
            .catch(function (error) {
                alert('Error while saving please try again');
                //Need to pop error someways
                console.log(error);
                setLoaded(false);

                return;
            });
        setLoaded(false);
    }

    async function loadData(restauHook) {
        if (!window.customerApp.auth().currentUser) {
            navigate('/login');
            return;
        }
        const token = await window.customerApp.auth().currentUser.getIdToken();

        let config = {
            headers: {
                token: token,
            },
        };
        console.log('Restau Hook from restaurant: ' + restauHook.restauId);
        const admin = window.customerApp.database();
        let posRef = admin.ref('integrations/config/' + restauHook.restauId);
        let payAtRef = admin.ref(
            'menus/' +
                restauHook.restauId +
                '/payments/thirdparty/pay_at_restaurant'
        );
        console.log('integrations/config/' + restauHook.restauId);
        payAtRef
            .once('value')
            .then(function (snapshot) {
                let result = snapshot.val();
                //menuHook.setValue({menu:result});
                console.log('Pay At restaurant setting: ', result);
                setUnPaid(result ? 'unpaid' : 'paid');

                setLoaded(false);

                console.log(posSetting);
                return result;
            })
            .catch(function (error) {
                setLoaded(false);
                console.log(error);
            });
        const pos = await posRef
            .once('value')
            .then(function (snapshot) {
                let result = snapshot.val();
                //menuHook.setValue({menu:result});
                console.log('IntegrattionConfig: ', result);
                setPrint(result && result.isPrint ? 'clover' : 'strideq');
                if (result) {
                    setPosSetting(result);
                } else {
                    setPosSetting({});
                }
                setLoaded(false);

                console.log(posSetting);
                return result;
            })
            .catch(function (error) {
                setLoaded(false);
                console.log(error);
            });
        return;
    }
    useEffect(() => {
        setLoaded(true);
        loadData(restauHook);
    }, [restauHook.restauId]);

    return (
        <div>
            {!posSetting ? (
                <div>
                    <img
                        className={classes.loadingImage}
                        src="../../img/spinner.gif"
                    />
                </div>
            ) : (
                <>
                    <div style={{ maxWidth: 350 }}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">
                                Print Setting
                            </FormLabel>
                            <RadioGroup
                                aria-label="print1"
                                name="print1"
                                value={isPrint}
                                onChange={handleChange}
                            >
                                <FormControlLabel
                                    value="strideq"
                                    control={<Radio />}
                                    label="Print Using StrideQ"
                                />
                                <FormControlLabel
                                    value="clover"
                                    control={<Radio />}
                                    label="Print Using Clover"
                                />
                            </RadioGroup>
                            <br />
                            <FormLabel component="legend">
                                Pay At Restaurant
                            </FormLabel>
                            <RadioGroup
                                aria-label="payAtRestaurant"
                                name="payAtRestaurant"
                                value={isUnPaid}
                                onChange={handleUnPaidChange}
                            >
                                <FormControlLabel
                                    value="unpaid"
                                    control={<Radio />}
                                    label="Pay At Restaurant"
                                />
                                <FormControlLabel
                                    value="paid"
                                    control={<Radio />}
                                    label="Only Paid Orders"
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    {loaded === true ? (
                        <div>
                            <img
                                height="75px"
                                className={classes.loadingImage}
                                src="../../img/spinner.gif"
                            />
                        </div>
                    ) : (
                        <>
                            <div style={{ textAlign: 'center', maxWidth: 350 }}>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        updateSettings(restauHook);
                                    }}
                                    style={{ width: 200, marginTop: 10 }}
                                >
                                    Update
                                </Button>
                            </div>
                            <div style={{ paddingLeft: '10px' }}>
                                {saveMessage}
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
}
