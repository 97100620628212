import { isNumber, toNumber } from 'lodash';
import { useMemo } from 'react';
import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Config from '../config/Config';
import { RestauInfoContext } from '../home/RestauInfoContext';

import {
    posterPaperSizes,
    posterTypes,
    tablePaperSizes,
    serviceTypes,
    designOptions,
} from './constants';

const { default: axios } = require('axios');

async function download(payload) {
    const token = await window.customerApp.auth().currentUser.getIdToken();
    payload['token'] = token;
    if (Config.env_value == 'prod') {
        payload['env'] = 'prod';
    } else {
        payload['env'] = 'stage';
    }
    const downloadQRTemplate = Config.downloadQRTemplate;

    const res = await axios.post(downloadQRTemplate, payload);
    if (res && res.status == 200 && res.data && res.data.status == 'OK') {
        // TODO : below code requires pop up to be enabled. Is there a better way to run this.
        window.open(res.data.ppt);
    } else {
        alert('Error generating the powerpoint');
    }
    return res;
}

const getTemplates = async () => {
    const token = await window.customerApp.auth().currentUser.getIdToken();
    let config = {
        headers: {
            token: token,
            'Content-Type': 'application/json',
        },
    };
    let reqBody = {};
    const getQRTemplatesURL = Config.getQRTemplates;
    return axios.post(getQRTemplatesURL, reqBody, config);
};

const getTableTemplates = (templates = []) =>
    templates.filter(
        (template) => template.mode == 'qsr' || template.mode == 'fsr'
    );

const getPosterTemplates = (templates = []) =>
    templates.filter((template) => template.mode.startsWith('poster'));

const getFilteredTemplates = (templates = [], serviceType, paperSize) =>
    templates.filter(
        (template) =>
            template.size === paperSize && template.mode === serviceType
    );

const findTemplateByUri = (templates, uri) =>
    templates.find((t) => uri === t.previewUri);

const useQRTemplates = () => {
    const { restauHook } = useContext(RestauInfoContext);
    const location = useLocation();

    const [templates, setTemplates] = useState([]);
    const [serviceType, setServiceType] = useState(
        Object.values(serviceTypes)[0].value
    );
    const [paperSize, setPaperSize] = useState(tablePaperSizes[0].value);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [activeTab, setActiveTab] = useState('');

    // table settings
    const [startTable, setStartTable] = useState();
    const [startTableError, setStartTableError] = useState('');
    const [endTable, setEndTable] = useState();
    const [endTableError, setEndTableError] = useState('');
    const [tablePrefix, setTablePrefix] = useState();
    const [tableSuffix, setTableSuffix] = useState();
    const [orderInstruction, setOrderInstruction] = useState();
    const [trackingCode, setTrackingCode] = useState();
    const [isProcessing, setIsProcessing] = useState(false);

    const nameSetterMap = {
        startTable: setStartTable,
        endTable: setEndTable,
        tablePrefix: setTablePrefix,
        tableSuffix: setTableSuffix,
        orderInstruction: setOrderInstruction,
        trackingCode: setTrackingCode,
    };

    useEffect(() => {
        getTemplates()
            .then((res) => {
                setTemplates(res.data.qrTemplates);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const tableTemplates = useMemo(
        () => getTableTemplates(templates),
        [templates]
    );
    const posterTemplates = useMemo(
        () => getPosterTemplates(templates),
        [templates]
    );

    const filteredTemplates = useMemo(
        () => getFilteredTemplates(templates, serviceType, paperSize),
        [templates, serviceType, paperSize]
    );

    const resetState = () => {
        const designOption =
            designOptions.find((option) =>
                location.pathname.endsWith(option.value)
            ) ||
            // picks 'table' (first item) as default tab
            designOptions[0];

        const tab = designOption?.value;

        // Default initialized to tab==='table'
        let serviceTypeCurrent = Object.values(serviceTypes)[0].value;
        let paperSizeCurrent = tablePaperSizes[0].value;

        if (tab === 'poster') {
            serviceTypeCurrent = Object.values(posterTypes)[0].value;
            paperSizeCurrent = posterPaperSizes[0].value;
        }
        setActiveTab(tab);
        setServiceType(serviceTypeCurrent);
        setPaperSize(paperSizeCurrent);
    };

    useEffect(() => {
        resetState();
    }, [location.pathname]);

    useEffect(() => {
        let value = '';
        if (filteredTemplates.length) {
            value = filteredTemplates[0]?.previewUri;
        }
        setSelectedTemplate(value);
    }, [filteredTemplates]);

    const handleSettingChange = (name, value) => {
        const fn = nameSetterMap[name];
        fn(value);
    };

    const activeTemplate = findTemplateByUri(templates, selectedTemplate);

    const validateTableAttributes = () => {
        const start = toNumber(startTable);
        const end = toNumber(endTable);
        if (!startTable || isNaN(start) || !isNumber(start)) {
            setStartTableError('`Start Table` should be numeric');
            return true;
        }

        if (!endTable || isNaN(end) || !isNumber(end)) {
            setEndTableError('`End Table` should be numeric');
            return true;
        }

        if (end < start) {
            setEndTableError(
                '`End Table` should be greater than `Start Table`'
            );
            return true;
        }
        return false;
    };

    const downloadTableQRCodes = () => {
        setEndTableError('');
        setStartTableError('');
        if (validateTableAttributes()) {
            console.log('table attributes are invalid');
            return;
        }
        const powerpointArgs = {
            // Required fields
            restauId: restauHook.restauId,
            mode: serviceType,
            templateId: activeTemplate?.templateId,
            // Required fields for qsr/fsr
            startTableId: startTable,
            endTableId: endTable,
            // Optional fields for qsr/fsr
            tablePrefix: tablePrefix,
            tablePostfix: tableSuffix,
        };
        setIsProcessing(true);
        return download(powerpointArgs)
            .then(() => {
                setIsProcessing(false);
            })
            .catch((e) => {
                console.log(
                    'An error occurred when generating the powerpoint for Table QR codes.',
                    e
                );
                setIsProcessing(false);
            });
    };

    const downloadPosterQRCodes = () => {
        const powerpointArgs = {
            // Required fields
            restauId: restauHook.restauId,
            mode: serviceType,
            templateId: activeTemplate?.templateId,
            // Required fields for poster*
            orderInstruction: orderInstruction,
            utmReferrer: trackingCode,
        };
        setIsProcessing(true);
        return download(powerpointArgs)
            .then(() => {
                setIsProcessing(false);
            })
            .catch((e) => {
                console.log(
                    'An error occurred when generating the powerpoint for Poster QR Codes.',
                    e
                );
                setIsProcessing(false);
            });
    };

    const fnDownload = {
        poster: downloadPosterQRCodes,
        table: downloadTableQRCodes,
    };

    const downloadQRCodes = fnDownload[activeTab]
        ? fnDownload[activeTab]
        : () => {};

    return {
        templates,
        tableTemplates,
        posterTemplates,
        filteredTemplates,
        serviceType,
        setServiceType,
        paperSize,
        setPaperSize,
        selectedTemplate,
        setSelectedTemplate,
        startTable,
        startTableError,
        endTable,
        endTableError,
        tablePrefix,
        tableSuffix,
        orderInstruction,
        trackingCode,
        activeTemplate,
        handleSettingChange,
        downloadQRCodes,
        activeTab,
        isProcessing,
    };
};

export default useQRTemplates;
