import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import BrandingMobile from './BrandingMobile';
import BrandingDesktop from './BrandingDesktop';

function BrandingTemplate(props) {
    const { children } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        noSsr: true,
    });
    return isMobile ? (
        <BrandingMobile>{children}</BrandingMobile>
    ) : (
        <BrandingDesktop>{children}</BrandingDesktop>
    );
}

BrandingTemplate.propTypes = {};

export default BrandingTemplate;
