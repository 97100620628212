import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import Config from '../config/Config';
import { RestauInfoContext } from '../home/RestauInfoContext.js';
import { useStyles } from '../home/styles/useStyles';
import { useDineIn } from '../Hooks/useDineIn';

const styles = (theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
});

export default function DineIn(props) {
    const [loaded, setLoaded] = React.useState(false);
    const classes = useStyles();
    const dineInHook = useDineIn();
    const { restauHook } = useContext(RestauInfoContext);

    async function loadData(dineInHook, restauHook) {
        if (!window.customerApp.auth().currentUser) {
            //debugger;
            navigate('/login');
            return;
        }
        dineInHook.setValue(null);
        const token = await window.customerApp.auth().currentUser.getIdToken();

        let config = {
            headers: {
                token: token,
            },
        };
        axios
            .post(
                Config.getDineInReport,
                { restauId: restauHook.restauId },
                config
            )
            .then(function (response) {
                dineInHook.setValue(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

        return;
    }

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }
    function getAllItems(items) {
        let itms = [];

        for (let key in items) {
            itms.push(
                <tr>
                    <td>{items[key].name}</td>
                    <td>{items[key].quantity}</td>
                    <td>${items[key].price / 100}</td>
                </tr>
            );
        }
        return itms;
    }
    function getTotalOrders(order) {
        if (!order) {
            return 0;
        }
        return order.length;
    }
    function getTableData(order) {
        console.log('order');
        console.log(order);
        let orders = [];
        for (let i = 0; i < order.length; i++) {
            let ordr = order[i];
            orders.push(
                <div style={{ marginBottom: '20px' }}>
                    <b>Table:</b> {ordr.tableId}
                    <br />
                    <b>OrderId: </b>
                    {ordr.orderId}
                    <br />
                    <b>Order Time: </b>
                    {formatAMPM(new Date(parseInt(ordr.time._seconds * 1000)))}
                    <br />
                    <br />
                    <table>
                        <tr>
                            <td>
                                <b>Item</b>
                            </td>
                            <td>
                                <b>Quantity</b>
                            </td>
                            <td>
                                <b>Price</b>
                            </td>
                        </tr>
                        {getAllItems(ordr.items)}
                        <tr>
                            <td>
                                <b>{''}</b>
                            </td>
                            <td>{''}</td>
                            <td>{''}</td>
                        </tr>
                        <tr>
                            <td>
                                <b>Sub Total</b>
                            </td>
                            <td>{''}</td>
                            <td>${ordr.subTotal / 100}</td>
                        </tr>
                        <tr>
                            <td>
                                <b>Sales Tax</b>
                            </td>
                            <td>{''}</td>
                            <td>${ordr.salesTax / 100}</td>
                        </tr>
                        <tr>
                            <td>
                                <b>Tip</b>
                            </td>
                            <td>{''}</td>
                            <td>${ordr.tip ? ordr.tip / 100 : 0}</td>
                        </tr>
                        <tr>
                            <td>
                                <b> Total</b>
                            </td>
                            <td>{''}</td>
                            <td>${ordr.total / 100}</td>
                        </tr>
                    </table>
                </div>
            );
        }
        return <>{orders}</>;
    }

    if (!restauHook) {
        return <></>;
    }
    useEffect(() => {
        console.log('Dine in loading');
        setLoaded(true);
        loadData(dineInHook, restauHook);
    }, [restauHook.restauId]);

    console.log('Dine in hook value:::');
    console.log(dineInHook.value);

    return (
        <div>
            {!dineInHook.value || !dineInHook.value.dineIn ? (
                <div>
                    <img
                        className={classes.loadingImage}
                        src="../../img/spinner.gif"
                    />
                </div>
            ) : (
                <div>
                    <div className={styles.root}>
                        {Object.keys(dineInHook.value.dineIn).length == 0 && (
                            <>No Dine in data!</>
                        )}
                        {Object.keys(dineInHook.value.dineIn).length > 0 && (
                            <b>Past 7 days Dine-in report</b>
                        )}

                        {Object.keys(dineInHook.value.dineIn).map(
                            (key, index) => (
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography className={styles.heading}>
                                            {key}&nbsp;Total Orders:{' '}
                                            {getTotalOrders(
                                                dineInHook.value.dineIn[key]
                                            )}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            {getTableData(
                                                dineInHook.value.dineIn[key]
                                            )}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
