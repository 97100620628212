export const designOptions = [
    {
        value: 'table',
        label: 'Table Tents',
    },
    {
        value: 'poster',
        label: 'Poster',
    },
];

export const serviceTypes = {
    qsr: {
        value: 'qsr',
        label: 'Quick-Service',
    },
    fsr: {
        value: 'fsr',
        label: 'Full-Service',
    },
};

export const posterTypes = {
    'poster-togo': {
        value: 'poster-togo',
        label: 'Only Togo',
    },
    'poster-togo-dinein': {
        value: 'poster-togo-dinein',
        label: 'Togo + DineIn',
    },
};
export const tablePaperSizes = [
    {
        value: '4" X 6" Vertical',
        label: '4" x 6" Vertical',
    },
    {
        value: '6" X 4" Horizontal',
        label: '6" x 4" Horizontal',
    },
    {
        value: '4" X 4" Square',
        label: '4" x 4" Square',
    },
];

export const posterPaperSizes = [
    {
        value: 'Letter Size',
        label: 'Letter (8.5" × 11")',
    },
];
