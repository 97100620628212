import React from 'react';
import { useContext } from 'react';
import ExistingOverrideTable from '../components/ExistingOverrides/ExistingOverrideTable';
import VisOverrideContext from '../VisOverrideContext';

// To display the list of all the Visibility Overrides for a restaurant
function ExistingOverrides() {
    const { allOverrideData } = useContext(VisOverrideContext);

    //console.log('all existing overrides: ', existingOverridesData);
    return <ExistingOverrideTable existingOverridesData={allOverrideData} />;
}

ExistingOverrides.propTypes = {};

export default ExistingOverrides;
