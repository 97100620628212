import { Dialog } from '@mui/material';
import styled from 'styled-components';

const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: '20px', //theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '20px', //theme.spacing(1),
    },
}));

export default CustomDialog;
