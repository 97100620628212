import { useState } from 'react';
import { getUUID } from '../Menu/Utils';

export function useModifiers() {
    const [value, setValue] = useState(null); //{modifiers: [{modifier}]}
    const [convertedPrice, setConvertedPrice] = useState(null);
    const [modifiers, setModifiers] = useState(null);
    // addEditModifierId : tracks the ID for the modifier dialog box which opens
    // when we click on add / edit a modifier group.
    const [addEditModifierId, setAddEditModifierId] = useState(null);
    const [currentModifiers, setCurrentModifiers] = useState([]);
    const [draftModifier, setDraftModifier] = useState(null);

    function moveUpModifier(modifier) {
        let cmods = [...currentModifiers];
        console.log('Current modifiers are..');
        console.log(cmods);

        let indx = 0;
        for (let i = 0; i < cmods.length; i++) {
            let mod = cmods[i];
            if (modifier.id === mod) {
                indx = i;
            }
        }
        if (indx > 0) {
            let t = cmods[indx - 1];
            cmods[indx - 1] = cmods[indx];
            cmods[indx] = t;
        }
        setCurrentModifiers(cmods);
    }
    function moveDownModifier(modifier) {
        let cmods = [...currentModifiers];
        console.log('Current modifiers are..');
        console.log(cmods);

        let indx = 0;
        for (let i = 0; i < cmods.length; i++) {
            let mod = cmods[i];
            if (modifier.id === mod) {
                indx = i;
            }
        }
        if (indx + 1 < cmods.length) {
            let t = cmods[indx + 1];
            cmods[indx + 1] = cmods[indx];
            cmods[indx] = t;
        }
        setCurrentModifiers(cmods);
    }

    function deleteCurrentModifier(modifier) {
        let cmods = [...currentModifiers];
        let newMods = [];
        for (let mod of cmods) {
            if (modifier.id !== mod) {
                newMods.push(mod);
            }
        }
        setCurrentModifiers(newMods);
    }
    function updateCurrentModifiers(isEdit, modifierIndx, modifier) {
        let cmods = [];
        console.log('updating');
        console.log(currentModifiers);
        console.log(modifier);
        console.log('Is Edit' + isEdit);
        console.log(modifierIndx);

        cmods = [...currentModifiers];
        if (!isEdit) {
            cmods.push(modifier.id);
        } else {
            cmods[modifierIndx] = modifier.id;
        }
        setCurrentModifiers(cmods);
    }

    function addConvertedPrice(group) {}

    function refreshConvertedPrice(modifiers) {
        if (!modifiers) {
            setConvertedPrice({});
            return;
        }
        let optionPrice = {};
        for (let mod in modifiers) {
            let modifier = modifiers[mod];
            if (!modifier.options) {
                continue;
            }
            for (let option of modifier.options) {
                if (!option.price) {
                    optionPrice[modifier.id + option.id] = option.price;
                } else {
                    let p = parseFloat(option.price) / 100;
                    optionPrice[modifier.id + option.id] = p.toFixed(2);
                }
            }
        }
        setConvertedPrice(optionPrice);
    }

    function addModifier() {
        let newModifiers = { ...modifiers };
        const modId = getUUID();
        const optionId = getUUID();
        let options = [{ label: '', id: optionId, quantity: 1, price: 0 }];
        let modifier = { name: '', label: '', id: modId, options: options };
        if (modifiers) {
            newModifiers[modId] = {};
            newModifiers[modId] = modifier;
        } else {
            newModifiers = {};
            newModifiers[modId] = {};
            newModifiers[modId] = modifier;
        }
        setModifiers(newModifiers);

        return modifier;
    }

    function changeModifier(modifierId, type, e) {
        let newModifiers = { ...modifiers };
        console.log('modifierId');
        console.log(modifierId);
        console.log(newModifiers[modifierId]);
        let modifier = newModifiers[modifierId];
        if (modifier.id === modifierId) {
            if (type === 'MIN') {
                if (
                    e.target.value &&
                    e.target.value.trim() !== '' &&
                    !isNaN(e.target.value)
                ) {
                    modifier.min = parseInt(e.target.value);
                } else {
                    modifier.min = null;
                }
            }
            if (type === 'MAX') {
                if (
                    e.target.value &&
                    e.target.value.trim() !== '' &&
                    !isNaN(e.target.value)
                ) {
                    modifier.max = parseInt(e.target.value);
                } else {
                    modifier.max = null;
                }
            } else if (type === 'LABEL') {
                modifier.label = e.target.value;
            } else if (type === 'NAME') {
                modifier.name = e.target.value;
            }
        }

        setModifiers(newModifiers);
    }

    function deleteAllModifier(modifierId) {
        const res = window.confirm('Are you sure you want to delete modifier');
        if (!res) {
            return;
        }
        const newModifierGrp = { ...value };
        let modifiers = [];
        for (let modifier of newModifierGrp.modifiers) {
            if (modifier.id !== modifierId) {
                modifiers.push(modifier);
            }
        }
        newModifierGrp.modifiers = Object.values(modifiers);
        setValue(newModifierGrp);
    }

    function addOptionLast(modifierId) {
        const newModifierGrp = { ...value };
        for (let modifier of newModifierGrp.modifiers) {
            if (modifier.id === modifierId) {
                const id = getUUID();
                let newOptn = {
                    id: id,
                    label: '',
                    checked: false,
                    quantity: 1,
                    price: 0,
                };
                modifier.options.push(newOptn);
                let conv = { ...convertedPrice };
                conv[modifierId + id] = 0;
                setConvertedPrice(conv);
            }
        }
        setValue(newModifierGrp);
    }
    function addOption(modifierId) {
        console.log('Adding new option');
        console.log(modifierId);
        let newModifiers = { ...modifiers };
        let modifier = newModifiers[modifierId];
        console.log(modifier);
        if (modifier.id === modifierId) {
            const id = getUUID();

            let newOptn = {
                id: id,
                label: '',
                checked: false,
                quantity: 1,
                price: 0,
            };
            if (!modifier.options) {
                modifier.options = [];
            }
            modifier.options.push(newOptn);
        }

        setModifiers(newModifiers);
    }
    function changeValue(modifierId, optionId, type, e) {
        let newModifiers = { ...modifiers };
        let modifier = newModifiers[modifierId];

        if (modifier.id === modifierId) {
            let newOptions = [];
            for (let option of modifier.options) {
                if (option.id === optionId) {
                    if (type === 'PRICE') {
                        let convertPrice = { ...convertedPrice };
                        convertPrice[modifierId + optionId] = e.target.value;
                        setConvertedPrice(convertPrice);
                        option.price = Math.round(e.target.value * 100);
                    } else if (type === 'LABEL') {
                        option.label = e.target.value;
                    }
                }
                newOptions.push(option);
            }
            modifier.options = Object.values(newOptions);
        }

        setModifiers(newModifiers);
    }
    function deleteModifier(modifierId) {
        let newModifiers = { ...modifiers };
        // delete newModifiers[modifierId];
        setModifiers(newModifiers);
    }
    function deleteOption(modifierId, optionId) {
        let newModifiers = { ...modifiers };
        let isEmptyModifier = false;
        let modifier = newModifiers[modifierId];
        if (modifier.id === modifierId) {
            let newOptions = [];
            for (let option of modifier.options) {
                if (option.id !== optionId) {
                    newOptions.push(option);
                }
            }
            if (newOptions.length == 0) {
                isEmptyModifier = true;
                modifier.options = Object.values(newOptions);
            } else {
                modifier.options = Object.values(newOptions);
            }
        }

        if (isEmptyModifier) {
            deleteModifier(modifierId);
        } else {
            setModifiers(newModifiers);
        }
    }

    function changeCheckBox(modifierId, optionId) {
        let newModifiers = { ...modifiers };
        let modifier = newModifiers[modifierId];
        if (modifier.id === modifierId) {
            let newOptions = [];
            for (let option of modifier.options) {
                if (option.id === optionId) {
                    option.checked = !option.checked;
                }
                newOptions.push(option);
            }
            modifier.options = Object.values(newOptions);
        }

        setModifiers(newModifiers);
    }
    function changeOptional(modifierId) {
        let newModifiers = { ...modifiers };
        let modifier = newModifiers[modifierId];
        if (modifier.id === modifierId) {
            modifier.isOptional = !modifier.isOptional;
        }

        setModifiers(newModifiers);
    }
    function setOptionHiddenValue(modifierId, optionId) {
        let newModifiers = { ...modifiers };
        let modifier = newModifiers[modifierId];
        if (modifier.id === modifierId) {
            let newOptions = [];
            for (let option of modifier.options) {
                if (option.id === optionId) {
                    option.hide = !option.hide;
                }
                newOptions.push(option);
            }
            modifier.options = Object.values(newOptions);
        }

        setModifiers(newModifiers);
    }
    function setOptionOOSValue(modifierId, optionId) {
        let newModifiers = { ...modifiers };
        let modifier = newModifiers[modifierId];
        if (modifier.id === modifierId) {
            let newOptions = [];
            for (let option of modifier.options) {
                if (option.id === optionId) {
                    option.oos = !option.oos;
                }
                newOptions.push(option);
            }
            modifier.options = Object.values(newOptions);
        }

        setModifiers(newModifiers);
    }

    function getCurrentAddEditModifier() {
        return modifiers ? modifiers[addEditModifierId] : null;
    }

    return {
        value,
        setValue,
        changeCheckBox,
        addOption,
        deleteOption,
        changeValue,
        changeModifier,
        addModifier,
        deleteAllModifier,
        convertedPrice,
        setConvertedPrice,
        refreshConvertedPrice,
        addOptionLast,
        modifiers,
        setModifiers,
        currentModifiers,
        setCurrentModifiers,
        updateCurrentModifiers,
        deleteCurrentModifier,
        moveUpModifier,
        moveDownModifier,
        draftModifier,
        setDraftModifier,
        changeOptional,
        setOptionHiddenValue,
        setOptionOOSValue,
        addEditModifierId,
        setAddEditModifierId,
        getCurrentAddEditModifier,
    };
}
