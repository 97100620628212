import React, { useState } from 'react';
import SlotsConfig from './SlotsConfig';

import { useSlotList } from './hooks/useSlotList';
import { useHoliday } from './hooks/useHoliday';

const createPayload = (date, holiday, slots) => {
    const datePattern = 'MMDDYYYY';
    const timePattern = 'hh:mm A';

    if (!date) {
        return null;
    }

    const key = date ? date.format(datePattern) : '';
    const hours = slots
        .filter((item) => item.start && item.end)
        .filter((item) => item.start.isBefore(item.end))
        .map((item) => ({
            start: item.start.format(timePattern),
            end: item.end.format(timePattern),
        }));

    // For a holiday, the slots will be empty
    // For a NON-holiday, the slots must be non-empty
    if (holiday === false && hours.length === 0) {
        return null;
    }

    const updated = {
        holiday,
    };
    if (!holiday) {
        updated.hours = hours;
    }

    return { [key]: updated };
};

const defaultDate = null;
function SlotsConfigContainer(props) {
    const [configDate, setConfigDate] = useState(defaultDate);
    const {
        list,
        handleRemoveSlot,
        handleAddSlot,
        handleChangeSlot,
        handleResetSlot,
    } = useSlotList();
    const { holiday, toggleHoliday, resetHoliday } = useHoliday();
    const { onUpdateConfig } = props;

    const handleResetContent = () => {
        handleResetSlot();
        resetHoliday();
        setConfigDate(defaultDate);
    };

    const handleUpdateContent = () => {
        const payload = createPayload(configDate, holiday, list);
        if (payload) {
            onUpdateConfig(payload);
            handleResetContent();
        }
    };

    return (
        <SlotsConfig
            list={list}
            holiday={holiday}
            configDate={configDate}
            disabled={holiday}
            onChangeDate={(date) => setConfigDate(date)}
            onRemoveSlot={handleRemoveSlot}
            onAddSlot={handleAddSlot}
            onChangeSlot={handleChangeSlot}
            onToggleHoliday={toggleHoliday}
            onUpdate={handleUpdateContent}
            onReset={handleResetContent}
        />
    );
}

SlotsConfigContainer.propTypes = {};

export default SlotsConfigContainer;
