import moment from 'moment';

import { useSlotList } from './useSlotList';
import { useHoliday } from './useHoliday';

export const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];

const parseTime = (time) => {
    return moment(time, 'HH:mm a');
};

const formatTimeObject = ({ start, end }) => {
    return {
        start: parseTime(start),
        end: parseTime(end),
    };
};

export const prepareState = (config) => {
    const updated = {};
    days.forEach((name, index) => {
        const day = {};
        const hours = config[name];
        day.holiday = !hours;
        if (hours) {
            day.hours = hours.map((slot) => formatTimeObject(slot)); // [{start:"9:00 AM", end: "10:30 AM"}]
        }
        updated[name] = day;
    });
    return updated;
};

export function useRegularHours(current = {}) {
    const formatted = prepareState(current);

    const config = {};
    days.forEach((day) => {
        const slotList = useSlotList(formatted[day].hours);
        const holidayConfig = useHoliday(formatted[day].holiday);
        config[day] = {
            ...slotList,
            ...holidayConfig,
        };
    });

    return { config };
}
