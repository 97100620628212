import React from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from 'react';
import SwitchWithLabel from '../../../../components/common/SwitchWithLabel';
import TextFieldWithMaxCharacters from '../../../../components/common/TextFieldWithMaxCharacters';
import SayPlayMessageDetails from './SayPlayMessage/SayPlayMessageDetails';
import Utils from '../../../../utils/Utils';
import { useContext } from 'react';
import PhoneSettingsContext from '../PhoneSettingsContext';
import ConfirmationDialog from './ConfirmationDialog';
import { cloneDeep, isEqual } from 'lodash';

function CustomMenuOptionDetails(props) {
    const { custMenuOptionData } = props;
    const {
        updatePhoneSettings,
        uploadAndUpdatePlayMessage,
        setAccordionExpanded,
    } = useContext(PhoneSettingsContext);
    const [isProcessing, setIsProcessing] = useState(false);
    const [uploadedPlayFile, setUploadedPlayFile] = useState({});
    const [errMsg, setErrMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [tempGreetMsgDetails, setTempGreetMsgDetails] = useState([]);
    const [enableGreetMsg, setEnableGreetMsg] = useState(false);
    const [tempTxtMsgToCust, setTempTxtMsgToCust] = useState('');
    const [enableTxtMsgToCust, setEnableTxtMsgToCust] = useState(false);
    const [tempEmailMsgBodyToOwner, setTempEmailMsgBodyToOwner] = useState('');
    const [tempEmailMsgSubToOwner, setTempEmailMsgSubToOwner] = useState('');
    const [tempOwnerEmail, setTempOwnerEmail] = useState('');
    const [enableEmailMsgToOwner, setEnableEmailMsgToOwner] = useState(false);
    const [tempTxtMsgToOwner, setTempTxtMsgToOwner] = useState('');
    const [tempOwnerPhNumber, setTempOwnerPhNumber] = useState('');
    const [enableTxtMsgToOwner, setEnableTxtMsgToOwner] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    //console.log('custMenuOptionData', custMenuOptionData);

    const resetCustomWidgetData = () => {
        //debugger;
        if (
            custMenuOptionData.greetingMessage.enabled === enableGreetMsg &&
            isEqual(
                custMenuOptionData.greetingMessage.message,
                tempGreetMsgDetails
            ) &&
            custMenuOptionData.textMsgToCustomer.enabled ===
                enableTxtMsgToCust &&
            custMenuOptionData.textMsgToCustomer.textContent ===
                tempTxtMsgToCust &&
            custMenuOptionData.emailMsgToOwner.enabled ===
                enableEmailMsgToOwner &&
            custMenuOptionData.emailMsgToOwner.emailContent ===
                tempEmailMsgBodyToOwner &&
            custMenuOptionData.emailMsgToOwner.emailSubject ===
                tempEmailMsgSubToOwner &&
            custMenuOptionData.txtMsgToOwner.enabled === enableTxtMsgToOwner &&
            custMenuOptionData.txtMsgToOwner.textContent ===
                tempTxtMsgToOwner &&
            custMenuOptionData.txtMsgToOwner.toPhNumber === tempOwnerPhNumber
        ) {
            //close the accordion
            setAccordionExpanded(false);
        } else {
            setShowConfirmationDialog(true);
        }
    };

    const resetData = () => {
        setErrMsg('');
        setSuccessMsg('');
        //let tempData = JSON.parse(JSON.stringify(custMenuOptionData));
        let tempData = cloneDeep(custMenuOptionData);
        if (tempData.greetingMessage) {
            setTempGreetMsgDetails([...tempData.greetingMessage.message]);
            setEnableGreetMsg(tempData.greetingMessage.enabled);
        }
        if (tempData.textMsgToCustomer) {
            setTempTxtMsgToCust(tempData.textMsgToCustomer.textContent);
            setEnableTxtMsgToCust(tempData.textMsgToCustomer.enabled);
        }
        if (tempData.emailMsgToOwner) {
            setEnableEmailMsgToOwner(tempData.emailMsgToOwner.enabled);
            setTempEmailMsgBodyToOwner(tempData.emailMsgToOwner.emailContent);
            setTempEmailMsgSubToOwner(tempData.emailMsgToOwner.emailSubject);
            setTempOwnerEmail(tempData.emailMsgToOwner.toEmail);
        }
        if (tempData.txtMsgToOwner) {
            setTempTxtMsgToOwner(tempData.txtMsgToOwner.textContent);
            setTempOwnerPhNumber(tempData.txtMsgToOwner.toPhNumber);
            setEnableTxtMsgToOwner(tempData.txtMsgToOwner.enabled);
        }
    };

    const handleDiscardChanges = () => {
        resetData();
        setAccordionExpanded(false);
    };

    const validatePhoneNumber = (phNumber) => {
        let formattedPhNumber = Utils.getPhoneNumberInE164Format(phNumber);
        //console.log('Formatted to:', formattedPhNumber);
        if (formattedPhNumber.length !== 12) {
            return false;
        }
        setTempOwnerPhNumber(formattedPhNumber);
        return true;
    };

    const validateEmail = (emailList) => {
        //debugger;
        return emailList
            .split(',')
            .every((em) => Utils.isEmailValid(em.trim()));
    };

    const saveCustomMenuOptionDetails = async () => {
        //validate Text Message to Owner
        let isTxtMsgToOwnerDetailsValid = true;
        if (enableTxtMsgToOwner && !validatePhoneNumber(tempOwnerPhNumber)) {
            isTxtMsgToOwnerDetailsValid = false;
            setErrMsg('Notification Phone Number is not Valid.');
            return;
        }
        if (enableTxtMsgToOwner && tempTxtMsgToOwner === '') {
            isTxtMsgToOwnerDetailsValid = false;
            setErrMsg('Notification Text Message to Owner cannot be empty.');
            return;
        }
        //validate Email Message to Owner
        let isEmailMsgToOwnerDetailsValid = true;
        if (enableEmailMsgToOwner && !validateEmail(tempOwnerEmail)) {
            isEmailMsgToOwnerDetailsValid = false;
            setErrMsg('Notification Email is not Valid.');
            return;
        }
        if (enableEmailMsgToOwner && setTempEmailMsgSubToOwner === '') {
            isEmailMsgToOwnerDetailsValid = false;
            setErrMsg('Notification Email Subject cannot be empty.');
            return;
        }
        if (enableEmailMsgToOwner && tempEmailMsgBodyToOwner === '') {
            isEmailMsgToOwnerDetailsValid = false;
            setErrMsg('Notification Email Message to Owner cannot be empty.');
            return;
        }
        //validate Greeting Message
        let isGreetMsgDetailsValid = true;
        if (
            enableGreetMsg &&
            (tempGreetMsgDetails.length === 0 ||
                tempGreetMsgDetails[0].type === undefined)
        ) {
            isGreetMsgDetailsValid = false;
            setErrMsg('Greeting Message Details are not Valid.');
            return;
        }
        //validate Text Message to Customer
        let isTxtMsgToCustDetailsValid = true;
        if (enableTxtMsgToCust && tempTxtMsgToCust === '') {
            isTxtMsgToCustDetailsValid = false;
            setErrMsg('Notification Text Message to Customer cannot be empty.');
            return;
        }

        if (
            isTxtMsgToOwnerDetailsValid &&
            isEmailMsgToOwnerDetailsValid &&
            isGreetMsgDetailsValid &&
            isTxtMsgToCustDetailsValid
        ) {
            //debugger;
            setIsProcessing(true);
            setErrMsg('');
            let temp;
            if (enableGreetMsg && tempGreetMsgDetails) {
                temp = JSON.parse(JSON.stringify(tempGreetMsgDetails));
                //if its say, make sure that the playfilepaths are deleted
                if (temp[temp.length - 1].type === 'say') {
                    temp.forEach((msg) => {
                        delete msg.playFilePath;
                    });
                }
            }
            //debugger;
            let body = {
                [custMenuOptionData.name]: {
                    message: temp,
                    notificationText: {
                        toNumber: tempOwnerPhNumber,
                        message: tempTxtMsgToOwner,
                    },
                    notificationEmail: {
                        toEmail: tempOwnerEmail,
                        subject: tempEmailMsgSubToOwner,
                        body: tempEmailMsgBodyToOwner,
                    },
                    sendText: tempTxtMsgToCust,
                },
            };

            if (!enableGreetMsg) {
                delete body[custMenuOptionData.name].message;
            }
            if (!enableEmailMsgToOwner) {
                delete body[custMenuOptionData.name].notificationEmail;
            }
            if (!enableTxtMsgToOwner) {
                delete body[custMenuOptionData.name].notificationText;
            }
            if (!enableTxtMsgToCust) {
                delete body[custMenuOptionData.name].sendText;
            }

            //setIsProcessing(true);
            updatePhoneSettings(
                body,
                setErrMsg,
                setSuccessMsg,
                setIsProcessing
            );
        }
    };

    useEffect(() => {
        resetData();
    }, []);

    return (
        <>
            <ConfirmationDialog
                showConfirmationDialog={showConfirmationDialog}
                setShowConfirmationDialog={setShowConfirmationDialog}
                handleConfirm={handleDiscardChanges}
            />
            <Grid container rowSpacing={3} xs={12} md={10}>
                <Grid item xs={12} style={{ display: 'flex', gap: '1rem' }}>
                    <Typography variant="h6" xs={6}>
                        Notification Message to Owner
                    </Typography>
                    <SwitchWithLabel
                        xs={6}
                        id="switchForTxtMsgToOwner"
                        size="small"
                        name="switch-for-txtmsg-to-owner"
                        label="Enabled"
                        checked={enableTxtMsgToOwner}
                        onChangeHandler={(checked) =>
                            setEnableTxtMsgToOwner(checked)
                        }
                    />
                </Grid>
                {enableTxtMsgToOwner && (
                    <Grid item xs={12} style={{ display: 'flex', gap: '1rem' }}>
                        <TextField
                            id="catNotificationNumber"
                            label="Notification Number"
                            value={tempOwnerPhNumber}
                            size="md"
                            onChange={(e) =>
                                setTempOwnerPhNumber(e.target.value)
                            }
                        />
                        <Grid item xs={12}>
                            <TextFieldWithMaxCharacters
                                label="Notification Message"
                                fullWidth={true}
                                id={'txtField-msg-to-owner'}
                                multiline={true}
                                rows={4}
                                size={'medium'}
                                sx={{
                                    borderRadius: '8px',
                                    flexDirection: 'column',
                                }}
                                txtMessage={tempTxtMsgToOwner}
                                setTxtMessage={setTempTxtMsgToOwner}
                                disabled={false}
                            />
                        </Grid>
                    </Grid>
                )}
                <Divider style={{ width: '100%' }} />
                <Grid item xs={12} style={{ display: 'flex', gap: '1rem' }}>
                    <Typography variant="h6">
                        Notification Email to Owner
                    </Typography>
                    <SwitchWithLabel
                        xs={6}
                        id="switchForEmailToOwner"
                        size="small"
                        name="switch-for-email-to-owner"
                        label="Enabled"
                        checked={enableEmailMsgToOwner}
                        onChangeHandler={(checked) =>
                            setEnableEmailMsgToOwner(checked)
                        }
                    />
                </Grid>
                {enableEmailMsgToOwner && (
                    <Grid item xs={12} style={{ display: 'flex', gap: '1rem' }}>
                        <Grid container columnSpacing={2}>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth={true}
                                    key={`email-filed`}
                                    label="Email Id"
                                    value={tempOwnerEmail}
                                    size="md"
                                    multiline={true}
                                    rows={3}
                                    onChange={(e) =>
                                        setTempOwnerEmail(e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth={true}
                                    label="Email Subject"
                                    id={'txtField-email-subject'}
                                    multiline={true}
                                    rows={3}
                                    size={'medium'}
                                    sx={{ borderRadius: '8px' }}
                                    value={tempEmailMsgSubToOwner}
                                    onChange={(e) =>
                                        setTempEmailMsgSubToOwner(
                                            e.target.value
                                        )
                                    }
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth={true}
                                    label="Email Body"
                                    id={'txtField-email-to-owner'}
                                    multiline={true}
                                    rows={4}
                                    size={'medium'}
                                    sx={{ borderRadius: '8px' }}
                                    value={tempEmailMsgBodyToOwner}
                                    onChange={(e) =>
                                        setTempEmailMsgBodyToOwner(
                                            e.target.value
                                        )
                                    }
                                    disabled={false}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Divider style={{ width: '100%' }} />
                <Grid item xs={12} style={{ display: 'flex', gap: '1rem' }}>
                    <Typography variant="h6">Greeting Message</Typography>
                    <SwitchWithLabel
                        xs={6}
                        id="switchForGreetMsg"
                        size="small"
                        name="switch-for-greet-msg"
                        label="Enabled"
                        checked={enableGreetMsg}
                        onChangeHandler={(checked) =>
                            setEnableGreetMsg(checked)
                        }
                    />
                </Grid>
                {enableGreetMsg && (
                    <Grid item xs={12}>
                        {
                            tempGreetMsgDetails && (
                                // tempGreetMsgDetails.map((x) => (
                                <SayPlayMessageDetails
                                    //key={x.name}
                                    msgDetails={tempGreetMsgDetails}
                                    setMsgDetails={setTempGreetMsgDetails}
                                    uploadedPlayFile={uploadedPlayFile}
                                    setUploadedPlayFile={setUploadedPlayFile}
                                    originalMsgDetails={
                                        custMenuOptionData.greetingMessage
                                            .message
                                    }
                                />
                            )
                            // ))
                        }
                    </Grid>
                )}
                <Divider style={{ width: '100%' }} />
                <Grid item xs={12} style={{ display: 'flex', gap: '1rem' }}>
                    <Typography variant="h6">Message to customer</Typography>
                    <SwitchWithLabel
                        xs={6}
                        id="switchForMsgToCust"
                        size="small"
                        name="switch-for-msg-to-cust"
                        label="Enabled"
                        checked={enableTxtMsgToCust}
                        onChangeHandler={(checked) =>
                            setEnableTxtMsgToCust(checked)
                        }
                    />
                </Grid>
                {enableTxtMsgToCust && (
                    <Grid item xs={12}>
                        <TextFieldWithMaxCharacters
                            fullWidth={true}
                            id={'txtField-msg-to-customer'}
                            multiline={true}
                            rows={4}
                            size={'medium'}
                            sx={{
                                borderRadius: '8px',
                                flexDirection: 'column',
                            }}
                            txtMessage={tempTxtMsgToCust}
                            setTxtMessage={setTempTxtMsgToCust}
                            disabled={false}
                        />
                    </Grid>
                )}

                {errMsg !== '' ? (
                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{ color: 'red' }}>
                            {errMsg}
                        </Typography>
                    </Grid>
                ) : (
                    successMsg !== '' && (
                        <Grid item xs={12}>
                            <Typography variant="body1" sx={{ color: 'green' }}>
                                {successMsg}
                            </Typography>
                        </Grid>
                    )
                )}
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                    }}
                >
                    <Button variant="outlined" onClick={resetCustomWidgetData}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ ml: 2 }}
                        onClick={saveCustomMenuOptionDetails}
                        disabled={isProcessing}
                    >
                        {isProcessing && <CircularProgress size={24} />}
                        Save
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

CustomMenuOptionDetails.propTypes = {
    custMenuOptionData: PropTypes.shape({
        label: PropTypes.string,
        name: PropTypes.string,
        greetingMessage: PropTypes.shape({
            enabled: PropTypes.bool,
            message: PropTypes.arrayOf(
                PropTypes.shape({ playFilePath: PropTypes.string })
            ),
        }),
        textMsgToCustomer: PropTypes.shape({
            enabled: PropTypes.bool,
            textContent: PropTypes.string,
        }),
        txtMsgToOwner: PropTypes.shape({
            enabled: PropTypes.bool,
            textContent: PropTypes.string,
            toPhNumber: PropTypes.string,
        }),
        emailMsgToOwner: PropTypes.shape({
            enabled: PropTypes.bool,
            emailContent: PropTypes.string,
            emailSubject: PropTypes.string,
            toEmail: PropTypes.arrayOf(PropTypes.string),
        }),
    }),
};

export default CustomMenuOptionDetails;
