import React, { useState, useContext } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {
    TableCell,
    TableRow,
    Typography,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import CateringContext from '../../../../CateringContext';
import formatPrice from '../../../../../utils/formatPrice';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const FoodItemRow = ({
    index,
    item,
    setOpenFood,
    setOpenFoodDialog,
    setOpenCustomFoodDialog,
    setKeyToEdit,
    orders,
    dispatchOrder,
    updateMenuSelection,
    updatePricing,
}) => {
    const { setSnackbarOpen, setSnackbarMessage } = useContext(CateringContext);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const handleEdit = () => {
        setKeyToEdit(item.key);
        if (item.isCustom) {
            setOpenFood(item);
            setOpenCustomFoodDialog(true);
        } else {
            setOpenFood(item);
            setOpenFoodDialog(true);
        }
    };

    const handleDelete = async () => {
        let newOrders = cloneDeep(orders);
        delete newOrders.items[item.key];
        newOrders = updatePricing(newOrders, newOrders.items);
        try {
            // send to DB first before updating state
            await updateMenuSelection(newOrders);

            dispatchOrder({ type: 'UPDATE_ALL', newOrders });
            setOpenDeleteDialog(false);
        } catch (e) {
            console.log('Error deleting order: ', e);
            setSnackbarMessage(
                'Issue deleting item. Please contact support if the issue persists.'
            );
            setSnackbarOpen(true);
        }
    };

    const checkDelete = () => {
        setOpenDeleteDialog(true);
    };

    return (
        <>
            <TableRow>
                <TableCell align="center">
                    <Typography variant="body2">
                        {item.quantity}
                        <Typography variant="caption">&nbsp;x</Typography>
                    </Typography>
                </TableCell>

                <TableCell align="center">
                    <Typography variant="body2"> {item.name} </Typography>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {item.modifiersStr && (
                            <Typography variant="caption">
                                {item.modifiersStr}
                            </Typography>
                        )}
                        {item.choice && (
                            <Typography variant="caption">
                                {item.choice}
                            </Typography>
                        )}
                    </div>
                    {item.notes && (
                        <>
                            <i
                                className="fas fa-info-circle"
                                style={{ marginRight: 2 }}
                            ></i>
                            <Typography variant="caption">
                                {item.notes}
                            </Typography>
                        </>
                    )}
                </TableCell>

                <TableCell align="center">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            variant="body2"
                            sx={{ textDecoration: 'line-through' }}
                        >
                            {item.original_price &&
                                item.original_price !== item.price &&
                                formatPrice(
                                    (item.quantity * item.original_price) / 100
                                )}
                        </Typography>
                        <Typography variant="body2">
                            {formatPrice((item.quantity * item.price) / 100)}
                        </Typography>
                    </div>
                </TableCell>

                <TableCell align="right">
                    <IconButton
                        onClick={handleEdit}
                        color="primary"
                        sx={{ padding: 0.2, mr: 5 }}
                    >
                        <EditIcon sx={{ fontSize: 13 }} />
                        <Typography sx={{ fontSize: 13 }}> Edit </Typography>
                    </IconButton>
                    <IconButton
                        onClick={checkDelete}
                        color="primary"
                        sx={{ padding: 0.2, mr: 5 }}
                    >
                        <RemoveCircleIcon sx={{ fontSize: 13 }} />
                        <Typography sx={{ fontSize: 13 }}> Delete </Typography>
                    </IconButton>
                </TableCell>
            </TableRow>

            <Dialog open={openDeleteDialog} fullWidth>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you want to delete {item.name}?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={() => {
                            setOpenDeleteDialog(false);
                        }}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        autoFocus
                        onClick={handleDelete}
                        variant="contained"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default FoodItemRow;
