import { useState } from 'react';
import axios from 'axios';
import Config from '../config/Config';
import { useContext } from 'react';
import { RestauInfoContext } from '../home/RestauInfoContext';
//import {getRestaurantTimeZone} from '../utils/Utils';
import Utils from '../utils/Utils';

const moment = require('moment-timezone');

const defaultCallSummaryByDay = [
    [
        'Day',
        'Online Order',
        'Missed Calls answered by StrideQ',
        'Answered Calls',
        'Banquet Inquiry',
        'Catering Inquiry',
        'Reservation Inquiry',
    ],
    ['Sunday', 0, 0, 0, 0, 0, 0],
    ['Monday', 0, 0, 0, 0, 0, 0],
    ['Tuesday', 0, 0, 0, 0, 0, 0],
    ['Wednesday', 0, 0, 0, 0, 0, 0],
    ['Thursday', 0, 0, 0, 0, 0, 0],
    ['Friday', 0, 0, 0, 0, 0, 0],
    ['Staurday', 0, 0, 0, 0, 0, 0],
];

const defaultCallSummaryByHour = [
    [
        'Hour',
        'Online Order',
        'Missed Calls answered by StrideQ',
        'Answered Calls',
        'Banquet Inquiry',
        'Catering Inquiry',
        'Reservation Inquiry',
    ],
    ['00', 0, 0, 0, 0, 0, 0],
    ['02', 0, 0, 0, 0, 0, 0],
    ['05', 0, 0, 0, 0, 0, 0],
    ['11', 0, 0, 0, 0, 0, 0],
    ['15', 0, 0, 0, 0, 0, 0],
    ['18', 0, 0, 0, 0, 0, 0],
    ['19', 0, 0, 0, 0, 0, 0],
    ['22', 0, 0, 0, 0, 0, 0],
];

const defaultCallSummaryPast2Days = [
    ['Metric', 'Number of Calls'],
    ['Answered Calls', 0],
    ['Missed Calls answered by StrideQ', 0],
    ['Online Order', 0],
    ['Banquet Inquiry', 0],
    ['Catering Inquiry', 0],
];

export function useCallSummary() {
    const [callSummary, setCallSummary] = useState({});
    const [callSummaryByDay, setCallSummaryByDay] = useState([]);
    const [callSummaryByHour, setCallSummaryByHour] = useState([]);
    const [callSummaryLast2Days, setCallSummaryLast2Days] = useState({});
    const [callSummaryLastNDays, setCallSummaryLastNDays] = useState({});
    const [callSummaryByDayLoading, setCallSummaryByDayLoading] =
        useState(true);
    const [callSummaryByHourLoading, setCallSummaryByHourLoading] =
        useState(true);
    const [callSummaryLast2DaysLoading, setCallSummaryLast2DaysLoading] =
        useState(true);
    const [pastRestauId, setPastRestauId] = useState({});

    const { restauHook } = useContext(RestauInfoContext);
    //const { startDate, endDate } = useContext(PhoneReportsContext);

    var restauTimeZone = '';
    var startTimeStamp = '';
    var endTimeStamp = '';
    var reportTillDate;
    var reportDataTillDate = '';
    var strideQSavedCallCount = 0;
    var avgCallDuration = 5;

    //Dharma: need to handle this dynamically.
    var metricLabelsMappingForDisplay = {
        Pressed_2_Connected: 'Answered Calls',
        Pressed_2_But_Busy: 'Missed Calls answered by StrideQ',
        Pressed_1: 'Online Order',
        Pressed_Banquet: 'Banquet Inquiry',
        Pressed_Catering: 'Catering Inquiry',
        Pressed_Reservation: 'Reservation Inquiry',
        Pressed_Store_hours: 'Store Hours Inquiry',
    };

    async function loadData(startDate, endDate) {
        /* let metricDataToDisplay = [
            'Pressed_1',
            'Pressed_2_Connected',
            'Pressed_2_But_Busy',
            'Pressed_Catering',
            'Pressed_Store_hours',
            'Pressed_Banquet',
        ]; */
        //debugger;
        if (
            !restauHook ||
            !restauHook.restauId ||
            Object.keys(restauHook.subscriptionPlan).length === 0 ||
            !window.customerApp.auth().currentUser
        ) {
            return;
        }
        if (!startDate || !endDate) {
            return;
        }
        // if (restauHook.restauId === pastRestauId) {
        //     return;
        // }

        restauTimeZone = await Utils.getRestaurantTimeZone(restauHook.restauId); //'America/Los_Angeles'; //restauInfoHook.restauTimeZone;
        if (!restauTimeZone) restauTimeZone = 'America/Los_Angeles';
        console.log('Restaurant TimeZone', restauTimeZone);

        reportTillDate = endDate && moment(endDate).tz(restauTimeZone);

        endTimeStamp = endDate;
        //debugger;
        reportDataTillDate =
            reportTillDate && reportTillDate.format('M/D/YYYY');
        console.log('EndTimeStamp for Report', endTimeStamp);
        startTimeStamp = startDate;
        console.log('StartTimeStamp for Report', startTimeStamp);

        setPastRestauId(restauHook.restauId);
        setCallSummaryByDayLoading(true);
        setCallSummaryByHourLoading(true);
        setCallSummaryLast2DaysLoading(true);
        //setCallSummaryByDay([]);
        //setCallSummaryByHour({});
        const token = await window.customerApp.auth().currentUser.getIdToken();

        let config = {
            headers: {
                token: token,
            },
        };
        //debugger;

        var customPlanPackages = {};
        //debugger;
        restauHook &&
            restauHook.subscriptionPlan &&
            restauHook.subscriptionPlan.productPlanPackages &&
            restauHook.subscriptionPlan.productPlanPackages.custom &&
            Object.keys(
                restauHook.subscriptionPlan.productPlanPackages.custom
            ).forEach((x) => {
                customPlanPackages[x] =
                    restauHook.subscriptionPlan.productPlanPackages.custom[
                        x
                    ].label;
            });

        //Load call Summary Data By Day
        loadCallSummaryDataByDay(
            config,
            Object.keys(metricLabelsMappingForDisplay),
            customPlanPackages
        );

        //Call Summary Data By Hour
        loadCallSummaryDataByHour(
            config,
            Object.keys(metricLabelsMappingForDisplay),
            customPlanPackages
        );

        //Past 2 Days Call Summary Data
        loadCallSummaryDataForLast2Days(
            config,
            Object.keys(metricLabelsMappingForDisplay),
            customPlanPackages
        );
    }

    async function loadCallSummaryDataByDay(
        config,
        metricDataToDisplay,
        customPlanPackages
    ) {
        // const date = '2019-04-10T20:30:00';
        // const zone = 'Asia/Kuala_Lumpur';
        // const utcDate = moment.tz(date, zone).utc().format();
        // console.log('UTC Date : ', utcDate);

        let reqBody = {
            startDate: startTimeStamp, //'2022-03-14T07:00:01Z', //'2022-03-21T00:00:01Z', //
            endDate: endTimeStamp, //'2022-04-22T06:59:59Z', //'2022-03-29T23:59:59Z', //
            restauId: restauHook.restauId, //'hdbmilpitas',
        };
        console.log('Call Summary Data by Day loading...........');
        console.log('customPlanPackages: ', customPlanPackages);
        //debugger;
        try {
            let response = await axios.post(
                Config.getCallRecordsSummaryByDay,
                reqBody,
                config
            );
            //console.log('API Response', response.data);
            //debugger;
            let callSummaryDataForChart = [];
            let callSummaryDataLastNDaysForChart = {};
            let noOfAnsweredCalls = 0;
            let totalNoOfCalls = 0;

            //if there is no call summary data, display the default data
            if (Object.keys(response.data).length === 0) {
                callSummaryDataForChart.push(...defaultCallSummaryByDay);
            } else {
                let headerData = {};
                Object.keys(metricLabelsMappingForDisplay).forEach((metric) => {
                    if (
                        Object.values(response.data).some(
                            (data) => data[metric]
                        )
                    ) {
                        headerData[metric] =
                            metricLabelsMappingForDisplay[metric];
                    }
                });
                //append the custom plan packages
                if (customPlanPackages) {
                    Object.keys(customPlanPackages).forEach((x) => {
                        let metric = 'Pressed_custom_' + x;
                        headerData[metric] = customPlanPackages[x];
                    });
                }
                console.log('Header Data: ', headerData);
                callSummaryDataForChart.push([
                    'Day',
                    ...Object.values(headerData),
                ]);

                //debugger;
                for (let key in response.data) {
                    //console.log('Processing..', key);
                    //Compute the total number of calls, ignoring "UnKnown"
                    //deduct that with "Pressed_2_Connected", that should give the CallsImpactedByStrideQ
                    noOfAnsweredCalls =
                        noOfAnsweredCalls +
                        //***************************
                        //NOTE: the calculation goes wrong if the parathesis is not present
                        //uncomment the start and close paranthesis, if the calculation needs to be fixed.
                        (response.data[key]['Pressed_2_Connected']
                            ? response.data[key]['Pressed_2_Connected']
                            : 0);
                    //***************************

                    for (let y in response.data[key]) {
                        //do not consider 'unknown' in totalNoOfCalls
                        if (y !== 'Unknown') {
                            totalNoOfCalls =
                                totalNoOfCalls + response.data[key][y];
                        }
                    }
                    // pressed1CallCount =
                    //     pressed1CallCount +
                    //     (response.data[key]['Pressed_1']
                    //         ? response.data[key]['Pressed_1']
                    //         : 0) +
                    //     (response.data[key]['Pressed_2_But_Busy']
                    //         ? response.data[key]['Pressed_2_But_Busy']
                    //         : 0);
                    let arr = [key];
                    //debugger;
                    Object.keys(headerData).forEach((metric) => {
                        arr.push(
                            response.data[key][metric]
                                ? response.data[key][metric]
                                : 0
                        );

                        if (response.data[key][metric]) {
                            if (
                                callSummaryDataLastNDaysForChart[
                                    headerData[metric]
                                ]
                            ) {
                                callSummaryDataLastNDaysForChart[
                                    headerData[metric]
                                ] += response.data[key][metric];
                            } else {
                                callSummaryDataLastNDaysForChart[
                                    headerData[metric]
                                ] = response.data[key][metric];
                            }
                        }
                    });
                    callSummaryDataForChart.push(arr);
                }
            }

            //compute the calls which made impact by StrideQ
            strideQSavedCallCount = totalNoOfCalls - noOfAnsweredCalls;

            console.log('Call Summary By Day', callSummaryDataForChart);
            console.log('strideQSavedCallCount: ', strideQSavedCallCount);
            console.log('noOfAnsweredCalls: ', noOfAnsweredCalls);
            console.log('totalNoOfCalls: ', totalNoOfCalls);
            console.log(
                'Pressed1CallTimeSavings :',
                strideQSavedCallCount * avgCallDuration
            );
            setCallSummary({
                tillDate: reportDataTillDate, //yesterdayDate.format('DD-MM-YYYY'),
                strideQSavedCallCount: strideQSavedCallCount,
                connectedToResCallCount: noOfAnsweredCalls,
                totalNoOfCalls: totalNoOfCalls,
                pressed1CallTimeSavings:
                    strideQSavedCallCount * avgCallDuration,
            });
            setCallSummaryByDay(callSummaryDataForChart);
            setCallSummaryLastNDays(() => {
                let data = [['Metric', 'Number of Calls']];
                Object.keys(callSummaryDataLastNDaysForChart).forEach((key) => {
                    data.push([key, callSummaryDataLastNDaysForChart[key]]);
                });
                return data;
            });
            setCallSummaryByDayLoading(false);
        } catch (error) {
            console.log(error);
            setCallSummaryByDayLoading(false);
        }
    }

    async function loadCallSummaryDataByHour(
        config,
        metricDataToDisplay,
        customPlanPackages
    ) {
        let reqBody = {
            startDate: startTimeStamp, //'2022-03-14T07:00:01Z', //'2022-03-21T00:00:01Z', //
            endDate: endTimeStamp, //'2022-04-22T06:59:59Z', //'2022-03-29T23:59:59Z', //
            restauId: restauHook.restauId, //'hdbmilpitas',
        };
        console.log('Call Summary Data by Hour loading...........');

        try {
            let response = await axios.post(
                Config.getCallRecordsSummaryByHour,
                reqBody,
                config
            );
            //console.log('API Response', response.data);

            let callSummaryDataForChart = [];

            //if there is no call summary data, display the default data
            if (Object.keys(response.data).length === 0) {
                callSummaryDataForChart.push(...defaultCallSummaryByHour);
            } else {
                let headerData = {};
                Object.keys(metricLabelsMappingForDisplay).forEach((metric) => {
                    if (
                        Object.values(response.data).some(
                            (data) => data[metric]
                        )
                    ) {
                        headerData[metric] =
                            metricLabelsMappingForDisplay[metric];
                    }
                });
                //append the custom plan packages
                if (customPlanPackages) {
                    Object.keys(customPlanPackages).forEach((x) => {
                        let metric = 'Pressed_custom_' + x;
                        headerData[metric] = customPlanPackages[x];
                    });
                }
                //console.log('Header Data: ', headerData);
                callSummaryDataForChart.push([
                    'Hour',
                    ...Object.values(headerData),
                ]);
                //debugger;
                let orderedKeys = Object.keys(response.data).sort(function (
                    a,
                    b
                ) {
                    //console.log('Sorting: ', a, Number(a), b, Number(b));
                    return Number(a) < Number(b) ? -1 : 1;
                });

                orderedKeys.forEach((key) => {
                    let timeKey = moment(key, 'h:mm:ss').format('h A');
                    let arr = [timeKey];
                    Object.keys(headerData).forEach((metric) => {
                        arr.push(
                            response.data[key][metric]
                                ? response.data[key][metric]
                                : 0
                        );
                    });
                    // metricDataToDisplay.forEach((metric) => {
                    //     arr.push(
                    //         response.data[key][metric]
                    //             ? response.data[key][metric]
                    //             : 0
                    //     );
                    // });

                    // //process custom plan packages here
                    // if (
                    //     Object.keys(response.data).length !== 0 &&
                    //     customPlanPackages
                    // ) {
                    //     Object.keys(customPlanPackages).forEach((pkg) => {
                    //         let cusMetric = 'Pressed_custom_' + pkg;
                    //         arr.push(
                    //             response.data[key][cusMetric]
                    //                 ? response.data[key][cusMetric]
                    //                 : 0
                    //         );
                    //     });
                    // }

                    callSummaryDataForChart.push(arr);
                });
            }

            console.log('Call Summary By Hour', callSummaryDataForChart);
            setCallSummaryByHour(callSummaryDataForChart);
            setCallSummaryByHourLoading(false);
        } catch (error) {
            console.log(error);
            setCallSummaryByHourLoading(false);
        }
    }

    async function loadCallSummaryDataForLast2Days(
        config,
        metricDataToDisplay,
        customPlanPackages
    ) {
        console.log('Call Summary Data for Last 2 days...........');

        // let reqBody = {
        //     startDate: '2022-04-21T07:00:01Z',
        //     endDate: '2022-04-22T06:59:59Z',
        //     restauId: restauInfoHook.restauId, //'hdbmilpitas',
        // };

        let reqBody = {
            startDate: moment
                .tz(
                    reportTillDate.format('YYYY-MM-DD') + 'T00:00:01',
                    restauTimeZone
                )
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss[Z]'),
            endDate: moment
                .tz(
                    reportTillDate.format('YYYY-MM-DD') + 'T23:59:59',
                    restauTimeZone
                )
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss[Z]'),
            restauId: restauHook.restauId,
        };

        console.log('Call Summary Data for yesterday loading...........');
        let todaysCallSummaryDataForChart = await getCallSummaryChartDataForDay(
            config,
            reqBody,
            metricDataToDisplay,
            customPlanPackages
        );
        console.log(
            'Call Summary Data for yesterday',
            todaysCallSummaryDataForChart
        );

        // reqBody = {
        //     startDate: '2022-04-20T07:00:01Z',
        //     endDate: '2022-04-21T06:59:59Z',
        //     restauId: restauInfoHook.restauId, //'hdbmilpitas',
        // };

        let dayBeforeYesterday = reportTillDate.subtract(1, 'days');
        reqBody = {
            startDate: moment
                .tz(
                    dayBeforeYesterday.format('YYYY-MM-DD') + 'T00:00:01',
                    restauTimeZone
                )
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss[Z]'),
            endDate: moment
                .tz(
                    dayBeforeYesterday.format('YYYY-MM-DD') + 'T23:59:59',
                    restauTimeZone
                )
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss[Z]'),
            restauId: restauHook.restauId,
        };
        console.log(
            'Call Summary Data for day before yesterday loading...........'
        );
        let yesterdaysCallSummaryDataForChart =
            await getCallSummaryChartDataForDay(
                config,
                reqBody,
                metricDataToDisplay,
                customPlanPackages
            );
        console.log(
            'Call Summary Data for day before yesterday',
            yesterdaysCallSummaryDataForChart
        );

        setCallSummaryLast2Days({
            today: todaysCallSummaryDataForChart,
            yesterday: yesterdaysCallSummaryDataForChart,
        });
        setCallSummaryLast2DaysLoading(false);
    }

    async function getCallSummaryChartDataForDay(
        config,
        reqBody,
        metricDataToDisplay,
        customPlanPackages
    ) {
        let callSummaryDataForChart = [];
        let response = await axios.post(
            Config.getCallRecordsSummary,
            reqBody,
            config
        );
        if (Object.keys(response.data).length === 0) {
            callSummaryDataForChart.push(...defaultCallSummaryPast2Days);
        } else {
            //headers
            callSummaryDataForChart.push(['Metric', 'Number of Calls']);

            //console.log('API Response', response.data);
            //debugger;
            for (let key in metricLabelsMappingForDisplay) {
                if (Object.keys(response.data).includes(key)) {
                    let labelForDisplay = metricLabelsMappingForDisplay[key];
                    callSummaryDataForChart.push([
                        labelForDisplay ? labelForDisplay : key,
                        response.data[key] ? response.data[key] : 0,
                    ]);
                }
            }
            //process customer plan packages here
            for (let pkg in customPlanPackages) {
                let labelForDisplay = customPlanPackages[pkg];
                let key = 'Pressed_custom_' + pkg;
                callSummaryDataForChart.push([
                    labelForDisplay ? labelForDisplay : pkg,
                    response.data[key] ? response.data[key] : 0,
                ]);
            }
        }

        return callSummaryDataForChart;
    }

    return {
        callSummary,
        callSummaryByDay,
        callSummaryByHour,
        callSummaryLast2Days,
        callSummaryByDayLoading,
        callSummaryByHourLoading,
        callSummaryLast2DaysLoading,
        setCallSummaryByDayLoading,
        setCallSummaryByHourLoading,
        setCallSummaryLast2DaysLoading,
        setCallSummaryByDay,
        setCallSummaryByHour,
        setCallSummaryLast2Days,
        loadData,
        callSummaryLastNDays,
        setCallSummaryLastNDays,
    };
}
