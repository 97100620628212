import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import { days, useRegularHours } from './hooks/useRegularHours';
import RegularHoursContainer from './RegularHoursContainer';

const getColor = (index) => {
    if (index % 2 === 0) {
        return '#8fc49f';
    } else {
        return '#cfe3d5';
    }
};

const formatDaySchedule = (slots, holiday) => {
    const timePattern = 'hh:mm A';

    if (holiday) {
        return null;
    }

    const hours = slots
        .filter((item) => item.start && item.end)
        .filter((item) => item.start.isBefore(item.end))
        .map((item) => ({
            start: item.start.format(timePattern),
            end: item.end.format(timePattern),
        }));

    return hours.length ? hours : null;
};

const formatWeekSchedule = (weekSchedule) => {
    const formatted = {};
    Object.keys(weekSchedule).forEach((day) => {
        const dayConfig = weekSchedule[day];
        const schedule = formatDaySchedule(dayConfig.list, dayConfig.holiday);
        if (schedule) {
            formatted[day] = schedule;
        }
    });

    return formatted;
};

function RegularHours(props) {
    const { regularHours, onUpdateConfig } = props;
    const { config } = useRegularHours(regularHours);

    const handleUpdateContent = () => {
        const payload = formatWeekSchedule(config);
        if (payload) {
            onUpdateConfig(payload);
        }
    };

    return (
        <div>
            <br />
            <Typography variant="h5">Delivery Hours</Typography>
            {days.map(function (name, index) {
                return (
                    <div
                        key={name}
                        style={{ backgroundColor: getColor(index) }}
                    >
                        <RegularHoursContainer
                            day={name}
                            config={config[name]}
                        />
                    </div>
                );
            })}
            <div style={{ textAlign: 'center', paddingTop: 15 }}>
                <Button
                    onClick={handleUpdateContent}
                    size="small"
                    variant="contained"
                    color="primary"
                >
                    Update
                </Button>
            </div>
        </div>
    );
}

RegularHours.propTypes = {};
RegularHours.defaultProps = {
    regularHours: {},
};

export default RegularHours;
