import React from 'react';
import {
    createTheme,
    Grid,
    TextField,
    ThemeProvider,
    Typography,
} from '@mui/material';
import OverrideRuleDisplay from './OverrideRuleDisplay';
import { useEffect } from 'react';
import OverrideRuleSelections from './OverrideRuleSelections';
import { useContext } from 'react';
import VisOverrideContext from '../../VisOverrideContext';

//To "Add New" or "Edit existing" Override
function OverrideDataEditor() {
    const {
        openVisOverrideData,
        setOpenVisOverrideData,
        openOverrideName,
        setOpenOverrideName,
        resetOpenVisOverrideData,
    } = useContext(VisOverrideContext);

    const addNewOverrideDialogTheme = createTheme({
        typography: {
            htmlFontSize: 8,
            fontSize: 8,
            body1: {
                fontSize: '.85rem',
            },
        },
    });
    //console.log('Open Visibility Override: ', openVisOverrideData);

    useEffect(() => {
        resetOpenVisOverrideData();
    }, []);

    return (
        <ThemeProvider theme={addNewOverrideDialogTheme}>
            <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                    <Grid item sx={{ m: 1 }}>
                        <TextField
                            fullWidth
                            id="overrideName"
                            label="Choose Name"
                            value={openOverrideName}
                            variant="outlined"
                            size="small"
                            onChange={(e) => {
                                setOpenOverrideName(e.target.value);
                                setOpenVisOverrideData({
                                    ...openVisOverrideData,
                                    name: e.target.value,
                                });
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <OverrideRuleSelections
                        visbilityOverrideData={openVisOverrideData}
                        setVisbilityOverrideData={setOpenVisOverrideData}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <Typography>Overview</Typography>
                    <br />
                    <Typography>
                        Name:
                        {openVisOverrideData && openVisOverrideData.name}
                    </Typography>
                    <br />
                    {openVisOverrideData &&
                        openVisOverrideData.rules &&
                        openVisOverrideData.rules.map((rule) => (
                            <>
                                <OverrideRuleDisplay
                                    key={rule.id}
                                    id={rule.id}
                                    overrideRuleData={rule}
                                />
                                <br />
                            </>
                        ))}
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}

OverrideDataEditor.propTypes = {};

export default OverrideDataEditor;
