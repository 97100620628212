import React from 'react';
import PropTypes from 'prop-types';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import ExistingOverrideRow from './ExistingOverrideRow';
// import { useContext } from 'react';
// import VisOverrideContext from '../VisOverrideContext';

//The table to display the list of all the visibility overrides at Restaurant level
function ExistingOverrideTable(props) {
    const { existingOverridesData } = props;
    //console.log('Display Existing overrides: ', existingOverridesData);
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table" sx={{ width: '100%' }}>
                <TableHead>
                    <TableRow>
                        <TableCell width="auto">Name</TableCell>
                        <TableCell align="center">Item/Category Name</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {existingOverridesData &&
                        Object.keys(existingOverridesData).map((ovId) => {
                            return (
                                <ExistingOverrideRow
                                    overrideRowData={
                                        existingOverridesData[ovId]
                                    }
                                    key={ovId}
                                />
                            );
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

ExistingOverrideTable.propTypes = {
    existingOverridesData: PropTypes.shape({}),
};

export default ExistingOverrideTable;
