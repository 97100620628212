import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import React from 'react';

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, width }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: width,
        width: `calc(100% - ${width}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
function AppTitleBar(props) {
    const { open, drawerWidth, onDrawerOpen, children } = props;
    return (
        <AppBar
            color="inherit"
            position="fixed"
            open={open}
            width={drawerWidth}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={onDrawerOpen}
                    edge="start"
                    sx={{ mr: 2, ...(open && { display: 'none' }) }}
                >
                    <MenuIcon />
                </IconButton>

                {children}
            </Toolbar>
        </AppBar>
    );
}

AppTitleBar.propTypes = {
    children: PropTypes.node,
    drawerWidth: PropTypes.number.isRequired,
    open: PropTypes.bool,
    onDrawerOpen: PropTypes.func.isRequired,
};

AppTitleBar.defaultProps = {
    children: null,
    open: false,
};

export default AppTitleBar;
