import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import { RestauInfoContext } from '../../home/RestauInfoContext';
import Config from '../../config/Config';
import axios from 'axios';
import SortIcon from '@mui/icons-material/Sort';
import SortItemsInDialog from './SortItemsInDialog';
import AddPopularItem from './AddPopularItem';
import FoodCardNew from './FoodCardNew';
import { useStyles } from '../../home/useStyles';

export default function PopularItems(props) {
    const classes = useStyles();
    const { restauHook } = useContext(RestauInfoContext);
    const [popularItemData, setPopularItemData] = useState([]);
    const [tempPopularItemData, setTempPopularItemData] = useState([]);
    const [openDialogToSort, setOpenDialogToSort] = useState(false);
    const [openDialogToAddFoodItem, setOpenDialogToAddFoodItem] =
        useState(false);
    const [loading, setLoading] = useState(false);

    async function refreshPopularItems() {
        //debugger;
        loadData(restauHook.restauId);
    }

    async function loadData(restauId) {
        setLoading(true);
        const token = await window.customerApp.auth().currentUser.getIdToken();
        var config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };

        let reqBody = {
            restauId: restauId,
        };

        //function to fetch the popularitems from databasse
        let response = await axios
            .post(Config.getPopularItemsInMenu, reqBody, config)
            .catch(function (error) {
                console.log(error);
            });
        if (response && response.data) {
            //console.log('Response>>: ' + JSON.stringify(response.data));
            if (response.data && response.data.status === undefined) {
                setPopularItemData([...response.data]);
            }
        }
        setLoading(false);
    }

    function displayDialogToSortItems() {
        setTempPopularItemData([...popularItemData]);
        setOpenDialogToSort(true);
    }

    async function handleAddPopularItems(addedItemList) {
        setLoading(true);
        const token = await window.customerApp.auth().currentUser.getIdToken();
        var config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };

        let reqBody = {
            restauId: restauHook.restauId,
            popularItemIds: [...popularItemData, ...addedItemList].map(
                (foodItem) => foodItem.id
            ),
        };

        //function to load the twilio data for this restaurant id
        let response = await axios
            .post(Config.updatePopularItemsInMenu, reqBody, config)
            .catch(function (error) {
                console.log(error);
            });
        if (response && response.data) {
            //console.log('Response: ' + JSON.stringify(response.data));
            if (response.data) {
                setPopularItemData([...popularItemData, ...addedItemList]);
                refreshPopularItems();
            }
        }
        setLoading(false);
    }

    async function handleDeletePopularItem(foodId) {
        //debugger;
        //console.log('FoodId to remove from Popular items: ', foodId);
        let updatedFoodItemList = popularItemData.filter(
            (item) => item.id !== foodId
        );
        const token = await window.customerApp.auth().currentUser.getIdToken();
        var config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };

        let reqBody = {
            restauId: restauHook.restauId,
            popularItemIds: updatedFoodItemList.map((item) => item.id),
        };

        //function to update the popular items in the databases
        let response = await axios
            .post(Config.updatePopularItemsInMenu, reqBody, config)
            .catch(function (error) {
                console.log(error);
            });
        if (response && response.data) {
            //console.log('Response: ' + JSON.stringify(response.data));
            if (response.data) {
                setPopularItemData([...updatedFoodItemList]);
                refreshPopularItems();
            }
        }
    }

    async function handleSortItemSave() {
        //debugger;
        //when the sort dialog is closed
        setLoading(true);
        const token = await window.customerApp.auth().currentUser.getIdToken();
        var config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };
        let reqBody = {
            restauId: restauHook.restauId,
            popularItemIds: [...tempPopularItemData].map(
                (foodItem) => foodItem.id
            ),
        };
        //function to update the popular items in database
        let response = await axios
            .post(Config.updatePopularItemsInMenu, reqBody, config)
            .catch(function (error) {
                console.log(error);
            });
        if (response && response.data) {
            //console.log('Response: ' + JSON.stringify(response.data));
            if (response.data) {
                //setPopularItemData([...tempPopularItemData]);
                refreshPopularItems();
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        //console.log('Loading data..');
        loadData(restauHook.restauId);
    }, []);

    return (
        <>
            <SortItemsInDialog
                open={openDialogToSort}
                setOpen={setOpenDialogToSort}
                itemDataToSort={tempPopularItemData}
                setItemDataToSort={setTempPopularItemData}
                restauId={restauHook.restauId}
                handleSave={handleSortItemSave}
            />
            <AddPopularItem
                open={openDialogToAddFoodItem}
                setOpen={setOpenDialogToAddFoodItem}
                popularItemData={popularItemData}
                setPopularItemData={setPopularItemData}
                restauId={restauHook.restauId}
                handleSave={handleAddPopularItems}
            />
            <Accordion key="popularItems" defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id="popularItems-header"
                    aria-controls="popularItems-content"
                    style={{ backgroundColor: '#eee' }}
                >
                    <div
                        style={{
                            display: 'flex',
                            gap: 4,
                            color: '#666',
                            height: 30,
                            alignItems: 'baseline', // 'center',
                        }}
                    >
                        <Typography variant="h6">Popular Items</Typography>
                    </div>
                </AccordionSummary>

                {loading ? (
                    <div>
                        <img
                            className={classes.loadingImage}
                            src="../../img/spinner.gif"
                        />
                    </div>
                ) : (
                    <AccordionDetails
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            paddingLeft: 5,
                            paddingRight: 5,
                        }}
                    >
                        <Button
                            aria-label="sort items"
                            onClick={displayDialogToSortItems}
                            sx={{
                                alignSelf: 'flex-end',
                                border: 0,
                                display: 'flex',
                                alignItems: 'center',
                                padding: 0,
                            }}
                        >
                            <SortIcon sx={{ fontSize: '22px' }} /> Sort Items
                        </Button>

                        <Grid
                            container
                            direction={'row'}
                            spacing={2}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            {popularItemData &&
                                popularItemData.map((foodItem, i) => {
                                    return (
                                        <Grid
                                            item
                                            sm={6}
                                            md={6}
                                            lg={4}
                                            key={foodItem && foodItem.id}
                                            style={{
                                                width: '100%',
                                                minHeight: '7vw',
                                            }}
                                        >
                                            <FoodCardNew
                                                data={foodItem}
                                                editMode={true}
                                                showWasPrice={true}
                                                handleDeletePopularItem={
                                                    handleDeletePopularItem
                                                }
                                            />
                                        </Grid>
                                    );
                                })}
                            <Grid item sm={6} md={6} lg={4}>
                                <Button
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: 1,
                                        height: '95%',
                                        minWidth: '30%',
                                    }}
                                    onClick={() => {
                                        setOpenDialogToAddFoodItem(true);
                                    }}
                                >
                                    <AddCircleSharpIcon
                                        color="primary"
                                        sx={{ fontSize: 40 }}
                                    />
                                    <Typography style={{ marginTop: '10px' }}>
                                        Add Item
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                )}
            </Accordion>
        </>
    );
}
