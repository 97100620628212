import React from 'react';
import QRPaperSizes from './QRPaperSizes';
import Preview from './QRPreview';

import Grid from '@mui/material/Grid';
import QRTableSettings from './QRTableSettings';
import QRTemplates from './QRTemplates';

import QRServiceTypes from './QRServiceTypes';
import { tablePaperSizes } from '../constants';
import { serviceTypes } from '../constants';

function TablePanel() {
    return (
        <Grid
            container
            columnSpacing={4}
            sx={{ justifyContent: 'space-between' }}
        >
            <Grid item sx={{ flexGrow: 1 }} xs={12} md={7}>
                <Grid container rowSpacing={3}>
                    <Grid item xs={12}>
                        <Grid container columnSpacing={1}>
                            <Grid item xs={6}>
                                <QRServiceTypes
                                    label={'Service Type'}
                                    serviceTypes={serviceTypes}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <QRPaperSizes paperSizes={tablePaperSizes} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <QRTemplates />
                    </Grid>
                    <Grid item xs={12}>
                        <QRTableSettings />
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                sx={{
                    flexGrow: 0,
                }}
                xs={12}
                md={5}
            >
                <Preview />
            </Grid>
        </Grid>
    );
}

TablePanel.propTypes = {};

export default TablePanel;
