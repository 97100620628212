import React from 'react';
import CateringSearchContent from '../Components/CateringSearchContent';
import CateringSearchContext from '../CateringSearchContext';
import useCateringSearch from '../useCateringSearch';

const CateringSearchRoot = () => {
    const cateringSearchHook = useCateringSearch();
    return (
        <CateringSearchContext.Provider value={cateringSearchHook}>
            <CateringSearchContent />
        </CateringSearchContext.Provider>
    );
};

export default CateringSearchRoot;
