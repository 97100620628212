import { Button, Grid, Stack } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { MenuInfoContext } from '../../home/MenuInfoContext';
import { RestauInfoContext } from '../../home/RestauInfoContext';
import ExcelHandling from '../ExcelHandling/ExcelHandling';
import SelectFoodTemplate from '../SelectFoodTemplate';

export default function MenuSettings() {
    const [template, setTemplate] = React.useState('');
    const menuHook = useContext(MenuInfoContext);
    const { restauHook } = useContext(RestauInfoContext);

    function templateOnChange(e, menuHook) {
        let val = menuHook.value;
        val.menu.template = e.target.value;
        menuHook.setValue(val);
        setTemplate(val.menu.template);
    }

    async function updateTemplate() {
        let updates = {};
        const database = window.customerApp.database();

        updates['menus/' + restauHook.restauId + '/template'] = template;
        console.log(updates);
        await database
            .ref()
            .update(updates)
            .then(function () {})
            .catch(function (error) {
                alert('Error while saving please try again');
                //Need to pop error someways
                console.log(error);

                return;
            });
    }

    useEffect(() => {
        //console.log(restauHook);
        setTemplate(menuHook.value.template);
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stack spacing={2} direction="row">
                    <SelectFoodTemplate
                        template={template}
                        onTemplateChange={(e) => {
                            templateOnChange(e, menuHook);
                        }}
                    />

                    <Button
                        onClick={updateTemplate}
                        size="small"
                        color="primary"
                        variant="contained"
                        style={{ maxHeight: '50px' }}
                    >
                        {'Update Template'}
                    </Button>
                </Stack>
            </Grid>
            <Grid item>
                <ExcelHandling
                    restauId={restauHook.restauId}
                    refreshMenu={menuHook.refreshData}
                />
            </Grid>
        </Grid>
    );
}
