import React, { useState } from 'react';
import FormRoot from '../formControls/FormRoot';
import TitleBlock from '../formControls/TitleBlock';
import ACHPendingForm from './ACHPendingForm';
import ACHSuccessForm from './ACHSuccessForm';
import AddCardPendingForm from './AddCardPendingForm';
import { Alert, Checkbox, Grid, Typography } from '@mui/material';
import HelpText from '../formControls/HelpText';
import PropTypes from 'prop-types';
function LinkAccountForm(props) {
    const {
        achSuccess,
        loaded,
        loginMessage,
        navigate,
        accept,
        setAccept,
        signup,
        successMessage,
        restaurantName,
        setLoginMessage,
        clientSecret,
        cardSuccess,
        setCardSuccess,
        sendStatus,
        setLoaded,
        ownerFullName,
    } = props;

    const [paymentMode, setPaymentMode] = useState('');

    return (
        <FormRoot>
            <TitleBlock
                title="Welcome to StrideQ!"
                subtitle={ownerFullName}
                subtitle2={restaurantName}
            />

            {achSuccess || cardSuccess ? (
                <ACHSuccessForm navigate={navigate} achSuccess={achSuccess} />
            ) : (
                <>
                    <Grid item sx={{ padding: '5px' }}>
                        <Typography>
                            To make sure the payout we need a send a micro
                            deposit and verify transfer. StrideQ needs 0.51$
                            cents transaction.
                        </Typography>
                        <Checkbox
                            value={accept}
                            onClick={(e) => {
                                setAccept(e.target.checked);
                                setLoginMessage(null);
                            }}
                        />
                        Accept
                    </Grid>
                    <Grid item sx={{ padding: '5px' }}>
                        {loginMessage && (
                            <Alert
                                severity="error"
                                style={{ marginBottom: '15px' }}
                            >
                                {loginMessage}
                            </Alert>
                        )}
                    </Grid>
                    <Grid item sx={{ paddingTop: '40px' }}>
                        <ACHPendingForm
                            loginMessage={loginMessage}
                            successMessage={successMessage}
                            loaded={loaded}
                            signup={signup}
                            accept={accept}
                            setAccept={setAccept}
                            setPaymentMode={setPaymentMode}
                        />

                        <AddCardPendingForm
                            successMessage={successMessage}
                            loaded={loaded}
                            accept={accept}
                            setLoginMessage={setLoginMessage}
                            clientSecret={clientSecret}
                            setCardSuccess={setCardSuccess}
                            sendStatus={sendStatus}
                            setLoaded={setLoaded}
                            setPaymentMode={setPaymentMode}
                        />
                    </Grid>
                    <HelpText />
                </>
            )}
        </FormRoot>
    );
}

LinkAccountForm.propTypes = {
    achSuccess: PropTypes.bool,
    loaded: PropTypes.bool,
    loginMessage: PropTypes.string,
    navigate: PropTypes.func,
    accept: PropTypes.bool,
    setAccept: PropTypes.func,
    signup: PropTypes.func,
    successMessage: PropTypes.string,
    restaurantName: PropTypes.string,
    confirmCardPayment: PropTypes.func,
    setLoginMessage: PropTypes.func,
    clientSecret: PropTypes.string,
    cardSuccess: PropTypes.bool,
    setCardSuccess: PropTypes.func,
    sendStatus: PropTypes.func,
    setLoaded: PropTypes.func,
    ownerFullName: PropTypes.string,
};

export default LinkAccountForm;
