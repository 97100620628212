import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import React from 'react';

function HolidayToggle(props) {
    const { label, labelPlacement, checked, name, onChange } = props;

    return (
        <div>
            <FormControl>
                <FormControlLabel
                    control={
                        <Switch
                            checked={checked}
                            onChange={onChange}
                            name={name}
                            label={label}
                            size="small"
                            id="holiday-switch"
                        />
                    }
                    label={label}
                    labelPlacement={labelPlacement}
                />
            </FormControl>
        </div>
    );
}

HolidayToggle.propTypes = {};
HolidayToggle.defaultProps = {
    label: 'Holiday',
    name: 'isHoliday',
    labelPlacement: 'end',
};

export default HolidayToggle;
