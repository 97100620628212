import React from 'react';
import usePhoneAudioLib from '../usePhoneAudioLib';
import PhoneAudioLibContext from '../PhoneAudioLibContext';
import PhoneAudioLibContent from '../Components/PhoneAudioLibContent';

function PhoneAudioLibRoot() {
    const phoneAudioLibHook = usePhoneAudioLib();

    return (
        <PhoneAudioLibContext.Provider value={phoneAudioLibHook}>
            <PhoneAudioLibContent />
        </PhoneAudioLibContext.Provider>
    );
}

PhoneAudioLibRoot.propTypes = {};

export default PhoneAudioLibRoot;
