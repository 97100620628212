import React from 'react';
import { Grid, Typography } from '@mui/material';

function BrandingLogo() {
    return (
        <Grid
            container
            direction="row"
            spacing="5px"
            noWrap
            justifyContent="flex-end"
            style={{ padding: 20 }}
        >
            <Grid item>
                <img
                    src="../../img/Vector2.png"
                    style={{
                        width: 20,
                        height: 20,
                    }}
                />
            </Grid>
            <Grid item>
                <Typography style={{ color: '#fff' }}>StrideQ</Typography>
            </Grid>
        </Grid>
    );
}

BrandingLogo.propTypes = {};

export default BrandingLogo;
