import makeStyles from '@mui/styles/makeStyles';
const drawerWidth = 240;
export const useStyles = makeStyles((theme) => ({
    disableElements: {
        opacity: 0.7,
        pointerEvents: 'none',
        //backgroundColor: theme.palette.action.disabledBackground,
    },
    depositContext: {
        flex: 1,
    },
    root: {
        flexGrow: 1,
    },
    loadingImage: {
        display: 'block',
        margin: '0 auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },

    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    ht: {
        minHeight: '20px',
    },
    title: {
        flexGrow: 1,
    },

    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    selectedTile: {
        border: '2.5px solid black',
        borderRadius: '8px',
    },
    unselectedTile: {
        border: '2.5px solid rgba(34, 44, 49, 0.4)',
        borderRadius: '8px',
    },
}));
