import React from 'react';
import { useCallSummary } from '../Hooks/useCallSummary';
import { useDashBoard } from '../Hooks/useDashboard';
import { usePastTwoDays } from '../Hooks/usePastTwoDays';
// Creating the context object and passing the default values.
export const DashboardContext = React.createContext(null);

export function DashboardProvider({ children }) {
    const pastTwoDaysHook = usePastTwoDays();
    const callSummaryHook = useCallSummary();
    const dashboardHook = useDashBoard();

    return (
        <DashboardContext.Provider
            value={{ pastTwoDaysHook, dashboardHook, callSummaryHook }}
        >
            {children}
        </DashboardContext.Provider>
    );
}
