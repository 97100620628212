import { Button, CircularProgress } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import React, { useContext, useEffect, useState } from 'react';
import OrderSearchResults from './OrderSearchResults';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import moment from 'moment';
import Config from '../config/Config';

import { RestauInfoContext } from '../home/RestauInfoContext';
import Utils from '../utils/Utils';

export default function OrderSearch() {
    const [loading, setLoading] = useState(false);
    const [hasResults, sethasResults] = useState(false);
    const [orderNumber, setorderNumber] = useState('');
    const [orderId, setorderId] = useState('');
    const [custName, setcustName] = useState('');
    const [custPhNo, setcustPhNo] = useState('');
    const [orderResults, setOrderResults] = useState([]);
    /*const [orderId, setorderId] = useState(); */
    const [searchCriteriaVal, setsearchCriteriaVal] = useState('');
    const [searchCriteria, setSearchCriteria] = useState({
        byOrderNumber: false,
        byOrderId: false,
        byCustomerName: false,
        byCustomerPhNo: false,
        byDateRange: false,
    });

    const resauInfoContext = useContext(RestauInfoContext);
    const restauHook = resauInfoContext.restauHook;
    const storeTimezone = restauHook.restauTimeZone;
    //console.log("storeTimezone", storeTimezone);
    //console.log("Now", storeTimezone && new Date().toLocaleString('en-US', { timeZone: storeTimezone }));

    // let dtNow = new Date();
    // let defaultStartDate = new Date(dtNow.getFullYear(),  dtNow.getMonth() ,  dtNow.getDate(), 0,0,1 );
    // let defaultEndDate = new Date(dtNow.getFullYear(),  dtNow.getMonth() ,  dtNow.getDate(), 23,59,59 );

    const [createdTimeStart, setcreatedTimeStart] = useState(null); //moment());
    const [createdTimeEnd, setcreatedTimeEnd] = useState(null); //moment());

    useEffect(() => {
        console.log('Order Search loading');
        restSearchCriteria();
    }, [restauHook.restauId]);

    function updateSearchCriteria(e) {
        //console.log("Setting Search Criteria.")
        // console.log("Target",e.target.id)
        // console.log("Value",e.target.value)
        setsearchCriteriaVal(e.target.value);
        setSearchCriteria({
            byOrderNumber: e.target.value == 'orderNumber',
            byOrderId: e.target.value == 'orderId',
            byCustomerName: e.target.value == 'customerName',
            byCustomerPhNo: e.target.value == 'customerPhNo',
            byDateRange:
                e.target.value == 'customerName' ||
                e.target.value == 'customerPhNo' ||
                e.target.value == 'dateRange',
        });
        //console.log(JSON.stringify(searchCriteria));
    }

    function restSearchCriteria() {
        //enable all the controls

        setsearchCriteriaVal('');
        setSearchCriteria({
            byOrderNumber: false,
            byOrderId: false,
            byCustomerName: false,
            byCustomerPhNo: false,
            byDateRange: false,
        });

        //clear the search results
        setOrderResults((arr) => []);
        sethasResults(false);

        //reset the fields to initial values
        setorderNumber('');
        setorderId('');
        setcustName('');
        setcustPhNo('');
        setcreatedTimeStart(null);
        setcreatedTimeEnd(null);
    }

    async function fetchOrdersBasedOn() {
        //console.log('Fetching orders for OrderNumber', orderNumber);
        //fetch order data from database
        setLoading((prevstatus) => !prevstatus);
        sethasResults(false);
        const token = await window.customerApp.auth().currentUser.getIdToken();
        let config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };

        let reqBody = {
            restauId: restauHook.restauId,
        };
        //identify the search criteria
        //console.log("Order Number",orderNumber);
        if (searchCriteria.byOrderNumber) {
            reqBody = { ...reqBody, orderNumber: orderNumber };
        } else if (searchCriteria.byOrderId) {
            reqBody = { ...reqBody, orderId: orderId };
        } else if (searchCriteria.byCustomerName) {
            reqBody = { ...reqBody, customer: { name: custName } };
        } else if (searchCriteria.byCustomerPhNo) {
            reqBody = { ...reqBody, customer: { phoneNumber: custPhNo } };
        }

        //If the dateTimeStamp is also specified, include it in the search criteria
        if (
            searchCriteria.byDateRange &&
            createdTimeStart != null
            // && createdTimeEnd != null
        ) {
            reqBody = {
                ...reqBody,
                createdTime: {
                    start: createdTimeStart,
                    end: createdTimeEnd ? createdTimeEnd : new Date(),
                },
            };
        }

        //console.log('RequestBody', JSON.stringify(reqBody));

        axios
            .post(Config.searchOrders, reqBody, config)
            .then(function (response) {
                //console.log("Response: " + JSON.stringify(response.data));

                if (response.data) {
                    sethasResults((prevstatus) => !prevstatus);
                }
                setOrderResults((arr) =>
                    response.data.orderResults
                        ? [...response.data.orderResults]
                        : []
                );
                //console.log("orderResults: " + JSON.stringify(orderResults));
                setLoading((prevstatus) => !prevstatus);
            })
            .catch(function (error) {
                setOrderResults((arr) => []);
                setLoading((prevstatus) => !prevstatus);
                console.log(error);
            });

        //**To test with dummy local json data**//
        // setOrderResults(arr => [...dummyOrderData])
        // setLoading((prevstatus) => !prevstatus);
        // sethasResults((prevstatus) => !prevstatus);

        //console.log("Restaurant Info",resInfo.restauHook);
    }

    return (
        <div>
            <div>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { width: '25ch', m: 1 },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <FormControl
                        variant="outlined"
                        style={{ width: '25ch', margin: '10px 0' }}
                    >
                        <InputLabel id="searchCriteriaLabel">
                            Search By
                        </InputLabel>
                        <Select
                            labelId="searchCriteriaLabel"
                            id="searchCriteria"
                            value={searchCriteriaVal}
                            label="Order Search"
                            onChange={(e) => updateSearchCriteria(e)}
                            margin="dense"
                            size="small"
                        >
                            <MenuItem value={'orderNumber'}>
                                Order Number
                            </MenuItem>
                            <MenuItem value={'orderId'}>Order Id</MenuItem>
                            <MenuItem value={'customerName'}>
                                Customer Name
                            </MenuItem>
                            <MenuItem value={'customerPhNo'}>
                                Customer Phone Number
                            </MenuItem>
                            <MenuItem value={'dateRange'}>Date Range</MenuItem>
                        </Select>
                    </FormControl>
                    <div
                        id="orderNumberDetails"
                        style={
                            searchCriteria.byOrderNumber
                                ? { display: 'block', visibility: 'visible' }
                                : { display: 'none', visibility: 'hidden' }
                        }
                    >
                        <TextField
                            style={{ width: '30ch' }}
                            id="orderNumber"
                            label="Order Number"
                            value={orderNumber}
                            onChange={(e) => {
                                setorderNumber(e.target.value);
                            }}
                            variant="outlined"
                            size="small"
                        />
                    </div>
                    <div
                        id="orderIdDetails"
                        style={
                            searchCriteria.byOrderId
                                ? { display: 'block', visibility: 'visible' }
                                : { display: 'none', visibility: 'hidden' }
                        }
                    >
                        <TextField
                            style={{ width: '30ch' }}
                            id="orderId"
                            label="Order Id"
                            value={orderId}
                            variant="outlined"
                            size="small"
                            onChange={(e) => {
                                setorderId(e.target.value);
                            }}
                        />
                    </div>

                    <div
                        id="customerDetails"
                        style={{
                            display: 'flex',
                            //float:'left',
                        }}
                    >
                        <TextField
                            style={
                                searchCriteria.byCustomerName
                                    ? {
                                          width: '30ch',
                                          display: 'block',
                                          visibility: 'visible',
                                      }
                                    : {
                                          width: '30ch',
                                          display: 'none',
                                          visibility: 'hidden',
                                      }
                            }
                            variant="outlined"
                            size="small"
                            id="customerName"
                            label="Customer Name"
                            value={custName}
                            onChange={(e) => {
                                setcustName(e.target.value);
                            }}
                        />
                        <TextField
                            style={
                                searchCriteria.byCustomerPhNo
                                    ? {
                                          width: '30ch',
                                          display: 'block',
                                          visibility: 'visible',
                                      }
                                    : {
                                          width: '30ch',
                                          display: 'none',
                                          visibility: 'hidden',
                                      }
                            }
                            variant="outlined"
                            size="small"
                            id="customerPhNo"
                            label="Customer Phone Number"
                            value={custPhNo}
                            onChange={(e) => {
                                setcustPhNo(e.target.value);
                            }}
                        />
                        <div
                            id="dateTimeRangeDetails"
                            style={
                                searchCriteria.byDateRange
                                    ? {
                                          display: 'block',
                                          visibility: 'visible',
                                      }
                                    : { display: 'none', visibility: 'hidden' }
                            }
                        >
                            <DateTimePicker
                                id="createdTimeStart"
                                style={{ width: '30ch', marginRight: '5px' }}
                                label="From Date"
                                value={createdTimeStart}
                                inputVariant="outlined"
                                size="small"
                                //margin="dense"
                                onChange={(val, event) => {
                                    event = {
                                        target: {
                                            value: val,
                                            id: 'createdTimeStart',
                                        },
                                    };
                                    setcreatedTimeStart(val);
                                }}
                                minDate={moment(Utils.getDateWithOffset(-120))}
                                maxDate={moment(new Date())}
                                selected={moment(createdTimeStart)}
                                renderInput={(props) => (
                                    <TextField {...props} />
                                )}
                            />
                            <DateTimePicker
                                id="createdTimeEnd"
                                style={{ width: '30ch' }}
                                label="To Date"
                                value={createdTimeEnd}
                                autoOk
                                inputVariant="outlined"
                                size="small"
                                // margin="dense"
                                onChange={(val, event) => {
                                    event = {
                                        target: {
                                            value: val,
                                            id: 'createdTimeEnd',
                                        },
                                    };
                                    setcreatedTimeEnd(val);
                                }}
                                minDate={moment(
                                    createdTimeStart
                                        ? createdTimeStart
                                        : Utils.getDateWithOffset(-180)
                                )}
                                maxDate={moment(new Date())}
                                selected={moment(createdTimeEnd)}
                                renderInput={(props) => (
                                    <TextField {...props} />
                                )}
                            />
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    marginTop: '0',
                                    marginBottom: '0',
                                }}
                            >
                                *Date and Time as per Restaurant TimeZone
                            </p>
                        </div>
                    </div>
                </Box>
                <div style={{ marginTop: '5px' }}>
                    <Button
                        color="primary"
                        style={{
                            height: 'max-content',
                            marginBottom: '20px',
                        }}
                        variant="contained"
                        onClick={() => fetchOrdersBasedOn()}
                    >
                        Fetch
                    </Button>
                    <Button
                        // color="secondary"
                        style={{
                            height: 'max-content',
                            margin: '0 0 20px 10px',
                        }}
                        variant="contained"
                        onClick={() => restSearchCriteria()}
                    >
                        Clear
                    </Button>
                    {/* For testing purpose only.
                        remove this after testing */}
                </div>
            </div>
            {loading ? (
                <CircularProgress color="secondary" />
            ) : (
                hasResults &&
                (orderResults.length > 0 ? (
                    <OrderSearchResults
                        results={orderResults}
                        storeTimezone={storeTimezone}
                    />
                ) : (
                    <h1>No Results.</h1>
                ))
            )}
        </div>
    );
}
