import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    DialogActions,
    DialogContent,
    Typography,
} from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
import { useEffect } from 'react';
import CustomDialogTitle from './CustomDialogTitle';
import CustomDialog from './StyledComponents';

function DialogWithContent(props) {
    const {
        children,
        open,
        dialogTitle,
        dialogMaxWidth,
        setOpen,
        handleSave,
        handleClose,
        dialogSaveBtnText,
        dialogCloseBtnText,
        showSaveButton,
        contentSyles,
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errString, setErrString] = useState('');

    const handleSaveClick = async () => {
        setIsLoading(true);
        setIsError(false);
        try {
            await handleSave();
            setOpen(false);
            setIsError(false);
            setErrString('');
        } catch (ex) {
            setOpen(true);
            setIsError(true);
            setErrString(ex.toString());
            console.log(ex);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsError(false);
        setErrString('');
    }, [children]);

    function handleCloseClick() {
        handleClose && handleClose();
        setOpen(false);
    }
    return (
        <CustomDialog
            onClose={handleCloseClick}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth={true}
            maxWidth={dialogMaxWidth}
            /* BackdropProps={{ style: { backgroundColor: 'transparent' } }} */
        >
            <CustomDialogTitle
                id="customized-dialog-title"
                onClose={handleCloseClick}
            >
                {dialogTitle}
            </CustomDialogTitle>

            <DialogContent dividers={true} style={contentSyles}>
                {children}
            </DialogContent>

            <DialogActions>
                {isError && (
                    <Typography sx={{ color: 'red' }}>{errString}</Typography>
                )}
                <Button
                    autoFocus
                    onClick={handleCloseClick}
                    variant="outlined"
                    size="medium"
                >
                    {dialogCloseBtnText}
                </Button>
                {showSaveButton && (
                    <Button
                        autoFocus
                        onClick={async () => await handleSaveClick()}
                        variant="contained"
                        size="medium"
                        disabled={isLoading}
                    >
                        {dialogSaveBtnText}
                        {isLoading && (
                            <CircularProgress
                                size={20}
                                sx={{ ml: 0.5, color: 'white' }}
                            />
                        )}
                    </Button>
                )}
            </DialogActions>
        </CustomDialog>
    );
}

DialogWithContent.propTypes = {
    children: PropTypes.node.isRequired,
    open: PropTypes.bool,
    dialogTitle: PropTypes.string.isRequired,
    dialogMaxWidth: PropTypes.string,
    setOpen: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleClose: PropTypes.func,
    dialogCloseBtnText: PropTypes.string,
    dialogSaveBtnText: PropTypes.string,
    showSaveButton: PropTypes.bool,
    contentSyles: PropTypes.shape,
};

DialogWithContent.defaultProps = {
    dialogSaveBtnText: 'Save',
    dialogCloseBtnText: 'Cancel',
    dialogMaxWidth: 'xs',
    showSaveButton: true,
};

export default DialogWithContent;
