const methods = (config) => {
    const { function_url, function_url_onboarding, function_url_takeout } =
        config;
    return {
        getRestaurantId: `${function_url}get_restauid`,
        getDailyReport: `${function_url}getDailyReport`,
        getPast2DaysReport: `${function_url}getPast2DaysReports`,
        getMenu: `${function_url}getMenu`,
        updateMenu: `${function_url}updateMenu`,
        uploadFile: `${function_url}uploadFile`,
        downloadSalesTax: `${function_url}getSalesReportAsCSV`,
        getDineInReport: `${function_url}getDineInReport`,
        getSummaryReport: `${function_url}getSummaryReport`,
        getCustomerReviews: `${function_url}getCustomerReviews`,
        getDineInSummaryReport: `${function_url}getDineInSummaryReport`,
        getMerchantInfo: `${function_url}getStoreInfo`,
        signup: `${function_url}signupMerchant`,
        getAuditLogOOS: `${function_url}getAuditLogOOS`,
        getSubscription: `${function_url}getSubscription`,
        getAuditLogHide: `${function_url}getAuditLogHide`,
        getAuditLogPause: `${function_url}getAuditLogPause`,
        searchOrders: `${function_url}searchOrders`,
        searchDineInOrders: `${function_url}searchDineInOrders`,
        getDetailedOrdersReport: `${function_url}getDetailedOrdersReport`,
        getCallRecordsSummaryByDay: `${function_url}getCallRecordsSummaryByDay`,
        getCallRecordsSummaryByHour: `${function_url}getCallRecordsSummaryByHour`,
        getCallRecordsSummary: `${function_url}getCallRecordsSummary`,
        listPhoneNumber: `${function_url_onboarding}listPhoneNumbers_external`,
        getPhoneLocality: `${function_url_onboarding}getPhoneLocality`,
        buyPhoneNumber: `${function_url_onboarding}createFlowAndBuyPhoneNumber`,
        getMenuWithPrice: `${function_url}getMenuWithPrice`,
        updateMenuWithPrice: `${function_url}updateMenuWithPrice`,
        fetchPhoneSettings: `${function_url}fetchPhoneSettings`,
        updatePhoneSettings: `${function_url}updatePhoneSettings`,
        uploadAssetForCustomVoice: `${function_url}uploadAssetForCustomVoice`,
        getCustomerData: `${function_url_onboarding}getCustomerData`,
        sendSMS: `${function_url}sendSMS`,
        signupStripe: `${function_url_onboarding}signupStripe`,
        saveSignupInfo: `${function_url_onboarding}saveSignupInfo`,
        signupSuccess: `${function_url_onboarding}signupSuccess`,
        achStatus: `${function_url_onboarding}achStatus`,
        getStrideQSignupInfo: `${function_url_onboarding}getStrideQSignupInfo`,
        downloadQRTemplate: `${function_url_onboarding}createPowerpointDesign_external`,
        getPopularItemsInMenu: `${function_url}getPopularItemsInMenu`,
        updatePopularItemsInMenu: `${function_url}updatePopularItemsInMenu`,
        updateSectionInMenu: `${function_url}updateSectionInMenu`,
        updateCompleteFoodMenu: `${function_url}updateCompleteFoodMenu`,
        getAllStationMapping: `${function_url}getAllStationMapping`,
        updateStationsForRestaurant: `${function_url}updateStationsForRestaurant`,
        getStationMappingForFoodItem: `${function_url}getStationMappingForFoodItem`,
        getQRTemplates: `${function_url}getQRTemplates`,
        getAllVisibilityOverrideMappingForRestau: `${function_url}getAllVisibilityOverrideMappingForRestau`,
        updateVisibilityOverridesForRestau: `${function_url}updateVisibilityOverridesForRestau`,
        getVisibilityOverrideForFoodItem: `${function_url}getVisibilityOverrideForFoodItem`,
        getStationMappingForCategory: `${function_url}getStationMappingForCategory`,
        getVisibilityOverrideForCategory: `${function_url}getVisibilityOverrideForCategory`,
        getFilteredCallRecordsData: `${function_url}getFilteredCallRecordsData`,
        getFilteredCallerDetails: `${function_url}getFilteredCallerDetails`,
        getFilteredEvents: `${function_url}getFilteredEvents`,
        getEventsByCateringId: `${function_url}getEventsByCateringId`,
        updateEvent: `${function_url}updateEvent`,
        createEvent: `${function_url}createEvent`,
        retrieveFormConfig: `${function_url}retrieveFormConfig`,
        updateFormConfig: `${function_url}updateFormConfig`,
        updateLocationName: `${function_url}updateLocationName`,
        addCustomDestinationForPhoneOption: `${function_url}addCustomDestinationForPhoneOption`,
        getSubscriptionPlanForRestau: `${function_url}getSubscriptionPlanForRestau`,
        processCateringConversation: `${function_url_takeout}processCateringConversation`,
        getAudioLibraryData: `${function_url}getAudioLibraryData`,
        deleteAudioFileFromLibrary: `${function_url}deleteAudioFileFromLibrary`,
        updateAudioFileinLibrary: `${function_url}updateAudioFileinLibrary`,
        addNewAudioFileinLibrary: `${function_url}addNewAudioFileinLibrary`,
        getScheduledGreetingMessageData: `${function_url}getScheduledGreetingMessageData`,
        addOrEditScheduledGreetingMessage: `${function_url}addOrEditScheduledGreetingMessage`,
        deleteScheduledGreetingMessage: `${function_url}deleteScheduledGreetingMessage`,
    };
};

export default methods;
