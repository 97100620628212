import PropTypes from 'prop-types';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Config from '../../../config/Config';
import { RestauInfoContext } from '../../../home/RestauInfoContext';
import PrintStations from '../components/PrintStations';

function PrintStationsAtSection(props) {
    const { openSection, handleStationsUpdate } = props;
    const { restauHook } = useContext(RestauInfoContext);
    const [
        openDialogToAssignStationsAtSec,
        setopenDialogToAssignStationsAtSec,
    ] = useState(false);
    const [catStationMapping, setCatStationMapping] = useState({});
    const [loading, setLoading] = useState(false);

    async function loadData() {
        //debugger;
        //console.log(props.openFood);
        setLoading(true);
        const token = await window.customerApp.auth().currentUser.getIdToken();
        var config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };

        let reqBody = {
            restauId: restauHook.restauId,
            categoryId: openSection.id,
        };

        //function to fetch the popularitems from databasse
        let response = await axios
            .post(Config.getStationMappingForCategory, reqBody, config)
            .catch(function (error) {
                console.log(error);
            });
        if (response && response.data) {
            //console.log('Response>>: ' + JSON.stringify(response.data));
            if (response.data && response.data.status === undefined) {
                //setPopularItemData([...response.data]);
                setCatStationMapping({ ...response.data });
            } else {
                setCatStationMapping({});
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        console.log('Open Section: ', openSection);
        loadData();
    }, []);

    const handleStationMappingUpdate = (catStationMappingToUpdate) => {
        // console.log(
        //     'Station Mapping to update in db: ',
        //     itemStationMappingToUpdate
        // );
        setCatStationMapping({ ...catStationMappingToUpdate });
        let stationNamesToMap = Object.values(catStationMappingToUpdate)
            .filter((fd) => fd.isMapped)
            .map((x) => x.name);
        //console.log('Stations to update in db: ', stationNamesToMap);
        handleStationsUpdate(stationNamesToMap);
    };

    return (
        <PrintStations
            openDialogToAssignStations={openDialogToAssignStationsAtSec}
            setopenDialogToAssignStations={setopenDialogToAssignStationsAtSec}
            handleStationMappingUpdate={handleStationMappingUpdate}
            stationMappingData={catStationMapping}
            loading={loading}
        />
    );
}

PrintStationsAtSection.propTypes = {
    openSection: PropTypes.shape({ id: PropTypes.string }).isRequired,
    handleStationsUpdate: PropTypes.func,
};

export default PrintStationsAtSection;
