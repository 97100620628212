import React, { useContext, useState } from 'react';
import CateringSearchContext from '../CateringSearchContext';
import { useStyles } from '../../../../home/useStyles';
import { DataGrid } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import Utils from '../../../../utils/Utils';

const SearchResults = () => {
    const { loading, resultColumns, results } = useContext(
        CateringSearchContext
    );
    const [pageSize, setPageSize] = useState(5);
    const classes = useStyles();

    const rows = results.map((r, i) => {
        return {
            ...r,
            submissionTimestamp: Utils.convertTimestamp(
                r.submissionTimestamp,
                4,
                false
            ),
            eventDate: Utils.convertTimestamp(r.eventDate, 4, true),
            id: i + 1,
            invoice_payment_success:
                r.orders && r.orders.invoice_payment_success
                    ? r.orders.invoice_payment_success
                    : '-',
            payment_amount:
                r.orders && r.orders.payment_amount
                    ? r.orders.payment_amount / 100
                    : '-',
            invoice_payment_method:
                r.orders && r.invoice_payment_method
                    ? r.orders.invoice_payment_method
                    : '-',
            payment_salestax:
                r.orders && r.payment_salestax
                    ? r.orders.payment_salestax / 100
                    : '-',
            tip: r.orders && r.tip ? r.orders.tip / 100 : '-',
            deposit_payment_success:
                r.orders && r.orders.deposit_payment_success
                    ? r.orders.deposit_payment_success
                    : '-',
            deposit_amount:
                r.orders && r.orders.deposit_amount
                    ? r.orders.deposit_amount / 100
                    : '-',
            invoice_payment_collected_by:
                r.orders && r.orders.invoice_payment_collected_by
                    ? r.orders.invoice_payment_collected_by
                    : '-',
            deposit_payment_collected_by:
                r.orders && r.orders.deposit_payment_collected_by
                    ? r.orders.deposit_payment_collected_by
                    : '-',
        };
    });

    return (
        <div>
            {loading ? (
                <img
                    className={classes.loadingImage}
                    src="/img/spinner.gif"
                    alt="loading"
                />
            ) : (
                results.length > 0 && (
                    <>
                        <Typography>Count: {results.length}</Typography>

                        <div style={{ height: 430, width: '100%' }}>
                            <DataGrid
                                rows={rows}
                                columns={resultColumns}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) =>
                                    setPageSize(newPageSize)
                                }
                                rowsPerPageOptions={[5, 10, 20]}
                            />
                        </div>
                    </>
                )
            )}
        </div>
    );
};

export default SearchResults;
