import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    FormControlLabel,
    FormGroup,
    Switch,
    TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: '5px', //theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '5px', //theme.spacing(1),
    },
}));

const CustomDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        //color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

CustomDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function AddNewStationDialog(props) {
    const [stationName, setStationName] = useState('');
    const [isStationDefault, setIsStationDefault] = useState(true);

    const handleClose = () => {
        props.setOpen(false);
    };

    async function handleSave() {
        handleClose();
        props.handleSave(stationName, isStationDefault);
    }

    useEffect(() => {
        setStationName(props.station ? props.station.name : '');
        setIsStationDefault(props.station ? props.station.isDefault : true);
    }, []);

    return (
        <div>
            <CustomDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <CustomDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    {props.mode === 'create'
                        ? 'Create Print Station'
                        : 'Edit Station'}
                </CustomDialogTitle>

                <DialogContent dividers={true}>
                    <Box
                        noValidate
                        component="div"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: '5% 10%',
                        }}
                    >
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Station Name"
                            variant="outlined"
                            value={stationName}
                            onChange={(e) => setStationName(e.target.value)}
                        />
                        <br />
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isStationDefault}
                                        onChange={(e) =>
                                            setIsStationDefault(
                                                e.target.checked
                                            )
                                        }
                                    />
                                }
                                label="Default Station"
                            />
                        </FormGroup>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={handleClose}
                        variant="outlined"
                        size="medium"
                    >
                        Cancel
                    </Button>
                    <Button
                        autoFocus
                        onClick={handleSave}
                        variant="contained"
                        size="medium"
                    >
                        Save
                    </Button>
                </DialogActions>
            </CustomDialog>
        </div>
    );
}
