import React from 'react';
import { Grid } from '@mui/material';
import BrandingLogo from './BrandingLogo';

function BrandingPane(props) {
    return (
        <Grid
            container
            direction="column"
            style={{
                backgroundColor: '#222C31',
                height: '100vh',
            }}
        >
            <Grid item align="right">
                <BrandingLogo />
            </Grid>
            <Grid
                item
                flexGrow={1}
                justifyContent="center"
                display="flex"
                alignItems="center"
            >
                <img src="../../img/LogoImg.png" />
            </Grid>
        </Grid>
    );
}

BrandingPane.propTypes = {};

export default BrandingPane;
