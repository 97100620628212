import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
export default function PauseLogRow(props) {
    const { row } = props;
    return (
        <TableRow key={row.index}>
            <TableCell
                component="th"
                scope="row"
                style={{ width: 200, minwidth: 150 }}
            >
                {row.totalPausedHr}
            </TableCell>
            <TableCell style={{ minwidth: 150 }} align="left">
                {row.pauseUser}
            </TableCell>
            <TableCell style={{ minwidth: 150 }} align="left">
                {row.pauseTime}
            </TableCell>
            <TableCell style={{ minwidth: 150 }} align="left">
                {row.resumeUser}
            </TableCell>
            <TableCell style={{ minwidth: 150 }} align="left">
                {row.resumeTime}
            </TableCell>
        </TableRow>
    );
}
