import React, { useContext, useEffect } from 'react';
import MultilicationContext from './MultilocationContext';
import { useStyles } from '../home/useStyles';
import { TextField, Grid, Button } from '@mui/material';

const MultilocationContent = () => {
    const classes = useStyles();
    const { loading, location, updateLocationName, updateForm } =
        useContext(MultilicationContext);

    useEffect(() => {}, [location]);

    return (
        <>
            {loading || !location ? (
                <img
                    className={classes.loadingImage}
                    src="/img/spinner.gif"
                    alt="loading"
                />
            ) : (
                <Grid container>
                    {location.map((l, i) => (
                        <Grid item container key={i}>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={4}
                                sx={{ m: 'auto' }}
                            >
                                <TextField
                                    label="Restaurant"
                                    size="small"
                                    variant="filled"
                                    margin="normal"
                                    sx={{ width: '90%' }}
                                    defaultValue={l.restauName}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={4}
                                sx={{ m: 'auto' }}
                            >
                                <TextField
                                    multiline
                                    label="Address"
                                    size="small"
                                    variant="filled"
                                    margin="normal"
                                    sx={{ width: '90%' }}
                                    defaultValue={l.restauAddress}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={4}
                                sx={{ m: 'auto' }}
                            >
                                <TextField
                                    required
                                    label="Location Name"
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    sx={{ width: '90%' }}
                                    value={l.editName}
                                    onChange={(e) =>
                                        updateForm(i, {
                                            editName: e.target.value,
                                        })
                                    }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    ))}
                    <Grid item sx={{ m: 'auto' }}>
                        <Button
                            size="medium"
                            variant="contained"
                            onClick={updateLocationName}
                            sx={{ m: 3 }}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default MultilocationContent;
