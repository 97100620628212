let function_url =
    'https://us-central1-sliceline-admin-stage.cloudfunctions.net/';

let function_url_onboarding =
    'https://us-central1-sliceline-onboarding.cloudfunctions.net/';

let function_url_takeout =
    'https://us-central1-sliceline-takeout.cloudfunctions.net/';

const stageConfig = {
    environment: 'Stage-',
    env: 'stage',
    stripe_publish_key:
        'pk_test_51HrjUNHaof0IjCDr8uObDLPZEA0HLwx9QlWMUznvzoFbPey1QKbHuULuvmykdy51zgMMo24CSfhWqh7EfZmdto6X00ZiQaZ4Xr',
    secondaryAppConfig: {
        appId: '1:856193594445:web:e4515098abad087c48d804',
        apiKey: 'AIzaSyCDvtBiowtLtnulX9VYRPUSHJFgWQH-0N8',
        projectId: 'sliceline-takeout-stage-4bf89',
        databaseURL:
            'https://sliceline-takeout-stage-4bf89-default-rtdb.firebaseio.com',
    },

    firebaseConfig: {
        apiKey: 'AIzaSyA339JgrEpHw_tJanH8CGnTzhhncNcrsYc',
        authDomain: 'sliceline-admin-stage.firebaseapp.com',
        projectId: 'sliceline-admin-stage',
        storageBucket: 'sliceline-admin-stage.appspot.com',
        messagingSenderId: '771690064222',
        appId: '1:771690064222:web:7b3b73fe4e2b74df1547cf',
        measurementId: 'G-L54C03QZM8',
    },

    function_url,
    function_url_onboarding,
    function_url_takeout,
};

export default stageConfig;
