import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import TabPanel from '../../components/common/TabPanel';

export default function PhoneMenuContent({ children }) {
    //const [value, setValue] = React.useState(0);
    const [activeTab, setActiveTab] = useState('');

    const navigate = useNavigate();
    const location = useLocation();

    const phMenuTabs = [
        {
            value: 'reports',
            label: 'Reports',
        },
        {
            value: 'logs',
            label: 'Logs',
        },
        {
            value: 'callers',
            label: 'Callers',
        },
        {
            value: 'settings',
            label: 'Settings',
        },
        {
            value: 'audioLibrary',
            label: 'Audio Library',
        },
    ];

    const handleChange = (event, tab) => {
        navigate(tab);
    };

    const resetState = () => {
        const phMenuTab =
            phMenuTabs.find((option) =>
                location.pathname.endsWith(option.value)
            ) ||
            // picks first item as default tab
            phMenuTabs[0];

        const tab = phMenuTab?.value;
        //console.log('Activetab: ', tab);
        setActiveTab(tab);
    };

    useEffect(() => {
        resetState();
    }, [location.pathname]);

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={activeTab}
                    onChange={handleChange}
                    aria-label="Phone Menu Tabs"
                    scrollButtons="auto"
                    variant="scrollable"
                >
                    {phMenuTabs.map(({ label, value }) => (
                        <Tab
                            key={value}
                            label={label}
                            value={value}
                            id={`ph-menu-tab-${value}`}
                            aria-controls={`ph-menu-tabpanel-${value}`}
                        />
                    ))}
                </Tabs>
            </Box>
            <TabPanel
                id={`ph-menu-tabpanel-${activeTab}`}
                aria-labelledby={`ph-menu-tabpanel-${activeTab}`}
                style={{ width: '100%' }}
            >
                {children}
            </TabPanel>
        </Box>
    );
}

PhoneMenuContent.propTypes = {};
