import { Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { ConfigContainer, ConfigTitle } from './HolidayStyles';
import HolidayToggle from './HolidayToggle';
import SlotList from './SlotList';

function RegularHoursConfig(props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        noSsr: true,
    });
    const {
        day,
        list,
        holiday,
        disabled,
        onRemoveSlot,
        onAddSlot,
        onChangeSlot,
        onToggleHoliday,
    } = props;

    return (
        <ConfigContainer verticalLayout={isMobile}>
            <ConfigTitle verticalLayout={isMobile}>
                <Typography variant="h6">{day}</Typography>
                <div>
                    <HolidayToggle
                        label="Holiday"
                        labelPlacement={isMobile ? 'end' : 'top'}
                        checked={holiday}
                        onChange={onToggleHoliday}
                    />
                </div>
            </ConfigTitle>
            <div>
                <SlotList
                    disabled={disabled}
                    list={list}
                    onChange={onChangeSlot}
                    onRemoveSlot={onRemoveSlot}
                    onAddSlot={onAddSlot}
                />
            </div>
        </ConfigContainer>
    );
}

RegularHoursConfig.propTypes = {};

export default RegularHoursConfig;
