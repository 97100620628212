import Config from '../../../config/Config';
import PhoneMenuContext from '../../PhoneMenuContext';
import {
    customPhDestWidgets,
    productPlanPackages,
} from './PhoneSettingsConstants';
const { useContext, useEffect, useState } = require('react');
const { RestauInfoContext } = require('../../../home/RestauInfoContext');
const { default: axios } = require('axios');

const getSchGreetMsgDetailsFromDb = async (payload) => {};

const getPhoneSettingsFromDb = async (payload) => {
    const token = await window.customerApp.auth().currentUser.getIdToken();
    var config = {
        headers: {
            token: token,
            'Content-Type': 'application/json',
        },
    };

    //function to fetch the Phone Settings from databasse
    return await axios.post(Config.fetchPhoneSettings, payload, config);
};

const updatePhoneSettingsinDb = async (payload) => {
    const token = await window.customerApp.auth().currentUser.getIdToken();
    var config = {
        headers: {
            token: token,
            'Content-Type': 'application/json',
        },
    };

    //function to fetch the Phone Settings from databasse
    return await axios.post(Config.updatePhoneSettings, payload, config);
};

const addWidgetsToTwilioFlow = async (payload) => {
    const token = await window.customerApp.auth().currentUser.getIdToken();
    var config = {
        headers: {
            token: token,
            'Content-Type': 'application/json',
        },
    };

    //function to fetch the Phone Settings from databasse
    return await axios.post(
        Config.addCustomDestinationForPhoneOption,
        payload,
        config
    );
};

async function uploadFileToTwilioAsAsset(uploadedPlayFile, restauId) {
    //debugger;
    const token = await window.customerApp.auth().currentUser.getIdToken();
    var config = {
        headers: {
            token: token,
            'Content-Type': 'multipart/form-data',
        },
    };
    const formData = new FormData();
    formData.append('restauId', restauId);
    formData.append('playFileName', uploadedPlayFile.name);
    formData.append('playFile', uploadedPlayFile, uploadedPlayFile.name);
    return await axios.post(Config.uploadAssetForCustomVoice, formData, config);
}

const computePoductPlan = (dataFromDb) => {
    if (!dataFromDb || !dataFromDb.productPlan) {
        return null;
    }
    let productPlans = {
        basic: {
            label: 'Basic',
            value: 'basic',
            selected: false,
        },
        fullService: {
            label: 'Full Service',
            value: 'fullService',
            selected: false,
        },
    };

    productPlans[dataFromDb.productPlan].selected = true;
    return productPlans;
};

const computeProductPlanPackages = (dataFromDb) => {
    if (!dataFromDb || !dataFromDb.productPlan) {
        return null;
    }
    let computedProPlanPackages = JSON.parse(
        JSON.stringify(productPlanPackages)
    );

    Object.keys(dataFromDb.productPlanPackages).forEach((key) => {
        if (computedProPlanPackages[key]) {
            computedProPlanPackages[key].selected =
                dataFromDb.productPlanPackages[key];
        }
    });

    //for custom packages
    const customPackages = dataFromDb.productPlanPackages.custom;
    if (customPackages) {
        Object.keys(customPackages).forEach((pkg) => {
            //add this to the total list of plan packages
            computedProPlanPackages[pkg] = {
                label: customPackages[pkg].label,
                value: customPackages[pkg].name,
                selected: true,
                custom: true,
                widgets: customPackages[pkg].widgets,
            };
        });
    }

    //console.log('Computed Product Plan Packages: ', computedProPlanPackages);
    return computedProPlanPackages;
};

const computeCustomPlanPackagesData = (dataFromDb) => {
    //TODO: Revisit this code and see if this can be done better
    //debugger;
    let computedCustomPlanPackages = {};
    let customPkgs = dataFromDb.productPlanPackages.custom;
    customPkgs &&
        Object.keys(customPkgs).forEach((pkg) => {
            let twilioDataFromDb = dataFromDb[pkg];
            if (twilioDataFromDb !== undefined) {
                computedCustomPlanPackages[pkg] = {
                    name: pkg,
                    label: customPkgs[pkg].label,
                    txtMsgToOwner: {
                        enabled:
                            customPkgs[pkg].widgets.indexOf(
                                'send_message_for_custom_'
                            ) > -1,
                        toPhNumber: twilioDataFromDb.notificationText
                            ? twilioDataFromDb.notificationText.toNumber
                            : '',
                        textContent: twilioDataFromDb.notificationText
                            ? twilioDataFromDb.notificationText.message
                            : '',
                    },
                    emailMsgToOwner: {
                        enabled:
                            customPkgs[pkg].widgets.indexOf(
                                'send_email_for_custom_'
                            ) > -1,
                        toEmail: twilioDataFromDb.notificationEmail
                            ? twilioDataFromDb.notificationEmail.toEmail
                            : '',
                        emailSubject: twilioDataFromDb.notificationEmail
                            ? twilioDataFromDb.notificationEmail.subject
                            : '',
                        emailContent: twilioDataFromDb.notificationEmail
                            ? twilioDataFromDb.notificationEmail.body
                            : '',
                    },
                    greetingMessage: {
                        enabled:
                            customPkgs[pkg].widgets.indexOf(
                                'say_play_custom_1_'
                            ) > -1,
                        message: [
                            {
                                name: twilioDataFromDb.message
                                    ? twilioDataFromDb.message[0].name
                                    : 'say_play_custom_1_' + pkg,
                                playFilePath:
                                    twilioDataFromDb.message &&
                                    twilioDataFromDb.message[0].playFilePath,
                                sayMessage: twilioDataFromDb.message
                                    ? twilioDataFromDb.message[0].sayMessage
                                    : '',
                                sayMessageLanguage: twilioDataFromDb.message
                                    ? twilioDataFromDb.message[0]
                                          .sayMessageLanguage
                                    : 'en-US',
                                type: twilioDataFromDb.message
                                    ? twilioDataFromDb.message[0].type
                                    : 'say',
                                voice: twilioDataFromDb.message
                                    ? twilioDataFromDb.message[0].voice
                                    : 'Polly.Joanna',
                            },
                            {
                                name: twilioDataFromDb.message
                                    ? twilioDataFromDb.message[1].name
                                    : 'say_play_custom_2_' + pkg,
                                playFilePath:
                                    twilioDataFromDb.message &&
                                    twilioDataFromDb.message[1].playFilePath,
                                sayMessage: twilioDataFromDb.message
                                    ? twilioDataFromDb.message[1].sayMessage
                                    : '',
                                sayMessageLanguage: twilioDataFromDb.message
                                    ? twilioDataFromDb.message[1]
                                          .sayMessageLanguage
                                    : 'es-US',
                                type: twilioDataFromDb.message
                                    ? twilioDataFromDb.message[1].type
                                    : 'say',
                                voice: twilioDataFromDb.message
                                    ? twilioDataFromDb.message[1].voice
                                    : 'Polly.Lupe',
                            },
                        ],
                    },
                    textMsgToCustomer: {
                        enabled:
                            customPkgs[pkg].widgets.indexOf(
                                'send_message_custom_'
                            ) > -1,
                        textContent: twilioDataFromDb.sendText,
                    },
                };
            }
        });

    //console.log('ComputedCustomPlanPackages :', computedCustomPlanPackages);
    return computedCustomPlanPackages;
};

const usePhoneSettings = () => {
    const { loadAudioLibraryData } = useContext(PhoneMenuContext);
    const { restauHook } = useContext(RestauInfoContext);
    const [loading, setLoading] = useState(false);
    const [strideQPhNumber, setStrideQPhNumber] = useState('');
    const [frwdingPhNumber, setFrwdingPhNumber] = useState('');
    const [productPlans, setProductPlans] = useState([]);
    const [txtMsgForOnlineOrdering, setTxtMsgForOnlineOrdering] = useState('');
    const [catNotificationPhNumber, setCatNotificationPhNumber] = useState('');
    const [catNotificationEmail, setCatNotificationEmail] = useState('');
    const [txtMsgForCatering, setTxtMsgForCatering] = useState('');
    const [cateringMsgDetails, setCateringMsgDetails] = useState([]);
    const [banquetNotificationPhNumber, setBanquetNotificationPhNumber] =
        useState('');
    const [txtMsgForBanquet, setTxtMsgForBanquet] = useState('');
    const [banquetMsgDetails, setBanquetMsgDetails] = useState([]);
    const [productPlanPackages, setProductPlanPackages] = useState({});
    const [greetingMsgDetails, setGreetingMsgDetails] = useState([]);
    const [revertGreetMsgDetails, setRevertGreetMsgDetails] = useState({});
    const [thankUMsgForOnlineOrdering, setThankUMsgForOnlineOrdering] =
        useState([]);
    const [flowLanguage, setFlowLanguage] = useState('english');
    const [phMenuOptions, setPhMenuOptions] = useState({});
    const [busyMessageDetails, setBusyMessageDetails] = useState([]);
    const [txtMsgForBusy, setTxtMsgForBusy] = useState('');
    const [allowUpdateTxtMessage, setAllowUpdateTxtMessage] = useState(true);
    const [txtMsgForReservations, setTxtMsgForReservations] = useState('');
    const [reservationMsgDetails, setReservationMsgDetails] = useState([]);
    const restauId = restauHook.restauId;
    const restauName = restauHook.value.restauName;
    const [showError, setShowError] = useState(false);
    const [customPlanPackages, setCustomPlanPackages] = useState({});
    const [showStatusSnackBar, setShowStatusSnackBar] = useState(false);
    const [statusSnackBarMessage, setStatusSnackBarMessage] = useState('');
    const [accordionExpanded, setAccordionExpanded] = useState(false);
    const [audioLibraryData, setAudioLibraryData] = useState(null);
    //const [errMsg, setErrMsg] = useState(false);

    // const handleClick = (newState) => () => {
    //     setErrSnackBar({ open: true, ...newState });
    //   };

    const refreshData = () => {
        if (restauHook.restauId === null) {
            return;
        }
        setLoading(true);
        setShowError(false);
        let reqBody = {
            restauId: restauHook.restauId,
        };
        Promise.all([
            getPhoneSettingsFromDb(reqBody),
            loadAudioLibraryData(restauHook.restauId),
        ])
            .then((responses) => {
                console.log('Response1: ', responses[0]);
                console.log('Response2: ', responses[1]);
                let phSettingsResponse = responses[0];
                let audioLibraryResponse = responses[1];
                if (phSettingsResponse && phSettingsResponse.data) {
                    setStateData(phSettingsResponse.data);
                }
                if (audioLibraryResponse) {
                    setAudioLibraryData(audioLibraryResponse);
                }
            })
            .catch((err) => {
                console.log(err);
                setShowError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const refreshAudioLibraryData = async () => {
        if (restauHook.restauId === null) {
            return;
        }
        // setLoading(true);
        // setShowError(false);
        let audioLibraryResponse = await loadAudioLibraryData(
            restauHook.restauId
        ).catch((err) => {
            console.log(err);
            throw new Error(err.toString());
            //setShowError(true);
        });

        if (audioLibraryResponse) {
            setAudioLibraryData(audioLibraryResponse);
        }

        //setLoading(false);
    };

    const setStateData = (data) => {
        console.log('Set State: ', data);
        if (data) {
            setStrideQPhNumber(data.twilioPhNumber);
            setFrwdingPhNumber(data.forwadingPhNumber);
            setProductPlans(computePoductPlan(data));
            setFlowLanguage(
                data.flowLangTemplate && data.flowLangTemplate.toLowerCase()
            );
            setAllowUpdateTxtMessage(data.updateOnlineOrderingSite);
            setTxtMsgForOnlineOrdering(
                data.callDeflection &&
                    data.callDeflection.sendTextForOnlineOrdering
            );
            setCateringMsgDetails(data.catering && data.catering.message);
            setCatNotificationPhNumber(
                data.catering && data.catering.notificationNo
            );
            setCatNotificationEmail(
                data.catering && data.catering.notificationEmail
            );
            setTxtMsgForCatering(data.catering && data.catering.sendText);

            setBanquetMsgDetails(data.banquetHall && data.banquetHall.message);
            setBanquetNotificationPhNumber(
                data.banquetHall && data.banquetHall.notificationNo
            );
            setTxtMsgForBanquet(data.banquetHall && data.banquetHall.sendText);

            setReservationMsgDetails(
                data.reservations && data.reservations.message
            );
            setTxtMsgForReservations(
                data.reservations && data.reservations.sendText
            );
            setProductPlanPackages(computeProductPlanPackages(data));
            setCustomPlanPackages(computeCustomPlanPackagesData(data));
            setGreetingMsgDetails(
                data.callDeflection && data.callDeflection.greetingMessage
            );
            setRevertGreetMsgDetails(data && data.revertGreetMsgDetails);
            setThankUMsgForOnlineOrdering(
                data.callDeflection &&
                    data.callDeflection.thankUMsgForOnlineOrdering
            );
            setPhMenuOptions(
                data.callDeflection && data.callDeflection.menuOptions
            );
            setBusyMessageDetails(
                data.missedCallProtection &&
                    data.missedCallProtection.busyMessage
            );
            setTxtMsgForBusy(
                data.missedCallProtection &&
                    data.missedCallProtection.sendTextWhenBusy
            );
        }
    };

    const uploadAndUpdatePlayMessage = async (
        sayPlayMsgDetails,
        uploadedPlayFile,
        setErrMsg,
        setSuccessMsg,
        originalMsgDetails
    ) => {
        console.log('Uploading file to Twilio Server.');
        let finalMsgDetails = [];
        //debugger;
        let uploadedFileResponse = await uploadFileToTwilio(uploadedPlayFile);
        if (uploadedFileResponse && uploadedFileResponse.status === 'success') {
            let temp = JSON.parse(JSON.stringify(sayPlayMsgDetails));
            temp[0].type = 'play';
            temp[0].playFilePath = uploadedFileResponse.assetUrl;

            delete temp[0].sayMessage;
            delete temp[0].sayMessageLanguage;

            //NOTE:
            //If the flow Language is not ENGLISH, and if message is PLAY type.
            // the first 2 widgets should be empty, and
            // the last widget should be have the play file.
            //debugger;
            originalMsgDetails.forEach((x, index) => {
                if (index !== originalMsgDetails.length - 1) {
                    finalMsgDetails.push({ ...x, sayMessage: '' });
                } else {
                    finalMsgDetails.push({ ...temp[0] });
                }
            });
            return finalMsgDetails;
        } else {
            setErrMsg('Failed to upload the Play file to server.');
            setSuccessMsg('');
            return null;
        }
    };

    const updatePhoneSettings = (
        dataToUpdate,
        setErrMsg,
        setSuccessMsg,
        setIsProcessing
    ) => {
        //debugger;
        dataToUpdate.restauId = restauHook.restauId;
        //let isSuccess;
        updatePhoneSettingsinDb(dataToUpdate)
            .then((response) => {
                //debugger;
                console.log('Response: ', response);
                //refreshData();
                setSuccessMsg('Data saved succesfully !');
                setErrMsg('');

                setStatusSnackBarMessage('Data saved succesfully!');
                setShowStatusSnackBar(true);
                refreshData();
            })
            .catch((err) => {
                console.log(err);
                setErrMsg('Failed to save data to database.');
                setSuccessMsg('');
            })
            .finally(() => {
                setIsProcessing(false);
            });
    };

    const addCustomWidgetsToTwilio = async (newPhDestData) => {
        try {
            let temp = [];
            /*   Object.keys(newPhDestData.widgets)
                .filter((x) => newPhDestData.widgets[x])
                .forEach((x) => {
                    Array.isArray(customPhDestWidgets[x].widgetToAdd)
                        ? temp.push(...customPhDestWidgets[x].widgetToAdd)
                        : temp.push(customPhDestWidgets[x].widgetToAdd);
                }); */
            newPhDestData.widgets.forEach((x) => {
                Array.isArray(customPhDestWidgets[x].widgetToAdd)
                    ? temp.push(...customPhDestWidgets[x].widgetToAdd)
                    : temp.push(customPhDestWidgets[x].widgetToAdd);
            });

            let payload = {
                restauId: restauHook.restauId,
                label: newPhDestData.label,
                name: newPhDestData.name,
                widgets: [...temp],
                positionOffsetCount: customPlanPackages
                    ? Object.keys(customPlanPackages).length
                    : 0,
            };

            let response = await addWidgetsToTwilioFlow(payload);
            console.log('Uploaded File: ', response);
            //debugger;
            if (response.status === 200) {
                setStatusSnackBarMessage('New Destination added succesfully!');
                setShowStatusSnackBar(true);
                //refresh the page to load the latest twilio data
                refreshData();
                return true;
            } else {
                return null;
            }
        } catch (ex) {
            return null;
        }
    };

    const uploadFileToTwilio = async (uploadedFile) => {
        //debugger;
        try {
            let response = await uploadFileToTwilioAsAsset(
                uploadedFile,
                restauHook.restauId
            );
            console.log('Uploaded File: ', response);
            if (response.status === 200) {
                return response.data;
            } else {
                return null;
            }
        } catch (ex) {
            return null;
        }
    };

    const getScheduledGreetMsgDetails = async () => {
        if (restauHook.restauId === null) {
            return;
        }

        const token = await window.customerApp.auth().currentUser.getIdToken();
        var config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };

        const payload = {
            restauId: restauHook.restauId,
        };

        //function to fetch the Phone Settings from databasse
        return await axios.post(
            Config.getScheduledGreetingMessageData,
            payload,
            config
        );
    };

    const saveScheduledGreetMsgDetails = async (
        msgId,
        msgDetails,
        updateMsgNow,
        revertMsgNow
    ) => {
        if (restauHook.restauId === null) {
            return;
        }

        const token = await window.customerApp.auth().currentUser.getIdToken();
        var config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };

        const payload = {
            restauId: restauHook.restauId,
            fileId: msgId,
            dataToUpdate: msgDetails,
            updateMsgNow: updateMsgNow,
            revertMsgNow: revertMsgNow,
        };

        //function to fetch the Phone Settings from databasse
        return await axios.post(
            Config.addOrEditScheduledGreetingMessage,
            payload,
            config
        );
    };

    const deleteScheduledGreetMsgDetails = async (msgId, revertMsgNow) => {
        if (restauHook.restauId === null) {
            return;
        }

        const token = await window.customerApp.auth().currentUser.getIdToken();
        var config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };

        const payload = {
            restauId: restauHook.restauId,
            msgId: msgId,
            revertMsgNow: revertMsgNow,
        };

        //function to fetch the Phone Settings from databasse
        return await axios.post(
            Config.deleteScheduledGreetingMessage,
            payload,
            config
        );
    };

    useEffect(() => {
        refreshData();
    }, [restauHook.restauId]);

    return {
        loading,
        strideQPhNumber,
        setStrideQPhNumber,
        frwdingPhNumber,
        setFrwdingPhNumber,
        productPlans,
        txtMsgForOnlineOrdering,
        setTxtMsgForOnlineOrdering,
        catNotificationPhNumber,
        setCatNotificationPhNumber,
        catNotificationEmail,
        setCatNotificationEmail,
        txtMsgForCatering,
        setTxtMsgForCatering,
        cateringMsgDetails,
        setCateringMsgDetails,
        banquetNotificationPhNumber,
        setBanquetNotificationPhNumber,
        txtMsgForBanquet,
        setTxtMsgForBanquet,
        banquetMsgDetails,
        setBanquetMsgDetails,
        productPlanPackages,
        setProductPlanPackages,
        greetingMsgDetails,
        setGreetingMsgDetails,
        thankUMsgForOnlineOrdering,
        setThankUMsgForOnlineOrdering,
        phMenuOptions,
        setPhMenuOptions,
        busyMessageDetails,
        setBusyMessageDetails,
        updatePhoneSettings,
        txtMsgForBusy,
        setTxtMsgForBusy,
        uploadFileToTwilio,
        uploadAndUpdatePlayMessage,
        allowUpdateTxtMessage,
        restauId,
        restauName,
        showError,
        setShowError,
        flowLanguage,
        txtMsgForReservations,
        setTxtMsgForReservations,
        reservationMsgDetails,
        setReservationMsgDetails,
        addCustomWidgetsToTwilio,
        customPlanPackages,
        setCustomPlanPackages,
        showStatusSnackBar,
        setShowStatusSnackBar,
        statusSnackBarMessage,
        setStatusSnackBarMessage,
        accordionExpanded,
        setAccordionExpanded,
        audioLibraryData,
        getScheduledGreetMsgDetails,
        saveScheduledGreetMsgDetails,
        deleteScheduledGreetMsgDetails,
        revertGreetMsgDetails,
        refreshData,
        refreshAudioLibraryData,
    };
};

export default usePhoneSettings;
