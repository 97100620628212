import { useState } from 'react';
import moment from 'moment';

export function useAudit() {
    const [audit, setAudit] = useState('Out of Stocks');
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [pastRestauId, setPastRestauId] = useState({});
    const [OOSLog, setOOSLog] = useState('');
    const [hideLog, setHideLog] = useState('');
    const [pauseLog, setPauseLog] = useState('');

    return {
        audit,
        setAudit,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        pastRestauId,
        setPastRestauId,
        OOSLog,
        setOOSLog,
        hideLog,
        setHideLog,
        pauseLog,
        setPauseLog,
    };
}
