import { Button, Divider, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import PhoneSettingsContext from '../../PhoneSettingsContext';
import CallDefGreetingMsgDetails from './CallDefGreetingMsgDetails';
import CallDefMenuOptionDetails from './CallDefMenuOptionDetails';
import CallDefMsgOnlineOrderDetails from './CallDefMsgOnlineOrderDetails';
import CircularProgress from '@mui/material/CircularProgress';
import CallDefThankYouMsgDetails from './CallDefThankYouMsgDetails';
import ConfirmationDialog from '../ConfirmationDialog';
import { isEqual } from 'lodash';

function CallDefDetailsRoot() {
    const {
        greetingMsgDetails,
        setGreetingMsgDetails,
        thankUMsgForOnlineOrdering,
        setThankUMsgForOnlineOrdering,
        phMenuOptions,
        setPhMenuOptions,
        txtMsgForOnlineOrdering,
        setTxtMsgForOnlineOrdering,
        updatePhoneSettings,
        productPlanPackages,
        uploadAndUpdatePlayMessage,
        setAccordionExpanded,
        revertGreetMsgDetails,
        refreshData,
    } = useContext(PhoneSettingsContext);

    const [isProcessing, setIsProcessing] = useState(false);
    const [originalGreetingMsgDetails, setOriginalGreetingMsgDetails] =
        useState([]);
    const [originalThankUMsgDetails, setOriginalThankUMsgDetails] = useState(
        []
    );
    const [originalPhMenuOptions, setOriginalPhMenuOptions] = useState({});
    const [
        originalTxtMsgForOnlineOrdering,
        setOriginalTxtMsgForOnlineOrdering,
    ] = useState('');
    const [greetMsgPlayFile, setGreetMsgPlayFile] = useState({});
    const [thankUMsgPlayFile, setThankUMsgPlayFile] = useState({});

    const [errMsg, setErrMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const computeSayPlayMsgDetails = async (
        msgDetails,
        originalMsgDetails,
        newPlayFile
    ) => {
        //debugger;
        let temp;
        if (msgDetails[0].type === 'play') {
            //Fix for uploading play file.
            //check if the play file changed.
            // if (
            //     originalMsgDetails[0].playFilePath !==
            //     msgDetails[0].playFilePath
            // ) {
            //     temp = await uploadAndUpdatePlayMessage(
            //         msgDetails,
            //         newPlayFile,
            //         setErrMsg,
            //         setSuccessMsg,
            //         originalMsgDetails
            //     );
            //     if (!temp) {
            //         //debugger;
            //         setIsProcessing(false);
            //         return;
            //     }
            // } else {
            temp = JSON.parse(JSON.stringify(msgDetails));
            //}
        } else {
            temp = JSON.parse(JSON.stringify(msgDetails));
            delete temp[0].playFilePath;
        }
        return temp;
    };

    const saveCallDefDetails = async () => {
        //debugger;
        let isGreetingMessageValid = true;
        if (
            greetingMsgDetails.length === 0 ||
            greetingMsgDetails[0].type === undefined || //sayMessage
            (greetingMsgDetails[0].type === 'play' &&
                (greetingMsgDetails[0].playFilePath === undefined ||
                    greetingMsgDetails[0].playFilePath === ''))
        ) {
            isGreetingMessageValid = false;
            setErrMsg('Greeting Message is not Valid.');
            return;
        }

        let isThankUMessageValid = true;
        //debugger;
        if (
            thankUMsgForOnlineOrdering.length === 0 ||
            thankUMsgForOnlineOrdering[0].type === undefined ||
            (thankUMsgForOnlineOrdering[0].type === 'play' &&
                (thankUMsgForOnlineOrdering[0].playFilePath === undefined ||
                    thankUMsgForOnlineOrdering[0].playFilePath === ''))
        ) {
            isThankUMessageValid = false;
            setErrMsg('Thank You Message for Online Ordering is not Valid.');
            return;
        }

        let isPhMenuOptionsValid = true;
        if (
            Object.keys(phMenuOptions).length === 0 ||
            Object.keys(phMenuOptions).some(
                (x) =>
                    phMenuOptions[x]['keyPressed'] === undefined ||
                    phMenuOptions[x]['routeTo'] === undefined
            ) ||
            Object.keys(phMenuOptions).some(
                (x) =>
                    phMenuOptions[x]['keyPressed'] === '' ||
                    phMenuOptions[x]['routeTo'] === ''
            )
        ) {
            isPhMenuOptionsValid = false;
            setErrMsg('Phone Menu Options are not Valid.');
            return;
        }
        let phMenuOpts = Object.values(phMenuOptions).map((x) => x.routeTo);
        //Javascript Set cannot have duplicates, but an array can.
        if (new Set(phMenuOpts).size !== phMenuOpts.length) {
            isPhMenuOptionsValid = false;
            setErrMsg('Phone Menu Options cannot have duplicate values.');
            return;
        }

        let isTxtForOnlineOrderingValid = true;
        if (txtMsgForOnlineOrdering === '') {
            isTxtForOnlineOrderingValid = false;
            setErrMsg('Text Message for Online Ordering cannot be empty.');
            return;
        }

        //Only if all the inputs are valid, proceed to save
        if (
            isGreetingMessageValid &&
            isThankUMessageValid &&
            isPhMenuOptionsValid &&
            isTxtForOnlineOrderingValid
        ) {
            setIsProcessing(true);
            setErrMsg('');
            setSuccessMsg('');
            // let temp;
            // if (greetingMsgDetails[0].type === 'play') {
            //     //Fix for uploading play file.
            //     //check if the play file changed.
            //     if (
            //         originalGreetingMsgDetails[0].playFilePath !==
            //         greetingMsgDetails[0].playFilePath
            //     ) {
            //         temp = await uploadAndUpdatePlayMessage(
            //             greetingMsgDetails,
            //             uploadedPlayFile,
            //             setErrMsg,
            //             setSuccessMsg,
            //             originalGreetingMsgDetails
            //         );
            //         if (!temp) {
            //             //debugger;
            //             setIsProcessing(false);
            //             return;
            //         }
            //     } else {
            //         temp = JSON.parse(JSON.stringify(greetingMsgDetails));
            //     }
            // } else {
            //     temp = JSON.parse(JSON.stringify(greetingMsgDetails));
            //     delete temp[0].playFilePath;
            // }
            //debugger;
            let body = {
                callDeflection: {
                    greetingMessage: await computeSayPlayMsgDetails(
                        greetingMsgDetails,
                        originalGreetingMsgDetails,
                        greetMsgPlayFile
                    ),
                    menuOptions: phMenuOptions,
                    sendTextForOnlineOrdering: txtMsgForOnlineOrdering,
                    thankUMsgForOnlineOrdering: await computeSayPlayMsgDetails(
                        thankUMsgForOnlineOrdering,
                        originalThankUMsgDetails,
                        thankUMsgPlayFile
                    ),
                },
            };
            //debugger;
            updatePhoneSettings(
                body,
                setErrMsg,
                setSuccessMsg,
                setIsProcessing
            );
        }
    };

    const resetData = () => {
        //debugger;
        //check if there are any changes to data
        if (
            isEqual(greetingMsgDetails, originalGreetingMsgDetails) &&
            isEqual(thankUMsgForOnlineOrdering, originalThankUMsgDetails) &&
            isEqual(phMenuOptions, originalPhMenuOptions) &&
            txtMsgForOnlineOrdering === originalTxtMsgForOnlineOrdering
        ) {
            //close the accordion
            setAccordionExpanded(false);
        } else {
            setShowConfirmationDialog(true);
        }
    };

    const handleDiscardChanges = () => {
        setErrMsg('');
        setSuccessMsg('');
        setGreetingMsgDetails(
            JSON.parse(JSON.stringify(originalGreetingMsgDetails))
        );
        setThankUMsgForOnlineOrdering(
            JSON.parse(JSON.stringify(originalThankUMsgDetails))
        );
        setPhMenuOptions(JSON.parse(JSON.stringify(originalPhMenuOptions)));
        setTxtMsgForOnlineOrdering(originalTxtMsgForOnlineOrdering);
        setAccordionExpanded(false);
    };

    useEffect(() => {
        setErrMsg('');
        setSuccessMsg('');
        setOriginalGreetingMsgDetails(
            JSON.parse(JSON.stringify(greetingMsgDetails))
        );
        setOriginalThankUMsgDetails(
            JSON.parse(JSON.stringify(thankUMsgForOnlineOrdering))
        );
        setOriginalPhMenuOptions(JSON.parse(JSON.stringify(phMenuOptions)));
        setOriginalTxtMsgForOnlineOrdering(txtMsgForOnlineOrdering);
    }, []);

    return (
        <>
            <ConfirmationDialog
                showConfirmationDialog={showConfirmationDialog}
                setShowConfirmationDialog={setShowConfirmationDialog}
                handleConfirm={handleDiscardChanges}
            />
            <Grid container rowSpacing={3} xs={12}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} md={9}>
                            <CallDefGreetingMsgDetails
                                greetingMsgDetails={greetingMsgDetails}
                                setGreetingMsgDetails={setGreetingMsgDetails}
                                greetMsgPlayFile={greetMsgPlayFile}
                                setGreetMsgPlayFile={setGreetMsgPlayFile}
                                originalGreetingMsgDetails={
                                    originalGreetingMsgDetails
                                }
                                revertGreetMsgDetails={revertGreetMsgDetails}
                                refreshData={refreshData}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Divider style={{ width: '100%' }} />
                <Grid item>
                    <CallDefMenuOptionDetails
                        phMenuOptions={phMenuOptions}
                        setPhMenuOptions={setPhMenuOptions}
                        productPlanPackages={productPlanPackages}
                    />
                </Grid>
                <Divider style={{ width: '100%' }} />
                <br />
                {/* {allowUpdateTxtMessage && ( */}
                <Grid container item xs={12} columnSpacing={3} rowSpacing={3}>
                    <Grid item lg={5} xs={12}>
                        <CallDefMsgOnlineOrderDetails
                            txtMsgForOnlineOrdering={txtMsgForOnlineOrdering}
                            setTxtMsgForOnlineOrdering={
                                setTxtMsgForOnlineOrdering
                            }
                        />
                    </Grid>
                    {/* <Divider style={{ width: '100%' }} /> */}
                    <Grid item lg={7} xs={12}>
                        <CallDefThankYouMsgDetails
                            thankUMsgDetails={thankUMsgForOnlineOrdering}
                            setThankUMsgDetails={setThankUMsgForOnlineOrdering}
                            thankUMsgPlayFile={thankUMsgPlayFile}
                            setThankUMsgPlayFile={setThankUMsgPlayFile}
                            originalThankUMsgDetails={originalThankUMsgDetails}
                        />
                    </Grid>
                </Grid>

                {/* )} */}
                {errMsg !== '' ? (
                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{ color: 'red' }}>
                            {errMsg}
                        </Typography>
                    </Grid>
                ) : (
                    successMsg !== '' && (
                        <Grid item xs={12}>
                            <Typography variant="body1" sx={{ color: 'green' }}>
                                {successMsg}
                            </Typography>
                        </Grid>
                    )
                )}
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                    }}
                >
                    <Button variant="outlined" onClick={resetData}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ ml: 2 }}
                        onClick={saveCallDefDetails}
                        disabled={isProcessing}
                    >
                        {isProcessing && <CircularProgress size={24} />}
                        Save
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

CallDefDetailsRoot.propTypes = {};

export default CallDefDetailsRoot;
