import React from 'react';
import PhoneCallerContext from '../PhoneCallerContext';
import usePhoneCallerDetails from '../usePhoneCallerDetails';
import PhoneCallerDetailsContent from '../Components/PhoneCallerDetailsContent';

function PhoneCallersRoot() {
    const phoneLogsHook = usePhoneCallerDetails();

    return (
        <PhoneCallerContext.Provider value={phoneLogsHook}>
            <PhoneCallerDetailsContent />
        </PhoneCallerContext.Provider>
    );
}

PhoneCallersRoot.propTypes = {};

export default PhoneCallersRoot;
