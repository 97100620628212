import React, { useState } from 'react';
import download from '../utils/downloadExcel';
import Config from '../config/Config';
import { Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import moment from 'moment';

export default function ItemDetailedDataExport(props) {
    // const [dineInOrders, setDineInOrders] = useState([]);
    // const [itemLevelOrderDetails, setItemLevelOrderDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const reportNamePrefix = 'AllItemsReport_';

    //to export the data related to all the items from all the orders
    async function exportItemLevelDataForAllOrders() {
        console.log('Calling server to get data.');
        setLoading((prevstatus) => !prevstatus);
        setIsError(false);
        const token = await window.customerApp.auth().currentUser.getIdToken();
        let config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };

        let reqBody = {
            restauId: props.restauId,
            createdTime: {
                start: props.startDate,
                end: props.endDate ? props.endDate : new Date(),
            },
        };

        let dataToExport = [];

        axios
            .post(Config.getDetailedOrdersReport, reqBody, config)
            .then(function (response) {
                setLoading((prevstatus) => !prevstatus);
                //console.log("Response: " + JSON.stringify(response.data));
                if (response.data.itemResults) {
                    console.table(response.data.itemResults);
                    download.excelDownload(
                        response.data.itemResults,
                        reportNamePrefix +
                            moment(new Date()).format('DD-MM-YYYY_hhmmss') +
                            '.xlsx',
                        'AllItems'
                    );
                } else {
                    setIsError(true);
                    setLoading((prevstatus) => !prevstatus);
                }
            })
            .catch(function (error) {
                setLoading((prevstatus) => !prevstatus);
                setIsError(true);
                console.log(error);
            });
    }

    return (
        <>
            <Button
                color="primary"
                variant="contained"
                onClick={() => {
                    return exportItemLevelDataForAllOrders();
                }}
            >
                Export to Excel
            </Button>
            {loading && <CircularProgress color="secondary" size={30} />}
            {isError && (
                <p
                    style={{
                        color: 'red',
                        fontWeight: 'normal',
                        fontSize: '0.85rem',
                    }}
                >
                    Failed to export the data.
                </p>
            )}
        </>
    );
}
