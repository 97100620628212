import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import Config from '../../../config/Config';
import { RestauInfoContext } from '../../../home/RestauInfoContext';
import AssignedVisOverrides from '../components/AssignOverridesToMenu/AssignedVisOverrides';

//To display the list of assigned Overrides in Section Edit Dialog
function AssignedOverridesAtSection(props) {
    const { openSection, handleOverrideUpdate } = props;

    const { restauHook } = useContext(RestauInfoContext);
    const [assignedVisOverrideForSection, setAssignedVisOverrideForSection] =
        useState({});
    const [openAssignOverridesDialog, setOpenAssignOverridesDialog] =
        useState(false);
    const [allOverrideMapping, setAllOverrideMapping] = useState({});
    const [loading, setLoading] = useState(false);

    function loadData() {
        //console.log(props.openFood);
        setLoading(true);
        loadVisOverridesForFood();
        //setLoading(false);
    }

    const loadVisOverridesForFood = async () => {
        //debugger;
        let payload = {
            restauId: restauHook.restauId,
            categoryId: openSection.id,
        };

        const token = await window.customerApp.auth().currentUser.getIdToken();
        var config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };
        console.log('Payload: ', payload);
        //function to fetch the Visibility Overrides from databasse
        axios
            .post(Config.getVisibilityOverrideForCategory, payload, config)
            .then((response) => {
                console.log('Response: ', response);
                if (response && response.status === 200) {
                    setAllOverrideMapping(response.data);
                    let mappedOverride = Object.values(response.data).filter(
                        (x) => x.isMapped
                    )[0];
                    setAssignedVisOverrideForSection({ ...mappedOverride });
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    async function handleAssignedOverrideUpdate(assignedOverride) {
        //debugger;
        console.log('Assigned Override: ', assignedOverride);
        setAssignedVisOverrideForSection({ ...assignedOverride });
        handleOverrideUpdate(assignedOverride.id);
    }

    useEffect(() => {
        console.log('Open Section: ', openSection);
        setAssignedVisOverrideForSection({});
        loadData();
    }, []);

    return (
        <AssignedVisOverrides
            setOpenAssignOverridesDialog={setOpenAssignOverridesDialog}
            openAssignOverridesDialog={openAssignOverridesDialog}
            loading={loading}
            assignedVisOverride={assignedVisOverrideForSection}
            setAssignedVisOverride={setAssignedVisOverrideForSection}
            allOverrideMapping={allOverrideMapping}
            handleAssignedOverrideUpdate={handleAssignedOverrideUpdate}
        />
    );
}

AssignedOverridesAtSection.propTypes = {
    openSection: PropTypes.shape({ id: PropTypes.string }).isRequired,
    handleOverrideUpdate: PropTypes.func.isRequired,
};

export default AssignedOverridesAtSection;
