import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import React from 'react';
import Title from './Title';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    flexContext: {
        flex: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 160,
    },
}));

export default function PriceGrid(prop) {
    function getTopSoldItems(items) {
        let itemText = '';
        let len = items.length;
        if (items.length > 5) {
            len = 5;
        }
        for (let i = 0; i < len; i++) {
            itemText += '\n' + items[i].item + '(Qty-' + items[i].total + '),';
        }
        return itemText;
    }
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    return (
        <Grid item xs={12} md={4} lg={3}>
            <Paper className={fixedHeightPaper}>
                <React.Fragment>
                    <Title>{prop.title}</Title>
                    <Typography component="p" variant="h4">
                        {prop.total ? prop.total : ''}
                    </Typography>
                    {(prop.paidInStore || prop.paidInTerminal) && (
                        <>
                            <Typography
                                color="textSecondary"
                                className={classes.flexContext}
                            >
                                {'Paid in Store: ' + prop.paidInStore}
                            </Typography>
                            <Typography
                                color="textSecondary"
                                className={classes.flexContext}
                            >
                                {'Paid Online: ' + prop.onlinePayment}
                            </Typography>
                            <Typography
                                color="textSecondary"
                                className={classes.flexContext}
                            >
                                {'Paid Terminal: ' + prop.paidInTerminal}
                            </Typography>
                        </>
                    )}
                    {prop.salesTaxTerminal && (
                        <>
                            <Typography
                                color="textSecondary"
                                className={classes.flexContext}
                            >
                                {'Sales Tax Terminal: ' + prop.salesTaxTerminal}
                            </Typography>
                        </>
                    )}

                    {prop.subTotalTerminal && (
                        <>
                            <Typography
                                color="textSecondary"
                                className={classes.flexContext}
                            >
                                {'Sub Total Terminal: ' + prop.subTotalTerminal}
                            </Typography>
                        </>
                    )}
                    {prop.tipTerminal && (
                        <>
                            <Typography
                                color="textSecondary"
                                className={classes.flexContext}
                            >
                                {'Sub Total Terminal: ' + prop.tipTerminal}
                            </Typography>
                        </>
                    )}
                </React.Fragment>
            </Paper>
        </Grid>
    );
}
