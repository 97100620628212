import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import React, { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { RestauInfoContext } from '../home/RestauInfoContext.js';
import { useStyles } from '../home/styles/useStyles';

import InputAdornment from '@mui/material/InputAdornment';

import HoursDialog from './HoursDialog.js';

export default function Delivery(props) {
    // const restauHook = props.restauHook;
    const resauInfoContext = useContext(RestauInfoContext);
    const restauHook = resauInfoContext.restauHook;
    const [loaded, setLoaded] = React.useState(false);
    const [isOpen, setOpen] = React.useState(false);
    const [saveMessage, setSaveMessage] = React.useState('');
    const classes = useStyles();
    const [deliveryConfig, setDeliveryConfig] = React.useState(null);
    const [enabled, setEnabled] = React.useState(false);
    const [minDeliveryAmt, setMinDeliveryAmt] = React.useState(0);
    const [fees, setFees] = React.useState(null);
    const [deliveryThreshold, setDeliveryThreshold] = React.useState(null);

    function addFee(index) {
        let tFee = [];
        for (let i = 0; i < fees.length; i++) {
            tFee.push(fees[i]);
            if (i === index) {
                tFee.push({ start: null, end: null, fee: null });
            }
        }
        setFees(tFee);
    }

    function onChangeFees(e, index, id) {
        let tFee = [...fees];
        if (id === 'fee') {
            tFee[index].fee = e.target.value;
        } else if (id === 'start') {
            tFee[index].start = e.target.value;
        } else if (id === 'end') {
            tFee[index].end = e.target.value;
        }

        setFees(tFee);
    }

    function deleteFee(index) {
        let tFee = [];
        for (let i = 0; i < fees.length; i++) {
            if (i === index) {
                continue;
            }
            tFee.push(fees[i]);
        }
        setFees(tFee);
    }

    async function setDefault() {
        let deliveryConf = { ...deliveryConfig };
        if (!deliveryConf.locationConfig) {
            deliveryConf.locationConfig = {};
        }
        if (!deliveryConf.locationConfig.fees) {
            deliveryConf.locationConfig.fees = [];
            deliveryConf.locationConfig.fees.push({ start: 0, end: 0, fee: 0 });
            setFees(deliveryConf.locationConfig.fees);
        }
        if (!deliveryConf['hours']) {
            const admin = window.customerApp.database();
            let payAtRef = await admin.ref(
                'menus/' + restauHook.restauId + '/details/hours'
            );
            await payAtRef.once('value').then(async function (snapshot) {
                let result = snapshot.val();
                deliveryConf['hours'] = result;
                let deliveryConfsetting = {};
                deliveryConfsetting[
                    'menus/' +
                        restauHook.restauId +
                        '/details/deliveryConfig/hours'
                ] = result;
                await admin
                    .ref()
                    .update(deliveryConfsetting)
                    .then(function () {
                        setSaveMessage('Succesfully updated');
                    })
                    .catch(function (error) {
                        setLoaded(false);
                        //Need to pop error someways
                        console.log('RestauID::::::', restauHook.restauId);
                        console.log(error);

                        return;
                    });
            });
        }
        setDeliveryConfig(deliveryConf);
    }
    async function handleChange(e) {
        let deliveryConf = { ...deliveryConfig };
        if (e.target.value === 'enabled') {
            deliveryConf['enabled'] = true;
            setEnabled(true);
            setDeliveryConfig(deliveryConf);
        } else {
            deliveryConf['enabled'] = false;
            setEnabled(false);
            setDeliveryConfig(deliveryConf);
        }
    }

    function loadMap(result = null) {
        console.log(deliveryConfig);
        let delConfig = deliveryConfig;
        if (!delConfig) {
            delConfig = result;
        }
        if (!delConfig) {
            console.log('del conf null');
            return;
        }
        if (
            delConfig.locationConfig &&
            delConfig.locationConfig.lat &&
            delConfig.locationConfig.lng &&
            document.getElementById('container21')
        ) {
            let src =
                'https://maps.google.com/maps?q=' +
                delConfig.locationConfig.lat +
                ',' +
                delConfig.locationConfig.lng +
                '&hl=es&z=14&output=embed';
            ReactDOM.render(
                <iframe
                    src={src}
                    style={{
                        width: '100%',
                        height: '400px',
                        frameborder: '0',
                        border: '0',
                    }}
                ></iframe>,
                document.getElementById('container21')
            );
        }
    }
    async function handleAheadChange(e) {
        if (e.target.value === 'enabled') {
            let deliveryConf = { ...deliveryConfig };
            deliveryConf['deliveryAheadEnabled'] = true;
            setDeliveryConfig(deliveryConf);
            if (
                !deliveryConf.locationConfig ||
                !deliveryConf.locationConfig.fees
            ) {
                let f = [{ start: null, end: null, fee: null }];
                setFees(f);
            }
        } else {
            let deliveryConf = { ...deliveryConfig };
            deliveryConf['deliveryAheadEnabled'] = false;
            setDeliveryConfig(deliveryConf);
        }
    }

    function handleLocationChange(e, id) {
        if (id === 'lat') {
            let deliveryConf = { ...deliveryConfig };
            console.log('lat', deliveryConf);
            deliveryConf['locationConfig']['lat'] = e.target.value;
            setDeliveryConfig(deliveryConf);
            loadMap();
        } else if (id === 'lng') {
            let deliveryConf = { ...deliveryConfig };
            deliveryConf['locationConfig']['lng'] = e.target.value;
            setDeliveryConfig(deliveryConf);
            loadMap();
        } else if (id === 'maxDistance') {
            let deliveryConf = { ...deliveryConfig };
            deliveryConf['locationConfig']['maxDistance'] = e.target.value;
            setDeliveryConfig(deliveryConf);
        } else if (id === 'minDeliveryAmt') {
            let str = e.target.value;
            setMinDeliveryAmt(str);
        } else if (id === 'scheduledOrderDays') {
            let deliveryConf = { ...deliveryConfig };
            deliveryConf['scheduledOrderDays'] = e.target.value;
            setDeliveryConfig(deliveryConf);
        }
    }

    async function updateSettings(restauHook) {
        const database = window.customerApp.database();
        setLoaded(true);

        let deliveryConfsetting = {};

        let deliveryConf = { ...deliveryConfig };
        deliveryConf['enabled'] = enabled;
        console.log(deliveryConf);
        if (deliveryConf['enabled']) {
            if (!deliveryConf['locationConfig']) {
                setLoaded(false);
                alert('please add location configs');
                return;
            }
            if (!deliveryConf['locationConfig']['maxDistance']) {
                setLoaded(false);
                alert('please add maximum delivery distance');
                return;
            }
            if (
                !deliveryConf['locationConfig']['lat'] ||
                !deliveryConf['locationConfig']['lng']
            ) {
                setLoaded(false);
                alert('please update lat/long location of the store');
                return;
            }
            if (!deliveryConf['locationConfig']['fees']) {
                setLoaded(false);
                alert('please update Fees based on the miles to deliver');
                return;
            }

            if (fees) {
                let maxDistCover =
                    deliveryConf['locationConfig']['maxDistance'];

                let fs = fees;
                let maxCovered = false;
                for (let f of fs) {
                    if (!f.start) {
                        f.start = 0;
                    }
                    if (!f.end || !f.fee) {
                        setLoaded(false);
                        alert('please update miles and fees');
                        return;
                    }

                    if (f.start < 0 || f.end <= 0 || f.fee <= 0) {
                        setLoaded(false);
                        alert('please update miles and fees with valid values');
                        return;
                    }
                    if (f.end >= maxDistCover) {
                        maxCovered = true;
                    }
                }
                if (!maxCovered) {
                    setLoaded(false);
                    alert('please update the fees properly for distance');
                    return;
                }
            }
            deliveryConf['locationConfig']['minDeliveryAmt'] = Math.round(
                minDeliveryAmt * 100
            );
            deliveryConf['deliveryThreshold'] = Math.round(
                deliveryThreshold * 100
            );
            deliveryConf['locationConfig']['maxDistance'] = parseInt(
                deliveryConf['locationConfig']['maxDistance']
            );
            let nFees = [];
            for (let f of fees) {
                let nf = { ...f };
                nf.fee = Math.round(nf.fee * 100);
                nf.start = parseFloat(nf.start).toFixed(1);
                nf.end = parseFloat(nf.end).toFixed(1);
                nFees.push(nf);
            }
            deliveryConf.locationConfig.fees = nFees;

            if (deliveryConf['deliveryAheadEnabled']) {
                if (!deliveryConf['scheduledOrderDays']) {
                    setLoaded(false);
                    alert(
                        'please update number of days scheduled order ahead days'
                    );
                    return;
                }
            }
        }

        //setDeliveryConfig(deliveryConf);
        deliveryConfsetting[
            'menus/' + restauHook.restauId + '/details/deliveryConfig'
        ] = deliveryConf;
        await database
            .ref()
            .update(deliveryConfsetting)
            .then(function () {
                setSaveMessage('Succesfully updated');
            })
            .catch(function (error) {
                alert('Error while saving please try again');
                //Need to pop error someways
                console.log(error);
                setLoaded(false);
                return;
            });

        setLoaded(false);
    }

    async function loadData(restauHook) {
        setLoaded(true);
        if (!restauHook.restauId) {
            setLoaded(false);
            return;
        }
        if (!window.customerApp.auth().currentUser) {
            //debugger;
            history.push('/login');
            return;
        }
        const token = await window.customerApp.auth().currentUser.getIdToken();

        let config = {
            headers: {
                token: token,
            },
        };
        console.log('Restau Hook from restaurant: ' + restauHook.restauId);
        const admin = window.customerApp.database();
        let payAtRef = await admin.ref(
            'menus/' + restauHook.restauId + '/details/deliveryConfig'
        );
        await payAtRef
            .once('value')
            .then(function (snapshot) {
                let result = snapshot.val();
                let noDefault = false;
                setEnabled(false);
                if (!result || !result.locationConfig) {
                    result = { locationConfig: {} };
                    noDefault = true;
                }
                if (result.enabled) {
                    setEnabled(true);
                }

                if (!result.locationConfig || !result.locationConfig.fees) {
                    let f = [{ start: null, end: null, fee: null }];
                    setFees([...f]);
                }

                if (noDefault) {
                    setDefault();
                    result = deliveryConfig;
                } else {
                    setDeliveryConfig(result);
                    loadMap(result);
                }

                setMinDeliveryAmt(
                    result.locationConfig.minDeliveryAmt
                        ? (result.locationConfig.minDeliveryAmt / 100).toFixed(
                              2
                          )
                        : 0
                );
                setDeliveryThreshold(
                    result.deliveryThreshold
                        ? (result.deliveryThreshold / 100).toFixed(2)
                        : 0
                );

                if (result.locationConfig.fees) {
                    let nFee = [...result.locationConfig.fees];

                    for (let nf of nFee) {
                        nf.fee = (nf.fee / 100).toFixed(2);
                    }
                    setFees(nFee);
                }
                setLoaded(false);

                return result;
            })
            .catch(function (error) {
                setLoaded(false);
                console.log(error);
            });

        return;
    }
    useEffect(async () => {
        setLoaded(true);
        loadData(restauHook);
    }, [restauHook.restauId]);
    return (
        <div>
            {!deliveryConfig ? (
                <div>
                    <img
                        className={classes.loadingImage}
                        src="../../img/spinner.gif"
                    />
                </div>
            ) : (
                <>
                    <div>
                        <div style={{ maxWidth: 350 }}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">
                                    Delivery Enabled
                                </FormLabel>
                                <RadioGroup
                                    aria-label="print1"
                                    name="print1"
                                    value={enabled ? 'enabled' : 'disabled'}
                                    onChange={handleChange}
                                >
                                    <Grid container spacing={24}>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                marginTop: '10px',
                                                marginBottom: '5px',
                                                marginRight: '15px',
                                            }}
                                        >
                                            <FormControlLabel
                                                value="enabled"
                                                control={<Radio />}
                                                label="Enable"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                marginTop: '10px',
                                                marginBottom: '5px',
                                                marginRight: '5px',
                                            }}
                                        >
                                            <FormControlLabel
                                                value="disabled"
                                                control={<Radio />}
                                                label="Disabled"
                                            />
                                        </Grid>
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </div>
                        {enabled && (
                            <>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={4}
                                        style={{
                                            marginTop: '10px',
                                            marginBottom: '5px',
                                            marginRight: '5px',
                                        }}
                                    >
                                        <TextField
                                            type="number"
                                            id="deliveryThreshold"
                                            label="Free Delivery After"
                                            variant="outlined"
                                            // fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        $
                                                    </InputAdornment>
                                                ),
                                                maxLength: 20,
                                            }}
                                            value={deliveryThreshold}
                                            onChange={(e) =>
                                                setDeliveryThreshold(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        style={{
                                            marginTop: '10px',
                                            marginBottom: '5px',
                                            marginRight: '5px',
                                        }}
                                    >
                                        <TextField
                                            type="number"
                                            id="minDeliveryAmt"
                                            label="Minimum Delivery Amount"
                                            variant="outlined"
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        $
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={minDeliveryAmt}
                                            onChange={(e) =>
                                                handleLocationChange(
                                                    e,
                                                    'minDeliveryAmt'
                                                )
                                            }
                                            style={{ marginRight: '20px' }}
                                        />
                                    </Grid>
                                </Grid>

                                <br />

                                <FormControl component="fieldset">
                                    <h2>Store Location Config</h2>
                                    <TextField
                                        id="maxDistance"
                                        label="Maximum Delivery Distance in miles"
                                        variant="outlined"
                                        inputProps={{
                                            inputMode: 'numeric',
                                        }}
                                        color="secondary"
                                        value={
                                            deliveryConfig.locationConfig
                                                ? deliveryConfig.locationConfig
                                                      .maxDistance
                                                : ''
                                        }
                                        onChange={(e) =>
                                            handleLocationChange(
                                                e,
                                                'maxDistance'
                                            )
                                        }
                                        style={{ marginRight: '20px' }}
                                    />
                                    <br />
                                    <br />
                                    <Grid container spacing={24}>
                                        <Grid
                                            item
                                            xs={5}
                                            style={{
                                                marginTop: '10px',
                                                marginBottom: '5px',
                                                marginRight: '5px',
                                            }}
                                        >
                                            <TextField
                                                id="lat"
                                                label="Lat of store"
                                                variant="outlined"
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                                color="secondary"
                                                value={
                                                    deliveryConfig.locationConfig
                                                        ? deliveryConfig
                                                              .locationConfig
                                                              .lat
                                                        : ''
                                                }
                                                onChange={(e) =>
                                                    handleLocationChange(
                                                        e,
                                                        'lat'
                                                    )
                                                }
                                                style={{ marginRight: '20px' }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={5}
                                            style={{
                                                marginTop: '10px',
                                                marginBottom: '5px',
                                                marginRight: '5px',
                                            }}
                                        >
                                            <TextField
                                                id="long"
                                                label="Long of store"
                                                variant="outlined"
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                                color="secondary"
                                                value={
                                                    deliveryConfig.locationConfig
                                                        ? deliveryConfig
                                                              .locationConfig
                                                              .lng
                                                        : ''
                                                }
                                                onChange={(e) =>
                                                    handleLocationChange(
                                                        e,
                                                        'lng'
                                                    )
                                                }
                                                style={{ marginRight: '20px' }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <div id="container21" />
                                </FormControl>
                                <h2>Fees Config based on miles</h2>
                                {fees &&
                                    fees.length > 0 &&
                                    fees.map((fee, index) => (
                                        <div key={index}>
                                            <Grid container spacing={24}>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    style={{
                                                        marginTop: '10px',
                                                        marginBottom: '5px',
                                                        marginRight: '5px',
                                                    }}
                                                >
                                                    <TextField
                                                        id={'start' + index}
                                                        label="Start mile"
                                                        variant="outlined"
                                                        inputProps={{
                                                            inputMode:
                                                                'numeric',
                                                        }}
                                                        color="secondary"
                                                        value={fee.start}
                                                        onChange={(e) =>
                                                            onChangeFees(
                                                                e,
                                                                index,
                                                                'start'
                                                            )
                                                        }
                                                        style={{
                                                            marginRight: '20px',
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    style={{
                                                        marginTop: '10px',
                                                        marginBottom: '10px',
                                                        marginRight: '5px',
                                                    }}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        id={'end' + index}
                                                        label="End mile"
                                                        variant="outlined"
                                                        inputProps={{
                                                            inputMode:
                                                                'numeric',
                                                        }}
                                                        color="secondary"
                                                        value={fee.end}
                                                        onChange={(e) =>
                                                            onChangeFees(
                                                                e,
                                                                index,
                                                                'end'
                                                            )
                                                        }
                                                        style={{
                                                            marginRight: '20px',
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    style={{
                                                        marginTop: '10px',
                                                        marginBottom: '10px',
                                                        marginRight: '5px',
                                                    }}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        id={'fee' + index}
                                                        label="Fees"
                                                        variant="outlined"
                                                        inputProps={{
                                                            inputMode:
                                                                'numeric',
                                                        }}
                                                        color="secondary"
                                                        value={fee.fee}
                                                        onChange={(e) =>
                                                            onChangeFees(
                                                                e,
                                                                index,
                                                                'fee'
                                                            )
                                                        }
                                                        style={{
                                                            marginRight: '20px',
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={2}
                                                    style={{
                                                        marginTop: '10px',
                                                        marginBottom: '10px',
                                                        marginRight: '5px',
                                                    }}
                                                >
                                                    <div>
                                                        <Button
                                                            size="medium"
                                                            variant="contained"
                                                            color="primary"
                                                            style={{
                                                                marginLeft:
                                                                    '10px',
                                                            }}
                                                            onClick={() => {
                                                                addFee(index);
                                                            }}
                                                            style={{
                                                                width: 20,
                                                                marginTop: 10,
                                                                marginRight:
                                                                    '10px',
                                                            }}
                                                        >
                                                            +
                                                        </Button>
                                                        <Button
                                                            size="medium"
                                                            variant="contained"
                                                            color="primary"
                                                            style={{
                                                                marginLeft:
                                                                    '10px',
                                                            }}
                                                            onClick={() => {
                                                                deleteFee(
                                                                    index
                                                                );
                                                            }}
                                                            style={{
                                                                width: 20,
                                                                marginTop: 10,
                                                            }}
                                                        >
                                                            -
                                                        </Button>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    ))}
                            </>
                        )}
                        {enabled && (
                            <div style={{ maxWidth: 350 }}>
                                <h2>Delivery Ahead config</h2>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                        Delivery Ahead Enabled
                                    </FormLabel>
                                    <RadioGroup
                                        aria-label="print1"
                                        name="dahead1"
                                        value={
                                            deliveryConfig.deliveryAheadEnabled
                                                ? 'enabled'
                                                : 'disabled'
                                        }
                                        onChange={handleAheadChange}
                                    >
                                        <FormControlLabel
                                            value="enabled"
                                            control={<Radio />}
                                            label="Enable"
                                        />
                                        <FormControlLabel
                                            value="disabled"
                                            control={<Radio />}
                                            label="Disabled"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <br />
                                <br />
                            </div>
                        )}
                        {enabled && deliveryConfig.deliveryAheadEnabled && (
                            <>
                                <TextField
                                    id="scheduledOrderDays"
                                    label="Scheduled Delivery Order Ahead days"
                                    variant="outlined"
                                    inputProps={{
                                        inputMode: 'numeric',
                                    }}
                                    value={deliveryConfig.scheduledOrderDays}
                                    onChange={(e) =>
                                        handleLocationChange(
                                            e,
                                            'scheduledOrderDays'
                                        )
                                    }
                                />
                            </>
                        )}
                        <br />
                        <br />
                        <HoursDialog isOpen={isOpen} setOpen={setOpen} />

                        <Button
                            size="medium"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setOpen(true);
                            }}
                            style={{ width: 200, marginTop: 10 }}
                        >
                            Delivery Hours
                        </Button>
                        <br />
                    </div>
                    {loaded === true ? (
                        <div>
                            <img
                                height="75px"
                                className={classes.loadingImage}
                                src="../../img/spinner.gif"
                            />
                        </div>
                    ) : (
                        <>
                            <div style={{ maxWidth: 350 }}>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        updateSettings(restauHook);
                                    }}
                                    style={{ width: 200, marginTop: 10 }}
                                >
                                    Update
                                </Button>
                            </div>
                            <div
                                style={{
                                    paddingLeft: '10px',
                                    marginTop: '30px',
                                }}
                            >
                                {saveMessage}
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
}
