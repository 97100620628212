import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router';
import Container from '@mui/material/Container';
import { useAuthentication } from './Hooks/useAuthentication';
import styled from 'styled-components';
import Alert from '@mui/material/Alert';

import Config from './config/Config';
const { default: axios } = require('axios');
var zipcode_to_timezone = require('zipcode-to-timezone');

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
            display: 'flex',
            flexWrap: 'wrap',
        },
        viewButtonLabel: {
            textTransform: 'none',
        },
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '25ch',
    },
}));

const ButtonGrid = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;
const Title = styled.div`
    font-family: 'Roboto';
`;

const corianderGreen = '#27ae60';
const ConfirmButton = styled(Title)`
    margin: 10px;
    color: white;
    font-weight: bold;
    height: 40px;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    width: 150px;
    cursor: pointer;
    background-color: ${corianderGreen};
    ${({ disabled }) =>
        disabled &&
        `
    opactity: .5; 
    background-color: grey; 
    pointer-events: none; 

    flex: 1 0 50%;
   text-align: center;
   padding: 10px;
   overflow: hidden;
  `}
`;

export default function Signup(props) {
    const auth = useAuthentication();
    const classes = useStyles();
    let navigate = useNavigate();
    const [loaded, setLoaded] = React.useState(true);
    const [saveLoader, setSaveLoader] = React.useState(false);
    saveLoader;
    const [merchantData, setMerchantData] = React.useState({});
    const [loginMessage, setLoginMessage] = React.useState('');
    const [values, setValues] = React.useState({
        password: '',
        email: '',
        error: '',
        showPassword: false,
    });

    const handleChange = (event, id) => {
        console.log('change called');
        setMerchantData({ ...merchantData, [id]: event.target.value });
        console.log(JSON.stringify(merchantData));
    };

    async function getMerchantInfo(merchantId, code, employeeId) {
        const getMerchantInfoURL = Config.getMerchantInfo;
        let merchantInfo = await axios.post(
            getMerchantInfoURL,
            {
                merchantId: merchantId,
                code: code,
                employeeId: employeeId,
            },
            { crossDomain: true }
        );
        if (merchantInfo.data.status === 'ERROR') {
            setMerchantData({ address: '' });
            setLoginMessage(merchantInfo.data.message);
            setLoaded(false);
            return;
        }
        merchantInfo.data.timezone = zipcode_to_timezone.lookup(
            merchantInfo.data.address.zip
        );
        const search = window.location.search;
        const params = new URLSearchParams(search);
        var merchantId = params.get('merchant_id'); // None
        var code = params.get('code');
        merchantInfo.data.merchantId = merchantId;
        merchantInfo.data.code = code;
        setMerchantData(merchantInfo.data);
        setLoaded(false);
    }
    useEffect(() => {
        setLoaded(true);

        // Update the document title using the browser API
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const merchantId = params.get('merchant_id'); // None
        const code = params.get('code');
        const employeeId = params.get('employee_id');
        if (merchantId && code) {
            getMerchantInfo(merchantId, code, employeeId);
        }
    }, [values.email]);
    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            handleLogin(event);
        }
    }
    async function handleLogin(e) {
        const signupURL = Config.signup;
        if (!merchantData.email) {
            setLoginMessage('Please enter your email id');
            return;
        }
        if (!merchantData.phone) {
            setLoginMessage('Please enter restaurant phone number');
            return;
        }
        if (!merchantData.password) {
            setLoginMessage('Please add password');
            return;
        }
        setSaveLoader(true);
        let signup = await axios.post(signupURL, merchantData);
        if (signup && signup.data) {
            if (signup.data.status === 'ERROR') {
                setLoginMessage(signup.data.message);
            } else {
                window.customerApp
                    .auth()
                    .signInWithEmailAndPassword(
                        merchantData.email,
                        merchantData.password
                    )
                    .then((res) => {
                        setLoaded(false);
                        if (res.user) {
                            auth.loggedInUser(res.user);
                            navigate('/home?isCloverFirst=true');
                        }
                    })
                    .catch((e) => {
                        setLoaded(false);
                        setLoginMessage('Invalid login');
                    });
            }
        }
        setSaveLoader(false);
    }
    if (loaded || !merchantData) {
        return (
            <div>
                <Alert severity="info">
                    Please contact contact@strideq.com /(650) 282-1239 for any
                    support
                </Alert>

                <img
                    className={classes.loadingImage}
                    src="../../img/spinner.gif"
                />
            </div>
        );
    }

    return (
        <>
            <Container
                component="main"
                maxWidth="xs"
                style={{ marginTop: '120px' }}
            >
                <div
                    className="flex flexDir"
                    style={{ marginLeft: '60px', margin: '0 auto' }}
                >
                    <img
                        alt="logo"
                        src="../../img/green-favicon.png"
                        style={{
                            width: '40px',
                            height: '40px',
                            marginRight: '10px',
                        }}
                    />
                    <img
                        alt="logo"
                        src="../../img/strideQ-logo.png"
                        style={{ width: '120px', height: '40px' }}
                    />
                </div>
                <Alert severity="info">
                    Please contact <b>contact@strideq.com / 650-282-1239</b> for
                    any support
                </Alert>
                <Typography
                    component="h1"
                    variant="h5"
                    style={{ marginTop: '20px' }}
                ></Typography>

                <div className="flex alignBtn">
                    <TextField
                        fullWidth
                        id="outlined-email-input"
                        label="Restaurant Name"
                        type="text"
                        autoComplete="current-email"
                        variant="outlined"
                        value={merchantData.name}
                        onChange={(e) => handleChange(e, 'name')}
                        style={{ width: '100%', marginBottom: '20px' }}
                    />
                </div>

                <div className="flex alignBtn">
                    <TextField
                        fullWidth
                        id="outlined-email-input"
                        label="Address"
                        type="text"
                        autoComplete="current-email"
                        variant="outlined"
                        value={merchantData.address.address1}
                        style={{ width: '100%', marginBottom: '20px' }}
                    />
                </div>

                <div className="flex alignBtn">
                    <TextField
                        fullWidth
                        id="outlined-email-input"
                        label="Restaurant Phone number"
                        type="text"
                        autoComplete="current-email"
                        variant="outlined"
                        value={merchantData.phone}
                        onChange={(e) => handleChange(e, 'phone')}
                        style={{ width: '100%', marginBottom: '20px' }}
                    />
                </div>

                <div className="flex alignBtn">
                    <TextField
                        fullWidth
                        id="outlined-email-input"
                        label="Email"
                        type="email"
                        autoComplete="current-email"
                        variant="outlined"
                        value={merchantData.email}
                        onChange={(e) => handleChange(e, 'email')}
                        style={{ width: '100%', marginBottom: '20px' }}
                    />
                </div>
                <div className="flex alignBtn">
                    <TextField
                        fullWidth
                        id="outlined-email-input"
                        label="Password"
                        type="password"
                        autoComplete="current-email"
                        variant="outlined"
                        value={merchantData.password}
                        onChange={(e) => handleChange(e, 'password')}
                        style={{ width: '100%', marginBottom: '20px' }}
                    />
                </div>
                <>
                    {loaded && (
                        <div>
                            <img
                                className={classes.loadingImage}
                                src="../../img/spinner.gif"
                            />
                        </div>
                    )}
                    {loginMessage && (
                        <Alert severity="error">{loginMessage}</Alert>
                    )}
                </>
                <ButtonGrid>
                    {saveLoader ? (
                        <div>
                            <img
                                className={classes.loadingImage}
                                src="../../img/spinner.gif"
                            />
                        </div>
                    ) : (
                        <>
                            <ConfirmButton
                                onClick={() => {
                                    handleLogin();
                                }}
                            >
                                {'Sign up'}
                            </ConfirmButton>
                            <ConfirmButton
                                onClick={() => {
                                    navigate('/login');
                                }}
                            >
                                {'Login'}
                            </ConfirmButton>
                        </>
                    )}
                </ButtonGrid>
            </Container>
        </>
    );
}
