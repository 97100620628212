import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Assessment from '@mui/icons-material/Assessment';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ComputerIcon from '@mui/icons-material/Computer';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
// import LockIcon from '@mui/icons-material/Lock';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MoreVert from '@mui/icons-material/MoreVert';
import SummarizeIcon from '@mui/icons-material/Summarize';
import GroupIcon from '@mui/icons-material/Group';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import GridViewIcon from '@mui/icons-material/GridView';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import BusinessIcon from '@mui/icons-material/Business';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';

const navOptions = [
    {
        path: '/home',
        icon: DashboardIcon,
        text: 'Home',
        index: 0,
        forStrideQOnlineCustOnly: false,
    },
    {
        path: '/reports',
        icon: ReceiptIcon,
        text: 'Reports',
        index: 7,
        forStrideQOnlineCustOnly: true,
    },
    {
        path: '/tax',
        icon: CardMembershipIcon,
        text: 'Sales Tax',
        index: 3,
        forStrideQOnlineCustOnly: true,
    },
    {
        path: '/menu',
        icon: MenuBookIcon,
        text: 'Menu',
        index: 2,
        forStrideQOnlineCustOnly: false,
    },
    {
        path: '/hours',
        icon: AccessTimeIcon,
        text: 'Hours',
        index: 8,
        forStrideQOnlineCustOnly: true,
    },
    {
        path: '/design',
        icon: MoreVert,
        text: 'Design',
        index: 6,
        forStrideQOnlineCustOnly: true,
    },
    {
        path: '/messaging',
        icon: LocalOfferIcon,
        text: 'Custom Messaging',
        index: 7,
        forStrideQOnlineCustOnly: true,
    },
    {
        path: '/phone',
        icon: PhoneInTalkIcon,
        text: 'Phone Menu',
        index: 14,
        forStrideQOnlineCustOnly: false,
        childs: [
            {
                path: '/phone/reports',
                icon: AssessmentIcon,
                text: 'Reports',
                index: 15,
                forStrideQOnlineCustOnly: false,
            },
            {
                path: '/phone/logs',
                icon: SummarizeIcon,
                text: 'Logs',
                index: 16,
                forStrideQOnlineCustOnly: false,
            },
            {
                path: '/phone/callers',
                icon: GroupIcon,
                text: 'Callers',
                index: 16,
                forStrideQOnlineCustOnly: false,
            },
            {
                path: '/phone/settings',
                icon: SettingsIcon,
                text: 'Settings',
                index: 17,
                forStrideQOnlineCustOnly: false,
            },
            {
                path: '/phone/audioLibrary',
                icon: LibraryMusicIcon,
                text: 'Audio Library',
                index: 24,
                forStrideQOnlineCustOnly: false,
            },
        ],
    },
    {
        path: '/catering',
        icon: FoodBankIcon,
        text: 'Catering',
        index: 18,
        forStrideQOnlineCustOnly: false,
        childs: [
            {
                path: '/catering/dashboard',
                icon: GridViewIcon,
                text: 'Dashboard',
                index: 19,
                forStrideQOnlineCustOnly: false,
            },
            {
                path: '/catering/search',
                icon: ManageSearchIcon,
                text: 'Search',
                index: 21,
                forStrideQOnlineCustOnly: false,
            },
            {
                path: '/catering/setting',
                icon: SettingsApplicationsIcon,
                text: 'Form Settings',
                index: 20,
                forStrideQOnlineCustOnly: false,
            },
        ],
    },
    {
        path: '/orders',
        icon: FindInPageIcon,
        text: 'Orders',
        index: 11,
        forStrideQOnlineCustOnly: true,
    },
    {
        path: '/86report',
        icon: Assessment,
        text: '86 Report',
        index: 9,
        forStrideQOnlineCustOnly: true,
    },
    {
        path: '/profile',
        icon: AccountBoxIcon,
        text: 'Profile',
        index: 4,
        forStrideQOnlineCustOnly: false,
    },
    {
        path: '/pos',
        icon: ComputerIcon,
        text: 'PoS Settings',
        index: 12,
        forStrideQOnlineCustOnly: true,
    },
    {
        path: '/delivery',
        icon: ComputerIcon,
        text: 'Delivery Settings',
        index: 13,
        forStrideQOnlineCustOnly: true,
    },
    {
        path: '/dinein',
        icon: LocalDiningIcon,
        text: 'Dine-in',
        index: 5,
        forStrideQOnlineCustOnly: true,
    },
    {
        path: '/multilocation',
        icon: BusinessIcon,
        text: 'Multilocation',
        index: 22,
        forStrideQOnlineCustOnly: false,
    },
    // {
    //     path: '/crm',
    //     icon: ComputerIcon,
    //     text: 'CRM',
    //     index: 15,
    // },
    // {
    //     path: '/subscription',
    //     icon: SubscriptionsIcon,
    //     text: 'Subscription',
    //     index: 10,
    // },
    // {
    //     path: '/items',
    //     icon: TocIcon,
    //     text: 'Items',
    //     index: 1,
    // },
];

export default navOptions;
