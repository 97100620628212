import React, { useContext } from 'react';
import CateringContext from '../../../CateringContext';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Divider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Conversation = () => {
    const { conversation } = useContext(CateringContext);
    return (
        <div>
            {conversation && (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="conversation-content"
                        id="conversation-header"
                        sx={{
                            backgroundColor: '#C5C5C5',
                        }}
                    >
                        <Typography variant="body2" sx={{ fontWeight: 500 }}>
                            Click to review detailed AI bot order conversation
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        {conversation.map((p) => (
                            <>
                                <Typography variant="body2">{p}</Typography>
                                <Divider />
                            </>
                        ))}
                    </AccordionDetails>
                </Accordion>
            )}
        </div>
    );
};

export default Conversation;
