import React, { useState } from 'react';
import SubmitButton from '../formControls/SubmitButton';
import PropTypes from 'prop-types';
import CardDetailsForm from './CardDetailsForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Config from '../../config/Config';

const stripePromise = loadStripe(Config.stripe_publish_key);

function AddCardPendingForm(props) {
    const {
        successMessage,
        loaded,
        accept,
        setLoginMessage,
        clientSecret,
        setCardSuccess,
        sendStatus,
        setLoaded,
        setPaymentMode,
    } = props;
    const [openAddCardDialog, setOpenAddCardDialog] = useState(false);

    async function addCardDetails() {
        //debugger;
        if (!accept) {
            setLoginMessage('Please accept to proceed');
            return;
        }
        setPaymentMode('CARD');
        setOpenAddCardDialog(true);
    }

    return (
        <>
            <SubmitButton
                severity={successMessage ? 'success' : ''}
                showProgress={!loaded}
                disabled={!loaded}
                label="Credit Card"
                onClick={addCardDetails}
            />
            <Elements stripe={stripePromise}>
                <CardDetailsForm
                    openAddCardDialog={openAddCardDialog}
                    setOpenAddCardDialog={setOpenAddCardDialog}
                    clientSecret={clientSecret}
                    setLoginMessage={setLoginMessage}
                    setCardSuccess={setCardSuccess}
                    sendStatus={sendStatus}
                    setLoaded={setLoaded}
                />
            </Elements>
        </>
    );
}

AddCardPendingForm.propTypes = {
    successMessage: PropTypes.string,
    loaded: PropTypes.bool,
    accept: PropTypes.bool,
    setLoginMessage: PropTypes.string,
    clientSecret: PropTypes.string,
    setCardSuccess: PropTypes.func,
    sendStatus: PropTypes.func,
    setLoaded: PropTypes.func,
    setPaymentMode: PropTypes.func,
};

export default AddCardPendingForm;
