import PropTypes from 'prop-types';

import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogWithContent from '../../../components/common/DialogWithContent/DialogWithContent';
import { Button, Grid, TextField, Typography } from '@mui/material';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';

export default function AddEditChoiceDialog(props) {
    const {
        open,
        setOpen,
        foodItemChoice,
        setFoodItemChoice,
        isModifier,
        handleFinalChoiceUpdate,
        showDialogForAdd,
    } = props;
    //const menuHook = useContext(MenuInfoContext);
    const [tempFoodItemChoice, setTempFoodItemChoice] = useState({});

    const handleClose = () => {
        setOpen(false);
    };

    const getPrice = (price) => {
        if (!price) {
            return price;
        }
        let p = parseFloat(price) / 100;
        return p.toFixed(2);
    };

    const handleFoodItemChoiceChange = () => {
        if (tempFoodItemChoice.choiceslabel === '') {
            //Label cannot be empty or blank
            throw new Error('Label cannot be empty or blank');
        }

        if (tempFoodItemChoice.choices.length === 0) {
            //Atleast one choice should be present
            throw new Error('Atleast one choice should be present');
        }

        if (tempFoodItemChoice.choices.some((x) => x.name === '')) {
            //Choice Names cannot be empty/blank
            throw new Error('Choice Names cannot be empty/blank');
        }

        if (
            tempFoodItemChoice.choices.some(
                (x) => x.price === '' || isNaN(x.price)
            )
        ) {
            //Choice Price cannot be 0
            throw new Error('Choice Price cannot be empty');
        }
        console.log('Updated Choice data: ', tempFoodItemChoice);
        setFoodItemChoice((prev) => {
            return { ...prev, ...tempFoodItemChoice };
        });
        handleFinalChoiceUpdate(tempFoodItemChoice);
    };

    const handleAddNewChoice = () => {
        //debugger;
        let ids = tempFoodItemChoice.choices
            .map((x) => x.id)
            .sort(function (a, b) {
                return a - b;
            });
        let newId = parseInt(ids[ids.length - 1]) + 1;
        setTempFoodItemChoice({
            ...tempFoodItemChoice,
            choices: [
                ...tempFoodItemChoice.choices,
                { id: newId.toString(), name: '', price: 0 },
            ],
        });
    };

    const handleDeleteChoice = (choiceId) => {
        // debugger;
        let index = tempFoodItemChoice.choices
            .map((x) => x.id)
            .indexOf(choiceId);
        setTempFoodItemChoice({
            ...tempFoodItemChoice,
            choices: tempFoodItemChoice.choices.filter(
                (x, ind) => ind !== index
            ),
        });
    };

    const handleEditChoice = (identifier, val) => {
        //debugger;
        if (identifier === '') return;

        let id = identifier.slice(-1);
        let data = tempFoodItemChoice.choices.filter((x) => x.id === id)[0];
        let updatedChoice = {};
        if (identifier.slice(0, -1) === 'name') {
            updatedChoice = { ...data, name: val };
        } else if (identifier.slice(0, -1) === 'price') {
            updatedChoice = { ...data, price: parseFloat(val) * 100 };
        }

        let allChoices = [
            ...tempFoodItemChoice.choices.filter((x) => x.id !== id),
            updatedChoice,
        ].sort(function (a, b) {
            return a.id - b.id;
        });

        setTempFoodItemChoice({
            ...tempFoodItemChoice,
            choices: [...allChoices],
        });
    };

    useEffect(() => {
        //console.log('Loading data..');
        if (open === true) {
            if (foodItemChoice === null) {
                //set with default values
                setTempFoodItemChoice({
                    choiceslabel: '',
                    choices: [{ id: '1', name: '', price: 0 }],
                });
            } else {
                setTempFoodItemChoice({ ...foodItemChoice });
            }

            //console.log('tempStationMappings', tempStationMappings);
        }
    }, [open]);

    return (
        <DialogWithContent
            open={open}
            dialogTitle={'Add/Edit Choice'}
            setOpen={setOpen}
            dialogMaxWidth={'sm'}
            dialogCloseBtnText={'Close'}
            dialogSaveBtnText={'Save'}
            handleSave={handleFoodItemChoiceChange}
        >
            <Grid container spacing={2} alignItems="center">
                {tempFoodItemChoice &&
                    tempFoodItemChoice.choices &&
                    tempFoodItemChoice.choices.length > 0 && (
                        <>
                            <Grid
                                container
                                item
                                xs={12}
                                spacing={2}
                                sx={{ ml: 0.1 }}
                            >
                                <TextField
                                    id="choiceLabel"
                                    label="Choice Label"
                                    defaultValue={
                                        tempFoodItemChoice.choiceslabel
                                    }
                                    size="small"
                                    margin="dense"
                                    onChange={(e) => {
                                        setTempFoodItemChoice({
                                            ...tempFoodItemChoice,
                                            choiceslabel: e.target.value,
                                        });
                                    }}
                                    variant="outlined"
                                />
                                <Button
                                    sx={{
                                        //alignSelf: 'flex-end',
                                        border: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    onClick={() => {
                                        handleAddNewChoice();
                                    }}
                                >
                                    <AddCircleSharpIcon
                                        color="primary"
                                        sx={{ fontSize: 23 }}
                                    />
                                    <Typography sx={{ ml: 0.5 }}>
                                        {'Add Choice'}
                                    </Typography>
                                </Button>
                            </Grid>

                            {tempFoodItemChoice.choices.map((choice, i) => (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        spacing={2}
                                        key={i}
                                        sx={{
                                            //alignSelf: 'flex-end',
                                            border: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <TextField
                                            label="Choice Name"
                                            value={choice.name}
                                            size="small"
                                            margin="dense"
                                            id={'name' + choice.id}
                                            onChange={(e) => {
                                                handleEditChoice(
                                                    e.currentTarget.getAttribute(
                                                        'id'
                                                    ),
                                                    e.target.value
                                                );
                                            }}
                                            variant="outlined"
                                        />
                                        <TextField
                                            label="Choice Price"
                                            defaultValue={getPrice(
                                                choice.price
                                            )}
                                            size="small"
                                            margin="dense"
                                            id={'price' + choice.id}
                                            onChange={(e) => {
                                                handleEditChoice(
                                                    e.currentTarget.getAttribute(
                                                        'id'
                                                    ),
                                                    e.target.value
                                                );
                                            }}
                                            variant="outlined"
                                            type="number"
                                        />
                                        <DeleteIcon
                                            id={choice.id}
                                            style={{
                                                topPadding: '120px',
                                            }}
                                            onClick={(e) => {
                                                handleDeleteChoice(
                                                    e.currentTarget.getAttribute(
                                                        'id'
                                                    )
                                                );
                                            }}
                                        />
                                    </Grid>
                                </>
                            ))}
                        </>
                    )}
                {!isModifier && (
                    <Button
                        sx={{
                            //alignSelf: 'flex-end',
                            border: 0,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        // onClick={() => {
                        //      addChoices(openFood);
                        // }}
                    >
                        <AddCircleSharpIcon
                            color="primary"
                            sx={{ fontSize: 18 }}
                        />
                        <Typography sx={{ ml: 0.5 }}>{'Add Choice'}</Typography>
                    </Button>
                )}
            </Grid>
        </DialogWithContent>
    );
}

AddEditChoiceDialog.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    foodItemChoice: PropTypes.shape({
        choices: PropTypes.array,
        choiceslabel: PropTypes.string,
    }),
    setFoodItemChoice: PropTypes.func,
    isModifier: PropTypes.bool,
    handleFinalChoiceUpdate: PropTypes.func,
    showDialogForAdd: PropTypes.bool,
};
