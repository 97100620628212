import { Grid, Typography } from '@mui/material';
import React from 'react';
import InputField from '../../components/formControls/InputField';

import { useContext } from 'react';
import QRContext from '../QRContext';
function PosterSettings() {
    const { trackingCode, orderInstruction, handleSettingChange } =
        useContext(QRContext);

    const handleInputChange = (e) => {
        handleSettingChange(e.target.name, e.target.value);
    };

    return (
        <>
            <Typography variant="h6" component="h6" margin="default">
                Poster Settings
            </Typography>

            <Grid container spacing={1} xs={12}>
                <Grid item xs={12}>
                    <Grid container spacing={1} direction="row">
                        <Grid item md={6}>
                            <InputField
                                id="order-instruction"
                                label="Order Instruction"
                                name="orderInstruction"
                                type="text"
                                value={orderInstruction}
                                placeholder="Customer Waiting Outside"
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1} direction="row">
                        <Grid item md={6}>
                            <InputField
                                id="tracking-code"
                                label="Tracking Code"
                                name="trackingCode"
                                type="text"
                                value={trackingCode}
                                placeholder="qrposter"
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

PosterSettings.propTypes = {};

export default PosterSettings;
