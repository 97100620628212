import { useState } from 'react';

export function useNumberInput(defaultState) {
    const [state, setState] = useState(defaultState);

    function onChange(e) {
        console.log('useNumberInput: old ', state, +e.target.value);
        const newState = { ...state };
        let newVal;
        if (e.target.value == '') {
            newVal = '';
        } else {
            newVal = +e.target.value;

            if ('min' in newState && newVal <= newState.min) {
                newVal = newState.min;
            }
            if ('max' in newState && newVal > newState.max) {
                newVal = newState.max;
            }
        }

        newState.value = newVal;
        console.log('useNumberInput: new ', newState, +e.target.value);
        setState(newState);
    }

    function setValue(val) {
        const newState = { ...state };
        newState.value = val;
        setState(newState);
    }

    return {
        value: state.value,
        onChange,
        setValue,
        min: state.min,
        max: state.max,
        type: 'number',
    };
}
