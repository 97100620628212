import React from 'react';
import DialogWithContent from '../common/DialogWithContent/DialogWithContent';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
};

function CardDetailsForm(props) {
    const {
        openAddCardDialog,
        setOpenAddCardDialog,
        clientSecret,
        setLoginMessage,
        setCardSuccess,
        sendStatus,
        setLoaded,
    } = props;
    const elements = useElements();
    const stripe = useStripe();

    async function confirmCardPayment() {
        //debugger;
        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: 'Test',
                },
            },
        });
        console.log('setup Intent ', result.paymentIntent);
        console.log('error', result.error);

        if (result.error) {
            setLoginMessage(result.error.message);
        } else if (result.paymentIntent.status === 'requires_payment_method') {
            // Confirmation failed. Attempt again with a different payment method.
        } else if (result.paymentIntent.status === 'succeeded') {
            // alert('processing ');
            await sendStatus('CARD_SUCCESS');
            setCardSuccess(true);
            setLoaded(true);
        }
    }

    return (
        <DialogWithContent
            open={openAddCardDialog}
            setOpen={setOpenAddCardDialog}
            dialogTitle="Add Card"
            dialogMaxWidth="xs"
            dialogCloseBtnText={'Cancel'}
            dialogSaveBtnText={'OK'}
            handleSave={confirmCardPayment}
        >
            <Typography component="h2">Enter Card Details</Typography>
            <br />
            {/* This section is for card details */}
            <CardElement options={CARD_ELEMENT_OPTIONS} />
            {/* This section is for card details */}
        </DialogWithContent>
    );
}

CardDetailsForm.propTypes = {
    openAddCardDialog: PropTypes.bool,
    setOpenAddCardDialog: PropTypes.func,
    clientSecret: PropTypes.string,
    setLoginMessage: PropTypes.func,
    setCardSuccess: PropTypes.func,
    sendStatus: PropTypes.func,
    setLoaded: PropTypes.func,
};

export default CardDetailsForm;
