import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { ModifierEditGroup } from './edit/ModifierEditGroup';

const useStyles = makeStyles(() => ({
    paper: { minWidth: '50%' },
}));
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function ModifierAddDialog({
    modifierHook,
    restauHook,
    openModEdit,
    setOpenModEdit,
    refreshMenu,
    setClose,
    modifierAddEditDialogTitle,
}) {
    let classes = useStyles();
    console.log('openModEdit' + openModEdit);
    let currentModifier = modifierHook.getCurrentAddEditModifier();
    const handleClose = () => {
        setOpenModEdit(false);
        setClose(true);
        modifierHook.setAddEditModifierId(null);
        //setCurrentModifier(null);
        refreshMenu();
    };

    async function saveChanges() {
        if (modifierHook.modifiers) {
            for (let modId in modifierHook.modifiers) {
                let modifier = modifierHook.modifiers[modId];
                console.log('modId: ' + modId);
                console.log('Label' + modifier.label);
                if (!modifier.label || modifier.label.trim() === '') {
                    alert('please add label for the modifier');
                    return;
                }
                if (modifier.max || modifier.min) {
                    if (
                        modifier.max &&
                        modifier.max != 0 &&
                        modifier.min &&
                        modifier.min > modifier.max
                    ) {
                        alert(
                            'Minimum modifier value should be less than maximum'
                        );
                        return;
                    }
                }
                if (modifier.options) {
                    for (let option of modifier.options) {
                        if (
                            !option ||
                            !option.label ||
                            option.price === undefined ||
                            option.price === '' ||
                            isNaN(option.price) ||
                            option.price < 0
                        ) {
                            alert(
                                'please ensure valid data for label / price for modifier options.'
                            );
                            return;
                        }
                    }
                }
            }
            const database = window.customerApp.database();
            let updates = {};
            updates['menus/' + restauHook.restauId + '/modifiers'] =
                modifierHook.modifiers;
            console.log('menus/' + restauHook.restauId + 'modifiers');
            await database
                .ref()
                .update(updates)
                .then(function () {
                    setClose(true);
                    refreshMenu();

                    alert('Modifier changes saved successfully.');
                    handleClose();
                })
                .catch(function (error) {
                    alert('Error while saving please try again');
                    //Need to pop error someways
                    return;
                });
        }
        // modifierHook.updateCurrentModifiers(isEdit,modifierIndx,currentModifier);
        // handleClose();
    }

    return (
        <Dialog
            onClose={console.log('tesdst')}
            aria-labelledby="customized-dialog-title"
            open={openModEdit}
            classes={{ paper: classes.paper }}
        >
            <DialogTitle
                id="customized-dialog-title"
                onClose={(e) => {
                    handleClose();
                }}
            >
                {modifierAddEditDialogTitle}
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    <FormControl variant="outlined" size="small" margin="dense">
                        {/* <InputLabel id="demo-simple-select-outlined-label">
                            All Modifiers
                        </InputLabel>

                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={currentModifier ? currentModifier.id : null}
                            onChange={handleChange}
                            label="All Modifiers"
                        >
                            {modifierHook &&
                                modifierHook.modifiers &&
                                Object.keys(modifierHook.modifiers).map(
                                    (key, indx) => (
                                        <MenuItem
                                            value={
                                                modifierHook.modifiers[key].id
                                            }
                                        >
                                            {modifierHook.modifiers[key].label}
                                        </MenuItem>
                                    )
                                )}
                        </Select> */}

                        <ModifierEditGroup modifierHook={modifierHook} />
                    </FormControl>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    variant="contained"
                    onClick={(e) => {
                        saveChanges();
                    }}
                    color="primary"
                >
                    Save changes
                </Button>
                <Button
                    autoFocus
                    onClick={(e) => {
                        handleClose();
                    }}
                    color="primary"
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
