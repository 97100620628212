import {
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import React from 'react';
import SelectWithLabel from '../../../../../components/common/SelectWithLabel';
import PropTypes from 'prop-types';
import { languages, DefaultWidgetValues } from '../../PhoneSettingsConstants';
import InfoIcon from '@mui/icons-material/Info';
import { useContext } from 'react';
import PhoneSettingsContext from '../../PhoneSettingsContext';
import { useStyles } from '../../../../../home/useStyles';

function SayMessageDetails(props) {
    const { msgDetails, setMsgDetails, readOnly } = props;
    const { restauId, restauName } = useContext(PhoneSettingsContext);
    const classes = useStyles();

    const handleMsgContentChange = (updatedMsg, id) => {
        //debugger;
        //console.log('Updated Msg: ', updatedMsg, 'id: ', id);
        let msgIndex = msgDetails.findIndex(
            (x) => x.name === id.split('-msg-content')[0]
        );
        let msgToUpdate = msgDetails[msgIndex];
        msgToUpdate.sayMessage = updatedMsg;
        setMsgDetails([
            ...msgDetails.slice(0, msgIndex),
            msgToUpdate,
            ...msgDetails.slice(msgIndex + 1),
        ]);
    };

    const handleMsgLanguageChange = (updatedLanguage, id) => {
        //debugger;
        console.log('Updated Language: ', updatedLanguage, 'id: ', id);
        let msgIndex = msgDetails.findIndex(
            (x) => x.name === id.split('-select-lang')[0]
        );
        let msgToUpdate = msgDetails[msgIndex];
        msgToUpdate.sayMessageLanguage = updatedLanguage;
        setMsgDetails([
            ...msgDetails.slice(0, msgIndex),
            msgToUpdate,
            ...msgDetails.slice(msgIndex + 1),
        ]);
    };

    return (
        <Grid
            container
            columnGap={1}
            justifyContent="space-between"
            className={readOnly && classes.disableElements}
        >
            {msgDetails &&
                msgDetails.map((msg, index) => {
                    return (
                        <>
                            <Grid
                                item
                                md={3.5}
                                xs={12}
                                key={`${msg.name}-lang-section`}
                            >
                                <Grid container rowSpacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Language
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectWithLabel
                                            items={languages}
                                            id={`${msg.name}-select-lang`}
                                            size="medium"
                                            label="select Language"
                                            minWidth="calc(80%)"
                                            value={msg.sayMessageLanguage}
                                            onChangeHandler={
                                                handleMsgLanguageChange
                                            }
                                        ></SelectWithLabel>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                md={8}
                                xs={12}
                                key={`${msg.name}-message`}
                            >
                                <Grid container rowSpacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Message
                                            <Tooltip
                                                title={DefaultWidgetValues(
                                                    msg.name &&
                                                        msg.name.indexOf(
                                                            'custom'
                                                        )
                                                        ? `say_play_custom_${
                                                              index + 1
                                                          }_`
                                                        : msg.name,
                                                    restauName,
                                                    restauId
                                                )}
                                                style={{ maxWidth: '700' }}
                                            >
                                                <IconButton>
                                                    <InfoIcon
                                                        sx={{ fontSize: 20 }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth={true}
                                            id={`${msg.name}-msg-content`}
                                            multiline={true}
                                            rows={4}
                                            size="medium"
                                            sx={{ borderRadius: '8px' }}
                                            value={msg.sayMessage}
                                            onChange={(e) =>
                                                handleMsgContentChange(
                                                    e.target.value,
                                                    `${msg.name}-msg-content`
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    );
                })}
        </Grid>
    );
}

SayMessageDetails.propTypes = {
    msgDetails: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            sayMessage: PropTypes.string,
            sayMessageLanguage: PropTypes.string,
        })
    ),
    setMsgDetails: PropTypes.func,
    readOnly: PropTypes.bool,
};

SayMessageDetails.defaultProps = {
    readOnly: false,
};

export default SayMessageDetails;
