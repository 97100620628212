import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch } from '@mui/material';

function SwitchWithLabel(props) {
    const { name, label, id, size, checked, onChangeHandler } = props;
    return (
        <FormControlLabel
            control={
                <Switch
                    checked={checked}
                    onChange={(e) => onChangeHandler(e.target.checked)}
                    name={name}
                    size={size}
                    id={id} //"switchForDineInToGo"
                />
            }
            label={label}
        />
    );
}

SwitchWithLabel.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    size: PropTypes.string,
    checked: PropTypes.bool,
    id: PropTypes.string.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
};

SwitchWithLabel.defaultProps = {
    name: 'switch-with-label',
    label: 'Switch With Label',
    id: 'switch-with-label',
    checked: false,
    size: 'small',
};

export default SwitchWithLabel;
