import React, { useState, useContext } from 'react';
import {
    Grid,
    TextField,
    MenuItem,
    InputAdornment,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment-timezone';
import DialogWithContent from '../../../../../components/common/DialogWithContent/DialogWithContent';
import cloneDeep from 'lodash/cloneDeep';
import CateringContext from '../../../../CateringContext';

const PaymentCustomDialog = ({
    open,
    setOpenPaymentDialog,
    orders,
    paymentType,
    update_payment,
    updateMenuSelection,
}) => {
    const [paymentMade, setPaymentMade] = useState(
        paymentType === 'Deposit'
            ? orders.deposit_payment_success
            : orders.invoice_payment_success
    );
    const [paymentMethod, setPaymentMethod] = useState(
        paymentType === 'Deposit'
            ? orders.deposit_payment_method
            : orders.invoice_payment_method
    );
    const [amountPaid, setAmountPaid] = useState(
        paymentType === 'Deposit'
            ? orders.deposit_amount_paid / 100
            : orders.invoice_amount_paid / 100
    );
    const [paymentTime, setPaymentTime] = useState(
        paymentType === 'Deposit'
            ? orders.deposit_payment_success_timestamp
                ? orders.deposit_payment_success_timestamp
                : null
            : orders.invoice_payment_success_timestamp
            ? orders.invoice_payment_success_timestamp
            : null
    );
    const [collectedBy, setCollectedBy] = useState(
        paymentType === 'Deposit'
            ? orders.deposit_payment_collected_by
            : orders.invoice_payment_collected_by
    );
    const [errors, setErrors] = useState({});
    const { setSnackbarOpen, setSnackbarMessage } = useContext(CateringContext);

    const savePaymentDialog = async () => {
        // conduct validation
        let tempErrors = {};
        if (amountPaid.length === 0) setAmountPaid(0);
        else if (isNaN(Number(amountPaid)))
            tempErrors.amountPaid =
                'Please provide a valid number, or leave as blank';

        if (!paymentTime)
            tempErrors.paymentTime = 'Please provide payment time';

        if (collectedBy.length === 0)
            tempErrors.collectedBy = 'Please provide collector name';

        if (!paymentMethod)
            tempErrors.paymentMethod = 'Please select a payment method';

        setErrors(tempErrors);

        if (Object.keys(tempErrors).length === 0) {
            if (paymentType === 'Deposit') {
                let newOrders = cloneDeep(orders);
                newOrders = {
                    ...newOrders,
                    deposit_payment_success: paymentMade,
                    deposit_amount_paid: Number(amountPaid) * 100,
                    deposit_payment_method: paymentMethod,
                    deposit_payment_success_timestamp: paymentTime,
                    deposit_payment_collected_by: collectedBy,
                };
                try {
                    // send to DB first before updating state
                    await updateMenuSelection(newOrders);
                    update_payment({
                        deposit_payment_success: paymentMade,
                        deposit_amount_paid: Number(amountPaid) * 100,
                        deposit_payment_method: paymentMethod,
                        deposit_payment_success_timestamp: paymentTime,
                        deposit_payment_collected_by: collectedBy,
                    });
                } catch (e) {
                    console.log('Error updating payment information: ', e);
                    setSnackbarMessage(
                        'Issue updating payment information. Please contact support if the issue persists.'
                    );
                    setSnackbarOpen(true);
                    throw new Error('Error updating payment information');
                }
            } else if (paymentType === 'Invoice') {
                let newOrders = cloneDeep(orders);
                newOrders = {
                    ...newOrders,
                    invoice_payment_success: paymentMade,
                    invoice_amount_paid: Number(amountPaid) * 100,
                    invoice_payment_method: paymentMethod,
                    invoice_payment_success_timestamp: paymentTime,
                    invoice_payment_collected_by: collectedBy,
                };
                try {
                    // send to DB first before updating state
                    await updateMenuSelection(newOrders);
                    update_payment({
                        invoice_payment_success: paymentMade,
                        invoice_amount_paid: Number(amountPaid) * 100,
                        invoice_payment_method: paymentMethod,
                        invoice_payment_success_timestamp: paymentTime,
                        invoice_payment_collected_by: collectedBy,
                    });
                } catch (e) {
                    console.log('Error updating payment information: ', e);
                    setSnackbarMessage(
                        'Issue updating payment information. Please contact support if the issue persists.'
                    );
                    setSnackbarOpen(true);
                    throw new Error('Error updating payment information');
                }
            }
        } else {
            throw new Error('Validation failed');
        }
    };

    return (
        <DialogWithContent
            open={open}
            dialogTitle={`Edit ${paymentType} Payment`}
            setOpen={setOpenPaymentDialog}
            handleSave={savePaymentDialog}
        >
            <Grid item container xs={12} direction="column">
                <FormControlLabel
                    control={
                        <Checkbox
                            size="small"
                            checked={paymentMade}
                            onChange={() => {
                                setPaymentMade(!paymentMade);
                            }}
                        />
                    }
                    label="Payment made"
                />

                <TextField
                    required
                    select
                    label="Payment Method"
                    size="small"
                    variant="outlined"
                    margin="normal"
                    sx={{ width: '90%' }}
                    value={paymentMethod}
                    onChange={(e) => {
                        setPaymentMethod(e.target.value);
                    }}
                    helperText={
                        errors.paymentMethod ? errors.paymentMethod : null
                    }
                    error={!!errors.paymentMethod}
                    InputLabelProps={{
                        shrink: true,
                    }}
                >
                    <MenuItem value="Credit Card"> Credit Card </MenuItem>
                    <MenuItem value="Cash"> Cash </MenuItem>
                </TextField>

                <TextField
                    required
                    label="Amount Paid"
                    size="small"
                    variant="outlined"
                    margin="normal"
                    sx={{ width: '90%' }}
                    value={amountPaid}
                    onChange={(e) => {
                        setAmountPaid(e.target.value);
                    }}
                    helperText={errors.amountPaid ? errors.amountPaid : null}
                    error={!!errors.amountPaid}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                        ),
                    }}
                />

                <DateTimePicker
                    label="Payment Time"
                    value={paymentTime}
                    onChange={(newTime) => {
                        setPaymentTime(newTime);
                    }}
                    renderInput={(params) => (
                        <TextField
                            size="small"
                            margin="normal"
                            sx={{ width: '90%' }}
                            value={moment(paymentTime).format(
                                'YYYY/MM/DD HH:mm:ss'
                            )}
                            {...params}
                            helperText={
                                errors.paymentTime ? errors.paymentTime : null
                            }
                            error={!!errors.paymentTime}
                        />
                    )}
                />

                <TextField
                    required
                    label="Payment Collected by"
                    size="small"
                    variant="outlined"
                    margin="normal"
                    sx={{ width: '90%' }}
                    value={collectedBy}
                    onChange={(e) => {
                        setCollectedBy(e.target.value);
                    }}
                    helperText={errors.collectedBy ? errors.collectedBy : null}
                    error={!!errors.collectedBy}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
        </DialogWithContent>
    );
};

export default PaymentCustomDialog;
