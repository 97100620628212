import { useState } from 'react';

const useCateringDashboard = () => {
    const [refreshToggle, setRefreshToggle] = useState(false);
    const dashboardSections = [
        {
            content: 'InqToReachOut',
            timeline: 'all',
            additionalField: 'Submission',
            additionalKey: 'submissionTimestamp',
        },
        {
            content: 'InqToFollowUp',
            timeline: 'all',
            additionalField: 'Follow up by',
            additionalKey: 'followupDueDate',
        },
        {
            content: 'UpcomingEvent',
            timeline: 'all',
            additionalField: 'Other',
            additionalKey: 'eventType',
        },
        {
            content: 'RecentPastEvent',
            timeline: 'all',
            additionalField: 'Other',
            additionalKey: 'eventType',
        },
        {
            content: 'VoidEvent',
            timeline: 'all',
            additionalField: null,
            additionalKey: null,
        },
    ];

    const prioritySubmission = (results) => {
        results = results.sort(
            (a, b) =>
                a.submissionTimestamp._seconds - b.submissionTimestamp._seconds
        );
        return results;
    };

    const priorityFollowup = (results) => {
        results = results.sort(
            (a, b) => a.followupDueDate._seconds - b.followupDueDate._seconds
        );
        return results;
    };

    const priorityConfirmed = (results) => {
        return results;
    };

    const priorityRecent = (results) => {
        results = results.reverse();
        return results;
    };

    return {
        dashboardSections,
        prioritySubmission,
        priorityFollowup,
        priorityConfirmed,
        priorityRecent,
        refreshToggle,
        setRefreshToggle,
    };
};

export default useCateringDashboard;
