import React from 'react';
import DialogWithContent from '../../../../components/common/DialogWithContent/DialogWithContent';
import { Typography } from '@mui/material';
import { PropTypes } from 'prop-types';

function ConfirmationDialog(props) {
    const { showConfirmationDialog, setShowConfirmationDialog, handleConfirm } =
        props;
    return (
        <DialogWithContent
            open={showConfirmationDialog}
            dialogTitle={'Confirmation'}
            setOpen={setShowConfirmationDialog}
            dialogMaxWidth="xs"
            dialogCloseBtnText={'Cancel'}
            dialogSaveBtnText={'OK'}
            handleSave={handleConfirm}
        >
            <Typography>
                There are some unsaved changes, are you sure to discard them?
            </Typography>
        </DialogWithContent>
    );
}

ConfirmationDialog.propTypes = {
    showConfirmationDialog: PropTypes.bool,
    setShowConfirmationDialog: PropTypes.func,
    handleConfirm: PropTypes.func,
};

export default ConfirmationDialog;
