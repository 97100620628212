import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import Edit from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState, useContext } from 'react';
import FoodCard from './FoodCard';
import { FoodGrid } from './FoodGrid';
import { MenuInfoContext } from '../home/MenuInfoContext';
import SortItemsInDialog from './PopularItems/SortItemsInDialog';
import SortIcon from '@mui/icons-material/Sort';
import { RestauInfoContext } from '../home/RestauInfoContext';
import Config from '../config/Config';
import axios from 'axios';
import { useStyles } from '../home/useStyles';
import EditSectionDialog from './EditSectionDialog';

const handleError = (error) => {
    alert('Error while saving please try again');
    //Need to pop error someways
    console.log(error);
    return;
};
function Section(props) {
    const classes = useStyles();
    const { sidx, template, addNewFoodItem, onFoodClick, onRefreshData } =
        props;

    const menuHook = useContext(MenuInfoContext);
    const { restauHook } = useContext(RestauInfoContext);
    const section = menuHook.value.menu[sidx];
    const [openDialogToEditSection, setOpenDialogToEditSection] =
        useState(false);
    const [openDialogToSort, setOpenDialogToSort] = useState(false);
    const toggleEdit = (event) => {
        event.stopPropagation();
        setOpenDialogToEditSection(!openDialogToEditSection);
    };
    const [loading, setLoading] = useState(false);

    const handleUpdateSectionName = (sectionName) => {
        if (section.name === sectionName) {
            return;
        }
        menuHook.updateSectionName(section.id, sectionName).catch(handleError);
    };

    const handleUpdateStationsAtSection = (stations) => {
        menuHook
            .updateStationsAtSection(section.id, stations)
            .catch(handleError);
    };

    const handleUpdateVisOverridesAtSection = (visOverrideId) => {
        menuHook
            .updateVisOverrideAtSection(section.id, visOverrideId)
            .catch(handleError);
    };

    const handleAddFoodItem = () => {
        addNewFoodItem(section.id);
    };

    const handleDeleteSection = () => {
        menuHook.deleteSection(section.id).catch(handleError);
    };

    const handleFoodClick = (food) => {
        onFoodClick(section.id, food);
    };

    const [tempItemData, setTempItemData] = useState([]);

    function showSortItemsDialog() {
        setTempItemData([...section.foods]);
        setOpenDialogToSort(true);
    }

    async function handleSortItemSave() {
        //debugger;
        setLoading(true);
        console.log(
            `Saving the sorted Item order in section '${section.name}' to database`
        );

        const token = await window.customerApp.auth().currentUser.getIdToken();
        var config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };

        let reqBody = {
            restauId: restauHook.restauId,
            section: {
                ...section,
                foods: tempItemData,
            },
        };

        //function to update the section data in database
        let response = await axios
            .post(Config.updateSectionInMenu, reqBody, config)
            .catch(function (error) {
                console.log(error);
            });
        if (response && response.data) {
            //console.log('Response>>: ' + JSON.stringify(response.data));
            if (response.data && response.data.status === 'success') {
                //setPopularItemData([...response.data]);
                onRefreshData();
            }
            setLoading(false);
        }
    }

    const handleEditSection = (sectionToUpdate) => {
        //debugger;
        //we make the save call only, if the data is updated.
        if (sectionToUpdate.name !== section.name) {
            handleUpdateSectionName(sectionToUpdate.name);
        }
        //we make the save call only, if the data is updated.
        if (
            (section.station === undefined &&
                sectionToUpdate.station !== undefined) ||
            (section.station !== undefined &&
                sectionToUpdate.station === undefined) ||
            (section.station !== undefined &&
                sectionToUpdate.station !== undefined &&
                sectionToUpdate.station.join(',') !== section.station.join(','))
        ) {
            handleUpdateStationsAtSection(sectionToUpdate.station);
        }
        //debugger;
        //we make the save call only, if the data is updated.
        if (
            section.visiblityOverrideId !== sectionToUpdate.visiblityOverrideId
        ) {
            handleUpdateVisOverridesAtSection(
                sectionToUpdate.visiblityOverrideId
            );
        }
    };

    return (
        <>
            <EditSectionDialog
                open={openDialogToEditSection}
                setOpen={setOpenDialogToEditSection}
                handleEdit={handleEditSection}
                handleDeleteSection={handleDeleteSection}
                section={section}
            />
            <SortItemsInDialog
                open={openDialogToSort}
                setOpen={setOpenDialogToSort}
                itemDataToSort={tempItemData}
                setItemDataToSort={setTempItemData}
                restauId={restauHook.restauId}
                handleSave={handleSortItemSave}
            />
            <Accordion
                key={section.id}
                trigger={section.name}
                defaultExpanded={sidx === 0}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: '#eee' }}
                >
                    <div
                        style={{
                            display: 'flex',
                            gap: 4,
                            color: '#666',
                            height: 30,
                            alignItems: 'baseline', // 'center',
                        }}
                    >
                        <Typography variant="h6">{section.name}</Typography>
                        <Button
                            variant="text"
                            size="small"
                            aria-label="edit section"
                            onClick={toggleEdit}
                            sx={{
                                border: 0,
                                display: 'flex',
                                alignItems: 'center',
                                padding: 0,
                                '&.MuiButton-textSizeSmall': {
                                    fontSize: '0.8em',
                                },
                            }}
                        >
                            <Edit sx={{ fontSize: '12px', mr: 0.1 }} /> Edit
                        </Button>
                    </div>
                </AccordionSummary>
                <AccordionDetails
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingLeft: 5,
                        paddingRight: 5,
                    }}
                >
                    {loading ? (
                        <div>
                            <img
                                className={classes.loadingImage}
                                src="../../img/spinner.gif"
                            />
                        </div>
                    ) : (
                        <>
                            <Button
                                aria-label="sort items"
                                onClick={showSortItemsDialog}
                                sx={{
                                    alignSelf: 'flex-end',
                                    border: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: 0,
                                }}
                            >
                                <SortIcon sx={{ fontSize: '22px' }} /> Sort
                                Items
                            </Button>
                            <FoodGrid>
                                {section.foods &&
                                    section.foods.map((food, fidx) =>
                                        food ? (
                                            <FoodCard
                                                key={sidx + ' ' + fidx}
                                                template={template}
                                                sectionId={section.id}
                                                food={food}
                                                onFoodClick={() =>
                                                    handleFoodClick(food)
                                                }
                                            />
                                        ) : (
                                            <div key={sidx + ' ' + fidx} />
                                        )
                                    )}
                                <Button
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: 1,
                                        //height: '95%',
                                        minWidth: '30%',
                                        maxWidth: '30%',
                                    }}
                                    onClick={handleAddFoodItem}
                                >
                                    <AddCircleSharpIcon
                                        color="primary"
                                        sx={{ fontSize: 40 }}
                                    />
                                    <Typography style={{ marginTop: '10px' }}>
                                        Add Item
                                    </Typography>
                                </Button>
                            </FoodGrid>
                        </>
                    )}
                </AccordionDetails>
            </Accordion>
        </>
    );
}

Section.propTypes = {};

export default Section;
