import { Button, Divider, Grid, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import SayPlayMessageDetails from './SayPlayMessage/SayPlayMessageDetails';
import PhoneSettingsContext from '../PhoneSettingsContext';
import Utils from '../../../../utils/Utils';
import CircularProgress from '@mui/material/CircularProgress';
import TextMessageDetails from './TextMessageDetails';
import { DefaultWidgetValues } from '../PhoneSettingsConstants';
import { isEqual } from 'lodash';
import ConfirmationDialog from './ConfirmationDialog';
const { getPhoneNumberInE164Format } = { ...Utils };

function CateringDetails() {
    const {
        catNotificationPhNumber,
        setCatNotificationPhNumber,
        catNotificationEmail,
        setCatNotificationEmail,
        txtMsgForCatering,
        setTxtMsgForCatering,
        cateringMsgDetails,
        setCateringMsgDetails,
        updatePhoneSettings,
        uploadAndUpdatePlayMessage,
        allowUpdateTxtMessage,
        restauName,
        restauId,
        setAccordionExpanded,
    } = useContext(PhoneSettingsContext);

    const [isProcessing, setIsProcessing] = useState(false);
    const [uploadedPlayFile, setUploadedPlayFile] = useState({});
    const [errMsg, setErrMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [
        originalCatNotificationPhNumber,
        setOriginalCatNotificationPhNumber,
    ] = useState('');
    const [originalCatNotificationEmail, setOriginalCatNotificationEmail] =
        useState('');
    const [originalCateringMsgDetails, setOriginalCateringMsgDetails] =
        useState([]);
    const [originalTxtMsgForCatering, setOriginalTxtMsgForCatering] =
        useState('');
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    function validatePhoneNumber(phNumber) {
        let phNumberToValidate = '';
        if (typeof phNumber === 'object') {
            phNumberToValidate = phNumber.toNumber;
        } else {
            phNumberToValidate = phNumber;
        }
        let formattedPhNumber = getPhoneNumberInE164Format(phNumberToValidate);

        // allow empty string
        if (formattedPhNumber.length === 0) {
            setCatNotificationPhNumber(formattedPhNumber);
            return [true, formattedPhNumber];
        }

        //console.log('Formatted to:', formattedPhNumber);
        if (formattedPhNumber.length !== 12) {
            return [false, formattedPhNumber];
        }
        setCatNotificationPhNumber(formattedPhNumber);
        return [true, formattedPhNumber];
    }

    function validateEmail(email) {
        //debugger;
        let formattedEmail = '';
        if (email) formattedEmail = email.trim();
        // allow empty string
        if (formattedEmail.length === 0) {
            setCatNotificationEmail(formattedEmail);
            return [true, formattedEmail];
        }
        const emailArr = formattedEmail.split(',');
        const emailReg = /\S+@\S+\.\S+/;
        for (let e of emailArr) {
            if (!emailReg.test(e)) return [false, formattedEmail];
        }
        formattedEmail = emailArr.join(',');
        setCatNotificationEmail(formattedEmail);
        return [true, formattedEmail];
    }

    const handleNotificationNoChange = (updatedVal) => {
        if (typeof catNotificationPhNumber === 'object') {
            setCatNotificationPhNumber({
                ...catNotificationPhNumber,
                toNumber: updatedVal,
            });
        } else {
            setCatNotificationPhNumber(updatedVal);
        }
    };

    const updateCateringDetails = async () => {
        //debugger;
        let [isNotificationPhNoValid, formattedPhNum] = validatePhoneNumber(
            catNotificationPhNumber
        );
        if (!isNotificationPhNoValid) {
            setErrMsg('Notification Phone Number is not Valid.');
            return;
        }

        // TODO : Email validation
        // split it on comma and validate each token as valid email.
        let [isNotificationEmailValid, formattedEmail] =
            validateEmail(catNotificationEmail);
        if (!isNotificationEmailValid) {
            setErrMsg('Notification Email is not Valid.');
            return;
        }

        let isMsgDetailsValid = true;
        if (
            cateringMsgDetails.length === 0 ||
            cateringMsgDetails[0].type === undefined
        ) {
            isMsgDetailsValid = false;
            setErrMsg('Message Details are not Valid.');
            return;
        }
        let isCateringTxtValid = true;
        if (txtMsgForCatering === '') {
            isCateringTxtValid = false;
            setErrMsg('Text Message to customer cannot be blank.');
            return;
        }
        if (formattedPhNum.length === 0) {
            if (
                !window.confirm(
                    'Notification number is empty. Are you sure you do not need text notifications?'
                )
            )
                return;
        }
        if (formattedEmail.length === 0) {
            if (
                !window.confirm(
                    'Notification email is empty. Are you sure you do not need email notifications?'
                )
            )
                return;
        }

        if (
            isNotificationPhNoValid &&
            isMsgDetailsValid &&
            isCateringTxtValid
        ) {
            setIsProcessing(true);
            setErrMsg('');
            let temp;
            if (cateringMsgDetails[0].type === 'play') {
                //Fix for uploading play file.
                //check if the play file changed.
                // if (
                //     originalCateringMsgDetails[0].playFilePath !==
                //     cateringMsgDetails[0].playFilePath
                // ) {
                //     temp = await uploadAndUpdatePlayMessage(
                //         cateringMsgDetails,
                //         uploadedPlayFile,
                //         setErrMsg,
                //         setSuccessMsg,
                //         originalCateringMsgDetails
                //     );
                //     if (!temp) {
                //         //debugger;
                //         setIsProcessing(false);
                //         return;
                //     }
                // } else {
                temp = JSON.parse(JSON.stringify(cateringMsgDetails));
                // }
            } else {
                temp = JSON.parse(JSON.stringify(cateringMsgDetails));
                delete temp[0].playFilePath;
            }

            let formattedPhNumParam = null;
            if (typeof catNotificationPhNumber === 'object') {
                formattedPhNumParam = {
                    ...catNotificationPhNumber,
                    toNumber: formattedPhNum,
                };
            } else {
                formattedPhNumParam = formattedPhNum;
            }
            let body = {
                catering: {
                    message: temp,
                    notificationNo: formattedPhNumParam,
                    notificationEmail: formattedEmail,
                    sendText: txtMsgForCatering,
                },
            };
            //setIsProcessing(true);
            updatePhoneSettings(
                body,
                setErrMsg,
                setSuccessMsg,
                setIsProcessing
            );
        }
    };

    const resetData = () => {
        //debugger;
        //check if there is any cahnge in data
        if (
            isEqual(cateringMsgDetails, originalCateringMsgDetails) &&
            catNotificationPhNumber === originalCatNotificationPhNumber &&
            catNotificationEmail === originalCatNotificationEmail &&
            txtMsgForCatering === originalTxtMsgForCatering
        ) {
            //close the accordion
            setAccordionExpanded(false);
        } else {
            setShowConfirmationDialog(true);
        }
    };

    const handleDiscardChanges = () => {
        setSuccessMsg('');
        setErrMsg('');
        setCatNotificationPhNumber(originalCatNotificationPhNumber);
        setCatNotificationEmail(originalCatNotificationEmail);
        setCateringMsgDetails(
            JSON.parse(JSON.stringify(originalCateringMsgDetails))
        );
        setTxtMsgForCatering(originalTxtMsgForCatering);
        setAccordionExpanded(false);
    };

    useEffect(() => {
        setErrMsg('');
        setSuccessMsg('');
        setOriginalCatNotificationPhNumber(catNotificationPhNumber);
        setOriginalCatNotificationEmail(catNotificationEmail);
        setOriginalCateringMsgDetails(
            JSON.parse(JSON.stringify(cateringMsgDetails))
        );
        setOriginalTxtMsgForCatering(txtMsgForCatering);
    }, []);

    return (
        <>
            <ConfirmationDialog
                showConfirmationDialog={showConfirmationDialog}
                setShowConfirmationDialog={setShowConfirmationDialog}
                handleConfirm={handleDiscardChanges}
            />

            <Grid container rowSpacing={3} xs={12} md={9}>
                <Grid item xs={12}>
                    <Typography variant="h6">Message to customer</Typography>
                </Grid>
                <Grid item xs={12}>
                    <SayPlayMessageDetails
                        msgDetails={cateringMsgDetails}
                        setMsgDetails={setCateringMsgDetails}
                        uploadedPlayFile={uploadedPlayFile}
                        setUploadedPlayFile={setUploadedPlayFile}
                        originalMsgDetails={originalCateringMsgDetails}
                    />
                </Grid>
                <Divider style={{ width: '100%' }} />
                <Grid item xs={12}>
                    {/* {allowUpdateTxtMessage && ( */}
                    <TextMessageDetails
                        txtMessage={txtMsgForCatering}
                        setTxtMessage={setTxtMsgForCatering}
                        id="catering-msg-text"
                        messageHeading="Text Message to Customer"
                        toolTipText={DefaultWidgetValues(
                            'send_message_catering',
                            restauName,
                            restauId
                        )}
                        size="large"
                        //disabled={!allowUpdateTxtMessage}
                    />
                    {/* )} */}
                </Grid>
                <Divider style={{ width: '100%' }} />
                <Grid item xs={12}>
                    <Typography variant="h6">Notification to Owner</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="catNotificationNumber"
                        label="Notification Number"
                        value={
                            typeof catNotificationPhNumber === 'object'
                                ? catNotificationPhNumber.toNumber
                                : catNotificationPhNumber
                        }
                        size="md"
                        onChange={(e) =>
                            handleNotificationNoChange(e.target.value)
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="catNotificationEmail"
                        label="Notification Email"
                        value={catNotificationEmail}
                        size="md"
                        onChange={(e) =>
                            setCatNotificationEmail(e.target.value)
                        }
                    />
                </Grid>
                {errMsg !== '' ? (
                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{ color: 'red' }}>
                            {errMsg}
                        </Typography>
                    </Grid>
                ) : (
                    successMsg !== '' && (
                        <Grid item xs={12}>
                            <Typography variant="body1" sx={{ color: 'green' }}>
                                {successMsg}
                            </Typography>
                        </Grid>
                    )
                )}
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                    }}
                >
                    <Button variant="outlined" onClick={resetData}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ ml: 2 }}
                        onClick={updateCateringDetails}
                        disabled={isProcessing}
                    >
                        {isProcessing && <CircularProgress size={24} />}
                        Save
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

CateringDetails.propTypes = {};

export default CateringDetails;
