import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { blue, green } from '@mui/material/colors';

import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Paper from '@mui/material/Paper';
import { Rating } from '@mui/material';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';

import Config from '../config/Config';
const { default: axios } = require('axios');

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    paper: {
        minWidth: '50%',
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    avatargreen: {
        backgroundColor: green[100],
        color: green[600],
    },
}));
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        align: 'left',
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function getRating(rating) {
    if (!rating || !rating.rating || !rating.count) {
        return 0;
    }
    return rating.rating / rating.count;
}

export default function MessageDialog({ isOpen, setOpen, restauId, msgData }) {
    let classes = useStyles();
    let customerURL = 'http://strideq.com/' + restauId;
    const [reply, setReply] = React.useState(null);

    async function sendMessage() {
        console.log(msgData.phoneNumber);
        console.log(reply);
        const getSMSURL = Config.sendSMS;
        let response = await axios.post(
            getSMSURL,
            {
                phoneNumber: msgData.phoneNumber,
                message: reply,
                module: 'sms_reply',
            },
            { crossDomain: true }
        );
        setReply('');
    }
    let alignRight = {
        marginLeft: '50px',
    };
    let alignLeft = {
        marginLeft: '0px',
    };

    return (
        <div>
            <Dialog
                onClose={console.log('tesdst')}
                aria-labelledby="customized-dialog-title"
                open={isOpen}
                classes={{ paper: classes.paper }}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={(e) => {
                        //handleClose();
                        setOpen(false);
                    }}
                >
                    {msgData && msgData.name}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Paper className={classes.paper}>
                                <h4>
                                    Total Orders:{' '}
                                    {msgData && msgData.totalOrderCount}
                                </h4>
                            </Paper>
                            <Paper className={classes.paper}>
                                {msgData &&
                                    msgData.allRatings &&
                                    msgData.allRatings.Overall_Food && (
                                        <Box
                                            component="fieldset"
                                            mb={1}
                                            borderColor="transparent"
                                        >
                                            <Typography component="legend">
                                                Food Rating
                                            </Typography>
                                            <Rating
                                                name="read-only"
                                                value={getRating(
                                                    msgData.allRatings
                                                        .Overall_Food
                                                )}
                                                readOnly
                                            />
                                        </Box>
                                    )}

                                {msgData &&
                                    msgData.allRatings &&
                                    msgData.allRatings.Online_Ordering && (
                                        <Box
                                            component="fieldset"
                                            mb={1}
                                            borderColor="transparent"
                                        >
                                            <Typography component="legend">
                                                Online Ordering
                                            </Typography>
                                            <Rating
                                                name="read-only"
                                                value={getRating(
                                                    msgData.allRatings
                                                        .Online_Ordering
                                                )}
                                                readOnly
                                            />
                                        </Box>
                                    )}

                                {msgData &&
                                    msgData.allRatings &&
                                    msgData.allRatings.Overall_Service && (
                                        <Box
                                            component="fieldset"
                                            mb={1}
                                            borderColor="transparent"
                                        >
                                            <Typography component="legend">
                                                Overall Service
                                            </Typography>
                                            <Rating
                                                name="read-only"
                                                value={getRating(
                                                    msgData.allRatings
                                                        .Overall_Service
                                                )}
                                                readOnly
                                            />
                                        </Box>
                                    )}

                                {msgData &&
                                    msgData.allRatings &&
                                    msgData.allRatings.comments && (
                                        <Box
                                            component="fieldset"
                                            mb={1}
                                            borderColor="transparent"
                                        >
                                            <Typography component="legend">
                                                {msgData.allRatings.comments.map(
                                                    (s) => (
                                                        <React.Fragment>
                                                            {s}
                                                            <br />
                                                        </React.Fragment>
                                                    )
                                                )}
                                            </Typography>
                                        </Box>
                                    )}
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl>
                                <h3>Messages</h3>
                                {msgData ? (
                                    Object.keys(msgData.message).map(
                                        (tkey, index) => (
                                            <div style={{ marginTop: '20px' }}>
                                                <Input
                                                    style={{ float: 'right' }}
                                                    id="input-with-icon-adornment"
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            {msgData.message[
                                                                tkey
                                                            ].module ===
                                                            'sms_reply' ? (
                                                                <Avatar
                                                                    className={
                                                                        classes.avatargreen
                                                                    }
                                                                >
                                                                    <AccountCircle />
                                                                </Avatar>
                                                            ) : (
                                                                <Avatar
                                                                    className={
                                                                        classes.avatar
                                                                    }
                                                                >
                                                                    <AccountCircle />
                                                                </Avatar>
                                                            )}
                                                        </InputAdornment>
                                                    }
                                                    value={
                                                        msgData.message[tkey]
                                                            .msg
                                                    }
                                                />
                                            </div>
                                        )
                                    )
                                ) : (
                                    <></>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <TextField
                        id="reply"
                        label="Reply"
                        value={reply}
                        variant="outlined"
                        size="small"
                        onChange={(e) => {
                            console.log(e.target.value);
                            setReply(e.target.value);
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <Button
                        onClick={() => {
                            sendMessage();
                        }}
                        variant="contained"
                        size="small"
                        color="primary"
                    >
                        Send
                    </Button>

                    <Button
                        autoFocus
                        onClick={(e) => {
                            setOpen(false);
                            // handleClose();
                        }}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
