import React, { useState } from 'react';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
} from '@mui/material';
import { OrderItemsDialog } from './OrderItemsDialog';
import moment from 'moment';
import Utils from '../utils/Utils';

export default function OrderSearchResults(props) {
    const [openItems, setopenItems] = useState({});
    const [isCloseItems, setisCloseItems] = useState(false);

    const handleItemsDisplay = (item) => {
        let openItemsValue = {
            items: item.items,
            subTotal: item.payment_subtotal,
            tax: item.payment_salestax,
            tip: item.tip,
            orderId: item.id,
            orderInst: item.orderInstruction,
            display: true,
        };

        //console.log("On Click",openItemsValue)

        setopenItems(() => openItemsValue);
        setisCloseItems(false);
    };

    function getDiffBetweenTimestamps(date1, date2) {
        if (!date1 || !date2) {
            return false;
        }

        let dateObj1 = new Date(date1._seconds ? date1._seconds * 1000 : date1);
        let dateObj2 = new Date(date2._seconds ? date2._seconds * 1000 : date2);
        //console.log(dateObj1, dateObj2);

        return dateObj1 > dateObj2
            ? Math.floor(
                  moment(dateObj1).diff(dateObj2, 'minutes', true) * 100
              ) / 100
            : Math.floor(
                  moment(dateObj2).diff(dateObj1, 'minutes', true) * 100
              ) / 100;
    }

    return (
        <>
            <OrderItemsDialog
                itemsData={openItems}
                isClose={isCloseItems}
                setisClose={setisCloseItems}
            />
            <div style={{ flexDirection: 'row', width: 'auto' }}>
                <div>
                    {' '}
                    <span style={{ fontWeight: 'bold' }}>
                        {props.results.length}
                    </span>{' '}
                    Result(s)
                </div>
                <TableContainer style={{ maxHeight: '400px' }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow
                                style={{
                                    backgroundColor: '#f5f5f5',
                                    height: '35px',
                                }}
                            >
                                <TableCell>Order Number</TableCell>
                                <TableCell>Order Id</TableCell>
                                <TableCell>Customer Name</TableCell>
                                <TableCell>Customer Type</TableCell>
                                <TableCell>Customer Ph No.</TableCell>
                                <TableCell>Order Type</TableCell>
                                <TableCell>Sub Total</TableCell>
                                <TableCell>Tip</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>Created Time</TableCell>
                                <TableCell>Completion time (mins)</TableCell>
                                <TableCell>Details</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.results.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell>{item.number}</TableCell>
                                    <TableCell style={{ width: '200px' }}>
                                        {item.id}
                                    </TableCell>
                                    <TableCell>{item.orderUnderName}</TableCell>
                                    <TableCell>{item.customerType} </TableCell>
                                    <TableCell>{item.phoneNumber} </TableCell>
                                    <TableCell>{item.orderType} </TableCell>
                                    <TableCell>
                                        {'$' + item.payment_subtotal / 100}{' '}
                                    </TableCell>
                                    <TableCell>
                                        {'$' + item.tip / 100}{' '}
                                    </TableCell>
                                    <TableCell>
                                        {'$' + item.total_amount_paid / 100}{' '}
                                    </TableCell>
                                    <TableCell>
                                        {Utils.getDateStringFromTimestamp(
                                            item.created_timestamp._seconds,
                                            props.storeTimezone
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {' '}
                                        {getDiffBetweenTimestamps(
                                            item.ready_timestamp
                                                ? item.ready_timestamp
                                                : item.completed_timestamp,
                                            item.accepted_timestamp
                                        )}{' '}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            color="primary"
                                            style={{
                                                height: 'max-content',
                                                margin: '10px 0',
                                            }}
                                            variant="contained"
                                            onClick={() =>
                                                handleItemsDisplay(
                                                    item
                                                    //item.id,
                                                    //item.items,
                                                    //item.payment_subtotal
                                                )
                                            }
                                        >
                                            Items
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}
