import PhoneNumberDetails from '../PhoneSettings/Components/PhoneNumberDetails';
import React from 'react';
import ProductPlanDetails from '../PhoneSettings/Components/ProductPlanDetails';
import CallDefDetailsRoot from './Components/CallDeflection/CallDefDetailsRoot';
import CateringDetails from '../PhoneSettings/Components/CateringDetails';
import MissedCallProtectionDetails from '../PhoneSettings/Components/MissedCallProtectionDetails';
import StoreHourDetails from '../PhoneSettings/Components/StoreHourDetails';
import ReservationDetails from '../PhoneSettings/Components/ReservationDetails';
import BanquetHallDetails from './Components/BanquetHallDetails';
import DirectionDetails from '../PhoneSettings/Components/DirectionDetails';
import PersonIcon from '@mui/icons-material/Person';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import PeopleIcon from '@mui/icons-material/People';
import DirectionsIcon from '@mui/icons-material/Directions';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const PhoneSettingCategories = [
    {
        name: 'phNumberPanel',
        label: 'Phone Number',
        icon: <PersonIcon />,
        component: <PhoneNumberDetails />,
        //always display this component
        display: (productPlanPackages) => {
            return true;
        },
    },
    {
        name: 'productPlanPanel',
        label: 'Product Plan',
        icon: <ContentPasteIcon />,
        component: <ProductPlanDetails />,
        //always display this component
        display: (productPlanPackages) => {
            return true;
        },
    },
    {
        name: 'missedCalProtectionPanel',
        label: ' Missed Call Protection',
        icon: <PhoneMissedIcon />,
        component: <MissedCallProtectionDetails />,
        display: (productPlanPackages) => {
            return (
                productPlanPackages &&
                productPlanPackages['missedCallProtection'] &&
                productPlanPackages['missedCallProtection'].selected
            );
        },
    },
    {
        name: 'callDeflectionPanel',
        label: 'Call Deflection',
        icon: <CallSplitIcon />,
        component: <CallDefDetailsRoot />,
        display: (productPlanPackages) => {
            return (
                productPlanPackages &&
                productPlanPackages['callDeflection'] &&
                productPlanPackages['callDeflection'].selected
            );
        },
    },
    {
        name: 'cateringPanel',
        label: 'Catering',
        icon: <PeopleIcon />,
        component: <CateringDetails />,
        display: (productPlanPackages) => {
            return (
                productPlanPackages &&
                productPlanPackages['catering'] &&
                productPlanPackages['catering'].selected
            );
        },
    },
    {
        name: 'banquetHallPanel',
        label: 'Banquet Hall',
        icon: <MeetingRoomIcon />,
        component: <BanquetHallDetails />,
        display: (productPlanPackages) => {
            return (
                productPlanPackages &&
                productPlanPackages['banquetHall'] &&
                productPlanPackages['banquetHall'].selected
            );
        },
    },
    {
        name: 'storeHoursPanel',
        label: 'Store Hours',
        icon: <AccessTimeIcon />,
        component: <StoreHourDetails />,
        display: (productPlanPackages) => {
            return (
                productPlanPackages &&
                productPlanPackages['storeHours'] &&
                productPlanPackages['storeHours'].selected
            );
        },
    },
    {
        name: 'reservationsPanel',
        label: 'Reservations',
        icon: <TableRestaurantIcon />,
        component: <ReservationDetails />,
        display: (productPlanPackages) => {
            return (
                productPlanPackages &&
                productPlanPackages['reservations'] &&
                productPlanPackages['reservations'].selected
            );
        },
    },
    {
        name: 'directionsPanel',
        label: 'Directions',
        icon: <DirectionsIcon />,
        component: <DirectionDetails />,
        display: (productPlanPackages) => {
            return (
                productPlanPackages &&
                productPlanPackages['directions'] &&
                productPlanPackages['directions'].selected
            );
        },
    },
];

export default PhoneSettingCategories;
