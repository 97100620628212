import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import ResLocations from './ResLocations';

function AppTitleBlock(props) {
    const { title, restaurant } = props;
    const { name, ...others } = restaurant;
    return (
        <Grid container flex flexDirection="row">
            <Grid item sx={{ flexGrow: 1, alignSelf: 'center' }}>
                <Typography
                    variant="h4"
                    component="h4"
                    fontWeight="bold"
                    style={{
                        paddingLeft: 20,
                    }}
                >
                    {title}
                </Typography>
            </Grid>
            <Grid item sx={{ flexShrink: 0 }}>
                <Typography>{name}</Typography>
                <ResLocations {...others} />
            </Grid>
        </Grid>
    );
}

AppTitleBlock.propTypes = {
    title: PropTypes.string.isRequired,
    restaurant: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
};

export default AppTitleBlock;
