import { useContext, useState } from 'react';
import CateringSettingContext from '../CateringSettingContext';
import { TextField, Typography, Grid, Button, IconButton } from '@mui/material';
import Utils from '../../../../utils/Utils';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const FormInfo = () => {
    const {
        adminContact,
        setAdminContact,
        formTitle,
        setFormTitle,
        customMessages,
        setCustomMessages,
        updateFormConfig,
    } = useContext(CateringSettingContext);
    const [formTitleError, setFormTitleError] = useState('');
    const [errors, setErrors] = useState({});

    const clearAll = () => {
        setAdminContact({ phone: '', email: '' });
        setFormTitle('');
        setCustomMessages([{ text: '' }]);
    };

    const save = async () => {
        // conduct validation
        if (formTitle.length === 0) {
            setFormTitleError('Please provide form title');
            return;
        }

        setErrors({});
        const tempErrors = {};
        const newAdminContact = { ...adminContact };

        if (newAdminContact.email.length === 0) {
            if (
                !window.confirm(
                    'Admin email is empty. Are you sure you do not need email notifications?'
                )
            )
                return;
        }
        if (newAdminContact.phone.length === 0) {
            if (
                !window.confirm(
                    'Admin phone number is empty. Are you sure you do not need text notifications?'
                )
            )
                return;
        }

        if (newAdminContact.email.length !== 0) {
            let emailArr = newAdminContact.email.split(',');
            for (let email of emailArr) {
                const re = /\S+@\S+\.\S+/;
                if (!re.test(email))
                    tempErrors.email =
                        'Please provide valid email(s) separated by ","';
            }
        }

        if (newAdminContact.phone.length !== 0) {
            let formattedPhNum = Utils.getPhoneNumberInE164Format(
                newAdminContact.phone
            );
            newAdminContact.phone = formattedPhNum;
            if (formattedPhNum.length !== 12) {
                tempErrors.phone = 'Please provide a valid phone number';
            }
        }

        // check if any message.text is empty, remove that message from customMessages before sending to DB
        let newCustomMessages = [];
        for (let i = 0; i < customMessages.length; i++) {
            if (customMessages[i].text.length !== 0) {
                newCustomMessages.push(customMessages[i]);
            }
        }

        setErrors(tempErrors);
        if (Object.keys(tempErrors).length === 0) {
            setAdminContact(newAdminContact);
            let response = await updateFormConfig({
                admin_contact: newAdminContact,
                form_title: formTitle,
                custom_message: newCustomMessages,
            });
        }
    };

    const addCustomMessages = () => {
        setCustomMessages([...customMessages, { text: '', image: '' }]);
    };

    const removeCustomMessages = () => {
        const updatedMessages = [...customMessages];
        if (updatedMessages.length > 0) updatedMessages.pop();
        else return;
        setCustomMessages(updatedMessages);
    };

    const updatecustomMessages = (index, field, value) => {
        const updatedMessages = [...customMessages];
        updatedMessages[index][field] = value;
        setCustomMessages(updatedMessages);
    };

    return (
        <Grid container>
            <Grid container item>
                <Typography color="primary" variant="h6">
                    New Catering Inquiry Notification Contacts
                </Typography>
                <Grid container item>
                    <Grid item xs={12} md={6} lg={6}>
                        <TextField
                            label="Admin Email"
                            size="small"
                            variant="outlined"
                            margin="normal"
                            sx={{ width: '70%' }}
                            value={adminContact.email}
                            helperText={
                                errors.email
                                    ? errors.email
                                    : 'If multiple emails, please use "," to separate'
                            }
                            error={errors.email}
                            onChange={(e) =>
                                setAdminContact({
                                    ...adminContact,
                                    email: e.target.value,
                                })
                            }
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <TextField
                            label="Admin Phone Number"
                            size="small"
                            variant="outlined"
                            margin="normal"
                            sx={{ width: '70%' }}
                            value={adminContact.phone}
                            helperText={
                                errors.phone
                                    ? errors.phone
                                    : 'One phone number allowed'
                            }
                            error={errors.phone}
                            onChange={(e) =>
                                setAdminContact({
                                    ...adminContact,
                                    phone: e.target.value,
                                })
                            }
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Typography color="primary" variant="h6">
                    Catering Form Header
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    required
                    id="formTitle"
                    label="Form Title"
                    size="medium"
                    variant="outlined"
                    margin="normal"
                    value={formTitle}
                    helperText={!formTitleError ? null : formTitleError}
                    error={!!formTitleError}
                    sx={{ width: '100%' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => setFormTitle(e.target.value)}
                />
            </Grid>
            <Grid container item alignItems="center">
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        Custom Message to Display
                    </Typography>

                    <IconButton onClick={addCustomMessages}>
                        <AddCircleIcon />
                    </IconButton>
                    <IconButton onClick={removeCustomMessages}>
                        <RemoveCircleIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container item xs={12} md={6}>
                {customMessages.map((msg, i) => (
                    <Grid container item alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id={`msg${i}`}
                                label="Text Content"
                                size="small"
                                variant="outlined"
                                margin="normal"
                                multiline
                                minRows={3}
                                value={customMessages[i].text}
                                sx={{ width: '100%' }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) =>
                                    updatecustomMessages(
                                        i,
                                        'text',
                                        e.target.value
                                    )
                                }
                            />
                        </Grid>
                        {/* <Grid item>
                            <Button variant="outlined" startIcon={<UploadIcon />}>
                                Upload Image
                            </Button>
                        </Grid> */}
                    </Grid>
                ))}
            </Grid>

            <Grid container item xs={12}>
                <Button
                    size="small"
                    variant="outlined"
                    onClick={clearAll}
                    sx={{ m: 2 }}
                >
                    Clear All
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    onClick={save}
                    sx={{ m: 2 }}
                >
                    Save
                </Button>
            </Grid>
        </Grid>
    );
};

export default FormInfo;
