import { Grid, TextField, Typography } from '@mui/material';
import React, { useContext } from 'react';
import ButtonWithIcon from '../../../../../components/common/ButtonWithIcon';
import SelectWithLabel from '../../../../../components/common/SelectWithLabel';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PropTypes from 'prop-types';
import {
    menuOptionDestinations,
    menuOptionDestinationsAPI,
} from '../../PhoneSettingsConstants';
import DialogWithContent from '../../../../../components/common/DialogWithContent/DialogWithContent';
import CallDefMenuOptionNewDest from './CallDefMenuOptionNewDest';
import { useState } from 'react';
import { useEffect } from 'react';
import PhoneSettingsContext from '../../PhoneSettingsContext';

function CallDefMenuOptionDetails(props) {
    const { phMenuOptions, setPhMenuOptions, productPlanPackages } = props;
    const { addCustomWidgetsToTwilio, customPlanPackages } =
        useContext(PhoneSettingsContext);
    const [openNewDestDialog, setOpenNewDestDialog] = useState(false);
    const [newPhDestData, setNewPhDestData] = useState({});

    const [filMenuOptionsToDisplay, setFilMenuOptionsToDisplay] = useState([]);

    const [newCustPhDestinations, setNewCustPhDestinations] = useState([]);

    const handleMenuOptionChange = (optionKey, routeToVal) => {
        //debugger;
        console.log(routeToVal);
        setPhMenuOptions({
            ...phMenuOptions,
            [optionKey]: { keyPressed: optionKey, routeTo: routeToVal },
        });
    };

    const handleMenuOptionDelete = (optionKey) => {
        let tempOptions = {};
        let foundKey = false;
        Object.keys(phMenuOptions).forEach((key) => {
            // if (key === 'noMatch') {
            //     tempOptions[key] = phMenuOptions[key];
            //     return;
            // }
            if (key === optionKey) {
                foundKey = true;
                return;
            }
            if (foundKey) {
                let newKey = parseInt(key) - 1;
                tempOptions[newKey] = phMenuOptions[key];
            } else {
                tempOptions[key] = phMenuOptions[key];
            }
        });

        setPhMenuOptions(tempOptions);
    };

    const handleMenuOptionAddition = () => {
        let tempOptions = JSON.parse(JSON.stringify(phMenuOptions));
        //pick the last key
        let lastItemKey = Object.keys(tempOptions).slice(-1);
        //add 1 to the key
        let newKeyToAdd = parseInt(lastItemKey) + 1;
        tempOptions[newKeyToAdd] = { keyPressed: newKeyToAdd, routeTo: '' };
        setPhMenuOptions(tempOptions);
    };

    const showNewDestDialog = () => {
        setOpenNewDestDialog(true);
    };

    const handleNewCustPhDestAddition = async () => {
        //debugger;

        //console.log('New Destination Data: ', newPhDestData);

        //validate the data
        if (newPhDestData.name === '') {
            throw new Error('Destination Name cannot be empty.');
        }
        //validate the name to check for duplicates
        if (Object.keys(customPlanPackages).indexOf(newPhDestData.name) >= 0) {
            throw new Error(
                'A custom destination with the same name already exists.'
            );
        }
        //validate the name to check for duplicates
        if (Object.keys(productPlanPackages).indexOf(newPhDestData.name) >= 0) {
            throw new Error(
                'A default Package with the same name already exists.'
            );
        }
        //validate that atleast one of the widgets are selected.
        if (newPhDestData.widgets.length === 0) {
            throw new Error('Atleast one of the widgets should be selected.');
        }

        //save the corresponding widget data to the twilio flow definition
        let result = await addCustomWidgetsToTwilio(newPhDestData);

        if (!result) {
            throw new Error('Failed to add widgets to Twilio.');
        }

        setNewCustPhDestinations([...newCustPhDestinations, newPhDestData]);
        //console.log('custPhDestinations', newCustPhDestinations);
    };

    useEffect(() => {
        //compute the filtered menu options based on the subscription packages
        //const filMenuOptions = [];
        //debugger;
        let menuOptionDestinationsFinal = menuOptionDestinations;
        let sendTextMsgUsingAPI = false;
        for (let menuNum in phMenuOptions) {
            if (phMenuOptions[menuNum].routeTo.endsWith('_api')) {
                sendTextMsgUsingAPI = true;
                menuOptionDestinationsFinal = menuOptionDestinationsAPI;
                break;
            }
        }

        const tempFilOptions = [];
        productPlanPackages &&
            Object.keys(productPlanPackages)
                .filter((x) => productPlanPackages[x].selected)
                .forEach((pkgKey) => {
                    //first check if the pkgKey is present in the standard Phone Destinations
                    //If not, check in the custom Phone Destinations
                    tempFilOptions.push(
                        ...menuOptionDestinationsFinal.filter(
                            (x) => x.package === pkgKey
                        )
                    );
                });

        //now check in the custom packages
        productPlanPackages &&
            newPhDestData &&
            Object.keys(productPlanPackages)
                .filter((x) => productPlanPackages[x].custom)
                .forEach((x) => {
                    tempFilOptions.push({
                        id: tempFilOptions.length + 1,
                        label: productPlanPackages[x].label,
                        package: productPlanPackages[x].value,
                        value:
                            productPlanPackages[x].widgets[0] +
                            productPlanPackages[x].value,
                    });
                });

        //Finally, set the data to the state
        setFilMenuOptionsToDisplay([...tempFilOptions]);

        //console.log('Filtered Menu Option Destinations: ', filMenuOptions);
    }, []);

    return (
        <Grid container rowSpacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6">Menu Options</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container rowSpacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="h6">Key</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item xs={2}>
                                <Typography variant="h6">
                                    Destination
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <ButtonWithIcon
                                    //flexDirection="row"
                                    buttonText="Add New Destination"
                                    buttonTextSize="0.75rem"
                                    buttonTextVariant="body1"
                                    sx={{ border: 0 }}
                                    onClickHandler={showNewDestDialog}
                                >
                                    <AddCircleSharpIcon
                                        color="primary"
                                        sx={{ fontSize: 18 }}
                                    />
                                </ButtonWithIcon>

                                {/* Dialog for Adding New Destination */}
                                <DialogWithContent
                                    open={openNewDestDialog}
                                    setOpen={setOpenNewDestDialog}
                                    dialogTitle="Add New Destination"
                                    dialogMaxWidth="sm"
                                    handleSave={handleNewCustPhDestAddition}
                                    handleClose={setOpenNewDestDialog}
                                    dialogCloseBtnText={'Close'}
                                    dialogSaveBtnText={'Save'}
                                >
                                    <CallDefMenuOptionNewDest
                                        newDestData={newPhDestData}
                                        setNewDestData={setNewPhDestData}
                                    />
                                </DialogWithContent>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {phMenuOptions &&
                            Object.keys(phMenuOptions).map((key, index) => {
                                return (
                                    <Grid
                                        container
                                        key={key}
                                        columnSpacing={2}
                                        rowSpacing={2}
                                        sx={{ mb: 1 }}
                                    >
                                        <Grid item xs={2}>
                                            <TextField
                                                id="keyPressed"
                                                label=""
                                                variant="outlined"
                                                size="medium"
                                                value={key}
                                                disabled
                                                sx={{
                                                    width: 'auto',
                                                    textAlign: 'center',
                                                }}
                                                /* onChange={(e) => {
                                                handleMenuOptionChange(e);
                                            }} */
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <SelectWithLabel
                                                //items={menuOptionDestinations}
                                                items={filMenuOptionsToDisplay}
                                                id="selectDestination"
                                                label=""
                                                size="medium"
                                                minWidth={220}
                                                value={
                                                    //'send_message_1'
                                                    phMenuOptions[key].routeTo
                                                }
                                                onChangeHandler={(
                                                    selectedVal
                                                ) =>
                                                    handleMenuOptionChange(
                                                        key,
                                                        selectedVal
                                                    )
                                                }
                                            ></SelectWithLabel>
                                            {index >= 2 &&
                                                key !== 'noMatch' && (
                                                    <DeleteOutlineIcon
                                                        sx={{ ml: 1 }}
                                                        onClick={() =>
                                                            handleMenuOptionDelete(
                                                                key
                                                            )
                                                        }
                                                    />
                                                )}
                                        </Grid>
                                    </Grid>
                                );
                            })}
                    </Grid>
                    <Grid item>
                        <ButtonWithIcon
                            flexDirection="row"
                            buttonText="Add Option"
                            sx={{ border: 0 }}
                            onClickHandler={handleMenuOptionAddition}
                        >
                            <AddCircleSharpIcon
                                color="primary"
                                sx={{ fontSize: 20 }}
                            />
                        </ButtonWithIcon>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

CallDefMenuOptionDetails.propTypes = {
    phMenuOptions: PropTypes.shape({}),
    setPhMenuOptions: PropTypes.func,
    productPlanPackages: PropTypes.shape({ custom: PropTypes.shape({}) }),
};

export default CallDefMenuOptionDetails;
