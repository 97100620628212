import { useEffect, useState } from 'react';

export function useAuthentication() {
    const [authenticated, setAuthenticated] = useState(null);

    function loggedInUser(user) {
        setAuthenticated(user);
    }

    useEffect(() => {
        const auth = window.customerApp.auth();
        auth.onAuthStateChanged(
            function (user) {
                if (user) {
                    setAuthenticated(user);
                } else {
                    setAuthenticated();
                }
            },
            function (error) {
                console.log(error);
            }
        );
    }, []);

    return { loggedInUser, loggedIn: authenticated };
}
