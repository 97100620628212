import { InputAdornment } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

import InputHint from './InputHint';

function SQInputAdornment(props) {
    const { children } = props;
    const theme = useTheme();
    return (
        <InputAdornment position="end">
            <InputHint>
                <div
                    style={{
                        padding: theme.spacing(1),
                        fontSize: theme.typography.caption.fontSize,
                        backgroundColor: theme.palette.tertiary.main,
                        color: theme.palette.tertiary.contrastText,
                    }}
                >
                    {children}
                </div>
            </InputHint>
        </InputAdornment>
    );
}

SQInputAdornment.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SQInputAdornment;
