import React, { useState, useRef, useEffect } from 'react';
import Search, { createFilter } from '../../../../../Search';
import FoodSection from './FoodSection';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Button,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';

const searchInputStyle = {
    padding: '7px',
    width: '60%',
    maxWidth: '200px',
    marginRight: '5px',
};

const MenuDialog = ({
    open,
    setOpenMenuDialog,
    setOpenFood,
    menu,
    setOpenFoodDialog,
    setOpenCustomFoodDialog,
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredMenu, setFilteredMenu] = useState(menu);
    const theme = useTheme();
    useEffect(() => {
        let tempMenu = menu;
        if (searchTerm && searchTerm.trim().length > 0 && menu) {
            const filtersFoods = menu
                .flatMap((sec) => sec.foods)
                .filter(
                    createFilter(searchTerm, ['name', 'desc'], {
                        fuzzy: false,
                    })
                );
            tempMenu = [
                {
                    id: 'searchResult' + searchTerm,
                    name: 'Results for ' + searchTerm,
                    foods: filtersFoods,
                },
            ];
        }
        setFilteredMenu(tempMenu);
    }, [menu, searchTerm]);
    const searchRef = useRef(null);
    const searchInputRef = useRef();
    const showCountInResults =
        searchTerm && searchTerm.length > 0 ? { showCount: true } : {};

    const close = () => {
        setOpenMenuDialog(false);
    };

    const addCustomItem = () => {
        setOpenCustomFoodDialog(true);
    };

    return (
        <Dialog open={open} fullScreen>
            <DialogTitle sx={{ m: 0, p: 2 }}>
                {close ? (
                    <IconButton
                        aria-label="close"
                        onClick={close}
                        sx={{
                            position: 'absolute',
                            right: 7,
                            top: 2,
                        }}
                    >
                        <CloseIcon sx={{ fontSize: 22 }} />
                    </IconButton>
                ) : null}
            </DialogTitle>

            <DialogContent>
                <div
                    ref={searchInputRef}
                    style={{
                        width: '97%',
                        boxSizing: 'border-box',
                        position: 'absolute',
                        zIndex: 2,
                        backgroundColor: 'white',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                    }}
                >
                    <Search
                        type={'input'}
                        ref={searchRef}
                        style={searchInputStyle}
                        value={searchTerm}
                        onChange={(term) => {
                            setSearchTerm(term);
                        }}
                    ></Search>
                </div>

                <div style={{ marginTop: '60px' }}>
                    {filteredMenu &&
                        filteredMenu.map((section, sidx) =>
                            section && section.foods ? (
                                <FoodSection
                                    key={section.id}
                                    sidx={sidx}
                                    {...section}
                                    {...showCountInResults}
                                    setOpenFood={setOpenFood}
                                    setOpenFoodDialog={setOpenFoodDialog}
                                />
                            ) : (
                                <div key={sidx} />
                            )
                        )}
                </div>

                <Button
                    component="label"
                    sx={{
                        mt: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: 1,
                        [theme.breakpoints.down('xs')]: {
                            maxWidth: '20%',
                        },
                        [theme.breakpoints.up('md')]: {
                            maxWidth: '9%',
                        },
                    }}
                    onClick={addCustomItem}
                >
                    <AddCircleSharpIcon color="primary" sx={{ fontSize: 20 }} />
                    <Typography
                        align="center"
                        variant="body2"
                        sx={{ m: 'auto' }}
                    >
                        Add Custom Item
                    </Typography>
                </Button>
            </DialogContent>
        </Dialog>
    );
};

export default MenuDialog;
