import React, { useContext, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import Config from './config/Config';
import { RestauInfoContext } from './home/RestauInfoContext.js';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import TextsmsIcon from '@mui/icons-material/Textsms';
import Alert from '@mui/material/Alert';

import Typography from '@mui/material/Typography';

const { default: axios } = require('axios');
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '50ch',
        },
    },
}));

export default function Twilio(props) {
    const { restauId } = props;

    const [id, setId] = useState(restauId);
    let customerURL = 'http://strideq.com/' + restauId;
    const [areaCode, setAreaCode] = useState('');
    const [secondLine, setSecondLine] = useState('');
    const [locality, setLocality] = useState('');
    const [phoneList, setPhone] = useState('');
    const [virtualNumber, setVirtualNumber] = useState('');
    const [numberBought, setNumberBought] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [loadList, setLoadList] = useState(false);
    const [ivrConfigSuccess, setIvrConfigSuccess] = useState(false);
    const classes = useStyles();
    const { restauHook } = useContext(RestauInfoContext);

    const handleClose = () => {
        props.setOpen(false);
        console.log(restauId);
    };
    const handleChange = (change) => (event) => {
        if (change == 'secondline') {
            setSecondLine(event.target.value);
        } else if (change == 'locality') {
            setLocality(event.target.value);
        } else if (change == 'restauid') {
            setId(event.target.value);
        }
        console.log(locality);
        console.log(areaCode);
    };

    async function handleSearch() {
        let code = parseInt(areaCode);
        setLoadList(true);
        const token = restauHook.tokenId;

        const admin = window.customerApp.database();
        let menuRef = admin.ref(
            'menus/' + restauHook.restauId + '/restaurantAddress'
        );

        let menus = await menuRef
            .once('value')
            .then(function (snapshot) {
                let result = snapshot.val();
                //menuHook.setValue({menu:result});
                console.log('Address' + result);
                let s = result.split(',');
                console.log(s[s.length - 2].trim());
                const request = {
                    locality: s[s.length - 2].trim(),
                    restauId: id,
                    token: token,
                    env: Config.env,
                };
                console.log('RequestLLLLLLLLL');
                console.log(request);
                axios
                    .post(Config.listPhoneNumber, request)
                    .then((response) => {
                        console.log(response.data);
                        setPhone(response.data);
                        console.log('Phone data');
                        console.log(response.data);
                        setVirtualNumber(
                            response.data.phoneNumbers[0].phoneNumber
                        );
                        setLoadList(false);
                    })
                    .catch(function (error) {
                        // setLoaded(false);
                        console.log(error);
                        setLoadList(false);
                    });
                return result;
            })
            .catch(function (error) {
                setLoadList(false);
                console.log(error);
            });

        menuRef = admin.ref(
            'menus/' + restauHook.restauId + '/restaurantPhoneNumber'
        );

        menus = await menuRef
            .once('value')
            .then(function (snapshot) {
                let result = snapshot.val();
                setSecondLine(result);
                return result;
            })
            .catch(function (error) {
                setLoadList(false);
                console.log(error);
            });
    }

    async function buyPhoneNo() {
        if (!secondLine) {
            alert('please enter the phone number to forward');
            return;
        }
        const token = restauHook.tokenId;
        const numberToBuy = virtualNumber;
        setNumberBought(numberToBuy);
        const request = {
            restauId: props.restauId,
            phoneNumber: numberToBuy,
            secondPhoneNumber: secondLine,
            env: Config.env,
            token: token,
        };
        setLoadList(true);
        axios
            .post(Config.buyPhoneNumber, request)
            .then((response) => {
                console.log(response.data);

                setLoadList(false);
                setIvrConfigSuccess(true);
            })
            .catch(function (error) {
                // setLoaded(false);
                console.log(error);
                alert(
                    'Failed to Setup the IVR number, StrideQ is working on setting up. We will contact you after the setup'
                );
                setLoadList(false);
            });
        // Necessary to close Dialog
    }
    async function getRestauDetail(resId) {
        setLoaded(true);
        const token = restauHook.tokenId;

        const request = {
            restauId: resId,
            token: token,
        };
        /*axios
            .post(Config.getPhoneLocality, request)
            .then((response) => {
                console.log(response.data);
                setAreaCode(response.data.phoneNumber.substring(2, 5));
                setLocality(response.data.locality.city);
                setLoaded(false);
            })
            .catch(function (error) {
                // setLoaded(false);
                console.log(error);
                setLoaded(false);
            });*/
        setLoaded(false);
    }

    useEffect(() => {
        if (props.restauId) {
            getRestauDetail(props.restauId);
            handleSearch();
        }
    }, []);

    //console.log('loaded:', loaded);
    //console.log('props.isOpen', props.isOpen);
    return (
        <>
            {loaded ? (
                <Dialog open={props.isOpen} aria-labelledby="form-dialog-title">
                    <img src="../../../img/spinner.gif" />
                </Dialog>
            ) : (
                <Dialog
                    open={props.isOpen}
                    aria-labelledby="form-dialog-title"
                    style={{ minWidth: '500px' }}
                >
                    <DialogTitle
                        id="form-dialog-title"
                        style={{ color: 'green' }}
                    >
                        {ivrConfigSuccess ? (
                            <>Welcome to StrideQ</>
                        ) : (
                            <>IVR Configuration</>
                        )}
                    </DialogTitle>

                    {!ivrConfigSuccess && (
                        <DialogContent>
                            <Typography
                                component="h6"
                                variant="h6"
                                color="inherit"
                                noWrap
                            >
                                Customer Press #1, Send SMS with order link
                            </Typography>
                            <div>
                                <b></b>
                            </div>
                            <br />
                            <div>
                                <Alert severity="info">
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        <ContactPhoneIcon
                                            style={{
                                                color: 'green',
                                                width: 30,
                                                height: 30,
                                            }}
                                            fontSize="default"
                                        />
                                        <span> Customer Call</span>
                                        <ArrowRightAltIcon
                                            style={{
                                                color: 'green',
                                                width: 30,
                                                height: 30,
                                            }}
                                        ></ArrowRightAltIcon>
                                        Restaurant
                                        <PhoneForwardedIcon
                                            style={{
                                                color: 'green',
                                                width: 30,
                                                height: 30,
                                            }}
                                        />
                                        Press #1
                                        <ArrowRightAltIcon
                                            style={{
                                                color: 'green',
                                                width: 30,
                                                height: 30,
                                            }}
                                        />
                                        Send SMS
                                        <TextsmsIcon
                                            style={{
                                                color: 'green',
                                                width: 30,
                                                height: 30,
                                            }}
                                            fontSize="default"
                                        />
                                    </div>
                                </Alert>
                                <br />
                                <Typography
                                    component="h6"
                                    variant="h6"
                                    color="inherit"
                                    noWrap
                                >
                                    Customer Press #2, forward the call to
                                    restaurant
                                </Typography>
                                <br />
                                <Alert severity="info">
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        <ContactPhoneIcon
                                            style={{
                                                color: 'green',
                                                width: 30,
                                                height: 30,
                                            }}
                                            fontSize="default"
                                        />
                                        <span>Customer Call</span>
                                        <ArrowRightAltIcon
                                            style={{
                                                color: 'green',
                                                width: 30,
                                                height: 30,
                                            }}
                                        ></ArrowRightAltIcon>
                                        Restaurant Number
                                        <PhoneForwardedIcon
                                            style={{
                                                color: 'green',
                                                width: 30,
                                                height: 30,
                                            }}
                                        />
                                        #1 forward
                                        <ArrowRightAltIcon
                                            style={{
                                                color: 'green',
                                                width: 30,
                                                height: 30,
                                            }}
                                        />
                                        Second line
                                        <ContactPhoneIcon
                                            style={{
                                                color: 'green',
                                                width: 30,
                                                height: 30,
                                            }}
                                            fontSize="default"
                                        />
                                    </div>
                                </Alert>
                                <br />
                                <br />
                                <TextField
                                    fullWidth
                                    id="outlined-secondary"
                                    label="Second Phone number to redirect"
                                    variant="outlined"
                                    inputProps={{
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*',
                                    }}
                                    color="secondary"
                                    onChange={handleChange('secondline')}
                                    value={secondLine}
                                    style={{ marginRight: '20px' }}
                                />
                            </div>
                            {/* <div>
                    <br />
                    <Typography component="h2" variant="h5" color="inherit" noWrap>
                    Step 2) Select a Virtual Number
                 </Typography>
                       </div>
                       <DialogContentText>
                            Search Phone Number
                        </DialogContentText>
                        <div className={classes.root}>
                            <TextField
                                fullWidth
                                id="outlined-secondary"
                                label="Area Code"
                                variant="outlined"
                                inputProps={{
                                    inputMode: 'numeric',
                                    pattern: '[0-9]*',
                                }}
                                color="secondary"
                                value={areaCode}
                                onChange={handleChange('areacode')}
                                style={{ marginRight: '20px' }}
                            />
                            <TextField
                                fullWidth
                                id="outlined-secondary"
                                label="Locality"
                                variant="outlined"
                                color="secondary"
                                value={locality}
                                onChange={handleChange('locality')}
                                style={{ marginRight: '20px' }}
                            />
                            
                        </div>
                        <Button
                            style={{
                                marginLeft: '20px',
                                color: 'white',
                                alignItems: 'left',
                            }}
                            variant="contained"
                            color="primary"
                            className="loginBtn text-transform btn-filled"
                            type="submit"
                            onClick={handleSearch}
                        >
                            Search
                        </Button>
*/}
                            {/*loadList ? (
                            <div>
                                <img
                                    className={classes.loadingImage}
                                    src="../../img/spinner.gif"
                                />
                            </div>
                        ) : phoneList ? (
                            <PhoneList
                                phoneList={phoneList}
                                closeDialog={handleClose}
                                restauId={props.restauId}
                            ></PhoneList>
                        ) : (
                            <></>
                        )*/}
                        </DialogContent>
                    )}
                    {ivrConfigSuccess && (
                        <DialogContent>
                            <Typography gutterBottom>
                                Congratulations! Your store is ready
                                <ul>
                                    <li>
                                        Check the restaurant profile,menu, sales
                                        tax etc at left menu tab
                                    </li>
                                    <li>Your daily reports</li>
                                    <li>
                                        <b>
                                            Your IVR number is:{numberBought}{' '}
                                        </b>
                                        ,please call to this number and verify
                                    </li>
                                    <li>
                                        {' '}
                                        Access{' '}
                                        <a href="http://merchant.strideq.com">
                                            merchant app{' '}
                                        </a>{' '}
                                        here using same credentials{' '}
                                    </li>
                                    <li>
                                        Here is your{' '}
                                        <a href={customerURL}>
                                            Online ordering App
                                        </a>
                                    </li>
                                </ul>
                            </Typography>
                        </DialogContent>
                    )}
                    <DialogActions>
                        {loadList ? (
                            <img src="../../../img/spinner.gif" />
                        ) : (
                            <Button
                                style={{
                                    width: '90px',
                                    color: 'white',
                                }}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    buyPhoneNo();
                                }}
                                className="loginBtn text-transform btn-filled"
                            >
                                Configure
                            </Button>
                        )}
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
