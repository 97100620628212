import React from 'react';
import { useParams } from 'react-router';
import { Grid, Button } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const CopyLink = () => {
    const { RestauId, CateringInquiryId } = useParams();
    const linkCustomer =
        'https://order.strideq.com/catering/' +
        RestauId +
        '/' +
        CateringInquiryId;
    const linkKitchen =
        'https://order.strideq.com/catering/' +
        RestauId +
        '/' +
        CateringInquiryId +
        '/' +
        'kitchen';

    const handleCopyCustomer = async () => {
        try {
            await navigator.clipboard.writeText(linkCustomer);
            alert('Order link for customer copied to clipboard!');
        } catch (err) {
            alert('Failed to copy the link');
        }
    };

    const handleCopyKitchen = async () => {
        try {
            await navigator.clipboard.writeText(linkKitchen);
            alert('Order link for kitchen copied to clipboard!');
        } catch (err) {
            alert('Failed to copy the link');
        }
    };

    return (
        <Grid
            container
            spacing={1}
            sx={{ display: 'flex', alignItems: 'center', mb: 10, mt: 2 }}
        >
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    size="small"
                    startIcon={<FileCopyIcon />}
                    onClick={handleCopyCustomer}
                >
                    Copy order link for customer
                </Button>
            </Grid>

            <Grid item xs={12}>
                <Button
                    variant="contained"
                    size="small"
                    startIcon={<FileCopyIcon />}
                    onClick={handleCopyKitchen}
                >
                    Copy order link for kitchen
                </Button>
            </Grid>
        </Grid>
    );
};

export default CopyLink;
