import React from 'react';
import HideLogTable from './hideLogTable';
import OOSLogTable from './oosLogTable';
import PausedLogTable from './pauseLogTable';

function AuditLogButton(props) {
    const { auditHook } = props;
    if (auditHook.audit == 'Out of Stocks') {
        return <OOSLogTable oosData={auditHook.OOSLog}></OOSLogTable>;
    } else if (auditHook.audit == 'Hidden Menu') {
        return <HideLogTable hideData={auditHook.hideLog}></HideLogTable>;
    } else if (auditHook.audit == 'Paused Order') {
        return <PausedLogTable pauseData={auditHook.pauseLog}></PausedLogTable>;
    }
}

AuditLogButton.propTypes = {};
export default AuditLogButton;
