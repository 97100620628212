import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import OverrideRuleDisplayTable from './OverrideRuleDisplayTable';

//The rule display part in the "Add New" or "Edit Existing" Override dialog
function OverrideRuleDisplay(props) {
    const { id, overrideRuleData } = props;

    return (
        <Box
            key={id}
            sx={{
                //p: 2,
                bgcolor: 'background.default',
            }}
        >
            <Grid item>
                <Typography>
                    Type:
                    <Typography
                        component="span"
                        sx={{ fontWeight: 'bold', ml: 1 }}
                    >
                        {overrideRuleData && overrideRuleData.type}
                    </Typography>
                </Typography>
                <Typography>
                    Status:
                    <Typography
                        component="span"
                        sx={{
                            ml: 1,
                            color:
                                overrideRuleData.status === 'hide'
                                    ? 'red'
                                    : 'green',
                        }}
                    >
                        {overrideRuleData && overrideRuleData.status}
                    </Typography>
                </Typography>
                <OverrideRuleDisplayTable ruleData={overrideRuleData} />
            </Grid>
        </Box>
    );
}

OverrideRuleDisplay.propTypes = {
    overrideRuleData: PropTypes.shape({
        status: PropTypes.string,
        type: PropTypes.string,
    }).isRequired,
    id: PropTypes.string.isRequired,
};

export default OverrideRuleDisplay;
