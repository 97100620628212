//Dharma : need to handle this dynamically
export const menuOptionDestinations = [
    {
        id: 1,
        value: 'send_message_1',
        label: 'StrideQ link for Online ordering',
        package: 'callDeflection',
    },
    {
        id: 2,
        value: 'connect_call_to_restaurant',
        label: 'Connect call to Restaurant',
        package: 'callDeflection',
    },
    {
        id: 3,
        value: 'send_message_for_catering',
        label: 'Catering',
        package: 'catering',
    },
    {
        id: 4,
        value: 'send_message_reservation',
        label: 'Reservations',
        package: 'reservations',
    },
    {
        id: 5,
        value: 'send_message_store_hours',
        label: 'Store Hours',
        package: 'storeHours',
    },
    {
        id: 6,
        value: 'send_message_for_banquet_hall',
        label: 'Banquet Hall',
        package: 'banquetHall',
    },
];

export const customPhDestWidgets = {
    txtMsgToOwner: {
        name: 'txtMsgToOwner',
        label: 'Text Message To Owner',
        widgetToAdd: 'send_message_for_custom_',
    },
    emailMsgToOwner: {
        name: 'emailMsgToOwner',
        label: 'Email Message To Owner',
        widgetToAdd: 'send_email_for_custom_',
    },
    greetingMessage: {
        name: 'greetingMessage',
        label: 'Greeting Message To Customer',
        //TODO: need to handle 1 & 2 in a better way
        widgetToAdd: ['say_play_custom_1_', 'say_play_custom_2_'],
    },
    textMsgToCustomer: {
        name: 'textMsgToCustomer',
        label: 'Text Message To Customer',
        widgetToAdd: 'send_message_custom_',
    },
};

export const menuOptionDestinationsAPI = [
    {
        id: 1,
        value: 'send_message_1_api',
        label: 'StrideQ link for Online ordering',
        package: 'callDeflection',
    },
    {
        id: 2,
        value: 'connect_call_to_restaurant',
        label: 'Connect call to Restaurant',
        package: 'callDeflection',
    },
    {
        id: 3,
        value: 'send_message_for_catering_api',
        label: 'Catering',
        package: 'catering',
    },
    {
        id: 4,
        value: 'send_message_reservation_api',
        label: 'Reservations',
        package: 'reservations',
    },
    {
        id: 5,
        value: 'send_message_store_hours_api',
        label: 'Store Hours',
        package: 'storeHours',
    },
    {
        id: 6,
        value: 'send_message_for_banquet_hall_api',
        label: 'Banquet Hall',
        package: 'banquetHall',
    },
];
export const languages = [
    {
        id: 1,
        value: 'en-US',
        label: 'English',
    },
    {
        id: 2,
        value: 'es-US',
        label: 'Spanish',
    },
];

//Dharma: need to handle this dynamically
export const productPlanPackages = {
    missedCallProtection: {
        label: 'Missed Call Protection',
        value: 'missedCallProtection',
        selected: false,
    },
    callDeflection: {
        label: 'Call Deflection',
        value: 'callDeflection',
        selected: false,
        options: {
            catering: '',
            custom1: '',
            custom2: '',
        },
    },
    catering: {
        label: 'Catering',
        value: 'catering',
        selected: false,
    },
    storeHours: {
        label: 'Store Hours',
        value: 'storeHours',
        selected: false,
    },
    directions: {
        label: 'Directions',
        value: 'directions',
        selected: false,
    },
    reservations: {
        label: 'Reservations',
        value: 'reservations',
        selected: false,
    },
    banquetHall: {
        label: 'Banquet Hall',
        value: 'banquetHall',
        selected: false,
    },
    specialOccasions: {
        label: 'Special Occasions',
        value: 'specialOccasions',
        selected: false,
    },
};

export const DefaultWidgetValues = (wdName, restauName, restauId) => {
    switch (wdName) {
        case 'send_message_1':
            return `Thanks for calling ${restauName} Order at the same prices using https://order.strideq.com/${restauId}?pn={{contact.channel.address}}&r=c1 and skip the wait. No account creation or app download. Apple/Google Pay also accepted. When your order is ready, you will get a text.`;
        case 'say_welcome_english':
            return `Thank you for calling ${restauName}.`;
        case 'gather_input_spanish':
            return `Gracias por llamar a ${restauName}. Para su comodidad, hemos iniciado una nueva forma de tomar pedidos por teléfono. Presione 1 para recibir un enlace para ordenar desde su teléfono y evitar la espera. Presione 2 para hablar con nosotros directamente en el restaurante. Gracias.`;
        case 'gather_input':
            return `Thank you for calling ${restauName}. For your convenience we have started a new way to take phone orders. Press 1 to receive a link to order from your phone and skip the wait. Press 2 to speak with us directly at the restaurant. Thank you.`;
        case 'say_play_busy':
            return 'Thank you for calling us. Sorry we missed your call. You can order directly from the link received on your phone and skip the wait. Thank you.';
        case 'say_play_busy_spanish':
            return `Gracias por llamarnos. Lo sentimos, nos perdimos su llamada. Puede ordenar directamente desde el enlace recibido en su teléfono y evitar la espera. Gracias.`;
        case 'say_play_busy_2':
            return `Gracias por llamarnos. Lo sentimos, nos perdimos su llamada. Puede ordenar directamente desde el enlace recibido en su teléfono y evitar la espera. Gracias.`;
        case 'send_message_as_busy':
            return `Thanks for calling ${restauName} Order at the same prices using https://order.strideq.com/${restauId}?pn={{contact.channel.address}}&r=c2 and skip the wait. No account creation or app download. Apple/Google Pay also accepted. When your order is ready, you will get a text.`;
        case 'say_play_catering':
            return 'Thank you for calling us for catering. We have noted down the number you are calling from. And will call you back soon. You can also directly reach us at the contact details sent over the text. Thank you !';
        case 'say_play_custom_1_':
            return `Thank you for calling us for <custom>. We have noted down the number you are calling from. And will call you back soon. You can also directly reach us at the contact details sent over the text. Thank you !`;
        case 'say_play_custom_2_':
            return `Thank you for calling us for <custom>. We have noted down the number you are calling from. And will call you back soon. You can also directly reach us at the contact details sent over the text. Thank you !`;
        case 'send_message_for_catering':
            return '{{contact.channel.address}} is enquiring for Catering.';
        case 'send_message_catering':
            return `Thanks for calling ${restauName}! We will call you back soon. For catering menu, refer https://order.strideq.com/${restauId} \n If you have questions, call us directly at ..\n Thank You !`;
        case 'say_play_store_info':
            return 'We have send you text with store hours, thank you';
        case 'send_message_store_hours':
            return `Thanks for calling ${restauName}!\n Restaurant Hours :\n Tue-Fri: 11AM–2:30PM, 5–10PM\n Sat-Sun: 11AM–3:30PM, 5–10:30PM\n   Monday:	Closed\n \nFor updates store hours : https://www.google.com/search?q=${restauName}`;
        case 'say_play_store_info':
            return 'We have send you text with store hours, thank you';
        case 'send_message_store_hours':
            return `Thanks for calling ${restauName}!\n Restaurant Hours :\n Tue-Fri: 11AM–2:30PM, 5–10PM\n Sat-Sun: 11AM–3:30PM, 5–10:30PM\n   Monday:	Closed\n \nFor updates store hours : https://www.google.com/search?q=${restauName}`;
        case 'say_play_banquet_hall':
            return 'Thank you for calling us for Banquet Hall enquiry. We have noted down the number you are calling from. And will call you back soon. You can also directly reach us at the contact details sent over the text. Thank you !';
        case 'send_message_for_banquet_hall':
            return '{{contact.channel.address}} is enquiring for Banquet Hall.';
        case 'send_message_banquet_hall':
            return `Thanks for calling ${restauName}! We will call you back soon. For banquet hall dates: https://calendar.google.com/calendar/embed?src=restauEmail \n If you have questions, call us directly at ..\n Thank You !`;
        case 'send_message_reservation':
            return `Thanks for calling ${restauName}! Please make a reservation here: {reservationUrl}`;
        case 'say_Thank_you_reservation':
            return `Your Text with the reservation link has been sent. Thank You!`;
        case 'say_Thank_you_1':
            return `Your Text with the ordering link has been sent. Thank You!`;
        case 'say_Thank_you_2':
            return `Su mensaje de texto con el enlace de pedido ha sido enviado. Gracias.`;
        default:
            return '';
    }
};
