import React from 'react';
import CateringSettingContent from '../Components/CateringSettingContent';
import CateringSettingContext from '../CateringSettingContext';
import useCateringSetting from '../useCateringSetting';

const CateringSettingRoot = () => {
    const cateringSettingHook = useCateringSetting();
    return (
        <CateringSettingContext.Provider value={cateringSettingHook}>
            <CateringSettingContent />
        </CateringSettingContext.Provider>
    );
};

export default CateringSettingRoot;
