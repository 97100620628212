import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
} from '@mui/material';
import formatPrice from '../../../../../utils/formatPrice';
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft';
import SwitchRightIcon from '@mui/icons-material/SwitchRight';

const PriceTable = ({ orders, dispatchOrder, defaultTax }) => {
    const initialDiscount =
        orders.discount.discount_type === 'Dollar'
            ? orders.discount.amount_off / 100
            : orders.discount.percent_off;
    const [inputDiscount, setInputDiscount] = useState(initialDiscount);
    const [inputTax, setInputTax] = useState(
        orders ? orders.payment_salestax_percent : defaultTax
    );
    useEffect(() => {
        if (!inputTax) {
            setInputTax(orders ? orders.payment_salestax_percent : defaultTax);
        }
    }, [defaultTax]);

    const updateDiscount = (value) => {
        setInputDiscount(value);
        let input = value;
        if (value === '') input = 0;

        if (value !== '') {
            input = Number(value);
            if (isNaN(input)) return;
        }
        var newOrders;
        if (orders.discount.discount_type === 'Dollar') {
            newOrders = {
                ...orders,
                discount: {
                    discount_type: 'Dollar',
                    amount: input * 100,
                    amount_off: input * 100,
                },
                payment_subtotal:
                    orders.payment_subtotal_before_discount - input * 100,
                payment_salestax: Math.round(
                    (orders.payment_salestax_percent / 100) *
                        Math.round(
                            orders.payment_subtotal_before_discount -
                                input * 100
                        )
                ),
                payment_total: Math.round(
                    (orders.payment_subtotal_before_discount - input * 100) *
                        (1 + orders.payment_salestax_percent / 100)
                ),
            };
        } else {
            newOrders = {
                ...orders,
                discount: {
                    discount_type: 'Percent',
                    amount:
                        (orders.payment_subtotal_before_discount * input) / 100,
                    percent_off: input,
                },
                payment_subtotal: Math.round(
                    orders.payment_subtotal_before_discount * (1 - input / 100)
                ),
                payment_salestax: Math.round(
                    (orders.payment_salestax_percent / 100) *
                        Math.round(
                            orders.payment_subtotal_before_discount *
                                (1 - input / 100)
                        )
                ),
                payment_total: Math.round(
                    orders.payment_subtotal_before_discount *
                        (1 - input / 100) *
                        (1 + orders.payment_salestax_percent / 100)
                ),
            };
        }
        dispatchOrder({ type: 'UPDATE_ALL', newOrders });
    };

    const toggleDiscountMethod = () => {
        let currDiscount = inputDiscount;
        if (inputDiscount === '') currDiscount = 0;
        if (inputDiscount !== '') {
            currDiscount = Number(inputDiscount);
            if (isNaN(currDiscount)) currDiscount = 0;
        }
        var newOrders;
        if (orders.discount.discount_type === 'Dollar') {
            newOrders = {
                ...orders,
                discount: {
                    discount_type: 'Percent',
                    percent_off: currDiscount,
                    amount:
                        (orders.payment_subtotal_before_discount *
                            currDiscount) /
                        100,
                },
                payment_subtotal: Math.round(
                    orders.payment_subtotal_before_discount *
                        (1 - currDiscount / 100)
                ),
                payment_salestax: Math.round(
                    (orders.payment_salestax_percent / 100) *
                        Math.round(
                            orders.payment_subtotal_before_discount *
                                (1 - currDiscount / 100)
                        )
                ),
                payment_total: Math.round(
                    orders.payment_subtotal_before_discount *
                        (1 - currDiscount / 100) *
                        (1 + orders.payment_salestax_percent / 100)
                ),
            };
        } else {
            newOrders = {
                ...orders,
                discount: {
                    discount_type: 'Dollar',
                    amount_off: currDiscount * 100,
                    amount: currDiscount * 100,
                },
                payment_subtotal: Math.round(
                    orders.payment_subtotal_before_discount - currDiscount * 100
                ),
                payment_salestax: Math.round(
                    (orders.payment_salestax_percent / 100) *
                        Math.round(
                            orders.payment_subtotal_before_discount -
                                currDiscount * 100
                        )
                ),
                payment_total: Math.round(
                    (orders.payment_subtotal_before_discount -
                        currDiscount * 100) *
                        (1 + orders.payment_salestax_percent / 100)
                ),
            };
        }
        dispatchOrder({ type: 'UPDATE_ALL', newOrders });
    };

    const updateTax = (value) => {
        setInputTax(value);
        let input = value;
        if (value === '') input = 0;

        if (value !== '') {
            input = Number(value);
            if (isNaN(input)) return;
        }

        var newOrders = {
            ...orders,
            payment_salestax_percent: input,
            payment_salestax: Math.round(
                (input / 100) * orders.payment_subtotal
            ),
            payment_total: Math.round(
                orders.payment_subtotal * (1 + input / 100)
            ),
        };
        dispatchOrder({ type: 'UPDATE_ALL', newOrders });
    };

    return (
        <Table size="small" sx={{ mb: 1 }}>
            <TableBody>
                <TableRow>
                    <TableCell variant="head" align="left">
                        Subtotal
                    </TableCell>
                    <TableCell align="left">
                        <Typography variant="body2">
                            {`Original Subtotal: ${formatPrice(
                                orders.payment_subtotal_original / 100
                            )}`}
                        </Typography>
                    </TableCell>
                    <TableCell align="left">
                        <Typography variant="body2">
                            {`Actual Subtotal: ${formatPrice(
                                orders.payment_subtotal_before_discount / 100
                            )}`}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell variant="head" align="left">
                        Additional Discount
                    </TableCell>
                    <TableCell align="left">
                        <TextField
                            size="small"
                            value={inputDiscount}
                            onChange={(e) => updateDiscount(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton
                                            size="small"
                                            sx={{ m: 0, p: 0 }}
                                            onClick={toggleDiscountMethod}
                                        >
                                            {orders.discount.discount_type ===
                                            'Dollar' ? (
                                                <SwitchLeftIcon
                                                    sx={{ fontSize: 15 }}
                                                />
                                            ) : (
                                                <SwitchRightIcon
                                                    sx={{ fontSize: 15 }}
                                                />
                                            )}
                                        </IconButton>
                                        <div style={{ width: 5 }}>
                                            {orders.discount.discount_type ===
                                            'Dollar'
                                                ? '$'
                                                : '%'}
                                        </div>
                                    </InputAdornment>
                                ),
                                style: { height: '30px', fontSize: '.875rem' },
                            }}
                        />
                    </TableCell>
                    <TableCell align="left">
                        <Typography variant="body2">
                            {`Additional Discount Amount: 
                                ${
                                    orders.discount.discount_type === 'Dollar'
                                        ? formatPrice(
                                              orders.discount.amount / 100
                                          )
                                        : formatPrice(
                                              ((orders.discount.percent_off /
                                                  100) *
                                                  orders.payment_subtotal_before_discount) /
                                                  100
                                          )
                                }`}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell variant="head" align="left">
                        Sales Tax
                    </TableCell>
                    <TableCell align="left">
                        <TextField
                            size="small"
                            value={inputTax}
                            onChange={(e) => updateTax(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        %
                                    </InputAdornment>
                                ),
                                style: { height: '30px', fontSize: '.875rem' },
                            }}
                        />
                    </TableCell>
                    <TableCell variant="head" align="left">
                        <Typography variant="body2">
                            {`Sales Tax Total: ${formatPrice(
                                orders.payment_salestax / 100
                            )}`}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableCell variant="head" align="left">
                    Final Total
                </TableCell>
                <TableCell variant="head" align="left"></TableCell>
                <TableCell variant="head" align="left">
                    {formatPrice(orders.payment_total / 100)}
                </TableCell>
            </TableBody>
        </Table>
    );
};

export default PriceTable;
