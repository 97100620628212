import React, { useContext, useState, useEffect } from 'react';
import FieldValidation from './FieldValidation';
import CateringSettingContext from '../CateringSettingContext';
import {
    Grid,
    TextField,
    FormControlLabel,
    Checkbox,
    MenuItem,
} from '@mui/material';
import DialogWithContent from '../../../../components/common/DialogWithContent/DialogWithContent';

const FieldDialog = ({
    open,
    setOpenFieldDialog,
    dialogTitle,
    setDialogTitle,
    defaultField,
    fieldData,
    isAddingNewField,
    setIsAddingNewField,
    allFields,
    setAllFields,
    fieldDialogIndex,
    setFieldDialogIndex,
}) => {
    const { updateFormConfig } = useContext(CateringSettingContext);
    const [editFieldForm, setEditFieldForm] = useState(defaultField);
    const [validationLoading, setValidationLoading] = useState(false);
    const [errors, setErrors] = useState({});
    useEffect(() => {
        setEditFieldForm(fieldData);
    }, []);

    const FIELD_TYPES = [
        'Text',
        'Number',
        'Dropdown',
        'Text Area',
        'Date',
        'Time',
    ];

    const updateFieldForm = (value) => {
        return setEditFieldForm((prev) => {
            return { ...prev, ...value };
        });
    };

    const cancel = () => {
        setDialogTitle('Edit Field');
        if (isAddingNewField) {
            setAllFields((prevFields) => {
                const newFields = [...prevFields];
                newFields.pop();
                return newFields;
            });
            setIsAddingNewField(false);
        }
        setEditFieldForm(defaultField);
        setOpenFieldDialog(false);
    };

    const saveField = async () => {
        // clean up previous type field
        const newFieldForm = { ...editFieldForm };
        const resetFields = [
            'field_type_text',
            'field_type_number',
            'field_type_date',
            'field_type_time',
            'field_type_dropdown',
            'field_type_text_area',
        ];
        resetFields.forEach((field) => {
            if (
                newFieldForm[field] &&
                field !==
                    `field_type_${newFieldForm.field_type
                        .toLowerCase()
                        .replace(' ', '_')}`
            ) {
                delete newFieldForm[field];
            }
        });

        // conduct validation
        setErrors({});
        const tempErrors = {};
        if (newFieldForm.field_label.length === 0) {
            tempErrors.field_label = 'Please provide field label';
        }
        if (newFieldForm.field_type.length === 0) {
            tempErrors.field_type = 'Please select field type';
        }
        if (newFieldForm.field_type === 'Text') {
            if (
                newFieldForm.validation.max_char.length !== 0 &&
                isNaN(Number(newFieldForm.validation.max_char))
            ) {
                tempErrors.text_max_char =
                    'Please provide a valid number, or leave as blank';
            } else if (newFieldForm.validation.max_char.length !== 0) {
                newFieldForm.validation.max_char = Number(
                    newFieldForm.validation.max_char
                );
            }
        } else if (newFieldForm.field_type === 'Number') {
            if (
                newFieldForm.validation.min_number.length !== 0 &&
                isNaN(Number(newFieldForm.validation.min_number))
            ) {
                tempErrors.number_min_number =
                    'Please provide a valid number, or leave as 0';
            } else if (newFieldForm.validation.min_number.length !== 0) {
                newFieldForm.validation.min_number = Number(
                    newFieldForm.validation.min_number
                );
            }
            if (
                newFieldForm.validation.max_number.length !== 0 &&
                isNaN(Number(newFieldForm.validation.max_number))
            ) {
                tempErrors.number_max_number =
                    'Please provide a valid number, or leave as blank';
            } else if (newFieldForm.validation.max_number.length !== 0) {
                newFieldForm.validation.max_number = Number(
                    newFieldForm.validation.max_number
                );
            }
        } else if (newFieldForm.field_type === 'Text Area') {
            if (
                newFieldForm.validation.max_char.length !== 0 &&
                isNaN(newFieldForm.validation.max_char)
            ) {
                tempErrors.text_area_max_char =
                    'Please provide a valid number, or leave as blank';
            } else if (newFieldForm.validation.max_char.length !== 0) {
                newFieldForm.validation.max_char = Number(
                    newFieldForm.validation.max_char
                );
            }
            if (newFieldForm.field_type_text_area.minRow.length === 0)
                newFieldForm.field_type_text_area.minRow = 3;
            else {
                if (isNaN(Number(newFieldForm.field_type_text_area.minRow))) {
                    tempErrors.text_area_minRow =
                        'Please provide a valid number, or leave as blank';
                } else {
                    newFieldForm.field_type_text_area.minRow = Number(
                        newFieldForm.field_type_text_area.minRow
                    );
                }
            }
        } else if (newFieldForm.field_type === 'Date') {
            if (newFieldForm.field_type_date.min_notice_period.length === 0)
                newFieldForm.field_type_date.min_notice_period = 0;
            else {
                if (
                    isNaN(
                        Number(newFieldForm.field_type_date.min_notice_period)
                    )
                ) {
                    tempErrors.date_min_notice_period =
                        'Please provide a valid number, or leave as blank';
                } else {
                    newFieldForm.field_type_date.min_notice_period = Number(
                        newFieldForm.field_type_date.min_notice_period
                    );
                }
            }
            if (newFieldForm.field_type_date.max_notice_period.length !== 0) {
                if (
                    isNaN(
                        Number(newFieldForm.field_type_date.max_notice_period)
                    )
                ) {
                    tempErrors.date_max_notice_period =
                        'Please provide a valid number, or leave as blank';
                } else {
                    newFieldForm.field_type_date.max_notice_period = Number(
                        newFieldForm.field_type_date.max_notice_period
                    );
                }
            }
        } else if (newFieldForm.field_type === 'Time') {
            if (newFieldForm.field_type_time.start_hour.length === 0)
                newFieldForm.field_type_time.start_hour = 0;
            else {
                if (
                    isNaN(Number(newFieldForm.field_type_time.start_hour)) ||
                    newFieldForm.field_type_time.start_hour < 0 ||
                    newFieldForm.field_type_time.start_hour > 23
                ) {
                    tempErrors.time_start_hour =
                        'Please enter a number between 0-23';
                } else {
                    newFieldForm.field_type_time.start_hour = Number(
                        newFieldForm.field_type_time.start_hour
                    );
                }
            }
            if (newFieldForm.field_type_time.start_min.length === 0)
                newFieldForm.field_type_time.start_min = 0;
            else {
                if (
                    isNaN(Number(newFieldForm.field_type_time.start_min)) ||
                    newFieldForm.field_type_time.start_min < 0 ||
                    newFieldForm.field_type_time.start_min > 59
                ) {
                    tempErrors.time_start_min =
                        'Please enter a number between 0-59';
                } else {
                    newFieldForm.field_type_time.start_min = Number(
                        newFieldForm.field_type_time.start_min
                    );
                }
            }
            if (newFieldForm.field_type_time.end_hour.length === 0)
                newFieldForm.field_type_time.end_hour = 23;
            else {
                if (
                    isNaN(Number(newFieldForm.field_type_time.end_hour)) ||
                    newFieldForm.field_type_time.end_hour < 0 ||
                    newFieldForm.field_type_time.end_hour > 23
                ) {
                    tempErrors.time_end_hour =
                        'Please enter a number between 0-23';
                } else {
                    newFieldForm.field_type_time.end_hour = Number(
                        newFieldForm.field_type_time.end_hour
                    );
                }
            }
            if (newFieldForm.field_type_time.end_min.length === 0)
                newFieldForm.field_type_time.end_min = 0;
            else {
                if (
                    isNaN(Number(newFieldForm.field_type_time.end_min)) ||
                    newFieldForm.field_type_time.end_min < 0 ||
                    newFieldForm.field_type_time.end_min > 59
                ) {
                    tempErrors.time_end_min =
                        'Please enter a number between 0-59';
                } else {
                    newFieldForm.field_type_time.end_min = Number(
                        newFieldForm.field_type_time.end_min
                    );
                }
            }
        } else if (newFieldForm.field_type === 'Dropdown') {
            for (
                let i = 0;
                i < newFieldForm.field_type_dropdown.options.length;
                i++
            ) {
                if (
                    newFieldForm.field_type_dropdown.options[i].label.length ===
                    0
                ) {
                    tempErrors[`dropdown_${i}`] = 'Please provide option value';
                }
            }
        }

        setErrors((prev) => {
            return { ...prev, ...tempErrors };
        });

        if (Object.keys(tempErrors).length === 0) {
            if (!newFieldForm.field_name) {
                // converting field label in camelCase.
                let temp = newFieldForm.field_label.split(' ');
                newFieldForm.field_name = temp[0];
                for (let i = 1; i < temp.length; i++) {
                    newFieldForm.field_name +=
                        temp[i][0].toUpperCase() + temp[i].substring(1);
                }
            }
            const newFields = [...allFields];
            newFields[fieldDialogIndex] = newFieldForm;
            console.log('newFields: ', newFields);
            setAllFields(newFields);
            let response = await updateFormConfig({ fields: newFields });
        } else {
            throw new Error('Validation failed');
        }
    };

    const handleFieldTypeChange = (e) => {
        const newFieldType = e.target.value;
        setValidationLoading(true);
        const newFieldForm = { ...editFieldForm };
        newFieldForm.field_type = newFieldType;

        for (let key in newFieldForm.validation) {
            if (key !== 'required' && key !== 'email' && key !== 'phone') {
                delete newFieldForm.validation[key];
            }
        }

        if (newFieldType === 'Text') {
            if (!newFieldForm.field_type_text) {
                newFieldForm.field_type_text = {};
            }
            if (!newFieldForm.validation.max_char) {
                newFieldForm.validation = {
                    ...newFieldForm.validation,
                    max_char: '',
                };
            }
        } else if (newFieldType === 'Number') {
            if (!newFieldForm.field_type_number) {
                newFieldForm.field_type_number = {
                    input_adornment: '',
                };
            }
            if (!newFieldForm.validation.is_number) {
                newFieldForm.validation = {
                    ...editFieldForm.validation,
                    is_number: true,
                    min_number: 0,
                    max_number: '',
                };
            }
        } else if (newFieldType === 'Text Area') {
            if (!editFieldForm.field_type_text_area) {
                updateFieldForm({ field_type_text_area: { minRow: 3 } });
            }
            if (!newFieldForm.validation.max_char) {
                newFieldForm.validation = {
                    ...editFieldForm.validation,
                    max_char: '',
                };
            }
        } else if (newFieldType === 'Date') {
            if (!editFieldForm.field_type_date) {
                updateFieldForm({
                    field_type_date: {
                        min_notice_period: 0,
                        max_notice_period: '',
                    },
                });
            }
        } else if (newFieldType === 'Time') {
            if (!newFieldForm.field_type_time) {
                newFieldForm.field_type_time = {
                    start_hour: 0,
                    start_min: 0,
                    end_hour: 23,
                    end_min: 0,
                };
            }
        } else if (newFieldType === 'Dropdown') {
            if (!newFieldForm.field_type_dropdown) {
                newFieldForm.field_type_dropdown = {
                    options: [
                        {
                            label: '',
                            desc: '',
                        },
                    ],
                };
            }
        }

        updateFieldForm(newFieldForm);
        setValidationLoading(false);
    };

    return (
        <>
            <DialogWithContent
                open={open}
                dialogTitle={dialogTitle}
                setOpen={setOpenFieldDialog}
                handleSave={saveField}
                handleClose={cancel}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <FormControlLabel
                            required
                            control={
                                <Checkbox
                                    checked={editFieldForm.enable_field}
                                    onChange={(e) => {
                                        updateFieldForm({
                                            enable_field:
                                                !editFieldForm.enable_field,
                                        });
                                        if (!editFieldForm.enable_field) {
                                            updateFieldForm({
                                                show_in_customer_form: false,
                                            });
                                            updateFieldForm({
                                                show_in_ai_bot: false,
                                            });
                                        }
                                    }}
                                />
                            }
                            label="Enable Field (uncheck to disable)"
                        />
                        {editFieldForm.enable_field ? (
                            <>
                                <FormControlLabel
                                    required
                                    control={
                                        <Checkbox
                                            checked={
                                                editFieldForm.show_in_customer_form
                                            }
                                            onChange={(e) =>
                                                updateFieldForm({
                                                    show_in_customer_form:
                                                        !editFieldForm.show_in_customer_form,
                                                })
                                            }
                                        />
                                    }
                                    label="Collect from website catering form"
                                />
                                <FormControlLabel
                                    required
                                    control={
                                        <Checkbox
                                            checked={
                                                editFieldForm.show_in_ai_bot
                                            }
                                            onChange={(e) =>
                                                updateFieldForm({
                                                    show_in_ai_bot:
                                                        !editFieldForm.show_in_ai_bot,
                                                })
                                            }
                                        />
                                    }
                                    label="Collect from catering phone bot"
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Field Label"
                            size="small"
                            variant="outlined"
                            margin="normal"
                            value={editFieldForm.field_label}
                            onChange={(e) =>
                                updateFieldForm({
                                    field_label: e.target.value,
                                })
                            }
                            helperText={
                                errors.field_label ? errors.field_label : null
                            }
                            error={errors.field_label}
                            sx={{ width: '90%' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label="Field Description"
                            size="small"
                            variant="outlined"
                            margin="normal"
                            helperText="Description will show under the field as a reminder to users (eg. minimum 5 guests required)"
                            value={editFieldForm.field_description}
                            onChange={(e) =>
                                updateFieldForm({
                                    field_description: e.target.value,
                                })
                            }
                            sx={{ width: '90%' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            required
                            control={
                                <Checkbox
                                    checked={editFieldForm.validation.required}
                                    onChange={(e) =>
                                        updateFieldForm({
                                            validation: {
                                                ...editFieldForm.validation,
                                                required:
                                                    !editFieldForm.validation
                                                        .required,
                                            },
                                        })
                                    }
                                />
                            }
                            label="Mandatory Field"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            required
                            select
                            label="Field Type"
                            size="small"
                            variant="outlined"
                            margin="normal"
                            sx={{ width: '90%' }}
                            value={editFieldForm.field_type}
                            onChange={handleFieldTypeChange}
                            helperText={
                                errors.field_type ? errors.field_type : null
                            }
                            error={errors.field_type}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                            {FIELD_TYPES.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={12}>
                        <FieldValidation
                            editFieldForm={editFieldForm}
                            updateFieldForm={updateFieldForm}
                            validationLoading={validationLoading}
                            errors={errors}
                        />
                    </Grid>
                </Grid>
            </DialogWithContent>
        </>
    );
};

export default FieldDialog;
