import React from 'react';
import PropTypes from 'prop-types';
import SubmitButton from '../formControls/SubmitButton';

function ACHSuccessForm(props) {
    const { navigate, achSuccess } = props;
    return (
        <div style={{ marginTop: '10px' }}>
            <SubmitButton
                severity="success"
                message={`${
                    achSuccess ? 'ACH' : 'Credit Card'
                } Linking successfully completed, Please login to admin dashboard`}
                onClick={() => navigate('/home')}
                label="Log in"
            />
        </div>
    );
}

ACHSuccessForm.propTypes = {
    navigate: PropTypes.func,
    achSuccess: PropTypes.bool,
};

export default ACHSuccessForm;
