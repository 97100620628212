import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SettingsIcon from '@mui/icons-material/Settings';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { useEffect } from 'react';
import { useState } from 'react';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function MainMenu() {
    //const [value, setValue] = React.useState(0);
    const [activeTab, setActiveTab] = useState('');

    const navigate = useNavigate();
    const location = useLocation();

    const menuTabs = [
        {
            value: 'fullmenu',
            label: 'Full Menu',
        },
        {
            value: 'modifiers',
            label: 'Modifier Groups',
        },
        {
            value: 'stations',
            label: 'Station Mapping',
        },
        {
            value: 'visibility',
            label: 'Visibility Override',
        },
    ];

    const settingsTab = {
        value: 'settings',
        label: 'Menu Settings',
    };

    const handleChange = (event, tab) => {
        navigate(tab);
    };

    const resetState = () => {
        const menuTab =
            menuTabs.find((option) =>
                location.pathname.endsWith(option.value)
            ) ||
            (location.pathname.endsWith('settings') && settingsTab) ||
            // picks first item as default tab
            menuTabs[0];

        const tab = menuTab?.value;
        //console.log('Activetab: ', tab);
        setActiveTab(tab);
    };

    useEffect(() => {
        resetState();
    }, [location.pathname]);

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={activeTab}
                    onChange={handleChange}
                    aria-label="Main Menu Tabs"
                    scrollButtons="auto"
                    variant="scrollable"
                >
                    {menuTabs.map(({ label, value }) => (
                        <Tab
                            key={value}
                            label={label}
                            value={value}
                            id={`menu-tab-${value}`}
                            aria-controls={`menu-tabpanel-${value}`}
                        />
                    ))}
                    <Tab
                        key={settingsTab.value}
                        icon={<SettingsIcon fontSize="medium" />}
                        iconPosition="start"
                        label={settingsTab.label}
                        value={settingsTab.value}
                        id={`menu-tab-${settingsTab.value}`}
                        aria-labelledby={`menu-tabpanel-${settingsTab.value}`}
                        sx={{ marginLeft: 'auto' }}
                    />
                </Tabs>
            </Box>
            <TabPanel
                id={`menu-tabpanel-${activeTab}`}
                aria-labelledby={`menu-tabpanel-${activeTab}`}
            >
                <Outlet />
            </TabPanel>
        </Box>
    );
}
