import {
    Button,
    CircularProgress,
    Divider,
    Grid,
    Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { DefaultWidgetValues } from '../PhoneSettingsConstants';
import PhoneSettingsContext from '../PhoneSettingsContext';
import SayPlayMessageDetails from './SayPlayMessage/SayPlayMessageDetails';
import TextMessageDetails from './TextMessageDetails';
import { isEqual } from 'lodash';
import ConfirmationDialog from './ConfirmationDialog';

function ReservationDetails() {
    const {
        txtMsgForReservations,
        setTxtMsgForReservations,
        reservationMsgDetails,
        setReservationMsgDetails,
        updatePhoneSettings,
        uploadAndUpdatePlayMessage,
        allowUpdateTxtMessage,
        restauName,
        restauId,
        setAccordionExpanded,
    } = useContext(PhoneSettingsContext);

    const [isProcessing, setIsProcessing] = useState(false);
    const [uploadedPlayFile, setUploadedPlayFile] = useState({});
    const [errMsg, setErrMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [originalReservationMsgDetails, setOriginalReservationMsgDetails] =
        useState([]);
    const [originalTxtMsgForReservations, setOriginalTxtMsgForReservations] =
        useState('');
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const updateReservationDetails = async () => {
        //debugger;
        let isMsgDetailsValid = true;
        if (
            reservationMsgDetails.length === 0 ||
            reservationMsgDetails[0].type === undefined
        ) {
            isMsgDetailsValid = false;
            setErrMsg('Message Details are not Valid.');
            return;
        }
        let isReservationTxtValid = true;
        if (txtMsgForReservations === '') {
            isReservationTxtValid = false;
            setErrMsg('Text Message to customer cannot be blank.');
            return;
        }
        if (isMsgDetailsValid && isReservationTxtValid) {
            setIsProcessing(true);
            setErrMsg('');
            let temp;
            if (reservationMsgDetails[0].type === 'play') {
                //Fix for uploading play file.
                //check if the play file changed.
                // if (
                //     originalReservationMsgDetails[0].playFilePath !==
                //     reservationMsgDetails[0].playFilePath
                // ) {
                //     temp = await uploadAndUpdatePlayMessage(
                //         reservationMsgDetails,
                //         uploadedPlayFile,
                //         setErrMsg,
                //         setSuccessMsg,
                //         originalReservationMsgDetails
                //     );
                //     if (!temp) {
                //         //debugger;
                //         setIsProcessing(false);
                //         return;
                //     }
                // } else {
                temp = JSON.parse(JSON.stringify(reservationMsgDetails));
                //}
            } else {
                temp = JSON.parse(JSON.stringify(reservationMsgDetails));
                delete temp[0].playFilePath;
            }

            let body = {
                reservations: {
                    message: temp,
                    sendText: txtMsgForReservations,
                },
            };
            //setIsProcessing(true);
            updatePhoneSettings(
                body,
                setErrMsg,
                setSuccessMsg,
                setIsProcessing
            );
        }
    };

    const resetData = () => {
        if (
            isEqual(reservationMsgDetails, originalReservationMsgDetails) &&
            txtMsgForReservations === originalTxtMsgForReservations
        ) {
            //close the accordion
            setAccordionExpanded(false);
        } else {
            setShowConfirmationDialog(true);
        }
    };

    const handleDiscardChanges = () => {
        setSuccessMsg('');
        setErrMsg('');
        setReservationMsgDetails(
            JSON.parse(JSON.stringify(originalReservationMsgDetails))
        );
        setTxtMsgForReservations(originalTxtMsgForReservations);
        setAccordionExpanded(false);
    };

    useEffect(() => {
        setErrMsg('');
        setSuccessMsg('');
        setOriginalReservationMsgDetails(
            JSON.parse(JSON.stringify(reservationMsgDetails))
        );
        setOriginalTxtMsgForReservations(txtMsgForReservations);
    }, []);

    return (
        <>
            <ConfirmationDialog
                showConfirmationDialog={showConfirmationDialog}
                setShowConfirmationDialog={setShowConfirmationDialog}
                handleConfirm={handleDiscardChanges}
            />
            <Grid container rowSpacing={3} xs={12} md={9}>
                <Grid item xs={12}>
                    <Typography variant="h6">Message to customer</Typography>
                </Grid>
                <Grid item xs={12}>
                    <SayPlayMessageDetails
                        msgDetails={reservationMsgDetails}
                        setMsgDetails={setReservationMsgDetails}
                        uploadedPlayFile={uploadedPlayFile}
                        setUploadedPlayFile={setUploadedPlayFile}
                        originalMsgDetails={originalReservationMsgDetails}
                    />
                </Grid>
                <Divider style={{ width: '100%' }} />
                <Grid item xs={12}>
                    <TextMessageDetails
                        txtMessage={txtMsgForReservations}
                        setTxtMessage={setTxtMsgForReservations}
                        id="reservation-msg-text"
                        messageHeading="Text Message to Customer"
                        toolTipText={DefaultWidgetValues(
                            'send_message_reservation',
                            restauName,
                            restauId
                        )}
                        size="large"
                        disabled={!allowUpdateTxtMessage}
                    />
                </Grid>
                {errMsg !== '' ? (
                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{ color: 'red' }}>
                            {errMsg}
                        </Typography>
                    </Grid>
                ) : (
                    successMsg !== '' && (
                        <Grid item xs={12}>
                            <Typography variant="body1" sx={{ color: 'green' }}>
                                {successMsg}
                            </Typography>
                        </Grid>
                    )
                )}
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                    }}
                >
                    <Button variant="outlined" onClick={resetData}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ ml: 2 }}
                        onClick={updateReservationDetails}
                        disabled={isProcessing}
                    >
                        {isProcessing && <CircularProgress size={24} />}
                        Save
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

ReservationDetails.propTypes = {};

export default ReservationDetails;
