import React from 'react';
import MultilocationContext from './MultilocationContext';
import useMultilocation from './useMultilocation';
import MultilocationContent from './MultilocationContent';

const Multilocation = () => {
    const multilocationHook = useMultilocation();

    return (
        <MultilocationContext.Provider value={multilocationHook}>
            <MultilocationContent />
        </MultilocationContext.Provider>
    );
};

export default Multilocation;
