import { Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import FormRoot from '../formControls/FormRoot';
import HelpText from '../formControls/HelpText';
import InputField from '../formControls/InputField';
import SubmitButton from '../formControls/SubmitButton';
import TitleBlock from '../formControls/TitleBlock';

function LoginForm(props) {
    const {
        email,
        loaded,
        password,
        loginMessage,
        onEmailChange,
        onKeyPress,
        onLogin,
        onPasswordChange,
    } = props;

    return (
        <FormRoot>
            <TitleBlock
                title="Login to StrideQ"
                subtitle="Welcome back! Login to use StrideQ"
            />

            <InputField
                id="outlined-email-input"
                label="Email"
                type="email"
                autoComplete="current-email"
                value={email}
                onChange={onEmailChange}
            />

            <InputField
                id="outlined-password-input"
                label="Password"
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={onPasswordChange}
                onKeyPress={onKeyPress}
            />

            <div>
                <Typography
                    align="right"
                    component="p"
                    variant="subtitle2"
                    display="block"
                    noWrap
                >
                    <Link to={`/resetpassword?email=${email}`}>
                        Reset Password
                    </Link>
                </Typography>
            </div>

            <SubmitButton
                message={loginMessage}
                severity={loginMessage ? 'error' : ''}
                showProgress={loaded}
                label="Sign In"
                onClick={onLogin}
            />
            <HelpText />
        </FormRoot>
    );
}

LoginForm.propTypes = {};

export default LoginForm;
