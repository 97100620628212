import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { RestauInfoContext } from '../../../home/RestauInfoContext';
import Choices from '../components/Choices';

export default function ChoicesAtFoodItem(props) {
    const { openFood, isModifier } = props;
    const { restauHook } = useContext(RestauInfoContext);
    const [openDialogToAddEditChoice, setOpenDialogToAddEditChoice] =
        useState(false);

    return (
        <Choices
            openDialogToAddEditChoice={openDialogToAddEditChoice}
            setOpenDialogToAddEditChoice={setOpenDialogToAddEditChoice}
            openFood={openFood}
            isModifier={isModifier}
        />
    );
}

ChoicesAtFoodItem.propTypes = {
    openFood: PropTypes.shape({
        value: PropTypes.shape({
            food: PropTypes.shape({
                id: PropTypes.string,
                choiceslabel: PropTypes.string,
                choices: PropTypes.array,
            }),
            sectionId: PropTypes.string,
        }),
        setValue: PropTypes.func,
    }),
    isModifier: PropTypes.bool,
};
