import React, { useState, useEffect, useContext } from 'react';
import CateringContext from '../../CateringContext';
import cloneDeep from 'lodash/cloneDeep';
import { Grid, TextField, MenuItem } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import moment from 'moment-timezone';

const useCateringSearch = () => {
    const [filters, setFilters] = useState([]);
    const [filterName, setFilterName] = useState('');
    const [operatorOptions, setOperatorOptions] = useState([
        'please select a filter first',
    ]);
    const [filterOperator, setFilterOperator] = useState('');
    const [renderFilterValue, setRenderFilterValue] = useState(null);
    const [filterValue1, setFilterValue1] = useState('');
    const [filterValue2, setFilterValue2] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const { eventStatus, retrieveFilteredEvents } = useContext(CateringContext);

    const filterConfig = [
        {
            label: 'Event Status',
            field: 'eventStatus',
            type: 'dropdown',
            dropdownOptions: eventStatus.map((status) => status.value),
        },
        { label: 'Event Date', field: 'eventDate', type: 'date' },
        { label: 'Email', field: 'email', type: 'text' },
        { label: 'Phone Number', field: 'phone', type: 'text' },
        { label: 'Submission Date', field: 'submissionDate', type: 'date' },
    ];

    const operatorMapping = {
        text: ['=', '!='],
        date: ['=', '!=', '<', '<=', '>', '>=', 'between'],
        time: ['=', '!=', '<', '<=', '>', '>=', 'between'],
        number: ['=', '!=', '<', '<=', '>', '>='],
        dropdown: ['='],
    };

    const filterValueField = () => {
        const selectedFilter = filterConfig.find((f) => f.label === filterName);
        if (!selectedFilter) {
            return (
                <Grid xs={12} md={2.5} sx={{ mr: 1 }}>
                    <TextField
                        label="Filter Value"
                        size="small"
                        variant="outlined"
                        margin="dense"
                        sx={{ width: '100%' }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            );
        }

        if (selectedFilter.type === 'date') {
            if (filterOperator === 'between') {
                return (
                    <>
                        <Grid item xs={12} md={2.5} sx={{ mr: 1 }}>
                            <DatePicker
                                required
                                label="Start Date"
                                size="small"
                                variant="outlined"
                                inputVariant="outlined"
                                margin="dense"
                                value={filterValue1}
                                onChange={(newValue) =>
                                    setFilterValue1(newValue)
                                }
                                renderInput={(params) => (
                                    <TextField
                                        size="small"
                                        margin="dense"
                                        sx={{ width: '100%' }}
                                        {...params}
                                        helperText={
                                            !errors.filterValue1
                                                ? null
                                                : errors.filterValue1
                                        }
                                        error={!!errors.filterValue1}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={2.5} sx={{ mr: 1 }}>
                            <DatePicker
                                required
                                label="End Date"
                                size="small"
                                variant="outlined"
                                inputVariant="outlined"
                                margin="dense"
                                value={filterValue2 || null}
                                onChange={(newValue) =>
                                    setFilterValue2(newValue)
                                }
                                renderInput={(params) => (
                                    <TextField
                                        size="small"
                                        margin="dense"
                                        sx={{ width: '100%' }}
                                        {...params}
                                        helperText={
                                            !errors.filterValue2
                                                ? null
                                                : errors.filterValue2
                                        }
                                        error={!!errors.filterValue2}
                                    />
                                )}
                            />
                        </Grid>
                    </>
                );
            } else {
                return (
                    <Grid item xs={12} md={2.5} sx={{ mr: 1 }}>
                        <DatePicker
                            required
                            label="Date Value"
                            size="small"
                            variant="outlined"
                            inputVariant="outlined"
                            margin="dense"
                            value={filterValue1 || null}
                            onChange={(newValue) => setFilterValue1(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    margin="dense"
                                    sx={{ width: '100%' }}
                                    {...params}
                                    helperText={
                                        !errors.filterValue1
                                            ? null
                                            : errors.filterValue1
                                    }
                                    error={!!errors.filterValue1}
                                />
                            )}
                        />
                    </Grid>
                );
            }
        } else if (selectedFilter.type === 'time') {
            if (filterOperator === 'between') {
                return (
                    <>
                        <Grid item xs={12} md={2.5} sx={{ mr: 1 }}>
                            <TimePicker
                                required
                                label="Start Time"
                                size="small"
                                variant="outlined"
                                inputVariant="outlined"
                                margin="dense"
                                value={filterValue1 || null}
                                onChange={(newValue) =>
                                    setFilterValue1(newValue)
                                }
                                renderInput={(params) => (
                                    <TextField
                                        size="small"
                                        margin="dense"
                                        sx={{ width: '100%' }}
                                        {...params}
                                        helperText={
                                            !errors.filterValue1
                                                ? null
                                                : errors.filterValue1
                                        }
                                        error={!!errors.filterValue1}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={2.5} sx={{ mr: 1 }}>
                            <TimePicker
                                required
                                label="End Time"
                                size="small"
                                variant="outlined"
                                inputVariant="outlined"
                                margin="dense"
                                value={filterValue2 || null}
                                onChange={(newValue) =>
                                    setFilterValue2(newValue)
                                }
                                renderInput={(params) => (
                                    <TextField
                                        size="small"
                                        margin="dense"
                                        sx={{ width: '100%' }}
                                        {...params}
                                        helperText={
                                            !errors.filterValue2
                                                ? null
                                                : errors.filterValue2
                                        }
                                        error={!!errors.filterValue2}
                                    />
                                )}
                            />
                        </Grid>
                    </>
                );
            } else {
                return (
                    <Grid item xs={12} md={2.5} sx={{ mr: 1 }}>
                        <TimePicker
                            required
                            label="Time Value"
                            size="small"
                            variant="outlined"
                            inputVariant="outlined"
                            margin="dense"
                            value={filterValue1 || null}
                            onChange={(newValue) => setFilterValue1(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    margin="dense"
                                    sx={{ width: '100%' }}
                                    {...params}
                                    helperText={
                                        !errors.filterValue1
                                            ? null
                                            : errors.filterValue1
                                    }
                                    error={!!errors.filterValue1}
                                />
                            )}
                        />
                    </Grid>
                );
            }
        } else if (selectedFilter.type === 'dropdown') {
            const dropdownOptions = selectedFilter.dropdownOptions;
            return (
                <Grid xs={12} md={2.5} sx={{ mr: 1 }}>
                    <TextField
                        required
                        select
                        label="Dropdown Value"
                        size="small"
                        variant="outlined"
                        margin="dense"
                        sx={{ width: '100%' }}
                        value={filterValue1}
                        onChange={(e) => setFilterValue1(e.target.value)}
                        helperText={
                            !errors.filterValue1 ? null : errors.filterValue1
                        }
                        error={!!errors.filterValue1}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    >
                        {dropdownOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            );
        } else {
            return (
                <Grid xs={6} md={2.5}>
                    <TextField
                        required
                        label="Filter Value"
                        size="small"
                        variant="outlined"
                        margin="dense"
                        sx={{ width: '100%' }}
                        value={filterValue1}
                        onChange={(e) => setFilterValue1(e.target.value)}
                        helperText={
                            !errors.filterValue1 ? null : errors.filterValue1
                        }
                        error={!!errors.filterValue1}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            );
        }
    };

    const deleteFilter = (i) => {
        let newFilters = cloneDeep(filters);
        newFilters = newFilters.filter((f) => f.key !== i);
        setFilters(newFilters);
    };

    const applyFilter = (e) => {
        e.preventDefault();
        setErrors({});
        const selectedFilter = filterConfig.find((f) => f.label === filterName);

        // conduct field validation
        const tempErrors = {};
        if (filterName === '' || filterName === null)
            tempErrors.filterName = 'Please provide filter name';
        if (filterOperator === '' || filterOperator === null)
            tempErrors.filterOperator = 'Please provide filter operator';
        if (filterValue1 === '' || filterValue1 === null)
            tempErrors.filterValue1 = 'Please provide filter value';
        else if (selectedFilter.type === 'number') {
            // if a number type but input is not a valid number
            if (isNaN(Number(filterValue1)))
                tempErrors.filterValue1 = 'Please provide a valid number';
            else setFilterValue1(Number(filterValue1));
        }
        if (filterOperator === 'between') {
            if (filterValue2 === '' || filterValue2 === null)
                tempErrors.filterValue2 = 'Please provide filter value';
        }
        setErrors(tempErrors);

        if (Object.keys(tempErrors).length === 0) {
            let newFilters = cloneDeep(filters);
            let currLength = filters.length;
            if (filterOperator === 'between') {
                newFilters.push({
                    key: currLength,
                    filterName: filterName,
                    field: selectedFilter.field,
                    type: selectedFilter.type,
                    operator: '>=',
                    value: filterValue1,
                });
                newFilters.push({
                    key: currLength + 1,
                    filterName: filterName,
                    field: selectedFilter.field,
                    type: selectedFilter.type,
                    operator: '<=',
                    value: filterValue2,
                });
            } else {
                newFilters.push({
                    key: currLength,
                    filterName: filterName,
                    field: selectedFilter.field,
                    type: selectedFilter.type,
                    operator: filterOperator,
                    value: filterValue1,
                });
            }
            setFilters(newFilters);
            setFilterValue1('');
            setFilterValue2('');
            setFilterOperator('');
        } else return;
    };

    const displayFilters = (filter) => {
        let valueToDisplay = filter.value;
        if (filter.type === 'date') {
            let date = moment(filter.value);
            valueToDisplay = date.format('YYYY/M/D');
        } else if (filter.type === 'time') {
            valueToDisplay = filter.value.format('h:mmA');
        }
        return filter.filterName + ' ' + filter.operator + ' ' + valueToDisplay;
    };

    const searchEvents = async (e) => {
        e.preventDefault();
        setResults([]);
        setLoading(true);
        try {
            const response = await retrieveFilteredEvents(filters);
            console.log('results: ', response.data);
            setResults(response.data.filteredEvents);
        } catch (error) {
            console.log('Error fetching event search results: ', error);
        } finally {
            setLoading(false);
        }
    };

    const resultColumns = [
        { field: 'id', headerName: 'Id', width: 50, align: 'left' },
        {
            field: 'submissionTimestamp',
            headerName: 'Submission Date',
            width: 130,
            align: 'left',
        },
        {
            field: 'eventDate',
            headerName: 'Event Date',
            width: 120,
            align: 'left',
        },
        {
            field: 'startTime',
            headerName: 'Event Time',
            width: 100,
            align: 'left',
        },
        {
            field: 'customerName',
            headerName: 'Customer',
            width: 150,
            align: 'left',
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 250,
            align: 'left',
        },
        {
            field: 'phone',
            headerName: 'Phone',
            width: 200,
            align: 'left',
        },
        {
            field: 'numOfGuests',
            headerName: '# of Guests',
            width: 100,
            align: 'left',
        },
        {
            field: 'eventStatus',
            headerName: 'Event Status',
            width: 100,
            align: 'left',
        },
        {
            field: 'invoice_payment_success',
            headerName: 'Invoice Paid',
            width: 110,
            align: 'left',
        },
        {
            field: 'payment_amount',
            headerName: 'Order Value($)',
            width: 120,
            align: 'left',
        },
        {
            field: 'invoice_payment_method',
            headerName: 'Invoice Method',
            width: 120,
            align: 'left',
        },
        {
            field: 'payment_salestax',
            headerName: 'Salse Tax',
            width: 80,
            align: 'left',
        },
        {
            field: 'tip',
            headerName: 'Tip($)',
            width: 80,
            align: 'left',
        },
        {
            field: 'deposit_amount',
            headerName: 'Deposit Amount($)',
            width: 150,
            align: 'left',
        },
        {
            field: 'deposit_payment_success',
            headerName: 'Deposit Paid',
            width: 110,
            align: 'left',
        },
        {
            field: 'invoice_payment_collected_by',
            headerName: 'Invoice Collected by',
            width: 150,
            align: 'left',
        },
        {
            field: 'deposit_payment_collected_by',
            headerName: 'Deposit Collected by',
            width: 150,
            align: 'left',
        },
    ];

    useEffect(() => {
        setFilterValue1('');
        setFilterValue2('');
        setRenderFilterValue(filterValueField());
        const selectedFilter = filterConfig.find((f) => f.label === filterName);
        if (selectedFilter)
            setOperatorOptions(operatorMapping[selectedFilter.type]);
    }, [filterName]);

    useEffect(() => {
        setRenderFilterValue(filterValueField());
    }, [filterValue1, filterValue2, filterOperator, filterName]);

    return {
        filters,
        setFilters,
        filterName,
        setFilterName,
        operatorOptions,
        setOperatorOptions,
        filterOperator,
        setFilterOperator,
        renderFilterValue,
        setRenderFilterValue,
        filterValue1,
        filterValue2,
        errors,
        loading,
        filterConfig,
        operatorMapping,
        filterValueField,
        deleteFilter,
        applyFilter,
        displayFilters,
        searchEvents,
        resultColumns,
        results,
    };
};

export default useCateringSearch;
