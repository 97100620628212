import React from 'react';
import Drawer from '@mui/material/Drawer';
// import clsx from 'clsx';
import { useStyles } from '../home/styles/useStyles';

function EditorDrawer(props) {
    const { open, children } = props;
    const classes = useStyles();
    return (
        <Drawer
            variant="permanent"
            anchor="right"
            open={open}
            // classes={{
            //     paper: classes.drawerPaper,
            // }}
        >
            <div className={classes.appBarSpacer} />
            {/* <div>Test Drawer</div> */}
            {children}
        </Drawer>
    );
}

EditorDrawer.propTypes = {};
EditorDrawer.defaultProps = {};

export default EditorDrawer;
