import { Grid, Typography } from '@mui/material';
import React from 'react';
import QRTemplateCard from './QRTemplateCard';

import { useContext } from 'react';
import QRContext from '../QRContext';

function QRTemplates() {
    const { filteredTemplates, selectedTemplate, setSelectedTemplate } =
        useContext(QRContext);

    const label = 'Templates';
    return (
        <div>
            <Typography variant="h6" component="h6">
                {label}
            </Typography>
            <Grid
                container
                sx={{ justifyContent: 'flex-start', gap: 2, marginTop: 1 }}
            >
                {filteredTemplates.length ? (
                    filteredTemplates.map(({ previewUri }) => (
                        <Grid key={previewUri} item>
                            <QRTemplateCard
                                src={previewUri}
                                selected={selectedTemplate === previewUri}
                                onSelected={setSelectedTemplate}
                            />
                        </Grid>
                    ))
                ) : (
                    <Grid item>
                        <QRTemplateCard />
                    </Grid>
                )}
            </Grid>
        </div>
    );
}

QRTemplates.propTypes = {};

export default QRTemplates;
