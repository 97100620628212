import React, { useState, useEffect } from 'react';
import SearchCriteria from './SearchCriteria';
import SearchResults from './SearchResults';

const CateringSearchContent = () => {
    const getResponsiveWidth = () => {
        if (window.innerWidth < 600) {
            return '100%'; // Full width on smaller screens
        } else {
            return '1000px';
        }
    };

    const [styles, setStyles] = useState({
        container: {
            width: getResponsiveWidth(), // Adjust based on screen size
            margin: '0 auto',
            padding: '0 auto',
        },
    });

    // Call this function when the component mounts and when the window resizes
    useEffect(() => {
        const handleResize = () => {
            setStyles({
                ...styles,
                container: {
                    ...styles.container,
                    maxWidth: getResponsiveWidth(),
                },
            });
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={styles.container}>
            <SearchCriteria />
            <SearchResults />
        </div>
    );
};

export default CateringSearchContent;
