import React from 'react';
import PhoneReportsContent from '../Components/PhoneReportsContent';
import PhoneReportsContext from '../PhoneReportsContext';
import usePhoneReports from '../usePhoneReports';

function PhoneReportsRoot(props) {
    const phoneReportsHook = usePhoneReports();

    return (
        <PhoneReportsContext.Provider value={phoneReportsHook}>
            <PhoneReportsContent />
        </PhoneReportsContext.Provider>
    );
}

PhoneReportsRoot.propTypes = {};

export default PhoneReportsRoot;
