import { Button, Grid, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useStyles } from '../../../../home/useStyles';
import PhoneSettingsContext from '../PhoneSettingsContext';
import DialogWithContent from '../../../../components/common/DialogWithContent/DialogWithContent';

function ProductPlanDetails() {
    const classes = useStyles();
    const { productPlanPackages, productPlans } =
        useContext(PhoneSettingsContext);
    const [openContactDetailsDialog, setOpenContactDetailsDialog] =
        useState(false);

    return (
        <>
            <Grid container rowSpacing={3} xs={12}>
                <Grid item xs={12}>
                    <Grid container rowSpacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Your Current Plan
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container xs={12} columnGap={2} rowGap={2}>
                                {Object.keys(productPlans).map((key) => {
                                    let plan = productPlans[key];
                                    return (
                                        <Grid
                                            key={plan.value}
                                            item
                                            md={2}
                                            xs={5.5}
                                            sx={{
                                                p: 4,
                                                textAlign: 'center',
                                            }}
                                            className={
                                                plan.selected
                                                    ? `${classes.selectedTile}`
                                                    : `${classes.unselectedTile}`
                                            }
                                        >
                                            <Typography
                                                variant="h6"
                                                sx={{ verticalAlign: 'middle' }}
                                            >
                                                {plan.label}
                                            </Typography>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container rowSpacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Features</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container xs={12} columnGap={2} rowGap={2}>
                                {Object.keys(productPlanPackages).map((key) => {
                                    let pkg = productPlanPackages[key];
                                    return (
                                        <Grid
                                            key={pkg.value}
                                            item
                                            md={1.75}
                                            xs={5.5}
                                            sx={{
                                                p: 2,
                                                textAlign: 'center',
                                            }}
                                            className={
                                                pkg.selected
                                                    ? `${classes.selectedTile}`
                                                    : `${classes.unselectedTile}`
                                            }
                                        >
                                            <Typography
                                                variant="h6"
                                                sx={{ verticalAlign: 'middle' }}
                                            >
                                                {pkg.label}
                                            </Typography>
                                            {/*   <Typography variant="body1">
                                            Description
                                        </Typography> */}
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container rowSpacing={2} columnSpacing={6}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Want to change plans?
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="lg"
                                onClick={() =>
                                    setOpenContactDetailsDialog(true)
                                }
                            >
                                Contact Us
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* For Adding New Override */}
            <DialogWithContent
                open={openContactDetailsDialog}
                setOpen={setOpenContactDetailsDialog}
                dialogTitle={'Contact Us'}
                dialogMaxWidth={'sm'}
                //handleSave={() => handleAddNewOverride(openVisOverrideData)}
                dialogCloseBtnText={'Close'}
                showSaveButton={false}
            >
                <Typography>
                    Email us at success@strideq.com or Use the chat feature to
                    reach us for any enquiries.
                </Typography>
                <Typography>
                    For any urgent matters, you can call us on the support
                    number : +16502821239.
                </Typography>
            </DialogWithContent>
        </>
    );
}

ProductPlanDetails.propTypes = {};

export default ProductPlanDetails;
