import React from 'react';
import { useRestauInfo } from '../Hooks/useRestauInfo';
import { useSummary } from '../Hooks/useSummary';
// Creating the context object and passing the default values.
export const RestauInfoContext = React.createContext(null);

export function RestaurantProvider({ children }) {
    const restauHook = useRestauInfo();
    const summaryHook = useSummary();

    return (
        <RestauInfoContext.Provider value={{ restauHook, summaryHook }}>
            {children}
        </RestauInfoContext.Provider>
    );
}
