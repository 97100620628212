let function_url = 'http://localhost:5001/sliceline-admin-stage/us-central1/';
let function_url_onboarding = 'http://127.0.0.1:5000/api/v1/';
let function_url_takeout =
    'http://localhost:5002/sliceline-takeout-stage-4bf89/us-central1/';

//uncomment below 2 lines  if you want to hit the stage backend during dev.
// function_url = 'https://us-central1-sliceline-admin-stage.cloudfunctions.net/';
// function_url_onboarding =
//     'https://us-central1-sliceline-onboarding.cloudfunctions.net/';

let secondary_db = 'https://sliceline-8644d.firebaseio.com';

const devConfig = {
    environment: 'Development-',
    env: 'stage',
    stripe_publish_key:
        'pk_test_51HrjUNHaof0IjCDr8uObDLPZEA0HLwx9QlWMUznvzoFbPey1QKbHuULuvmykdy51zgMMo24CSfhWqh7EfZmdto6X00ZiQaZ4Xr',
    secondaryAppConfig: {
        appId: '1:856193594445:web:e4515098abad087c48d804',
        apiKey: 'AIzaSyCDvtBiowtLtnulX9VYRPUSHJFgWQH-0N8',
        projectId: 'sliceline-takeout-stage-4bf89',
        databaseURL:
            'https://sliceline-takeout-stage-4bf89-default-rtdb.firebaseio.com',
    },
    firebaseConfig: {
        apiKey: 'AIzaSyA339JgrEpHw_tJanH8CGnTzhhncNcrsYc',
        authDomain: 'sliceline-admin-stage.firebaseapp.com',
        projectId: 'sliceline-admin-stage',
        storageBucket: 'sliceline-admin-stage.appspot.com',
        messagingSenderId: '771690064222',
        appId: '1:771690064222:web:7b3b73fe4e2b74df1547cf',
        measurementId: 'G-L54C03QZM8',
    },

    function_url,
    function_url_onboarding,
    function_url_takeout,
};

export default devConfig;
