import React from 'react';
import styled from 'styled-components';
import formatPrice from '../../../../../utils/formatPrice';

const FoodContainer = styled.div`
@media(max-width: 500px) {
padding: 7px 0px;
}
padding: 12px 0px;
border-bottom: 1px solid rgba(39,174,96,0.5);
background-color: ${(props) => (props.oos ? '#c0392b11' : 'white')}

&:hover {
  cursor: pointer;
  background-color: ${(props) => (props.oos ? '#c0392b11' : '#f8f8f8')};
}
`;
FoodContainer.displayName = 'FoodContainer';

const Title = styled.div`
    padding-left: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;
Title.displayName = 'Title';

const ImageDetails = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
`;
ImageDetails.displayName = 'ImageDetails';

const FoodLabelRight = styled.div`
    display: grid;
    grid-gap: 10px;

    ${(props) =>
        props.img !== undefined
            ? `grid-template-columns: auto 94px;`
            : `grid-template-columns: auto;`}
`;
FoodLabelRight.displayName = 'FoodLabelRight';

const Description = styled.div`
    color: gray;
    font-size: 13px;
    margin-top: 5px;
    padding-left: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;
Description.displayName = 'Description';

const StrikePrice = styled.span`
    font-size: 0.875rem;
    color: red;
    margin-left: 5px;
    text-decoration: line-through;
`;
StrikePrice.displayName = 'StrikePrice';

function FoodItem({ food, setOpenFood, setOpenFoodDialog }) {
    const isDiscountedPrice = (actualPrice, discountPrice) => {
        if (
            !actualPrice ||
            !discountPrice ||
            isNaN(actualPrice) ||
            isNaN(discountPrice)
        ) {
            return false;
        }
        if (actualPrice > discountPrice) {
            return true;
        } else {
            return false;
        }
    };

    const openDialog = (food) => {
        setOpenFood(food);
        setOpenFoodDialog(true);
    };

    return (
        <FoodContainer
            oos={food.oos}
            onClick={() => {
                food.oos
                    ? alert(food.name + ' is temporarily out of stock')
                    : openDialog(food);
            }}
        >
            <FoodLabelRight img={food.img}>
                <ImageDetails>
                    <Title>
                        <span opacity="0.5">{food.name} </span>
                        {food.oos && (
                            <span
                                style={{
                                    backgroundColor: '#e74c3c',
                                    color: '#FFFFFF',
                                    borderRadius: '5px',
                                }}
                            >
                                <b>Sold Out</b>
                            </span>
                        )}
                    </Title>
                    <Description>{food.desc}</Description>
                    <div
                        style={{
                            paddingLeft: '10px',
                            paddingTop: '5px',
                            fontSize: '15px',
                        }}
                    >
                        {food.pricelabel
                            ? food.pricelabel
                            : formatPrice(food.price / 100)}
                        {isDiscountedPrice(food.original_price, food.price) && (
                            <StrikePrice>
                                {formatPrice(food.original_price / 100)}
                            </StrikePrice>
                        )}
                    </div>
                </ImageDetails>

                {food.img && (
                    <img
                        src={food.img}
                        alt={food.name}
                        style={{
                            width: '94px',
                            height: '94px',
                            objectFit: 'cover',
                            borderRadius: '5%',
                        }}
                    />
                )}
            </FoodLabelRight>
        </FoodContainer>
    );
}

export default FoodItem;
