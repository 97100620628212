import React, { useContext, useEffect } from 'react';
import { MenuInfoContext } from '../../home/MenuInfoContext';
import { RestauInfoContext } from '../../home/RestauInfoContext';
import { useModifiers } from '../../Hooks/useModifiers';
import ModifierAddDialog from './ModifierAddDialog';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { CustomWidthTooltip } from '../StationMapping/CustomWidthTooltip';

export default function ModifierGroups() {
    const [isModEdit, setOpenModEdit] = React.useState(false);
    const [modifierAddEditDialogTitle, setModifierAddEditDialogTitle] =
        React.useState('Add/Edit Modifier');
    const { restauHook } = useContext(RestauInfoContext);
    const [isClose, setClose] = React.useState(false);
    const menuHook = useContext(MenuInfoContext);
    const [modifierMapping, setModifierMapping] = React.useState({});

    const modifiersHook = useModifiers();

    function addNewModifier() {
        setModifierAddEditDialogTitle('Add New Modifier Group');
        const newMod = modifiersHook.addModifier();
        //setCurrentModifier(newMod);
        modifiersHook.setAddEditModifierId(newMod.id);
        setOpenModEdit(true);
    }

    function editExistingModifier(mid) {
        setModifierAddEditDialogTitle('Edit Modifier Group');
        //setCurrentModifier(menuHook.value.modifiers[mid]);
        modifiersHook.setAddEditModifierId(mid);
        setOpenModEdit(true);
    }
    console.log(modifiersHook);
    console.log(menuHook);

    // Not sure about the below method.
    async function refreshMenu() {
        await menuHook.refreshData();
        modifiersHook.setModifiers(menuHook.value.modifiers);
        modifiersHook.refreshConvertedPrice(menuHook.value.modifiers);
    }
    useEffect(() => {
        // TODO : for multi-restau mode dara is not updating.
        // BELOW CODE NOT WORKING STILL.
        //debugger;
        //refreshMenu();
        //debugger;
        modifiersHook.setModifiers(menuHook.value.modifiers);
        modifiersHook.refreshConvertedPrice(menuHook.value.modifiers);
        const menu = menuHook.value.menu;
        let modifierMappingLocal = {};
        menu &&
            menu.forEach((cat) => {
                //console.log('Processing Category: ', cat.name);
                cat.foods &&
                    cat.foods.forEach((food) => {
                        //console.log('Processing Food: ', food.name);
                        let foodModifiers = food.modifiers;
                        //console.log('Stations at Food: ', stationsAtFoodLevel);
                        foodModifiers &&
                            foodModifiers.forEach((foodModifier) => {
                                if (
                                    modifierMappingLocal[foodModifier] ===
                                    undefined
                                ) {
                                    modifierMappingLocal[foodModifier] = {
                                        mappedTo: [],
                                    };
                                }
                                modifierMappingLocal[
                                    foodModifier
                                ].mappedTo.push(food.name);
                            });
                    });
            });
        setModifierMapping(modifierMappingLocal);
    }, [restauHook.restauId]);

    return (
        <>
            <Button
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: 1,
                    padding: '10px 15px',
                }}
                onClick={addNewModifier}
            >
                <AddCircleSharpIcon color="primary" sx={{ fontSize: 30 }} />
                <Typography sx={{ ml: '20px' }}>
                    Create Modifier Group
                </Typography>
            </Button>
            <br />

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="center">Items Assigned</TableCell>
                            {/* <TableCell align="center">Status</TableCell> */}
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {menuHook &&
                            menuHook.value &&
                            menuHook.value.modifiers &&
                            Object.keys(menuHook.value.modifiers).map(
                                (mid, index) => (
                                    <TableRow
                                        key={mid + '_row'}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell
                                            component="th"
                                            scope="station"
                                            style={{ width: 120 }}
                                        >
                                            {menuHook.value.modifiers[mid].name
                                                ? menuHook.value.modifiers[mid]
                                                      .name
                                                : menuHook.value.modifiers[mid]
                                                      .label}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                width: 850,
                                            }}
                                        >
                                            <CustomWidthTooltip
                                                placement="bottom"
                                                title={
                                                    modifierMapping &&
                                                    modifierMapping[mid] &&
                                                    modifierMapping[mid]
                                                        .mappedTo &&
                                                    modifierMapping[
                                                        mid
                                                    ].mappedTo.join(' | ')
                                                }
                                            >
                                                <Typography
                                                    sx={{
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: '2',
                                                        WebkitBoxOrient:
                                                            'vertical',
                                                    }}
                                                >
                                                    {modifierMapping &&
                                                        modifierMapping[mid] &&
                                                        modifierMapping[mid]
                                                            .mappedTo &&
                                                        modifierMapping[
                                                            mid
                                                        ].mappedTo.join(' | ')}
                                                </Typography>
                                            </CustomWidthTooltip>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            style={{ width: 100 }}
                                        >
                                            <Button
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    border: 1,
                                                    padding: '10px 15px',
                                                }}
                                                onClick={() =>
                                                    editExistingModifier(mid)
                                                }
                                            >
                                                <Typography>Edit</Typography>
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                    </TableBody>
                </Table>
            </TableContainer>

            <ModifierAddDialog
                modifierHook={modifiersHook}
                restauHook={restauHook}
                openModEdit={isModEdit}
                setOpenModEdit={setOpenModEdit}
                refreshMenu={menuHook.refreshData}
                setClose={setClose}
                modifierAddEditDialogTitle={modifierAddEditDialogTitle}
            />
        </>
    );
}
