import React from 'react';
import PropTypes from 'prop-types';
import {
    Autocomplete,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';

function SelectWithLabel(props) {
    const {
        size,
        id,
        label,
        minWidth,
        items,
        value,
        multiple,
        onChangeHandler,
        native,
        autoComplete,
    } = props;
    return autoComplete ? (
        <Autocomplete
            disablePortal
            id={id}
            value={
                items &&
                value &&
                items.filter((x) => x.id === value)[0] &&
                items.filter((x) => x.id === value)[0].label
            }
            options={items}
            multiple={multiple}
            size={size}
            sx={{ minWidth: { minWidth } }}
            onChange={(e, newVal) => {
                onChangeHandler(newVal.id);
            }}
            renderInput={(params) => <TextField {...params} label={label} />}
        />
    ) : (
        <FormControl size={size} sx={{ minWidth: { minWidth } }}>
            <InputLabel id={id}>{label}</InputLabel>
            <Select
                native={native}
                labelId={id}
                id={id}
                value={value}
                label={label}
                onChange={(e) => onChangeHandler(e.target.value, id)}
                autoWidth
                multiple={multiple}
            >
                {items &&
                    items.map((item) => (
                        <MenuItem value={item.value} key={item.id}>
                            {item.label}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
}

SelectWithLabel.propTypes = {
    size: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    minWidth: PropTypes.number,
    value: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    native: PropTypes.bool,
    autoComplete: PropTypes.bool,
};

SelectWithLabel.defaultProps = {
    size: 'small',
    id: 'select-with-label',
    label: 'Select With Label',
    minWidth: 120,
    multiple: false,
    items: [{ id: 'item1', label: 'item1', value: 'item1' }],
    native: false,
    autoComplete: false,
};

export default SelectWithLabel;
