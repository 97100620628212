import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import TextFieldWithMaxCharacters from '../../../../components/common/TextFieldWithMaxCharacters';
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';

function TextMessageDetails(props) {
    const {
        rowSpacing,
        messageHeading,
        toolTipText,
        fullWidth,
        id,
        multiline,
        rows,
        size,
        txtMessage,
        setTxtMessage,
        disabled,
    } = props;
    return (
        <Grid container rowSpacing={rowSpacing}>
            <Grid item xs={12}>
                <Typography variant="h6">
                    {messageHeading}
                    <Tooltip title={toolTipText} style={{ maxWidth: '700' }}>
                        <IconButton>
                            <InfoIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                    </Tooltip>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextFieldWithMaxCharacters
                    fullWidth={fullWidth}
                    id={id}
                    multiline={multiline}
                    rows={rows}
                    size={size}
                    sx={{ borderRadius: '8px' }}
                    txtMessage={txtMessage}
                    setTxtMessage={setTxtMessage}
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
}

TextMessageDetails.propTypes = {
    rowSpacing: PropTypes.number,
    messageHeading: PropTypes.string,
    toolTipText: PropTypes.string,
    fullWidth: PropTypes.bool,
    id: PropTypes.string.isRequired,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    size: PropTypes.string,
    txtMessage: PropTypes.string.isRequired,
    setTxtMessage: PropTypes.func.isRequired,
};

TextMessageDetails.defaultProps = {
    rowSpacing: 1,
    messageHeading: 'Heading',
    toolTipText: 'toolTip text',
    fullWidth: true,
    multiline: true,
    rows: 4,
    size: 'medium',
};

export default TextMessageDetails;
