import React from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

function DeletedFoodItems(props) {
    const { deletedFoodItems } = props;
    return (
        <>
            {deletedFoodItems.length > 0 ? (
                <TableContainer style={{ maxHeight: '400px' }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow
                                style={{
                                    backgroundColor: '#f5f5f5',
                                    height: '40px',
                                    textAlign: 'center',
                                }}
                            >
                                <TableCell style={{ maxWidth: '50px' }}>
                                    SL.No
                                </TableCell>
                                <TableCell
                                    style={{
                                        textAlign: 'center',
                                        minWidth: '250px',
                                    }}
                                >
                                    Category Name
                                </TableCell>
                                <TableCell
                                    style={{
                                        textAlign: 'center',
                                        minWidth: '200px',
                                    }}
                                >
                                    Food Name
                                </TableCell>
                                <TableCell style={{ width: '50px' }}>
                                    Price
                                </TableCell>
                                <TableCell style={{ width: '50px' }}>
                                    Hidden
                                </TableCell>
                                <TableCell style={{ width: '50px' }}>
                                    OutOfStock
                                </TableCell>
                                <TableCell
                                    style={{
                                        textAlign: 'center',
                                        minWidth: '250px',
                                    }}
                                >
                                    Description
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    Choices Label
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    ChoiceAPrice
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    ChoiceALabel
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    ChoiceBPrice
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    ChoiceBLabel
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    ChoiceCPrice
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    ChoiceCLabel
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    ChoiceDPrice
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    ChoiceDLabel
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    ChoiceEPrice
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    ChoiceELabel
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {deletedFoodItems.map((item, index) => (
                                <TableRow
                                    key={item.categoryName + item.foodName}
                                    style={{
                                        height: '40px',
                                        textAlign: 'center',
                                    }}
                                >
                                    <TableCell style={{ textAlign: 'center' }}>
                                        {index}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        {item.categoryName}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        {item.foodName}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        {item.price}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        {item.hidden && item.hidden.toString()}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        {item.outOfStock &&
                                            item.outOfStock.toString()}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        {item.foodDescription}
                                    </TableCell>
                                    <TableCell>{item.choiceslabel}</TableCell>
                                    <TableCell>{item.ChoiceAPrice}</TableCell>
                                    <TableCell>{item.ChoiceALabel}</TableCell>
                                    <TableCell>{item.ChoiceBLabel}</TableCell>
                                    <TableCell>{item.ChoiceBPrice}</TableCell>
                                    <TableCell>{item.ChoiceCPrice}</TableCell>
                                    <TableCell>{item.ChoiceCLabel}</TableCell>
                                    <TableCell>{item.ChoiceDLabel}</TableCell>
                                    <TableCell>{item.ChoiceDPrice}</TableCell>
                                    <TableCell>{item.ChoiceELabel}</TableCell>
                                    <TableCell>{item.ChoiceEPrice}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography>None</Typography>
            )}
        </>
    );
}

DeletedFoodItems.propTypes = {
    deletedFoodItems: PropTypes.arrayOf(PropTypes.shape({})),
};

export default DeletedFoodItems;
