import React from 'react';
import {
    FoodLabel,
    FoodContainer,
    ImageDetails,
    Description,
    PriceContainer,
    Price,
    HigherStrikePrice,
    FoodImage,
} from '../FoodGrid';
import { Title } from '../Styles/title';
import FoodOOS from './FoodOOS';
import FoodHidden from './FoodHidden';

function ImageRight(props) {
    const {
        sectionId,
        foodId,
        price,
        desc,
        oos,
        name,
        imgSrc,
        hide,
        originalPrice,
        onFoodClick,
    } = props;
    return (
        <FoodContainer key={sectionId + foodId} oos={oos} onClick={onFoodClick}>
            <FoodLabel>
                <ImageDetails>
                    <Title>
                        <span opacity="0.5">
                            <b>{name} </b>
                        </span>
                        {oos ? <FoodOOS /> : null}
                        {hide && hide === true ? <FoodHidden /> : null}
                    </Title>

                    <Description>{desc}</Description>
                    <PriceContainer>
                        <Price>{price}</Price>
                        <HigherStrikePrice>{originalPrice}</HigherStrikePrice>
                    </PriceContainer>
                </ImageDetails>

                {imgSrc ? <FoodImage src={imgSrc} alt={name} /> : null}
            </FoodLabel>
        </FoodContainer>
        //  <PopularItem
        //     data={{
        //         name: name,
        //         desc: desc,
        //         price: price,
        //         img: imgSrc,
        //         oos: oos,
        //         hide: hide,
        //     }}
        // />
    );
}

ImageRight.propTypes = {};

export default ImageRight;
