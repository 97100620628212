import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Rating } from '@mui/material';
import clsx from 'clsx';
import React, { useContext, useEffect } from 'react';
import CopyRight from '../common/CopyRight';
import Chart from './Chart';
import { DashboardContext } from './DashboardContext';
import Orders from './Orders';
import PastTwoDays from './PastTwoDays';
import { useStyles } from './styles/useStyles';
import CallSummary from './CallSummary';
import { RestauInfoContext } from './RestauInfoContext';
import { useState } from 'react';
import axios from 'axios';
import Config from '../config/Config';

export default function HomeSummary() {
    const { dashboardHook } = useContext(DashboardContext);
    const { restauHook } = useContext(RestauInfoContext);
    const [strideQOnlineOrdering, setStrideQOnlineOrdering] = useState(true);

    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const chartFixedHeightPaper = clsx(classes.paper);

    function getAverageRating(dashboardHook) {
        if (
            !dashboardHook.value.allReviews ||
            !dashboardHook.value.allReviews.food
        ) {
            return 0;
        } else {
            return dashboardHook.value.allReviews.food.averageOverallFood;
        }
    }

    function getAverageOnlineRating(dashboardHook) {
        if (
            !dashboardHook.value.allReviews ||
            !dashboardHook.value.allReviews.online
        ) {
            return 0;
        } else {
            return dashboardHook.value.allReviews.online.averageOverallOnline;
        }
    }

    const fetchSubscriptionPlanData = async () => {
        if (!restauHook.restauId) {
            console.log('ReastauId not available.');
            return '';
        }
        const restauId = restauHook.restauId;
        const token = await window.customerApp.auth().currentUser.getIdToken();

        let config = {
            headers: {
                token: token,
            },
        };
        //debugger;
        let response = await axios
            .post(
                Config.getSubscriptionPlanForRestau,
                { restauId: restauId },
                config
            )
            .catch(function (error) {
                //let url = location.protocol + '//' + location.host;
                //window.location.href = url + '/login';
                console.log(error);
            });

        if (response && response.status === 200) {
            restauHook.setSubscriptionPlan(response.data);
        }
    };

    /*   useEffect(() => {
        setStrideQOnlineOrdering(restauHook.strideQOnlineOrdering);
    }, []); */

    useEffect(() => {
        dashboardHook.loadData(restauHook.restauId);
        fetchSubscriptionPlanData();
        setStrideQOnlineOrdering(restauHook.strideQOnlineOrdering);
    }, [restauHook.restauId]);

    return (
        <div>
            {strideQOnlineOrdering && <PastTwoDays />}

            <CallSummary last30Days={true} />

            {strideQOnlineOrdering ? (
                !dashboardHook.value.allOrders ? (
                    <div>
                        <img
                            className={classes.loadingImage}
                            src="../../img/spinner.gif"
                        />
                    </div>
                ) : (
                    <>
                        <Grid container spacing={3}>
                            {/* Chart */}

                            <Grid item xs={12} md={8} lg={9}>
                                <Typography
                                    component="b"
                                    variant="h6"
                                    color="primary"
                                    gutterBottom
                                >
                                    Daily Orders
                                </Typography>
                                <Paper
                                    className={fixedHeightPaper}
                                    style={{ height: '300px' }}
                                >
                                    <Chart
                                        allOrders={
                                            dashboardHook.value.allOrders
                                        }
                                    />
                                </Paper>
                            </Grid>

                            <Grid item xs={12} md={4} lg={3}>
                                <Typography
                                    component="b"
                                    variant="h6"
                                    color="primary"
                                    gutterBottom
                                >
                                    Past 10 Days Ratings
                                </Typography>
                                <Paper
                                    className={chartFixedHeightPaper}
                                    style={{ height: '300px' }}
                                >
                                    <Box
                                        component="fieldset"
                                        mb={1}
                                        borderColor="transparent"
                                    >
                                        <Typography component="legend">
                                            Food Rating
                                        </Typography>
                                        <Rating
                                            name="read-only"
                                            value={getAverageRating(
                                                dashboardHook
                                            )}
                                            readOnly
                                        />
                                    </Box>
                                    <Box
                                        component="fieldset"
                                        mb={1}
                                        borderColor="transparent"
                                    >
                                        <Typography component="legend">
                                            Online Service
                                        </Typography>
                                        <Rating
                                            name="read-only"
                                            value={getAverageOnlineRating(
                                                dashboardHook
                                            )}
                                            readOnly
                                        />
                                    </Box>
                                </Paper>
                            </Grid>
                            {/* Recent Orders */}
                            <Grid item xs={12}>
                                <Typography
                                    component="b"
                                    variant="h6"
                                    color="primary"
                                    gutterBottom
                                >
                                    Past 10 Days Orders
                                </Typography>
                                <Paper className={classes.paper}>
                                    <Orders
                                        allOrders={
                                            dashboardHook.value.allOrders
                                        }
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                        <Box pt={4}>
                            <CopyRight />
                        </Box>
                    </>
                )
            ) : (
                <></>
            )}
        </div>
    );
}
