import {
    Button,
    DialogActions,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputAdornment,
    Switch,
    Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Config from '../config/Config';
import { useStyles } from '../home/useStyles';
import { ModifierGroupsForFoodItem } from './ModifierGroup/ModifierGroupsForFoodItem';
import { corianderGreen } from './Styles/colors';
import { style } from './Styles/style';
import { Title } from './Styles/title';
import { getUUID } from './Utils';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/system';
import FoodCardPreview from './FoodCardPreview';
import DeleteItem from './DeleteItem';
import PrintStationsAtFoodItem from './PrintStations/containers/PrintStationsAtFoodItem';
import AssignedOverridesAtFoodItem from './VisiblityOverride/containers/AssignedOverridesAtFoodItem';
import ChoicesAtFoodItem from './Choices/containers/ChoicesAtFoodItem';
const { default: axios } = require('axios');

export const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export const DialogFooter = styled.div`
    box-shadow: 0px -2px 0px 0px #e7e7e7;
    display: flex;
    justify-content: center;
    min-height: 60px;
`;

export const ConfirmButton = styled(Title)`
    margin: 10px;
    color: white;
    font-weight: bold;
    height: 40px;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    width: 200px;
    cursor: pointer;
    background-color: ${corianderGreen};
    ${({ disabled }) =>
        disabled &&
        `
    opactity: .5; 
    background-color: grey; 
    pointer-events: none; 
  `}
`;

export const AddButton = styled(Title)`
    margin-left: 32px;
    color: white;
    font-weight: bold;
    height: 10px;
    border-radius: 5px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 130px;
    cursor: pointer;
    background-color: ${corianderGreen};
    ${({ disabled }) =>
        disabled &&
        `
    opactity: .5; 
    background-color: grey; 
    pointer-events: none; 
  `}
`;

export const DialogShadow = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;
    background-color: black;
    opacity: 0.7;
    z-index: 19;
`;

export const LabelContainer = styled(Title)`
    display: flex;
    height: 24px;
`;

function FoodDialogContainer(
    openFood,
    menuHook,
    restauHook,
    classes,
    loader,
    styl,
    uploadError,
    setUploadError,
    modifiersHook,
    setClose,
    refreshMenu,
    modEdit,
    setModEdit,
    hideFood,
    setHideFood,
    oosFood,
    setOosFood,
    imgData,
    setImgData,
    imageUrl,
    setImageUrl,
    deleteFoodItem
) {
    function close() {
        setUploadError('');
        openFood.setValue({});
        modifiersHook.setCurrentModifiers([]);
    }

    function cancel() {
        setUploadError('');
        openFood.setValue({});
        modifiersHook.setCurrentModifiers([]);
        setImageUrl(null);
        setClose(true);
        refreshMenu();
    }
    async function edit(menu, sectionId) {
        //debugger;
        const database = window.customerApp.database();
        let updates = {};
        let secId;
        let sec;
        let foodId;
        if (!openFood.value.food.name || !openFood.value.food.name.trim()) {
            alert('Please enter a valid name for the item');
            return;
        }

        if (!openFood.value.food.price || openFood.value.food.price <= 0) {
            alert('Please enter a valid price for the item');
            return;
        }
        for (let i = 0; i < menu.value.menu.length; i++) {
            let data = menu.value.menu[i];
            if (sectionId === data.id) {
                secId = i;
                sec = data;
                break;
            }
        }
        if (!sec.foods) {
            sec.foods = [];
        }
        for (let j = 0; j < sec.foods.length; j++) {
            let food = sec.foods[j];
            if (openFood.value.food.id === food.id) {
                foodId = j;
                break;
            }
        }

        if (secId === undefined || secId === null || secId === 'undefined') {
            loader.setValue({ loading: false });
            return;
        }
        if (!validateSize(openFood.value.selectedFile, openFood)) {
            loader.setValue({ loading: false });
            return;
        }

        if (foodId === undefined) {
            foodId = sec.foods.length;
            const newFoodId = getUUID();
            openFood.value.food.id = newFoodId;
        }
        loader.setValue({ loading: true });
        if (openFood.value.selectedFile) {
            await uploadImage(openFood);
        }

        if (
            openFood.value.food.choices &&
            openFood.value.food.choices.length > 0
        ) {
            for (let i = 0; i < openFood.value.food.choices.length; i++) {
                let cs = openFood.value.food.choices[i];
                if (
                    !cs ||
                    !cs.price ||
                    !cs.name ||
                    cs.price === undefined ||
                    cs.price === '' ||
                    isNaN(cs.price) ||
                    cs.price <= 0
                ) {
                    loader.setValue({ loading: false });
                    alert('please add the valid name and price for choice');
                    return;
                }
            }
            openFood.value.food.choice = openFood.value.food.choices[0].name;
            openFood.value.food.choiceId = openFood.value.food.choices[0].id;
        }

        if (
            !openFood.value.food.choices ||
            openFood.value.food.choices.length <= 0
        ) {
            delete openFood.value.food.choices;
            delete openFood.value.food.choice;
            delete openFood.value.food.choiceId;
            delete openFood.value.food.choiceslabel;
            updates[
                'menus/' +
                    restauHook.restauId +
                    '/payments/priceMapping/' +
                    openFood.value.food.id +
                    '/choices'
            ] = null;
        }

        if (
            openFood.value.food.toppings &&
            openFood.value.food.toppings.length > 0
        ) {
            for (let i = 0; i < openFood.value.food.toppings.length; i++) {
                let ts = openFood.value.food.toppings[i];

                if (
                    !ts ||
                    !ts.name ||
                    ts.price === undefined ||
                    ts.price === '' ||
                    isNaN(ts.price) ||
                    ts.price < 0
                ) {
                    loader.setValue({ loading: false });
                    alert('please add the name and price for Toppings');
                    return;
                }
            }
        }

        if (
            !openFood.value.food.toppings ||
            openFood.value.food.toppings.length <= 0
        ) {
            delete openFood.value.food.toppings;
            delete openFood.value.food.toppingslabel;
            delete openFood.value.food.toppingsmax;
            updates[
                'menus/' +
                    restauHook.restauId +
                    '/payments/priceMapping/' +
                    openFood.value.food.id +
                    '/toppings'
            ] = null;
        }

        if (
            modifiersHook.currentModifiers &&
            Object.values(modifiersHook.currentModifiers).length > 0
        ) {
            openFood.value.food.modifiers = Object.values(
                modifiersHook.currentModifiers
            );
        } else {
            if (openFood.value.food.modifiers) {
                openFood.value.food.modifiers = null;
            }
        }

        updates[
            'menus/' +
                restauHook.restauId +
                '/menu/' +
                secId +
                '/foods/' +
                foodId
        ] = openFood.value.food;
        updates[
            'menus/' +
                restauHook.restauId +
                '/payments/priceMapping/' +
                openFood.value.food.id +
                '/name'
        ] = openFood.value.food.name;
        updates[
            'menus/' +
                restauHook.restauId +
                '/payments/priceMapping/' +
                openFood.value.food.id +
                '/price'
        ] = openFood.value.food.price;
        if (openFood.value.food.original_price) {
            if (
                openFood.value.food.original_price < openFood.value.food.price
            ) {
                loader.setValue({ loading: false });
                alert(' Striked price should be greater than the Actual price');
                return;
            }
            updates[
                'menus/' +
                    restauHook.restauId +
                    '/payments/priceMapping/' +
                    openFood.value.food.id +
                    '/original_price'
            ] = openFood.value.food.original_price;
        }

        if (openFood.value.food.choices) {
            let updatedChoices = {};
            for (let i = 0; i < openFood.value.food.choices.length; i++) {
                updatedChoices[openFood.value.food.choices[i].id] =
                    openFood.value.food.choices[i];
            }

            updates[
                'menus/' +
                    restauHook.restauId +
                    '/payments/priceMapping/' +
                    openFood.value.food.id +
                    '/choices'
            ] = updatedChoices;
        }
        if (openFood.value.food.toppings) {
            let updatedToppings = {};
            for (let i = 0; i < openFood.value.food.toppings.length; i++) {
                updatedToppings[openFood.value.food.toppings[i].id] =
                    openFood.value.food.toppings[i];
            }

            updates[
                'menus/' +
                    restauHook.restauId +
                    '/payments/priceMapping/' +
                    openFood.value.food.id +
                    '/toppings'
            ] = updatedToppings;
        }

        console.log('udpdate the price...........');
        console.log(openFood.value.food);
        try {
            console.log('update');
            console.log(updates);
            await database
                .ref()
                .update(updates)
                .then(function () {
                    // alert("Data saved successfully.");
                })
                .catch(function (error) {
                    alert('Error while saving please try again');
                    //Need to pop error someways

                    loader.setValue({ loading: false });
                    return;
                });
            const token = await window.customerApp
                .auth()
                .currentUser.getIdToken();
            let config = {
                headers: {
                    token: token,
                },
            };
            let updatedMenu = menu.value;
            updatedMenu.menu[secId].foods[foodId] = openFood.value.food;
            menu.setValue(updatedMenu);

            const resp = axios.post(
                Config.updateMenu,
                { menu: menu.value, restauId: restauHook.restauId },
                config
            );
            close();
        } catch (error) {
            loader.setValue({ loading: false });
            console.log(error);
        }
        loader.setValue({ loading: false });
    }

    function nameOnChange(e, openFood) {
        let val = openFood.value;
        val.food.name = e.target.value;
        openFood.setValue(val);
    }

    function descOnChange(e, openFood) {
        let val = openFood.value;
        val.food.desc = e.target.value;
        openFood.setValue(val);
    }
    function hideOnChange(e, openFood) {
        setHideFood(e.target.checked);
        let val = openFood.value;
        val.food.hide = e.target.checked;
        openFood.setValue(val);
    }
    function oosOnChange(e, openFood) {
        setOosFood(e.target.checked);
        let val = openFood.value;
        val.food.oos = e.target.checked;
        openFood.setValue(val);
    }
    function choiceLabelOnChange(e, openFood) {
        let val = openFood.value;
        val.food.choiceslabel = e.target.value;
        openFood.setValue(val);
    }

    function toppingsLabelOnChange(e, openFood) {
        let val = openFood.value;
        val.food.toppingslabel = e.target.value;
        openFood.setValue(val);
    }
    function toppingMaxOnChange(e, openFood) {
        let val = openFood.value;
        val.food.toppingsmax = e.target.value;
        openFood.setValue(val);
    }

    function priceOnChange(e, openFood) {
        let val = openFood.value;
        val.food.price = Math.round(e.target.value * 100);
        console.log('original val', val.food.price);
        openFood.setValue(val);
    }

    function originalPriceOnChange(e, openFood) {
        let val = openFood.value;
        val.food.original_price = Math.round(e.target.value * 100);
        openFood.setValue(val);
    }
    function getPrice(price) {
        if (!price) {
            return price;
        }
        let p = parseFloat(price) / 100;
        return p.toFixed(2);
    }

    function choiceNameOnChange(e, food, openFood, i) {
        let val = food;
        val.choices[i].name = e.target.value;
        openFood.setValue({
            food: val,
            display: true,
            sectionId: openFood.value.sectionId,
        });
    }

    function toppingsNameOnChange(e, food, openFood, i) {
        let val = food;
        val.toppings[i].name = e.target.value;
        openFood.setValue({
            food: val,
            display: true,
            sectionId: openFood.value.sectionId,
        });
    }

    function choicePriceOnChange(e, food, openFood, i) {
        let val = food;
        val.choices[i].price = Math.round(e.target.value * 100);
        openFood.setValue({
            food: val,
            display: true,
            sectionId: openFood.value.sectionId,
        });
    }

    function toppingsPriceOnChange(e, food, openFood, i) {
        let val = food;
        val.toppings[i].price = Math.round(e.target.value * 100);
        openFood.setValue({
            food: val,
            display: true,
            sectionId: openFood.value.sectionId,
        });
    }
    function addChoices(openFood) {
        let food = openFood.value.food;
        if (!food.choices) {
            food.choices = [];
        }
        const translator = getUUID();
        let choiceId = translator.new();
        food.choices.push({ id: choiceId, name: '', price: '' });
        openFood.setValue({
            food: food,
            display: true,
            sectionId: openFood.value.sectionId,
        });
    }

    function deleteChoice(choiceId, openFood) {
        let food = openFood.value.food;
        for (let i = 0; i < food.choices.length; i++) {
            if (food.choices[i].id === choiceId) {
                const index = i;
                if (index > -1) {
                    food.choices.splice(index, 1);
                }

                break;
            }
        }

        openFood.setValue({
            food: food,
            display: true,
            sectionId: openFood.value.sectionId,
        });
    }

    function deleteToppings(toppingId, openFood) {
        let food = openFood.value.food;
        for (let i = 0; i < food.toppings.length; i++) {
            if (food.toppings[i].id === toppingId) {
                const index = i;
                if (index > -1) {
                    food.toppings.splice(index, 1);
                }

                break;
            }
        }

        openFood.setValue({
            food: food,
            display: true,
            sectionId: openFood.value.sectionId,
        });
    }
    function addToppings(openFood) {
        let food = openFood.value.food;
        if (!food.toppings) {
            food.toppings = [];
        }
        let toppingId = getUUID();
        food.toppings.push({ id: toppingId, name: '', price: '' });

        openFood.setValue({
            food: food,
            display: true,
            sectionId: openFood.value.sectionId,
        });
    }

    function onImageChange(e, openFood) {
        let val = openFood.value;
        val.selectedFile = e.target.files[0];
        openFood.setValue(val);
        setImgData(e.target.files[0]);
    }

    function onDeleteImage(openFood) {
        let val = openFood.value;
        val.selectedFile = null;
        val.food.img = null;
        openFood.setValue(val);
        setImgData(null);
        setImageUrl(null);
    }
    function validateSize(input, openFood) {
        setUploadError('');
        if (!input) {
            return true;
        }
        const fileSize = input.size / 1024 / 1024;
        if (fileSize > 5) {
            setUploadError(
                'Image size should not exceeds 5MB , please upload a smaller size file'
            );
            return false;
        } else {
            return true;
        }
    }
    async function uploadImage(openFood) {
        const formData = new FormData();
        let imagefile = openFood.value.selectedFile;
        if (imagefile) {
            formData.append('file', imagefile);
            formData.append('restauId', restauHook.restauId);
            const token = await window.customerApp
                .auth()
                .currentUser.getIdToken();

            await axios
                .post(Config.uploadFile, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        token: token,
                    },
                })
                .then(function (response) {
                    let oval = openFood.value;
                    oval.food.img = response.data.file;
                    openFood.setValue(oval);
                })
                .catch(function (error) {
                    console.log(error);
                });
            console.log('upload image completed');
        }
    }

    function handleDeleteFood() {
        deleteFoodItem(openFood.value.food.id, openFood.value.sectionId);
    }

    let isModifier = false;
    if (modifiersHook && modifiersHook.value && modifiersHook.value.modifiers) {
        isModifier = true;
    } else if (
        openFood.value &&
        openFood.value.food &&
        !openFood.value.food.choices
    ) {
        isModifier = true;
    } else if (
        openFood.value &&
        openFood.value.food &&
        !openFood.value.food.toppings
    ) {
        isModifier = true;
    }

    //const hideFood = openFood.value.food.hide;
    return (
        <>
            {/*onClose={props.handleClose}*/}
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={openFood.value.display}
            >
                <DialogTitle>
                    <Typography variant="h4"> Update Menu </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormGroup
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <FormControlLabel
                                    xs={3}
                                    control={
                                        <Switch
                                            checked={hideFood}
                                            onChange={(e) => {
                                                hideOnChange(e, openFood);
                                            }}
                                        />
                                    }
                                    label="Hidden"
                                />
                                <FormControlLabel
                                    xs={3}
                                    control={
                                        <Switch
                                            checked={oosFood}
                                            onChange={(e) => {
                                                oosOnChange(e, openFood);
                                            }}
                                        />
                                    }
                                    label="Out of Stock"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <Grid
                                item
                                xs={3}
                                sx={{ mr: 1, position: 'relative' }}
                            >
                                {imageUrl ? (
                                    <>
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '5px',
                                                left: '5px',
                                            }}
                                        >
                                            <label htmlFor="edit-food-image">
                                                <IconButton
                                                    component="label"
                                                    sx={{ m: 0, p: 0 }}
                                                >
                                                    <EditIcon
                                                        sx={{ fontSize: 20 }}
                                                    />
                                                    <input
                                                        accept="image/*"
                                                        type="file"
                                                        id="edit-food-image"
                                                        style={{
                                                            display: 'none',
                                                        }}
                                                        onChange={(e) =>
                                                            onImageChange(
                                                                e,
                                                                openFood
                                                            )
                                                        }
                                                    />
                                                </IconButton>
                                            </label>

                                            <IconButton
                                                sx={{ m: 0, p: 0 }}
                                                onClick={() => {
                                                    console.log(
                                                        'Delete icon clicked.'
                                                    );
                                                    onDeleteImage(openFood);
                                                }}
                                            >
                                                <DeleteIcon
                                                    sx={{ ml: 1, fontSize: 20 }}
                                                />
                                            </IconButton>
                                        </Box>
                                        <img
                                            src={imageUrl}
                                            alt={imgData && imgData.name}
                                            width="100%"
                                            height="100%"
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            component="label"
                                            htmlFor="select-food-image"
                                            xs={6}
                                            sx={{
                                                m: 0,
                                                p: 0,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: 1,
                                                height: '95%',
                                                maxWidth: '92%',
                                            }}
                                        >
                                            <AddCircleSharpIcon
                                                color="primary"
                                                sx={{ fontSize: 40 }}
                                            />
                                            <Typography
                                                variant="body1"
                                                sx={{ mt: 1, p: 0 }}
                                            >
                                                Upload Image
                                            </Typography>
                                        </Button>

                                        <input
                                            accept="image/*"
                                            type="file"
                                            id="select-food-image"
                                            style={{ display: 'none' }}
                                            onChange={(e) =>
                                                onImageChange(e, openFood)
                                            }
                                        />
                                    </>
                                )}
                            </Grid>

                            <Grid
                                item
                                xs={9}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <TextField
                                    id="name"
                                    label="Item Name"
                                    defaultValue={openFood.value.food.name}
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    onChange={(e) => {
                                        nameOnChange(e, openFood);
                                    }}
                                />
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <TextField
                                        id="price"
                                        label="Current Price"
                                        defaultValue={getPrice(
                                            openFood.value.food.price
                                        )}
                                        onChange={(e) => {
                                            priceOnChange(e, openFood);
                                        }}
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        margin="dense"
                                    />
                                    <TextField
                                        id="original_price"
                                        label="Was Price"
                                        defaultValue={getPrice(
                                            openFood.value.food.original_price
                                        )}
                                        onChange={(e) => {
                                            originalPriceOnChange(e, openFood);
                                        }}
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        margin="dense"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FoodCardPreview
                                                        foodItem={
                                                            openFood.value.food
                                                        }
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="desc"
                                label="Description"
                                defaultValue={openFood.value.food.desc}
                                onChange={(e) => {
                                    descOnChange(e, openFood);
                                }}
                                variant="outlined"
                                size="small"
                                margin="dense"
                                multiline
                                rows={2}
                                fullWidth
                            />
                        </Grid>

                        {/* <Grid item xs={12}>
                            {openFood.value &&
                                openFood.value.food &&
                                openFood.value.food.choices &&
                                openFood.value.food.choices.length > 0 && (
                                    <>
                                        <br />
                                        <b> Choices</b>
                                        <br />
                                        <div
                                            style={{ marginBottom: '10px' }}
                                        ></div>
                                        <TextField
                                            id="choiceLabel"
                                            label="Choice Label"
                                            defaultValue={
                                                openFood.value.food.choiceslabel
                                            }
                                            onChange={(e) => {
                                                choiceLabelOnChange(
                                                    e,
                                                    openFood
                                                );
                                            }}
                                            variant="outlined"
                                            InputProps={{
                                                className: styl.input,
                                            }}
                                        />
                                        <br />
                                        <div>
                                            {openFood.value.food.choices.map(
                                                (choice, i) => (
                                                    <>
                                                        <TextField
                                                            label="Choice Name"
                                                            value={choice.name}
                                                            onChange={(e) => {
                                                                choiceNameOnChange(
                                                                    e,
                                                                    openFood
                                                                        .value
                                                                        .food,
                                                                    openFood,
                                                                    i
                                                                );
                                                            }}
                                                            variant="outlined"
                                                            InputProps={{
                                                                className:
                                                                    styl.input,
                                                            }}
                                                        />
                                                        <TextField
                                                            label="Choice Price"
                                                            defaultValue={getPrice(
                                                                choice.price
                                                            )}
                                                            onChange={(e) => {
                                                                choicePriceOnChange(
                                                                    e,
                                                                    openFood
                                                                        .value
                                                                        .food,
                                                                    openFood,
                                                                    i
                                                                );
                                                            }}
                                                            variant="outlined"
                                                            type="number"
                                                            InputProps={{
                                                                className:
                                                                    styl.input,
                                                            }}
                                                        />
                                                        <DeleteIcon
                                                            style={{
                                                                topPadding:
                                                                    '120px',
                                                            }}
                                                            onClick={() => {
                                                                deleteChoice(
                                                                    choice.id,
                                                                    openFood
                                                                );
                                                            }}
                                                        />
                                                    </>
                                                )
                                            )}
                                        </div>{' '}
                                    </>
                                )}
                            {!isModifier && (
                                <AddButton
                                    onClick={() => {
                                        addChoices(openFood);
                                    }}
                                >
                                    {'Add Choice'}
                                </AddButton>
                            )}
                        </Grid> */}
                        <Divider style={{ width: '100%' }} />
                        <Grid item xs={12}>
                            <ChoicesAtFoodItem
                                openFood={openFood}
                                isModifier={isModifier}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {openFood.value &&
                                openFood.value.food &&
                                openFood.value.food.toppings &&
                                openFood.value.food.toppings.length > 0 && (
                                    <>
                                        <br />
                                        <b> Toppings</b>
                                        <br />
                                        <div
                                            style={{ marginBottom: '10px' }}
                                        ></div>
                                        <TextField
                                            id="toppingLabel"
                                            label="Toppings Label"
                                            defaultValue={
                                                openFood.value.food
                                                    .toppingslabel
                                            }
                                            onChange={(e) => {
                                                toppingsLabelOnChange(
                                                    e,
                                                    openFood
                                                );
                                            }}
                                            variant="outlined"
                                            InputProps={{
                                                className: styl.input,
                                            }}
                                        />

                                        <TextField
                                            label="Topping max Selection"
                                            defaultValue={
                                                openFood.value.food.toppingsmax
                                            }
                                            onChange={(e) => {
                                                toppingMaxOnChange(e, openFood);
                                            }}
                                            variant="outlined"
                                            type="number"
                                            InputProps={{
                                                className: styl.input,
                                            }}
                                        />

                                        <br />
                                        <div>
                                            {openFood.value.food.toppings.map(
                                                (topping, i) => (
                                                    <>
                                                        <TextField
                                                            label="Topping Name"
                                                            value={topping.name}
                                                            onChange={(e) => {
                                                                toppingsNameOnChange(
                                                                    e,
                                                                    openFood
                                                                        .value
                                                                        .food,
                                                                    openFood,
                                                                    i
                                                                );
                                                            }}
                                                            variant="outlined"
                                                            InputProps={{
                                                                className:
                                                                    styl.input,
                                                            }}
                                                        />
                                                        <TextField
                                                            label="Topping Price"
                                                            defaultValue={getPrice(
                                                                topping.price
                                                            )}
                                                            onChange={(e) => {
                                                                toppingsPriceOnChange(
                                                                    e,
                                                                    openFood
                                                                        .value
                                                                        .food,
                                                                    openFood,
                                                                    i
                                                                );
                                                            }}
                                                            type="number"
                                                            variant="outlined"
                                                            InputProps={{
                                                                className:
                                                                    styl.input,
                                                            }}
                                                        />
                                                        <DeleteIcon
                                                            style={{
                                                                topPadding:
                                                                    '120px',
                                                            }}
                                                            onClick={() => {
                                                                deleteToppings(
                                                                    topping.id,
                                                                    openFood
                                                                );
                                                            }}
                                                        />
                                                    </>
                                                )
                                            )}
                                        </div>
                                    </>
                                )}
                            {!isModifier && (
                                <AddButton
                                    style={{ marginTop: '20px' }}
                                    onClick={() => {
                                        addToppings(openFood);
                                    }}
                                >
                                    {'Add Toppings'}
                                </AddButton>
                            )}
                        </Grid>
                        <Divider style={{ width: '100%' }} />
                        <Grid item xs={12}>
                            {(isModifier || modifiersHook.value) && (
                                <ModifierGroupsForFoodItem
                                    modifierHook={modifiersHook}
                                    restauHook={restauHook}
                                    modEdit={modEdit}
                                    setModEdit={setModEdit}
                                    openFood={openFood}
                                />
                            )}
                        </Grid>
                        <Divider style={{ width: '100%' }} />
                        <Grid item xs={12}>
                            <PrintStationsAtFoodItem openFood={openFood} />
                        </Grid>
                        <Divider style={{ width: '100%' }} />
                        <Grid item xs={12}>
                            <AssignedOverridesAtFoodItem openFood={openFood} />
                        </Grid>
                        <Divider style={{ width: '100%' }} />
                        {openFood.value.food.id && (
                            <Grid item xs={12}>
                                <DeleteItem
                                    handleDelete={handleDeleteFood}
                                    setClose={setClose}
                                    itemType="Item"
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            cancel();
                        }}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    {loader.value.loading === true ? (
                        <div>
                            <img
                                height="75px"
                                className={classes.loadingImage}
                                src="../../img/spinner.gif"
                            />
                        </div>
                    ) : (
                        <Button
                            autoFocus
                            onClick={() => {
                                edit(menuHook, openFood.value.sectionId);
                            }}
                            variant="contained"
                        >
                            Save
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
}

export function FoodDialog(props) {
    const openFood = props.food;
    const [uploadError, setUploadError] = React.useState('');
    const [modEdit, setModEdit] = React.useState();
    const [hideFood, setHideFood] = useState(false);
    const [oosFood, setOosFood] = useState(false);
    //const [picture, setPicture] = useState(null);
    const [imgData, setImgData] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        //console.log('Open Food: ', props);
        setHideFood(openFood.value.food && openFood.value.food.hide);
        setOosFood(openFood.value.food && openFood.value.food.oos);
        if (openFood.value.food && openFood.value.food.img) {
            console.log(openFood.value.food.img);
            setImageUrl(openFood.value.food.img);
        }
    }, [openFood.value.display]);

    useEffect(() => {
        if (imgData) {
            setImageUrl(URL.createObjectURL(imgData));
        }
    }, [imgData]);

    const classes = useStyles();
    const styl = style();
    //console.log('Reload food dialog');

    if (!openFood.value.display || props.isClose) {
        return null;
    }
    return FoodDialogContainer(
        openFood,
        props.menu,
        props.restauHook,
        classes,
        props.loader,
        styl,
        uploadError,
        setUploadError,
        props.modifiersHook,
        props.setClose,
        props.refreshMenu,
        modEdit,
        setModEdit,
        hideFood,
        setHideFood,
        oosFood,
        setOosFood,
        imgData,
        setImgData,
        imageUrl,
        setImageUrl,
        props.deleteFoodItem
    );
}
