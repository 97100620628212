import { Chip, Link, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { useContext } from 'react';
import PhoneLogContext from '../PhoneLogContext';

function SearchResultFilters() {
    const { filters, setFilters, callStatusItems, resetFilters } =
        useContext(PhoneLogContext);

    const handleDeleteFilter = (keyToDelete) => {
        setFilters((previousFilters) => [
            ...previousFilters.filter((x) => x.key !== keyToDelete),
        ]);
    };

    const handleClearAllFilters = () => {
        setFilters((previousFilters) => []);
        resetFilters();
    };

    return (
        <Stack
            direction="row"
            spacing={3}
            sx={{ display: 'flex', alignItems: 'center', mt: 5, mb: 1 }}
        >
            <Typography variant="body1">
                Filters Applied ({Object.keys(filters).length}):
            </Typography>
            <Stack direction="row" spacing={1}>
                {filters.map((fil) => {
                    var valToDisplay = '';
                    if (fil.key === 'callStatus') {
                        valToDisplay = callStatusItems[fil.val];
                    } else {
                        valToDisplay = fil.val;
                    }
                    return (
                        <Chip
                            key={fil.key}
                            label={fil.key + ": '" + valToDisplay + "'"}
                            variant="outlined"
                            onDelete={() => handleDeleteFilter(fil.key)}
                        />
                    );
                })}
            </Stack>
            <Typography variant="body1">
                <Link underline="hover" onClick={handleClearAllFilters}>
                    Clear All
                </Link>
            </Typography>
        </Stack>
    );
}

SearchResultFilters.propTypes = {};

export default SearchResultFilters;
