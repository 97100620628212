import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import DrawerHeader from './DrawerHeader';

function AppContent(props) {
    const { children } = props;
    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />
            {children}
        </Box>
    );
}

AppContent.propTypes = {
    children: PropTypes.node,
};

AppContent.defaultProps = {
    children: null,
};

export default AppContent;
