import React from 'react';

import TextField from '@mui/material/TextField';
import PriceField from './PriceField';
import UploadButton from './UploadButton';
// import { useFoodItem } from './hooks/useFoodItem';

function FoodForm(props) {
    // const { name, desc, price, original_price } = props;
    // const foodItem = useFoodItem(props);
    const { name, desc, price, file, original_price, imgSrc, updateItem } =
        props;

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        console.log('file', selectedFile);
        console.log('name', selectedFile?.name);
        console.log('size', selectedFile?.size);
        console.log('type', selectedFile?.type);
        // setFile(selectedFile);
        updateItem({ file: selectedFile });
        // onFileChange(e, openFood);
    };

    const handleChange = (e) => {
        updateItem({ [e.target.id]: e.target.value });
    };

    const handlePriceChange = (e) => {
        const value = e.target.value
            ? Math.round(parseFloat(e.target.value) * 100)
            : 0.0;
        updateItem({ [e.target.id]: value });
    };

    console.log('price => ', price);
    console.log('original_price => ', original_price);

    return (
        <div>
            <div>
                name
                <TextField
                    id="name"
                    label="Name"
                    defaultValue={name}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                />
            </div>
            <div>
                description
                <TextField
                    id="desc"
                    label="Description"
                    defaultValue={desc}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    multiline
                    minRows={2}
                />
            </div>
            <div>
                actual price
                <br />
                <PriceField
                    id="price"
                    label="Actual Price"
                    value={price}
                    onChange={handlePriceChange}
                />
            </div>
            <div>
                higher striked price
                <br />
                <PriceField
                    id="original_price"
                    label="Higher Striked Price"
                    value={original_price || 0.0}
                    onChange={handlePriceChange}
                />
            </div>
            <div>
                menu image
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <TextField
                        disabled
                        value={file?.name}
                        label="Selected File"
                        id="selected_file"
                        size="small"
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <UploadButton
                        label="Browse..."
                        color="default"
                        onChange={handleFileChange}
                    />
                </div>
                {file && (
                    <div>
                        <span>Preview</span>
                        <div
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: 3,
                                padding: 5,
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            {imgSrc ? (
                                <img
                                    src={imgSrc}
                                    alt="food image"
                                    height="50px"
                                />
                            ) : null}
                        </div>
                    </div>
                )}
                {/* <div style={{ color: 'red' }}>{uploadError}</div> */}
            </div>
            <div>hide item</div>
            <div>modifier group</div>
        </div>
    );
}

FoodForm.propTypes = {};

export default FoodForm;
