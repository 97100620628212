import React from 'react';
import { Chip } from '@mui/material';

function FoodOOS() {
    return (
        <Chip
            color="error"
            label="Sold out"
            sx={{
                fontSize: '15px',
                height: '20px',
                '& .MuiChip-label': {
                    padding: '5px',
                },
            }}
        />
    );
}

FoodOOS.propTypes = {};

export default FoodOOS;
