import React, { useState, useEffect } from 'react';
import { getPriceWithToppings } from './FoodDialog';
import { getModifierPrice } from './ModifierGroup/ModifierUtil';
import formatPrice from '../../../../../utils/formatPrice';
import {
    Grid,
    Typography,
    TextField,
    IconButton,
    InputAdornment,
} from '@mui/material';
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft';
import SwitchRightIcon from '@mui/icons-material/SwitchRight';

const ItemDiscount = ({ discount, setDiscount, order, finalPrice }) => {
    const initialDiscount =
        discount.discount_type === 'Dollar'
            ? discount.amount_off / 100
            : discount.percent_off;
    // const [inputFinal, setInputFinal] = useState((finalPrice/100).toFixed(2));
    const [inputDiscount, setInputDiscount] = useState(initialDiscount);
    const [totalOriginalPrice, setTotalOriginalPrice] = useState(
        getPriceWithToppings(order) + getModifierPrice(order)
    );
    useEffect(() => {
        setTotalOriginalPrice(
            getPriceWithToppings(order) + getModifierPrice(order)
        );
    }, [order]);
    // useEffect(() => {
    //     if (discount.discount_type === 'Dollar') {
    //       setInputFinal((totalOriginalPrice/100 - inputDiscount).toFixed(2));
    //       setFinalPrice(totalOriginalPrice - inputDiscount * 100);
    //     } else {
    //       setInputFinal((totalOriginalPrice * (100-inputDiscount) / 10000).toFixed(2));
    //       setFinalPrice(Math.round(totalOriginalPrice * (100-inputDiscount) / 100));
    //     }
    //   }, [discount]);

    const updateDiscount = (value) => {
        setInputDiscount(value);
        let input = value;
        if (value === '') input = 0;

        if (value !== '') {
            input = Number(value);
            if (isNaN(input)) return;
        }

        if (discount.discount_type === 'Dollar') {
            setDiscount({
                discount_type: 'Dollar',
                amount: input * 100,
                amount_off: input * 100,
            });
        } else {
            setDiscount({
                discount_type: 'Percent',
                amount: Math.round((totalOriginalPrice * input) / 100),
                percent_off: input,
            });
        }
    };

    const toggleDiscountMethod = () => {
        if (discount.discount_type === 'Dollar') {
            setDiscount({
                discount_type: 'Percent',
                percent_off:
                    inputDiscount !== '' && !isNaN(Number(inputDiscount))
                        ? inputDiscount
                        : 0,
                amount:
                    inputDiscount !== '' && !isNaN(Number(inputDiscount))
                        ? Math.round((totalOriginalPrice * inputDiscount) / 100)
                        : 0,
            });
        } else {
            setDiscount({
                discount_type: 'Dollar',
                amount_off:
                    inputDiscount !== '' && !isNaN(Number(inputDiscount))
                        ? inputDiscount * 100
                        : 0,
                amount:
                    inputDiscount !== '' && !isNaN(Number(inputDiscount))
                        ? inputDiscount * 100
                        : 0,
            });
        }
    };

    // const updateFinal = (value) => {
    //     setInputFinal(value);
    //     let input = value;
    //     if (value === '') input = 0;

    //     if (value !== '') {
    //         input = Number(value);
    //         if (isNaN(input)) return;
    //     }

    //     setFinalPrice(input * 100);
    //     if (discount.discount_type === 'Dollar') {
    //         setDiscount({
    //             discount_type: 'Dollar',
    //             amount_off: totalOriginalPrice - input * 100,
    //             amount: totalOriginalPrice - input * 100
    //         });
    //         setInputDiscount((totalOriginalPrice / 100 - input).toFixed(2));
    //     } else {
    //         setDiscount({
    //             discount_type: 'Percent',
    //             percent_off: (totalOriginalPrice - input * 100) / totalOriginalPrice * 100,
    //             amount: totalOriginalPrice - input * 100
    //         });
    //         setInputDiscount((totalOriginalPrice - input * 100) / totalOriginalPrice * 100);
    //     }
    // }

    return (
        <Grid container spacing={2} sx={{ mt: '20px' }}>
            <Grid item>
                <Typography xs={12} sx={{ fontFamily: 'Roboto' }}>
                    {`Original Price: ${formatPrice(totalOriginalPrice / 100)}`}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    size="small"
                    label="Item Discount"
                    value={inputDiscount}
                    onChange={(e) => updateDiscount(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    size="small"
                                    sx={{ width: '50%' }}
                                    onClick={toggleDiscountMethod}
                                >
                                    {discount.discount_type === 'Dollar' ? (
                                        <SwitchLeftIcon sx={{ fontSize: 15 }} />
                                    ) : (
                                        <SwitchRightIcon
                                            sx={{ fontSize: 15 }}
                                        />
                                    )}
                                </IconButton>
                                <div style={{ width: 5 }}>
                                    {discount.discount_type === 'Dollar'
                                        ? '$'
                                        : '%'}
                                </div>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <Typography xs={12} sx={{ fontFamily: 'Roboto' }}>
                    {`Final Price: ${formatPrice(finalPrice / 100)}`}
                </Typography>
                {/* <TextField 
                label='Final Price'
                size='small'                
                sx={{ width: '45%' }} 
                value={inputFinal} 
                onChange={(e) => updateFinal(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            $
                        </InputAdornment>
                    )                           
                }}
            /> */}
            </Grid>
        </Grid>
    );
};

export default ItemDiscount;
