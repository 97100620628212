import React, { useEffect, useState } from 'react';
import DialogWithContent from '../../components/common/DialogWithContent/DialogWithContent';
import { CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import UpdatedFoodItems from './UpdatedFoodItems';
import DeletedFoodItems from './DeletedFoodItems';
import AddedFoodItems from './AddedFoodItems';
import axios from 'axios';
import Config from '../../config/Config';

function PreviewDialogNew(props) {
    const {
        setDialogOpen,
        dialogOpen,
        addedFoodItems,
        updatedFoodItems,
        deletedFoodItems,
        updatedMenuItemDataFromServer,
        pricesToUpdate,
        excelIssueList,
        restauId,
        refreshMenu,
        setSuccessMsg,
        setErrorMsg,
    } = props;

    const [processing, setProcessing] = useState(false);
    const [displayStatus, setDisplayStatus] = useState(false);
    const [isError, setIsError] = useState(false);
    const [disableSave, setDisableSave] = useState(false);
    const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

    const handleClose = () => {
        setDialogOpen(false);
        setDisplayStatus(false);
        refreshMenu();
    };

    // useEffect(() => {
    //     setLoading(true);
    // }, []);

    useEffect(() => {
        setDisplayStatus(false);
        if (excelIssueList.length > 0 || pricesToUpdate.length === 0) {
            setDisableSave(true);
        } else {
            setDisableSave(false);
        }
    }, [excelIssueList, pricesToUpdate]);

    //console.log('Close:', close, 'Open: ', open);
    async function updateMenuWithPrices() {
        //call the api to update sprices
        setProcessing(true);
        if (excelIssueList.length > 0) {
            //throw error;
            setIsError(false);
        }
        const token = await window.customerApp.auth().currentUser.getIdToken();
        let config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };
        let reqBody = {
            restauId: restauId,
            addedFoodItems: [...addedFoodItems],
            deletedFoodItems: [...deletedFoodItems],
            updatedFoodItems: [...updatedFoodItems],
            //updatedPrices: [...pricesToUpdate],
        };

        //debugger;
        let result = await axios
            .post(Config.updateMenuWithPrice, reqBody, config)
            .catch(function (error) {
                console.log('Failed to update MenuItem Prices.', error);
                setIsError(true);
            });
        //console.log('Response: ' + JSON.stringify(result.data));
        setProcessing(false);
        setDisplayStatus(true);
        setDisableSave(true);
        if (result.data.status !== 'SUCCESS') {
            setIsError(true);
            setErrorMsg('Error updating menu. ' + result.data.msg);
            window.confirm('Error updating menu. ' + result.data.msg);
        } else {
            setIsError(false);
            setSuccessMsg('Successfully updated menu.');
            window.confirm('Successfully updated menu.');
        }
    }

    return (
        <DialogWithContent
            open={dialogOpen}
            setOpen={setDialogOpen}
            dialogTitle="Menu Update - Preview"
            dialogMaxWidth="xl"
            handleSave={updateMenuWithPrices}
            handleClose={handleClose}
            dialogCloseBtnText={'Cancel Update'}
            dialogSaveBtnText={'Save to Database'}
        >
            {processing && <CircularProgress color="secondary" size={30} />}

            {displayStatus &&
                (isError ? (
                    <span
                        style={{
                            color: 'red',
                            fontWeight: 'normal',
                            fontSize: '0.85rem',
                        }}
                    >
                        Failed to update the Price details.
                    </span>
                ) : (
                    <span
                        style={{
                            color: 'green',
                            fontWeight: 'normal',
                            fontSize: '0.85rem',
                        }}
                    >
                        Succesfully updated price details.
                    </span>
                ))}
            <Typography variant="h4" sx={{ mb: 1 }}>
                Updated Food Items
            </Typography>
            <UpdatedFoodItems
                updatedFoodItems={updatedFoodItems}
                updatedMenuItemDataFromServer={updatedMenuItemDataFromServer}
            />
            <br />
            <br />
            <Typography variant="h4" sx={{ mb: 1 }}>
                Deleted Food Items
            </Typography>
            <DeletedFoodItems deletedFoodItems={deletedFoodItems} />
            <br />
            <br />
            <Typography variant="h4" sx={{ mb: 1 }}>
                Added Food Items
            </Typography>
            <AddedFoodItems addedFoodItems={addedFoodItems} />
        </DialogWithContent>
    );
}

PreviewDialogNew.propTypes = {
    setDialogOpen: PropTypes.func,
    dialogOpen: PropTypes.bool,
    addedFoodItems: PropTypes.arrayOf(PropTypes.shape({})),
    updatedFoodItems: PropTypes.arrayOf(PropTypes.shape({})),
    deletedFoodItems: PropTypes.arrayOf(PropTypes.shape({})),
    updatedMenuItemDataFromServer: PropTypes.shape({}),
    pricesToUpdate: PropTypes.arrayOf(PropTypes.shape({})),
    excelIssueList: PropTypes.arrayOf(PropTypes.shape({})),
    restauId: PropTypes.string,
    refreshMenu: PropTypes.func,
};

export default PreviewDialogNew;
