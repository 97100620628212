import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import GreetingMessageHeader from './GreetingMessageHeader';
import GreetingMessageDetails from './GreetingMessageDetails';
import PhoneSettingsContext from '../../../PhoneSettingsContext';
import { useStyles } from '../../../../../../home/useStyles';
import { RestauInfoContext } from '../../../../../../home/RestauInfoContext';
import { getUUID } from '../../../../../../Menu/Utils';
const moment = require('moment-timezone');

function ScheduledGreetingMessageDetails(props) {
    //const {} = props;
    const {
        getScheduledGreetMsgDetails,
        saveScheduledGreetMsgDetails,
        deleteScheduledGreetMsgDetails,
    } = useContext(PhoneSettingsContext);
    const { restauHook } = useContext(RestauInfoContext);
    const [allSchGreetMsgDetails, setAllSchGreetMsgDetails] = useState([]);
    const [pastGrtMsgDetails, setPastGrtMsgDetails] = useState([]);
    const [upcomingGrtMsgDetails, setUpcomingGrtMsgDetails] = useState([]);
    const [selectedGreetMsgDetails, setSelectedGreetMsgDetails] =
        useState(null);
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const classes = useStyles();
    // const [selectedMsgDetails, setSelectedMsgDetails] = useState(null);
    // const [origGreetMsgDetails, setOrigGreetMsgDetails] = useState(null);
    // const [origMsgDetails, setOrigMsgDetails] = useState(null);
    // const [selectedPlayFileDetails, setSelectedplayFileDetails] =
    //     useState(null);

    const loadData = async () => {
        //debugger;
        setLoading(true);
        try {
            let response = await getScheduledGreetMsgDetails();
            if (response.status === 200 && response.data.status) {
                setAllSchGreetMsgDetails(response.data.data);
                setSelectedGreetMsgDetails(null);
                setErrMsg('');
            } else {
                setErrMsg(`Failed to fetch data`);
            }
        } catch (ex) {
            setErrMsg(`Error: ${ex.response.data}`);
        } finally {
            setLoading(false);
        }
    };

    const showGreetMsgDetails = (msgId) => {
        //debugger;
        let data = allSchGreetMsgDetails.filter((x) => x.id === msgId)[0];
        setSelectedGreetMsgDetails(JSON.parse(JSON.stringify(data)));
        // setSelectedMsgDetails(data.msgDetails);
        // setOrigGreetMsgDetails(data);
        // setOrigMsgDetails(data.msgDetails);
    };

    const handleNewGreetMessage = () => {
        // let todayDate = new Date();
        // let tomorrowDate = new Date();
        //tomorrowDate.setDate(todayDate.getDate() + 1);
        //debugger;
        let newMsgDetails = {
            id: getUUID(), //'new'
            displayName: '',
            // from_date:
            //     moment()
            //         //.tz(restauHook.restauTimeZone)
            //         .format('YYYY-MM-DD') + 'T07:00:01Z',
            // from_date:
            //     moment().tz(restauHook.restauTimeZone).format('YYYY-MM-DD') +
            //     'T00:00:01',
            from_date: moment()
                .add(1, 'days')
                .tz(restauHook.restauTimeZone)
                .startOf('day')
                .add(1, 'second')
                .format('YYYY-MM-DDTHH:mm:ssZ'),
            // to_date:
            //     moment()
            //         .add(1, 'days')
            //         //.tz(restauHook.restauTimeZone)
            //         .format('YYYY-MM-DD') + 'T07:00:01Z',
            // to_date:
            //     moment()
            //         .add(1, 'days')
            //         .tz(restauHook.restauTimeZone)
            //         .format('YYYY-MM-DD') + 'T00:00:01',
            to_date: moment()
                .add(2, 'days')
                .tz(restauHook.restauTimeZone)
                .startOf('day')
                .add(1, 'second')
                .format('YYYY-MM-DDTHH:mm:ssZ'),
            //.format('MMM DD YYYY'),

            msgDetails: [
                {
                    name: 'gather_input',
                    sayMessage: '',
                    sayMessageLanguage: 'en-US',
                    type: 'say',
                    voice: 'Polly.Joanna',
                },
            ],
        };

        setSelectedGreetMsgDetails(newMsgDetails);
    };

    const handleSaveGreetMessage = async (greetMsgDetails) => {
        let updateMsgNow = false;
        let revertMsgNow = false;
        // try {
        if (selectedGreetMsgDetails) {
            //check if the name is not duplicate
            //only for the new case (check that id is not same as current one but name is same)
            if (
                allSchGreetMsgDetails.filter(
                    (x) =>
                        x.id !== greetMsgDetails.id &&
                        x.displayName === greetMsgDetails.displayName
                ).length >= 1
            ) {
                throw new Error('Error: Name already exists.');
            }

            //check if the date not duplicate
            //only for the new case (check that id is not same as current one but date is same)
            if (
                allSchGreetMsgDetails.filter(
                    (x) =>
                        x.id !== greetMsgDetails.id &&
                        moment(x.from_date).format('YYYY-MM-DD') ===
                            moment(greetMsgDetails.from_date).format(
                                'YYYY-MM-DD'
                            )
                ).length >= 1
            ) {
                throw new Error('Error: Message for this date already exists.');
            }

            if (
                (greetMsgDetails.type === 'say' &&
                    !greetMsgDetails.sayMessage) ||
                (greetMsgDetails.type === 'play' &&
                    !greetMsgDetails.playFilePath)
            ) {
                throw new Error(`Error: Greeting Message can't be empty.`);
            }

            //debugger;
            if (
                greetMsgDetails.from_date.startsWith(
                    moment().tz(restauHook.restauTimeZone).format('YYYY-MM-DD')
                )
            ) {
                if (
                    !window.confirm(
                        'This update will take effect now. \n Are you sure to proceed?'
                    )
                ) {
                    return;
                }
                updateMsgNow = true;
            }

            if (
                selectedGreetMsgDetails.from_date.startsWith(
                    moment().tz(restauHook.restauTimeZone).format('YYYY-MM-DD')
                ) &&
                moment(greetMsgDetails.from_date)
                    .tz(restauHook.restauTimeZone)
                    .format('YYYY-MM-DD') !==
                    moment(selectedGreetMsgDetails.from_date)
                        .tz(restauHook.restauTimeZone)
                        .format('YYYY-MM-DD')
            ) {
                if (
                    !window.confirm(
                        'This update will take effect now. The existing message will be reverted. \n Are you sure to proceed?'
                    )
                ) {
                    return;
                }
                revertMsgNow = true;
            }

            //setLoading(true);
            const data = await saveScheduledGreetMsgDetails(
                greetMsgDetails.id,
                greetMsgDetails,
                updateMsgNow,
                revertMsgNow
            );
            if (data.status) {
                loadData();
                //set error message
                setErrMsg('');
            } else {
                throw new Error('Error: Failed to save data.');
            }
        }
        // } catch (ex) {
        //     console.error(ex.toString());
        //     //setErrMsg(`Error: ${ex.toString()}`);
        // } finally {
        //     //setLoading(false);
        // }
    };

    const handleDeleteGreetMessage = async (greetMsgDetails) => {
        //debugger;
        let revertMsgNow = false;

        if (
            greetMsgDetails.from_date.startsWith(
                moment().tz(restauHook.restauTimeZone).format('YYYY-MM-DD')
            )
        ) {
            if (
                !window.confirm(
                    'This message will be deleted and reverted to old Greeting Message right now. \n Are you sure to proceed?'
                )
            ) {
                return;
            }
            revertMsgNow = true;
        } else {
            if (
                !window.confirm(
                    'Are you sure you want to delete the Scheduled Greeting Message?'
                )
            ) {
                return;
            }
        }

        //setLoading(true);
        const data = await deleteScheduledGreetMsgDetails(
            greetMsgDetails.id,
            revertMsgNow
        );
        if (data.status && data.data.status) {
            loadData();
            //set error message
            setErrMsg('');
        } else {
            throw new Error('Error: Failed to save data.');
        }
    };

    useEffect(() => {
        if (restauHook.restauId) loadData();
    }, [restauHook.restauId]);

    useEffect(() => {
        //debugger;
        setPastGrtMsgDetails([
            ...allSchGreetMsgDetails
                .filter((x) => moment(x.from_date).isBefore(moment(), 'day'))
                .sort((x, y) => new Date(x.from_date) - new Date(y.from_date)),
        ]);

        setUpcomingGrtMsgDetails([
            ...allSchGreetMsgDetails
                .filter((x) =>
                    moment(x.from_date).isSameOrAfter(moment(), 'day')
                )
                .sort((x, y) => new Date(x.from_date) - new Date(y.from_date)),
        ]);
    }, [allSchGreetMsgDetails]);

    return loading ? (
        <img
            className={classes.loadingImage}
            src="../../../img/spinner.gif"
            alt="loading"
        />
    ) : errMsg !== '' ? (
        <Typography color="red" variant="h6">
            {errMsg}
        </Typography>
    ) : (
        <Grid
            container
            columnSpacing={2}
            //direction="column"
            justifyContent="space-between"
            style={{ height: '100%' }}
        >
            <Grid
                item
                xs={3}
                sx={{
                    height: '100%',
                }}
            >
                <Typography variant="h6" sx={{ mb: 1 }}>
                    Existing Messages:
                </Typography>
                <Box
                    sx={{
                        flexGrow: 1,
                        maxHeight: '95%',
                        overflowY: 'auto',
                        px: 1,
                    }}
                >
                    <Grid
                        container
                        //justifyContent="flex-start"
                        //alignItems={'center'}
                        rowSpacing={1}
                        direction="column"
                        sx={{
                            pr: 1,
                            pb: 1,
                        }}
                    >
                        <Grid item xs={12} textAlign="center">
                            <Button
                                variant="contained"
                                fullWidth
                                size="small"
                                onClick={handleNewGreetMessage}
                            >
                                Add New
                            </Button>
                        </Grid>
                        <Grid item xs={12} alignItems={'center'}>
                            <Typography variant="h6" textAlign={'center'}>
                                Upcoming:
                            </Typography>
                        </Grid>
                        {upcomingGrtMsgDetails &&
                            upcomingGrtMsgDetails.map((x) => (
                                <GreetingMessageHeader
                                    key={'upcoming_' + x.id}
                                    grtMsgDetails={x}
                                    showSpecialMsgDetails={showGreetMsgDetails}
                                    pastData={false}
                                />
                            ))}
                        <Grid item xs={12} sx={{ mt: 3 }}>
                            <Typography variant="h6" textAlign={'center'}>
                                Past:
                            </Typography>
                        </Grid>
                        {pastGrtMsgDetails &&
                            pastGrtMsgDetails.map((x) => (
                                <GreetingMessageHeader
                                    key={'past_' + x.id}
                                    grtMsgDetails={x}
                                    showSpecialMsgDetails={showGreetMsgDetails}
                                    pastData={true}
                                />
                            ))}
                    </Grid>
                </Box>
            </Grid>

            {selectedGreetMsgDetails && (
                <GreetingMessageDetails
                    selectedGreetMsgDetails={selectedGreetMsgDetails}
                    setSelectedGreetMsgDetails={setSelectedGreetMsgDetails}
                    handleSaveGreetMessage={handleSaveGreetMessage}
                    handleDeleteGreetMessage={handleDeleteGreetMessage}
                />
            )}
        </Grid>
    );
}

ScheduledGreetingMessageDetails.propTypes = {};

export default ScheduledGreetingMessageDetails;
