import React from 'react';
import PropTypes from 'prop-types';
import { Button, TableCell, TableRow, Typography } from '@mui/material';
import { CustomWidthTooltip } from '../../../StationMapping/CustomWidthTooltip';
/* import MoreHorizIcon from '@mui/icons-material/MoreHoriz'; */
import { useContext } from 'react';
import VisOverrideContext from '../../VisOverrideContext';

function ExistingOverrideRow(props) {
    const { overrideRowData } = props;
    const { setOpenEditOverrideDialog, setOpenVisOverrideData } =
        useContext(VisOverrideContext);

    //console.log('Row for Override: ', overrideRowData);

    const showEditOverrideDialog = () => {
        setOpenEditOverrideDialog(true);
        setOpenVisOverrideData(overrideRowData);
    };

    return (
        overrideRowData && (
            <>
                <TableRow
                    key={`${overrideRowData.id}_row`}
                    sx={{
                        '&:last-child td, &:last-child th': {
                            border: 0,
                        },
                    }}
                >
                    <TableCell>{overrideRowData.name}</TableCell>
                    <TableCell component="th" scope="station" align="center">
                        <CustomWidthTooltip
                            placement="bottom"
                            title={
                                overrideRowData.mappedTo &&
                                overrideRowData.mappedTo.join(' | ')
                            }
                        >
                            <Typography
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '2',
                                    WebkitBoxOrient: 'vertical',
                                }}
                            >
                                {overrideRowData.mappedTo &&
                                    overrideRowData.mappedTo.join(' | ')}
                            </Typography>
                        </CustomWidthTooltip>
                    </TableCell>

                    <TableCell align="right">
                        {/*    <MoreHorizIcon
                            sx={{ fontSize: 24 }}
                            disabled
                            //onClick={handleStationMenuClick}
                        /> */}
                        <Button
                            variant="outlined"
                            onClick={showEditOverrideDialog}
                        >
                            Edit
                        </Button>
                    </TableCell>
                </TableRow>
            </>
        )
    );
}

ExistingOverrideRow.propTypes = {
    overrideRowData: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        rules: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                type: PropTypes.string,
                status: PropTypes.string,
                days: PropTypes.shape({
                    day: PropTypes.string,
                    time: PropTypes.shape({}),
                }),
            })
        ),
        mappedTo: PropTypes.array,
    }),
};

export default ExistingOverrideRow;
