import React from 'react';
import ModifierEdit from './ModifierEdit';
import { ModifierDiv } from './ModifierEditStyle';

export function ModifierEditGroup({ modifierHook }) {
    const currentModifier = modifierHook.getCurrentAddEditModifier();
    console.log('current modifier');
    console.log(currentModifier);

    return (
        <ModifierDiv>
            {currentModifier && <ModifierEdit modifierHook={modifierHook} />}
        </ModifierDiv>
    );
}
