import React from 'react';
import {
    ModifierDiv,
    LabelContainer,
    ModifierGrid,
    CheckboxLabel,
    ModifierCheckbox,
} from './ModifierStyle';
import formatPrice from '../../../../../../utils/formatPrice';
import { getQuatity } from './ModifierUtil';
import { Typography } from '@mui/material';

export default function ModifierCheckBox({ modifier, onChange }) {
    function isDisabled(modifier, isChecked) {
        let max = modifier.max;
        let count = 0;
        for (let option of modifier.options) {
            if (option.checked) {
                count++;
            }
        }
        if (max && max != 0 && count >= max && !isChecked) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <ModifierDiv>
            {modifier.label ? (
                <LabelContainer style={{ paddingBottom: '3px' }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {modifier.label}
                    </Typography>{' '}
                </LabelContainer>
            ) : (
                <LabelContainer style={{ paddingBottom: '3px' }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        Options
                    </Typography>{' '}
                </LabelContainer>
            )}
            <ModifierGrid>
                {modifier.options &&
                    modifier.options.map(
                        (option, i) =>
                            !option.hide && (
                                <CheckboxLabel
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: '5px',
                                    }}
                                >
                                    <ModifierCheckbox
                                        type="checkbox"
                                        checked={
                                            option.checked
                                                ? option.checked
                                                : false
                                        }
                                        id={option.id}
                                        disabled={
                                            isDisabled(
                                                modifier,
                                                option.checked
                                            ) || option.oos
                                        }
                                        name={modifier.id}
                                        onClick={(e) => {
                                            onChange(modifier.id, option.id);
                                        }}
                                    />
                                    <span>
                                        {option.label}
                                        {option.price
                                            ? ' (+' +
                                              formatPrice(
                                                  (option.price *
                                                      getQuatity(
                                                          option.quantity
                                                      )) /
                                                      100
                                              ) +
                                              ')'
                                            : ''}
                                    </span>
                                    {option.oos ? (
                                        <span
                                            style={{
                                                backgroundColor: '#e74c3c',
                                                color: '#FFFFFF',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            <b>Sold Out</b>
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </CheckboxLabel>
                            )
                    )}
            </ModifierGrid>
        </ModifierDiv>
    );
}
