import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { DashboardContext } from '../../../home/DashboardContext';
import { RestauInfoContext } from '../../../home/RestauInfoContext';

function usePhoneReports() {
    const { restauHook } = useContext(RestauInfoContext);
    const { callSummaryHook } = useContext(DashboardContext);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const loadData = () => {
        //debugger;
        callSummaryHook.loadData(startDate, endDate);
    };

    useEffect(() => {
        //debugger;
        console.log('StartDate', startDate);
        console.log('EndDate', endDate);
        loadData();
    }, [restauHook.restauId]);
    /*  
    useEffect(() => {
        debugger;
        console.log('StartDate', startDate);
        console.log('EndDate', endDate);
    }, []); */

    return {
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        loadData,
    };
}

export default usePhoneReports;
