import { useReducer, useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import CateringContext from '../../../../CateringContext';

const useOrder = () => {
    const { RestauId, CateringInquiryId } = useParams();
    const orderReducer = (state, action) => {
        let newOrders;
        switch (action.type) {
            case 'SET_INITIAL':
                return action.payload;

            case 'UPDATE_PAYMENT':
                newOrders = { ...state, ...action.value };
                return newOrders;

            case 'UPDATE_INSTRUCTIONS':
                newOrders = { ...state, ...action.value };
                return newOrders;

            case 'UPDATE_ALL':
                return action.newOrders;

            default:
                throw new Error();
        }
    };
    const { updateEvent, form, eventOrder } = useContext(CateringContext);
    const [orders, dispatchOrder] = useReducer(orderReducer, eventOrder);
    useEffect(() => {
        dispatchOrder({ type: 'SET_INITIAL', payload: eventOrder });
    }, [eventOrder]);
    const [openFood, setOpenFood] = useState(null);
    const [menuLoading, setMenuLoading] = useState(false);

    const updateMenuSelection = async (orders) => {
        try {
            setMenuLoading(true);
            const response = await updateEvent(
                RestauId,
                CateringInquiryId,
                form.eventId,
                { orders: orders }
            );
        } catch (error) {
            console.log('Error updating menu selection: ', error);
            setSnackbarMessage(
                'Issue updating menu selection. Please contact support if the issue persists.'
            );
            setSnackbarOpen(true);
            throw new Error('Error updating menu selection: ' + error);
        } finally {
            setMenuLoading(false);
        }
    };

    return {
        orders,
        dispatchOrder,
        openFood,
        setOpenFood,
        menuLoading,
        setMenuLoading,
        updateMenuSelection,
    };
};

export default useOrder;
