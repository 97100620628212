import React from 'react';
import { useMenu } from '../Hooks/useMenu';
// Creating the context object and passing the default values.
export const MenuInfoContext = React.createContext(null);

export function MenuProvider({ children }) {
    const menuHook = useMenu();

    return (
        <MenuInfoContext.Provider value={menuHook}>
            {children}
        </MenuInfoContext.Provider>
    );
}
