import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const linkStyle = {
    textDecoration: 'none',
    color: 'inherit',
};

function NavItem(props) {
    const { path, icon: Icon, text, open, onClick, children, level } = props;
    return (
        <>
            <Link key={path} to={path} style={linkStyle}>
                <ListItem
                    dense
                    button
                    onClick={onClick}
                    sx={{
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        ml: (level - 1) * 3,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <Icon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primary={text}
                        sx={{ opacity: open ? 1 : 0 }}
                    />
                </ListItem>
            </Link>
            {children}
        </>
    );
}

NavItem.propTypes = {
    path: PropTypes.string,
    icon: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    open: PropTypes.bool,
    children: PropTypes.node,
    level: PropTypes.number,
};

NavItem.defaultProps = {
    onClick: undefined,
    path: '',
    open: false,
    level: 1,
};

export default NavItem;
