import React from 'react';
import SearchCriteria from './SearchCriteria';
import SearchResults from './SearchResults';

function PhoneCallerDetailsContent() {
    return (
        <div style={{ width: 'maxContent' }}>
            <SearchCriteria />
            <SearchResults />
        </div>
    );
}

PhoneCallerDetailsContent.propTypes = {};

export default PhoneCallerDetailsContent;
