import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router';
import AppContent from './AppContent';
import AppDrawer from './AppDrawer';
import AppLogo from './AppLogo';
import AppTitleBar from './AppTitleBar';
import AppTitleBlock from './AppTitleBlock';
import NavBar from './NavBar';

export default function Layout(props) {
    const theme = useTheme();
    const { drawerWidth, drawerRadius, title, restaurant, onLogout, children } =
        props;
    const [open, setOpen] = useState(true);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        noSsr: true,
    });
    const location = useLocation();

    useEffect(() => {
        if (isMobile) {
            setOpen(false);
        }
    }, [location]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <AppTitleBar
                drawerWidth={drawerWidth}
                open={open}
                onDrawerOpen={handleDrawerOpen}
            >
                <AppTitleBlock title={title} restaurant={restaurant} />
            </AppTitleBar>

            <AppDrawer
                drawerRadius={drawerRadius}
                drawerWidth={drawerWidth}
                open={open}
            >
                <AppLogo onDrawerClose={handleDrawerClose} />
                <Divider />
                <NavBar
                    open={open}
                    onLogout={onLogout}
                    strideQOnlineOrdering={restaurant.strideQOnlineOrdering}
                />
            </AppDrawer>

            <AppContent>{children}</AppContent>
        </Box>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    drawerRadius: PropTypes.number,
    drawerWidth: PropTypes.number,
    restaurant: PropTypes.shape({ strideQOnlineOrdering: PropTypes.bool })
        .isRequired,
    title: PropTypes.string,
    onLogout: PropTypes.func.isRequired,
};

Layout.defaultProps = {
    drawerWidth: 240,
    drawerRadius: 20,
    title: 'Welcome to Admin Portal',
};
