import { useState } from 'react';
import moment from 'moment-timezone';

export function useSummary() {
    const [value, setValue] = useState({});
    const [restauId, setRestauId] = useState();
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [review, setReview] = useState();
    const [dineInReport, setDineInReport] = useState();
    return {
        value,
        setValue,
        restauId,
        setRestauId,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        review,
        setReview,
        dineInReport,
        setDineInReport,
    };
}
