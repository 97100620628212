import React, { useContext, useEffect, useState } from 'react';
import { Chip, Grid, IconButton, Stack, Typography } from '@mui/material';
import SayPlayMessageDetails from '../SayPlayMessage/SayPlayMessageDetails';
import PropTypes from 'prop-types';
import MoreTimeTwoToneIcon from '@mui/icons-material/MoreTimeTwoTone';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DialogWithContent from '../../../../../components/common/DialogWithContent/DialogWithContent';
import ScheduledGreetingMessageDetails from './ScheduledGreetings/ScheduledGreetingMessageDetails';
import { RestauInfoContext } from '../../../../../home/RestauInfoContext';
const moment = require('moment-timezone');

function CallDefGreetingMsgDetails(props) {
    const {
        greetingMsgDetails,
        setGreetingMsgDetails,
        greetMsgPlayFile,
        setGreetMsgPlayFile,
        originalGreetingMsgDetails,
        revertGreetMsgDetails,
        refreshData,
    } = props;

    const { restauHook } = useContext(RestauInfoContext);
    const [showSchGreetMsgDetails, setShowSchGreetMsgDetails] = useState(false);
    const [showRevertMsgDetails, setShowRevertMsgDetails] = useState(false);
    const [revertMsgDetails, setRevertMsgDetails] = useState(null);
    const [revertMsgLabel, setRevertMsgLabel] = useState('');

    useEffect(() => {
        if (revertGreetMsgDetails) {
            setRevertMsgDetails(revertGreetMsgDetails.revertMsgDetails);
            setRevertMsgLabel(
                `Greeting Message '${
                    revertGreetMsgDetails.displayName
                }' is LIVE, will be reverted on '${
                    revertGreetMsgDetails.to_date &&
                    moment(revertGreetMsgDetails.to_date._seconds * 1000)
                        //.tz('America/Los_Angeles')
                        .tz(restauHook.restauTimeZone)
                        .format('YYYY-MM-DD')
                }'. See details`
            );
        }
    }, []);

    const handleSchGreetMsgDialogClose = () => {
        refreshData();
    };

    return (
        <>
            {/* Dialog to show all the Scheduled Greeting Messages */}
            <DialogWithContent
                open={showSchGreetMsgDetails}
                dialogTitle="Scheduled Greeting Messages"
                setOpen={setShowSchGreetMsgDetails}
                dialogMaxWidth="lg"
                contentSyles={{ height: '60vh', overflowY: 'auto' }}
                showSaveButton={false}
                dialogCloseBtnText="Close"
                handleClose={handleSchGreetMsgDialogClose}
            >
                <ScheduledGreetingMessageDetails />
            </DialogWithContent>
            {/* Dialog to show reverted message details */}
            <DialogWithContent
                open={showRevertMsgDetails}
                dialogTitle="Reverted Greeting Message"
                setOpen={setShowRevertMsgDetails}
                dialogMaxWidth="md"
                showSaveButton={false}
                dialogCloseBtnText="Close"
                //handleClose={cancel}
            >
                <SayPlayMessageDetails
                    msgDetails={revertMsgDetails}
                    setMsgDetails={setRevertMsgDetails}
                    originalMsgDetails={revertMsgDetails}
                    readOnly={true}
                />
            </DialogWithContent>

            <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography variant="h6">Greeting Message</Typography>
                        <IconButton
                            onClick={() => setShowSchGreetMsgDetails(true)}
                        >
                            <MoreTimeTwoToneIcon
                                color="primary"
                                sx={{ fontSize: 36 }}
                            />
                        </IconButton>
                        {revertMsgDetails && (
                            <Chip
                                icon={<InfoOutlinedIcon />}
                                clickable
                                color="warning" //"info"
                                onClick={() => setShowRevertMsgDetails(true)}
                                // component={Link}
                                // to={'/phone/audioLibrary'}
                                label={revertMsgLabel}
                            ></Chip>
                        )}
                    </Stack>
                    <Typography variant="body1">
                        Greeting message and phone menu which intelligently
                        route phone calls to online actions
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <SayPlayMessageDetails
                        msgDetails={greetingMsgDetails}
                        setMsgDetails={setGreetingMsgDetails}
                        uploadedPlayFile={greetMsgPlayFile}
                        setUploadedPlayFile={setGreetMsgPlayFile}
                        originalMsgDetails={originalGreetingMsgDetails}
                    />
                </Grid>
            </Grid>
        </>
    );
}

CallDefGreetingMsgDetails.propTypes = {
    greetingMsgDetails: PropTypes.shape({}),
    setGreetingMsgDetails: PropTypes.func,
    greetMsgPlayFile: PropTypes.shape({}),
    setGreetMsgPlayFile: PropTypes.func,
    originalGreetingMsgDetails: PropTypes.array,
    revertGreetMsgDetails: PropTypes.shape({
        displayName: PropTypes.string,
        to_date: PropTypes.string,
        revertMsgDetails: PropTypes.arrayOf(PropTypes.shape({})),
    }),
};

export default CallDefGreetingMsgDetails;
