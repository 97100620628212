import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export default function OrderItemAndModifiers(props) {
    let orderItemToDisplay = props.orderItem;
    let [displayModifiers, setDisplayModifiers] = useState(false);

    let selectedModifiers =
        orderItemToDisplay.modifierGroup &&
        orderItemToDisplay.modifierGroup.modifiers.length > 0
            ? orderItemToDisplay.modifierGroup.modifiers.flatMap((modifier) => {
                  //console.log("Modifier",modifier.label)
                  return (
                      modifier.options &&
                      modifier.options.filter((option) => option.checked)
                  );
              })
            : null;
    //console.log(orderItemToDisplay.name)
    //console.table(selectedModifiers);

    console.log(getModifiersListToDisplay());

    function getModifiersListToDisplay() {
        return (
            selectedModifiers && (
                <div style={{ paddingLeft: '10px' }}>
                    {selectedModifiers.map((option) => (
                        <span key={option.id} style={{ display: 'block' }}>
                            (+) {option.label}
                        </span>
                    ))}
                </div>
            )
        );
    }

    return (
        <>
            <span
                style={{
                    fontWeight: 'bold',
                    alignItems: 'center',
                    display: 'inline-flex',
                }}
            >
                {selectedModifiers ? (
                    <span
                        onClick={() => setDisplayModifiers(!displayModifiers)}
                    >
                        {displayModifiers ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )}
                    </span>
                ) : (
                    <span style={{ width: '20px' }}></span>
                )}
                {orderItemToDisplay.name}
            </span>
            {displayModifiers && getModifiersListToDisplay()}
        </>
    );
}
