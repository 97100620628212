import { Button, Divider, Grid, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import SayPlayMessageDetails from './SayPlayMessage/SayPlayMessageDetails';
import PhoneSettingsContext from '../PhoneSettingsContext';
import Utils from '../../../../utils/Utils';
import CircularProgress from '@mui/material/CircularProgress';
import TextMessageDetails from './TextMessageDetails';
import { DefaultWidgetValues } from '../PhoneSettingsConstants';
const { getPhoneNumberInE164Format } = { ...Utils };

function BanquetHallDetails() {
    const {
        banquetNotificationPhNumber,
        setBanquetNotificationPhNumber,
        txtMsgForBanquet,
        setTxtMsgForBanquet,
        banquetMsgDetails,
        setBanquetMsgDetails,
        updatePhoneSettings,
        uploadAndUpdatePlayMessage,
        allowUpdateTxtMessage,
        restauName,
        restauId,
    } = useContext(PhoneSettingsContext);

    const [isProcessing, setIsProcessing] = useState(false);
    const [uploadedPlayFile, setUploadedPlayFile] = useState({});
    const [errMsg, setErrMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [
        originalBanquetNotificationPhNumber,
        setOriginalBanquetNotificationPhNumber,
    ] = useState('');
    const [originalBanquetMsgDetails, setOriginalBanquetMsgDetails] = useState(
        []
    );
    const [originalTxtMsgForBanquet, setOriginalTxtMsgForBanquet] =
        useState('');

    function validatePhoneNumber(phNumber) {
        let formattedPhNumber = getPhoneNumberInE164Format(phNumber);
        //console.log('Formatted to:', formattedPhNumber);
        if (formattedPhNumber.length !== 12) {
            return false;
        }
        setBanquetNotificationPhNumber(formattedPhNumber);
        return true;
    }

    const updateBanquetHallDetails = async () => {
        //debugger;

        let isNotificationPhNoValid = true;
        if (!validatePhoneNumber(banquetNotificationPhNumber)) {
            isNotificationPhNoValid = false;
            setErrMsg('Notification Phone Number is not Valid.');
            return;
        }
        let isMsgDetailsValid = true;
        if (
            banquetMsgDetails.length === 0 ||
            banquetMsgDetails[0].type === undefined
        ) {
            isMsgDetailsValid = false;
            setErrMsg('Message Details are not Valid.');
            return;
        }
        let isCustomerTxtValid = true;
        if (txtMsgForBanquet === '') {
            isCustomerTxtValid = false;
            setErrMsg('Text Message to customer cannot be blank.');
            return;
        }
        if (
            isNotificationPhNoValid &&
            isMsgDetailsValid &&
            isCustomerTxtValid
        ) {
            setIsProcessing(true);
            setErrMsg('');
            let temp;
            if (banquetMsgDetails[0].type === 'play') {
                //Fix for uploading play file.
                //check if the play file changed.
                // if (
                //     originalBanquetMsgDetails[0].playFilePath !==
                //     banquetMsgDetails[0].playFilePath
                // ) {
                //     temp = await uploadAndUpdatePlayMessage(
                //         banquetMsgDetails,
                //         uploadedPlayFile,
                //         setErrMsg,
                //         setSuccessMsg,
                //         originalBanquetMsgDetails
                //     );
                //     if (!temp) {
                //         //debugger;
                //         setIsProcessing(false);
                //         return;
                //     }
                // } else {
                temp = JSON.parse(JSON.stringify(banquetMsgDetails));
                //}
            } else {
                temp = JSON.parse(JSON.stringify(banquetMsgDetails));
                delete temp[0].playFilePath;
            }

            let body = {
                banquetHall: {
                    message: temp,
                    notificationNo: banquetNotificationPhNumber,
                    sendText: txtMsgForBanquet,
                },
            };
            //setIsProcessing(true);
            updatePhoneSettings(
                body,
                setErrMsg,
                setSuccessMsg,
                setIsProcessing
            );
        }
    };

    const resetData = () => {
        setSuccessMsg('');
        setErrMsg('');
        setBanquetNotificationPhNumber(originalBanquetNotificationPhNumber);
        setBanquetMsgDetails(
            JSON.parse(JSON.stringify(originalBanquetMsgDetails))
        );
        setTxtMsgForBanquet(originalTxtMsgForBanquet);
    };

    useEffect(() => {
        setErrMsg('');
        setSuccessMsg('');
        setOriginalBanquetNotificationPhNumber(banquetNotificationPhNumber);
        setOriginalBanquetMsgDetails(
            JSON.parse(JSON.stringify(banquetMsgDetails))
        );
        setOriginalTxtMsgForBanquet(txtMsgForBanquet);
    }, []);

    return (
        <Grid container rowSpacing={3} xs={12} md={9}>
            <Grid item xs={12}>
                <Typography variant="h6">Message to customer</Typography>
            </Grid>
            <Grid item xs={12}>
                <SayPlayMessageDetails
                    msgDetails={banquetMsgDetails}
                    setMsgDetails={setBanquetMsgDetails}
                    uploadedPlayFile={uploadedPlayFile}
                    setUploadedPlayFile={setUploadedPlayFile}
                    originalMsgDetails={originalBanquetMsgDetails}
                />
            </Grid>
            <Divider style={{ width: '100%' }} />
            <Grid item xs={12}>
                {/* {allowUpdateTxtMessage && ( */}
                <TextMessageDetails
                    txtMessage={txtMsgForBanquet}
                    setTxtMessage={setTxtMsgForBanquet}
                    id="banquet-msg-text"
                    messageHeading="Text Message to Customer"
                    toolTipText={DefaultWidgetValues(
                        'send_message_banquet_hall',
                        restauName,
                        restauId
                    )}
                    size="large"
                    //disabled={!allowUpdateTxtMessage}
                />
                {/* )} */}
            </Grid>
            <Divider style={{ width: '100%' }} />
            <Grid item xs={12}>
                <Typography variant="h6">Notification to Owner</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="banquetNotificationNumber"
                    label="Notification Number"
                    value={banquetNotificationPhNumber}
                    size="md"
                    onChange={(e) =>
                        setBanquetNotificationPhNumber(e.target.value)
                    }
                />
            </Grid>
            {errMsg !== '' ? (
                <Grid item xs={12}>
                    <Typography variant="body1" sx={{ color: 'red' }}>
                        {errMsg}
                    </Typography>
                </Grid>
            ) : (
                successMsg !== '' && (
                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{ color: 'green' }}>
                            {successMsg}
                        </Typography>
                    </Grid>
                )
            )}
            <Grid
                item
                xs={12}
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                }}
            >
                <Button variant="outlined" onClick={resetData}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    sx={{ ml: 2 }}
                    onClick={updateBanquetHallDetails}
                    disabled={isProcessing}
                >
                    {isProcessing && <CircularProgress size={24} />}
                    Save
                </Button>
            </Grid>
        </Grid>
    );
}

BanquetHallDetails.propTypes = {};

export default BanquetHallDetails;
