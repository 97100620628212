import React from 'react';
import ModifierCheckBox from './ModifierCheckBox';
import ModifierRadio from './ModifierRadio';
import { ModifierDiv, ModifierGrid } from './ModifierStyle';

export function ModifierGroup({ modifierHook }) {
    return (
        <ModifierDiv>
            {modifierHook.value.modifiers.map((modifier) => (
                <ModifierGrid key={modifier.id}>
                    {modifier.max == 1 && !modifier.isOptional ? (
                        <ModifierRadio
                            modifier={modifier}
                            onChange={modifierHook.changeRadio}
                        />
                    ) : (
                        <ModifierCheckBox
                            modifier={modifier}
                            onChange={modifierHook.changeCheckBox}
                        />
                    )}
                </ModifierGrid>
            ))}
        </ModifierDiv>
    );
}
