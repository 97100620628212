import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Utils from '../utils/Utils';
const { getAmount } = { ...Utils };

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
    return { id, date, name, shipTo, paymentMethod, amount };
}

function preventDefault(event) {
    event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
}));

export default function Orders(prop) {
    const classes = useStyles();
    console.log(prop.allOrders);
    return (
        <React.Fragment>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Total Orders</TableCell>
                        <TableCell align="right">Payment Total</TableCell>
                        <TableCell align="right"> Tip </TableCell>
                        <TableCell align="right"> Average Orders </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(prop.allOrders).map((key, index) => (
                        <TableRow key={prop.allOrders[key].customerType}>
                            <TableCell>{key}</TableCell>

                            <TableCell align="right">
                                {prop.allOrders[key].numberOfOrders}
                            </TableCell>
                            <TableCell align="right">
                                {getAmount(prop.allOrders[key].paymentTotal)}
                            </TableCell>
                            <TableCell align="right">
                                {getAmount(prop.allOrders[key].tip)}
                            </TableCell>
                            <TableCell align="right">
                                {getAmount(prop.allOrders[key].averageOrder)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}
