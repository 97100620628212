/**
 * handles list operations for slots that include
 * - add slot
 * - delete slot
 * - show slot
 */

import React from 'react';
import Button from '@mui/material/Button';
import SlotItem from './SlotItem';

function SlotList(props) {
    const { list, onChange, onRemoveSlot, onAddSlot, disabled } = props;
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: 'fit-content',
            }}
        >
            {list.map((slot, index) => {
                const { start, end, key, label } = slot;
                return (
                    <SlotItem
                        key={index}
                        startTime={start}
                        endTime={end}
                        label={label}
                        index={index}
                        onChange={onChange}
                        onRemove={onRemoveSlot}
                        disabled={disabled}
                    />
                );
            })}
            <div
                style={{ textAlign: 'right', marginBottom: 12, marginTop: 12 }}
            >
                <Button
                    aria-label="add time slot"
                    color="secondary"
                    variant="outlined"
                    size="small"
                    onClick={onAddSlot}
                    disabled={disabled}
                >
                    Add
                </Button>
            </div>
        </div>
    );
}

SlotList.propTypes = {};
SlotList.defaultProps = {
    list: [{}],
};

export default SlotList;
