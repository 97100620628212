import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { RestauInfoContext } from '../../../home/RestauInfoContext';
import CateringContext from '../../CateringContext';
import Utils from '../../../utils/Utils';

const useCateringEvent = () => {
    const { restauHook } = useContext(RestauInfoContext);
    const { updateEvent, createEvent, form, updateForm, setLoading } =
        useContext(CateringContext);
    const navigate = useNavigate();
    const { RestauId, CateringInquiryId } = useParams();
    const [errors, setErrors] = useState({});
    const requiredFields = [
        'customerName',
        'phone',
        //'email',
        'numOfGuests',
        'eventStatus',
    ];

    //PASS RESTAUID from restauHook
    const submitNewEvent = async (e) => {
        setErrors({});
        let tempErrors = {};
        if (form.customerName.length === 0)
            tempErrors['customerName'] = 'Please fill out this field';

        if (form.email.length === 0)
            tempErrors['email'] = 'Please fill out this field';
        else if (form.email.length !== 0) {
            const emailReg = /\S+@\S+\.\S+/;
            if (!emailReg.test(form.email))
                tempErrors['email'] = 'Please enter a valid email';
        }
        if (form.phone.length === 0)
            tempErrors['phone'] = 'Please fill out this field';
        else if (form.phone.length !== 0) {
            let formattedPhNum = Utils.getPhoneNumberInE164Format(form.phone);
            updateForm({ phone: formattedPhNum });
            form.phone = formattedPhNum;
            if (formattedPhNum.length !== 12) {
                tempErrors['phone'] = 'Please enter a valid phone number';
            }
        }
        if (form.numOfGuests.length === 0)
            tempErrors['numOfGuests'] = 'Please fill out this field';
        else if (isNaN(Number(form.numOfGuests))) {
            tempErrors['numOfGuests'] = 'Please provide a valid number';
        }

        if (!form.eventStatus)
            tempErrors['eventStatus'] = 'Please select a status for the event';
        setErrors(tempErrors);

        if (Object.keys(tempErrors).length === 0) {
            try {
                setLoading(true);
                e.preventDefault();
                const response = await createEvent(form);
                const { createdInquiry } = await response.data;
                navigate(
                    `/catering/${createdInquiry.restauId}/${createdInquiry.inquiryId}`
                );
            } catch (error) {
                console.log('Error creating new event: ', error);
            } finally {
                setLoading(false);
            }
        }
    };

    //PASS RESTAUID from url
    const updateExistingEvent = async (e) => {
        setErrors({});
        let tempErrors = {};
        // if (form.customerName.length === 0)
        //     tempErrors['customerName'] = 'Please fill out this field';

        // // ASK MORE CUSTOMER : current feedback was email is not necc for casual caterings.
        // // if (form.email.length === 0)
        // //     tempErrors['email'] = 'Please fill out this field';
        // // else if (form.email.length !== 0) {
        // //     const emailReg = /\S+@\S+\.\S+/;
        // //     if (!emailReg.test(form.email))
        // //         tempErrors['email'] = 'Please enter a valid email';
        // // }
        // if (form.phone.length === 0)
        //     tempErrors['phone'] = 'Please fill out this field';
        // else if (form.phone.length !== 0) {
        //     let formattedPhNum = Utils.getPhoneNumberInE164Format(form.phone);
        //     updateForm({ phone: formattedPhNum });
        //     form.phone = formattedPhNum;
        //     if (formattedPhNum.length !== 12) {
        //         tempErrors['phone'] = 'Please enter a valid phone number';
        //     }
        // }

        // if (form.numOfGuests.length === 0)
        //     tempErrors['numOfGuests'] = 'Please fill out this field';
        // else if (isNaN(Number(form.numOfGuests))) {
        //     tempErrors['numOfGuests'] = 'Please provide a valid number';
        // }
        // if (!form.eventStatus)
        //     tempErrors['eventStatus'] = 'Please select a status for the event';
        //setErrors(tempErrors);

        if (Object.keys(tempErrors).length === 0) {
            try {
                setLoading(true);
                e.preventDefault();
                const response = await updateEvent(
                    RestauId,
                    CateringInquiryId,
                    form.eventId,
                    form
                );
                alert('Event information sucessfully updated.');
            } catch (error) {
                console.log('Error updating current event: ', error);
            } finally {
                setLoading(false);
            }
        }
    };

    return {
        submitNewEvent,
        updateExistingEvent,
        requiredFields,
        errors,
    };
};

export default useCateringEvent;
