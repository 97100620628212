import React, { useContext } from 'react';
import {
    Grid,
    TextField,
    MenuItem,
    Stack,
    Chip,
    Button,
    Typography,
} from '@mui/material';
import CateringSearchContext from '../CateringSearchContext';

const SearchCriteria = () => {
    const {
        filters,
        filterName,
        setFilterName,
        operatorOptions,
        filterOperator,
        setFilterOperator,
        renderFilterValue,
        errors,
        filterConfig,
        deleteFilter,
        applyFilter,
        displayFilters,
        searchEvents,
    } = useContext(CateringSearchContext);

    return (
        <Grid container>
            <Grid container alignItems="center">
                <Grid xs={12} md={2.5} sx={{ mr: 1 }}>
                    <TextField
                        required
                        select
                        label="Filter"
                        size="small"
                        variant="outlined"
                        margin="dense"
                        sx={{ width: '100%' }}
                        value={filterName}
                        helperText={!errors.filter ? null : errors.filter}
                        error={!!errors.filter}
                        onChange={(e) => setFilterName(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    >
                        {filterConfig.map((option) => (
                            <MenuItem key={option.label} value={option.label}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid xs={12} md={2.5} sx={{ mr: 1 }}>
                    <TextField
                        required
                        select
                        label="Filter Operator"
                        size="small"
                        variant="outlined"
                        margin="dense"
                        sx={{ width: '100%' }}
                        value={filterOperator}
                        helperText={
                            !errors.filterOperator
                                ? null
                                : errors.filterOperator
                        }
                        error={!!errors.filterOperator}
                        onChange={(e) => setFilterOperator(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    >
                        {operatorOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                {renderFilterValue}

                <Grid item xs={12} md={2} sx={{ ml: 1 }}>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={(e) => applyFilter(e)}
                    >
                        Apply
                    </Button>
                </Grid>
            </Grid>

            {filters.length !== 0 && (
                <Grid item xs={12} container sx={{ mt: 1 }}>
                    <Typography variant="body1">FIlters applied: </Typography>
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            gap: 1,
                        }}
                    >
                        {filters.map((f) => (
                            <Chip
                                key={f.key}
                                label={displayFilters(f)}
                                onDelete={() => deleteFilter(f.key)}
                                size="small"
                            />
                        ))}
                    </Stack>
                </Grid>
            )}

            <Grid item xs={12} display="flex" justifyContent="center">
                <Button
                    size="small"
                    variant="contained"
                    onClick={(e) => searchEvents(e)}
                    sx={{ m: 2 }}
                >
                    Search
                </Button>
            </Grid>
        </Grid>
    );
};

export default SearchCriteria;
