import React from 'react';
import { useContext } from 'react';
import CallSummary from '../../../../home/CallSummary';
import PhoneReportsContext from '../PhoneReportsContext';
import SearchReportsCriteria from './SearchReportsCriteria';

function PhoneReportsContent(props) {
    const { startDate, endDate } = useContext(PhoneReportsContext);

    return (
        <div>
            <SearchReportsCriteria />
            <CallSummary />
        </div>
    );
}

PhoneReportsContent.propTypes = {};

export default PhoneReportsContent;
