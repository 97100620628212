import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Snackbar,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useContext } from 'react';
import PhoneSettingsContext from '../PhoneSettingsContext';
import PhoneSettingCategories from '../../PhoneSettings/PhoneSettingCategories';
import { useStyles } from '../../../../home/useStyles';
import CustomMenuOptionDetails from './CustomMenuOptionDetails';
import EventIcon from '@mui/icons-material/Event';

function PhoneSettingsContent() {
    const classes = useStyles();
    //const [expanded, setExpanded] = useState(false);
    const {
        loading,
        productPlanPackages,
        customPlanPackages,
        showStatusSnackBar,
        statusSnackBarMessage,
        setShowStatusSnackBar,
        accordionExpanded,
        setAccordionExpanded,
    } = useContext(PhoneSettingsContext);
    //const [customCallDefOptions, setCustomCallDefOptions] = useState([]);

    // useEffect(() => {
    //     //debugger;
    //     let customOptions =
    //         productPlanPackages &&
    //         Object.keys(productPlanPackages).filter(
    //             (x) => productPlanPackages[x].custom
    //         );
    //     if (customOptions.length > 0) {
    //         setCustomPlanPackages(
    //             customOptions.map((x) => productPlanPackages[x])
    //         );
    //     }
    // }, [productPlanPackages]);

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={showStatusSnackBar}
                onClose={() => setShowStatusSnackBar(false)}
                message={statusSnackBarMessage}
                key={'bottomcenter'}
            />

            {loading ? (
                <img
                    className={classes.loadingImage}
                    src="../../../img/spinner.gif"
                    alt="loading"
                />
            ) : (
                <div>
                    <div>
                        {PhoneSettingCategories.map((cat) => {
                            return (
                                cat.display(productPlanPackages) && (
                                    <Accordion
                                        key={cat.name}
                                        expanded={
                                            accordionExpanded === cat.name
                                        }
                                        onChange={(event, curExpandedState) =>
                                            setAccordionExpanded(
                                                curExpandedState
                                                    ? cat.name
                                                    : false
                                            )
                                        }
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`${cat.name}-content`}
                                            id={`${cat.name}-header`}
                                            sx={{ verticalAlign: 'middle' }}
                                        >
                                            {cat.icon}
                                            <Typography
                                                sx={{
                                                    ml: 1,
                                                    width: {
                                                        md: '33%',
                                                        xs: '50%',
                                                    },
                                                    flexShrink: 0,
                                                }}
                                            >
                                                {cat.label}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ m: 2 }}>
                                            {cat.component}
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            );
                        })}
                    </div>
                    {Object.keys(customPlanPackages).length > 0 && (
                        <Typography variant="h6" mt={1}>
                            <br />
                            Custom Call Deflection Options
                        </Typography>
                    )}
                    <div>
                        {Object.keys(customPlanPackages).map((custPkg) => {
                            return (
                                <Accordion
                                    key={
                                        'customPanel_' +
                                        customPlanPackages[custPkg].name
                                    }
                                    expanded={
                                        accordionExpanded ===
                                        'customPanel_' +
                                            customPlanPackages[custPkg].name
                                    }
                                    onChange={(event, curExpandedState) =>
                                        setAccordionExpanded(
                                            curExpandedState
                                                ? 'customPanel_' +
                                                      customPlanPackages[
                                                          custPkg
                                                      ].name
                                                : false
                                        )
                                    }
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`${customPlanPackages[custPkg].name}-content`}
                                        id={`${customPlanPackages[custPkg].name}-header`}
                                        sx={{ verticalAlign: 'middle' }}
                                    >
                                        <EventIcon />
                                        <Typography
                                            sx={{
                                                ml: 1,
                                                width: '33%',
                                                flexShrink: 0,
                                            }}
                                        >
                                            {customPlanPackages[custPkg].label}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ m: 2 }}>
                                        <CustomMenuOptionDetails
                                            custMenuOptionData={
                                                customPlanPackages[custPkg]
                                            }
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
}

PhoneSettingsContent.propTypes = {};

export default PhoneSettingsContent;
