import React, { useContext, useEffect } from 'react';
import CallSummaryByDay from './CallSummaryByDay';
import CallSummaryByHour from './CallSummaryByHour';
import CallSummaryForLast2Days from './CallSummaryForLast2Days';
import { DashboardContext } from './DashboardContext';
import { useStyles } from './styles/useStyles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import CallSummaryReport from './CallSummaryReport';
import { RestauInfoContext } from './RestauInfoContext';
import moment from 'moment';
import CallSummaryForLastNDays from './CallSummaryForLastNDays';

export default function CallSummary(props) {
    // const { startDate, endDate } = useContext(PhoneReportsContext);
    // const { restauHook } = useContext(RestauInfoContext);
    const { restauHook } = useContext(RestauInfoContext);
    const { last30Days } = props;
    const { callSummaryHook } = useContext(DashboardContext);
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    const chartColorPriority = [
        '#C6CECA',
        '#36B37E',
        '#7777F7',
        '#C1DCE0',
        '#647178',
        '#A1BCFF',
        '#EBFFA1',
        '#FFE4A1',
        '#F7F777',
        '#949447',
        '#676731',
    ];

    /* useEffect(() => {
        debugger;
        console.log('Loading Call Summary data');
        callSummaryHook.loadData(startDate, endDate);
    }, [restauHook.restauId]); */

    useEffect(() => {
        //debugger;
        //Take yesterday's date as end date
        //subtract 30 days, and use that as start date
        if (!restauHook.restauTimeZone || !restauHook.restauId) {
            return;
        }
        let yesterdayDate = moment()
            .tz(restauHook.restauTimeZone)
            .subtract(1, 'days');
        //.format('YYYY-MM-DD');
        let endDate = moment
            .tz(
                yesterdayDate.format('YYYY-MM-DD') + 'T23:59:59',
                restauHook.restauTimeZone
            )
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss[Z]');

        let startDate = moment
            .tz(
                yesterdayDate.format('YYYY-MM-DD') + 'T00:00:01',
                restauHook.restauTimeZone
            )
            .subtract(30, 'days')
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss[Z]');

        callSummaryHook.loadData(startDate, endDate);
    }, [
        restauHook.restauId,
        restauHook.restauTimeZone,
        restauHook.subscriptionPlan,
    ]);

    return (
        <Grid
            container
            sx={{
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'center',
                mt: 2,
            }}
            columnSpacing={2}
            rowSpacing={3}
        >
            <Grid item xs={12}>
                {
                    // !callSummaryHook.callSummaryByDay ||
                    // callSummaryHook.callSummaryByDay.length === 0 ? (
                    callSummaryHook.callSummaryByDayLoading ? (
                        <div>
                            <img
                                className={classes.loadingImage}
                                src="../../img/spinner.gif"
                                alt="loading"
                            />
                        </div>
                    ) : (
                        <CallSummaryReport
                            callSummaryData={callSummaryHook.callSummary}
                            lastNdaysData={callSummaryHook.callSummaryLastNDays}
                            chartColorPriority={chartColorPriority}
                            last30Days={last30Days}
                        />
                    )
                }
            </Grid>
            <Grid item xs={12}>
                {
                    //!callSummaryHook.callSummaryByDay ||
                    //callSummaryHook.callSummaryByDay.length === 0 ||
                    callSummaryHook.callSummaryByDayLoading ? (
                        <div>
                            <img
                                className={classes.loadingImage}
                                src="../../img/spinner.gif"
                                alt="loading"
                            />
                        </div>
                    ) : (
                        // callSummaryHook.callSummaryByDay.length > 1 && (
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Typography
                                    component="b"
                                    variant="h6"
                                    color="primary"
                                    gutterBottom
                                >
                                    {`StrideQ's Smart Host Impact - By Day ${
                                        last30Days ? '(last 30 days)' : ''
                                    }`}

                                    <Paper
                                        className={fixedHeightPaper}
                                        style={{ height: '400px' }}
                                    >
                                        <CallSummaryByDay
                                            data={
                                                callSummaryHook.callSummaryByDay
                                            }
                                            chartColorPriority={
                                                chartColorPriority
                                            }
                                        />
                                    </Paper>
                                </Typography>
                            </Grid>
                        </Grid>
                        // )
                    )
                }
            </Grid>
            <Grid item xs={12}>
                {
                    //!callSummaryHook ||
                    //callSummaryHook.callSummaryByHour.length === 0
                    callSummaryHook.callSummaryByHourLoading ? (
                        <div>
                            <img
                                className={classes.loadingImage}
                                src="../../img/spinner.gif"
                                alt="loading"
                            />
                        </div>
                    ) : (
                        // callSummaryHook.callSummaryByHour.length > 1 && (
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Typography
                                    component="b"
                                    variant="h6"
                                    color="primary"
                                    gutterBottom
                                >
                                    {`StrideQ's Smart Host Impact - By Hour ${
                                        last30Days ? '(last 30 days)' : ''
                                    }`}
                                    <Paper
                                        className={fixedHeightPaper}
                                        style={{ height: '500px' }}
                                    >
                                        <CallSummaryByHour
                                            data={
                                                callSummaryHook.callSummaryByHour
                                            }
                                            chartColorPriority={
                                                chartColorPriority
                                            }
                                        />
                                    </Paper>
                                </Typography>
                            </Grid>
                        </Grid>
                        // )
                    )
                }
            </Grid>
            <Grid item xs={6}>
                {callSummaryHook.callSummaryByDayLoading ? (
                    <div>
                        <img
                            className={classes.loadingImage}
                            src="../../img/spinner.gif"
                            alt="loading"
                        />
                    </div>
                ) : (
                    // (callSummaryHook.callSummaryLast2Days.today.length > 1 ||
                    //     callSummaryHook.callSummaryLast2Days.yesterday.length >
                    //         1) && (
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography
                                component="b"
                                variant="h6"
                                color="primary"
                                gutterBottom
                            >
                                {`StrideQ's Smart Host Impact - Summary ${
                                    last30Days ? '(last 30 days)' : ''
                                }`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper
                                className={fixedHeightPaper}
                                style={{ height: '100%', width: '100%' }}
                            >
                                <CallSummaryForLastNDays
                                    data={callSummaryHook.callSummaryLastNDays}
                                    chartColorPriority={chartColorPriority}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                    // )
                )}
            </Grid>
            <Grid item xs={12}>
                {
                    //!callSummaryHook ||
                    //Object.keys(callSummaryHook.callSummaryLast2Days).length ===  0
                    callSummaryHook.callSummaryLast2DaysLoading ? (
                        <div>
                            <img
                                className={classes.loadingImage}
                                src="../../img/spinner.gif"
                                alt="loading"
                            />
                        </div>
                    ) : (
                        // (callSummaryHook.callSummaryLast2Days.today.length > 1 ||
                        //     callSummaryHook.callSummaryLast2Days.yesterday.length >
                        //         1) && (
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Typography
                                    component="b"
                                    variant="h6"
                                    color="primary"
                                    gutterBottom
                                >
                                    {`StrideQ's Smart Host Impact - Last 2 Days`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper
                                    className={fixedHeightPaper}
                                    style={{ height: '350px', width: '100%' }}
                                >
                                    <CallSummaryForLast2Days
                                        data={
                                            callSummaryHook.callSummaryLast2Days
                                        }
                                        chartColorPriority={chartColorPriority}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                        // )
                    )
                }
            </Grid>
        </Grid>
    );
}
