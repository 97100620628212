import axios from 'axios';
import { useState } from 'react';
import { useContext } from 'react';
import Config from '../../../config/Config';
import { RestauInfoContext } from '../../../home/RestauInfoContext';

const getFilteredCallerDetailsFromDb = async (payload) => {
    const token = await window.customerApp.auth().currentUser.getIdToken();
    var config = {
        headers: {
            token: token,
            'Content-Type': 'application/json',
        },
    };

    //function to fetch the Phone Settings from databasse
    return await axios.post(Config.getFilteredCallerDetails, payload, config);
};

function usePhoneCallerDetails() {
    const { restauHook } = useContext(RestauInfoContext);
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [searchStartDate, setSearchStartDate] = useState(null);
    const [searchEndDate, setSearchEndDate] = useState(null);
    const [filteredCallerDetails, setFilteredCallerDetails] = useState({});
    //const [successMsg, setSuccessMsg] = useState('');

    const resetFilters = () => {
        setSearchStartDate(null);
        setSearchEndDate(null);
        setErrMsg('');
    };

    const getFilteredCallDetails = () => {
        setLoading(true);
        //debugger;
        let reqBody = {
            restauId: restauHook.restauId,
            startDate: searchStartDate,
            endDate: searchEndDate,
        };

        console.log('Request Body: ', reqBody);

        getFilteredCallerDetailsFromDb(reqBody)
            .then((response) => {
                //debugger;
                console.log('Response: ', response);
                if (Object.keys(response.data).length > 0) {
                    setErrMsg('');
                } else {
                    setErrMsg('No results found.');
                }

                setFilteredCallerDetails(response.data);
                //refreshData();
                //setSuccessMsg('Data saved succesfully !');
            })
            .catch((err) => {
                //debugger;
                console.log(err);
                setErrMsg('Failed to fetch Phone Caller Details.');
                // setSuccessMsg('');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        searchStartDate,
        setSearchStartDate,
        searchEndDate,
        setSearchEndDate,
        filteredCallerDetails,
        setFilteredCallerDetails,
        getFilteredCallDetails,
        resetFilters,
        loading,
        setLoading,
        errMsg,
    };
}

export default usePhoneCallerDetails;
