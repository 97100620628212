import React, { useContext } from 'react';
import CateringEventContext from '../CateringEventContext';
import CateringContext from '../../../CateringContext';
import BasicEventInfo from './BasicEventInfo';
import { Grid, Button, Snackbar, Typography } from '@mui/material';
import MenuSelection from './MenuSelection/MenuSelection';
import CopyLink from './CopyLink';

const CateringEventContent = () => {
    const { submitNewEvent, updateExistingEvent } =
        useContext(CateringEventContext);
    const {
        newEventPage,
        clearAll,
        snackbarOpen,
        setSnackbarOpen,
        snackbarMessage,
        snackbarAction,
    } = useContext(CateringContext);

    return (
        <div>
            {/* <BasicEventInfo style={{ maxWidth: '100%' }} /> */}
            <BasicEventInfo />

            {newEventPage ? (
                <Button
                    size="small"
                    variant="contained"
                    onClick={submitNewEvent}
                    sx={{ m: 2 }}
                >
                    Create
                </Button>
            ) : (
                <Button
                    size="small"
                    variant="contained"
                    onClick={updateExistingEvent}
                    sx={{ m: 2 }}
                >
                    Save
                </Button>
            )}

            <Button
                size="small"
                variant="contained"
                onClick={clearAll}
                sx={{ m: 2 }}
            >
                Clear All
            </Button>

            {newEventPage ? (
                <Typography variant="h6">
                    {' '}
                    Please create an event before setting menu selections{' '}
                </Typography>
            ) : (
                <Grid container sx={{ mt: 4 }}>
                    <MenuSelection />
                    <CopyLink />
                </Grid>
            )}

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                message={snackbarMessage}
                onClose={(event, reason) => {
                    if (reason === 'clickaway') return;
                    setSnackbarOpen(false);
                }}
                action={snackbarAction}
            />
        </div>
    );
};

export default CateringEventContent;
