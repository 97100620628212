import React from 'react';
import Grid from '@mui/material/Grid';
import PriceGrid from './PriceGrid';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

export default function CallSummaryReport(props) {
    let data = props.callSummaryData;
    let last30Days = props.last30Days;

    let percentSavedByStrideq =
        data.strideQSavedCallCount && data.totalNoOfCalls
            ? (data.strideQSavedCallCount / data.totalNoOfCalls) * 100
            : 0;

    let callCountTitleToDisplay = '';
    let callCountOrPercentToDisplay = '';
    let callDetailsToDisplay = '';

    /* if the percentage is less than 25, 
                         then show the call count 
                         else show the call percentage */

    if (percentSavedByStrideq >= 25) {
        callCountTitleToDisplay = '% of calls saved by StrideQ';
        callCountOrPercentToDisplay = Math.ceil(percentSavedByStrideq) + '%';
        if (data.strideQSavedCallCount >= 149) {
            callDetailsToDisplay = `(${
                data.strideQSavedCallCount ? data.strideQSavedCallCount : 0
            } of ${
                data.totalNoOfCalls ? data.totalNoOfCalls : 0
            } calls in total)`;
        }
    } else {
        if (data.strideQSavedCallCount >= 149) {
            callCountTitleToDisplay = 'Number of calls saved by StrideQ';
            callCountOrPercentToDisplay = data.strideQSavedCallCount;
            callDetailsToDisplay = '';
        } else {
            callCountTitleToDisplay = 'Total Number of Calls';
            callCountOrPercentToDisplay = data.totalNoOfCalls;
            callDetailsToDisplay = '';
        }
    }

    //console.log('CallSummaryReport data: ', data);

    return (
        <Grid container rowSpacing={4}>
            <Grid item xs={12}>
                <Typography
                    component="b"
                    variant="h6"
                    color="primary"
                    gutterBottom
                >
                    {`StrideQ's Smart Host Impact ${
                        last30Days ? '(last 30 days)' : ''
                    }`}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} md={4} lg={3.5}>
                        <PriceGrid
                            title={callCountTitleToDisplay}
                            total={callCountOrPercentToDisplay}
                            details={callDetailsToDisplay}
                            date={data.tillDate}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3.5}>
                        <PriceGrid
                            title="No of hrs. saved by StrideQ"
                            total={
                                data.pressed1CallTimeSavings
                                    ? (
                                          data.pressed1CallTimeSavings / 60
                                      ).toFixed(2)
                                    : '0'
                            }
                            date={data.tillDate}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

CallSummaryReport.propTypes = {
    callSummaryData: PropTypes.shape({
        today: PropTypes.array,
        yesterday: PropTypes.array,
        strideQSavedCallCount: PropTypes.number,
        totalNoOfCalls: PropTypes.number,
        pressed1CallTimeSavings: PropTypes.number,
        tillDate: PropTypes.string,
    }),
    lastNdaysData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    chartColorPriority: PropTypes.array,
    last30Days: PropTypes.bool,
};
