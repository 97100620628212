import { styled } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { darkTheme } from '../../theme';

const borderRadiusProps = (radius) => ({
    borderBottomRightRadius: radius,
    // borderTopRightRadius: radius,
});

const openedMixin = (theme, width, radius) => ({
    width: width,
    ...borderRadiusProps(radius),
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme, radius) => ({
    ...borderRadiusProps(radius),
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(10)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(11)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, width, radius }) => ({
    width: width,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',

    ...(open && {
        ...openedMixin(theme, width),
        '& .MuiDrawer-paper': openedMixin(theme, width, radius),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme, radius),
    }),
}));

function AppDrawer(props) {
    const { drawerWidth, drawerRadius, children, open } = props;

    return (
        <ThemeProvider theme={darkTheme}>
            <Drawer
                variant="permanent"
                radius={drawerRadius}
                open={open}
                width={drawerWidth}
            >
                {children}
            </Drawer>
        </ThemeProvider>
    );
}

AppDrawer.propTypes = {
    open: PropTypes.bool,
    drawerWidth: PropTypes.number.isRequired,
    drawerRadius: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
};

AppDrawer.defaultProps = {
    open: false,
};

export default AppDrawer;
