import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';

const QuantityTitle = {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    color: '#222C31',
};

const IncrementContainer = styled.div`
    padding-top: 10px;
    display: flex;
    height: 24px;
    margin: 10px 0px 0px 0px;
`;

const IncrementButton = styled.div`
  width: 23px;
  height: 23px
  color: #7777F7;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  margin: 0px 10px 5px;
  ${({ disabled }) =>
      disabled &&
      `opacity: 0.5; 
     pointer-events: none; 
     `}
  &:hover {
    background-color: "#81ecec";
  }
`;

export function QuantityInput({ quantity, setQuantity }) {
    return (
        <IncrementContainer>
            <div className={QuantityTitle}>
                <b>Quantity</b>
            </div>
            <IncrementButton
                onClick={() => {
                    setQuantity(quantity - 1);
                }}
                disabled={quantity === 1}
            >
                <i
                    className="fas fa-minus-circle"
                    style={{ fontSize: '27px' }}
                ></i>
            </IncrementButton>
            <Typography variant="body1" sx={{ ml: 1, mr: 1 }}>
                {quantity}
            </Typography>
            <IncrementButton
                onClick={() => {
                    setQuantity(quantity + 1);
                }}
            >
                <i
                    className="fas fa-plus-circle"
                    style={{ fontSize: '27px' }}
                ></i>
            </IncrementButton>
        </IncrementContainer>
    );
}
