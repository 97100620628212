import React from 'react';
import { Alert, Button, CircularProgress } from '@mui/material';

function SubmitButton(props) {
    const { severity, message, showProgress, label, onClick, ...other } = props;
    return (
        <>
            {message && (
                <Alert severity={severity} style={{ marginBottom: 20 }}>
                    {message}
                </Alert>
            )}

            <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginBottom: 10, marginTop: 10 }}
                onClick={onClick}
                disabled={showProgress}
                {...other}
            >
                {showProgress ? <CircularProgress size={24} /> : label}
            </Button>
        </>
    );
}

SubmitButton.propTypes = {};

export default SubmitButton;
