import {
    Button,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/x-date-pickers';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useContext } from 'react';
import PhoneLogContext from '../PhoneLogContext';
import moment from 'moment';
import { useEffect } from 'react';
import { RestauInfoContext } from '../../../../home/RestauInfoContext';
import Utils from '../../../../utils/Utils';

const { getDateWithOffset, getPhoneNumberInE164Format } = { ...Utils };

function SearchCriteria() {
    const {
        searchPhNumber,
        setSearchPhNumber,
        searchStartDate,
        setSearchStartDate,
        searchEndDate,
        setSearchEndDate,
        searchCallStatus,
        setSearchCallStatus,
        getFilteredPhoneLogs,
        setFilters,
        filters,
        filteredCallLogs,
        setFilteredCallLogs,
        callStatusItems,
    } = useContext(PhoneLogContext);
    const { restauHook } = useContext(RestauInfoContext);

    function setDateRange(date) {
        setSearchStartDate(date);
        if (searchEndDate < date) {
            setSearchEndDate(date);
        }
    }

    function getPhoneLogs() {
        if (restauHook.restauId === null) {
            return;
        }
        setFilters((prevFilters) => []);
        //setLoading(true);
        //debugger;
        if (searchPhNumber) {
            setFilters((prevFilters) => [
                ...prevFilters,
                {
                    key: 'phoneNumber',
                    val: getPhoneNumberInE164Format(searchPhNumber),
                },
            ]);
        }

        // console.log(
        //     'Start: ',
        //     searchStartDate.format('YYYY-MM-DD'),
        //     'End: ',
        //     searchEndDate.format('YYYY-MM-DD')
        //);
        if (searchStartDate) {
            setFilters((prevFilters) => [
                ...prevFilters,
                {
                    key: 'startDate',
                    val: searchStartDate.format('YYYY-MM-DD'), //getUTCDate(searchStartDate),
                },
            ]);
        }

        if (searchEndDate) {
            setFilters((prevFilters) => [
                ...prevFilters,
                {
                    key: 'endDate',
                    val: searchEndDate.format('YYYY-MM-DD'), //getUTCDate(searchEndDate, true),
                },
            ]);
        }

        if (searchCallStatus) {
            setFilters((prevFilters) => [
                ...prevFilters,
                { key: 'callStatus', val: searchCallStatus },
            ]);
        }
    }

    function loadData() {
        setSearchStartDate(moment());
        setSearchEndDate(moment().add(1, 'days'));
        // setSearchStartDate(null);
        // setSearchEndDate(null);
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (filters.length > 0) {
            getFilteredPhoneLogs();
        } else {
            setFilteredCallLogs({});
        }
    }, [filters]);

    return (
        <Grid
            container
            sx={{
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'center',
            }}
            columnSpacing={2}
            rowSpacing={2}
        >
            <Grid item xs={12} md={2.5}>
                <TextField
                    fullWidth
                    label="Search by Phone #"
                    id="searchByPhNumber"
                    value={searchPhNumber}
                    onChange={(e) => setSearchPhNumber(e.target.value.trim())}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={6} md={2.5}>
                <DatePicker
                    fullWidth
                    size="small"
                    label="Date From"
                    value={searchStartDate}
                    autoOk
                    inputVariant="outlined"
                    margin="dense"
                    onChange={(date) => setDateRange(date)}
                    minDate={moment(getDateWithOffset(-365))}
                    maxDate={moment().add(1, 'days')}
                    selected={moment(searchStartDate)}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Grid>
            <Grid item xs={6} md={2.5}>
                <DatePicker
                    fullWidth
                    size="small"
                    label="Date To"
                    autoOk
                    value={searchEndDate}
                    inputVariant="outlined"
                    margin="dense"
                    onChange={(date) => setSearchEndDate(date)}
                    minDate={moment(
                        searchStartDate
                            ? searchStartDate
                            : getDateWithOffset(-180)
                    )}
                    maxDate={
                        searchStartDate
                            ? moment(searchStartDate).add(30, 'days')
                            : moment().add(1, 'days')
                    }
                    //selected={moment(searchEndDate)}
                    renderInput={(props) => <TextField {...props} />}
                />
            </Grid>
            <Grid item xs={12} md={3.5}>
                <FormControl fullWidth sx={{ minWidth: 300 }}>
                    <InputLabel id="callStatus-select-label">
                        Call Status
                    </InputLabel>
                    <Select
                        labelId="callStatus-select-label"
                        id="callStatus-select"
                        value={searchCallStatus}
                        label="Call Status"
                        onChange={(e) => setSearchCallStatus(e.target.value)}
                        startAdornment={
                            <InputAdornment position="start">
                                <DashboardIcon />
                            </InputAdornment>
                        }
                    >
                        {Object.keys(callStatusItems).map((key) => (
                            <MenuItem key={key} value={key}>
                                {callStatusItems[key] || ''}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={1}>
                <Button variant="contained" onClick={getPhoneLogs}>
                    Apply
                </Button>
            </Grid>
        </Grid>
    );
}

SearchCriteria.propTypes = {};

export default SearchCriteria;
