import { Grid } from '@mui/material';
import React from 'react';
import DisplayChart from '../utils/DisplayChart';
import PropTypes from 'prop-types';

export default function CallSummaryForLast2Days(props) {
    // * uncomment below lines for some chart sample data
    /* 
    const todayData = [
        ['Metric', 'Number of Calls'],
        ['Answered Calls', 10],
        ['Missed Calls answered by StrideQ', 18],
        ['Online Order', 12],
        ['Banquet Inquiry', 8],
        ['Catering Inquiry', 7],
        ['Reservation Inquiry', 7],
        ['Store Hours Inquiry', 7],
    ];

    const yesterdayData = [
        ['Metric', 'Number of Calls'],
        ['Answered Calls', 10],
        ['Missed Calls answered by StrideQ', 18],
        ['Online Order', 12],
        ['Banquet Inquiry', 8],
        ['Catering Inquiry', 7],
        ['Reservation Inquiry', 7],
        ['Store Hours Inquiry', 7],
    ];
    */

    const { data, chartColorPriority } = props;
    const todayData = data.today;
    const yesterdayData = data.yesterday;

    const todayOptions = {
        title: 'Yesterday',
        sliceVisibilityThreshold: 0,
        pieHole: 0.4,
        is3D: false,
        chartArea: { width: '100%', height: '80%' },
        pieSliceText: 'value',
        colors: [...chartColorPriority],
    };

    const yesterdayOptions = {
        title: 'Day before Yesterday',
        sliceVisibilityThreshold: 0,
        pieHole: 0.4,
        is3D: false,
        chartArea: { width: '100%', height: '80%' },
        pieSliceText: 'value',
        colors: [...chartColorPriority],
    };

    return (
        <Grid container rowSpacing={2}>
            <Grid item xs={12} md={6}>
                <DisplayChart
                    chartType="PieChart"
                    width="100%"
                    height="310px"
                    data={todayData}
                    options={todayOptions}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <DisplayChart
                    chartType="PieChart"
                    width="100%"
                    height="310px"
                    data={yesterdayData}
                    options={yesterdayOptions}
                />
            </Grid>
        </Grid>
    );
}

CallSummaryForLast2Days.propTypes = {
    data: PropTypes.shape({
        today: PropTypes.array,
        yesterday: PropTypes.array,
    }),
    chartColorPriority: PropTypes.array,
};
