import { Grid, IconButton, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AudioPreview from './AudioPreview';

function UploadAudioFile(props) {
    //const [selectedFile, setSelectedFile] = useState(null);
    const { newAudioFile, setNewAudioFile } = props;
    const [errMsg, setErrMsg] = useState(null);
    const fileInputRef = useRef(null);

    const handleFileSelection = (file) => {
        //debugger;
        //validate the fileType to be mp3
        setErrMsg('');
        console.log(file);
        if (file === undefined || file.name.split('.').slice(-1)[0] !== 'mp3') {
            setErrMsg(
                'Inavlid audio file. Only mp3 files accepted. Please use free tool like https://www.onlineconverter.com/mp3 to get file in the right mp3 format.'
            );
            return;
        }
        //file.name = getUUID() + file.name;
        setNewAudioFile(file);
    };

    const handleUploadBtnClick = () => {
        fileInputRef.current.click();
    };

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid
                item
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: 'auto',
                }}
                alignContent="center"
            >
                <input
                    hidden
                    ref={fileInputRef}
                    accept=".mp3"
                    type="file"
                    onChange={(e) => {
                        handleFileSelection(e.target.files[0]);
                    }}
                />
                <Typography sx={{ margin: 'auto' }}>
                    Click below to upload a file.
                </Typography>
                <IconButton
                    color="primary"
                    onClick={handleUploadBtnClick}
                    sx={{ margin: 'auto' }}
                    //sx={{ padding: 0.2, mr: 5 }}
                >
                    <FileUploadIcon sx={{ fontSize: 65 }} />
                </IconButton>
            </Grid>
            <Grid item xs={12}>
                {newAudioFile && (
                    <>
                        <Typography variant="h6" component="div">
                            Audio file selected:
                        </Typography>
                        <AudioPreview audioFile={newAudioFile} />
                    </>
                )}
            </Grid>
        </Grid>
    );
}

UploadAudioFile.propTypes = {
    newAudioFile: PropTypes.object,
    setNewAudioFile: PropTypes.func,
};

export default UploadAudioFile;
