import moment from 'moment';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import Config from '../../config/Config';
import { RestauInfoContext } from '../../home/RestauInfoContext';
import { getUUID } from '../Utils';
import {
    overrideDateTimeTypes,
    overrideStatuses,
    overrideTypes,
} from './constants';
const { default: axios } = require('axios');

const updateVisOverridesinDb = async (payload) => {
    const token = await window.customerApp.auth().currentUser.getIdToken();
    var config = {
        headers: {
            token: token,
            'Content-Type': 'application/json',
        },
    };

    //function to fetch the popularitems from databasse
    return await axios.post(
        Config.updateVisibilityOverridesForRestau,
        payload,
        config
    );
};

const getAllVisOverridesFromDb = async (payload) => {
    //console.log('Save Override Data', data);

    const token = await window.customerApp.auth().currentUser.getIdToken();
    var config = {
        headers: {
            token: token,
            'Content-Type': 'application/json',
        },
    };

    //function to fetch the popularitems from databasse
    return await axios.post(
        Config.getAllVisibilityOverrideMappingForRestau,
        payload,
        config
    );
};

const useVisOverride = () => {
    const [openNewOverrideDialog, setOpenNewOverrideDialog] = useState(false);
    const [openEditOverrideDialog, setOpenEditOverrideDialog] = useState(false);
    const [allOverrideData, setAllOverrideData] = useState({});
    const [openVisOverrideData, setOpenVisOverrideData] = useState({});

    const [openOverrideName, setOpenOverrideName] = useState('');
    const [openOverrideSameRuleType, setOpenOverrideSameRuleType] =
        useState(false);
    const [openOverrideRuleType, setOpenOverrideRuleType] = useState('');
    const [openOverrideRuleStatus, setOpenOverrideRuleStatus] = useState('');
    const [openOverrideRuleDtTimeType, setOpenOverrideRuleDtTimeType] =
        useState('');
    const [openOverrideRuleDays, setOpenOverrideRuleDays] = useState([]);
    const [openOverrideRuleTimeStart, setOpenOverrideRuleTimeStart] =
        useState('');
    const [openOverrideRuleTimeEnd, setOpenOverrideRuleTimeEnd] = useState('');
    const [openOverrideHasError, setOpenOverrideHasError] = useState(false);
    const [openOverrideErrorStr, setOpenOverrideErrorStr] = useState('');
    const [loading, setLoading] = useState(false);

    const { restauHook } = useContext(RestauInfoContext);

    const deleteDayDataFromRule = (ruleId, day) => {
        //debugger;
        let tempVisOverrideData = JSON.parse(
            JSON.stringify(openVisOverrideData)
        );
        let ruleData = tempVisOverrideData.rules.find((x) => x.id === ruleId);
        //delete the whole rule, when the last day data is deleted.
        if (ruleData && Object.keys(ruleData.days).length == 1) {
            tempVisOverrideData.rules = [
                ...tempVisOverrideData.rules.filter((x) => x.id !== ruleId),
            ];
        } else if (ruleData) {
            //ruleData.days = ruleData.days.filter((x) => x.day !== day);
            delete ruleData.days[day];
            tempVisOverrideData.rules = [
                ...tempVisOverrideData.rules.filter((x) => x.id !== ruleId),
                ruleData,
            ];
        }
        //console.log('Updated Vis Override Data: ', visOverrideData);
        setOpenVisOverrideData({ ...tempVisOverrideData });
    };

    const computeTimeString = (timeObj, dateTimeType) => {
        //moment("1634", "HHmm").format("hh:mm A")
        if (dateTimeType === '24/7') {
            return '24/7';
        } else {
            return (
                moment(
                    `${timeObj.startHour}${timeObj.startMin}`,
                    'HHmm'
                ).format('hh:mm A') +
                ' - ' +
                moment(`${timeObj.endHour}${timeObj.endMin}`, 'HHmm').format(
                    'hh:mm A'
                )
            );
        }
    };

    const resetOpenVisOverrideData = () => {
        if (openVisOverrideData.id) {
            setOpenOverrideName(openVisOverrideData.name);
        } else {
            //setVisbilityOverrideData({ ...visbilityOverride });
            setOpenOverrideName('');
            setOpenVisOverrideData({
                name: '',
                id: getUUID(),
                rules: [],
            });
        }
        setOpenOverrideSameRuleType(false);
        setOpenOverrideRuleType(overrideTypes[0].value);
        setOpenOverrideRuleStatus(overrideStatuses[1].value);
        setOpenOverrideRuleDtTimeType(overrideDateTimeTypes[0].value);
        setOpenOverrideRuleTimeStart('');
        setOpenOverrideRuleTimeEnd('');
        setOpenOverrideRuleDays([]);
        setOpenOverrideErrorStr('');
        setOpenOverrideHasError(false);
    };

    const validateNewOverrideDialog = () => {
        if (openOverrideName === '') {
            setOpenOverrideHasError(true);
            setOpenOverrideErrorStr('Override Name cannot be empty.');
            return false;
        }
        if (openOverrideRuleType === '') {
            setOpenOverrideHasError(true);
            setOpenOverrideErrorStr('Override rule type is mandatory.');
            return false;
        }
        if (openOverrideRuleStatus === '') {
            setOpenOverrideHasError(true);
            setOpenOverrideErrorStr('Override rule status is mandatory.');
            return false;
        }
        if (openOverrideRuleDtTimeType === '') {
            setOpenOverrideHasError(true);
            setOpenOverrideErrorStr(
                'Override rule DateTime type is mandatory.'
            );
            return false;
        }

        setOpenOverrideErrorStr('');
        setOpenOverrideHasError(false);
        return true;
    };

    const handleAddNewVisOverride = (data) => {
        //console.log('Save Override Data', data);
        //let guid = getUUID();
        //debugger;
        setLoading(true);
        if (data.rules.length === 0) {
            throw new Error('Alteast one Rule should be present.');
        } else if (restauHook.restauId === null) {
            throw new Error('Alteast one Rule should be present.');
        }
        let reqBody = {
            restauId: restauHook.restauId,
            overridesToAdd: { [data.id]: data },
        };
        updateVisOverridesinDb(reqBody)
            .then((response) => {
                console.log('Response: ', response);
                if (
                    response &&
                    response.status === 200 &&
                    response.data &&
                    response.data.status === 'Success'
                ) {
                    //setAllOverrideData({ ...allOverrideData, [data.id]: data });
                    refreshData();
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                //throw new Error(err.toString());
                setLoading(false);
            });
    };

    const handleEditVisOverride = (data) => {
        //debugger;
        setLoading(true);
        if (data.rules.length === 0) {
            throw new Error('Alteast one Rule should be present.');
        } else if (restauHook.restauId === null) {
            throw new Error('Alteast one Rule should be present.');
        }
        let reqBody = {
            restauId: restauHook.restauId,
            overridesToUpdate: { [data.id]: data },
        };
        updateVisOverridesinDb(reqBody)
            .then((response) => {
                console.log('Response: ', response);
                if (
                    response &&
                    response.status === 200 &&
                    response.data &&
                    response.data.status === 'Success'
                ) {
                    refreshData();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const addNewOverrideRule = () => {
        if (!validateNewOverrideDialog()) {
            //alert('Invalid data');
            return;
        }
        console.log(openOverrideRuleTimeStart, openOverrideRuleTimeEnd);

        const data = openVisOverrideData;

        if (!data.rules) {
            data.rules = [];
        }
        //{'endHour': 11, 'endMin': 30, 'startHour': 7, 'startMin': 30}}
        let daysData = {};
        if (openOverrideRuleDtTimeType === '24/7') {
            daysData['All Days'] = {
                day: 'All Days',
                //time: '24/7',
                time: {
                    startHour: 0,
                    startMin: 0,
                    endHour: 0,
                    endMin: 0,
                },
            };
        } else if (openOverrideRuleDtTimeType === 'time') {
            daysData['All Days'] = {
                day: 'All Days',
                //time: startTime + ' - ' + endTime,
                time: {
                    startHour: openOverrideRuleTimeStart.format('HH'),
                    startMin: openOverrideRuleTimeStart.format('mm'),
                    endHour: openOverrideRuleTimeEnd.format('HH'),
                    endMin: openOverrideRuleTimeEnd.format('mm'),
                },
            };
        } else if (openOverrideRuleDtTimeType === 'dayTime') {
            daysData = openOverrideRuleDays.reduce((res, day) => {
                res[day] = {
                    day: day,
                    //time: startTime + ' - ' + endTime,
                    time: {
                        startHour: openOverrideRuleTimeStart.format('HH'),
                        startMin: openOverrideRuleTimeStart.format('mm'),
                        endHour: openOverrideRuleTimeEnd.format('HH'),
                        endMin: openOverrideRuleTimeEnd.format('mm'),
                    },
                };
                return res;
            }, {});
        }

        data.rules = [
            ...data.rules,
            {
                id: getUUID(),
                type: openOverrideRuleType,
                status: openOverrideRuleStatus,
                dateTimeType: openOverrideRuleDtTimeType,
                days: { ...daysData },
            },
        ];
        setOpenVisOverrideData({ ...data });
    };

    const refreshData = () => {
        if (restauHook.restauId === null) {
            return;
        }
        setLoading(true);
        let reqBody = {
            restauId: restauHook.restauId,
        };
        getAllVisOverridesFromDb(reqBody)
            .then((response) => {
                if (response && response.data) {
                    setAllOverrideData({ ...response.data });
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    /*  useEffect(() => {
        refreshData();
    }, []); */

    useEffect(() => {
        validateNewOverrideDialog();
    }, [
        openOverrideName,
        openOverrideRuleType,
        openOverrideRuleStatus,
        openOverrideRuleDtTimeType,
    ]);

    useEffect(() => {
        refreshData();
    }, [restauHook.restauId]);

    return {
        allOverrideData,
        setAllOverrideData,
        handleAddNewVisOverride,
        openVisOverrideData,
        setOpenVisOverrideData,
        openOverrideSameRuleType,
        setOpenOverrideSameRuleType,
        openOverrideRuleType,
        setOpenOverrideRuleType,
        openOverrideRuleStatus,
        setOpenOverrideRuleStatus,
        openOverrideRuleDtTimeType,
        setOpenOverrideRuleDtTimeType,
        openOverrideRuleDays,
        setOpenOverrideRuleDays,
        openOverrideRuleTimeStart,
        setOpenOverrideRuleTimeStart,
        openOverrideRuleTimeEnd,
        setOpenOverrideRuleTimeEnd,
        addNewOverrideRule,
        openOverrideName,
        setOpenOverrideName,
        resetOpenVisOverrideData,
        openOverrideErrorStr,
        openOverrideHasError,
        computeTimeString,
        deleteDayDataFromRule,
        openNewOverrideDialog,
        setOpenNewOverrideDialog,
        handleEditVisOverride,
        openEditOverrideDialog,
        setOpenEditOverrideDialog,
        loading,
        setLoading,
    };
};

export default useVisOverride;
