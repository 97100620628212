import React from 'react';
import DaySlot from './DaySlot';
import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

function SlotItem(props) {
    const { startTime, endTime, label, index, disabled, onChange, onRemove } =
        props;
    const handleRemove = () => {
        onRemove(index);
    };
    const handleChange = (date, id) => {
        onChange(date, index, id);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <DaySlot
                startTime={startTime}
                endTime={endTime}
                label={label}
                index={index}
                disabled={disabled}
                onChange={handleChange}
            />
            <IconButton
                aria-label="delete time slot"
                component="span"
                size="small"
                onClick={handleRemove}
                disabled={disabled}
            >
                <Close />
            </IconButton>
        </div>
    );
}

SlotItem.propTypes = {};
SlotItem.defaultProps = {
    startTime: null,
    endTime: null,
};

export default SlotItem;
