import { Grid, Typography } from '@mui/material';
import React from 'react';
import InputField from '../../components/formControls/InputField';

import { useContext } from 'react';
import QRContext from '../QRContext';
import SQInputAdornment from '../../components/formControls/SQInputAdornment';

function TableSettings() {
    const {
        startTable,
        startTableError,
        endTable,
        endTableError,
        tablePrefix,
        tableSuffix,
        handleSettingChange,
    } = useContext(QRContext);

    const handleInputChange = (e) => {
        handleSettingChange(e.target.name, e.target.value);
    };

    const prefixHint =
        'If prefix="Patio-" you will get tables labelled asPatio-1, Patio-2, ...';
    const suffixHint =
        'If suffix="-Patio" you will get tables labelled as 1-Patio, 2-Patio, ...';

    return (
        <>
            <Typography variant="h6" component="h6" margin="default">
                Table Settings
            </Typography>

            <Grid container spacing={1} xs={12}>
                <Grid item xs={12}>
                    <Grid container spacing={1} direction="row">
                        <Grid item md={6}>
                            <InputField
                                id="start-table-number"
                                label="Start Table"
                                name="startTable"
                                type="number"
                                value={startTable}
                                error={startTableError}
                                helperText={startTableError}
                                placeholder="Start Table #"
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <InputField
                                id="end-table-number"
                                label="End Table"
                                name="endTable"
                                type="number"
                                value={endTable}
                                error={endTableError}
                                helperText={endTableError}
                                placeholder="End Table #"
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1} direction="row">
                        <Grid item md={6}>
                            <InputField
                                id="table-prefix"
                                label="Prefix"
                                name="tablePrefix"
                                type="text"
                                value={tablePrefix}
                                placeholder="Prefix"
                                onChange={handleInputChange}
                                InputProps={{
                                    endAdornment: (
                                        <SQInputAdornment>
                                            {prefixHint}
                                        </SQInputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <InputField
                                id="table-suffix"
                                label="Suffix"
                                name="tableSuffix"
                                type="text"
                                value={tableSuffix}
                                placeholder="Suffix"
                                onChange={handleInputChange}
                                InputProps={{
                                    endAdornment: (
                                        <SQInputAdornment>
                                            {suffixHint}
                                        </SQInputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

TableSettings.propTypes = {};

export default TableSettings;
