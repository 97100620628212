import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { formatPrice } from '../../Data/FoodData';
import AddEditChoiceDialog from './AddEditChoiceDialog';

function Choices(props) {
    const {
        openDialogToAddEditChoice,
        setOpenDialogToAddEditChoice,
        isModifier,
        openFood,
    } = props;

    const [foodItemChoice, setFoodItemChoice] = useState({});

    const removeChoice = () => {
        setFoodItemChoice({});
        let food = openFood.value.food;
        food.choiceslabel = '';
        food.choices = [];
        openFood.setValue({
            food: food,
            display: true,
            sectionId: openFood.value.sectionId,
        });
    };

    const handleAddOrEditChoice = () => {
        // if (foodItemChoice === null) {
        //     //set with default values
        //     setFoodItemChoice({
        //         choiceslabel: '',
        //         choices: [{ id: '1', name: '', price: 0 }],
        //     });
        // }
        setOpenDialogToAddEditChoice(true);
    };

    const handleFinalChoiceUpdate = (updatedFoodItemChoice) => {
        //debugger;
        let food = openFood.value.food;
        food.choiceslabel = updatedFoodItemChoice.choiceslabel;
        food.choices = updatedFoodItemChoice.choices;
        openFood.setValue({
            food: food,
            display: true,
            sectionId: openFood.value.sectionId,
        });
    };

    useEffect(() => {
        let curFoodItemChoice = null;
        if (
            openFood.value.food.choices &&
            openFood.value.food.choices.length > 0
        ) {
            curFoodItemChoice = {
                choiceslabel: openFood.value.food.choiceslabel,
                choices: [...openFood.value.food.choices],
            };
        }
        setFoodItemChoice(curFoodItemChoice);
    }, []);

    return (
        <Grid container spacing={0} justifyContent="space-around">
            <Grid item xs={5}>
                <Box>
                    <Typography variant="h6" component="h6">
                        Choices
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={7} sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                    sx={{
                        //alignSelf: 'flex-end',
                        border: 0,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={handleAddOrEditChoice}
                >
                    <AddCircleSharpIcon color="primary" sx={{ fontSize: 23 }} />
                    <Typography sx={{ ml: 0.5 }}>
                        {foodItemChoice &&
                        foodItemChoice.choices &&
                        foodItemChoice.choices.length > 0
                            ? 'Edit Choice'
                            : 'Add Choice'}
                    </Typography>
                </Button>
                <AddEditChoiceDialog
                    open={openDialogToAddEditChoice}
                    setOpen={setOpenDialogToAddEditChoice}
                    foodItemChoice={foodItemChoice}
                    setFoodItemChoice={setFoodItemChoice}
                    isModifier={isModifier}
                    handleFinalChoiceUpdate={handleFinalChoiceUpdate}
                />
            </Grid>
            <Grid item xs={12} row>
                {foodItemChoice &&
                    foodItemChoice.choices &&
                    foodItemChoice.choices.length > 0 && (
                        <Grid
                            container
                            justifyContent="space-around"
                            direction="row"
                        >
                            <Grid item xs={5}>
                                <Typography variant="h6" component="h6">
                                    {foodItemChoice.choiceslabel}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={7}
                                sx={{ display: 'flex', justifyContent: 'end' }}
                            >
                                <Button
                                    sx={{
                                        border: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    onClick={() => {
                                        removeChoice();
                                    }}
                                >
                                    <DeleteOutlineIcon sx={{ fontSize: 23 }} />
                                    <Typography sx={{ ml: 0.5 }}>
                                        Remove Choice
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <RadioGroup name="radio-buttons-group">
                                        {foodItemChoice.choices.map(
                                            (option) => (
                                                <FormControlLabel
                                                    key={option.id}
                                                    control={
                                                        <Radio
                                                            id={option.id}
                                                            name={option.name}
                                                            value={option.name}
                                                            checked={false}
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            variant="subtitle1"
                                                            component="span"
                                                        >
                                                            {option.name}
                                                            {option.price &&
                                                            option.price > 0
                                                                ? ' (+' +
                                                                  formatPrice(
                                                                      option.price /
                                                                          100
                                                                  ) +
                                                                  ') '
                                                                : ' '}
                                                        </Typography>
                                                    }
                                                />
                                            )
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}
            </Grid>
        </Grid>
    );
}

Choices.propTypes = {
    openDialogToAddEditChoice: PropTypes.bool,
    setOpenDialogToAddEditChoice: PropTypes.func,
    isModifier: PropTypes.bool,
    openFood: PropTypes.shape({
        value: PropTypes.shape({
            food: PropTypes.shape({
                choices: PropTypes.arrayOf(PropTypes.shape({})),
                choiceslabel: PropTypes.string,
                id: PropTypes.string,
                name: PropTypes.string,
            }),
            sectionId: PropTypes.string,
        }),
        setValue: PropTypes.func,
    }),
};

Choices.defaultProps = {};

export default Choices;
