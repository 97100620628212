import { useState } from 'react';

export function useModifiers(defaultModifiers) {
    const [value, setValue] = useState(convertToAnArray(defaultModifiers)); //{modifiers: [{modifier}]}

    function convertToAnArray(defaultModifiers) {
        if (defaultModifiers && defaultModifiers.modifiers) {
            const newModifierGrp = {
                ...defaultModifiers,
                modifiers: Object.values(defaultModifiers.modifiers).map(
                    (modifier) => {
                        return {
                            ...modifier,
                            options: modifier.options
                                ? Object.values(modifier.options)
                                : modifier.options,
                        };
                    }
                ),
            };
            return newModifierGrp;
        } else {
            return defaultModifiers;
        }
    }
    function optionOnChange() {
        let val = value;
        setValue({ ...val });
    }
    function changeRadio(modifierId, optionId) {
        const newModifierGrp = {
            ...value,
            modifiers: value.modifiers.map((modifier) => {
                if (modifier.id == modifierId) {
                    return {
                        ...modifier,
                        options: modifier.options.map((opt) => {
                            return { ...opt, checked: opt.id == optionId };
                        }),
                    };
                } else return modifier;
            }),
        };
        setValue(newModifierGrp);
    }

    function changeCheckBox(modifierId, optionId) {
        const newModifierGrp = {
            ...value,
            modifiers: value.modifiers.map((modifier) => {
                if (modifier.id == modifierId) {
                    return {
                        ...modifier,
                        options: modifier.options.map((opt) => {
                            return opt.id == optionId
                                ? { ...opt, checked: !opt.checked }
                                : opt;
                        }),
                    };
                } else return modifier;
            }),
        };
        setValue(newModifierGrp);
    }
    return {
        value,
        setValue,
        optionOnChange,
        changeRadio,
        changeCheckBox,
    };
}
