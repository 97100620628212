import styled from 'styled-components';
import { Title } from './Styles/title';

export const FoodGrid = styled.div`
    @media (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
    @media (max-width: 500px) {
        grid-template-columns: 1fr;
        gap: 10px;
    }
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding-bottom: 20px;
`;

// ${props => props.img !== undefined && `
//   position: absolute;
//   justify-content: space-between;
// `}

export const FoodLabel = styled(Title)`
    ${(props) =>
        props.img !== undefined
            ? `position: absolute;`
            : `justify-content: space-between;   display: flex;`}

    ${(props) =>
        props.img !== undefined &&
        `background-color: rgba(255, 255, 255, 0.75);`}
  
    padding: 5px;
    align-items: start;
`;

export const FoodLabelLeft = styled(Title)`
    display: flex;
    gap: 10px;
    ${(props) =>
        props.img !== undefined &&
        `background-color: rgba(255, 255, 255, 0.75);`}

    padding: 5px;
    align-items: start;
`;

export const Food = styled.div`
  ${(props) =>
      props.img !== undefined &&
      `
  height : 100px;
  `}
 padding: 7px 0px; 
 font-size: 20px; 
 background-image: ${({ img }) => `url(${img});`} 
 background-position: center;
 background-size: cover;
 filter: contrast(90%); 
 border-radius: 7px; 
 margin-top: 5px; 
 transition-property: box-shadow margin-top filter; 
 transition-duration: .1s; 
 box-shadow: 0px 0px 1px 1px #f2f2f2;
 &:hover {
  cursor: pointer; 
  filter: contrast(100%); 
  margin-top: 0px; 
  margin-bottom: 5px; 
  box-shadow: 0px 5px 10px 0px #f2f2f2;
 }
`;

export const FoodContainer = styled.div`
  padding: 7px 0px;
  border-bottom: 1px solid rgb(39,174,96,0.5);
  background-color: ${(props) => (props.oos ? '#c0392b11' : 'white')}
    &:hover {
      cursor: pointer;
      background-color: ${(props) => (props.oos ? '#c0392b11' : '#f8f8f8')};
    };
  cursor: pointer;
`;

export const ImageDetails = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
`;

export const Description = styled.div`
    color: gray;
    font-size: 13px;
    margin-top: 3px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const PriceContainer = styled.div`
    display: flex;
`;
export const Price = styled.span`
    // padding-left: 10px;
    padding-top: 5px;
    font-size: 15px;
`;

export const HigherStrikePrice = styled.span`
    margin-left: 10px;
    padding-top: 5px;
    font-size: 15px;
    color: red;
    text-decoration: line-through;
`;

export const FoodImage = styled.img`
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 5%;
`;
