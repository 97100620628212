import { useState, useContext } from 'react';
import { RestauInfoContext } from '../home/RestauInfoContext.js';
import { getUUID } from '../Menu/Utils';

const findSectionIndex = (sections, sectionId) => {
    return sections.findIndex((section) => section && section.id === sectionId);
};

const findFoodIndex = (foods, foodId) => {
    return foods.findIndex((food) => food.id === foodId);
};

const saveMenuConfig = (restauId, menu) => {
    if (!restauId) {
        return;
    }
    const database = window.customerApp.database();
    const location = `menus/${restauId}/menu`;
    const updates = {
        [location]: menu,
    };
    return database.ref().update(updates);
};

export function useMenu(defaultChoice) {
    const [value, setValue] = useState({});
    const { restauHook } = useContext(RestauInfoContext);
    const restauId = restauHook && restauHook.restauId;

    // Food
    // - delete
    // - add
    // - edit
    // - reorder

    // Section
    // - delete
    // - add
    // - edit
    // - reorder

    const deleteFoodItem = (foodId, sectionId) => {
        const secIndex = findSectionIndex(value.menu, sectionId);
        const section = {
            ...value.menu[secIndex],
        };
        const foodIndex = findFoodIndex(section.foods, foodId);
        section.foods = [
            ...section.foods.slice(0, foodIndex),
            ...section.foods.slice(foodIndex + 1),
        ];
        const newMenu = [
            ...value.menu.slice(0, secIndex),
            section,
            ...value.menu.slice(secIndex + 1),
        ];

        return setUpdatedMenu(newMenu);
    };

    const getNewSection = (name = '') => {
        return {
            id: getUUID(),
            name: name,
            foods: [],
        };
    };

    const setUpdatedMenu = (menu) => {
        //debugger;
        const newValue = {
            ...value,
        };
        newValue.menu = [...menu];
        setValue({ ...newValue });
        return saveMenuConfig(restauId, menu);
    };

    const addSectionAfter = (sectionId, sectionName) => {
        const index = findSectionIndex(value.menu, sectionId);
        const section = getNewSection(sectionName);

        const menu = value.menu;
        const newMenu = [
            ...menu.slice(0, index + 1),
            section,
            ...menu.slice(index + 1),
        ];
        return setUpdatedMenu(newMenu);
    };

    const updateSectionName = (sectionId, sectionName) => {
        const menu = value.menu;
        const index = findSectionIndex(menu, sectionId);
        const section = {
            ...menu[index],
        };
        section.name = sectionName;
        const newMenu = [
            ...menu.slice(0, index),
            section,
            ...menu.slice(index + 1),
        ];
        return setUpdatedMenu(newMenu);
    };

    const updateStationsAtSection = (sectionId, stations) => {
        //debugger;

        const menu = value.menu;
        const index = findSectionIndex(menu, sectionId);
        const section = {
            ...menu[index],
        };
        if (stations.length === 0) {
            delete section.station;
        } else {
            section.station = stations;
        }

        const newMenu = [
            ...menu.slice(0, index),
            section,
            ...menu.slice(index + 1),
        ];
        return setUpdatedMenu(newMenu);
    };

    const updateVisOverrideAtSection = (sectionId, visOverrideId) => {
        const menu = value.menu;
        const index = findSectionIndex(menu, sectionId);
        const section = {
            ...menu[index],
        };
        if (visOverrideId) {
            section.visiblityOverrideId = visOverrideId;
        } else {
            delete section.visiblityOverrideId;
        }
        /* 
        if (!visOverrideId) {
            section.visiblityOverrideId = null;
        } */

        const newMenu = [
            ...menu.slice(0, index),
            section,
            ...menu.slice(index + 1),
        ];
        return setUpdatedMenu(newMenu);
    };

    const deleteSection = (sectionId) => {
        const index = findSectionIndex(value.menu, sectionId);
        const newMenu = [
            ...value.menu.slice(0, index),
            ...value.menu.slice(index + 1),
        ];

        return setUpdatedMenu(newMenu);
    };

    const refreshData = async () => {
        //setLoading(true);
        if (!window.customerApp.auth().currentUser) {
            //debugger;
            navigate('/login');
            return;
        }

        const admin = window.customerApp.database();
        let menuRef = await admin.ref('menus/' + restauHook.restauId);
        const menu = await menuRef
            .once('value')
            /*  .then(function (snapshot) {
                let result = snapshot.val();
                //Below code is hack. as all modifiers didnt have the name field earlier.
                if (result && result.modifiers) {
                    for (let mid in result.modifiers) {
                        if (!result.modifiers[mid].name) {
                            result.modifiers[mid].name =
                                result.modifiers[mid].label;
                        }
                    }
                }
                setValue(result);
                return result;
            }) */
            .catch(function (error) {
                console.log(error);
            });

        let result = menu.val();
        console.log('Menu Refresh - Result: ', result);
        setValue(result);
        return result;
    };

    return {
        value,
        setValue,
        addSectionAfter,
        updateSectionName,
        deleteSection,
        refreshData,
        deleteFoodItem,
        updateStationsAtSection,
        updateVisOverrideAtSection,
    };
}
