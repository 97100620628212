import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((them) => ({
    appBar: {
        background: '#222C31',
        height: '10vh',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mobileImage: {
        height: '200px',
        width: '180px',
        display: 'block',
        margin: 'auto',
    },
    needHelp: {
        fontFamily: 'TT Norms Pro',
        fontSize: '12px',
        lineHeight: '143%',
        paddingTop: '5px',
        color: 'rgba(34, 44, 49, 0.6)',
    },
    homeimage: {
        [them.breakpoints.down('sm')]: {
            display: 'none',
        },

        background: '#222C31',
        height: '100vh',
    },
    leftGrid: {
        [them.breakpoints.down('sm')]: {
            padding: '5%',
        },

        padding: '20%',
    },
    textFiled: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: '#7777F7',
            },
        },
        '& label.Mui-focused': {
            color: '#7777F7',
        },
        fontFamily: 'TT Norms Pro',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: 'rgba(34, 44, 49, 0.6)',
        borderColor: 'red',
    },
    subTitle: {
        /* H5/ 20 px */

        fontFamily: 'TT Norms Pro',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '17px',
        lineHeight: '133.4%',
        /* identical to box height, or 27px */

        color: 'rgba(34, 44, 49, 0.6)',
    },
    submitButton: {
        justifyContent: 'center',
        alignItems: 'center',

        background: '#7777F7',
        backgroundColor: '#7777F7',
        '&:hover': {
            backgroundColor: '#7777F7',
        },
        boxShadow: '2px 2px 12px rgba(0, 0, 0, 0.2)',
    },
    titleStyle: {
        boxSizing: 'border-box',
        color: 'red',
        width: 300,
        height: 200,
    },
    typoGraphy: {
        fontFamily: 'TT Norms Pro',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '30px',
        lineHeight: '123.5%',
        letterSpacing: '0.3px',
        color: '#222C31',
    },
}));
