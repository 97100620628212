import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import React from 'react';
import ReactDOM from 'react-dom';
import AppRoutes from './AppRoutes';
import { initApp } from './Firebase';
import theme from './theme';

initApp();
ReactDOM.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                {/* <RestaurantProvider> */}
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <AppRoutes />
                {/* </RestaurantProvider> */}
            </LocalizationProvider>
        </ThemeProvider>
    </StyledEngineProvider>,
    document.querySelector('#root')
);
