import React from 'react';
import { Outlet } from 'react-router-dom';
import useQRTemplates from '../useQRTemplates';
import QRContext from '../QRContext';
import QRDesign from '../components/QRDesign';

function QRRoot() {
    const qrTemplates = useQRTemplates();

    return (
        <QRContext.Provider value={qrTemplates}>
            <QRDesign>
                <Outlet />
            </QRDesign>
        </QRContext.Provider>
    );
}

QRRoot.propTypes = {};

export default QRRoot;

// TODOs:
// How To - create a 2-minute short video and provide a YouTube link
