import React from 'react';
import { Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import DialogWithContent from '../../../../components/common/DialogWithContent/DialogWithContent';

function ConfirmationDialog(props) {
    const {
        showConfirmationDialog,
        setShowConfirmationDialog,
        handleConfirm,
        handleClose,
        dialogMsg,
    } = props;
    return (
        <DialogWithContent
            open={showConfirmationDialog}
            dialogTitle={'Confirmation'}
            setOpen={setShowConfirmationDialog}
            dialogMaxWidth="xs"
            dialogCloseBtnText={'Cancel'}
            dialogSaveBtnText={'OK'}
            handleSave={handleConfirm}
            handleClose={handleClose}
        >
            <Typography>{dialogMsg}</Typography>
        </DialogWithContent>
    );
}

ConfirmationDialog.propTypes = {
    showConfirmationDialog: PropTypes.bool,
    setShowConfirmationDialog: PropTypes.func,
    handleConfirm: PropTypes.func,
    handleClose: PropTypes.func,
    dialogMsg: PropTypes.string,
};

export default ConfirmationDialog;
