import React from 'react';
import { Chip } from '@mui/material';

function FoodHidden() {
    return (
        <Chip
            color="error"
            label="Hidden"
            sx={{
                color: 'Pink',
                fontSize: '15px',
                height: '20px',
                '& .MuiChip-label': {
                    padding: '5px',
                },
            }}
        />
    );
}

FoodHidden.propTypes = {};

export default FoodHidden;
