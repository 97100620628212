import React from 'react';
import { formatPrice } from '../Data/FoodData';
import { getQuatity } from './ModifierUtil';
import FoodHidden from '../FoodTemplates/FoodHidden';
import FoodOOS from '../FoodTemplates/FoodOOS';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';

export default function ModifierRadio({ modifier, onChange, removeModifier }) {
    return (
        <Grid container justifyContent="space-between" direction="row">
            <Grid item xs={7}>
                <Typography variant="h6" component="h6">
                    {modifier.label}
                    <Typography component="subtitle1" sx={{ ml: 1 }}>
                        (Select 1)
                    </Typography>
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <Button
                    sx={{
                        border: 0,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={(e) => {
                        removeModifier(modifier);
                    }}
                >
                    <DeleteOutlineIcon sx={{ fontSize: 23 }} />
                    <Typography sx={{ ml: 0.5 }}>Remove Modifier</Typography>
                </Button>
            </Grid>
            <Grid item xs={12}>
                <FormControl>
                    <RadioGroup name="radio-buttons-group">
                        {modifier.options.map((option, i) => (
                            <FormControlLabel
                                key={option.id}
                                control={
                                    <Radio
                                        id={option.id}
                                        name={modifier.id}
                                        value={option.label}
                                        checked={
                                            option.checked
                                                ? option.checked
                                                : false
                                        }
                                    />
                                }
                                label={
                                    <Typography
                                        variant="subtitle1"
                                        component="span"
                                    >
                                        {option.label}
                                        {option.price && option.price > 0
                                            ? ' (+' +
                                              formatPrice(
                                                  (option.price *
                                                      getQuatity(
                                                          option.quantity
                                                      )) /
                                                      100
                                              ) +
                                              ') '
                                            : ' '}
                                        {option.hide ? <FoodHidden /> : ''}
                                        {option.oos ? <FoodOOS /> : ''}
                                    </Typography>
                                }
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
                {/* {modifier.options.map((option, i) => (
                    <>
                        <label
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '5px',
                            }}
                        >
                            <RadioInput
                                type="radio"
                                id={option.id}
                                name={modifier.id}
                                value={option.label}
                                checked={
                                    option.checked ? option.checked : false
                                }
                            />
                            <span>
                                {option.label}
                                {option.price && option.price > 0 ? (
                                    ' (+' +
                                    formatPrice(
                                        (option.price *
                                            getQuatity(option.quantity)) /
                                            100
                                    ) +
                                    ') '
                                ) : (
                                    <></>
                                )}
                                {option.hide ? <FoodHidden /> : ''}
                                {option.oos ? <FoodOOS /> : ''}
                            </span>
                        </label>
                    </>
                ))} */}
            </Grid>
        </Grid>
    );
}
