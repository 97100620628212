import React, { useState } from 'react';
import Config from '../../config/Config';
import download from '../../utils/downloadExcel';
import { Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { isNumber, isString } from 'lodash';
//import { updateMenuItemsWithPrice } from '../../../functions/src/api/bulkPricingUpdateAPI';
import PreviewDialogNew from './PreviewDialogNew';

export default function ExcelHandling(props) {
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    // TODO : below code still not working properly
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');

    const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
    const [pricesToUpdate, setPricesToUpdate] = useState([]);
    const [excelIssueList, setExcelIssueList] = useState([]);

    const [addedFoodItems, setAddedFoodItems] = useState([]);
    const [deletedFoodItems, setDeletedFoodItems] = useState([]);
    const [updatedFoodItems, setUpdatedFoodItems] = useState([]);

    const [updatedMenuItemDataFromServer, setUpdatedMenuItemDataFromServer] =
        useState({});

    //let excelIssueList = [];
    //let pricesToUpdate = [];
    let reportNamePrefix = 'MenuItemsWithPrice_';
    let menuItemDataFromServer;
    //var updatedMenuItemDataFromServer = {};

    //to export the data related to all the items from all the orders
    async function exportMenuItemsWithPriceToExcel() {
        console.log('Calling server to get data.');
        setLoading((prevstatus) => !prevstatus);
        setIsError(false);
        let dataToExport;
        dataToExport = await getMenuItemsWithPrice(props.restauId);
        if (dataToExport === undefined) {
            //display error
            setLoading(false);
            setIsError(true);
        }
        menuItemDataFromServer = dataToExport;
        download.excelDownload(
            Object.values(dataToExport).map((x) => {
                //x.newPrice = '';
                return x;
            }),
            reportNamePrefix +
                moment(new Date()).format('DD-MM-YYYY_hhmmss') +
                '.xlsx',
            'MenuItems'
        );
        setLoading(false);
        setIsError(false);
    }

    async function getMenuItemsWithPrice(restauId) {
        const token = await window.customerApp.auth().currentUser.getIdToken();
        let config = {
            headers: {
                token: token,
                'Content-Type': 'application/json',
            },
        };
        let reqBody = {
            restauId: restauId,
        };

        let result = await axios
            .post(Config.getMenuWithPrice, reqBody, config)
            .catch(function (error) {
                console.log(error);
                return undefined;
            });
        //console.log('Response: ' + JSON.stringify(result.data));
        return result.data;
    }

    //read the data from excel
    async function extractUpdatedPricesFromExcel(e) {
        setLoading((prevstatus) => !prevstatus);
        setIsError(false);
        console.log(e.files[0]);
        setPricesToUpdate([]);
        setExcelIssueList([]);
        //read the data from excel
        await download.readExcelData(e.files[0], showPreviewWithUpdates);
    }

    function AreObjectsMatching(sourceObj, destObj) {
        if (Object.keys(sourceObj).length !== Object.keys(destObj).length)
            return false;
        return Object.keys(sourceObj).every((key) => {
            //debugger;
            let sourceData, destData;
            if (isString(sourceObj[key]))
                sourceData = sourceObj[key]
                    .replace('\r', '')
                    .replace('\n', '')
                    .replace('"', '')
                    .trim();
            else sourceData = sourceObj[key];

            if (isString(destObj[key]))
                destData = destObj[key]
                    .replace('\r', '')
                    .replace('\n', '')
                    .replace('"', '')
                    .trim();
            else destData = destObj[key];

            if (sourceData !== destData) return false;
            else return true;
        });
    }

    //look for updates in excel and process the data.
    async function showPreviewWithUpdates(dataFromUploadedExcel) {
        //debugger;
        console.log('Callback called with data.');
        //console.log(dataFromUploadedExcel);

        if (dataFromUploadedExcel === undefined) {
            //throw error
        }

        //handle validations here
        //1. check for null values
        if (
            dataFromUploadedExcel.some(
                (x) =>
                    x.categoryName === '' ||
                    x.foodName === '' ||
                    x.price === '' ||
                    !isNumber(x.price)
            )
        ) {
            //throw error
        }

        let tempData = {};
        if (menuItemDataFromServer === undefined) {
            menuItemDataFromServer = await getMenuItemsWithPrice(
                props.restauId
            );
            Object.keys(menuItemDataFromServer).forEach((key) => {
                let temp = key.split('_');
                let updatedKey =
                    temp[1]
                        .replace('\r', '')
                        .replace('\n', '')
                        .replace('"', '')
                        .trim() +
                    '_' +
                    temp[3]
                        .replace('\r', '')
                        .replace('\n', '')
                        .replace('"', '')
                        .trim();
                tempData[updatedKey] = menuItemDataFromServer[key];
            });
        }

        if (Object.keys(tempData).length === 0) {
            //throw error
        }

        let updatedDataFromExcel = {};
        dataFromUploadedExcel.forEach((item) => {
            let key =
                item.categoryName
                    .replace('\r', '')
                    .replace('\n', '')
                    .replace('"', '')
                    .trim() +
                '_' +
                item.foodName
                    .replace('\r', '')
                    .replace('\n', '')
                    .replace('"', '')
                    .trim();
            updatedDataFromExcel[key] = item;
        });

        let priceUpdateList = [];
        let tempExcelIssuesList = [];
        let addedItems = [];
        let deletedItems = [];
        let updatedItems = [];
        console.log('updatedDataFromExcel: ', updatedDataFromExcel);
        console.log('updatedMenuItemDataFromServer: ', tempData);
        //let itemKeysFromServerData = Object.keys(menuItemDataFromServer);
        Object.keys(updatedDataFromExcel).forEach((key) => {
            //console.log('processing key: ', key);
            let item = updatedDataFromExcel[key];
            //let itemNewPriceFromExcel = item.price && item.price.toFixed(2);

            // seggregate the data based on New, Delete & Updates
            // check for adds
            if (tempData[key] !== undefined) {
                let res = AreObjectsMatching(tempData[key], item);
                if (!res) {
                    //console.log('item updated : ', key);
                    updatedItems.push(item);
                }
            } else {
                //console.log('Item added: ', key);
                addedItems.push(item);
            }
        });

        //look for deleted items
        Object.keys(tempData).forEach((key) => {
            if (updatedDataFromExcel[key] === undefined) {
                //console.log('item deleted: ', key);
                deletedItems.push(tempData[key]);
            }
        });

        console.log('Added Items', addedItems);
        console.log('Deleted Items', deletedItems);
        console.log('Updated Items', updatedItems);

        if (
            addedItems.length === 0 &&
            deletedItems.length === 0 &&
            updatedItems.length === 0
        ) {
            //throw error
        }

        setAddedFoodItems(addedItems);
        setDeletedFoodItems(deletedItems);
        setUpdatedFoodItems(updatedItems);

        setUpdatedMenuItemDataFromServer((prevVal) => {
            return { ...prevVal, ...tempData };
        });

        setLoading(false);
        setIsError(false);
        //display the Preview dialog
        setOpenPreviewDialog(true);

        setPricesToUpdate([...priceUpdateList]);
        setExcelIssueList([...tempExcelIssuesList]);
    }

    return (
        <>
            <Button
                //onClick={async () => await exportMenuItemsWithPriceToExcel()}
                onClick={exportMenuItemsWithPriceToExcel}
                size="small"
                color="primary"
                variant="contained"
            >
                {'Download to Excel'}
            </Button>
            <Button
                component="label"
                htmlFor="export-menu"
                variant="contained"
                color="primary"
                size="small"
                //component="span"
                sx={{ ml: 1 }}
            >
                {'Upload from Excel'}
                <input
                    accept=".xlsx"
                    style={{ display: 'none' }}
                    id="export-menu"
                    type="file"
                    //onClick={(e) => (e.target = null)}
                    onChange={(e) => {
                        extractUpdatedPricesFromExcel(e.target);
                        e.target.value = null;
                    }}
                />
            </Button>
            {loading && <CircularProgress color="secondary" size={30} />}
            {isError && (
                <p
                    style={{
                        color: 'red',
                        fontWeight: 'normal',
                        fontSize: '0.85rem',
                    }}
                >
                    {errorMsg}
                </p>
            )}
            {successMsg && (
                <p
                    style={{
                        color: 'green',
                        fontWeight: 'normal',
                        fontSize: '0.85rem',
                    }}
                >
                    {successMsg}
                </p>
            )}
            {/* <PreviewDialog
                //open={openPreviewDialog}
                setDialogOpen={setOpenPreviewDialog}
                dialogOpen={openPreviewDialog}
                addedFoodItems={addedFoodItems}
                updatedFoodItems={updatedFoodItems}
                deletedFoodItems={deletedFoodItems}
                pricesToUpdate={pricesToUpdate}
                excelIssueList={excelIssueList}
                restauId={props.restauId}
                refreshMenu={props.refreshMenu}
            /> */}

            <PreviewDialogNew
                //open={openPreviewDialog}
                setDialogOpen={setOpenPreviewDialog}
                dialogOpen={openPreviewDialog}
                addedFoodItems={addedFoodItems}
                updatedFoodItems={updatedFoodItems}
                deletedFoodItems={deletedFoodItems}
                updatedMenuItemDataFromServer={updatedMenuItemDataFromServer}
                pricesToUpdate={pricesToUpdate}
                excelIssueList={excelIssueList}
                restauId={props.restauId}
                refreshMenu={props.refreshMenu}
                setSuccessMsg={setSuccessMsg}
                setErrorMsg={setErrorMsg}
            />
        </>
    );
}
