import React from 'react';
import { Grid, Box, Typography, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const Field = ({ field, handleEdit, handleDelete }) => {
    return (
        <Grid item xs={12}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '10%',
                    width: '100%',
                    height: '80%',
                    backgroundColor: 'neutral1.main',
                    borderBottom: 1,
                    borderColor: 'divider',
                }}
            >
                <Grid container>
                    {field.enable_field ? (
                        <CheckCircleIcon
                            sx={{ color: 'secondary.main', ml: 2 }}
                        />
                    ) : (
                        <CancelIcon sx={{ color: '#2F2440', ml: 2 }} />
                    )}
                    <Typography sx={{ ml: 2 }}>{field.field_label}</Typography>
                    {field.validation.required ? (
                        <Typography
                            color="primary.main"
                            sx={{ ml: 1, fontSize: 12 }}
                        >
                            Required
                        </Typography>
                    ) : (
                        <></>
                    )}
                </Grid>

                <Grid
                    item
                    container
                    direction="row"
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                    <Grid item>
                        {!field.is_system && (
                            <IconButton
                                onClick={handleDelete}
                                color="secondary"
                                sx={{ padding: 0.2, mr: 5 }}
                            >
                                <RemoveCircleIcon sx={{ fontSize: 18 }} />
                                <Typography> Delete </Typography>
                            </IconButton>
                        )}
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={handleEdit}
                            color="primary"
                            sx={{ padding: 0.2, mr: 5 }}
                        >
                            <EditIcon sx={{ fontSize: 18 }} />
                            <Typography> Edit </Typography>
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <br />
        </Grid>
    );
};

export default Field;
