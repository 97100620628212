import React from 'react';
import { Outlet } from 'react-router';
import CateringContext from '../CateringContext';
import useCatering from '../useCatering';
import CateringContent from '../Components/CateringContent';

function CateringRoot() {
    const cateringHook = useCatering();

    return (
        <CateringContext.Provider value={cateringHook}>
            <CateringContent>
                <Outlet />
            </CateringContent>
        </CateringContext.Provider>
    );
}

export default CateringRoot;
