import { Button } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { RestauInfoContext } from '../home/RestauInfoContext.js';
import { useStyles } from '../home/styles/useStyles';
import { useLoader } from '../Hooks/useLoader';
import { useOpenFood } from '../Hooks/useOpenFood';
import { useProfile } from '../Hooks/useProfile';
import { useNumberInput } from '../Menu/hooks/useNumberInput.js';
import { corianderGreen } from '../tax/Styles/colors';
import { Title } from '../tax/Styles/title';

export const ConfirmButton = styled(Title)`
    margin: 10px;
    color: white;
    font-weight: bold;
    height: 40px;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    width: 200px;
    cursor: pointer;
    background-color: ${corianderGreen};
    ${({ disabled }) =>
        disabled &&
        `
    opactity: .5; 
    background-color: grey; 
    pointer-events: none; 
  `}
`;
//Collapsible trigger={sectionName}

async function loadData(
    restauHook,
    profileHook,
    serviceCharge,
    minCreditCardAmt,
    minCreditCardFees
) {
    if (!window.customerApp.auth().currentUser) {
        navigate('/login');
        return;
    }
    const token = await window.customerApp.auth().currentUser.getIdToken();

    let config = {
        headers: {
            token: token,
        },
    };
    const admin = window.customerApp.database();
    let allData = admin.ref('menus/' + restauHook.restauId);

    const data = await allData
        .once('value')
        .then(function (snapshot) {
            let result = snapshot.val();
            profileHook.setValue({ profile: result });
            if (result && result.payments) {
                if (result.payments.serviceCharge) {
                    serviceCharge.setValue(result.payments.serviceCharge);
                }
                if (result.payments.minCreditCardAmt) {
                    minCreditCardAmt.setValue(
                        result.payments.minCreditCardAmt / 100
                    );
                }
                if (result.payments.minCreditCardFees) {
                    minCreditCardFees.setValue(
                        result.payments.minCreditCardFees / 100
                    );
                }
            }
            return result;
        })
        .catch(function (error) {
            console.log(error);
        });
    console.log(data);

    return;
}

function onMinimumCreditCardAmtChange(e, profileHook) {
    let val = profileHook.value.profile;
    val.payments.minCreditCardAmt = Math.round(e.target.value * 100);
    console.log(val.payments);
    profileHook.setValue({ profile: val });
}

function onMinimumCreditCardFeeChange(e, profileHook) {
    let val = profileHook.value.profile;
    val.payments.minCreditCardFees = Math.round(e.target.value * 100);
    profileHook.setValue({ profile: val });
}

export default function Profile() {
    const resauInfoContext = useContext(RestauInfoContext);
    const restauHook = resauInfoContext.restauHook;

    const profileHook = useProfile();
    const openFood = useOpenFood();
    const classes = useStyles();
    const loader = useLoader();
    const [loaded, setLoaded] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const serviceCharge = useNumberInput({ min: 0 });
    const minCreditCardAmt = useNumberInput({ min: 0 });
    const minCreditCardFees = useNumberInput({ min: 0 });

    console.log(profileHook);

    useEffect(() => {
        console.log('**********Profile Reload**********************');
        loadData(
            restauHook,
            profileHook,
            serviceCharge,
            minCreditCardAmt,
            minCreditCardFees
        );
    }, [restauHook.restauId]);
    function getPrice(price) {
        if (!price) {
            return price;
        }
        let p = parseFloat(price) / 100;
        return p.toFixed(2);
    }

    async function updateProfile(
        restauHook,
        serviceCharge,
        minCreditCardAmt,
        minCreditCardFees,
        setLoaded
    ) {
        let updates = {};
        const database = window.customerApp.database();
        setLoaded(true);
        updates['menus/' + restauHook.restauId + '/payments/minCreditCardAmt'] =
            Math.round(minCreditCardAmt.value * 100);
        updates[
            'menus/' + restauHook.restauId + '/payments/minCreditCardFees'
        ] = Math.round(minCreditCardFees.value * 100);
        updates['menus/' + restauHook.restauId + '/payments/serviceCharge'] =
            serviceCharge.value;
        console.log(updates);
        await database
            .ref()
            .update(updates)
            .then(function () {
                alert('Updated Successfully !!');
            })
            .catch(function (error) {
                alert('Error while saving please try again');
                //Need to pop error someways
                console.log(error);
            });
        setLoaded(false);
    }

    const profile = profileHook.value.profile;
    return (
        <div>
            {!profile ? (
                <div>
                    <img
                        className={classes.loadingImage}
                        src="../../img/spinner.gif"
                    />
                </div>
            ) : (
                <>
                    <div style={{ maxWidth: 350 }}>
                        <TextField
                            id="name"
                            label="Restaurant Name"
                            size="small"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={profile.restaurantName}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />

                        <TextField
                            id="phone"
                            label="Phone Number"
                            size="small"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={profile.restaurantPhoneNumber}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />

                        <TextField
                            id="restaurantAddress"
                            name="restaurantAddress"
                            label="Address"
                            size="small"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            multiline
                            minRows={3}
                            value={profile.restaurantAddress.replace(
                                /,\s{0,}/g,
                                '\n'
                            )}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <TextField
                            type="number"
                            id="minCreditCardAmt"
                            label="Minimum Credit Card Amount"
                            size="small"
                            variant="outlined"
                            // fullWidth
                            margin="normal"
                            value={minCreditCardAmt.value}
                            onChange={minCreditCardAmt.onChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        $
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <TextField
                            type="number"
                            id="minCreditCardFees"
                            label="Fee if below Minimum Credit Card Amount"
                            size="small"
                            variant="outlined"
                            // fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={minCreditCardFees.value}
                            onChange={minCreditCardFees.onChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        $
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <TextField
                            type="number"
                            id="serviceCharge"
                            label="FSR Service Charge"
                            size="small"
                            variant="outlined"
                            margin="normal"
                            value={serviceCharge.value}
                            onChange={serviceCharge.onChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        %
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <div>
                            {loaded === true ? (
                                <div>
                                    <img
                                        height="75px"
                                        className={classes.loadingImage}
                                        src="../../img/spinner.gif"
                                    />
                                </div>
                            ) : (
                                <Button
                                    onClick={() => {
                                        updateProfile(
                                            restauHook,
                                            serviceCharge,
                                            minCreditCardAmt,
                                            minCreditCardFees,
                                            setLoaded
                                        );
                                    }}
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                >
                                    Update
                                </Button>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

{
    /* <TextField
                            type="number"
                            id="deliveryThreshold"
                            label="Delivery Threshold"
                            size="small"
                            variant="outlined"
                            // fullWidth
                            margin="normal"
                            value={getPrice(profile.payments.deliveryThreshold)}
                            onChange={(e) => {
                                // onMinimumCreditCardFeeChange(e, profileHook);
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        $
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <TextField
                            type="number"
                            id="deliveryFees"
                            label="Delivery Fees"
                            size="small"
                            variant="outlined"
                            // fullWidth
                            margin="normal"
                            value={getPrice(profile.payments.deliveryFees)}
                            onChange={(e) => {
                                onMinimumCreditCardFeeChange(e, profileHook);
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        $
                                    </InputAdornment>
                                ),
                            }}
                        /> */
}
