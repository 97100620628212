import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import Config from '../config/Config';
import { RestauInfoContext } from '../home/RestauInfoContext.js';
import { useStyles } from '../home/styles/useStyles';

const { default: axios } = require('axios');

const ControlsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
`;

export default function Tax(props) {
    // const restauHook = props.restauHook;
    const resauInfoContext = useContext(RestauInfoContext);
    const restauHook = resauInfoContext.restauHook;
    const [salesTax, setSalesTax] = React.useState(undefined);
    const [loaded, setLoaded] = React.useState(false);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const classes = useStyles();

    async function updateSalesTax(restauHook) {
        let updates = {};
        const database = window.customerApp.database();
        setLoaded(true);
        updates['menus/' + restauHook.restauId + '/payments/salesTax'] =
            salesTax;
        console.log(updates);
        await database
            .ref()
            .update(updates)
            .then(function () {})
            .catch(function (error) {
                alert('Error while saving please try again');
                //Need to pop error someways
                console.log(error);
                setLoaded(false);

                return;
            });
        setLoaded(false);
    }

    function salesTaxChange(e) {
        setSalesTax(e.target.value);
    }
    function getPreviousMonthName() {
        const current = new Date();
        current.setMonth(current.getMonth() - 1);
        const previousMonth = current.toLocaleString('default', {
            month: 'long',
        });
        return previousMonth;
    }
    async function downloadSalesTax(restauHook) {
        setLoaded(true);
        const token = await window.customerApp.auth().currentUser.getIdToken();
        let config = {
            headers: {
                token: token,
            },
        };
        axios
            .post(
                Config.downloadSalesTax,
                {
                    restauId: restauHook.restauId,
                    startDate: startDate,
                    endDate: endDate,
                },
                config
            )
            .then((response) => {
                console.log(response.data);
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'salesTax-' +
                        restauHook.restauId +
                        '-' +
                        getPreviousMonthName() +
                        '.csv'
                ); //or any other extension
                document.body.appendChild(link);
                link.click();
                setLoaded(false);
            })
            .catch(function (error) {
                setLoaded(false);
                console.log(error);
            });
    }
    async function loadData(restauHook) {
        if (!window.customerApp.auth().currentUser) {
            navigate('/login');
            return;
        }
        const token = await window.customerApp.auth().currentUser.getIdToken();

        let config = {
            headers: {
                token: token,
            },
        };
        console.log('Restau Hook from restaurant: ' + restauHook.restauId);
        const admin = window.customerApp.database();
        let menuRef = admin.ref(
            'menus/' + restauHook.restauId + '/payments/salesTax'
        );
        setLoaded(false);

        const menus = await menuRef
            .once('value')
            .then(function (snapshot) {
                let result = snapshot.val();
                //menuHook.setValue({menu:result});
                console.log('Sales tax' + result);
                if (result) {
                    console.log('setting value');
                    setSalesTax(result);
                }
                setLoaded(false);

                console.log(salesTax);
                return result;
            })
            .catch(function (error) {
                setLoaded(false);
                console.log(error);
            });
        setLoaded(false);
        return;
    }

    function getDays(days) {
        var date = new Date();
        date.setDate(date.getDate() + days);
        return date;
    }
    useEffect(() => {
        console.log('Loading Tax data>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
        setLoaded(true);
        loadData(restauHook);
    }, [restauHook.restauId]);

    return (
        <div>
            {loaded ? (
                <div>
                    <img
                        className={classes.loadingImage}
                        src="../../img/spinner.gif"
                    />
                </div>
            ) : (
                <>
                    <ControlsContainer>
                        <TextField
                            id="sales-taxs"
                            label="Sales Tax"
                            value={salesTax}
                            variant="outlined"
                            size="small"
                            onChange={salesTaxChange}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {loaded === true ? (
                            <div>
                                <img
                                    height="75px"
                                    className={classes.loadingImage}
                                    src="../../img/spinner.gif"
                                />
                            </div>
                        ) : (
                            <Button
                                onClick={() => {
                                    updateSalesTax(restauHook);
                                }}
                                variant="contained"
                                size="small"
                                color="primary"
                            >
                                Update
                            </Button>
                        )}
                    </ControlsContainer>
                    <div>
                        <br />
                        <div>
                            <b>Download Online + QSR Sales report</b>
                        </div>
                        <ControlsContainer>
                            <DatePicker
                                label="From Date"
                                value={startDate}
                                autoOk
                                inputVariant="outlined"
                                size="small"
                                margin="dense"
                                onChange={(date) => setStartDate(date)}
                                minDate={moment(getDays(-365))}
                                maxDate={moment(endDate ? endDate : new Date())}
                                selected={moment(startDate)}
                                renderInput={(props) => (
                                    <TextField {...props} />
                                )}
                            />

                            <DatePicker
                                label="To Date"
                                value={endDate}
                                autoOk
                                inputVariant="outlined"
                                size="small"
                                margin="dense"
                                onChange={(date) => setEndDate(date)}
                                selected={moment(endDate)}
                                minDate={moment(
                                    startDate ? startDate : getDays(-180)
                                )}
                                maxDate={moment(new Date())}
                                renderInput={(props) => (
                                    <TextField {...props} />
                                )}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    if (!startDate || !endDate) {
                                        alert('Please select valid date range');
                                        return;
                                    }
                                    downloadSalesTax(restauHook);
                                }}
                            >
                                Download Report
                            </Button>
                        </ControlsContainer>
                    </div>
                </>
            )}
        </div>
    );
}
