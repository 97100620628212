import React from 'react';
import HelpText from '../formControls/HelpText';
import InputField from '../formControls/InputField';
import SubmitButton from '../formControls/SubmitButton';

function SignupPendingForm(props) {
    const { handleChange, merchantData, loginMessage, loaded, signup } = props;
    return (
        <>
            <InputField
                label="Full Name"
                type="text"
                value={merchantData.fullName}
                onChange={(e) => handleChange(e, 'fullName')}
            />

            <InputField
                label="Phone number"
                type="text"
                value={merchantData.phone}
                onChange={(e) => handleChange(e, 'phone')}
            />

            <InputField
                label="Email"
                type="email"
                value={merchantData.email}
                onChange={(e) => handleChange(e, 'email')}
            />

            <InputField
                label="Password"
                type="password"
                value={merchantData.password}
                onChange={(e) => handleChange(e, 'password')}
            />

            <SubmitButton
                message={loginMessage}
                severity={loginMessage ? 'error' : ''}
                label="Sign Up"
                showProgress={!loaded}
                disabled={!loaded}
                onClick={signup}
            />

            <HelpText />
        </>
    );
}

SignupPendingForm.propTypes = {};

export default SignupPendingForm;
