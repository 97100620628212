import * as firebase from 'firebase';
import '@firebase/auth';
import '@firebase/firestore';
import Config from './config/Config';

firebase.initializeApp(Config.firebaseConfig);
console.log('Secondary Config>>>>>>>>>>>>>>>>>>>>');

var secondaryServer = firebase.initializeApp(
    Config.secondaryAppConfig,
    'secondary'
);
console.log(secondaryServer);
export function initApp() {
    window.customerApp = secondaryServer;
    return secondaryServer;
}
