import { useState } from 'react';

const defaultConfig = {
    name: '',
    desc: '',
    price: '',
    original_price: '',
};
export function useFoodItem(config = defaultConfig) {
    const [file, setFile] = useState(null);
    const [name, setName] = useState(config.name);
    const [desc, setDesc] = useState(config.desc);
    const [price, setPrice] = useState(config.price);
    const [imgSrc, setImage] = useState(config.imgSrc);
    const [original_price, setOriginalPrice] = useState(config.original_price);

    const updateItem = (params) => {
        console.log(params);
        const { name, desc, price, file, original_price } = params;
        params.hasOwnProperty('name') && setName(name);
        params.hasOwnProperty('desc') && setDesc(desc);
        params.hasOwnProperty('price') && setPrice(price);
        params.hasOwnProperty('original_price') &&
            setOriginalPrice(original_price);
        file && setFile(file);
        file && setImage(URL.createObjectURL(file));
    };

    return {
        file,
        name,
        desc,
        price,
        imgSrc,
        original_price,
        updateItem,
    };
}
