import React from 'react';
import { FoodLabel, FoodContainer, Description } from '../FoodGrid';
import FoodOOS from './FoodOOS';
import FoodHidden from './FoodHidden';

function Tuba(props) {
    const {
        sectionId,
        foodId,
        price,
        desc,
        oos,
        name,
        imgSrc,
        hide,
        onFoodClick,
    } = props;
    return (
        <FoodContainer key={sectionId + foodId} oos={oos} onClick={onFoodClick}>
            <FoodLabel>
                <div style={{ marginRight: '20px' }}>
                    <span opacity="0.5">
                        <b>{name}</b>
                    </span>
                    {oos ? <FoodOOS /> : null}
                    {hide && hide === true ? <FoodHidden /> : null}
                </div>
                <div>{price}</div>
            </FoodLabel>
            <Description>{desc}</Description>
        </FoodContainer>
        // <PopularItem
        //     data={{
        //         name: name,
        //         desc: desc,
        //         price: price,
        //         img: imgSrc,
        //         oos: oos,
        //         hide: hide,
        //     }}
        // />
    );
}

Tuba.propTypes = {};

export default Tuba;
