import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, { useContext, useEffect } from 'react';
import { RestauInfoContext } from '../home/RestauInfoContext.js';
import { useStyles } from '../home/styles/useStyles';

export default function Promotions(props) {
    // const restauHook = props.restauHook;
    const resauInfoContext = useContext(RestauInfoContext);
    const restauHook = resauInfoContext.restauHook;
    const [promotionMessages, setPromotionMessages] = React.useState(undefined);
    const [loaded, setLoaded] = React.useState(false);
    const [saveMessage, setSaveMessage] = React.useState('');
    const classes = useStyles();

    async function updatePromotionsMessages(restauHook) {
        let updates = {};
        const database = window.customerApp.database();
        setSaveMessage('');
        setLoaded(true);
        updates['menus/' + restauHook.restauId + '/promotionMessages'] =
            promotionMessages;
        console.log(updates);
        await database
            .ref()
            .update(updates)
            .then(function () {
                setSaveMessage('Succesfully updated');
            })
            .catch(function (error) {
                alert('Error while saving please try again');
                //Need to pop error someways
                console.log(error);
                setLoaded(false);

                return;
            });
        setLoaded(false);
    }

    function updateMessageField(e, fieldName) {
        let val = promotionMessages;
        val[fieldName] = e.target.value;
        setPromotionMessages({ ...val });
    }

    async function loadData(restauHook) {
        if (!window.customerApp.auth().currentUser) {
            navigate('/login');
            return;
        }
        const token = await window.customerApp.auth().currentUser.getIdToken();

        let config = {
            headers: {
                token: token,
            },
        };
        console.log('Restau Hook from restaurant: ' + restauHook.restauId);
        const admin = window.customerApp.database();
        let menuRef = admin.ref(
            'menus/' + restauHook.restauId + '/promotionMessages'
        );

        const menus = await menuRef
            .once('value')
            .then(function (snapshot) {
                let result = snapshot.val();
                //menuHook.setValue({menu:result});
                console.log('Promotion Messages: ' + promotionMessages);
                if (result) {
                    setPromotionMessages(result);
                } else {
                    setPromotionMessages({});
                }
                setLoaded(false);

                console.log(promotionMessages);
                return result;
            })
            .catch(function (error) {
                setLoaded(false);
                console.log(error);
            });
        return;
    }
    useEffect(() => {
        setLoaded(true);
        loadData(restauHook);
    }, [restauHook.restauId]);

    return (
        <div>
            {!promotionMessages ? (
                <div>
                    <img
                        className={classes.loadingImage}
                        src="../../img/spinner.gif"
                    />
                </div>
            ) : (
                <>
                    <div style={{ maxWidth: 350 }}>
                        <TextField
                            id="banner-message"
                            label="Banner Message"
                            minRows={3}
                            fullWidth
                            multiline
                            size="small"
                            variant="outlined"
                            value={promotionMessages.banner_message}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => {
                                updateMessageField(e, 'banner_message');
                            }}
                        />

                        <TextField
                            id="checkout-message"
                            label="Checkout &amp; Review Message"
                            minRows={3}
                            fullWidth
                            multiline
                            size="small"
                            variant="outlined"
                            value={promotionMessages.checkout_message}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => {
                                updateMessageField(e, 'checkout_message');
                            }}
                        />

                        <TextField
                            id="food-allergy-warning"
                            label="Food Allergy Warning Disclaimer"
                            minRows={3}
                            fullWidth
                            multiline
                            size="small"
                            variant="outlined"
                            value={promotionMessages.food_allergy_warning}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => {
                                updateMessageField(e, 'food_allergy_warning');
                            }}
                        />

                        <TextField
                            id="special-instruction-disclaimer"
                            label="Special Instruction Disclaimer"
                            minRows={3}
                            fullWidth
                            multiline
                            size="small"
                            variant="outlined"
                            value={
                                promotionMessages.special_instruction_disclaimer
                            }
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => {
                                updateMessageField(
                                    e,
                                    'special_instruction_disclaimer'
                                );
                            }}
                        />

                        <TextField
                            id="dine-in-banner-message"
                            label="Dine-In Banner Message"
                            minRows={3}
                            fullWidth
                            multiline
                            size="small"
                            variant="outlined"
                            value={promotionMessages.dine_in_banner_message}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => {
                                updateMessageField(e, 'dine_in_banner_message');
                            }}
                        />

                        <TextField
                            id="dine-in-message"
                            label="Dine-In Submit Tab Message"
                            minRows={3}
                            fullWidth
                            multiline
                            size="small"
                            variant="outlined"
                            value={promotionMessages.dine_in_submit_message}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => {
                                updateMessageField(e, 'dine_in_submit_message');
                            }}
                        />

                        <TextField
                            id="dine-in-auto-gratuity"
                            label="Dine-In Auto Gratuity Message"
                            minRows={3}
                            fullWidth
                            multiline
                            size="small"
                            variant="outlined"
                            value={promotionMessages.dine_in_auto_gratuity}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => {
                                updateMessageField(e, 'dine_in_auto_gratuity');
                            }}
                        />
                    </div>
                    {loaded === true ? (
                        <div>
                            <img
                                height="75px"
                                className={classes.loadingImage}
                                src="../../img/spinner.gif"
                            />
                        </div>
                    ) : (
                        <>
                            <div style={{ textAlign: 'center', maxWidth: 350 }}>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        updatePromotionsMessages(restauHook);
                                    }}
                                    style={{ width: 200, marginTop: 10 }}
                                >
                                    Update
                                </Button>
                            </div>
                            <div style={{ paddingLeft: '10px' }}>
                                {saveMessage}
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
}
