import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router';
import { useAuthentication } from '../Hooks/useAuthentication';
import { useStyles } from './newSignupTheme';

import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';
import BrandingTemplate from '../components/branding/BrandingTemplate';
import SignupForm from '../components/forms/SignupForm';
import Config from '../config/Config';

const { default: axios } = require('axios');

export default function SignupStripe(props) {
    const auth = useAuthentication();
    let navigate = useNavigate();
    const [loaded, setLoaded] = useState(true);
    const [saveLoader, setSaveLoader] = useState(false);
    saveLoader;
    const [merchantData, setMerchantData] = useState({});
    const [loginMessage, setLoginMessage] = useState('');
    const [globalError, setGlobalError] = useState(null);
    const [restauId, setRestauId] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [signupSuccess, setSignupSuccess] = useState(false);
    const [restaurantName, setRestuarantName] = useState(null);
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        noSsr: true,
    });
    const mobileMargin = { marginTop: '210px' };
    const mobileStyle = isMobile ? mobileMargin : {};
    const mobileLayout = isMobile ? 12 : 6;

    let { rId } = useParams();

    const handleChange = (event, id) => {
        console.log('change called');
        setMerchantData({ ...merchantData, [id]: event.target.value });
        console.log(JSON.stringify(merchantData));
    };

    async function executeSignUpStatus(signupStatus, rId) {
        if (signupStatus && signupStatus.data) {
            let status = signupStatus.data.status;
            if (status === 'ERROR') {
                setGlobalError(signupStatus.data.msg);
                setLoaded(true);
                return;
            }
            let restaurantName = signupStatus.data.data.storeName;
            setRestuarantName(restaurantName);
            let onboardType = signupStatus.data.data.onboardType;

            if (status === 'START') {
                return;
            } else if (status === 'SUCCESS') {
                setSignupSuccess(true);
                if (onboardType == 'ONLY_SIGNUP') {
                    setSuccessMessage(
                        'You are successfully signup , please login to the admin dashboard'
                    );
                    return;
                }
            } else if (status === 'REDIRECT_BANK') {
                navigate('/signupstoresuccess/' + rId);
            } else if (status === 'REDIRECT_STRIPE') {
                setLoaded(false);
                console.log(signupStatus.data.data.rdirect);

                location.replace(signupStatus.data.data.rdirect);
                //console.log(signupStatus.data.data.stripeURL)
            }
        }
    }

    async function getOnlySignUPStatus(restauId) {
        setLoaded(false);
        const getOnlySignUPStatus = Config.getStrideQSignupInfo;

        let signupStatus = await axios.post(
            getOnlySignUPStatus,
            {
                env: Config.env,
                restauId: restauId,
            },
            { crossDomain: true }
        );
        setLoaded(true);
        return executeSignUpStatus(signupStatus, rId);
    }
    useEffect(() => {
        // Update the document title using the browser API
        const search = window.location.search;
        const params = new URLSearchParams(search);
        //const restauId = params.get('r'); // None

        if (rId) {
            setRestauId(rId);
            getOnlySignUPStatus(rId);
        }
    }, []);
    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            signup(event);
        }
    }
    async function signup(e) {
        const saveSignupInfo = Config.saveSignupInfo;
        if (!merchantData.fullName) {
            setLoginMessage('Please enter full name');
            return;
        }
        if (!merchantData.email) {
            setLoginMessage('Please enter your email id');
            return;
        }
        if (!merchantData.phone) {
            setLoginMessage('Please enter phone number');
            return;
        }
        if (!merchantData.password) {
            setLoginMessage('Please add password');
            return;
        }
        setLoaded(false);
        let env = Config.env;
        let req = { ...merchantData };
        req['restauId'] = restauId;
        req['env'] = env;
        console.log(req);

        let signupStatus = await axios.post(saveSignupInfo, req, {
            crossDomain: true,
        });
        if (signupStatus.status === 'SUCCESS') {
            window.customerApp
                .auth()
                .signInWithEmailAndPassword(
                    merchantData.email,
                    merchantData.password
                )
                .then((res) => {
                    setLoaded(false);
                    if (res.user) {
                        auth.loggedInUser(res.user);
                    }
                })
                .catch((e) => {
                    setLoaded(false);
                    setLoginMessage('Invalid login');
                });
        }

        executeSignUpStatus(signupStatus, restauId);
    }

    return (
        <BrandingTemplate>
            <SignupForm
                successMessage={successMessage}
                restaurantName={restaurantName}
                merchantData={merchantData}
                loginMessage={loginMessage}
                loaded={loaded}
                globalError={globalError}
                signupSuccess={signupSuccess}
                navigate={navigate}
                handleChange={handleChange}
                signup={signup}
            />
        </BrandingTemplate>
    );
}
