import PropTypes from 'prop-types';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: '5px', // theme.spacing(2), //
    },
    '& .MuiDialogActions-root': {
        padding: '5px', // theme.spacing(1), //
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function AssignStationsDialog(props) {
    const { open, setOpen, handleAssignStations, itemStationMapping } = props;
    //const menuHook = useContext(MenuInfoContext);
    const [tempStationMappings, setTempStationMappings] = useState({});

    const handleClose = () => {
        setOpen(false);
    };

    const handleStationMappingChange = (e, stationId) => {
        setTempStationMappings({
            ...tempStationMappings,
            [stationId]: {
                ...tempStationMappings[stationId],
                isMapped: e.target.checked,
                mappingFromCategory: e.target.checked
                    ? false
                    : tempStationMappings[stationId].mappingFromCategory,
                mappingFromDefault: e.target.checked
                    ? false
                    : tempStationMappings[stationId].mappingFromDefault,
            },
        });
    };

    async function handleAssign() {
        handleClose();
        handleAssignStations({ ...tempStationMappings });
    }

    useEffect(() => {
        //console.log('Loading data..');
        if (open === true) {
            setTempStationMappings({ ...itemStationMapping });
            //console.log('tempStationMappings', tempStationMappings);
        }
    }, [open]);

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={'xs'}
                fullWidth={true}
                sx={{
                    '& .MuiDialog-container': {
                        '& .MuiPaper-root': {
                            width: '100%',
                            maxWidth: '250px', // Set your width here
                        },
                    },
                }}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    Assign to Stations
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <FormControl
                        sx={{ m: 2, ml: 4 }}
                        component="fieldset"
                        variant="standard"
                    >
                        <FormGroup>
                            {tempStationMappings &&
                                Object.keys(tempStationMappings).map(
                                    (stationId) => {
                                        return (
                                            <FormControlLabel
                                                key={stationId}
                                                control={
                                                    <Checkbox
                                                        sx={{
                                                            '& .MuiSvgIcon-root':
                                                                {
                                                                    fontSize: 20,
                                                                },
                                                        }}
                                                        checked={
                                                            tempStationMappings[
                                                                stationId
                                                            ].isMapped
                                                        }
                                                        onChange={(e) =>
                                                            handleStationMappingChange(
                                                                e,
                                                                stationId
                                                            )
                                                        }
                                                        name={stationId}
                                                    />
                                                }
                                                label={
                                                    tempStationMappings[
                                                        stationId
                                                    ].name
                                                }
                                            ></FormControlLabel>
                                        );
                                    }
                                )}
                        </FormGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        autoFocus
                        onClick={handleAssign}
                        variant="contained"
                    >
                        Assign
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

AssignStationsDialog.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    handleAssignStations: PropTypes.func.isRequired,
    itemStationMapping: PropTypes.shape({}).isRequired,
};
