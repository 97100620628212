import React from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import SwitchWithLabel from '../../../../components/common/SwitchWithLabel';
import ToggleButtonGroupWithLabel from '../../../../components/common/ToggleButtonGroupWithLabel';
import {
    overrideAllDays,
    overrideDateTimeTypes,
    overrideStatuses,
    overrideTypes,
} from '../../constants';
import VisOverrideContext from '../../VisOverrideContext';
import { useContext } from 'react';
import { TimePicker } from '@mui/x-date-pickers';

//The Rule selections part in the "Add New" or "Edit Existing" Override dialog
function OverrideRuleSelections() {
    const {
        openOverrideSameRuleType,
        setOpenOverrideSameRuleType,
        openOverrideRuleType,
        setOpenOverrideRuleType,
        openOverrideRuleStatus,
        setOpenOverrideRuleStatus,
        openOverrideRuleDtTimeType,
        setOpenOverrideRuleDtTimeType,
        openOverrideRuleDays,
        setOpenOverrideRuleDays,
        openOverrideRuleTimeStart,
        setOpenOverrideRuleTimeStart,
        openOverrideRuleTimeEnd,
        setOpenOverrideRuleTimeEnd,
        addNewOverrideRule,
        openOverrideErrorStr,
        openOverrideHasError,
    } = useContext(VisOverrideContext);

    return (
        <>
            <Grid item sx={{ m: 1 }}>
                <SwitchWithLabel
                    id="switchForDineInToGo"
                    size="small"
                    name="switch-for-dinein-togo"
                    label="Same for DineIn and ToGo"
                    checked={openOverrideSameRuleType}
                    onChangeHandler={(checked) => {
                        setOpenOverrideSameRuleType(checked);
                        if (checked) {
                            setOpenOverrideRuleType('DineIn & ToGo');
                        }
                    }}
                />
            </Grid>
            {!openOverrideSameRuleType && (
                <Grid item sx={{ m: 1 }}>
                    <ToggleButtonGroupWithLabel
                        items={overrideTypes}
                        value={openOverrideRuleType}
                        color="primary"
                        label="Choose Type"
                        onChange={(e, newVal) => {
                            setOpenOverrideRuleType(newVal);
                        }}
                    />
                </Grid>
            )}
            <Grid item sx={{ m: 1 }}>
                <ToggleButtonGroupWithLabel
                    items={overrideStatuses}
                    value={openOverrideRuleStatus}
                    color="primary"
                    label="Choose Status"
                    onChange={(event, newVal) => {
                        setOpenOverrideRuleStatus(newVal);
                    }}
                />
            </Grid>
            <Grid item sx={{ m: 1 }}>
                <ToggleButtonGroupWithLabel
                    items={overrideDateTimeTypes}
                    value={openOverrideRuleDtTimeType}
                    color="primary"
                    label="Choose Days + Time"
                    onChange={(event, newVal) => {
                        setOpenOverrideRuleDtTimeType(newVal);
                    }}
                />
            </Grid>
            {openOverrideRuleDtTimeType === 'dayTime' && (
                <Grid
                    item
                    sx={{ m: 1, display: 'flex', justifyContent: 'flex-end' }}
                >
                    <ToggleButtonGroupWithLabel
                        items={overrideAllDays}
                        value={openOverrideRuleDays}
                        color="primary"
                        exclusive={false}
                        includeLabel={false}
                        onChange={(event, newVal) => {
                            setOpenOverrideRuleDays([...newVal]);
                        }}
                    />
                </Grid>
            )}
            {openOverrideRuleDtTimeType !== '24/7' && (
                <Grid
                    item
                    sx={{
                        m: 1,
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Grid item xs={5}>
                            <TimePicker
                                label="Start Time"
                                value={openOverrideRuleTimeStart}
                                onChange={setOpenOverrideRuleTimeStart}
                                renderInput={(params) => (
                                    <TextField disabled {...params} />
                                )}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Typography>To</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <TimePicker
                                clearable
                                views={['hours', 'minutes']}
                                label="End Time"
                                value={openOverrideRuleTimeEnd}
                                onChange={setOpenOverrideRuleTimeEnd}
                                renderInput={(params) => (
                                    <TextField disabled {...params} />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid
                item
                sx={{
                    m: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    variant="outlined"
                    onClick={addNewOverrideRule}
                    disabled={openOverrideHasError}
                >
                    Add Override
                </Button>
            </Grid>
            {openOverrideHasError && (
                <Typography sx={{ color: 'red' }} variant="body1">
                    {openOverrideErrorStr}
                </Typography>
            )}
        </>
    );
}

OverrideRuleSelections.propTypes = {};

export default OverrideRuleSelections;
