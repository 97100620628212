import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Typography } from '@mui/material';
import download from '../utils/downloadExcel';
import Grid from '@mui/material/Grid';
import HideLogRow from './hideLogRow';
import AuditTableHead from './AuditTableHead';
import SetTablePagination from '../utils/setTablePagination';

export default function HideLogTable(props) {
    const tableHead = [
        'Item Name',
        'Hide Date',
        'Hide User',
        'Unhide Date',
        'Unhide User',
    ];
    function createData(
        index,
        itemName,
        unhideDate,
        unhideEmail,
        hideDate,
        hideEmail
    ) {
        return {
            index,
            itemName,
            unhideDate,
            unhideEmail,
            hideDate,
            hideEmail,
        };
    }
    var items = [];
    var length = 0;
    console.log(props);
    if (props.hideData != '' && props.hideData != undefined) {
        props.hideData.forEach((item) => {
            items.push(
                createData(
                    item.index,
                    item.itemName,
                    item.unhideDate,
                    item.unhideEmail,
                    item.hideDate,
                    item.hideEmail
                )
            );
        });
    }
    const rows = items;
    length = rows.length;

    const useStyles2 = makeStyles({
        table: {
            minWidth: 650,
        },
    });

    const classes = useStyles2();
    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const rowsPerPageOptions = [5, 10, 15, { label: 'All', value: -1 }];

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    function ExportToExcel() {
        const format = '.xlsx';
        const fileName = 'Hidden Menu' + format;
        download.excelDownload(rows, fileName);
    }
    return (
        <div>
            {length == 0 ? (
                <></>
            ) : (
                <>
                    <Typography
                        component="h2"
                        variant="h5"
                        color="inherit"
                        noWrap
                    >
                        Hidden Stocks
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table
                            className={classes.table}
                            aria-label="custom pagination table"
                        >
                            <AuditTableHead
                                headData={tableHead}
                            ></AuditTableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? rows.slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                      )
                                    : rows
                                ).map((row, index) => (
                                    <HideLogRow row={row}></HideLogRow>
                                ))}

                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{ height: 53 * emptyRows }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            <SetTablePagination
                                rows={rows}
                                rowsPerPageOptions={rowsPerPageOptions}
                                page={page}
                                setPage={setPage}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                            ></SetTablePagination>
                        </Table>
                    </TableContainer>
                    <Grid container justifyContent="flex-end">
                        <Button
                            color="primary"
                            style={{
                                height: 'max-content',
                                marginTop: '20px',
                            }}
                            variant="contained"
                            onClick={() => ExportToExcel()}
                        >
                            Export to Excel
                        </Button>
                    </Grid>
                </>
            )}
        </div>
    );
}
