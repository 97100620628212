import Typography from '@mui/material/Typography';
import React from 'react';
import SlotsConfigContainer from './SlotsConfigContainer';
import SpecialHoursList from './SpecialHourList';

function SpecialHours(props) {
    const { specialHours, onUpdateConfig, onDelete } = props;
    return (
        <div>
            <Typography component="h2" variant="h5">
                Special Hours
            </Typography>
            <SlotsConfigContainer onUpdateConfig={onUpdateConfig} />
            {specialHours && Object.keys(specialHours).length > 0 ? (
                <SpecialHoursList
                    specialHours={specialHours}
                    onDelete={onDelete}
                />
            ) : null}
        </div>
    );
}

SpecialHours.propTypes = {};

export default SpecialHours;
