import React from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

function UpdatedFoodItems(props) {
    const { updatedFoodItems, updatedMenuItemDataFromServer } = props;
    return (
        <>
            {updatedFoodItems.length > 0 ? (
                <TableContainer style={{ maxHeight: '400px' }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow
                                style={{
                                    backgroundColor: '#f5f5f5',
                                    height: '40px',
                                    textAlign: 'center',
                                }}
                            >
                                <TableCell style={{ maxWidth: '50px' }}>
                                    SL.No
                                </TableCell>
                                <TableCell
                                    style={{
                                        textAlign: 'center',
                                        minWidth: '250px',
                                    }}
                                >
                                    Category Name
                                </TableCell>
                                <TableCell
                                    style={{
                                        textAlign: 'center',
                                        minWidth: '200px',
                                    }}
                                >
                                    Food Name
                                </TableCell>
                                <TableCell style={{ width: '50px' }}>
                                    Price
                                </TableCell>
                                <TableCell style={{ width: '50px' }}>
                                    Hidden
                                </TableCell>
                                <TableCell style={{ width: '50px' }}>
                                    OutOfStock
                                </TableCell>
                                <TableCell
                                    style={{
                                        textAlign: 'center',
                                        minWidth: '250px',
                                    }}
                                >
                                    Description
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    ChoicesLabel
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    ChoiceAPrice
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    ChoiceALabel
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    ChoiceBPrice
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    ChoiceBLabel
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    ChoiceCPrice
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    ChoiceCLabel
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    ChoiceDPrice
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    ChoiceDLabel
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    ChoiceEPrice
                                </TableCell>
                                <TableCell style={{ maxWidth: '100px' }}>
                                    ChoiceELabel
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {updatedFoodItems.map((item, index) => {
                                //debugger;
                                let key =
                                    item.categoryName + '_' + item.foodName;
                                let prevVal =
                                    updatedMenuItemDataFromServer[key];
                                return (
                                    <TableRow
                                        key={key}
                                        style={{
                                            height: '40px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <TableCell
                                            style={{ textAlign: 'center' }}
                                        >
                                            {index}
                                        </TableCell>
                                        <TableCell
                                            style={{ textAlign: 'center' }}
                                        >
                                            {item.categoryName}
                                        </TableCell>
                                        <TableCell
                                            style={{ textAlign: 'center' }}
                                        >
                                            {item.foodName}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                textAlign: 'center',
                                                color:
                                                    prevVal.price === item.price
                                                        ? 'black'
                                                        : 'red',
                                            }}
                                        >
                                            {item.price}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                textAlign: 'center',
                                                color:
                                                    prevVal.hidden ===
                                                    item.hidden
                                                        ? 'black'
                                                        : 'red',
                                            }}
                                        >
                                            {item.hidden &&
                                                item.hidden.toString()}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                textAlign: 'center',
                                                color:
                                                    prevVal.outOfStock ===
                                                    item.outOfStock
                                                        ? 'black'
                                                        : 'red',
                                            }}
                                        >
                                            {item.outOfStock &&
                                                item.outOfStock.toString()}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                textAlign: 'center',
                                                color:
                                                    prevVal.foodDescription ===
                                                    item.foodDescription
                                                        ? 'black'
                                                        : 'red',
                                            }}
                                        >
                                            {item.foodDescription}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                color:
                                                    prevVal.choiceslabel ===
                                                    item.choiceslabel
                                                        ? 'black'
                                                        : 'red',
                                            }}
                                        >
                                            {item.choiceslabel}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                color:
                                                    prevVal.ChoiceAPrice ===
                                                    item.ChoiceAPrice
                                                        ? 'black'
                                                        : 'red',
                                            }}
                                        >
                                            {item.ChoiceAPrice}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                color:
                                                    prevVal.ChoiceALabel ===
                                                    item.ChoiceALabel
                                                        ? 'black'
                                                        : 'red',
                                            }}
                                        >
                                            {item.ChoiceALabel}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                color:
                                                    prevVal.ChoiceBLabel ===
                                                    item.ChoiceBLabel
                                                        ? 'black'
                                                        : 'red',
                                            }}
                                        >
                                            {item.ChoiceBLabel}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                color:
                                                    prevVal.ChoiceBPrice ===
                                                    item.ChoiceBPrice
                                                        ? 'black'
                                                        : 'red',
                                            }}
                                        >
                                            {item.ChoiceBPrice}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                color:
                                                    prevVal.ChoiceCPrice ===
                                                    item.ChoiceCPrice
                                                        ? 'black'
                                                        : 'red',
                                            }}
                                        >
                                            {item.ChoiceCPrice}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                color:
                                                    prevVal.ChoiceCLabel ===
                                                    item.ChoiceCLabel
                                                        ? 'black'
                                                        : 'red',
                                            }}
                                        >
                                            {item.ChoiceCLabel}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                color:
                                                    prevVal.ChoiceDLabel ===
                                                    item.ChoiceDLabel
                                                        ? 'black'
                                                        : 'red',
                                            }}
                                        >
                                            {item.ChoiceDLabel}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                color:
                                                    prevVal.ChoiceDPrice ===
                                                    item.ChoiceDPrice
                                                        ? 'black'
                                                        : 'red',
                                            }}
                                        >
                                            {item.ChoiceDPrice}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                color:
                                                    prevVal.ChoiceELabel ===
                                                    item.ChoiceELabel
                                                        ? 'black'
                                                        : 'red',
                                            }}
                                        >
                                            {item.ChoiceELabel}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                color:
                                                    prevVal.ChoiceEPrice ===
                                                    item.ChoiceEPrice
                                                        ? 'black'
                                                        : 'red',
                                            }}
                                        >
                                            {item.ChoiceEPrice}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography>None</Typography>
            )}
        </>
    );
}

UpdatedFoodItems.propTypes = {
    updatedFoodItems: PropTypes.arrayOf(PropTypes.shape()),
    updatedMenuItemDataFromServer: PropTypes.shape({}),
};

export default UpdatedFoodItems;
